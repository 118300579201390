import React from 'react';
import DollarOutlinedIcon from 'components/sgvIcons/DollarOutlined';
import VndOutlined from 'components/sgvIcons/VndOutlined';

export enum ECurrency {
  Vnd = 'VND',
  Usd = 'USD',
  Brl = "BRL",
  Eur = "EUR",
  Cny = "CNY",
  Thb = "THB",
  Idr = "IDR",
  Myr = "MYR",
  Php = "PHP",
  Krw = "KRW",
  Jpy = "JPY",
  Mxn = "MXN",
  Gbp = "GBP",
  Egp = "EGP",
  PLN = "PLN",
}

export enum ELocales {
  vi = 'vi',
  en = 'en'
}

export enum ELanguageLocales {
  vi = 'vi',
  en = 'en',
  zh = 'zh',
  fr = 'fr',
  ms = 'ms',
  pl = 'pl',
  ar = 'ar',
  pt = 'pt',
  es = 'es',
  de = 'de',
  th = 'th',
  in = 'in',
  nl = 'nl',
  fil = 'fil',
  el = 'el',
  ja = 'ja',
  ko = 'ko',
  tl = 'tl',
}

export const formatPrefix = (preferCurrency: ECurrency) => {
  switch (preferCurrency) {
    case ECurrency.Usd:
      return '$';

    case ECurrency.Brl:
      return 'R$';

    case ECurrency.Cny:
      return '¥';

    case ECurrency.Myr:
      return 'RM';

    case ECurrency.Php:
      return '₱';

    case ECurrency.Krw:
      return '₩';

    default:
      return '';
  }
};

export const formatSuffix = (preferCurrency: ECurrency) => {
  switch (preferCurrency) {
    case ECurrency.Vnd:
      return 'đ';

    case ECurrency.Eur:
      return '€';

    case ECurrency.Thb:
      return '฿';

    case ECurrency.Idr:
      return 'Rp';

    case ECurrency.Jpy:
      return '¥';

    case ECurrency.Mxn:
      return 'Mex$';

    case ECurrency.Gbp:
      return '£';

    case ECurrency.Egp:
      return 'E£';

    case ECurrency.PLN:
      return 'zł'

    default:
      return '';
  }
};

type CurrencySymbol = 'prefix' | 'suffix';

export const formatPrefixOrSuffix = (preferCurrency: ECurrency): CurrencySymbol => {
  switch (preferCurrency) {
    case ECurrency.Usd:
      return 'prefix';

    case ECurrency.Vnd:
      return 'suffix';

    case ECurrency.Brl:
      return 'prefix';

    case ECurrency.Eur:
      return 'suffix';

    case ECurrency.Cny:
      return 'prefix';

    case ECurrency.Thb:
      return 'suffix';

    case ECurrency.Idr:
      return 'suffix';

    case ECurrency.Myr:
      return 'prefix';

    case ECurrency.Php:
      return 'prefix';

    case ECurrency.Krw:
      return 'prefix';

    case ECurrency.Jpy:
      return 'suffix';

    case ECurrency.Mxn:
      return 'suffix';

    case ECurrency.Gbp:
      return 'suffix';

    case ECurrency.Egp:
      return 'suffix';

    case ECurrency.PLN:
      return 'suffix';

    default:
      return 'prefix';
  }
}

export const LIST_CURRENCY: TCurrencyObject[] = [
  {
    id: 1,
    code: ECurrency.Usd,
    conversionRate: 1,
    isDefault: true,
    name: "US DOLLAR",
    state: 1,
    symbol: "$",
    icon: <DollarOutlinedIcon width="16px" height="16px" />,
  },
  {
    id: 2,
    code: ECurrency.Vnd,
    conversionRate: 23400,
    isDefault: false,
    name: "vietnamdong",
    state: 1,
    symbol: "đ",
    icon: <VndOutlined width="16px" height="16px" />,
  },
];

export type TCurrencyObject = {
  code: ECurrency;
  conversionRate: number;
  id: number;
  state: number;
  symbol: string;
  name: string;
  isDefault: boolean;
  icon?: React.ReactElement;
};

export const CURRENCY_LOCALE_CODES = {
  [ELocales.en]: 'en-US',
  [ELocales.vi]: 'vi-VN',
};
