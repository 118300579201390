import { TCountryPhoneCode } from "constants/i18n";

export const COUNTRY_PHONE_CODE_OPTIONS_IN: TCountryPhoneCode[] = [
  {
    "text": "Armenia +374",
    "value": "+374",
    "locale": "am-AM",
    "shortLocale": "AM"
  },
  {
    "text": "Uni Emirat Arab +971",
    "value": "+971",
    "locale": "ar-AE",
    "shortLocale": "AE"
  },
  {
    "text": "Bahrain +973",
    "value": "+973",
    "locale": "ar-BH",
    "shortLocale": "BH"
  },
  {
    "text": "Aljazair +213",
    "value": "+213",
    "locale": "ar-DZ",
    "shortLocale": "DZ"
  },
  {
    "text": "Mesir +20",
    "value": "+20",
    "locale": "ar-EG",
    "shortLocale": "EG"
  },
  {
    "text": "Irak +964",
    "value": "+964",
    "locale": "ar-IQ",
    "shortLocale": "IQ"
  },
  {
    "text": "Yordania +962",
    "value": "+962",
    "locale": "ar-JO",
    "shortLocale": "JO"
  },
  {
    "text": "Kuwait +965",
    "value": "+965",
    "locale": "ar-KW",
    "shortLocale": "KW"
  },
  {
    "text": "Libya +218",
    "value": "+218",
    "locale": "ar-LY",
    "shortLocale": "LY"
  },
  {
    "text": "Arab Saudi +966",
    "value": "+966",
    "locale": "ar-SA",
    "shortLocale": "SA"
  },
  {
    "text": "Suriah +963",
    "value": "+963",
    "locale": "ar-SY",
    "shortLocale": "SY"
  },
  {
    "text": "Tunisia +216",
    "value": "+216",
    "locale": "ar-TN",
    "shortLocale": "TN"
  },
  {
    "text": "Belarusia +375",
    "value": "+375",
    "locale": "be-BY",
    "shortLocale": "BY"
  },
  {
    "text": "Bulgaria +359",
    "value": "+359",
    "locale": "bg-BG",
    "shortLocale": "BG"
  },
  {
    "text": "Bangladesh +880",
    "value": "+880",
    "locale": "bn-BD",
    "shortLocale": "BD"
  },
  {
    "text": "Republik Ceko +420",
    "value": "+420",
    "locale": "cs-CZ",
    "shortLocale": "CZ"
  },
  {
    "text": "Denmark +45",
    "value": "+45",
    "locale": "da-DK",
    "shortLocale": "DK"
  },
  {
    "text": "Jerman +49",
    "value": "+49",
    "locale": "de-DE",
    "shortLocale": "DE"
  },
  {
    "text": "Austria +43",
    "value": "+43",
    "locale": "de-AT",
    "shortLocale": "AT"
  },
  {
    "text": "Swiss +41",
    "value": "+41",
    "locale": "de-CH",
    "shortLocale": "CH"
  },
  {
    "text": "Yunani +30",
    "value": "+30",
    "locale": "el-GR",
    "shortLocale": "GR"
  },
  {
    "text": "Australia +61",
    "value": "+61",
    "locale": "en-AU",
    "shortLocale": "AU"
  },
  {
    "text": "Inggris Raya +44",
    "value": "+44",
    "locale": "en-GB",
    "shortLocale": "GB"
  },
  {
    "text": "Ghana +233",
    "value": "+233",
    "locale": "en-GH",
    "shortLocale": "GH"
  },
  {
    "text": "Hong Kong +852",
    "value": "+852",
    "locale": "en-HK",
    "shortLocale": "HK"
  },
  {
    "text": "Makau +853",
    "value": "+853",
    "locale": "en-MO",
    "shortLocale": "MO"
  },
  {
    "text": "Irlandia +353",
    "value": "+353",
    "locale": "en-IE",
    "shortLocale": "IE"
  },
  {
    "text": "India +91",
    "value": "+91",
    "locale": "en-IN",
    "shortLocale": "IN"
  },
  {
    "text": "Kenya +254",
    "value": "+254",
    "locale": "en-KE",
    "shortLocale": "KE"
  },
  {
    "text": "Malta +356",
    "value": "+356",
    "locale": "en-MT",
    "shortLocale": "MT"
  },
  {
    "text": "Mauritius +230",
    "value": "+230",
    "locale": "en-MU",
    "shortLocale": "MU"
  },
  {
    "text": "Nigeria +234",
    "value": "+234",
    "locale": "en-NG",
    "shortLocale": "NG"
  },
  {
    "text": "Selandia Baru +64",
    "value": "+64",
    "locale": "en-NZ",
    "shortLocale": "NZ"
  },
  {
    "text": "Pakistan +92",
    "value": "+92",
    "locale": "en-PK",
    "shortLocale": "PK"
  },
  {
    "text": "Rwanda +250",
    "value": "+250",
    "locale": "en-RW",
    "shortLocale": "RW"
  },
  {
    "text": "Singapura +65",
    "value": "+65",
    "locale": "en-SG",
    "shortLocale": "SG"
  },
  {
    "text": "Sierra Leone +232",
    "value": "+232",
    "locale": "en-SL",
    "shortLocale": "SL"
  },
  {
    "text": "Tanzania +255",
    "value": "+255",
    "locale": "en-TZ",
    "shortLocale": "TZ"
  },
  {
    "text": "Uganda +256",
    "value": "+256",
    "locale": "en-UG",
    "shortLocale": "UG"
  },
  {
    "text": "Amerika Serikat +1",
    "value": "+1",
    "locale": "en-US",
    "shortLocale": "US"
  },
  {
    "text": "Afrika Selatan +27",
    "value": "+27",
    "locale": "en-ZA",
    "shortLocale": "ZA"
  },
  {
    "text": "Zambia +260",
    "value": "+260",
    "locale": "en-ZM",
    "shortLocale": "ZM"
  },
  {
    "text": "Zimbabwe +263",
    "value": "+263",
    "locale": "en-ZW",
    "shortLocale": "ZW"
  },
  {
    "text": "Kolombia +57",
    "value": "+57",
    "locale": "es-CO",
    "shortLocale": "CO"
  },
  {
    "text": "Chili +56",
    "value": "+56",
    "locale": "es-CL",
    "shortLocale": "CL"
  },
  {
    "text": "Kosta Rika +506",
    "value": "+506",
    "locale": "es-CR",
    "shortLocale": "CR"
  },
  {
    "text": "Ekuador +593",
    "value": "+593",
    "locale": "es-EC",
    "shortLocale": "EC"
  },
  {
    "text": "Spanyol +34",
    "value": "+34",
    "locale": "es-ES",
    "shortLocale": "ES"
  },
  {
    "text": "Meksiko +52",
    "value": "+52",
    "locale": "es-MX",
    "shortLocale": "MX"
  },
  {
    "text": "Panama +507",
    "value": "+507",
    "locale": "es-PA",
    "shortLocale": "PA"
  },
  {
    "text": "Paraguay +595",
    "value": "+595",
    "locale": "es-PY",
    "shortLocale": "PY"
  },
  {
    "text": "Uruguay +598",
    "value": "+598",
    "locale": "es-UY",
    "shortLocale": "UY"
  },
  {
    "text": "Estonia +372",
    "value": "+372",
    "locale": "et-EE",
    "shortLocale": "EE"
  },
  {
    "text": "Iran +98",
    "value": "+98",
    "locale": "fa-IR",
    "shortLocale": "IR"
  },
  {
    "text": "Finlandia +358",
    "value": "+358",
    "locale": "fi-FI",
    "shortLocale": "FI"
  },
  {
    "text": "Fiji +679",
    "value": "+679",
    "locale": "fj-FJ",
    "shortLocale": "FJ"
  },
  {
    "text": "Kepulauan Faroe +298",
    "value": "+298",
    "locale": "fo-FO",
    "shortLocale": "FO"
  },
  {
    "text": "Prancis +33",
    "value": "+33",
    "locale": "fr-FR",
    "shortLocale": "FR"
  },
  {
    "text": "Guyana Prancis +594",
    "value": "+594",
    "locale": "fr-GF",
    "shortLocale": "GF"
  },
  {
    "text": "Guadeloupe +590",
    "value": "+590",
    "locale": "fr-GP",
    "shortLocale": "GP"
  },
  {
    "text": "Martinique +596",
    "value": "+596",
    "locale": "fr-MQ",
    "shortLocale": "MQ"
  },
  {
    "text": "Réunion +262",
    "value": "+262",
    "locale": "fr-RE",
    "shortLocale": "RE"
  },
  {
    "text": "Israel +972",
    "value": "+972",
    "locale": "he-IL",
    "shortLocale": "IL"
  },
  {
    "text": "Hungaria +36",
    "value": "+36",
    "locale": "hu-HU",
    "shortLocale": "HU"
  },
  {
    "text": "Indonesia +62",
    "value": "+62",
    "locale": "id-ID",
    "shortLocale": "ID"
  },
  {
    "text": "Italia +39",
    "value": "+39",
    "locale": "it-IT",
    "shortLocale": "IT"
  },
  {
    "text": "Jepang +81",
    "value": "+81",
    "locale": "ja-JP",
    "shortLocale": "JP"
  },
  {
    "text": "Kazakhstan +7",
    "value": "+7",
    "locale": "kk-KZ",
    "shortLocale": "KZ"
  },
  {
    "text": "Greenland +299",
    "value": "+299",
    "locale": "kl-GL",
    "shortLocale": "GL"
  },
  {
    "text": "Korea Selatan +82",
    "value": "+82",
    "locale": "ko-KR",
    "shortLocale": "KR"
  },
  {
    "text": "Lituania +370",
    "value": "+370",
    "locale": "lt-LT",
    "shortLocale": "LT"
  },
  {
    "text": "Malaysia +60",
    "value": "+60",
    "locale": "ms-MY",
    "shortLocale": "MY"
  },
  {
    "text": "Norwegia +47",
    "value": "+47",
    "locale": "nb-NO",
    "shortLocale": "NO"
  },
  {
    "text": "Nepal +977",
    "value": "+977",
    "locale": "ne-NP",
    "shortLocale": "NP"
  },
  {
    "text": "Belgia +32",
    "value": "+32",
    "locale": "nl-BE",
    "shortLocale": "BE"
  },
  {
    "text": "Belanda +31",
    "value": "+31",
    "locale": "nl-NL",
    "shortLocale": "NL"
  },
  {
    "text": "Polandia +48",
    "value": "+48",
    "locale": "pl-PL",
    "shortLocale": "PL"
  },
  {
    "text": "Brasil +55",
    "value": "+55",
    "locale": "pt-BR",
    "shortLocale": "BR"
  },
  {
    "text": "Portugal +351",
    "value": "+351",
    "locale": "pt-PT",
    "shortLocale": "PT"
  },
  {
    "text": "Rumania +40",
    "value": "+40",
    "locale": "ro-RO",
    "shortLocale": "RO"
  },
  {
    "text": "Rusia +7",
    "value": "+7",
    "locale": "ru-RU",
    "shortLocale": "RU"
  },
  {
    "text": "Slovenia +386",
    "value": "+386",
    "locale": "sl-SI",
    "shortLocale": "SI"
  },
  {
    "text": "Slovakia +421",
    "value": "+421",
    "locale": "sk-SK",
    "shortLocale": "SK"
  },
  {
    "text": "Serbia +381",
    "value": "+381",
    "locale": "sr-RS",
    "shortLocale": "RS"
  },
  {
    "text": "Swedia +46",
    "value": "+46",
    "locale": "sv-SE",
    "shortLocale": "SE"
  },
  {
    "text": "Thailand +66",
    "value": "+66",
    "locale": "th-TH",
    "shortLocale": "TH"
  },
  {
    "text": "Turki +90",
    "value": "+90",
    "locale": "tr-TR",
    "shortLocale": "TR"
  },
  {
    "text": "Ukraina +380",
    "value": "+380",
    "locale": "uk-UA",
    "shortLocale": "UA"
  },
  {
    "text": "Vietnam +84",
    "value": "+84",
    "locale": "vi-VN",
    "shortLocale": "VN"
  },
  {
    "text": "Tiongkok +86",
    "value": "+86",
    "locale": "zh-CN",
    "shortLocale": "CN"
  },
  {
    "text": "Taiwan +886",
    "value": "+886",
    "locale": "zh-TW",
    "shortLocale": "TW"
  },
  // {
  //   "text": "Kanada +1",
  //   "value": "+1",
  //   "locale": "en-CA",
  //   "shortLocale": "CA"
  // }
];