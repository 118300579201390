import { cloneDeep, pick } from 'lodash';

// M: current model
// P: payload format, default payload format is same as model attributes
abstract class BaseModel<M, P = M> {
  clone(): this {
    return cloneDeep(this);
  }
  
  // EXTENDABLE
  protected _getAttrNames(): string[] {
    return [];
  }
  
  // EXTENDABLE: massive assign data
  assign(attrs: Partial<Omit<M, BaseModelAttrNames>>) {
    if (this._getAttrNames().length > 0) {
      Object.assign(this, pick(attrs, this._getAttrNames()));
    } else {
      Object.assign(this, attrs);
    }
    return this;
  }
  
  toObject(): Partial<Exclude<M, Function>> {
    return pick(this, this._getAttrNames()) as Partial<Exclude<M, Function>>;
  }
  
  // EXTENDABLE: map current model to endpoint payload
  // Override this method if payload format is different from model attributes
  toPayload(): Partial<P> {
    return Object.assign({}, this);
  }
  
  // EXTENDABLE: map payload from endpoint to current model
  // Override this method if payload format is different from model attributes
  fromPayload(payload: Partial<P>): this {
    Object.assign(this, payload);
    return this;
  };
}

export type BaseModelAttrNames =
  | 'clone'
  | 'assign'
  | 'toObject'
  | 'toPayload'
  | 'fromPayload'
  | '_getAttrNames';

export default BaseModel;
