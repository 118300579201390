import React from 'react';

const IcArrowRight = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 28 27" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.98146 20.4404C9.55826 20.0172 9.55826 19.3311 9.98146 18.9079L15.7171 13.1723L9.98146 7.43661C9.55827 7.01342 9.55827 6.32729 9.98146 5.9041C10.4046 5.48091 11.0908 5.48091 11.514 5.9041L18.0159 12.406C18.4391 12.8292 18.4391 13.5153 18.0159 13.9385L11.514 20.4404C11.0908 20.8636 10.4046 20.8636 9.98146 20.4404Z" fill="white" />
    </svg>
  );
};

export default IcArrowRight;
