import BaseModel from './baseModel';
import { Obj } from 'constants/types';

const HIDE_CATEGORY_NAMES = ['Walls', 'Floors', 'Ceilings', 'Tường', 'Sàn', 'Trần']

class ProductCategory extends BaseModel<ProductCategory> {
  id?: number;

  name?: string;

  image128Url?: string;
  image512Url?: string;
  
  get shouldHideProductName() {
    return HIDE_CATEGORY_NAMES.includes(this.name!);
  }

  fromPayload({ id, name, image128Url, image512Url }: Obj): this {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (image128Url !== undefined) this.image128Url = image128Url;
    if (image512Url !== undefined) this.image512Url = image512Url;

    return this;
  }
}

export default ProductCategory;
