import React from 'react';
import styled from 'styled-components';
import Image from 'components/images/Image';
import logoBg from 'assets/logo.png';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageRoute } from 'constants/route';
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';

const PageFooter = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Stack flexDirection="row" justifyContent="center" pt={4} pb={4}>
        <Box>
          <Image
            src={getStorage(StorageKey.LogoCompany) ? getStorage(StorageKey.LogoCompany) : logoBg}
            height="auto"
            width="230px"
          />
        </Box>
      </Stack>
      <Stack flexDirection={{ md: 'row', xs: 'column' }} padding={{ md: 0, xs: 1.5 }} justifyContent="space-evenly">
        <Box className='box-footer'>
          <div className='title-box-footer'>
            {t('Help Center')}
          </div>
          <div className='text-sub-footer'>
            <Link to={PageRoute.ContactUs}>
              {t('Get in touch')}
            </Link>
          </div>
          <div className='text-sub-footer'>
            <Link to={PageRoute.FAQ}>
              {t('Frequently Asked Questions')}
            </Link>
          </div>
          <div className='text-sub-footer'></div>
        </Box>
        <Box className='box-footer'>
          <div className='title-box-footer'>
            {t('{{context}} International', { context: 'Levitate' })}
          </div>
          <div className='text-sub-footer'>
            <Link to={PageRoute.OurStory}>
              {t('Our Story')}
            </Link>
          </div>
          <div className='text-sub-footer'>
            {/* <Link to={PageRoute.InTheSplotlight}> */}
              {t('In the spotlight')}
            {/* </Link> */}
          </div>
          <div className='text-sub-footer'>
            <a href='https://www.linkedin.com/company/levitateinternational/' target="_blank" rel="noopener noreferrer">
              {t('Join our team')}
            </a>
          </div>
        </Box>
        <Box className='box-footer'>
          <div className='title-box-footer'>{t('Inspiration & Testimonials')}</div>
          <div className='text-sub-footer'>
            <Link to={PageRoute.PrivacyPolicy}>
              {t('Privacy Policy')}
            </Link>
          </div>
          <div className='text-sub-footer'>
            <Link to={PageRoute.CookiePolicy}>
              {t('Cookie Policy')}
            </Link>
          </div>
          <div className='text-sub-footer'>
            <Link to={PageRoute.TermsOfUse}>
              {t('Terms of use')}
            </Link>
          </div>
        </Box>
      </Stack>
      <div className='text-copyright'>
        Copyright © Levitate International Pte. Ltd. All rights reserved.
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: url(https://storage.googleapis.com/levitate-project-management-v2/levitate-me-static-resource/footer/background-footer-levitate.png);
  background-size: cover;
  background-color: rgba(77, 74, 84, 0.1); 
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  width: 100%;

  .title-box-footer {
    padding-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;              
  }

  .text-sub-footer {
    font-weight: 300;
    font-size: 15px;
    height: 30px;
    cursor: pointer;

    a{
      color: #fff;

      &:hover{
        text-decoration: none;
        color: ${p => p.theme.palette.accent.main};
      }
    }
  }

  .box-footer {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .text-copyright {
    background: #5A5761;
    padding: 1rem;
    text-align: center;
    font-size: 13px;
  }
`;

export default PageFooter;
