import React from 'react';
import BaseIcon from './BaseIcon';

const IcOutlinePlayV2 = (props: any) => {
  return (
    <BaseIcon
      viewBox="0 0 54 62"
      {...props}
    >
      <path d="M52.0007 28.0999L5.33401 1.16655C4.82728 0.873991 4.25247 0.719971 3.66734 0.719971C3.08222 0.719971 2.50741 0.873991 2.00068 1.16655C1.492 1.46023 1.06995 1.88311 0.77727 2.39237C0.484593 2.90163 0.331679 3.47919 0.334011 4.06655V57.9332C0.331679 58.5206 0.484593 59.0981 0.77727 59.6074C1.06995 60.1167 1.492 60.5395 2.00068 60.8332C2.50741 61.1258 3.08222 61.2798 3.66734 61.2798C4.25247 61.2798 4.82728 61.1258 5.33401 60.8332L52.0007 33.8999C52.5137 33.6092 52.9404 33.1876 53.2372 32.6781C53.5341 32.1686 53.6905 31.5895 53.6905 30.9999C53.6905 30.4102 53.5341 29.8311 53.2372 29.3217C52.9404 28.8122 52.5137 28.3906 52.0007 28.0999ZM7.00068 52.1666V9.83322L43.6673 30.9999L7.00068 52.1666Z" fill="currentColor"/>
    </BaseIcon>
  )
}

export default IcOutlinePlayV2
