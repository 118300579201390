enum DesignHomeTypes {
  SetDesignHome = 'designHome/SET_DESIGN_HOME',
  ResetDesignHome = 'designHome/RESET_DESIGN_HOME',

  SetAuth = 'designHome/SET_AUTH',

  SetFinancePlan = 'designHome/SET_FINANCE_PLAN',
  ResetFinancePlan = 'designHome/RESET_FINANCE_PLAN',

  FilterProjects = 'designHome/FILTER_PROJECTS',

  SetTextContentBrand = 'designHome/SET_TEXT_CONTENT_BRAND',
}

export default DesignHomeTypes;
