import {
  createRequestInstance,
  RequestConfigs,
  Interceptor,
} from 'utils/requestHelpers';

import {
  dataInterceptor,
  firebaseAuthInterceptor,
  homebuyerAuthInterceptor,
  saleAgentAuthInterceptor,
  portalAuthInterceptor,
  notifyInterceptor,
  abortInterceptor,
  customerInterceptor, 
  userInterceptor,
} from './interceptors';

// CONSTANTS
import env from 'constants/env';

// Auto add common interceptors
const createRequest = (
  configs: RequestConfigs,
  interceptors?: Interceptor[]
) => createRequestInstance(
  configs,
[...(interceptors || []), dataInterceptor, abortInterceptor]
);

export const saleAgentAuthRequest = createRequest({
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v2/`,
  timeout: 100000,
});

export const homebuyerAuthRequest = createRequest({
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v2/`,
  timeout: 100000
}, [saleAgentAuthInterceptor, firebaseAuthInterceptor]);

export const saleAgentManagementRequest = createRequest({
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v2/`,
  timeout: 100000,
}, [saleAgentAuthInterceptor])

export const userManagementRequest = createRequest({
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v2/`,
  timeout: 200000,
}, [homebuyerAuthInterceptor, firebaseAuthInterceptor, notifyInterceptor]);

export const projectManagementRequest = createRequest({
  baseURL: `${env.API_PROJECT_MANAGEMENT_HOSTNAME}/api/v2/levitate`,
  timeout: 100000,
}, [homebuyerAuthInterceptor, notifyInterceptor]);

export const projectManagementPublicRequest = createRequest({
  baseURL: `${env.API_PROJECT_MANAGEMENT_HOSTNAME}/api/v2/public/levitate`,
  timeout: 100000,
}, [saleAgentAuthInterceptor, notifyInterceptor]);

export const projectManagementV1Request = createRequest({
  baseURL: `${process.env.REACT_APP_API_PROJECT_MANAGEMENT_V1_HOSTNAME}/api/v1/public`,
  timeout: 30000,
})

export const portalRequest = createRequest({
  baseURL: `${env.API_PROJECT_MANAGEMENT_HOSTNAME}/api/v2/`,
  timeout: 100000,
}, [portalAuthInterceptor, firebaseAuthInterceptor]);

export const portalUserRequest = createRequest({
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v2/`,
  timeout: 100000
}, [portalAuthInterceptor, firebaseAuthInterceptor])

export const matchMakerRequest = createRequest({
  baseURL: env.API_MATCHMAKER_HOSTNAME,
  timeout: 100000,
});

export const ipApiRequest = createRequest({
  baseURL: process.env.REACT_APP_API_IPAPI_HOSTNAME!,
  timeout: 100000,
});

export const questionnaireRequest = createRequest({
  baseURL: env.API_QUESTIONNAIRE_HOSTNAME,
  timeout: 100000,
});

export const pmsPublicRequest = createRequest({
  // baseURL: `https://pms-backend.levitate.me/api/v3/public`,
  // baseURL: `https://pms-backend-staging.levitate.me/api/v3/public`,
  // baseURL: `http://192.168.1.58:9090/api/v3/public`,
  // baseURL: `https://test.pms-backend.levitate.me/api/v3/public`,
  baseURL: `${env.API_PROJECT_MANAGEMENT_HOSTNAME}/api/v3/public`,
  timeout: 100000,
}, []);

export const pmsCustomerRequest = createRequest({
  // baseURL: `https://pms-backend.levitate.me/api/v3/levitate/pro`,
  // baseURL: `https://pms-backend-staging.levitate.me/api/v3/levitate/pro`,
  // baseURL: `http://192.168.1.58:9090/api/v3/levitate/pro`,
  // baseURL: `https://test.pms-backend.levitate.me/api/v3/levitate/pro`,
  baseURL: `${env.API_PROJECT_MANAGEMENT_HOSTNAME}/api/v3/levitate/pro`,
  timeout: 100000,
}, [customerInterceptor]);

export const userPublicRequest = createRequest({
  // baseURL: `https://user.levitate.me/api/v3/public`,
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v3/public`,
  timeout: 100000,
}, []);

export const userPrivateRequest = createRequest({
  // baseURL: `https://user.levitate.me/api/v3`,
  // baseURL: `https://user.levitate-staging.me/api/v3`,
  baseURL: `${env.API_USER_MANAGEMENT_HOSTNAME}/api/v3`,
  timeout: 100000,
}, [userInterceptor]);

export const userUploadRequest = createRequest({
  // baseURL: `https://pms-backend.levitate.me/api/v2`,
  // baseURL: `https://pms-backend-staging.levitate.me/api/v2`,
  baseURL: `${env.API_PROJECT_MANAGEMENT_HOSTNAME}/api/v2`,
  timeout: 100000,
}, [customerInterceptor]);