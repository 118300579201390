import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import Button from 'components/buttons/Button';
import { PageFooter } from 'components/layouts';
import { ELanguageLocales } from 'constants/currency';
import { useLayout } from 'contexts/layoutContext';
import { i18n } from 'locales';
import { POLICY_AR } from 'locales/policy/policy-ar';
import { POLICY_DE } from 'locales/policy/policy-de';
import { POLICY_EL } from 'locales/policy/policy-el';
import { POLICY_EN } from 'locales/policy/policy-en';
import { POLICY_ES } from 'locales/policy/policy-es';
import { POLICY_FIL } from 'locales/policy/policy-fil';
import { POLICY_FR } from 'locales/policy/policy-fr';
import { POLICY_ID } from 'locales/policy/policy-id';
import { POLICY_JA } from 'locales/policy/policy-ja';
import { POLICY_KO } from 'locales/policy/policy-ko';
import { POLICY_MS } from 'locales/policy/policy-ms';
import { POLICY_NL } from 'locales/policy/policy-nl';
import { POLICY_PL } from 'locales/policy/policy-pl';
import { POLICY_PT } from 'locales/policy/policy-pt';
import { POLICY_TH } from 'locales/policy/policy-th';
import { POLICY_TL } from 'locales/policy/policy-tl';
import { POLICY_VI } from 'locales/policy/policy-vi';
import { POLICY_ZH } from 'locales/policy/policy-zh';
import { AccordionStyle } from 'pages/FAQ/FAQ';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import styled from "styled-components";
import { padZero } from 'utils/numberUtil';

export const handleFlowPolicy = (textBrand: string) => {
  switch (i18n.language) {
    case ELanguageLocales.vi:
      return POLICY_VI(textBrand!);

    case ELanguageLocales.en:
      return POLICY_EN(textBrand!);

    case ELanguageLocales.ar:
      return POLICY_AR(textBrand!);

    case ELanguageLocales.de:
      return POLICY_DE(textBrand!);

    case ELanguageLocales.el:
      return POLICY_EL(textBrand!);

    case ELanguageLocales.es:
      return POLICY_ES(textBrand!);

    case ELanguageLocales.fil:
      return POLICY_FIL(textBrand!);

    case ELanguageLocales.fr:
      return POLICY_FR(textBrand!);

    case ELanguageLocales.in:
      return POLICY_ID(textBrand!);

    case ELanguageLocales.ja:
      return POLICY_JA(textBrand!);

    case ELanguageLocales.ko:
      return POLICY_KO(textBrand!);

    case ELanguageLocales.ms:
      return POLICY_MS(textBrand!);

    case ELanguageLocales.nl:
      return POLICY_NL(textBrand!);

    case ELanguageLocales.pl:
      return POLICY_PL(textBrand!);

    case ELanguageLocales.pt:
      return POLICY_PT(textBrand!);

    case ELanguageLocales.th:
      return POLICY_TH(textBrand!);

    case ELanguageLocales.tl:
      return POLICY_TL(textBrand!);

    case ELanguageLocales.zh:
      return POLICY_ZH(textBrand!);

    default:
      return POLICY_EN(textBrand!);
  }
}

function PrivacyPolicy() {
  const { textContentBrand: { textBrand } } = useSelector(selectDesignHome);
  const history = useHistory();
  const { t } = useTranslation();
  const { paddingTopHeaderFixed, updateState } = useLayout();

  const [dataPolicy, setDataPolicy] = useState(handleFlowPolicy(textBrand! || 'Levitate'));
  const [selected, setSelected] = useState<any>({ id: 1, index: 1, ...dataPolicy[0] });
  const [expanded, setExpanded] = useState<any>();

  useEffect(() => {
    updateState({ isFloating: true });
    window.scrollTo(0, 0)
  }, [])

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : -1);
    };

  return (
    <>
      <ContentLayout $paddingTopHeaderFixed={paddingTopHeaderFixed}>
        <Stack
          columnGap={4}
          rowGap={{ lg: 0, xs: 4 }}
          flexDirection={{ lg: 'row', xs: 'column' }}
        >
          <Box>
            <Button
              startIcon={<ArrowBackIcon />}
              color="transparent"
              onClick={() => history.goBack()}
              className='button-back'
              style={{ fontSize: '16px' }}
            >
              {t('Back')}
            </Button>
          </Box>

          <Box>
            <Stack divider={<Divider orientation={'vertical'} flexItem />} flexDirection={{ md: 'row', xs: 'column' }} rowGap={{ md: 0, xs: 4 }} columnGap={4}>
              <Box className='column-left' >
                <h4 className='title'>{i18n.t('messages|privacyPolicy:Privacy Policy')}</h4>
                <Stack flexDirection={'column'} rowGap={2.5} columnGap={{ xs: 2.5, md: 0 }}>
                  {
                    dataPolicy?.map((item: { title: string, description: string }, index: number) => (
                      <Box key={item.title}>
                        <Box
                          component="div"
                          onClick={() => setSelected({ id: `${index + 1}`, ...item, index: index + 1 })}
                          className={`column-left__tab ${index + 1 === selected?.index ? 'active' : ''}`}
                          flex={1}
                          key={index}
                          display={{ md: 'flex', xs: 'none' }}
                        >
                          {index + 1}. {item.title}
                        </Box>
                        <Box display={{ md: 'none', xs: 'block' }}>
                          <AccordionStyle className='accodian' expanded={expanded === index} onChange={handleChange(index)}>
                            <AccordionSummary
                              expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Stack className='accodian-title' flexDirection="row">
                                <Box className="accordian-number">
                                  {padZero(index + 1)}
                                </Box>
                                <Box ml={1} className='accodian-title-des'>
                                  {item?.title}
                                </Box>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography dangerouslySetInnerHTML={{ __html: item?.description }} className='accodian-content-des'></Typography>
                            </AccordionDetails>
                          </AccordionStyle>
                        </Box>
                      </Box>
                    ))
                  }
                </Stack>
              </Box>
              <Box display={{ md: 'block', xs: 'none' }} className='column-right' flex={1}>
                <h4 className='title'>
                  {
                    `${selected?.index}. ${selected.title}`
                  }
                </h4>
                <div className="column-right__content" dangerouslySetInnerHTML={{ __html: selected?.description }}></div>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </ContentLayout>
      <PageFooter />
    </>
  );
}

export default PrivacyPolicy;

const ContentLayout = styled.section<{ $paddingTopHeaderFixed?: number; }>`
  padding: 40px 150px;
  min-height: calc(100vh - 64px);
  margin: ${props => props.$paddingTopHeaderFixed ? `${props.$paddingTopHeaderFixed}px auto 0` : "0 auto"};

  @media (max-width: 1280px){
    padding: 40px 40px;
  }

  @media (max-width: 1024px){
    padding: 20px 20px;
  }

  .wrapper{
    margin-top: 0 !important;
  }

  .button-back{
    white-space: nowrap;
  }
  .column-left{
    padding-top: 0px !important;

    &__tab{
      cursor: pointer;
      font-size: 15px;
      min-width: 300px;
      max-width: 300px;
      font-weight: 400;
      /* transition: all ease 2s; */

      @media (max-width: 1024px){
        min-width: auto;
        max-width: auto;
        white-space: nowrap;
      }

      &.active{
        color: ${p => p.theme.palette.accent.main};
        text-decoration: underline;
        font-weight: 500;
      }

      &:hover{
        text-decoration: underline;
        color: ${p => p.theme.palette.accent.main};
      }
    }

    .title{
      font-weight: 500;
      margin-bottom: 2rem;
      font-size: 25px;
    }
  }
  .column-right{
    padding-top: 0px !important;

    &__content{
      font-size: 15px;
      font-weight: 400;
      margin-left: 10px;
      a{
        color: ${p => p.theme.palette.accent.main};
        text-decoration: underline;

        &:hover{
          opacity: 0.8;
        }
      }
    }

    .title{
      font-weight: 500;
      margin-bottom: 2rem;
      font-size: 20px;
    }
  }

  .MuiAccordionDetails-root p{
    line-height: 1.4 !important;
  }
  .MuiAccordionDetails-root{
    padding: 10px 5rem 16px calc(-0.2rem + 32px) !important;
  }

  .button-back{
    height: 40px;

    @media (max-width: 768px){
      height: 50px;
    }
  }
`;