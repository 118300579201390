import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import locale from './locales.json';

// CONSTANTS
import env from 'constants/env';
import { SessionKey, StorageKey } from 'constants/storage';
import { Lang, formatLanguageDefault } from 'constants/i18n';

const {
  languages, defaultLanguage, namespaces, defaultNamespace,
  namespaceSeparator, keySeparator, pluralSeparator,
} = locale as {
  languages: Lang[],
  defaultLanguage: Lang,
  [name: string]: any,
};

const resources = {} as { [lang: string]: { [namespace: string]: any } };
languages.forEach((lang: string) => {
  resources[lang] = {};
  namespaces.forEach((namespace: string) => {
    const translations = require(`./${namespace}/${lang}.json`);
    resources[lang][namespace] = translations;
  })
});

export const saveLangCode = (lang: Lang) =>
  sessionStorage.setItem(SessionKey.Language, lang);

export const getLangCode = (): Lang => {
  // todo: only ENGLISH is available at the moment, remove this when all traslated
  // return defaultLanguage;
  
  // Return saved language code from localStorage
  let lang = formatLanguageDefault(sessionStorage.getItem(SessionKey.Language) as Lang || navigator.language as Lang) as Lang | null;

  if (lang && resources.hasOwnProperty(lang)) return lang;
  // If language code is not saved, guest language based on browser
  lang = navigator.language.split(/[-_]/)[0] as Lang | null;

  if (!lang || !resources.hasOwnProperty(lang)) {
    lang = defaultLanguage;
  }

  saveLangCode(lang);

  return lang;
}

const lang = getLangCode();

// console.debug('lang', { lang, resources });

i18n
  // detect user language. @see https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next. @see https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: env.ENV !== 'production',
    
    lng: lang,
    
    ns: namespaces,
    defaultNS: defaultNamespace,
    
    nsSeparator: namespaceSeparator,
    keySeparator: keySeparator,
    pluralSeparator: pluralSeparator,
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
