import { createTheme, Theme } from '@mui/material/styles';
import { css } from 'styled-components';

import func from './functions';

const theme = require('styles/_theme.scss');

// COLORS
export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type SizeMap<V = string> = {
  [size in Size]?: V;
};

interface ButtonStyle {
  color?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  hoverColor?: string;
}

const button: { [name: string]: ButtonStyle } = {
  primary: {
    color: theme.primaryText,
    backgroundColor: theme.defaultColor,
    hoverBackgroundColor: theme.defaultHoverColor,
  },
  secondary: {
    color: theme.secondaryText,
    backgroundColor: theme.secondaryColor,
    hoverBackgroundColor: theme.secondaryHoverColor,
  },
  accent: {
    color: theme.primaryText,
    backgroundColor: theme.accentColor,
    hoverBackgroundColor: theme.accentHoverColor,
  },
  ghost: {
    color: theme.primaryText,
    backgroundColor: 'transparent',
  },
  round: {
    color: theme.primaryText,
    backgroundColor: theme.primaryColor,
  },
  link: {
    color: theme.accentColor,
    hoverColor: theme.accentHoverColor,
  },
};

const typeCss = {
  normalItem: css`
    background: ${theme.primaryColor};
    color: ${theme.primaryText};
    &:hover {
      background: ${theme.accentHoverColor};
      color: ${theme.primaryText};
    }
  `,
  activeItem: css`
    background: ${theme.accentColor};
    color: ${theme.primaryText};
    font-weight: 500;
    &:hover {
      background: ${theme.accentHoverColor};
    }
  `,
  disabledItem: css`
    background: ${theme.primaryColor};
    color: ${theme.disabledText};
  `,
};

export const MEDIA_BREAKPOINT: SizeMap = {
  xs: `(min-width: ${theme.breakpointXs}px)`,
  sm: `(min-width: ${theme.breakpointSm}px)`,
  md: `(min-width: ${theme.breakpointMd}px)`,
  lg: `(min-width: ${theme.breakpointLg}px)`,
  xl: `(min-width: ${theme.breakpointXl}px)`,
};

type LayoutDimension = {
  headerHeight: string;
};

interface NeutralColor {
  primary?: string;
  secondary?: string;
  dark?: string;
  light?: string;
}

interface NeutralThemeColor {
  dark?: string;
  medium?: string;
  main?: string;
  light?: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    dark: string;
    light: string;
    accent: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    button: any;
    typeCss: any;
    func: any;
    dimension: LayoutDimension;
    
  }

  interface PaletteColor {
    mainHover?: string;
    mainDisabled?: string;
  }

  interface SimplePaletteColorOptions {
    mainHover?: string;
    mainDisabled?: string;
  }

  interface Palette {
    neutral: NeutralColor;
    neutralLight: NeutralThemeColor;
    neutralDark: NeutralThemeColor;
    accent: Palette['primary'];
    default: Palette['primary']; // default button
    transparent: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral: NeutralColor;
    neutralLight: NeutralThemeColor;
    neutralDark: NeutralThemeColor;
    accent: PaletteOptions['primary'];
    default: PaletteOptions['primary'];
    transparent: PaletteOptions['primary'];
  }

  interface ThemeOptions {
    button: any;
    typeCss: any;
    func: any;
    dimension: LayoutDimension;
  }
}

declare module '@mui/material/SvgIcon/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    accent: true;
  }
}

interface ThemeData {
  primaryMain: string;
  primaryDark: string;
  textBorderColor: string;
  textBorderColorLight: string;
  secondaryMain: string;
  secondaryLight: string;
  secondaryDark: string;
  defaultDark: string;
  defaultMain: string;
  defaultLight: string;
  defaultContrastText: string;
  accentMain: string;
  accentMainHover: string;
  accentMainDisabled: string;
  accentDark: string;
  // Add other theme properties as needed
}

// Color Icon Main
const customTheme = (themeData: ThemeData): Theme => {
  return createTheme({
    palette: {
      mode: 'dark',
      text: {
        primary: theme.primaryText,
        secondary: theme.secondaryText,
        dark: theme.darkText,
        light: theme.lightText,
        accent: theme.accentText,
        disabled: theme.disabledText,
      },
      default: {
        main: themeData.defaultMain || theme.defaultColor,
        light: themeData.defaultLight,
        dark: themeData.defaultDark,
        mainHover: theme.defaultHoverColor,
        contrastText: themeData.defaultContrastText,
      },
      transparent: {
        main: 'transparent',
        dark: 'transparent',
        light: 'transparent',
      },
      primary: {
        main: themeData?.primaryMain! || theme.primaryColor, // #4E4B55
        dark: themeData?.primaryDark! || theme.primaryDarkColor, // #3E3B45
        light: themeData?.textBorderColorLight || 'transparent',
        contrastText: themeData?.textBorderColor || 'transparent',
      },
      secondary: {
        main: themeData?.secondaryMain! || theme.secondaryColor,
        light: themeData?.secondaryLight,
        dark: themeData?.secondaryDark,
      },
      success: {
        main: theme.successColor,
        dark: theme.successDarkColor,
        light: theme.successLightColor,
      },
      warning: {
        main: theme.warningColor,
        dark: theme.warningDarkColor,
        light: theme.warningLightColor,
      },
      error: {
        main: theme.errorColor,
        dark: theme.errorDarkColor,
        light: theme.errorLightColor,
      },
      info: {
        main: theme.infoBlue,
        dark: theme.infoBlue,
      },
      neutral: {
        primary: theme.neutralPrimaryColor,
        dark: theme.neutralDarkColor,
        light: theme.neutralLightColor,
      },
      neutralDark: {
        main: theme.neutralDrkGeneral,
        dark: theme.neutralDrkDark,
        medium: theme.neutralDrkMedium,
        light: theme.neutralDrkLight,
      },
      neutralLight: {
        main: theme.neutralLgtGeneral,
        dark: theme.neutralLgtDark,
        medium: theme.neutralLgtMedium,
        light: theme.neutralLgtLight,
      },
      accent: {
        main: themeData?.accentMain || theme.accentColor,
        mainHover: themeData?.accentMainHover || theme.accentHoverColor,
        mainDisabled: themeData?.accentMainDisabled || theme.accentDisabledColor,
        dark: themeData?.accentDark || theme.accentDarkColor,
        light: theme.accentLightColor,
      },
      action: {
        active: theme.activeColor,
        selected: theme.accentColor,
        focus: theme.accentColor,
      },
    },
    breakpoints: {
      values: {
        xs: +theme.breakpointXs,
        sm: +theme.breakpointSm,
        md: +theme.breakpointMd,
        lg: +theme.breakpointLg,
        xl: +theme.breakpointXl,
      },
    },
    typography: {
      fontFamily: theme.fontFamilyBase,
      fontWeightBold: 600,
      h1: {
        fontSize: '3rem', // 48px
        fontWeight: 600,
        lineHeight: '4rem',
      },
      h2: {
        fontSize: '2.5rem', // 40px
        fontWeight: 600,
        lineHeight: '3rem',
      },
      h3: {
        fontSize: '2rem', // 32px
        fontWeight: 600,
        lineHeight: '2.5rem',
      },
      h4: {
        fontSize: '1.5625rem', // 25px
        fontWeight: 600,
        lineHeight: '2rem',
      },
      h5: {
        fontSize: '1.25rem', // 20px
        fontWeight: 600,
        lineHeight: '1.75rem',
      },
      h6: {
        fontSize: '1rem', // 16px
        fontWeight: 600,
        lineHeight: '1.25rem',
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
      },
      subtitle2: {
        fontSize: '0.8125rem',
        fontWeight: 500,
        lineHeight: '1.25rem',
      },
      body1: {
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
      },
      body2: {
        fontSize: '0.625rem',
        fontWeight: 400,
        lineHeight: '1rem',
      },
    },
    shape: {
      borderRadius: 0,
    },
    components: {
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            fontSize: '.875rem',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: 'inherit',
            },
          },
          underlineNone: {
            '&:hover': {
              textDecoration: 'none',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          fullWidth: {
            // width: '100%',
          },
        },
      },
    },
    dimension: {
      headerHeight: '3.75rem',
    },
    button,
    typeCss,
    func,
  });
};

export default customTheme;
