export const POLICY_FIL = (textBrand: string) => {
  return [
    {
      title: 'Introduksyon',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>tayo</strong>", "<strong>atin</strong>" o "<strong>${textBrand}</strong>"), ang operator ng <a href="https://levitate.homes">https://levitate.homes</a> (ang "<strong>Website</strong>"), at kami ay nakatuon sa pagpapangalaga sa iyong Personal na Data (na tinukoy sa Clause 2 sa ibaba) alinsunod sa mga termino at kundisyon dito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Ang Patakaran sa Pagkapribado na ito (kasama ang aming Mga Tuntunin ng Paggamit at anumang iba pang mga dokumento na tinutukoy dito) ay naglalayong ipakita kung paano gagamitin, ipoproseso, o ipapahayag namin ang Personal na Data na aming kinolekta mula sa iyo, o na iyong ibinigay sa amin, kabilang kung paano gagamitin ang ilang impormasyon upang personalisin ang mga patalastas na inuulat sa iyo. Isinasalarawan din ng Patakaran sa Pagkapribado na ito ang mga uri ng Personal na Data na aming kinokolekta, kung paano namin ito pinapanatili sa ligtas na paraan, pati na rin kung paano mo maaaring ma-access o baguhin ang Personal na Data na naka-imbak sa amin. Ang Patakaran sa Pagkapribado na ito ay tumutukoy sa iyong paggamit sa Website, at anumang ibang paraan kung saan maaari kang kumonekta o makipag-ugnayan sa amin sa pamamagitan ng paggamit ng aming mga produkto at serbisyo (sa kabuuan, tinutukoy dito bilang ang "<strong>Mga Serbisyo</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Mangyaring basahin nang mabuti ang sumusunod upang maunawaan ang aming patakaran at kasanayan hinggil sa iyong Personal na Data at kung paano namin ito itratrabaho.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div{ Barring any restrictions imposed by applicable law, sa paggamit ng Website at Mga Serbisyo, sumasang-ayon ka na ang anumang at lahat na Personal na Data na nauugnay sa iyo na kinolekta namin o ibinigay mo sa amin mula sa panahon hanggang sa panahon ay maaaring gamitin, iproseso, at ihayag para sa mga layunin at mga tao na nakasaad sa Patakaran sa Pagkapribado na ito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Bilang bahagi ng aming mga pagsisikap na masiguro na tama namin na pinangangalagaan, inaayos, at ipinoproseso ang iyong Personal na Data, regular kaming mag-uulat at magsusuri sa aming mga patakaran, proseso, at proseso. Maaari naming baguhin ang Patakaran sa Pagkapribado na ito anumang oras sa pamamagitan ng pagpo-post ng isang nabago at muling bersyon sa Website. Maaring kami ay magpupumilit na magpadala ng abiso kapag binago ang patakaran, gayunpaman, ituturing kang nakakilala at pumapayag sa nabagong Patakaran sa Pagkapribado kung patuloy kang gumagamit ng Website o Mga Serbisyo pagkatapos ng pagkakapagbagong-buhay, alinsunod kung natanggap mo man ang abiso o hindi. Ipinapaalala ka na suriin nang paminsan-minsan ang pahinang ito para sa mga update sa Patakaran sa Pagkapribado na ito.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      title: 'Pagtitipon ng Personal na Datos',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                Pwedeng mag-browse sa Website at gamitin ang limitadong mga function nito nang hindi inilalantad ang iyong Personal na Datos. Hindi mo kinakailangang magbigay ng Personal na Datos para gamitin ang Website, maliban kung kailanganin ito upang maibigay namin ang mga Serbisyo na binibili mo o ina-access sa pamamagitan ng aming Website, tugunan ang iyong mga kahilingan, o sa mga kaso kung diretso mo kaming kinokontak.
              </div>
              <div>
                Kapag ginagamit mo ang aming mga Serbisyo, kinokolekta namin ang iba't ibang impormasyon tungkol sa iyo, iyong mga aparato, at ang iyong pakikisalamuha sa mga Serbisyo. Ang ilan sa impormasyong ito ay tuwirang nagpapakilala sa iyo o maaaring gamitin upang nang bahagya kang makilala kapag pinagsama-sama sa iba pang data. Anumang data, tama man o hindi, tungkol sa isang indibidwal na maaaring makilala mula sa data na iyon; o mula sa data na iyon at iba pang impormasyon na mayroon o malamang na mayroon ang organisasyon, ay itinuturing na personal na datos ("<strong>Personal na Datos</strong>").
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.1.</div>
                  <div>
                    <strong>Impormasyong kusang ibinibigay mo</strong>. Kapag gumagamit ka ng aming mga Serbisyo, maaaring hingin sa iyo na magbigay ng personal na impormasyon tungkol sa iyo, tulad ng iyong pangalan, impormasyon sa contact, impormasyon sa pagbabayad, mga detalye tungkol sa iyong tahanan o mga ari-arian na nais mo, impormasyon tungkol sa pinansya, iyong mga paborito, o iyong mga istilo. Maaaring mangyari ito, halimbawa:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Kapag nagtutulutan ka ng mga transaksyon sa amin sa o kaugnay sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Kapag nagrerehistro ka ng isang account sa amin sa Website;;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Kapag kumokontak ka sa aming serbisyo sa kustomer o mga tauhan sa pamamagitan ng email, telepono, o personal na pakikipag-usap, upang malutas ang anumang problema na iyong kinakaharap o may mga katanungan o kahilingan;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Kapag sumusubscribe ka sa mga serbisyo na ibinibigay namin sa Website, o sa aming mga email notifications, at/o newsletters;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Kapag nagbibigay ka o iniwan mo ang kaugnay na input, tugon, impormasyon sa Website para sa layunin ng paggamit ng mga Serbisyo;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Kapag nakikipag-ugnayan ka sa isang propesyonal sa real estate (tulad ng isang ahente o broker sa real estate, nagpapautang o opisyal ng pautang, tagapamahala ng ari-arian, mamumuhunan, tagagawa ng bahay, o iba pa) sa pamamagitan ng mga Serbisyo; at</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g. </div>
                        <div>Kapag nagkumpleto ka ng iba pang mga form o transaksyon, tulad ng kahilingan para sa impormasyon sa pautang, aplikasyon para sa background check (kung mayroon).</div>
                      </div>
                    </div>
                    <div>
                      Maaari ka ring magbigay ng impormasyon tungkol sa isang ikatlong partido sa pamamagitan ng mga Serbisyo, halimbawa, kung ibinahagi mo ang isang listahan ng real estate sa isang tatanggap sa pamamagitan ng email o SMS. Sa pagbibigay ng gayong impormasyon sa amin, iginagarantiya mo sa amin na nakakuha ka ng pahintulot ng ikatlong partido na ibigay sa amin ang kanilang Personal na Datos para sa mga layunin na nakasaad sa Patakaran sa Pagkapribado na ito.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.2.</div>
                  <div>
                    <strong>Mga Cookies, Pixels, at iba pang mga mekanismo ng pagtutugma</strong>. Kami at ang aming mga kasosyo ay gumagamit ng iba't ibang teknolohiya upang awtomatikong kolektahin ang impormasyon kapag binubuksan at ginagamit mo ang aming mga Serbisyo, kabilang ang mga cookies at iba pang katulad na teknolohiya. Ang mga cookies ay mga pirasong impormasyong elektroniko na maaaring ilipat sa iyong computer o iba pang electronic device upang maikilala nang tumpak ang iyong browser. Kapag ginagamit mo ang mga Serbisyo, maaaring ilagay ng aming mga kasosyo at kami ang isa o higit pang mga cookies sa iyong computer o iba pang electronic device o gamitin ang iba pang mga teknolohiyang nagbibigay ng katulad na kakayahan.
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Maaaring gamitin namin at ang aming mga kasosyo ang mga cookies upang kumonekta ang iyong aktibidad sa mga Serbisyo sa ibang impormasyong aming iniimbak tungkol sa iyo sa iyong account profile o mga nakaraang pakikipag-ugnayan mo sa aming mga Serbisyo, halimbawa, pagtago ng iyong mga kagustuhan. Ang paggamit ng cookies ay tumutulong sa amin na mapabuti ang kalidad ng aming mga Serbisyo sa iyo, sa pamamagitan ng pagkilala sa impormasyon na pinaka-interesado sa iyo, pagsubaybay sa mga trend, pagmamasa ng epektibong pagsasagawa ng mga patalastas, o pag-iimbak ng impormasyon na maaaring nais mong i-retrieve nang madalas, tulad ng iyong mga piling-ari-arian o mga hinahanap, at iba pang mga pagkilos na pinapayagan ng naaangkop na batas. Sa anumang oras, maaaring ayusin mo ang mga setting sa iyong browser upang tanggihan ang mga cookies ayon sa mga tagubilin kaugnay ng iyong browser. Gayunpaman, kung pipiliin mong hindi paganahin ang mga cookies, maaaring hindi mabuo nang maayos ang karamihan sa mga libreng bahagi ng mga Serbisyo.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Maaring maglaman din ang ilang mga pahina namin ng mga pixels, na mga elektronikong file para bilangin ang mga user na bumisita sa pahinang iyon, subaybayan ang aktibidad sa loob ng panahon at sa iba't ibang mga website, tukuyin ang mga interaksyon ng mga user sa mga email na aming ipinadala, tukuyin ang ilang mga cookies sa computer o iba pang electronic device na nag-aaccess sa pahinang iyon, o kolektahin ang iba pang kaugnay na impormasyon, at maaaring maiugnay ito sa iyong natatanging browser, identifier ng aparato, o IP address. Halimbawa, maaaring magpatupad kami ng isang pixel sa partikular na mga Serbisyo kung saan makikita mo ang tiyak na listahan upang matukoy namin kung ang listahang iyon ay may kaugnayan sa iyo habang inaalok ang mga rekomendasyon. Sa pamamagitan ng mga pixel at cookies na ito, kinokolekta namin ang impormasyon tungkol sa iyong paggamit ng aming mga Serbisyo, kabilang ang uri ng iyong browser, oras ng pag-access, mga pahinang ni-view, ang iyong IP address, at ang pahinang binisita mo bago pumunta sa aming mga Serbisyo.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Kinokolekta rin namin ang impormasyon tungkol sa computer o mobile device na iyong ginagamit upang mag-access sa aming mga Serbisyo, tulad ng modelong hardware, operating system at bersyon nito, natatanging identifier ng aparato, impormasyon ng mobile network, at pag-uugali sa pag-browse.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Pinapayagan din namin ang ilang partikular na ikatlong partido na kolektahin ang impormasyon tungkol sa iyong mga online na aktibidad sa pamamagitan ng mga cookies at iba pang teknolohiya kapag ginagamit mo ang Website. Kasama sa mga ikatlong partido na ito (a) mga business partner, na nakokolekta ang impormasyon kapag ikaw ay nakakita o nakikipag-ugnayan sa isa sa kanilang mga patalastas sa Website, at (b) mga advertising network, na nakokolekta ang impormasyon tungkol sa iyong mga interes kapag ikaw ay nakakita o nakikipag-ugnayan sa isa sa mga patalastas na inilagay nila sa maraming iba't ibang website sa Internet. Ang impormasyong kinokolekta ng mga ikatlong partido na ito ay hindi makikilalang impormasyon na ginagamit upang gumawa ng mga prediksyon tungkol sa iyong mga katangian, interes, o mga nais, at upang ipakita ang mga patalastas sa Website, at sa buong Internet, na naaayon sa iyong mga interes.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Maaaring pamahalaan mo ang uri ng mga cookies na pinapahintulutan sa pamamagitan ng iyong mga setting sa browser, kabilang ang kumpletong pag-block ng lahat ng mga cookies kung iyon ang nais mo. Para sa impormasyon tungkol sa pagpapamahala sa mga cookies sa iyong browser, mangyaring tingnan ang seksyon ng tulong ng browser na iyong ginagamit. </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.3.</div>
                  <div>
                    <strong>Impormasyon tungkol sa Mobile Device at Mobile Browser.</strong> Maaring ayusin mo ang mga setting sa iyong mobile device at mobile browser kaugnay ng mga cookies at pagbabahagi ng ilang impormasyon, tulad ng modelong gamit mo sa mobile device o ang wika na ginagamit ng mobile device mo, sa pamamagitan ng pag-aayos ng mga setting sa privacy at seguridad sa iyong mobile device. Mangyaring tumukoy sa mga tagubilin na ibinigay ng iyong mobile service provider o tagagawa ng mobile device.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.4.</div>
                  <div>
                    <strong>Impormasyon tungkol sa Lokasyon.</strong> Kung pinagana mo ang location services sa iyong mobile device, kami, sa iyong pahintulot, ay maaaring kolektahin ang lokasyon ng iyong aparato na ginagamit namin upang magbigay sa iyo ng impormasyong nakabase sa lokasyon at mga patalastas. Kung nais mong hindi paganahin ang tampok na ito, maaari mong i-disable ang location services sa iyong mobile device.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.5.</div>
                  <div>
                   <strong>Pangangasiwa ng Email.</strong> Maaari kang tumanggap ng mga email mula sa amin para sa iba't ibang dahilan - halimbawa, kung kumilos ka sa pamamagitan ng mga Serbisyo, nag-subscribe ka sa regular na report, o kumumpleto ng iyong disenyo, at nagpapadala kami sa iyo ng mga mensahe upang sumunod sa mga susunod na hakbang. Kung may account ka sa amin, maaaring i-edit mo ang iyong mga kagustuhan sa pamamagitan ng iyong mga setting sa account. Bukod pa rito, maaari mong pamahalaan ang pagtanggap ng ilang uri ng komunikasyon sa pamamagitan ng pagtupad sa mga tagubiling kasama sa email na ipinadala namin sa iyo. Paki-tandaan na kahit mag-unsubscribe ka sa ilang mga email correspondence, maaaring kailanganin pa rin naming ipadala sa iyo ang mga email na may kinalaman sa importanteng transaksyon o administratibong impormasyon.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.6.</div>
                  <div>
                    <strong>Pagpapahayag ng Hindi Pananagutan.</strong> Hindi kami mananagot para sa anumang kusang pagpapahayag ng Personal na Datos mo sa ibang mga user kaugnay ng paggamit ng Website, kung mayroon man.
                  </div>
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Layunin ng Paggamit',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    Ang Personal na Data na ibinibigay mo ay maaaring gamitin at isagawa ng amin (o ng mga ikatlong partido (tulad ng Google Analytics) para sa amin) nang agregado o indibidwal at maaaring pagsamahin sa ibang impormasyon para sa layunin ng pagpapatakbo at pagpapatakbo ng Website, at maaaring isama ngunit hindi limitado sa mga sumusunod:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>Pangangasiwa ng Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>Pagpapabuti ng iyong karanasan sa pag-browse sa pamamagitan ng personalisasyon ng Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Pagpapaganap ng mga Serbisyo na magagamit sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Pagbibigay sa iyo ng mga Serbisyo na iyong espesyal na hiningi;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Pagtugon sa iyong mga katanungan o kahilingan ayon sa iyong mga email at/o pagsusumite ng form (s) sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Pagtugon at pagsunod-sunod sa mga reklamo tungkol sa paggamit ng Website o anumang Serbisyo na ibinigay ng amin;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>Pagpapadala ng pangkalahatang komersyal na komunikasyon sa pamamagitan ng anumang channel ng komunikasyon, hindi limitado sa email, tawag sa boses, SMS, at app notifications;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>Pagpapatupad ng mga opisyal at operasyonal na gawain sa administrasyon;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>Pagbibigay ng estadistikong impormasyon sa mga ikatlong partido tungkol sa aming mga user. Ang estadistikong impormasyong ito ay maaaring gamitin upang personalisahin ang advertising sa Website, ngunit hindi magagamit upang kilalanin ang anumang indibidwal na user;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>Paglikha at pamamahala sa iyong online na account;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>Pagpapadali sa iyong mga aktibidad at transaksyon sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>Pagpapadali sa iyong pakikilahok sa mga paligsahan o promosyon;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>Pagpapatakbo sa pagbabayad, o pagbabalik ng bayad mula o sa iyo;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>Pagbibigay sa iyo ng impormasyon tungkol sa mga Serbisyo na hinihiling mo sa amin; o batay sa iyong pahintulot, ang impormasyong ito na aming itinuturing na maaaring magkaroon ng interes sa iyo sa pamamagitan ng email o text message;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>Pagpapatakbo, pagsusuri, at pagpapabuti sa aming negosyo (kasama na ang pagbuo ng bagong mga produkto at serbisyo; pagsusuri at pagsasaayos ng aming mga Serbisyo);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>Pag-unawa sa aming mga customer at paglakip ng data analysis at iba pang mga proseso (kasama ang market at consumer research, trend analysis, at anonimisasyon);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>Pagtugon sa iyong mga komento o tanong o aplikasyon para sa trabaho (kung mayroon);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>Pagbahagi sa mga tagapagbigay ng serbisyo upang maaari nilang magbigay ng mga Serbisyo sa iyo; at ayon sa ibang kailangan o pinapahintulutan ng batas.</div>
                      </div>
                    </div>
                  </div>
                </div>
                Maaari kang pumili na mag-browse sa Website at gamitin ang limitadong mga function nito nang hindi ibinubunyag ang iyong Personal na Data. Hindi mo kailangang magbigay ng Personal na Data bilang kundisyon sa paggamit ng Website, maliban kung kinakailangan ito para sa amin upang magkaroon ng kakayahan na magbigay ng mga Serbisyo na binili mo o kinahanggan mo sa pamamagitan ng aming Website, tugunan ang iyong mga kahilingan, o sa mga kaso kung saan ka direktang nakipag-ugnayan sa amin.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>Dahil ang mga layunin para sa mga maaaring/kailangang kolektahin, gamitin, ibunyag o isagawa namin sa iyong Personal na Data ay nakasalalay sa mga kalakaran sa kasalukuyan, maaaring hindi nakalista ang mga layunin na ito sa itaas. Gayunpaman, ipaalam namin sa iyo ang mga ibang layunin na ito sa oras ng pagkuha ng iyong pahintulot, maliban kung ang pagproseso ng iyong Personal na Data nang walang iyong pahintulot ay pinahihintulutan ng Personal Data Protection Act 2012 ng Singapore ("<strong>PDPA</strong>") o ng anumang ibang naaangkop na batas.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>Mga Patakaran sa Hindi Tumawag.</strong> Kung ibinigay mo sa amin ang iyong numero ng telepono (ang "<strong>Numero</strong>") at ipinahiwatig mo na sumasang-ayon ka sa pagtanggap ng impormasyon sa marketing o iba pang promotional na impormasyon sa pamamagitan ng iyong Numero, maaari naming makipag-ugnayan sa iyo gamit ang mga Numero na iyon paminsan-minsan, na may impormasyon tungkol sa aming mga Serbisyo kahit na ang mga Numero na ito ay rehistrado sa Do Not Call Registry (kung meron, ayon sa iyong kaukulang hurisdiksyon). Gayunpaman, maaari mong ipaalam sa amin sa pagsulat kung hindi mo nais na makipag-ugnayan kami sa iyo sa iyong mga Numero para sa mga layunin na ito.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Kasiguraduhan at Kaligtasan',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Siguraduhin mong kumpleto, tama, totoo, at wasto ang lahat ng Personal na Data na ipinasa mo sa amin. Ang pagkukulang sa iyong bahagi na gawin ito ay maaaring magresulta sa hindi namin pagkakasundo na ibigay sa iyo ang Mga Serbisyo na iyong hiningi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Gagawin namin ang makakaya namin upang tiyakin na ang iyong Personal na Data ay tama at kumpleto kung ang iyong Personal na Data ay malamang na gagamitin namin upang gumawa ng desisyon na nakakaapekto sa iyo o ipakita sa ibang organisasyon. Gayunpaman, ibig sabihin nito ay dapat din naming i-update mo kami sa anumang mga pagbabago sa iyong Personal na Data na iyong unang ibinigay sa amin. Hindi kami mananagot para sa pagtitiwala sa maling o hindi kumpletong Personal na Data na nagmumula sa hindi pag-update mo sa amin sa anumang mga pagbabago sa iyong Personal na Data na iyong unang ibinigay sa amin.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Gagawin namin ang mga makatuwirang pagsisikap upang maiwasan ang pagkawala, pag-abuso, o pagbabago ng iyong Personal na Data.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Iimbak namin ang lahat ng Personal na Data na iyong ibinigay sa ligtas na mga server sa isang nakakodig na format.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Gayunpaman, nauunawaan mo na ang pagpapadala ng impormasyon sa pamamagitan ng Internet ay hindi lubos na ligtas. Bagaman gagawin namin ang aming makakaya upang protektahan ang iyong Personal na Data, hindi namin maaaring garantiyahin ang seguridad ng iyong Personal na Data na ipinadala sa Website; anumang pagpapadala ay nasa iyong sariling panganib. Bukod dito, hindi namin maaaring tanggapin ang pananagutan para sa anumang hindi awtorisadong paggamit ng iyong Personal na Data ng mga ikatlong partido na lubos na maaaring maipataw sa mga kadahilanan na nasa labas ng aming kontrol.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Kung saan kami ay nagbigay sa iyo (o kung ikaw ay pumili) ng isang password na nagpapahintulot sa iyo na ma-access ang tiyak na mga bahagi ng Website, ikaw ay responsable na panatilihing lihim ang password na ito. Hinihiling namin sa iyo na hindi ibahagi ang password sa sinuman. HINDI kami magtatanong sa iyo ng iyong password maliban kapag ka mag-log in sa Website, kung mayroon man.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Pinapaalalahanan ka namin na gumawa ng mga hakbang upang mapanatili ang kaligtasan ng iyong personal na impormasyon, tulad ng pagpili ng isang matatag na password at pananatiling pribado nito, pati na rin ang pag-log out mula sa iyong user account at pag-isara ng iyong web browser kapag natapos ka nang gumamit ng aming Mga Serbisyo sa isang inuupahang o hindi ligtas na aparato.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Paglantad ng Personal na Impormasyon',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Hindi namin ipapahayag ang iyong Personal na Impormasyon sa mga ikatlong partido para sa mga layuning direktang marketing nang hindi nagbibigay ng iyong malinaw na pahintulot. Ang pahintulot para sa paglantad ng Personal na Impormasyon sa mga ikatlong partido para sa mga layuning makatanggap ng marketing na komunikasyon ay ibinibigay sa pamamagitan ng aming form ng rehistro, maging ito ay online o sa papel.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Upang pamahalaan at paandarin ang Website, maaari naming ipahayag ang iyong Personal na Impormasyon sa anumang kasapi ng aming grupo, kabilang ang aming mga kaanib at subsidiariya. Maaari rin naming ipahayag ang iyong Personal na Impormasyon sa mga ikatlong partido, tulad ng aming mga nagbibigay ng serbisyo at propesyonal na mga tagapayo, para sa mga sumusunod na layunin:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Para sa anumang layunin na pinapayagan ng alinmang umiiral na batas o regulasyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Upang tuparin ang anumang legal na pangangailangan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Upang ipatupad o isagawa ang aming Mga Tuntunin ng Paggamit;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Upang protektahan ang aming mga karapatan, pag-aari, at kaligtasan, pati na rin ang mga karapatan, pag-aari, at kaligtasan ng aming mga kaanib at subsidiariya, iba pang mga gumagamit ng Website, at iba pang mga tao;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Patungkol sa anumang legal na proseso o potensyal na legal na proseso; at</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Patungkol sa isang transaksyon ng mga ari-arian ng negosyo (halimbawa, ang pagbili, pagbebenta, pag-upa, pagkakasama-sama o pagkakasama-sama o anumang iba pang pagbili, pag-aari, o pagpopondo ng isang organisasyon o bahagi ng organisasyon o ng anumang mga negosyo o ari-arian ng isang organisasyon) kung saan tayo ay isa o magiging isa sa mga partido o interesadong partido.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Hinding-hindi rin namin ipapahayag ang iyong Personal na Impormasyon sa mga ikatlong partido nang walang una mong pahintulot para sa paggawa nito. Gayunpaman, tandaan na maaari naming ipahayag ang iyong Personal na Impormasyon sa mga ikatlong partido nang walang una mong pahintulot sa ilang sitwasyon, kasama na rito ang mga sumusunod:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Mga sitwasyon kung saan ang paghahayag ay kinakailangan o pinapayagan batay sa mga umiiral na batas at/ o regulasyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Mga sitwasyon kung saan ang layunin ng paghahayag ay malinaw na para sa iyong kapakanan, at kung hindi maaaring makuha ang pahintulot sa tamang panahon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Mga sitwasyon kung saan ang paghahayag ay kinakailangan upang tugunan ang anumang emerhensiya na nagbabanta sa buhay, kalusugan, o kaligtasan mo o ng ibang tao;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Mga sitwasyon kung saan ang paghahayag ay kinakailangan para sa anumang imbestigasyon o proseso ng hukuman;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Mga sitwasyon kung saan ang Personal na Impormasyon ay ibinubunyag sa anumang opisyal ng isang preskripsiyong ahensiya ng pagsasakatuparan ng batas;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Mga sitwasyon kung saan ang paghahayag ay para sa isang ahensiya ng gobyerno at kinakailangan ang paghahayag na ito sa pangkalahatang kapakanan; at/o</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Kung ang gayong paghahayag nang hindi mo pahintulutan ay pinapayagan ng PDPA o ng batas.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Kapag ipinapahayag namin ang iyong Personal na Impormasyon sa mga ikatlong partido nang hindi mo pahintulutan, gagawin namin ang lahat ng aming makakaya upang hilingin sa mga ikatlong partido na pangalagaan ang iyong Personal na Impormasyon.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Pag-atras ng Pahintulot',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Maaari mong bawiin ang iyong pahintulot sa pamamagitan ng pakikipag-ugnayan sa aming serbisyo sa customer sa <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Pakipansin na kung bawiin mo ang iyong pahintulot sa anumang o lahat ng paggamit ng iyong Personal na Datos, depende sa kalikasan ng iyong kahilingan, maaaring hindi kami makapagpatuloy sa pagbibigay ng aming mga Serbisyo sa iyo. Sa iyong abiso, ipaalam namin sa iyo ang mga malamang na kahihinatnan ng pag-atras ng pahintulot, kabilang ngunit hindi limitado sa pagtatapos ng anumang kasunduan na maaaring mayroon ka sa amin at ang iyong paglabag sa iyong mga obligasyon o pag-aayos sa kontrata. Ang aming mga legal na karapatan at lunas sa gayong pangyayari ay malinaw na itinatakdang iniwang bukas.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Pandaigdigang Paglipat ng Data',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Nagtatrabaho kami sa maraming bansa at maaaring pansamantalang magimbak, magproseso, o ilipat ang iyong Personal na Datos sa pagitan ng anumang mga bansa na kung saan kami nag-ooperate upang bigyan kami ng kakayahang gumamit ng iyong Personal na Datos alinsunod sa Polisiya sa Privacy na ito at sa aming Mga Tuntunin ng Paggamit.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Gagawin namin ang lahat ng mga kaukulang hakbang upang tiyakin na ang iyong Personal na Datos ay ililipat alinsunod sa anumang nalalapat na batas o regulasyon at gagawin namin ang lahat ng karampatang hakbang upang tiyakin na ang anumang dayuhang tatanggap ng iyong Personal na Datos ay binded sa legal na mabigat na mga obligasyon na magbigay ng proteksyon na hindi bababa sa proteksyon sa ilalim ng PDPA.</div>
              </div>
          </div>
      `
    },
    {
      "title": "Third Party Website",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  Maaaring maglaman ang Website mula sa panahon hanggang panahon ng mga link papunta at mula sa mga website ng aming mga katuwang na network at vendor. Kung susundan mo ang isang link patungo sa alinman sa mga website na ito, pakitandaan na mayroon silang sariling mga patakaran sa pagkapribado at hindi kami tumatanggap ng anumang pananagutan para sa mga patakaran na ito. Pakisuri ang mga patakaran na ito bago mo isumite ang anumang Personal na Data sa mga website na ito.
                </div>
              </div>
            `
    },
    {
      "title": "Access, Pagbura, at Koreksyon",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>Ang Aming Data Protection Officer ay responsable sa pagtiyak na ang mga kahilingan para sa access, pagbura, o pagbabago ng Personal na Data ay wastong pinapamamahalaan ayon sa mga itinakdang proseso dito.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>Ang lahat ng mga kahilingan para sa impormasyon o mga kahilingan para sa pagbabago o pagbura ng personal na data o pagbura ng personal na data na itinago namin ay gagawin sa pamamagitan ng pagsumite ng kahilingan sa aming Data Protection Officer sa: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>Pakitandaan na tatanggapin at aaksyunan lamang namin ang mga kahilingan sa wikang Ingles na sumusunod sa Privacy Policy na ito. Ang anumang kahilingang hindi sumusunod sa itaas ay ituturing na ibinasura, at hindi kami gagawa ng anumang hakbang kaugnay ng mga ibinasurang kahilingan.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>Maaari kaming tumanggi na ibunyag, burahin, o baguhin ang anumang Personal na Data batay sa mga dahilan na nakasaad sa PDPA.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>Hangga't maaari, aming sasagutin ang iyong kahilingan sa loob ng sampung (10) araw na pantrabaho mula sa petsa ng pagtanggap ng kahilingan. Kung hindi ito posible, ikaw ay iuulat dito sa loob ng sampung (10) araw na pantrabaho mula sa petsa ng pagtanggap ng kahilingan.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Retention ng iyong Personal na Data",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Itinatagal namin ang mga Personal na Data na aming kinokolekta mula sa iyo kung mayroon kaming patuloy na lehitimong pangangailangan sa negosyo upang gawin ito. Kung ikaw ay isang kliyente o vendor (o kinatawan ng isang kliyente o vendor), ang impormasyon ay itatagal para sa isang panahon upang bigyan kami ng pagkakataon na magbigay ng aming mga Serbisyo, upang sumunod sa mga nararapat na legal, buwis, o accounting na pangangailangan at, kung kinakailangan, para sa pagtatatag, pagpapatupad, o pagtanggol ng mga legal na kahilingan.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Kapag wala kaming patuloy na lehitimong pangangailangan sa negosyo na proseso ang iyong personal na impormasyon, ipapatanggal o i-aanonymize namin ito o, kung hindi ito posible (halimbawa, dahil ang iyong personal na impormasyon ay nakaimbak sa mga backup archive), kung gayon ay aming ligtas na itatago ang iyong personal na impormasyon at iihiwalay ito mula sa anumang karagdagang proseso hanggang sa maaari na itong tanggalin.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Makipag-ugnayan sa Amin",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      Kung kailangan mo ng karagdagang impormasyon tungkol sa pagtrato sa iyong Personal na Data sa amin, huwag kang mag-atubiling makipag-ugnay sa aming Data Protection Officer sa: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "Pamamahala ng Batas",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  Ang Privacy Policy na ito ay pamamahalaan sa lahat ng aspeto ng mga batas ng Singapore.
                  </div>
                </div>
              `
    }
  ]
};
