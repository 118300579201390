import React from 'react';

const ArabicOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41202)">
        <path
          d="M5.0001 12.8932V7.10786C6.52575 7.10067 8.05191 7.10786 9.57777 7.10449C9.61972 7.10449 9.63616 7.11113 9.63556 7.15361C9.63051 7.36465 9.63919 7.57387 9.63152 7.7851H5.85607V9.56047H9.38836V10.2409H5.85556V12.2126H9.78613C9.78613 12.4205 9.78613 12.6259 9.78613 12.8336C9.78613 12.8732 9.76452 12.893 9.72128 12.8931C8.14742 12.8931 6.57386 12.893 5 12.8933L5.0001 12.8932Z"
          fill="white"
        />
        <path
          d="M11.1234 9.69883C10.7192 8.77316 10.8305 7.52702 12.0122 7.15033C13.2629 6.71716 14.6455 7.23575 14.8375 8.50417C14.5703 8.54029 14.3124 8.57513 14.0532 8.61015C13.9209 7.25531 11.6674 7.23866 11.7494 8.66746C11.7446 8.78508 11.9194 9.73749 12.0461 9.69892C12.4859 9.69892 12.9246 9.69892 13.3644 9.69892V10.281H12.1411C12.3973 10.9526 11.9333 11.6219 11.4495 12.1088C12.7204 11.7955 13.5815 12.6535 14.7398 12.077C14.8264 12.2952 14.9123 12.5111 15 12.7319C13.2134 13.547 12.5813 11.953 10.6727 13C10.5781 12.7911 10.4868 12.5779 10.3914 12.369C10.3759 12.3348 10.3838 12.3201 10.4179 12.3021C11.1907 11.9198 11.5497 11.073 11.3215 10.3138C11.3128 10.2814 11.291 10.2834 11.2656 10.2834C10.9914 10.2834 10.7198 10.2834 10.4453 10.2834C10.445 10.1026 10.4468 9.92606 10.4443 9.74586C10.4438 9.70701 10.4581 9.69773 10.4989 9.69819C10.7081 9.7001 10.9137 9.69864 11.1234 9.69883Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41202">
          <rect width="10" height="6" fill="white" transform="translate(5 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArabicOutlined;
