import Collection from 'model/collection';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type CollectionState = {
  collections: Collection[];
};

const initCollectionState: CollectionState = {
  collections: [],
};

type CollectionActions = {
  updateCollections: (data: Collection[]) => void;
};

const defaultCollectionActions: CollectionActions = {
  updateCollections: () => {},
};

const CollectionContext = createContext<CollectionState & CollectionActions>({
  ...initCollectionState,
  ...defaultCollectionActions,
});

export const CollectionProvider = ({ children }: PropsWithChildren<{}>) => {

  const [collections, setCollections] = useState<Collection[]>([]);

  const updateCollections = (data: Collection[]) => {
    setCollections(data);
  };

  return (
    <CollectionContext.Provider
      value={{
        collections,
        updateCollections,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

export const CollectionConsumer = CollectionContext.Consumer;

export const useCollectionContext = () => useContext(CollectionContext);
