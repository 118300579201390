import React from 'react';

function FAQIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        d="M17.0297 5.76406L11.9859 0.720313C11.8453 0.579688 11.6555 0.5 11.4562 0.5H1.5C1.08516 0.5 0.75 0.835156 0.75 1.25V20.75C0.75 21.1648 1.08516 21.5 1.5 21.5H16.5C16.9148 21.5 17.25 21.1648 17.25 20.75V6.29609C17.25 6.09687 17.1703 5.90469 17.0297 5.76406ZM15.5203 6.64062H11.1094V2.22969L15.5203 6.64062ZM15.5625 19.8125H2.4375V2.1875H9.51562V7.25C9.51562 7.51107 9.61934 7.76145 9.80394 7.94606C9.98855 8.13066 10.2389 8.23438 10.5 8.23438H15.5625V19.8125ZM8.8125 13.4844H4.5C4.39687 13.4844 4.3125 13.5688 4.3125 13.6719V14.7969C4.3125 14.9 4.39687 14.9844 4.5 14.9844H8.8125C8.91562 14.9844 9 14.9 9 14.7969V13.6719C9 13.5688 8.91562 13.4844 8.8125 13.4844ZM4.3125 10.4844V11.6094C4.3125 11.7125 4.39687 11.7969 4.5 11.7969H13.5C13.6031 11.7969 13.6875 11.7125 13.6875 11.6094V10.4844C13.6875 10.3813 13.6031 10.2969 13.5 10.2969H4.5C4.39687 10.2969 4.3125 10.3813 4.3125 10.4844Z"
        fill="white"
      />
    </svg>
  );
}

export default FAQIcon;