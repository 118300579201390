import { isEmpty } from 'lodash';
import { getMeInfoApi } from 'apis/userApis';

// CONSTANTS
import { SessionKey, SessionValues, StorageKey } from 'constants/storage';
import { Obj } from 'constants/types';

// SERVICES
import {
  hasSession,
  setSession,
  getSession,
  removeSession,
  getStorage,
  removeStorage,
} from 'services/storageService';

// UTILS
import { OldPageRoute } from 'constants/route';
import { User } from 'model/user';

let accessToken: string;
let userInfo: User;

// Load user info and access token to local variables to reduce JSON.parse session
const loadAuth = () => {
  const {
    [SessionKey.AccessToken]: sessionAuthData,
    [SessionKey.UserInfo]: sessionUserInfo,
  } = getSession([SessionKey.AccessToken, SessionKey.UserInfo]) as Pick<
    SessionValues,
    SessionKey.AccessToken | SessionKey.UserInfo
  >;

  if (sessionAuthData) {
    accessToken = sessionAuthData.accessToken;
  }

  if (sessionUserInfo) {
    userInfo = sessionUserInfo;
  }
};

export const setUserInfo = (userData: User) => {
  sessionStorage.setItem(SessionKey.UserInfo, JSON.stringify(userData));
  userInfo = userData;
};

export const setAuth = (authData: { accessToken: string }) => {
  setSession(SessionKey.AccessToken, authData);
  accessToken = authData.accessToken;
};

export const setFirebaseToken = (authData: Obj) => {
  setSession(SessionKey.FirebaseAccessToken, JSON.stringify(authData));
};

// If user is not logged in, access token will be empty
export const getAccessToken = () => {
  if (!accessToken) {
    loadAuth();
  }
  return accessToken;
};

export const getFirebaseAccessToken = () => {
  return getSession(SessionKey.FirebaseAccessToken) as string;
};

export const popLoginRedirectUrl = () => {
  const url = getSession(SessionKey.LoginRedirectUrl) as string;

  if (url) {
    removeSession(SessionKey.LoginRedirectUrl);
  }

  return url;
};

export const getUserInfo = (): User => {
  if (!userInfo) {
    loadAuth();
  }

  return userInfo;
};

export const isCompletedProfile = () => {
  return (
    userInfo &&
    userInfo.email &&
    userInfo.firstName &&
    userInfo.lastName &&
    userInfo.phoneNumber
  );
};

// export const getTokenInfo = (): Obj => {
//   if (!accessToken) {
//     loadAuth();
//   }

//   return accessToken ? jwt(accessToken) : {};
// }

export const fetchUserInfo = async (isAdmin: boolean = false) => {
  if (isEmpty(accessToken)) {
    return {};
  }

  try {
    const r = await getMeInfoApi();
    const { data: user } = r;
    setUserInfo({ ...user, isAdmin });
    return user;
  } catch (e) {
    console.log(e);
  }

  return {};
};

export const pingUserInfoService = async () => {
  try {
    await getMeInfoApi();
  } catch (e) {
    console.log('pingUserInfoService', e);
  }
};

export const login = async (accessToken: string, isAdmin: boolean = false) => {
  setAuth({ accessToken });
  // remove firebase token after login sucess
  // tmp comment out
  // removeSession(SessionKey.FirebaseAccessToken);

  // Load user info
  await fetchUserInfo(isAdmin);
};

export const logout = (keepCurrentUrl = false) => {
  const hasPortalToken = hasSession(SessionKey.PortalToken);
  accessToken = '';
  userInfo = new User();

  removeSession([
    SessionKey.AccessToken,
    SessionKey.FirebaseAccessToken,
    SessionKey.UserInfo,
    SessionKey.PortalToken,
    SessionKey.PortalUserInfo,
    SessionKey.SkipCheckOverBudget,
  ]);

  removeStorage([
    StorageKey.AuthDesign,
  ])

  if (hasSession(SessionKey.SaleAgentAccessToken)) {
    window.location.href = '/';
  } else if (hasPortalToken) {
    window.location.href = '/';
  } else {
    removeSession(true);
    if (keepCurrentUrl) {
      setSession(
        SessionKey.LoginRedirectUrl,
        window.location.pathname + window.location.search
      );
    }
    window.location.href = '/';
  }
};
