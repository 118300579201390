export const DATA_FAQ_FIL = (textBrand: string) => {
  return [
    {
      title:
        `Sino ba ang ${textBrand} International at ano ba ang kanilang dala-dala sa mesa?`,
      description: `
            <p>Sige, pag-usapan natin ang pangunahing merkado ng property at ang nakakabagot at luma na karanasan ng pagbenta na kaakibat nito. Ang ${textBrand} International ay naririto upang baguhin ang larong ito. Narito kami upang gisingin ang mga bagay, kaibigan.</p>
            <p>Tayo ay nagkakaisang mag-develop ng mga tahanan ng hinaharap. Paano namin ito ginagawa? Sa pamamagitan ng paggamit ng teknolohiyang pang-personalize. Oo, tama iyon. Ipakilala ng ${textBrand} ang isang kahanga-hangang tool sa 3D interior design na hinihikayat ng A.I. Oo, narinig mo ako ng tama. Ito ay nagbibigay-daan sa iyo na mag-navigate sa iyong espasyo sa totoong oras, kuwarto-kwarto, at i-premodel ang iyong hinaharap na tahanan.</p>
            <p>Sa ${textBrand}, ikaw ay magsisimula sa isang paglalakbay na magaan, masaya, at malinaw. Walang mga sorpresa, walang mga paghihintay. Ito ay isang walang-hassle, epektibong paglalakbay tungo sa iyong pangarap na tahanan.</p>
           `,
    },
    {
      title:
        'Ano nga ba ang isang Segmento at paano ito gumagana kapag bumibili ng bagong tahanan?',
      description: `
            <p>Sige, kaibigan ko, pag-usapan natin ang mga segmento at kung paano gumagana ang kanilang mahika sa pagbili ng mga tahanan sa ${textBrand}.</p>
            <p>Ang isang segmento ay parang isang piraso ng puzzle na maaaring pagsamahin upang lumikha ng espasyo na tumutugon sa iyong mga pagnanasa at badyet.</p>
            <p>Isipin mo ito bilang isang natatanging laki ng living area na maaari mong makuha ng hiwalay o sa maramihan, pina-personalize ang iyong bagong tahanan upang matugunan ang iyong lahat ng pangangailangan at pagnanasa.</p>
           `,
    },
    {
      title:
        'Bakit napakahalaga ng Kasunduan sa Reserbasyon sa proseso ng pagbili?',
      description: `
            <p>Sige, pag-usapan natin ang bagay na tinatawag na Kasunduan sa Reserbasyon. Baka iniisip mo, "Ano iyon at bakit ko ba ito dapat intindihin?" E, kaibigan ko, hayaan mo akong ipaliwanag sa iyo. Ang Kasunduan na ito ay isang napakahalagang elemento sa masayang paglalakbay ng pagbili ng isang property. Bakit? Dahil ito ay parang isang malditang safety net, kaibigan ko.</p>
            <p>Kapag pumirma ka ng Kasunduan sa Reserbasyon, praktikal na sinasabi mo, "Huwag muna, may mata ako sa property na ito, at gusto kong siguraduhing akin ito."</p>
            <p>Bakit ito napakahalaga? Simple. Nagbibigay ito sa iyo ng kasiyahan at nagpoprotekta sa iyong mga interes. Ito ay pinatutunayan na ang property ay hindi mabebenta habang ikaw ay abala sa pag-aayos ng mga detalye.</p>
           `,
    },
    {
      title:
        'Ano nga ba ang kasama sa isang Kasunduan sa Pagbili at Pagbebenta at ano ang itinatakda nito sa proseso ng pagbili ng bahay?',
      description: `
            <p>Ang Kasunduan sa Pagbili at Pagbebenta ay parang banal na kopa ng pagbili ng bahay. Ito ang dokumento na naglalagda sa deal at nagpoprotekta sa iyo sa proseso.</p>
            <p>Bigyan mo ako ng pagkakataon na ipaliwanag ito: kapag binibili mo ang isang bahay, hindi mo lang ibinibigay ang iyong pera at umaasa na maging maganda ang resulta. Dito pumapasok ang banal na dokumentong ito. Ito ay isang legal na nakatali na kas agreemente sa pagitan mo at ng nagbebenta na nagtatakda ng lahat ng mahalagang bagay. Nagsasangkot ito ng presyo ng bahay, ang mga kondisyon ng paghahatid, at ang timeline para sa pagtanggap ng bahay.</p>
            <p>Ito ay hindi isang casual na kasunduan sa paghawak ng kamay, kaibigan ko. Ito ay isang seryosong pag-aalaga na nagtitiyak na pareho ng mga partido ay mananagot. Kaya, kapag handa ka nang gumawa ng malaking pagbili, siguraduhing mayroon kang matibay na Kasunduan sa Pagbili at Pagbebenta. Ito ang iyong safety net sa masalimuot na mundo ng pagbili ng bahay.</p>
           `,

    },
    {
      title:
        `Paano mo maisasakatuparan ang iyong pinakamatitinding pangarap sa pagbili ng bahay sa pamamagitan ng misteryosong kapangyarihan ng ${textBrand} International?`,
      description: `
            <p>Kaya, namamatay ka na sa pagkaalam kung paano mo maisasakatuparan ang iyong pangarap na tahanan sa pamamagitan ng ${textBrand} International? E, humanda ka, kaibigan ko, dahil ipapaliwanag ko ito sa iyo.</p>
            <p><strong>Hakbang isa:</strong> pumili ng bansang iyong ninanais. Saan mo nakikita ang iyong pinakamagandang buhay? Pumili at magsimula tayo.</p>
            <p><strong>Hakbang dalawa:</strong> mayroon kaming isang malaking listahan ng mga proyekto na naghihintay na iyong galugarin. Lumubog ka sa mga detalye, imahinasyon mo ang sarili mo sa mga espasyong iyon, at isipin ang buhay na tatahakin mo.</p>
            <p><strong>Hakbang tatlo:</strong> magbawas ka ng mga opsyon, kaibigan. Piliin ang dami ng segmento na kaya mong bilhin at ang palapag na bumibitaw sa iyong kaluluwa.</p>
            <p><strong>Hakbang apat:</strong> Dito na nagsisimula ang kaaliwan. Handa ka na ba sa nakababagbag-damdaming bahagi? Pwede mong i-premodel ang iyong apartment sa isang kahanga-hangang instant 3D. Oo, nag-uusap kami tungkol sa pagdadala ng iyong pangarap na tahanan sa buhay bago pa man ito maganap sa iyong mga mata. I-adjust, paglaruan, at gawing perpekto. O, nabanggit ko na ba na makakakuha ka ng ganap na pananaw at transparensiya sa mga gastos? Wala kang dapat ipangamba dito.</p>
            <p><strong>Hakbang lima:</strong> Kapag nasiyahan ka na sa iyong obra maestra, oras na para isara ang deal. Pirmahan ang iyong kas agreemente sa reserbasyon at kas agreemente sa pagbili at pagbebenta sa online, at voilà!</p>
            <p>Ngayon, upo ka, kaibigan ko, at hayaan mo ang pagkakataon na dumating. Malapit na, magiging komportable ka na sa tahanang matagal mo nang pinapangarap. Ang ${textBrand} ay naririto upang gawin ito nang mangyari.</p> 
          `,
    },

    {

      title:
        'Paano ka makakasali sa listahan ng mga naghihintay at manatiling updated sa mga soon-to-be-launched na mga property?',
      description: `
            <p>Kaya gusto mo nang makuha ang mga mahiwagang "Darating na" na mga property, 'no? Hindi kita masisisi. E, narito ang sikreto para manatiling updated at maging una sa labanan.</p>
            <p>Pumunta ka sa aming website at hanapin ang seksyon na nagsasabing "Manatiling Informed." Ito ay parang isang sandukot ng mga mamimili tulad mo. Hanapin ang opsyon na sumali sa listahan ng mga naghihintay. Ilagay ang iyong email address, at ituring ang iyong sarili na opisyal na kasali sa loop.</p>
            <p>Asigurado kaming ikaw ang unang malalaman kapag ang mga kapana-panabik na proyekto ay handa nang lumipad at magandang tignan. Ito ay parang pagkakaroon ng backstage pass sa mundo ng mga property. Kaya huwag na huwag nang mag-aaksaya pa ng isa pang segundo, kaibigan ko. Sumali sa listahan ng mga naghihintay at hayaan na magsimula ang antsisipasyon.</p>
          `,
    },

    {
      title:
        `Paano pinangangalagaan ng ${textBrand} International ang proteksyon ng iyong mahalagang data at ano ang mga partnersiyang inilagay nila upang masiguro ang ligtas na mga transaksyon?`,
      description: `
            <p>Sa ${textBrand} International, hindi kami nagbibiro pagdating sa proteksyon ng data. Ang impormasyon mo ay katulad ng ginto para sa amin, at binabantayan namin ito ng buong sikap. Hindi kami gumagamit ng mga solusyong pang-karaniwan. Nag-partner kami sa mga pinakamahusay upang mapanatili ang iyong data na ligtas at maayos.</p>
            <p>Mayroon kaming Tazapay, isang kumpanyang nakabase sa Singapore na espesyalista sa pag-iingat ng mga detalye ng iyong pagbabayad. Pero hindi doon nagtatapos ang lahat. Nagdagdag kami ng isang antas sa pamamagitan ng nagbabagong teknolohiya ng Pandadoc para sa digital na pagpirma ng kontrata.</p>
            <p>Hindi kami nagpapabaya, kaibigan ko. Ang iyong data ay aming pangunahing prayoridad at may tamang mga partnersiya kami upang tiyakin iyon.</p>
          `,
    },
  ];
};
