import React from 'react';

import LevitasteLogoImg from 'assets/logos/levitaste_logo.png';
import LevitateLogoImg from 'assets/logos/levitate_logo.svg';
import styled from "styled-components";
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';

type Props = {
  width?: string;
  height?: string;
  onClick?: (...arg: any) => void
}

const Img = styled.img`
  position: relative;
`;

export const LevitasteLogo = (props: Props) => (
  <Img src={LevitasteLogoImg} {...props} />);

export const LevitateLogo = (props: Props) => (
  <Img
    src={getStorage(StorageKey.LogoCompany) || LevitateLogoImg}
    {...props}
    style={{ cursor: 'pointer' }}
  />
);

export default {
  LevitasteLogo,
  LevitateLogo
};
