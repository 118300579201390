import * as types from './types';
import { getAccessToken } from 'services/authService';

export type AuthState = {
  error: any;
  accessToken?: string;
};

const initialState: AuthState = {
  error: null,
  accessToken: getAccessToken(),
};

export const authReducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case types.LOGIN:
      return { ...initialState };
    case types.LOGOUT:
      return { ...initialState };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        error: null,
      };

    case types.LOGIN_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};

export default authReducer;
