import React from 'react';

const VndOutlined = (props: any) => (
  <svg {...props} viewBox="0 0 17 17">
    <path
      d="M8.18952 0.6875C10.3982 0.6875 12.3984 1.58309 13.8461 3.03082C15.2939 4.47853 16.1895 6.47866 16.1895 8.68758C16.1895 10.8962 15.2939 12.8965 13.8461 14.3442C12.3986 15.7919 10.3984 16.6875 8.18952 16.6875C5.9806 16.6875 3.98047 15.7919 2.53276 14.3442C1.08504 12.8965 0.189453 10.8962 0.189453 8.68758C0.189453 6.47866 1.08519 4.47853 2.53276 3.03082C3.98047 1.58309 5.98088 0.6875 8.18952 0.6875ZM9.94043 11.0415H8.86989V10.4444C8.83848 10.4444 8.45601 11.1414 7.56013 11.1414C7.04328 11.1414 6.63441 10.9586 6.33193 10.593C6.03072 10.2261 5.87948 9.73171 5.87948 9.10703C5.87948 8.44423 6.04915 7.91039 6.38848 7.50536C6.72767 7.09891 7.17353 6.8964 7.72579 6.8964C8.25707 6.8964 8.63182 7.09235 8.85018 7.48565H8.86989V6.3757H7.56414V5.65106H8.86989V5.05009H9.94043V5.65106H10.4993V6.3757H9.94043V11.0415ZM8.88446 8.72571C8.88446 8.44037 8.79762 8.20358 8.62268 8.01551C8.44787 7.82613 8.22822 7.73144 7.96387 7.73144C7.65353 7.73144 7.40889 7.85255 7.23266 8.09177C7.05514 8.33255 6.96573 8.65988 6.96573 9.07676C6.96573 9.45951 7.04985 9.75927 7.21824 9.97621C7.38662 10.1933 7.62069 10.3011 7.92174 10.3011C8.20708 10.3011 8.43988 10.188 8.62139 9.9605C8.80148 9.73299 8.88959 9.45023 8.88446 9.11104V8.72571ZM9.8983 12.3249H6.02287V11.6346H9.8983V12.3249ZM13.0984 3.7786C11.8423 2.52253 10.1065 1.74548 8.18952 1.74548C6.27251 1.74548 4.53659 2.52253 3.28054 3.7786C2.02463 5.03465 1.24743 6.77042 1.24743 8.68758C1.24743 10.6046 2.02448 12.3404 3.28054 13.5964C4.53659 14.8525 6.27237 15.6295 8.18952 15.6295C10.1067 15.6295 11.8424 14.8525 13.0984 13.5964C14.3544 12.3404 15.1315 10.6046 15.1315 8.68758C15.1315 6.77042 14.3544 5.03465 13.0984 3.7786Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
  </svg>
);

export default VndOutlined;
