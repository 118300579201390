import { useCallback, useEffect, useState } from 'react';

// type Return = [boolean];

const useTriggerRefresh = () => {
  const [show, setShow] = useState(true);

  const triggerRefresh = useCallback(() => setShow(false), []);

  useEffect(() => {
    if (!show) {
      setShow(true);
    }
  }, [show]);

  return { show, triggerRefresh };
};

export default useTriggerRefresh;
