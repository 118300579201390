import { THomeData } from 'constants/designConstants';

// @deprecated
export enum OldPageRoute {
  // sale agent pages
  DesignHub = '/design-hub/design-hub',
  // homebuyer pages
  SaleAgentLogin = '/design-hub',
  HomebuyerLogin = '/design-hub/homebuyer-login',
  SaleAgentHome = '/design-hub/sale-agent-home',
  CompleteProfile = '/design-hub/complete-profile',
  ChatBot = '/design-hub/chat-bot',
  LevitasteQuiz = '/design-hub/what-is-your-levitaste',
  YourTaste = '/design-hub/your-taste',
  ProjectPlace = '/design-hub/project-place',
  ChooseBlock = '/design-hub/choose-block',
  ChooseSegment = '/design-hub/choose-segment',
  ChooseLayout = '/design-hub/choose-layout',
  YourDesign = '/design-hub/your-design',
  MortgagePlan = '/design-hub/mortgage-plan',
  AvailablePlans = '/design-hub/available-plans',
  PersonalInfo = '/design-hub/account/personal-info',
  MyDesign = '/design-hub/account/designs',
  MyOrders = '/design-hub/account/orders',
  MyOrderDetail = '/design-hub/account/orders/:id',
  FavoriteProject = '/design-hub/account/projects',
  MyHistory = '/design-hub/account/history',
  PaymentSchedule = '/design-hub/payment-schedule',
  ShareSocial = '/design-hub/share-social/:orderId',
  EndSession = '/design-hub/end-session',
  AdminEndSession = '/design-hub/admin-end-session',
  // portal pages
  LevitatorDashboard = '/mylevitator',
  LevitatorOrderDetail = '/mylevitator/order/:id',
  LevitatorlLogin = '/mylevitator/login',
}

export enum PageRoute {
  //Short link
  Fusion = '/fusion',
  FushionOwner = '/fusionowner',

  // Route
  Dashboard = '/',
  HomePage = '/home', // Introduction and World Map
  HomePageSelling = '/home-selling',
  HomePageProject = '/home-project',
  ChooseCountry = '/choose-country',
  ProjectListResell = '/project-listing-resell/:locationId',
  ChooseResellOptions = '/choose-resell-options/location/:locationId/project/:projectId/collection/:personalityId/segment/:segmentId/floor/:floorId/planId/:planId',
  ResellUnit = '/resell-unit/location/:locationId/project/:projectId/collection/:personalityId/segment/:segmentId/floor/:floorId/planId/:planId',
  KeepModel = '/keep-model/location/:locationId/project/:projectId/collection/:personalityId/segment/:segmentId/floor/:floorId/planId/:planId',
  KeepAndRemodel = '/keep-and-remodel/location/:locationId/project/:projectId/collection/:personalityId/segment/:segmentId/floor/:floorId/planId/:planId',
  ProjectListing = '/project-listing/:locationId', // Project List and Project Detail
  ProjectDetail = '/project-listing/:locationId/:projectId',
  ChooseBlock = '/choose-block/location/:locationId/project/:projectId', // Choose Block
  ChooseSegment = '/choose-segment/location/:locationId/project/:projectId', // Choose Block
  PromotionChoosing = '/promotion-choosing/location/:locationId/project/:projectId/collection/:personalityId',
  ChooseCollection = '/choose-collection/location/:locationId/project/:projectId',
  SignatureLayout = '/signature-layout/location/:locationId/project/:projectId',
  HomebuyerLogin = '/login', // Login
  PrivacyPolicy = '/privacy-policy', // Static page privacy policy
  CookiePolicy = '/cookie-policy', // Static page cookie policy
  FAQ = '/FAQ',// Static page FAQ
  TermsOfUse = '/terms-of-use', //Static page TermsOfUse
  YourDesign = '/your-design/location/:locationId/project/:projectId/collection/:personalityId',
  EndSession = '/end-session',
  ProjectPlace = '/project-place',
  MyFinancialPackage = '/my-financial-package/location/:locationId/project/:projectId',
  MyFinancialCombine = '/my-financial-combine/location/:locationId/project/:projectId',
  MyFinancialBank = '/my-financial-bank/location/:locationId/project/:projectId',
  MortagePlan = '/mortage-plan/location/:locationId/project/:projectId',
  PersonalProfile = '/personal-profile/location/:locationId/project/:projectId',
  MyOrderConfirm = '/order-confirm/:orderId/location/:locationId/project/:projectId',
  ShareSocial = '/order-confirm/share-social/:orderId',
  ContactUs = '/contact-us',
  PartnerLevitate = '/li',
  OurStory = '/our-story',
  InTheSplotlight = '/in-the-spotlight',
  DemoInteract3D = '/demo',
  WaitList = '/join-the-queue',
}

export enum PageRouteName {
  Dashboard = '/',
  HomePage = '/home',
  PartnerLevitate = '/li',
  ChooseBlock = '/choose-block',
  ChooseSegment = '/choose-segment',
  ChooseCollection = '/choose-collection',
  PromotionChoosing = '/promotion-choosing',
  SignatureLayout = '/signature-layout',
  YourDesign = '/your-design',
  MyFinancialPackage = '/my-financial-package',
  MyFinancialCombine = '/my-financial-combine',
  MyFinancialBank = '/my-financial-bank',
  MortagePlan = '/mortage-plan',
  PersonalProfile = '/personal-profile',
  MyOrderConfirm = '/order-confirm',
  PrivacyPolicy = '/privacy-policy',
  CookiePolicy = '/cookie-policy',
  FAQ = '/FAQ',
  ProjectListing = '/project-listing',
  ShareSocial = '/order-confirm/share-social',
  TermsOfUse = '/terms-of-use',
  ContactUs = '/contact-us',
  OurStory = '/our-story',
  InTheSplotlight = '/in-the-spotlight',
  DemoInteract3D = '/demo',
  WaitList = '/join-the-queue',
  HomePageSelling = '/home-selling',
  HomePageProject = '/home-project',
  ChooseCountry = '/choose-country',
  ProjectListResell = '/project-listing-resell',
  ChooseResellOptions = '/choose-resell-options',
  ResellUnit = '/resell-unit',
  KeepModel = '/keep-model',
  KeepAndRemodel = '/keep-and-remodel',
  Fusion = '/fusion',
  FushionOwner = '/fusionowner'
}

export const PageRouteNameOrderedValidation: string[] = [
  PageRouteName.ChooseBlock,
  PageRouteName.ChooseSegment,
  PageRouteName.ChooseCollection,
  PageRouteName.PromotionChoosing,
  PageRouteName.SignatureLayout,
  PageRouteName.YourDesign,
  PageRouteName.MyFinancialPackage,
  PageRouteName.MyFinancialCombine,
  PageRouteName.MyFinancialBank,
  PageRouteName.MortagePlan,
  PageRouteName.MyFinancialPackage
]

export const PageRouteParent = {
  MyHome: [PageRouteName.ChooseBlock, PageRouteName.ChooseSegment],
  MyDesign: [PageRouteName.SignatureLayout, PageRouteName.YourDesign],
  MyFinancialPackage: [],
  MyProfile: [PageRouteName.PersonalProfile],
  MyOrderConfirm: [PageRouteName.MyOrderConfirm]
}

// order by step
export enum PageStep {
  SaleAgentLogin,
  HomebuyerLogin,
  CompleteProfile,
  ChatBot,
  LevitasteQuiz,
  YourTaste,
  ProjectPlace,
  ChooseBlock,
  ChooseSegment,
  ChooseLayout,
  YourDesign,
  MortgagePlan,
  EndSession,
  DesignHub,
}

export const PAGE_STEP_ROUTE_MAP: { [key in PageStep]?: OldPageRoute } = {
  [PageStep.SaleAgentLogin]: OldPageRoute.SaleAgentLogin,
  [PageStep.HomebuyerLogin]: OldPageRoute.HomebuyerLogin,
  [PageStep.CompleteProfile]: OldPageRoute.CompleteProfile,
  [PageStep.ChatBot]: OldPageRoute.ChatBot,
  [PageStep.LevitasteQuiz]: OldPageRoute.LevitasteQuiz,
  [PageStep.YourTaste]: OldPageRoute.YourTaste,
  [PageStep.ProjectPlace]: OldPageRoute.ProjectPlace,
  [PageStep.ChooseBlock]: OldPageRoute.ChooseBlock,
  [PageStep.ChooseSegment]: OldPageRoute.ChooseSegment,
  [PageStep.ChooseLayout]: OldPageRoute.ChooseLayout,
  [PageStep.YourDesign]: OldPageRoute.YourDesign,
  [PageStep.MortgagePlan]: OldPageRoute.MortgagePlan,
  [PageStep.EndSession]: OldPageRoute.EndSession,
  [PageStep.DesignHub]: OldPageRoute.DesignHub,
};

export const PAGE_ROUTE_STEP_MAP: { [key in OldPageRoute]?: PageStep } = {
  [OldPageRoute.SaleAgentLogin]: PageStep.SaleAgentLogin,
  [OldPageRoute.HomebuyerLogin]: PageStep.HomebuyerLogin,
  [OldPageRoute.CompleteProfile]: PageStep.CompleteProfile,
  [OldPageRoute.ChatBot]: PageStep.ChatBot,
  [OldPageRoute.LevitasteQuiz]: PageStep.LevitasteQuiz,
  [OldPageRoute.YourTaste]: PageStep.YourTaste,
  [OldPageRoute.ProjectPlace]: PageStep.ProjectPlace,
  [OldPageRoute.ChooseBlock]: PageStep.ChooseBlock,
  [OldPageRoute.ChooseSegment]: PageStep.ChooseSegment,
  [OldPageRoute.ChooseLayout]: PageStep.ChooseLayout,
  [OldPageRoute.YourDesign]: PageStep.YourDesign,
  [OldPageRoute.MortgagePlan]: PageStep.MortgagePlan,
  [OldPageRoute.AvailablePlans]: PageStep.MortgagePlan,
  [OldPageRoute.PaymentSchedule]: PageStep.MortgagePlan,
  [OldPageRoute.EndSession]: PageStep.EndSession,
};

export type LocationState = {
  skipCheckStep?: boolean;
  savedDesignId?: number; // apply saved design in Your Design page
  changingOrder?: {
    orderId: number;
    changingType: 'design' | 'layout';
    totalPrice: number;
    layoutHasChanged?: boolean;
  } & THomeData;
  currentOrderId?: number;
};
