import React from 'react';
import { Form, Input, Select } from 'antd';
import { isEmpty, sortBy } from 'lodash';
import styled from 'styled-components';
import { COUNTRY_PHONE_CODE_OPTIONS } from 'locales/countries/en';
import { COUNTRY_PHONE_CODE_OPTIONS_VIETNAM } from 'locales/countries/vi';
import { COUNTRY_PHONE_CODE_OPTIONS_ZH } from 'locales/countries/zh';
import { COUNTRY_PHONE_CODE_OPTIONS_FR } from 'locales/countries/fr';
import { COUNTRY_PHONE_CODE_OPTIONS_MS } from 'locales/countries/ms';
import { COUNTRY_PHONE_CODE_OPTIONS_PL } from 'locales/countries/pl';
import { COUNTRY_PHONE_CODE_OPTIONS_AR } from 'locales/countries/ar';
import { COUNTRY_PHONE_CODE_OPTIONS_PT } from 'locales/countries/pt';
import { COUNTRY_PHONE_CODE_OPTIONS_ES } from 'locales/countries/es';
import { COUNTRY_PHONE_CODE_OPTIONS_DE } from 'locales/countries/de';
import { COUNTRY_PHONE_CODE_OPTIONS_TH } from 'locales/countries/th';
import { COUNTRY_PHONE_CODE_OPTIONS_EL } from 'locales/countries/el';
import { COUNTRY_PHONE_CODE_OPTIONS_IN } from 'locales/countries/in';
import { COUNTRY_PHONE_CODE_OPTIONS_NL } from 'locales/countries/nl';
import { COUNTRY_PHONE_CODE_OPTIONS_FIL } from 'locales/countries/fil';
import { COUNTRY_PHONE_CODE_OPTIONS_JA } from 'locales/countries/ja';
import { COUNTRY_PHONE_CODE_OPTIONS_KO } from 'locales/countries/ko';
import { COUNTRY_PHONE_CODE_OPTIONS_TL } from 'locales/countries/tl';

// "languages": ["en", "vi", "zh", "fr", "ms", "pl", "ar", "pt", "es", "de", "th", "in", "nl", "fil", "el", "ja", "ko", "tl"],

// EN
export const CountryPhoneCodeOptionsSortedByName =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS, 'text');

// VI
export const TranslateCountryOptionsSortedByName =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_VIETNAM, 'text');

// ZH
export const TranslateCountryOptionZh =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_ZH, 'text');

// FR
export const TranslateCountryOptionFr =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_FR, 'text');

// MS
export const TranslateCountryOptionMs =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_MS, 'text');

// PL
export const TranslateCountryOptionPl =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_PL, 'text');

// AR
export const TranslateCountryOptionAr =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_AR, 'text');

// PT
export const TranslateCountryOptionPt =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_PT, 'text');

// ES
export const TranslateCountryOptionEs =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_ES, 'text');

// DE
export const TranslateCountryOptionDe =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_DE, 'text');

// TH
export const TranslateCountryOptionTh =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_TH, 'text');

// IN
export const TranslateCountryOptionIn =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_IN, 'text');

// NL
export const TranslateCountryOptionNl =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_NL, 'text');

// FIL
export const TranslateCountryOptionFil =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_FIL, 'text');

// EL
export const TranslateCountryOptionEl =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_EL, 'text');

// JA
export const TranslateCountryOptionJa =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_JA, 'text');

// KO
export const TranslateCountryOptionKo =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_KO, 'text');

// TL
export const TranslateCountryOptionTl =
  sortBy(COUNTRY_PHONE_CODE_OPTIONS_TL, 'text');

const { Option } = Select;
const numberRegex = /[0-9]/;

const getOptionByValue = (value: string, options: Array<any>) => {
  return options.find((o) => o.value === value)
}

class PhoneNumberInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      prefix: this.props?.value?.prefix || "",
      phoneNumber: this.props?.value?.phoneNumber || "",
      option: {},
    }
  }

  componentDidMount() {
    const { countryCodeOptions = CountryPhoneCodeOptionsSortedByName, value = {} } = this.props;
    const { prefix, phoneNumber } = value || {}
    const _prefix = prefix || countryCodeOptions[0]?.value || "";
    const defaultOption = getOptionByValue(_prefix, countryCodeOptions);
    this.setState({
      prefix: _prefix,
      phoneNumber: phoneNumber,
      option: defaultOption,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<any>) {
    if (nextProps?.value?.phoneNumber !== this.state.phoneNumber
      || (nextProps?.value?.prefix !== this.state.prefix) && !isEmpty(nextProps?.value?.prefix)) {
      this.setState({
        phoneNumber: nextProps?.value?.phoneNumber,
        prefix: nextProps?.value?.prefix,
      })
    }
  }

  handleChangePrefix = (optionValue: any) => {
    const opSelected = getOptionByValue(optionValue, CountryPhoneCodeOptionsSortedByName);
    const { onChange } = this.props;
    const { phoneNumber } = this.state;

    this.setState({
      prefix: optionValue,
      option: opSelected,
    }, () => {
      onChange && onChange({
        prefix: optionValue,
        phoneNumber,
        option: opSelected
      })
    })
  }


  handleChangePhone = (event: any) => {
    const { onChange } = this.props;
    const { prefix, option } = this.state;
    const phoneNumber = event.target.value

    this.setState({
      phoneNumber,
    }, () => {
      onChange && onChange({
        prefix,
        phoneNumber,
        option
      })
    })
  }

  handleKeyPress = (event: any) => {
    if (!numberRegex.test(event.key)) {
      event.preventDefault()
    }
  }

  render() {
    const {
      value,
      errorMsg = '',
      placeholder = null,
      onBlur = () => {
      },
      className = '',
      noStyle = false
    } = this.props;
    const { phoneNumber } = this.state;
    return (
      <Wrapper>
        <Form.Item
          validateStatus={isEmpty(errorMsg) ? 'success' : 'error'}
          help={errorMsg || ''}
          noStyle={noStyle}
        // rules={[{required: true}]}
        //   name={['phone', 'phoneNumber']}
        >
          <Input.Group compact>
            <Select
              style={{ width: '40%' }}
              size={"large"}
              showSearch
              placeholder="Select a phone country"
              optionFilterProp="children"
              onChange={this.handleChangePrefix}
              // onFocus={this.onFocus}
              onBlur={onBlur}
              defaultValue={value?.prefix}
              className={`${className} bg-transparent`}

            >
              {
                CountryPhoneCodeOptionsSortedByName.map((o: any) => (
                  <Option value={o.value} key={o.text}>{o.text}</Option>
                ))
              }
            </Select>
            <Input
              autoComplete={"off"}
              className={`${className} bg-transparent `}
              name={'phoneNumber'}
              onChange={this.handleChangePhone}
              onBlur={onBlur}
              size={"large"}
              style={{ width: '60%' }}
              value={phoneNumber}
              placeholder={placeholder || 'Phone number'}

            />
          </Input.Group>
        </Form.Item>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .ant-select-selector {
    background-color: transparent !important
  }
  .ant-select-arrow {
    color: currentColor;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

export default PhoneNumberInput;
