import React from 'react';
import BaseIcon from './BaseIcon';

const IcOutlineClose = (props: any) => {
  return (
    <BaseIcon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.13355 6.65998C8.97739 6.50372 8.72421 6.50372 8.56805 6.65998L6.66066 8.56861C6.5045 8.72487 6.5045 8.97822 6.66066 9.13448L17.5166 19.9975L6.65922 30.8411C6.50291 30.9972 6.50292 31.2503 6.65922 31.4064L8.56844 33.3132C8.72475 33.4693 8.97818 33.4693 9.13449 33.3132L19.9895 22.472L30.824 33.3136C30.9802 33.4699 31.2334 33.4699 31.3895 33.3136L33.2969 31.405C33.4531 31.2487 33.4531 30.9954 33.2969 30.8391L22.4647 19.9999L33.3211 9.1573C33.4774 9.00119 33.4774 8.74808 33.3211 8.59197L31.4119 6.68518C31.2555 6.52906 31.0021 6.52907 30.8458 6.68518L19.9918 17.5254L9.13355 6.65998Z"
        fill={props.color || 'currentColor'}
      />
    </BaseIcon>
  );
};

export default IcOutlineClose
