import { Obj } from 'constants/types';
import BaseModel from './baseModel';

class CurrencyModel extends BaseModel<CurrencyModel> {
  id?: number;
  code?: string;
  conversionRate?: number;
  countryCode?: string;
  isDefault?: boolean;
  name?: string;
  state?: number;
  symbol?: string;
  icon?: React.ReactElement;

  fromPayload({
    id,
    code,
    conversionRate,
    countryCode,
    isDefault,
    name,
    state,
    symbol,
    icon,
  }: Obj): this {
    if (id !== undefined) this.id = id;
    if (code !== undefined) this.code = code;
    if (conversionRate !== undefined) this.conversionRate = conversionRate;
    if (countryCode !== undefined) this.countryCode = countryCode;
    if (isDefault !== undefined) this.isDefault = isDefault;
    if (name !== undefined) this.name = name;
    if (state !== undefined) this.state = state;
    if (symbol !== undefined) this.symbol = symbol;
    if (icon !== undefined) this.icon = icon;

    return this;
  }
};

export default CurrencyModel;