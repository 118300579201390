export const POLICY_EL = (textBrand: string) => {
  return [
    {
      title: 'Εισαγωγή',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>1.1.</div>
            <div>Η ${textBrand} International Pte. Ltd. ("<strong>εμείς</strong>", "<strong>εμάς</strong>", “<strong>ημείς</strong>” ή “<strong>${textBrand}</strong>”) είναι ο φορέας λειτουργίας του <a href="https://levitate.homes">https://levitate.homes</a> (ο "<strong>Ιστότοπος</strong>"), και δεσμευόμαστε να προστατεύουμε τα Προσωπικά Δεδομένα σας (όπως ορίζεται στην Παράγραφο 2 παρακάτω) σύμφωνα με τους όρους και προϋποθέσεις που αναφέρονται εδώ.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.2.</div>
            <div>Η παρούσα Πολιτική Απορρήτου (μαζί με τους Όρους Χρήσης μας και οποιαδήποτε άλλα έγγραφα που αναφέρονται σε αυτήν) ορίζει πώς τα Προσωπικά Δεδομένα που συλλέγουμε από εσάς ή που μας παρέχετε, θα χρησιμοποιηθούν, επεξεργαστούν ή αποκαλυφθούν από εμάς, συμπεριλαμβανομένου του τρόπου με τον οποίο ορισμένες πληροφορίες θα χρησιμοποιηθούν για την προσαρμογή διαφημίσεων που στρέφονται προς εσάς. Η παρούσα Πολιτική Απορρήτου περιγράφει επίσης τους τύπους Προσωπικών Δεδομένων που συλλέγουμε, τον τρόπο που τα προστατεύουμε, καθώς και τον τρόπο που μπορείτε να έχετε πρόσβαση ή να αλλάξετε τα Προσωπικά Δεδομένα σας που αποθηκεύονται από εμάς. Η παρούσα Πολιτική Απορρήτου καλύπτει τη χρήση του Ιστότοπου και κάθε άλλο τρόπο με τον οποίο μπορείτε να συνδεθείτε ή να αλληλεπιδράσετε μαζί μας μέσω της χρήσης των προϊόντων και υπηρεσιών μας (στο εξής αναφερόμενα αναλλοίωτα ως οι "<strong>Υπηρεσίες</strong>").</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.3.</div>
            <div>Παρακαλούμε διαβάστε προσεκτικά τα παρακάτω για να κατανοήσετε την πολιτική και τις πρακτικές μας σχετικά με τα Προσωπικά Δεδομένα σας και τον τρόπο με τον οποίο θα τα χειριστούμε.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.4.</div>
            <div>Εκτός εάν περιοριστεί από το ισχύον δίκαιο, χρησιμοποιώντας τον Ιστότοπο και τις Υπηρεσίες, συμφωνείτε ότι τα Προσωπικά Δεδομένα που αφορούν εσάς και που συλλέγονται από εμάς ή παρέχονται από εσάς από καιρό σε καιρό μπορεί να χρησιμοποιούνται, να επεξεργάζονται και να αποκαλύπτονται για τους σκοπούς και στους ανθρώπους που καθορίζονται στην παρούσα Πολιτική Απορρήτου.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.5.</div>
            <div>Στο πλαίσιο των προσπαθειών μας για να εξασφαλίσουμε ότι διαχειριζόμαστε, προστατεύουμε και επεξεργαζόμαστε σωστά τα Προσωπικά Δεδομένα σας, θα επανεξετάζουμε τις πολιτικές, τις διαδικασίες και τις διαδικασίες μας από καιρό σε καιρό. Μπορούμε να αλλάξουμε την παρούσα Πολιτική Απορρήτου ανά πάσα στιγμή αναρτώντας μια αναθεωρημένη έκδοση στον Ιστότοπο. Μπορεί να προσπαθήσουμε να στείλουμε ειδοποίηση όταν αλλάζει η πολιτική, παρ' όλα αυτά, θα θεωρηθείτε ότι έχετε αναγνωρίσει και συμφωνήσει με την τροποποιημένη Πολιτική Απορρήτου εάν συνεχίσετε να χρησιμοποιείτε τον Ιστότοπο ή τις Υπηρεσίες μετά την έναρξη ισχύος των αλλαγών, ανεξάρτητα από το αν λάβατε την ειδοποίηση ή όχι. Σας υπενθυμίζουμε να ελέγχετε αυτήν τη σελίδα από καιρό σε καιρό για ενημερώσεις σχετικά με την παρούσα Πολιτική Απορρήτου.</div>
          </div>
          <div>
          </div>
        </div>
      `
    },
    {
      title: 'Συλλογή Προσωπικών Δεδομένων',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div>
            Μπορείτε να επιλέξετε να περιηγηθείτε στον Ιστότοπο και να χρησιμοποιήσετε τις περιορισμένες λειτουργίες του χωρίς να αποκαλύψετε τα Προσωπικά Δεδομένα σας. Δεν απαιτείται να παρέχετε Προσωπικά Δεδομένα ως προϋπόθεση για τη χρήση του Ιστότοπου, εκτός αν είναι απαραίτητο για να μπορέσουμε να παρέχουμε τις Υπηρεσίες που αγοράζετε ή έχετε πρόσβαση μέσω του Ιστότοπου μας, να ανταποκριθούμε στα αιτήματά σας ή σε περιπτώσεις που επικοινωνείτε μαζί μας απευθείας.
          </div>
          <div>
            Όταν χρησιμοποιείτε τις Υπηρεσίες μας, συλλέγουμε μια ποικιλία πληροφοριών από και σχετικά με εσάς, τις συσκευές σας και την αλληλεπίδρασή σας με τις Υπηρεσίες. Κάποιες από αυτές τις πληροφορίες σας αναγνωρίζουν άμεσα ή μπορούν να χρησιμοποιηθούν για να σας αναγνωρίσουν εν προκειμένω, όταν συνδυάζονται με άλλα δεδομένα. Κάθε δεδομένο, είτε αληθινό είτε όχι, σχετικά με ένα άτομο που μπορεί να αναγνωριστεί από αυτά τα δεδομένα ή από αυτά τα δεδομένα και άλλες πληροφορίες που διαθέτει ο οργανισμός, θεωρείται προσωπικά δεδομένα ("<strong>Προσωπικά Δεδομένα</strong>").
          </div>
          <div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.1.</div>
              <div>
                <strong>Πληροφορίες που παρέχετε εκούσια</strong>. Κατά τη χρήση των Υπηρεσιών μας, ενδέχεται να σας ζητηθεί να παράσχετε προσωπικές πληροφορίες σχετικά με εσάς, όπως, μεταξύ άλλων, το όνομά σας, τα στοιχεία επικοινωνίας σας, πληροφορίες πληρωμής, λεπτομέρειες σχετικά με το σπίτι σας ή τα ακίνητα που σας ενδιαφέρουν, οικονομικές πληροφορίες, τα αγαπημένα σας ή τα στυλ σας. Αυτό μπορεί να συμβεί, για παράδειγμα:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>α.</div>
                    <div>Όταν πραγματοποιείτε συναλλαγές μαζί μας στον Ιστότοπο ή σχετικά με αυτόν·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>β.</div>
                    <div>Όταν εγγράφετε λογαριασμό μαζί μας στον Ιστότοπο·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>γ.</div>
                    <div>Όταν επικοινωνείτε με την εξυπηρέτηση πελατών ή τους πωλητές μας μέσω ηλεκτρονικού ταχυδρομείου, τηλεφώνου ή από κοντά, για να επιλύσετε οποιαδήποτε προβλήματα αντιμετωπίζετε ή για ερωτήσεις ή αιτήματα σας·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>δ.</div>
                    <div>Όταν εγγράφεστε για τις υπηρεσίες που παρέχουμε στον Ιστότοπο ή για τις ειδοποιήσεις ηλεκτρονικού ταχυδρομείου και/ή ενημερωτικά δελτία μας·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ε.</div>
                    <div>Όταν παρέχετε ή αφήνετε τις σχετικές πληροφορίες, απαντήσεις ή πληροφορίες στον Ιστότοπο για τον εαυτό σας ή τις προτιμήσεις σας, καθώς και για τα ακίνητα που σας ενδιαφέρουν·</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Σκοπός Χρήσης',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div>
            <div style="display: flex; gap: 0.3rem;">
              <div>3.1</div>
              <div>
                Τα Προσωπικά Δεδομένα που παρέχετε μπορούν να χρησιμοποιούνται και να επεξεργάζονται από εμάς (ή από τρίτα μέρη, όπως η Google Analytics, εκ μέρους μας) είτε συλλογικά είτε ατομικά και μπορεί να συνδυαστούν με άλλες πληροφορίες για τον σκοπό της διαχείρισης και λειτουργίας του Ιστότοπου, το εύρος του οποίου μπορεί να περιλαμβάνει, αλλά δεν περιορίζεται στα παρακάτω:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                      <div>α.</div>
                      <div>Διαχείριση του Ιστότοπου</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                      <div>β.</div>
                      <div>Βελτίωση της εμπειρίας περιήγησής σας προσαρμόζοντας τον Ιστότοπο</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>γ.</div>
                    <div>Δυνατότητα χρήσης των Υπηρεσιών που είναι διαθέσιμες στον Ιστότοπο</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>δ.</div>
                    <div>Παροχή σε εσάς των Υπηρεσιών που έχετε ζητήσει ειδικά</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ε.</div>
                    <div>Απόκριση στις ερωτήσεις ή αιτήματά σας σύμφωνα με τα ηλεκτρονικά μηνύματα και/ή υποβολή φορμών στον Ιστότοπο</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>στ.</div>
                    <div>Ανταπόκριση και λήψη μέτρων σχετικά με παράπονα σχετικά με τη χρήση του Ιστότοπου ή οποιαδήποτε Υπηρεσία παρέχεται από εμάς</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ζ.</div>
                    <div>Αποστολή γενικών εμπορικών επικοινωνιών μέσω οποιασδήποτε μεθόδου επικοινωνίας, όπως ηλεκτρονικό ταχυδρομείο, τηλεφωνική κλήση, SMS και ειδοποιήσεις από εφαρμογές</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>η.</div>
                    <div>Εκτέλεση γραφειακών και διοικητικών θεμάτων</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>θ.</div>
                    <div>Παροχή σε τρίτα μέρη στατιστικών πληροφοριών σχετικά με τους χρήστες μας. Τέτοιες στατιστικές πληροφορίες μπορούν να χρησιμοποιηθούν για την εξατομίκευση της διαφήμισης στον Ιστότοπο, αλλά δεν μπορούν να χρησιμοποιηθούν για την αναγνώριση οποιουδήποτε μεμονωμένου χρήστη;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ι.</div>
                    <div>Δημιουργία και διαχείριση του online λογαριασμού σας</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ι.</div>
                    <div>Διευκόλυνση των δραστηριοτήτων και συναλλαγών σας στον Ιστότοπο</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>κ.</div>
                    <div>Διευκόλυνση της συμμετοχής σας σε διαγωνισμούς ή προωθητικές ενέργειες</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>λ.</div>
                    <div>Χειρισμός πληρωμής ή επιστροφής χρημάτων προς/από εσάς</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>μ.</div>
                    <div>Παροχή πληροφοριών σχετικά με τις Υπηρεσίες που ζητάτε από εμάς· ή υπό την προηγούμενη συγκατάθεσή σας, πληροφορίες που πιστεύουμε ότι μπορεί να σας ενδιαφέρουν μέσω ηλεκτρονικού ταχυδρομείου ή SMS;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ν.</div>
                    <div>Λειτουργία, αξιολόγηση και βελτίωση της επιχείρησής μας (συμπεριλαμβανομένης της ανάπτυξης νέων προϊόντων και υπηρεσιών· ανάλυση και ενισχυμένης λειτουργίας των Υπηρεσιών μας);</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ξ.</div>
                    <div>Κατανόηση των πελατών μας και πραγματοποίηση ανάλυσης δεδομένων και άλλων επεξεργασιών (συμπεριλαμβανομένης της έρευνας της αγοράς και των καταναλωτών, ανάλυσης τάσεων και ανωνυμοποίησης);</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ο.</div>
                    <div>Ανταπόκριση στις σχόλια ή ερωτήσεις σας ή αίτηση πρόσληψης (εφόσον υπάρχει);</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>π.</div>
                    <div>Διανομή στους παρόχους υπηρεσιών έτσι ώστε να μπορούν να σας παρέχουν τις Υπηρεσίες· και κατά τα άλλα, σύμφωνα με τις νομικές απαιτήσεις ή τις επιτρεπόμενες νομικές διατάξεις.
                  </div>
                </div>
              </div>
            </div>
            Μπορείτε να επιλέξετε να περιηγηθείτε στον Ιστότοπο και να χρησιμοποιήσετε τις περιορισμένες λειτουργίες του χωρίς να αποκαλύψετε τα Προσωπικά Δεδομένα σας. Δεν απαιτείται να παρέχετε Προσωπικά Δεδομένα ως προϋπόθεση για τη χρήση του Ιστότοπου, εκτός αν είναι απαραίτητο για να μπορέσουμε να σας παρέχουμε τις Υπηρεσίες που αγοράζετε ή έχετε πρόσβαση μέσω του Ιστότοπου, να ανταποκριθούμε στα αιτήματά σας ή σε περιπτώσεις που επικοινωνείτε μαζί μας απευθείας.
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2. </div>
            <div>Επειδή οι σκοποί για τους οποίους μπορούμε/θα μπορούμε να συλλέγουμε, χρησιμοποιούμε, αποκαλύπτουμε ή επεξεργαζόμαστε τα Προσωπικά Δεδομένα σας εξαρτώνται από τις συνθήκες που επικρατούν, τέτοιος σκοπός ενδέχεται να μην εμφανίζεται παραπάνω. Ωστόσο, θα σας ενημερώσουμε για τους άλλους σκοπούς αυτούς κατά την απόκτηση της συγκατάθεσής σας, εκτός αν η επεξεργασία των Προσωπικών Δεδομένων σας χωρίς τη συγκατάθεσή σας επιτρέπεται από τον Νόμο περί Προστασίας Δεδομένων του 2012 της Σιγκαπούρης (ο "<strong>PDPA</strong>") ή από οποιονδήποτε άλλο ισχύον νόμο.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3. </div>
            <div><strong>Παροχές "Μην Καλέσεις".</strong> Εάν μας έχετε παρέχει τον αριθμό τηλεφώνου σας (ο "Αριθμός/αριθμοί") και έχετε δηλώσει ότι συναινείτε να λαμβάνετε μάρκετινγκ ή άλλες προωθητικές πληροφορίες μέσω των Αριθμών σας, τότε κατά καιρούς μπορούμε να επικοινωνούμε μαζί σας χρησιμοποιώντας αυτούς τους Αριθμούς για πληροφορίες σχετικά με τις Υπηρεσίες μας, ακόμη κι αν αυτοί οι Αριθμοί είναι εγγεγραμμένοι στο Κατάλογο Μην Καλέσεις (εάν υπάρχει, σύμφωνα με την αντίστοιχη δικαιοδοσία). Ωστόσο, μπορείτε να επιθυμείτε να μην επικοινωνούμε μαζί σας στους Αριθμούς σας για αυτούς τους σκοπούς και μπορείτε να το δηλώσετε γραπτώς.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Ακρίβεια και Ασφάλεια',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Θα πρέπει να διασφαλίζετε ότι όλα τα Προσωπικά Δεδομένα που υποβάλλονται σε εμάς είναι πλήρη, ακριβή, αληθινά και σωστά. Η αποτυχία εκ μέρους σας να το κάνετε μπορεί να έχει ως αποτέλεσμα την αδυναμία μας να σας παρέχουμε τις Υπηρεσίες που έχετε ζητήσει.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Θα καταβάλουμε λογικές προσπάθειες για να διασφαλίσουμε ότι τα Προσωπικά Δεδομένα σας είναι ακριβή και πλήρη, αν τα Προσωπικά Δεδομένα σας πρόκειται να χρησιμοποιηθούν από εμάς για να λάβουμε μια απόφαση που σας αφορά ή να τα αποκαλύψουμε σε μια άλλη οργάνωση. Ωστόσο, αυτό σημαίνει ότι θα πρέπει επίσης να μας ενημερώσετε για οποιεσδήποτε αλλαγές στα Προσωπικά Δεδομένα σας που μας είχατε παράσχει αρχικά. Δεν θα είμαστε υπεύθυνοι για την εξάρτηση από ανακριβή ή ατελή Προσωπικά Δεδομένα που προκύπτουν από τη μη ενημέρωσή σας για τυχόν αλλαγές στα Προσωπικά Δεδομένα σας που μας είχατε παράσχει αρχικά.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Θα λάβουμε λογικά τεχνικά και οργανωτικά μέτρα για την πρόληψη απώλειας, κατάχρησης ή αλλοίωσης των Προσωπικών Δεδομένων σας.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Θα αποθηκεύουμε όλα τα Προσωπικά Δεδομένα που παρέχετε σε ασφαλείς διακομιστές με κρυπτογράφηση.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Ωστόσο, κατανοείτε ότι η μετάδοση πληροφοριών μέσω Διαδικτύου δεν είναι εντελώς ασφαλής. Παρόλο που θα κάνουμε το καλύτερο δυνατό για την προστασία των Προσωπικών Δεδομένων σας, δεν μπορούμε να εγγυηθούμε την ασφάλεια των Προσωπικών Δεδομένων που μεταδίδονται στον Ιστότοπο. Οποιαδήποτε μετάδοση πληροφοριών γίνεται με δικό σας ρίσκο. Επιπλέον, δεν μπορούμε να αναλάβουμε την ευθύνη για τη μη εξουσιοδοτημένη χρήση των Προσωπικών Δεδομένων σας από τρίτα μέρη που οφείλεται πλήρως σε παράγοντες εκτός του ελέγχου μας.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Όπου σας έχουμε δώσει (ή όπου έχετε επιλέξει) έναν κωδικό πρόσβασης για να αποκτήσετε πρόσβαση σε συγκεκριμένα μέρη του Ιστότοπου, είστε υπεύθυνοι για τη διατήρηση αυτού του κωδικού πρόσβασης εμπιστευτικό. Σας ζητούμε να μην μοιράζεστε τον κωδικό πρόσβασης με κανέναν. Οποιαδήποτε αίτηση να μας δώσετε τον κωδικό πρόσβασης σας πέραν της σύνδεσης στον Ιστότοπο δεν πρέπει να την λάβετε υπόψη. ΠΟΤΕ δεν θα σας ζητήσουμε τον κωδικό πρόσβασης σας, εκτός εάν συνδεθείτε στον Ιστότοπο, όπως ισχύει.
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Σας παροτρύνουμε να λάβετε μέτρα για τη διατήρηση των προσωπικών σας πληροφοριών ασφαλείς, όπως η επιλογή ενός ισχυρού κωδικού πρόσβασης και η διατήρησή του σε μυστικό, καθώς επίσης και η αποσύνδεσή σας από τον λογαριασμό χρήστη και το κλείσιμο του προγράμματος περιήγησης όταν τελειώσετε τη χρήση των Υπηρεσιών μας σε ένα κοινόχρηστο ή μη ασφαλές συσκευή.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Σκοπός Χρήσης',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Δεν θα αποκαλύψουμε τα Προσωπικά σας Δεδομένα σε τρίτους για σκοπούς άμεσης μάρκετινγκ χωρίς την έκφραση της ρητής συναίνεσής σας. Η συναίνεση για την αποκάλυψη Προσωπικών Δεδομένων σε τρίτους, για σκοπούς λήψης ενημερωτικών μηνυμάτων, παρέχεται μέσω της φόρμας εγγραφής μας, είτε διαδικτυακά είτε σε έντυπη μορφή.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
              <div>
                Για τη διαχείριση και λειτουργία του Ιστότοπου, ενδέχεται να αποκαλύψουμε τα Προσωπικά σας Δεδομένα σε οποιονδήποτε μέλος της ομάδας μας, που σημαίνει τις συναφείς εταιρείες και θυγατρικές. Επίσης, μπορεί να αποκαλύψουμε τα Προσωπικά σας Δεδομένα σε τρίτους, όπως οι παροχείς υπηρεσιών μας και επαγγελματίες σύμβουλοι, για τους ακόλουθους σκοπούς:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>α.</div>
                    <div>Για οποιονδήποτε σκοπό επιτρέπεται από οποιονδήποτε ισχύον νόμο ή κανονισμό·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>β.</div>
                    <div>Για τη συμμόρφωση με οποιαδήποτε νομική απαίτηση·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>γ.</div>
                    <div>Για την εφαρμογή ή εκτέλεση των Όρων Χρήσης μας·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>δ.</div>
                    <div>Για την προστασία των δικαιωμάτων, της ιδιοκτησίας και της ασφάλειας μας, καθώς και των δικαιωμάτων, της ιδιοκτησίας και της ασφάλειας των συναφών εταιρειών και θυγατρικών μας, άλλων χρηστών του Ιστότοπου και άλλων·</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ε.</div>
                    <div>Σε σχέση με οποιαδήποτε νομική διαδικασία ή ενδεχόμενη νομική διαδικασία· και</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>f.</div>
                    <div>Σε συνδυασμό με μια συναλλαγή επιχειρηματικού περιουσιακού στοιχείου (δηλαδή, την αγορά, πώληση, μίσθωση, συγχώνευση ή ενσωμάτωση ή οποιαδήποτε άλλη απόκτηση, κατανομή ή χρηματοδότηση μιας οργάνωσης ή ενός τμήματος της οργάνωσης ή οποιαδήποτε από τις επιχειρηματικές δραστηριότητες ή περιουσιακά στοιχεία μιας οργάνωσης) στην οποία είμαστε μέρος ή προοπτικό μέρος.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Δεν θα αποκαλύψουμε επίσης τα Προσωπικά σας Δεδομένα σε τρίτους χωρίς να έχουμε πρώτα λάβει τη συναίνεσή σας που μας επιτρέπει να το πράξουμε. Ωστόσο, παρακαλούμε σημειώστε ότι μπορεί να αποκαλύψουμε τα Προσωπικά σας Δεδομένα σε τρίτους χωρίς να έχουμε πρώτα λάβει τη συναίνεσή σας σε ορισμένες καταστάσεις, συμπεριλαμβανομένων, χωρίς περιορισμό, των ακόλουθων:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>α.</div>
                  <div>Σε περιπτώσεις όπου η αποκάλυψη απαιτείται ή εξουσιοδοτείται βάσει των ισχύοντων νόμων και/ή κανονιστικών προσταγών·</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>β.</div>
                  <div>Σε περιπτώσεις όπου ο σκοπός αυτής της αποκάλυψης εξυπηρετεί προφανώς τα συμφέροντά σας και δεν είναι δυνατή η άμεση λήψη συναίνεσης·</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>γ.</div>
                  <div>Σε περιπτώσεις όπου η αποκάλυψη είναι αναγκαία για να ανταποκριθούμε σε οποιαδήποτε έκτακτη ανάγκη που απειλεί τη ζωή, την υγεία ή την ασφάλεια σας ή άλλου ατόμου·</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>δ.</div>
                  <div>Σε περιπτώσεις όπου η αποκάλυψη είναι αναγκαία για οποιαδήποτε έρευνα ή διαδικασία·</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>ε.</div>
                  <div>Σε περιπτώσεις όπου τα Προσωπικά Δεδομένα αποκαλύπτονται σε οποιονδήποτε αξιωματούχο του ορισμένου νομοθετικού οργάνου επιβολής του νόμου·</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>στ.</div>
                  <div>Σε περιπτώσεις όπου η αποκάλυψη γίνεται σε μια δημόσια αρχή και είναι αναγκαία για δημόσιο συμφέρον· και/ή</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>ζ.</div>
                  <div>Όπου η αποκάλυψη χωρίς τη συναίνεσή σας επιτρέπεται από τον PDPA ή από το νόμο.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Όταν αποκαλύπτουμε τα Προσωπικά σας Δεδομένα σε τρίτους χωρίς τη συναίνεσή σας, θα καταβάλουμε τις καλύτερες προσπάθειές μας για να απαιτήσουμε από αυτούς τους τρίτους να προστατεύουν τα Προσωπικά σας Δεδομένα.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Απόσυρση Συγκατάθεσης',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Μπορείτε να αποσύρετε τη συγκατάθεσή σας επικοινωνώντας με την υπηρεσία πελατών μας στη διεύθυνση <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Παρακαλούμε να σημειώσετε ότι αν αποσύρετε τη συγκατάθεσή σας για οποιαδήποτε ή όλες τις χρήσεις των Προσωπικών σας Δεδομένων, ανάλογα με τη φύση του αιτήματός σας, μπορεί να μην είμαστε σε θέση να συνεχίσουμε να παρέχουμε τις υπηρεσίες μας σε εσάς. Ενημερωθείτε από εσάς, θα σας ενημερώσουμε για τις πιθανές συνέπειες της απόσυρσης της συγκατάθεσης, περιλαμβανομένης, μεταξύ άλλων, της λήξης οποιωνδήποτε συμφωνιών που μπορεί να έχετε μαζί μας και της παραβίασης των συμβατικών υποχρεώσεών σας. Τα νομικά μας δικαιώματα και μέτρα σε τέτοια περίπτωση διατηρούνται ρητώς.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Διεθνής Μεταφορά Δεδομένων',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>7.1.</div>
                <div>Λειτουργούμε σε πολλές χώρες και ενδέχεται να αποθηκεύουμε, επεξεργαζόμαστε ή μεταφέρουμε προσωρινά τα Προσωπικά σας Δεδομένα μεταξύ οποιασδήποτε από τις χώρες στις οποίες λειτουργούμε, προκειμένου να μπορούμε να χρησιμοποιούμε τα Προσωπικά σας Δεδομένα σύμφωνα με αυτήν την Πολιτική Απορρήτου και τους Όρους Χρήσης μας.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>7.2.</div>
                <div>Θα λάβουμε όλα τα εύλογα μέτρα που απαιτούνται για να διασφαλίσουμε ότι τα Προσωπικά σας Δεδομένα μεταφέρονται σύμφωνα με οποιονδήποτε ισχύον νόμο ή κανονισμό και θα λάβουμε όλα τα εύλογα μέτρα για να διασφαλίσουμε ότι οποιαδήποτε ξένη οργάνωση παραλήπτης των Προσωπικών σας Δεδομένων είναι υποχρεωμένη να παρέχει στα μεταφερόμενα Προσωπικά σας Δεδομένα ένα επίπεδο προστασίας που τουλάχιστον είναι συγκρίσιμο με την προστασία που παρέχεται σύμφωνα με τον PDPA.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Ιστότοποι Τρίτων',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Ο ιστότοπος μπορεί, από καιρό σε καιρό, να περιέχει συνδέσμους προς και από τους ιστότοπους των εταίρων δικτύων και προμηθευτών μας. Εάν ακολουθήσετε έναν σύνδεσμο προς οποιονδήποτε από αυτούς τους ιστότοπους, παρακαλούμε σημειώστε ότι αυτοί οι ιστότοποι διαθέτουν τις δικές τους πολιτικές απορρήτου και ότι δεν αποδεχόμαστε καμία ευθύνη ή υποχρέωση για αυτές τις πολιτικές. Παρακαλούμε ελέγξτε αυτές τις πολιτικές πριν υποβάλετε οποιαδήποτε Προσωπικά Δεδομένα σε αυτούς τους ιστότοπους.
              </div>
            </div>
          `
    },
    {
      title: 'Πρόσβαση, Διαγραφή και Διόρθωση',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>9.1.</div>
            <div>Ο Διευθυντής Προστασίας Δεδομένων μας είναι υπεύθυνος για τη διασφάλιση ότι οι αιτήσεις για πρόσβαση, διαγραφή ή διόρθωση των Προσωπικών Δεδομένων χειρίζονται σωστά σύμφωνα με τις προβλεπόμενες διαδικασίες που προσδιορίζονται εδώ.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>9.2.</div>
            <div>Όλες οι αιτήσεις για πληροφορίες ή αιτήσεις για διορθώσεις ή διαγραφές προσωπικών δεδομένων που κατέχουμε θα πραγματοποιούνται υποβάλλοντας μια αίτηση στον Διευθυντή Προστασίας Δεδομένων μας στη διεύθυνση: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>9.3.</div>
            <div>Παρακαλούμε σημειώστε ότι θα αποδεχόμαστε και θα εκτελούμε μόνο αιτήσεις στην αγγλική γλώσσα που συμμορφώνονται με αυτήν την Πολιτική Απορρήτου. Οποιαδήποτε αίτηση που δεν συμμορφώνεται με τα παραπάνω θα απορρίπτεται, και δεν θα προβούμε σε καμία ενέργεια σχετικά με τις απορριφθείσες αιτήσεις.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>9.4.</div>
            <div>Ενδέχεται να αρνηθούμε να αποκαλύψουμε, να διαγράψουμε ή να τροποποιήσουμε οποιαδήποτε Προσωπικά Δεδομένα για τους λόγους που προβλέπονται στον PDPA.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>9.5.</div>
            <div>Όσον αφορά το δυνατό, θα ανταποκριθούμε στο αίτημά σας εντός δέκα (10) εργάσιμων ημερών από την ημερομηνία λήψης του αιτήματος. Εάν αυτό δεν είναι δυνατό, θα ενημερωθείτε εντός δέκα (10) εργάσιμων ημερών από την ημερομηνία λήψης του αιτήματος..</div>
          </div>
        </div>
      `
    },
    {
      title: 'Φύλαξη των Προσωπικών σας Δεδομένων',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>Φυλάσσουμε τα Προσωπικά Δεδομένα που συλλέγουμε από εσάς όπου έχουμε διαρκή νόμιμο επαγγελματικό λόγο να το κάνουμε. Εάν είστε πελάτης ή προμηθευτής (ή εκπρόσωπος ενός πελάτη ή προμηθευτή) τότε οι πληροφορίες θα παραμείνουν για ένα χρονικό διάστημα που θα μας επιτρέψει να παρέχουμε τις Υπηρεσίες μας, να συμμορφωθούμε με τις ισχύουσες νομικές, φορολογικές ή λογιστικές απαιτήσεις και, εάν είναι απαραίτητο, για τη διεκδίκηση, άσκηση ή υπεράσπιση νομικών αξιώσεων.
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>Όταν δεν έχουμε διαρκή νόμιμο επαγγελματικό λόγο για να επεξεργαστούμε τις προσωπικές σας πληροφορίες, θα τις διαγράψουμε ή θα τις ανωνυμοποιήσουμε. Εάν αυτό δεν είναι δυνατό (π.χ. επειδή οι προσωπικές σας πληροφορίες έχουν αποθηκευτεί σε αρχεία αντιγράφων ασφαλείας), τότε θα φυλάξουμε ασφαλώς τις προσωπικές σας πληροφορίες και θα τις απομονώσουμε από οποιαδήποτε περαιτέρω επεξεργασία μέχρι να είναι δυνατή η διαγραφή τους.
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Επικοινωνήστε μαζί μας',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>
                  Εάν χρειάζεστε περισσότερες πληροφορίες σχετικά με την επεξεργασία των Προσωπικών Δεδομένων σας από εμάς, μην διστάσετε να επικοινωνήσετε με τον Διευθυντή Προστασίας Δεδομένων μας στη διεύθυνση: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Εφαρμογή του Δικαίου',
      description: `<div style="display: flex; flex-direction: column; gap: 0.5rem;"> <div style="display: flex; gap: 0.3rem;"> Η παρούσα Πολιτική Απορρήτου υπόκειται σε όλες τις πτυχές του νόμου της Σιγκαπούρης. </div> </div>`
    }
  ];
};
