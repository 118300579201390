import React from 'react';

const PolishPlotyOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4995_49482)">
        <path
          d="M6 13.2765C6.77556 12.2397 7.5498 11.202 8.32734 10.1668C8.48478 9.95735 8.6554 9.75825 8.81964 9.55396C8.83545 9.53432 8.84994 9.51333 8.87564 9.47857C8.82996 9.47857 8.8001 9.47857 8.77002 9.47857C7.98633 9.47857 7.20243 9.47857 6.41874 9.47857C6.3902 9.47857 6.36121 9.47947 6.33289 9.47653C6.26086 9.46931 6.21563 9.43003 6.21519 9.35306C6.21365 9.09888 6.21343 8.84448 6.21563 8.5903C6.21629 8.51423 6.26174 8.4745 6.3353 8.47021C6.35858 8.46886 6.38207 8.46931 6.40557 8.46931C7.66333 8.46931 8.92087 8.46931 10.1786 8.46931C10.344 8.46931 10.3723 8.49888 10.3723 8.67134C10.3723 8.82936 10.3765 8.98737 10.3699 9.14493C10.3677 9.1973 10.3499 9.2578 10.3194 9.29866C9.48893 10.4108 8.65627 11.5212 7.82274 12.6309C7.73535 12.7474 7.64115 12.8582 7.53707 12.988C7.58033 12.988 7.60953 12.988 7.63851 12.988C8.50037 12.988 9.36223 12.988 10.2241 12.988C10.3813 12.988 10.4134 13.0203 10.4136 13.179C10.4138 13.3932 10.414 13.6072 10.4136 13.8215C10.4131 13.961 10.3767 13.9989 10.2434 13.9989C8.88683 13.9989 7.53026 13.9987 6.17369 14C6.10057 14 6.04084 13.9849 6.00044 13.9187C6 13.7048 6 13.4908 6 13.2765Z"
          fill="white"
        />
        <path
          d="M12.6917 8.81922C12.7749 8.75579 12.8434 8.70387 12.9115 8.6515C13.1012 8.50568 13.2889 8.35715 13.4808 8.21448C13.684 8.06369 13.9459 8.16189 13.9936 8.40545C14.0223 8.55308 13.9608 8.66437 13.8469 8.7515C13.5902 8.94789 13.3344 9.14586 13.0781 9.34315C12.9718 9.42487 12.8673 9.50907 12.7586 9.5874C12.709 9.62329 12.6903 9.66279 12.6903 9.72622C12.6923 11.0777 12.6919 12.4294 12.6917 13.7808C12.6917 13.8077 12.6923 13.8343 12.6912 13.8612C12.6875 13.9504 12.6469 13.9973 12.5615 13.998C12.2569 14 11.9521 14 11.6476 13.998C11.5674 13.9973 11.5255 13.9501 11.5187 13.8666C11.5167 13.8427 11.518 13.8185 11.518 13.7944C11.518 12.7452 11.518 11.6962 11.518 10.647V10.5486C11.4837 10.5729 11.4607 10.5883 11.4387 10.6052C11.1658 10.8152 10.8942 11.0271 10.6197 11.235C10.415 11.3901 10.1494 11.2917 10.1024 11.0441C10.0749 10.8996 10.1342 10.7894 10.2455 10.7041C10.504 10.5055 10.7622 10.3064 11.0207 10.1075C11.1665 9.9953 11.3109 9.88107 11.4589 9.77182C11.5042 9.73841 11.5198 9.70275 11.5195 9.64586C11.5178 8.49778 11.5182 7.34947 11.5182 6.20139C11.5182 6.03232 11.5492 6.00049 11.7141 6.00049C11.9771 6.00049 12.24 6.00049 12.503 6.00049C12.664 6.00049 12.6919 6.02961 12.6919 6.19823C12.6919 7.03593 12.6919 7.87363 12.6919 8.71132V8.81968L12.6917 8.81922Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4995_49482">
          <rect width="8" height="8" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PolishPlotyOutlined;
