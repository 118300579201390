import React from 'react';

const PoundSterlingOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4355_44683)">
        <path
          d="M7.83203 9.75121H8.78375C8.75969 9.7135 8.74087 9.68522 8.72518 9.65599C8.56517 9.3694 8.36332 9.09601 8.25664 8.79434C8.04643 8.19665 8.16356 7.61593 8.55053 7.09931C9.0107 6.48277 9.67376 6.13679 10.4885 6.03403C11.047 5.96427 11.5992 5.99443 12.1242 6.19335C12.9096 6.49219 13.3698 7.02861 13.4984 7.78845C13.5361 8.00905 13.5486 8.23341 13.5737 8.46438H12.5383C12.5059 8.2683 12.4881 8.07127 12.44 7.87989C12.3375 7.47452 12.1231 7.12194 11.6724 6.96827C10.9957 6.7373 10.3253 6.75333 9.74174 7.18604C9.35269 7.47452 9.23032 7.87895 9.27216 8.32392C9.30562 8.68875 9.49388 9.00362 9.68004 9.3185C9.75429 9.44294 9.84005 9.56172 9.9143 9.68616C9.94463 9.73801 9.98228 9.75309 10.045 9.75215C10.6046 9.75027 11.163 9.75121 11.7226 9.75121C11.7581 9.75121 11.7937 9.75121 11.8355 9.75121V10.3329H10.25C10.296 10.4894 10.3421 10.6327 10.3808 10.7778C10.478 11.1474 10.3849 11.4896 10.1904 11.8167C9.98124 12.1684 9.69363 12.4653 9.39661 12.7566C9.30249 12.849 9.20522 12.9395 9.11737 13.0413C9.14456 13.0281 9.1728 13.0168 9.19895 13.0027C9.64866 12.767 10.1329 12.6416 10.6516 12.6954C10.957 12.7274 11.2551 12.8132 11.5573 12.8735C11.7895 12.9197 12.0206 12.9791 12.2549 13.0046C12.6178 13.0432 12.941 12.9225 13.2422 12.75C13.2715 12.7331 13.3018 12.717 13.3384 12.6963C13.5036 12.9301 13.6647 13.1582 13.8331 13.3977C13.7651 13.4514 13.6992 13.508 13.6291 13.5589C13.2955 13.8021 12.919 13.9511 12.4892 13.9831C12.4672 13.985 12.4452 13.9935 12.4243 13.9982H12.1691C12.1053 13.9888 12.0415 13.9793 11.9767 13.9709C11.5103 13.9068 11.0626 13.7804 10.6202 13.6381C10.1287 13.4797 9.63088 13.4429 9.13515 13.6126C8.91971 13.6862 8.71681 13.788 8.49719 13.8822C8.31939 13.6616 8.13219 13.4288 7.94289 13.1941C7.99518 13.1516 8.03911 13.1168 8.08303 13.0819C8.46267 12.7727 8.82454 12.4502 9.10587 12.0609C9.48865 11.532 9.51584 10.989 9.19058 10.43C9.15188 10.363 9.10901 10.3329 9.01906 10.3338C8.62373 10.3385 8.22841 10.3338 7.83203 10.3338V9.74932V9.75121Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4355_44683">
          <rect
            width="6"
            height="8"
            fill="white"
            transform="translate(7.83203 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PoundSterlingOutlined;

