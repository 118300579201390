// CONSTANTS
import UserTasteTypes from './userTasteTypes';
import PersonalityResult from 'model/personalityResult';

export type TUserTasteState = {
  tasteLoaded: boolean;
  personalityResult: PersonalityResult;
};

const initialState: TUserTasteState = {
  tasteLoaded: false,
  personalityResult: new PersonalityResult(),
}

const userTasteReducer = (state = initialState, action: any): TUserTasteState => {
    switch (action.type) {
      case UserTasteTypes.ResetCurrentUserTaste:
        return {
          personalityResult: initialState.personalityResult,
          tasteLoaded: true,
        };
      
      case UserTasteTypes.EditCurrentUserTaste:
        return {
          ...state,
          personalityResult: action.payload,
        };

      case UserTasteTypes.FetchCurrentUserTaste:
        return {
          ...state,
          personalityResult: action.payload ?? state.personalityResult,
          tasteLoaded: true,
        };
      
      default:
        return state;
    }
} 

export default userTasteReducer;


