import styled, { css } from 'styled-components';

// COMPONENTS
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import React from 'react';
import { Box } from '@mui/material';

declare module '@mui/material/TextField/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

export type TextFieldProps = MuiTextFieldProps;
export type InputProps = {
  maxLength?: number;
  typeInput?: string;
  minInput?: number;
  maxInput?: number;
  endAdornment?: React.ReactNode;
  labelControl?: string;
}

export type StyledProps = {
  paddingright?: string;
  background?: string;
}

const TextField = ({
  variant = 'outlined',
  maxLength = 100,
  typeInput = 'text',
  minInput,
  maxInput,
  endAdornment,
  paddingright,
  background,
  labelControl,
  ...restProps
}: TextFieldProps & InputProps & StyledProps) => {
  return (
    <WrapperTextField>
      {labelControl && <Box className="label-required" mb={1}>{labelControl}</Box>}
      <StyledTextField
        inputProps={{
          maxLength,
          type: typeInput,
          min: minInput,
          max: maxInput,
        }}
        InputProps={{
          endAdornment,
        }}
        variant={variant}
        paddingright={paddingright}
        background={background}
        {...restProps}
      />
    </WrapperTextField>
  );
};

TextField.defaultProps = {
  autoComplete: "",
}

const WrapperTextField = styled(Box)`
  .label-required {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    
  }

  .label-required::after {
    content: ' *';
    color: #FF4B55;
  }

  .label-error {
    font-size: 11px;
    color: ${p => p.theme.palette.error.main};
  }
`

const StyledTextField = styled(MuiTextField) <StyledProps>`
  label {
    font-size: 13px;
    color: #aca8b7;
    &.MuiInputLabel-outlined {
      top: -3px;
    }
    &.MuiInputLabel-shrink {
      top: 0;
      color: #aca8b7;
    }
  }

  .MuiOutlinedInput-root {
    padding-right: ${p => p.paddingright || '14px'};
    background: ${p => p.background || ''};
    font-size: 14px;

    &.MuiInputBase-root {
      padding: 0;
    }
    
    fieldset {
      top: 0;
      border: 1px solid ${p => p.theme.palette.primary.contrastText} !important;
      legend {
        display: none;
      }
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${p => p.theme.palette.accent.main} !important;
    }

    &.Mui-focused .MuiSvgIcon-root {
      color: #d4d2db;
    }
  }

  .MuiSvgIcon-root {
    color: ${p => p.theme.palette.primary.contrastText};
  }
  
  ${p => !p.label && css`
    legend {
      width: 0;
    }
  `}
  
  ${p => p.size === 'large' && css`
    .MuiInputBase-root {
      font-size: 16px;
      height: 46px;
    }
  `}

  .MuiOutlinedInput-input {
    padding: 10px 14px !important;

    &::placeholder {
      color: ${p => p.theme.palette.primary.contrastText};
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: ${p => p.theme.palette.primary.contrastText};
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
     color:${p => p.theme.palette.primary.contrastText};
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .Mui-disabled {
    background-color: rgb(220 220 220 / 20%) !important;
  }

  .Mui-error {
    color: #d32f2f !important;
    font-size: 12px;
    .MuiOutlinedInput-notchedOutline {
      border-color: #d32f2f !important;
    }
  }
`;

export default TextField;
