import { TypeChangePhoneNumberOrEmail } from 'pages/MyProfile/PersonalProfile';
import React from 'react';
import { TypeSinup } from '../SignupLight';

// COMPONENTS
import SignupEmail from '../commons/SignupEmaill';
import SignupPhoneNumber from '../commons/SignupPhoneNumber';

type SignupLightFormProps = {
  telephone?: string;
  onChangeStep?: (value: string) => void;
  typeVerify?: string;
  typePage: string,
  onChangeTypeVerify?: (value: string) => void;
  onSubmitFormPhoneNumber?: (data: { name: string; phoneNumber: string, prefix: string, email: string }) => void,
  setShow: (show: boolean) => void;
}
const SignupLightForm = ({
  onChangeStep,
  typeVerify,
  typePage,
  onChangeTypeVerify,
  onSubmitFormPhoneNumber,
  setShow,
  ...restProps
}: SignupLightFormProps) => {

  return (
    <div>
      {
        (typeVerify === TypeSinup.email || typeVerify === TypeChangePhoneNumberOrEmail.changeEmail) &&
        <SignupEmail
          typeVerify={typeVerify}
          onChangeStep={(email, name) => {
            onSubmitFormPhoneNumber!({ email: email || '', name: name || '', phoneNumber: '', prefix: '' })
            onChangeStep!(typeVerify)
          }}
          typePage={typePage}
          onChangeTypeVerify={value => onChangeTypeVerify!(value)}
        />
      }
      {
        (typeVerify === TypeSinup.PhoneNumber || typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber) &&
        <SignupPhoneNumber
          typeVerify={typeVerify}
          typePage={typePage}
          onChangeStep={(data) => {
            onChangeStep!(typeVerify);
            onSubmitFormPhoneNumber!({ ...data, email: '' })
          }}
          onChangeTypeVerify={value => onChangeTypeVerify!(value)}
          setShow={show => {setShow!(show)}}
          {...restProps}
        />
      }
    </div>
  );
}

export default SignupLightForm;