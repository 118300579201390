/* eslint react-hooks/rules-of-hooks: 0 */
import { RootState } from '../store';

// read: https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-useselector-hook
export const selectUserDataLoaded = (state: RootState) => state.userData.loaded;

export const selectUserHome = (state: RootState) =>
  state.userData.userHome.data;

export const selectUserMortgagePlan = (state: RootState) =>
  state.userData.userMortgagePlan;

export const selectProjectHistory = (state: RootState) =>
  state.userData.projectHistory.data;
