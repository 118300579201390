
import { DesignHome } from 'model/designHome';
import DesignHomeTypes from './designHomeTypes';
import { Auth } from 'model/auth.model';
import { FinancialPackage } from 'model/financialPlan';
import { TextContentBrand } from 'model/textContent';
import { FilterValue } from './designHomeReducers';

export const setDesignHome = (data: DesignHome) => {
  return {
    type: DesignHomeTypes.SetDesignHome,
    data
  }
};

export const resetDesignHome = () => {
  return {
    type: DesignHomeTypes.ResetDesignHome,
  }
};

export const setAuth = (data: Auth) => {
  return {
    type: DesignHomeTypes.SetAuth,
    data
  }
};

export const setFinancialPlan = (data: FinancialPackage) => {
  return {
    type: DesignHomeTypes.SetFinancePlan,
    data
  }
};

export const resetFinancialPlan = () => {
  return {
    type: DesignHomeTypes.ResetFinancePlan,
  }
};

export const setFilterProjects = (data: FilterValue) => {
  return {
    type: DesignHomeTypes.FilterProjects,
    data
  }
};

export const setTextContentBrand = (data: TextContentBrand) => {
  return {
    type: DesignHomeTypes.SetTextContentBrand,
    data
  }
};
