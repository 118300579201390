import { FormControl } from '@mui/material';
import styled from 'styled-components';

export const StyledFormControl = styled(FormControl) <{ paddingbutton: string }>`
  && {

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${p => p.theme?.palette?.primary?.contrastText} !important;
    }

    .MuiInputBase-formControl {
      .MuiInputBase-input {
        padding: ${p => p.paddingbutton};
      }
    }

    @media only screen and (max-width: 992px) {
      .MuiOutlinedInput-root {
        font-size: 13px;
      }
    }

    label {
      font-size: 13px;
      color: #aca8b7;

      &.MuiInputLabel-outlined {
        top: -3px;
      }

      &.MuiInputLabel-shrink {
        top: 0;
        color: #aca8b7;
      }
    }

    fieldset {
      border-width: 1px !important;
      border-radius: 0 !important ;
    }

    .Mui-disabled {
      background-color: rgb(220 220 220 / 20%) !important;
    }

    .Mui-error {
      color: ${(p) => p.theme.palette.error.main} !important;
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(p) => p.theme.palette.error.main} !important;
      }
    }

    .MuiFormHelperText-root {
      margin-left: 0;
    }

    .MuiInputBase-hiddenLabel {
      fieldset {
        top: 0;
      }
      legend {
        display: none;
      }
    }

    &.bgcolor-dark {
      background-color: #2f2c37;

      .Mui-disabled {
        background-color: #2f2c37 !important;
      }
    }
  }

  &.MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiInputBase-adornedStart {
    .MuiSvgIcon-root:first-child {
      width: 24px;
      padding-right: 4px;
      border-right: 1px solid ${p => p.theme.palette?.primary?.contrastText};
    }
  }
`;