
import { Obj } from 'constants/types';

type MortgageType = {
  id?: number;
  name?: string;
  enableCapitalRepaidRate?: boolean;
  enableCapitalRepaymentPeriod?: boolean;
  code?: string;
};

export type FinancePackage = {
  amount?: number;
  deposit?: number;
  property?: number;
  term?: number;
  rate?: number;
  type?: string;
  loanToValue?: number;
}


type BankType = {
  id?: number;
  logoUri?: string;
  lat?: number;
  lng?: number;
  mortgageContactEmail?: string;
  mortgageContactPhoneNumber?: string;
  name?: string;
  representationEmail?: string;
  representationName?: string;
  representationPhoneNumber?: string;
  address?: string;
  street?: string;
  // city
  // continent
  // country
  // district
  // region
}

export class FinancialMortgage {
  mortgageType?: MortgageType;
  monthlyPayment?: number;

  constructor({ mortgageType, monthlyPayment }: Obj) {
    if (mortgageType !== undefined) this.mortgageType = mortgageType;
    if (monthlyPayment !== undefined) this.monthlyPayment = monthlyPayment;

    return this;
  }
}

export class FinancialPackage {
  id?: number;
  financialPackageId?: number;
  mortgageTypeId?: number;
  totalPrice?: number;
  mortgagePackage?: string;
  mortgagePackageName?: string;
  mortgageTypeBank?: string;
  bankId?: number;
  availablePlanId?: number;
  maxLoanDuration?: number;
  interestRate?: number;
  amountNeedToBorrow?: number;
  downPayment?: number;
  loanToValue?: number;
  logoBank?: string;
  interestRateLockDuration?: number;
  repaymentTimelineYears?: number;
  type?: string;
  bareshellPrice?: number;

  constructor({
    id,
    financialPackageId,
    mortgageTypeId,
    mortgagePackage,
    mortgageTypeBank,
    type,
    bankId,
    totalPrice,
    
    availablePlanId,
    maxLoanDuration,
    interestRate,
    amountNeedToBorrow,
    downPayment,
    loanToValue,
    logoBank,
    mortgagePackageName,
    interestRateLockDuration,
    repaymentTimelineYears,
    bareshellPrice
  }: FinancialPackage) {
    if (id !== undefined)
      this.id = id;
    if (financialPackageId !== undefined)
      this.financialPackageId = financialPackageId;
    if (mortgageTypeId !== undefined) this.mortgageTypeId = mortgageTypeId;
    if (mortgagePackage !== undefined) this.mortgagePackage = mortgagePackage;
    if (bankId !== undefined) this.bankId = bankId;
    if (mortgageTypeBank !== undefined) this.mortgageTypeBank = mortgageTypeBank;
    if (type !== undefined) this.type = type;
    if (totalPrice !== undefined) this.totalPrice = totalPrice;
    if (availablePlanId !== undefined) this.availablePlanId = availablePlanId;
    if (maxLoanDuration !== undefined) this.maxLoanDuration = maxLoanDuration;
    if (interestRate !== undefined) this.interestRate = interestRate;
    if (amountNeedToBorrow !== undefined) this.amountNeedToBorrow = amountNeedToBorrow;
    if (downPayment !== undefined) this.downPayment = downPayment;
    if (loanToValue !== undefined) this.loanToValue = loanToValue;
    if (logoBank !== undefined) this.logoBank = logoBank;
    if (mortgagePackageName !== undefined) this.mortgagePackageName = mortgagePackageName;
    if (interestRateLockDuration !== undefined) this.interestRateLockDuration = interestRateLockDuration;
    if (repaymentTimelineYears !== undefined) this.repaymentTimelineYears = repaymentTimelineYears;
    if (bareshellPrice !== undefined) this.bareshellPrice = bareshellPrice;

    return this;
  }
};

export class FinancialQuestion {
  id?: number;
  grossAnnualSalary?: number;
  fixedAnnualOutgoings?: number;
  projectMultiplier?: number;
  downPayment?: number;
  grossIncomeAfterDeductions?: number;
  totalPrice?: number;
  maximumLoan?: number;
  borrowAmount?: number;
  loanToValue?: number;
  repaymentTimelineYears?: number;
  interestRateLockDuration?: number;
  isNextToChoosePackage?: boolean;
  errorMessageLoantoValue? = "Your loan-to-value ratio exceeds the acceptable limit. Please adjust your input or proceed reserving your Levitate home without a financial package.";
  errorMessageNeedToBorrow? = "The amount of money you need to borrow is exceed your est.maximum Financial Plan. Please adjust your input proceed reserving your Levitate home without a financial package.";
  message?: string;
  currencyCode?: string;
  projectId?: number;
  baresellPrice? : number
  constructor({
    id,
    grossAnnualSalary,
    fixedAnnualOutgoings,
    projectMultiplier,
    downPayment,
    grossIncomeAfterDeductions,
    totalPrice,
    maximumLoan,
    borrowAmount,
    loanToValue,
    repaymentTimelineYears,
    interestRateLockDuration,
    isNextToChoosePackage,
    message,
    currencyCode,
    projectId,
    baresellPrice
  }: FinancialQuestion) {
    if (id !== undefined) this.id = id;
    if (grossAnnualSalary !== undefined) this.grossAnnualSalary = grossAnnualSalary;
    if (fixedAnnualOutgoings !== undefined) this.fixedAnnualOutgoings = fixedAnnualOutgoings;
    if (projectMultiplier !== undefined) this.projectMultiplier = projectMultiplier;
    if (downPayment !== undefined) this.downPayment = downPayment;
    if (grossIncomeAfterDeductions !== undefined) this.grossIncomeAfterDeductions = grossIncomeAfterDeductions;
    if (totalPrice !== undefined) this.totalPrice = totalPrice;
    if (maximumLoan !== undefined) this.maximumLoan = maximumLoan;
    if (borrowAmount !== undefined) this.borrowAmount = borrowAmount;
    if (loanToValue !== undefined) this.loanToValue = loanToValue;
    if (repaymentTimelineYears !== undefined) this.repaymentTimelineYears = repaymentTimelineYears;
    if (interestRateLockDuration !== undefined) this.interestRateLockDuration = interestRateLockDuration;
    if (isNextToChoosePackage !== undefined) this.isNextToChoosePackage = isNextToChoosePackage;
    if (message !== undefined) this.message = message;
    if (currencyCode !== undefined) this.currencyCode = currencyCode;
    if (projectId !== undefined) this.projectId = projectId;
    if (baresellPrice !== undefined) this.baresellPrice = baresellPrice
    return this;
  }
};

export class AvailablePlan {
  id?: number;
  bankId?: number;
  bank?: BankType;
  capitalRepaidRate?: number;
  interestRate?: number;
  interestRateCapitalRepaymentPeriod?: number;
  maxLoanDuration?: number;
  monthlyPayment?: number;
  mortgageTypeId?: number;
  name?: string;

  constructor({
    id,
    bankId,
    bank,
    capitalRepaidRate,
    interestRate,
    interestRateCapitalRepaymentPeriod,
    maxLoanDuration,
    monthlyPayment,
    mortgageTypeId,
    name
  }: Obj) {
    if (id !== undefined) this.id = id;
    if (bankId !== undefined) this.bankId = bankId;
    if (bank !== undefined) this.bank = bank;
    if (capitalRepaidRate !== undefined) this.capitalRepaidRate = capitalRepaidRate;
    if (interestRate !== undefined) this.interestRate = interestRate;
    if (interestRateCapitalRepaymentPeriod !== undefined) this.interestRateCapitalRepaymentPeriod = interestRateCapitalRepaymentPeriod;
    if (maxLoanDuration !== undefined) this.maxLoanDuration = maxLoanDuration;
    if (monthlyPayment !== undefined) this.monthlyPayment = monthlyPayment;
    if (mortgageTypeId !== undefined) this.mortgageTypeId = mortgageTypeId;
    if (name !== undefined) this.name = name;

    return this;
  }
}

export class LoanSchedule {
  amountOwed?: number;
  estNetCost?: number;
  estProfit?: number;
  month?: number;
  monthlyCapitalPayment?: number;
  monthlyInterestPayment?: number;
  monthlyPayment?: number;
  date?: string;

  constructor({
    amountOwed,
    estNetCost,
    estProfit,
    month,
    monthlyCapitalPayment,
    monthlyInterestPayment,
    monthlyPayment,
    date
  }: Obj) {
    if (amountOwed !== undefined) this.amountOwed = amountOwed;
    if (estNetCost !== undefined) this.estNetCost = estNetCost;
    if (estProfit !== undefined) this.estProfit = estProfit;
    if (month !== undefined) this.month = month;
    if (monthlyCapitalPayment !== undefined) this.monthlyCapitalPayment = monthlyCapitalPayment;
    if (monthlyInterestPayment !== undefined) this.monthlyInterestPayment = monthlyInterestPayment;
    if (monthlyPayment !== undefined) this.monthlyPayment = monthlyPayment;
    if (date !== undefined) this.date = date;
    return this;
  }

  fromPayload({
    amountOwed,
    estNetCost,
    estProfit,
    month,
    monthlyCapitalPayment,
    monthlyInterestPayment,
    monthlyPayment,
  }: Obj): this {
    if (amountOwed !== undefined) this.amountOwed = amountOwed;
    if (estNetCost !== undefined) this.estNetCost = estNetCost;
    if (estProfit !== undefined) this.estProfit = estProfit;
    if (month !== undefined) this.month = month;
    if (monthlyCapitalPayment !== undefined) this.monthlyCapitalPayment = monthlyCapitalPayment;
    if (monthlyInterestPayment !== undefined) this.monthlyInterestPayment = monthlyInterestPayment;
    if (monthlyPayment !== undefined) this.monthlyPayment = monthlyPayment;
    
    return this;
  }
}

