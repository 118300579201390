import { Interceptor } from 'utils/requestHelpers';
import { Obj } from 'constants/types';
import { getAccessToken, getFirebaseAccessToken, logout } from 'services/authService';
import { getPortalAccessToken } from 'services/portalAuthService';
import { getAgentAccessToken } from 'services/agentAuthService';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';
import { checkInteractMobile } from 'utils/dataHelpers';

declare module 'axios' {
  interface AxiosRequestConfig {
    options?: {
      notifySuccess?: boolean; // don't display notification when success
      useFirebaseToken?: boolean;
      newAbortSignal?: () => AbortSignal;
    };
  }
}

export const dataInterceptor: Interceptor = {
  onRequest: config => {
    config.headers!['Accept-Language'] = i18n.language;
    return config;
  },
  onResponse: ({ data }) => data,
}


export const abortInterceptor: Interceptor = {
  onRequest: config => {
    if (config?.options?.newAbortSignal) {
      config.signal = config?.options?.newAbortSignal();
    }
    return config;
  },
  onResponseError: error => {
    if (error.code === 'ERR_CANCELED') {
      console.error('Cancelled request', error);
      return Promise.reject('Request was aborted!');
    }
    
    return Promise.reject(error);
  }
};

export const notifyInterceptor: Interceptor = {
  // onResponse: response => {
  //   const {
  //     data,
  //     config: { options },
  //   } = response;
  //   if (!options?.notifySuccess) return response;
  //   toast.success(data.message);
  //
  //   return response;
  // },
  onResponseError: error => {
    // timeout, request cancelled
    if (error.code === 'ECONNABORTED') {
      toast.error('Request timeout. Please try it again');
    } else if (error?.response?.data) {
      // @ts-ignore
      const { data: { errors, message }, status } = error.response;
      
      if (status === 500) {
        toast.error('Internal server error!')
      }
      else if (!isEmpty(errors)) {
        errors.forEach((e: { message: string }) => {
          if (e.message) {
            toast.error(e.message);
          }
        });
      } else if (message) {
        toast.error(message);
      }
    }
  
    return Promise.reject(error);
  }
}

export const firebaseAuthInterceptor: Interceptor = {
  onRequest: config => {
    // if (!config?.options?.useFirebaseToken) return config;
    
    const firebaseAccessToken = getFirebaseAccessToken();
    if (firebaseAccessToken) {
      config.headers!['X-Firebase-Token'] = `Bearer ${firebaseAccessToken}`;
    }
    
    return config;
  }
}

export const homebuyerAuthInterceptor: Interceptor = {
  onRequest: (config: Obj) => {
    const accessToken = getAccessToken();
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  onResponseError: error => {
    if (error.response?.status === 401) {
      logout();
    }
    
    return Promise.reject(error);
  },
}

export const saleAgentAuthInterceptor: Interceptor = {
  onRequest: (config: Obj) => {
    const agentAccessToken = getAgentAccessToken();
    config.headers['Authorization'] = `Bearer ${agentAccessToken}`;
    
    return config;
  },
  onResponseError: error => {
    if (error.response?.status === 401) {
      logout();
    }
    
    return Promise.reject(error);
  },
}

export const portalAuthInterceptor: Interceptor = {
  onRequest: (config: Obj) => {
    const accessToken = getPortalAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    
    return config;
  },
  onResponseError: error => {
    if (error.response?.status === 401) {
      logout();
    }
    
    return Promise.reject(error);
  },
}

export const customerInterceptor: Interceptor = {
  onRequest: (config: Obj) => {
    const authDesign = getStorage(StorageKey.AuthDesign) || '';
    if (authDesign?.tokenPMS) {
      config.headers['Authorization'] = `Bearer ${authDesign?.tokenPMS}`;
      config.headers['accept_address'] = 'levitate_pro';
    }
    
    return config;
  },
  onResponseError: error => {
    if (error.response?.status === 401) {
      const currentPath = window.location.pathname;
      const pattern = /^\/order-confirm\/\d+\/location\/\d+\/project\/\d+$/;
      if (pattern.test(currentPath)) {
        localStorage.setItem("PREV_PATH", currentPath+"_LOGIN");
      }
      if (checkInteractMobile()) {

      } else {
        logout();
      }
    }
    
    return Promise.reject(error);
  },
}

export const userInterceptor: Interceptor = {
  onRequest: (config: Obj) => {
    const authDesign = getStorage(StorageKey.AuthDesign) || '';
    if (authDesign?.tokenUMS) {
      config.headers['Authorization'] = `Bearer ${authDesign?.tokenUMS}`;
      // config.headers['accept_address'] = 'levitate_pro';
    }
    
    return config;
  },
  onResponseError: error => {
    if (error.response?.status === 401) {
      if (checkInteractMobile()) {

      } else {
        logout();
      }
    }
    
    return Promise.reject(error);
  },
}
