export const TERM_PL = (textBrand: string) => {
  return [
    {
      title: 'Akceptacja Regulaminu',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Korzystając z <a href="https://levitate.homes">https://levitate.homes</a> (witryna internetowa), zawierasz wiążącą umowę na warunkach i zgodnie z postanowieniami niniejszego Regulaminu ("Warunki Korzystania") z nami, ${textBrand} International Pte. Ltd. ("my", "nas", "nasz" lub "${textBrand}"), właścicielem i administratorem witryny internetowej.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Akceptujesz bez ograniczeń lub zastrzeżeń Warunki Korzystania, w tym Politykę Prywatności.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Zastrzegamy sobie prawo do zmiany Warunków Korzystania w dowolnym czasie, publikując nową wersję na witrynie internetowej. Jednak nie zostanie do ciebie wysłane indywidualne powiadomienie. Wszystkie zmienione Warunki Korzystania zastąpią wszystkie poprzednie wersje. Jeśli nie zgadzasz się na zmiany lub modyfikacje w Warunkach Korzystania, powinieneś natychmiast zaprzestać korzystania z usług na witrynie internetowej. Po wejściu w życie takiej zmiany będziemy uważać, że dalsze korzystanie z witryny internetowej stanowi twoją akceptację i zgodę na taką zmianę. Możesz w dowolnym momencie przeglądać najnowszą wersję Warunków Korzystania.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Od czasu do czasu będziemy publikować na witrynie internetowej wytyczne i zasady dotyczące korzystania z witryny. Wszystkie takie wytyczne lub zasady wchodzą w skład Warunków Korzystania przez odniesienie.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Penggunaan Laman Web',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>Anda bersetuju untuk mematuhi semua terma dan syarat di bawah Terma Penggunaan ini dalam menggunakan Laman Web. Kami boleh mengeluarkan atau menghapuskan sebarang kandungan yang anda berikan di Laman Web sekiranya kami, atas budi bicara mutlak kami, mengesyaki bahawa anda telah melanggar syarat-syarat di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  Dalam menjalankan sebarang urus niaga dengan kami di Laman Web atau berkaitan dengan Laman Web, atau mendaftar akaun dengan kami di Laman Web, anda menjamin bahawa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Anda dibenarkan secara undang-undang untuk menjalankan urus niaga sendiri;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Anda menggunakan identiti sebenar anda;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Data peribadi yang anda berikan kepada kami adalah benar, tepat, lengkap, dan terkini; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Anda akan mengekalkan dan dengan segera mengemas kini butiran peribadi anda dan memastikan bahawa maklumat tersebut adalah benar, tepat, lengkap, dan terkini.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>Pengguna di bawah umur (pengguna di bawah umur yang diatur di bawah undang-undang negara masing-masing) tidak layak untuk menggunakan Laman Web atau memberikan sebarang data peribadi di Laman Web tanpa pengawasan. Jika anda di bawah umur, anda boleh menggunakan Laman Web dan/atau memberikan sebarang data peribadi di Laman Web hanya selepas anda mendapatkan persetujuan dari ibu bapa/penjaga undang-undang atau jika anda di bawah pengawasan ibu bapa/penjaga undang-undang anda.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>Jika kami telah memberikan anda (atau jika anda telah memilih) kata laluan yang membolehkan anda mengakses bahagian-bahagian tertentu Laman Web, anda mengakui bahawa anda sepenuhnya bertanggungjawab untuk mengekalkan kerahsiaan kata laluan anda, dan segala aktiviti yang berlaku di bawah akaun anda. Kami tidak mempunyai tanggungjawab untuk mengesahkan sama ada pengguna yang log masuk dan menggunakan Laman Web adalah anda atau bukan.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>Anda bersetuju untuk memberitahu kami jika terdapat sebarang pelanggaran keselamatan terhadap kata laluan anda.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>Kami tidak akan bertanggungjawab atas sebarang kerugian atau kerosakan yang timbul daripada kegagalan anda mematuhi Fasal 2 ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.7.</div>
                <div>Anda tidak dibenarkan untuk membingkai atau menggunakan teknik pembingkaian untuk menyelitkan mana-mana cap dagang, logo, atau maklumat milik kami dan afiliasi kami (termasuk syarikat induk, "Afiliasi") tanpa persetujuan bertulis daripada kami. Penggunaan tanpa kebenaran akan mengakhiri izin atau persetujuan yang diberikan oleh kami.</div>
              </div>
            
          </div>
        `
    },
    {
      title: 'Materiały na stronie internetowej',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Korzystając z witryny, możesz zostać narażony na informacje, dane, tekst, oprogramowanie, fotografie, grafiki, filmy, wiadomości, koncepcje, pomysły, projekty, rysunki, listy produktów, ceny lub inne materiały, które my lub inni użytkownicy witryny mogą przesyłać, zamieszczać, wysyłać e-mailem lub udostępniać na witrynie ("Materiały na stronie internetowej"), zarówno publicznie, jak i prywatnie.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Rozumiesz, że Materiały na stronie internetowej mogą być obraźliwe, nieprzyzwoite lub budzące zastrzeżenia.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Rozumiesz, że Materiały na stronie internetowej można wykorzystywać wyłącznie do własnego użytku i niekomercyjnego, a nie do publikacji, dystrybucji, przekazywania, ponownego przekazywania, redystrybucji, nadawania, reprodukcji ani obiegu dla kogoś innego w tej samej firmie lub organizacji, ani do zamieszczania na innych witrynach lub forach, grupach dyskusyjnych, listach mailingowych, tablicach ogłoszeń elektronicznych ani czatach internetowych obsługiwanych przez inne witryny.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Zgadzasz się, że nie będziesz reprodukować, kopiować, sprzedawać, odsprzedawać, wyświetlać ani w inny sposób udostępniać Materiałów na stronie internetowej na innej witrynie lub serwerze bez naszej wcześniejszej pisemnej zgody.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Nie ponosimy odpowiedzialności za żadne błędy ani zaniedbania w Materiałach na stronie internetowej ani za jakiekolwiek straty ani uszkodzenia jakiegokolwiek rodzaju poniesione w wyniku korzystania z Materiałów na stronie internetowej zamieszczonych, przesłanych, przesyłanych lub udostępnianych na witrynie.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>Przyjmujesz do wiadomości i zgadzasz się, że format i treść witryny mogą być zmieniane w dowolnym momencie bez uprzedzenia; działanie witryny może być zawieszone lub zakończone ze względu na prace wsparcia lub konserwacyjne, aktualizację treści lub z innych powodów; a dostęp do witryny może być wstrzymany w odniesieniu do konkretnych użytkowników lub ogólnie w dowolnym momencie i bez uprzedzenia.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Dokładność informacji na naszej stronie internetowej',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Chociaż ${textBrand} podejmuje rozsądne wysiłki, aby umieścić dokładne informacje na stronie internetowej, nie udzielamy żadnych gwarancji ani reprezentacji co do dokładności jakichkolwiek udzielonych informacji, zarówno domyślnych, jak i innych, w zakresie dozwolonym przez prawo. ${textBrand} wyłącza wszelką odpowiedzialność za błędy lub opuszczenia w treści strony internetowej w najszerszym zakresie dozwolonym przez prawo.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Szczegóły zawarte na stronie internetowej mają charakter orientacyjny. Dokładność jakichkolwiek szczegółów, opisów, wymiarów, odniesień do stanu, niezbędnych pozwoleń na użytkowanie i zajmowanie oraz innych szczegółów zawartych na stronie internetowej nie jest gwarantowana i służy jedynie ogólnemu wskazaniu, dlatego zaleca się nie poleganie na nich jako na stwierdzeniach faktów lub reprezentacjach i zaleca się, aby osobiście sprawdzić ich dokładność.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand} zaleca, że ponieważ zakup nieruchomości stanowi znaczący zobowiązanie finansowe, powinieneś podjąć wszelkie rozsądne kroki, aby upewnić się, że dowolna nieruchomość, którą zamierzasz zakupić, jest odpowiednia dla twoich wymagań pod każdym względem. Podjęliśmy rozsądne kroki w celu zapewnienia, że materiały zawarte na stronie internetowej są dokładne, ale nie powinny one stanowić zamiennika twoich pytań ani rozważań. Zaleca się podjęcie odpowiednich kroków w celu zweryfikowania wszelkich informacji, na których chcesz polegać.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Mimo wszelkich starań, aby zapewnić dokładne odwzorowanie kolorów i wzornictwa każdego produktu na stronie internetowej, rzeczywiste kolory i wzornictwo mogą nieznacznie różnić się ze względu na różne ustawienia ekranu urządzenia, oświetlenie w miejscu instalacji, niewielkie różnice w wykończeniach produktu w czasie oraz inne czynniki. ${textBrand} nie ponosi odpowiedzialności za żadne różnice kolorów lub wzornictwa, które nie wynikają z naszej winy. Kupując nieruchomość lub produkt za pośrednictwem strony internetowej, zgadzasz się na zaakceptowanie niewielkiego ryzyka związanego z różnicami między rzeczywistymi kolorami i wzornictwem a przedstawieniem na stronie internetowej.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Zobowiązania Użytkownika',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Oprócz zobowiązań określonych w niniejszych Warunkach Korzystania, NIE WOLNO CI:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Korzystać z Witryny w sposób sprzeczny z obowiązującymi przepisami prawa;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Działać w sposób szkodzący, nadużywający, nękanie, śledzenie, zniesławienie, obelgi, groźby lub w inny sposób obrażanie innych osób, lub używać Witryny w celu dokonywania takich działań;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Powodować lub być w stanie powodować niedogodności, straty lub szkody dla użytkowników, osób trzecich lub ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Podejmować działania polegające na oszustwach w celu uzyskania lub utrzymania wielu rejestracji członkowskich;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Uczestniczyć w działaniach niemoralnych lub potencjalnie niemoralnych, w tym, ale nie tylko, w prostytucji i pornografii;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Podawać się za inną osobę lub podmiot lub fałszywie oświadczać lub w inny sposób przekształcać swoje powiązanie z inną osobą lub podmiotem;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Podawać fałszywe, niedokładne lub wprowadzające w błąd informacje;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Angażować się w potencjalnie oszukańczą, podejrzaną lub nielegalną działalność i/lub transakcje;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Wysyłać niechciane e-maile do dowolnego użytkownika lub używać usług na Witrynie do pobierania płatności za wysyłanie lub pomaganie w wysyłaniu niechcianych e-maili do osób trzecich;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Ujawniać lub rozpowszechniać dane osobowe innego użytkownika osobie trzeciej lub używać tych informacji w celach marketingowych bez wyraźnej zgody tego użytkownika;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Podejmować działania, które powodują nadmierny lub nadmiernie duży obciążenie infrastruktury Witryny;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Wgrywać, lub powodować wgranie, jakiejkolwiek zawartości na Witrynę, która zawiera wirusy lub inne elementy, które mogą uszkodzić, zakłócić lub w inny sposób negatywnie wpływać lub utrudniać dostęp do Witryny;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Angażować się w spamowanie, phishing, nieodpowiednie, złośliwe lub, według naszego wyłącznego uznania, oszukańcze klikanie, wyświetlenia lub działania marketingowe związane z ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>Według naszego wyłącznego uznania, wpływać negatywnie lub oszpecać reputację ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Modyfikować, dostosowywać, przekształcać, rekompilować, transmitować, publikować, licencjonować, reverse engineering, rozkładać na części, rekonstruować, dekompilować, kopiować lub tworzyć utwory pochodne, przenosić lub sprzedawać jakiekolwiek usługi na Witrynie lub ich część, w tym kody źródłowe lub algorytmy, chyba że jest to wyraźnie autoryzowane przez nas na piśmie lub zgodnie z obowiązującym prawem;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Zmieniać, usuwać, zakrywać lub w inny sposób usuwać identyfikację, znak towarowy, prawo autorskie lub inny znak z jakiejkolwiek części usług na Witrynie;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Używać jakiegokolwiek nieautoryzowanego oprogramowania firm trzecich, które ma na celu dostęp, przeszukiwanie lub zbieranie danych z usług ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Używać jakiegokolwiek nieautoryzowanego oprogramowania firm trzecich, które przechwytuje, "wydobywa" lub w inny sposób zbiera informacje z ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Wgrywać, transmitować, umieszczać lub w inny sposób udostępniać jakąkolwiek treść, która jest lub mogłaby być uznana za obraźliwą, dyskryminacyjną, zniesławiającą, nieprzyzwoitą, groźną, niezgodną z prawem, w tym treść, która jest prawdopodobnie obraźliwa, obrażająca lub upokarzająca innych na podstawie rasy, religii, pochodzenia etnicznego, płci, wieku, orientacji seksualnej lub jakiejkolwiek niepełnosprawności fizycznej lub psychicznej; lub
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Nie jest twoją oryginalną pracą, lub może naruszać własność intelektualną lub inne prawa innej osoby;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>Zawiera obraz lub dane osobowe innej osoby bez jej zgody; lub</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Zawiera dużą ilość niecelowanych, niechcianych lub powtarzających się treści; lub</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Zakłócać, przerywać lub tworzyć nadmierne obciążenie na infrastrukturze Witryny lub naszej działalności.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Jeśli uważasz, że użytkownik naruszył którekolwiek z powyższych warunków, skontaktuj się z nami.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Zastrzegamy sobie prawo do zablokowania, zawieszenia lub anulowania dowolnego użytkownika, rejestracji członkowskiej na naszej Witrynie oraz zmiany lub usunięcia jakiejkolwiek zawartości przesłanej, zamieszczonej, przesłanej pocztą lub w inny sposób udostępnionej na naszej Witrynie przez dowolnego użytkownika, bez powiadomienia; jednoznacznie zdefiniować i ograniczyć, odmówić i/lub odrzucić zwroty od klientów w dowolnym momencie z powodu nieregularnej lub nadmiernej historii zwrotów. Przesyłając, przesyłając pocztą, umieszczając lub w inny sposób udostępniając jakikolwiek materiał za pośrednictwem Witryny, udzielasz ${textBrand} nie wyłącznej, na całym świecie, bezpłatnej, nieograniczonej w czasie licencji na korzystanie, reprodukcję, edycję i eksploatację tego materiału w dowolnej formie i w jakimkolwiek celu. Wniosek o członkostwo może nie zostać zatwierdzony, jeśli wnioskodawca miał wcześniej anulowane swoje członkostwo.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Nie ponosimy odpowiedzialności za jakiekolwiek materiały przesłane, zamieszczone, przesłane pocztą lub w inny sposób udostępnione na naszej Witrynie przez osoby inne niż my. Nie popieramy żadnych opinii, porad ani oświadczeń dokonanych przez osoby inne niż ${textBrand}.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Zgadzasz się na zwolnienie ${textBrand} i każdego z jej urzędników, pracowników, agentów, kontrahentów, dostawców i licencjodawców (zwanych "Powiązanymi stronami") z odpowiedzialności, strat lub szkód (w tym wszelkie koszty prawne i inne na zasadzie pełnego zwrotu kosztów) poniesionych lub doznanych przez nich w wyniku (całkowicie lub częściowo) naruszenia lub niestosowania się do jakiejkolwiek z niniejszych Warunków Korzystania lub innych nieprawidłowych działań lub zachowań w związku z przedmiotem niniejszych Warunków Korzystania, ze strony Ciebie lub któregokolwiek z Twoich powiązanych stron.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Oświadczenie o braku gwarancji',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Twoje korzystanie z Witryny odbywa się na wyłączne ryzyko. Wyłączamy wszelkie gwarancje w jakiejkolwiek formie, czy to wyraźne, czy dorozumiane.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Zastrzegamy sobie prawo do modyfikowania i zawieszania, tymczasowo lub na stałe, dowolnych usług na Witrynie (lub dowolnej jej części) z lub bez uprzedzenia. Zgadzasz się, że nie będziemy ponosić odpowiedzialności wobec ciebie ani wobec żadnej strony trzeciej za jakąkolwiek modyfikację, zawieszenie lub zakończenie takich usług.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Nie udzielamy żadnej gwarancji co do tego, że:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Witryna będzie spełniać twoje wymagania;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Witryna będzie działać bez przerw, terminowo, bezpiecznie i bezbłędnie;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Jakiekolwiek wyniki, które mogą wyniknąć z korzystania z Witryny, będą dokładne lub rzetelne; oraz</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Jakość jakichkolwiek produktów, usług, informacji lub innych materiałów zakupionych lub uzyskanych za pośrednictwem Witryny będzie odpowiadać twoim oczekiwaniom.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Nie możemy i nie popieramy, ani nie ponosimy odpowiedzialności za wiadomości, poglądy, opinie, badania i rekomendacje osób, użytkowników i organizacji uznanych za interesujące, ani nie popieramy żadnego patronatu wobec żadnego dostawcy usług, sposobu leczenia lub transakcji handlowych w jakiekolwiek papiery wartościowe, inwestycje czy klasy papierów wartościowych lub inwestycji, a także umieszczenie na Witrynie łączy do innych witryn lub zasobów nie implikuje żadnej formy poparcia ze strony nas.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Nie dokonujemy aktywnej recenzji ani edycji wiadomości, poglądów, opinii, badań i rekomendacji użytkowników, nawet jeśli takie wiadomości, poglądy, opinie, badania i rekomendacje są publikowane przez użytkowników korzystających z Witryny. Chociaż informacje na Witrynie pochodzą z wiarygodnych źródeł, żadne z danych, wiadomości, informacji, raportów, opinii ani żadne z rekomendacji (jeśli takie istnieją) nie zostały dostosowane do konkretnej osoby ani klasy osób.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Udostępniając informacje i dane na Witrynie, w tym fakty, poglądy, opinie i rekomendacje osób i organizacji uznanych za interesujące, my i nasze podmioty dostarczające treści nie udzielamy ani nie uznajemy, że udzielamy porad finansowych, inwestycyjnych, podatkowych i prawnych. Zawsze powinieneś szukać odpowiedniej porady zawodowej przed podjęciem takich decyzji.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>Możemy monitorować lub sprawdzać dowolne obszary na Witrynie, gdzie przesyłasz lub zamieszczasz komunikaty w celu promowania ich wydajności, użyteczności i odpowiedniości użytkowania. Jednak nie zobowiązujemy się monitorować lub sprawdzać każdego postu lub komunikacji i wyłączamy wszelką odpowiedzialność związaną z wszelkim Materiałem Witryny, niezależnie od tego, czy wynika on z przepisów dotyczących praw autorskich, znaków towarowych, zniesławienia, prywatności, obsceniczności czy w inny sposób.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Ta Witryna i wszelkie informacje w niej zawarte są dostarczane przez ${textBrand} na zasadzie "tak jak jest" i "tak jak dostępne". ${textBrand} i/lub jego odpowiednie podmioty powiązane, udziałowcy, partnerzy, dyrektorzy, pracownicy i agenci:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Nie składają żadnych oświadczeń ani nie dają żadnych gwarancji w jakiejkolwiek formie, wyraźnych ani dorozumianych, co do funkcjonowania i dostępności tej Witryny ani informacji, treści, materiałów lub produktów prezentowanych na Witrynie. Wyraźnie zgadzasz się, że korzystanie z tej Witryny odbywa się na wyłączne ryzyko w zakresie dozwolonym przez obowiązujące prawo;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Wyłączają wszelkie gwarancje, wyraźne lub dorozumiane, w tym, ale nie ograniczając się do, dorozumianych gwarancji dotyczących przydatności do określonego celu;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Nie gwarantują, że ta Witryna, jej serwery ani e-maile wysyłane przez nas są wolne od wirusów lub innych szkodliwych komponentów lub błędów; oraz</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Nie ponoszą odpowiedzialności za jakiekolwiek szkody jakiejkolwiek natury, w tym, ale nie ograniczając się do, szkód bezpośrednich, pośrednich, przypadkowych, karalnych i wynikających lub jakichkolwiek innych strat lub odpowiedzialności wynikających z (a) korzystania lub niemożności korzystania z tej Witryny; (b) jakiejkolwiek decyzji podjętej, działania lub zaniechania podjętej przez jakąkolwiek stronę na podstawie treści tej Witryny; (c) przerwy w działalności; (d) opóźnienia/przerwy w dostępie do tej Witryny; (e) braku dostarczania danych, błędy w dostarczaniu, korupcji, zniszczenia lub innych modyfikacji danych; (f) utraty lub szkód jakiejkolwiek natury poniesionej w wyniku korzystania z łączy do stron trzecich na tej Witrynie; (g) wirusów komputerowych, awarii lub zakłóceń systemu związanego z korzystaniem z tej Witryny; lub (h) wszelkie opóźnienia, braki w dokładności lub zaniechania w treści tej Witryny.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Korzystając z naszej Witryny, podejmujesz wszelkie ryzyko związane z jej używaniem, w tym, ale nie ograniczając się do ryzyka, że twój komputer, urządzenia, oprogramowanie lub dane mogą zostać uszkodzone przez wirusy przenoszone przez tę Witrynę lub przez treści stron trzecich lub stron trzecich, a także ryzyko związane z jakością usług świadczonych przez dostawcę usług (jeśli istnieje). W zakresie dozwolonym przez prawo, wszystkie gwarancje, warunki i roszczenia (czy wyraźne czy dorozumiane) wynikające związane z tą Witryną są tutaj wyłączone.
              </div>
          </div>
        `
    },
    {
      title: 'Odpowiedzialność odszkodowawcza',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Zgadzasz się zrekompensować i zwolnić ${textBrand}, naszych afiliantów, akcjonariuszy, partnerów, dyrektorów, pracowników oraz agentów od wszelkich roszczeń lub żądań, w tym opłat adwokackich, zgłoszonych przez osoby trzecie z powodu lub w wyniku treści, które przesyłasz, umieszczasz, przekazujesz lub udostępniasz na stronie internetowej, twojego korzystania ze strony internetowej, twojego połączenia z nią, naruszenia przez ciebie warunków korzystania, lub naruszenia twoich praw lub praw innej strony.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Ograniczenie Odpowiedzialności',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Nie ponosimy odpowiedzialności wobec Ciebie za żadne bezpośrednie, pośrednie, przypadkowe, specjalne, następcze ani wzorcowe szkody, w tym między innymi za utratę zysków, reputacji, korzystania, danych lub innych szkód niematerialnych (nawet jeśli zostaliśmy poinformowani o możliwości takich szkód), wynikające z:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Użytkowania lub niemożności użytkowania Strony internetowej;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Kosztów zakupu zamiennych produktów i usług wynikających z zakupu lub uzyskania jakichkolwiek produktów, danych, informacji lub usług, otrzymanych wiadomości lub dokonanych transakcji za pośrednictwem Strony internetowej;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Nieautoryzowanego dostępu lub zmiany Twoich transmisji lub danych;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Oświadczeń lub zachowania osób trzecich na Stronie internetowej; oraz</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Jakiejkolwiek innej sprawy związanej ze Stroną internetową.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Każde roszczenie każdej natury przez którąkolwiek ze stron przeciwko drugiej stronie oraz każda czynność przeciwko drugiej stronie musi zostać rozpoczęta w ciągu 03 miesięcy (lub okresu minimalnego, o ile przewidzianego odpowiednim prawem) od daty, w której powstało podstawowe roszczenie, po upływie tego okresu oskarżona strona nie będzie miała żadnego kolejnego roszczenia wobec drugiej strony.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Linki i treści stron trzecich',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>Strona internetowa może zawierać odnośniki hipertekstowe do witryn obsługiwanych przez strony trzecie w celach informacyjnych i referencyjnych. ${textBrand} nie przeglądał i nie ponosi odpowiedzialności za polityki i praktyki prywatności innych witryn, nawet jeśli uzyskasz do nich dostęp przez odnośniki ze strony internetowej. Zalecamy sprawdzenie warunków korzystania i polityki prywatności każdej odwiedzanej witryny oraz skontaktowanie się z jej właścicielem lub operatorem w przypadku pytań lub wątpliwości. Podobnie, jeśli połączyłeś się ze stroną internetową ${textBrand} z witryny strony trzeciej, ${textBrand} nie ponosi odpowiedzialności za polityki i praktyki prywatności właścicieli lub operatorów tej witryny strony trzeciej. Zalecamy sprawdzenie polityki tej witryny strony trzeciej oraz skontaktowanie się z jej właścicielem lub operatorem w przypadku pytań lub wątpliwości. ${textBrand} nie udziela żadnych gwarancji ani reprezentacji dotyczących dowolnych połączonych witryn stron trzecich. ${textBrand} wyklucza wszelką odpowiedzialność za straty lub szkody w najszerszym zakresie dozwolonym przez prawo wynikające z treści na takich witrynach.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Dla wyjaśnienia, obecność odnośnika do witryny stron trzecich nie będzie uznawana za polecenie takich witryn ani za udzielanie przez ${textBrand} jakiejkolwiek rady lub informacji na ich temat.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Polityka prywatności',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Wszystkie dane osobowe o Tobie podlegają naszej Polityce Prywatności.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Polityka Prywatności jest uznawana za włączoną do Warunków Korzystania poprzez odwołanie do tej klauzuli.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Jeśli którykolwiek użytkownik Witryny lub inna osoba przegląda dokument opublikowany przez ${textBrand} i odpowiada na niego jakimikolwiek informacjami lub uwagami, takimi jak pytania, komentarze, sugestie czy podobne, informacje te będą uznawane za nie poufne, a ${textBrand} ma prawo do korzystania, reprodukcji, ujawnienia i rozpowszechniania tych informacji wobec innych osób.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Dodatkowo, ${textBrand} ma prawo do korzystania lub wykorzystania jakichkolwiek pomysłów, koncepcji, know-how lub technik zawartych w tych informacjach/opiniach w dowolnym celu, bezpłatnie, w tym, lecz nie wyłącznie, do opracowywania, wytwarzania i promowania produktów zawierających takie informacje.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Własność Intelektualna i Prawo Autorskie',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Akceptujesz i przyjmujesz, że wszelkie prawa autorskie, prawa baz danych i inne prawa własności intelektualnej, które istnieją w projektach, układzie, procesach, funkcjach, danych i treści Witryny są własnością ${textBrand}, naszych dostawców informacji lub licencjodawców. Witryna jest własnością i jest zarządzana przez ${textBrand}. Żaden fragment Witryny, jej treści ani oprogramowania ani baz danych stanowiących jej podstawę nie może być kopiowany ani reprodukowany, udostępniany osobom trzecim, poddawany działaniom odtwarzania, demontażu wstecznemu ani wykorzystywany do tworzenia jakiejkolwiek pracy pochodnej (zarówno w formie fizycznej, jak i elektronicznej) bez wcześniejszej pisemnej zgody ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Siła Wyższa',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Nie ponosimy odpowiedzialności za żadne opóźnienia ani niepowodzenia w wykonywaniu naszych zobowiązań względem Ciebie z powodu okoliczności leżących poza naszą kontrolą, w tym między innymi zdarzeń losowych, wojny lub terroryzmu, epidemii, pandemii, klęsk żywiołowych, problemów z połączeniem internetowym, problemów systemowych, zmian w obowiązujących przepisach, regulacjach lub politykach rządowych oraz niedoborów dostaw i usług.
              </div>
          </div>
        `
    },
    {
      title: 'Rozwiązanie',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Możemy, w określonych okolicznościach i bez wcześniejszego zawiadomienia, według naszego wyłącznego uznania, natychmiast zakończyć Twoje konto (jeśli takie istnieje). Przyczyny takiego rozwiązania mogą obejmować między innymi:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Naruszenie Warunków korzystania lub innych włączonych dokumentów, wytycznych lub zasad;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Żądanie organów ścigania lub innych agencji rządowych;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Usunięcie konta z własnej inicjatywy;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Naruszenie praw własności intelektualnej innych;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Przerwanie lub istotna modyfikacja usług na stronie internetowej lub jej części;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Nieoczekiwane problemy techniczne lub związane z bezpieczeństwem; lub</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Przedłużony okres nieaktywności.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Zakończenie Twojego konta spowoduje:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Usunięcie dostępu i zablokowanie dalszego korzystania z wszystkich ofert na stronie internetowej związanych z tym kontem; oraz</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Usunięcie hasła oraz wszystkich powiązanych informacji, plików i treści związanych z Twoim kontem (lub jego częścią).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Zakończenie Twojego konta nie będzie naruszać żadnych innych praw lub środków prawnych, do których możemy być uprawnieni na podstawie Warunków korzystania, prawa lub w drodze sądowej, ani nie będzie miało wpływu na jakiekolwiek nabyte prawa lub zobowiązania ani wejście w życie lub kontynuację obowiązywania jakichkolwiek postanowień, które są wyraźnie lub dorozumianie przeznaczone do wejścia w życie lub kontynuacji po takim zakończeniu.</div>
              </div
          </div>
        `
    },
    {
      title: 'Prawa Własności Intelektualnej',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Po przesłaniu przez Ciebie jakichkolwiek obrazów, fotografii i/lub opisów ofert na stronę internetową („<strong>Treść</strong>”), udzielasz nam światowej, bezpłatnej i nieodwołalnej licencji na korzystanie, przechowywanie, hostowanie, reprodukowanie, modyfikowanie, dostosowywanie, opatrywanie znakiem wodnym, komunikowanie, publikowanie, publiczne wykonanie, publiczne wyświetlanie, tworzenie utworów pochodnych i rozpowszechnianie Treści. Przesyłając Treść, gwarantujesz, że posiadasz prawa autorskie do Treści i masz odpowiednie uprawnienia do udzielenia nam takiej licencji. Mamy prawo umieścić znak wodny na zdjęciach w Treści, a prawa autorskie do ostatecznych zdjęć z znakiem wodnym będą należały wyłącznie do nas. Wszystkie inne wymienione nazwy, produkty i znaki towarowe są prawami własności intelektualnej ich odpowiednich właścicieli. Żadne materiały udostępnione za pośrednictwem strony internetowej, w tym teksty, grafiki, kody, oceny, rankingi, bazy danych, zgromadzone informacje, Materiały Strony internetowej i/lub oprogramowanie, nie mogą być reprodukowane, modyfikowane, dostosowywane, rozpowszechniane, publikowane, wyświetlane, przesyłane, załadowane, umieszczane ani powiązane z innymi stronami w jakikolwiek sposób i w jakiejkolwiek formie bez naszej wyraźnej, uprzedniej pisemnej zgody oraz zgody odpowiednich właścicieli praw autorskich i znaków towarowych. Strona internetowa i Materiały Strony internetowej są przeznaczone wyłącznie do użytku osobistego, a Ty nie możesz podejmować żadnych działań z innymi podmiotami dotyczących takich usług i treści. Takie działania obejmują działania komercyjne i działania, które będą miały niekorzystny wpływ na wartość komercyjną Strony internetowej i Materiałów Strony internetowej.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Ogólne',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Warunki korzystania podlegają prawom Republiki Singapuru i są interpretowane zgodnie z tymi prawami. Wszelkie roszczenia lub spory, bez względu na ich charakter, podlegają wyłącznej jurysdykcji sądów Republiki Singapuru.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Niekorzystanie przez nas z któregokolwiek prawa lub postanowienia Warunków korzystania nie będzie stanowić zrzeczenia się tego prawa lub postanowienia. Jeśli którykolwiek zapis Warunków korzystania zostanie uznany przez sąd właściwy za nieważny, strony mimo to zgadzają się, że sąd powinien dążyć do nadania skuteczności intencjom stron, odbijając intencje stron wyrażone w zapisie, a pozostałe postanowienia Warunków korzystania pozostają w pełnej mocy i skuteczności.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Konto, które możesz posiadać u nas, nie jest przenoszalne, a wszelkie prawa do takiego konta wygasną w przypadku Twojej śmierci. Po otrzymaniu kopii aktu zgonu Twojego, Twoje konto zostanie zakończone, a cała zawartość, którą wygenerowałeś w swoim koncie, zostanie trwale usunięta.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Wszystkie powiadomienia do nas zgodnie z Warunkami korzystania należy przesyłać drogą mailową na adres: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
