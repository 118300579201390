export const DATA_FAQ_DE = (textBrand: string) => {
  return [
    {
      title:
        `Wer zum Teufel ist ${textBrand} International und was bringen sie mit?`,
      description: `
            <p>Also gut, sprechen wir über den primären Immobilienmarkt und das langweilige, veraltete Verkaufserlebnis, das damit einhergeht. ${textBrand} International ist hier, um das Spiel zu verändern. Wir sind hier, um die Dinge aufzumischen, mein Freund.</p>
            <p>Wir entwickeln gemeinsam die Häuser der Zukunft. Wie machen wir das? Indem wir die Kraft der Personalisierungstechnologie nutzen. Ja, genau. ${textBrand} bringt ein bahnbrechendes 3D-Innenraum-Design-Tool, das von KI gesteuert wird. Ja, du hast richtig gehört. Dieses Ding ermöglicht es dir, deinen Wohnraum in Echtzeit zu navigieren, Raum für Raum, und dein zukünftiges Zuhause vorzumodellieren.</p>
            <p>Mit ${textBrand} begibst du dich auf eine Reise, die reibungslos, angenehm und transparent ist. Keine Überraschungen mehr, keine Verzögerungen. Es ist eine nahtlose, effiziente Reise zu deinem Traumhaus.</p>
           `,
    },
    {
      title:
        'Was ist ein Segment und wie funktioniert es beim Kauf meines neuen Zuhauses?',
      description: `
            <p>Also gut, mein Freund, sprechen wir über Segmente und wie sie ihre Magie in der Welt der Immobilienkaufreise von ${textBrand} wirken.</p>
            <p>Ein Segment ist wie ein Puzzleteil, das kombiniert werden kann, um einen Raum zu schaffen, der auf deine Wünsche und dein Budget zugeschnitten ist.</p>
            <p>Stelle es dir als eine bestimmte Größe des Wohnbereichs vor, die du einzeln oder mehrfach erwerben kannst, um dein neues Zuhause ganz nach deinen Bedürfnissen und Wünschen zu gestalten.</p>
           `,
    },
    {
      title:
        'Warum ist ein Reservierungsvertrag so verdammt wichtig im Kaufprozess?',
      description: `
            <p>Also gut, sprechen wir über dieses Ding namens Reservierungsvertrag. Du denkst vielleicht: "Was ist das und warum sollte es mich überhaupt interessieren?" Nun, mein Freund, lass mich es dir erklären. Dieses Ding ist ein entscheidendes Element in der wilden Fahrt beim Kauf einer Immobilie. Warum? Weil es wie ein verdammtes Sicherheitsnetz ist, mein Freund.</p>
            <p>Wenn du einen Reservierungsvertrag unterschreibst, sagst du im Grunde genommen: "Moment mal, ich habe mein Auge auf diese Immobilie geworfen und ich möchte sicherstellen, dass sie mir gehört."</p>
            <p>Warum ist das so wichtig? Ganz einfach. Es gibt dir Sicherheit und schützt deine Interessen. Es stellt sicher, dass die Immobilie nicht unter dir verkauft wird, während du mit dem Feinschliff beschäftigt bist.</p>
           `,
    },
    {
      title:
        'Was genau ist in einem Kaufvertrag enthalten und was bedeutet er im Rahmen des Immobilienkaufs?',
      description: `
            <p>Ein Kaufvertrag ist wie der heilige Gral eines Immobilienkaufs. Es ist das Dokument, das den Deal besiegelt und deinen Hintern in dem Prozess schützt.</p>
            <p>Lass mich es dir erklären: Wenn du ein Zuhause kaufst, gibst du nicht einfach dein Geld her und hoffst auf das Beste. Da kommt dieses Ding ins Spiel. Es ist ein rechtlich bindender Vertrag zwischen dir und dem Verkäufer, der alles Wichtige regelt. Wir reden hier über den Preis des Hauses, die Bedingungen der Übergabe und den Zeitplan für den Abschluss des Deals.</p>
            <p>Das ist keine lässige Handschlagvereinbarung, mein Freund. Es ist ein ernsthaftes Engagement, das sicherstellt, dass beide Parteien zur Rechenschaft gezogen werden. Also, wenn du bereit bist, diesen großen Kauf zu tätigen, sorge dafür, dass du einen soliden Kaufvertrag hast. Es ist dein Sicherheitsnetz in der wilden Welt des Immobilienkaufs.</p>
           `,

    },
    {
      title:
        `Wie kannst du deine wildesten Träume vom Immobilienkauf durch die mysteriösen Kräfte von ${textBrand} International in die Realität umsetzen?`,
      description: `
            <p>Also, du willst wissen, wie du dein Traumhaus durch ${textBrand} International zur Realität machen kannst? Nun, schnall dich an, mein Freund, denn ich werde es dir erklären.</p>
            <p><strong>Schritt eins:</strong> Wähle das gewünschte Land. Wo siehst du dich dabei, dein bestes verdammt gutes Leben zu führen? Wähle aus und lass uns eintauchen.</p>
            <p><strong>Schritt zwei:</strong> Wir haben eine Vielzahl von Projekten aufgelistet, die nur darauf warten, von dir erkundet zu werden. Verliere dich in den Details, stelle dir vor, wie du in diesen Räumen lebst, und stell dir das Leben vor, das du führen wirst.</p>
            <p><strong>Schritt drei:</strong> Grenze es ein, mein Freund. Wähle die Anzahl der Segmente aus, die du dir leisten kannst, und den Boden, der zu deiner Seele spricht.</p>
            <p><strong>Schritt vier:</strong> Hier wird es aufregend. Mach dich bereit für den atemberaubenden Teil. Du kannst deine Wohnung in atemberaubendem 3D vorab modellieren. Ja, wir reden davon, dein Traumhaus direkt vor deinen Augen zum Leben zu erwecken. Feile daran herum, spiele damit und mache es perfekt. Oh, und habe ich erwähnt, dass du volle Transparenz über die Kosten haben wirst? Hier gibt es kein undurchsichtiges Geschäft.</p>
            <p><strong>Schritt fünf:</strong> Wenn du mit deinem Meisterwerk zufrieden bist, ist es Zeit, den Deal abzuschließen. Unterschreibe online deinen Reservierungsvertrag und Kaufvertrag, und voilà!</p>
            <p>Nun, lehne dich zurück, mein Freund, und lass die Vorfreude wachsen. Bald wirst du in dem Zuhause entspannen, von dem du immer geträumt hast. ${textBrand} ist hier, um es möglich zu machen.</p> 
          `,
    },

    {

      title:
        'Wie kannst du dich der Warteliste anschließen und auf dem Laufenden bleiben über die demnächst startenden Immobilien?',
      description: `
            <p>Also bist du begierig darauf, die begehrten "Demnächst startenden" Immobilien in die Hände zu bekommen, huh? Ich kann es dir nicht verübeln. Nun, hier ist das Geheimnis, wie du auf dem Laufenden bleibst und allen anderen voraus bist.</p>
            <p>Besuche unsere Website und suche nach dem Abschnitt "Informiert bleiben". Es ist wie eine Schatzkiste für Käufer wie dich. Suche nach der Möglichkeit, dich der Warteliste anzuschließen. Gib deine E-Mail-Adresse ein und du bist offiziell dabei.</p>
            <p>Wir stellen sicher, dass du als Erster erfährst, wenn diese aufregenden Projekte bereit sind, loszulegen. Es ist wie ein Backstage-Pass zur Immobilienwelt. Also verschwende keine weitere Sekunde, mein Freund. Schließe dich der Warteliste an und lass die Vorfreude beginnen.</p>
          `,
    },

    {
      title:
        `Wie priorisiert ${textBrand} International den Schutz deiner wertvollen Daten und welche Partnerschaften haben sie für sichere Transaktionen geschlossen?`,
      description: `
            <p>Bei ${textBrand} International machen wir keine Kompromisse, wenn es um den Schutz von Daten geht. Deine Informationen sind für uns wie Gold, und wir schützen sie mit aller Kraft. Wir lassen uns nicht auf zweitklassige Lösungen ein. Wir haben uns mit den Besten der Besten zusammengetan, um deine Daten sicher und geschützt zu halten.</p>
            <p>Wir arbeiten mit Tazapay zusammen, einem Unternehmen mit Sitz in Singapur, das sich auf die Sicherung deiner Zahlungsdetails spezialisiert hat. Aber damit nicht genug. Wir haben mit der innovativen Technologie von Pandadoc aufgerüstet, um digitale Vertragsunterzeichnungen zu ermöglichen.</p>
            <p>Wir lassen keinen Stein unturned, mein Freund. Deine Daten haben für uns oberste Priorität, und wir haben die richtigen Partnerschaften geschlossen, um das zu gewährleisten.</p>
          `,
    },
  ];
};
