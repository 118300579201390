import React from 'react';

const PhilippineOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41177)">
        <path
          d="M9.31553 11.8828V15.0005H8.07629V10.0143H6.83537V8.76005H8.05957V8.13792H6.83203V6.89116H8.06459V5.03814C8.09927 5.01976 8.11055 5.00806 8.12225 5.00806C9.28921 5.016 10.4612 4.96419 11.6223 5.05193C12.6714 5.1309 13.4486 5.73882 13.9495 6.66763C14.0389 6.8335 14.1334 6.89074 14.3176 6.88405C14.7267 6.86943 15.1365 6.87946 15.5615 6.87946V8.12037H14.3427V8.75002H15.5652V10.0056C15.2807 10.0056 14.9907 10.0248 14.7041 10.001C14.3009 9.96712 14.0414 10.049 13.7966 10.4522C13.2016 11.4311 12.2628 11.8811 11.1151 11.882C10.5255 11.8824 9.93598 11.882 9.31636 11.882L9.31553 11.8828ZM9.33809 8.13374V8.7425H13.0596V8.13374H9.33767H9.33809ZM12.5436 6.86776C12.3063 6.57362 11.9072 6.31624 11.5291 6.29828C10.8084 6.2636 10.0856 6.26903 9.36358 6.25942C9.35313 6.25942 9.34227 6.27446 9.33767 6.2778V6.86818H12.5436V6.86776ZM12.5636 10.021H9.33725V10.6302C9.98696 10.6302 10.6195 10.6411 11.2517 10.6268C11.7581 10.6156 12.1893 10.4117 12.5628 10.021H12.5636Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41177">
          <rect
            width="8.73277"
            height="10"
            fill="white"
            transform="translate(6.83203 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhilippineOutlined;
