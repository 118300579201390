import { Lang } from 'constants/i18n';
import moment from 'moment';
import { saveLangCode } from 'locales';
import i18n from 'i18next';

const formatLocalCode = (language: Lang) => {
  switch (language) {
    case 'zh':
      return 'zh-cn';
    
    case 'in':
      return 'id';
    
    case 'tl':
      return 'tl-ph';
  
    default:
      return language;
  }
}

// For moment, only en is loaded as default
const loadedMomentLanguages = ['en'];

// Change language and set new locale to related features
export const changeLanguage = (language: Lang) => {
  // Set locale for moment
  if (!loadedMomentLanguages.includes(language)) {
    require(`moment/locale/${formatLocalCode(language)}`);
    loadedMomentLanguages.push(language);
  }

  if (i18n.language === language) {
    return;
  }

  moment.locale(language);

  // Change locale
  saveLangCode(language);
  i18n.changeLanguage(language);

  window.location.reload();
};
