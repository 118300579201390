export const DATA_FAQ_ES = (textBrand: string) => {
  return [
    {
      title:
        `¿Quién diablos es ${textBrand} International y qué demonios aportan a la mesa?`,
      description: `
            <p>De acuerdo, hablemos del mercado inmobiliario primario y la aburrida y desactualizada experiencia de ventas que conlleva. ${textBrand} International está aquí para cambiar el juego. Estamos aquí para sacudir las cosas, amigo mío.</p>
            <p>Estamos co-desarrollando las casas del futuro. ¿Cómo lo hacemos? Aprovechando el poder de la tecnología de personalización. Así es, ${textBrand} presenta una alucinante herramienta de diseño de interiores en 3D impulsada por la inteligencia artificial. Sí, me has oído bien. Esta joya te permite navegar por tu espacio vital en tiempo real, habitación por habitación, y premodelar tu futura casa.</p>
            <p>Con ${textBrand}, emprenderás un viaje fluido, agradable y transparente. Sin sorpresas, sin demoras. Es un viaje sin problemas y eficiente hacia tu hogar soñado.</p>
           `,
    },
    {
      title:
        '¿Qué es un Segmento y cómo funciona al comprar mi nueva casa?',
      description: `
            <p>De acuerdo, amigo mío, hablemos de los segmentos y cómo funcionan su magia en el proceso de compra de casas de ${textBrand}.</p>
            <p>Un segmento es como una pieza del rompecabezas que se puede combinar para crear un espacio que se adapte a tus deseos y presupuesto.</p>
            <p>Imagínalo como un tamaño distintivo de área de estar que puedes adquirir individualmente o en múltiples, personalizando tu nuevo hogar para satisfacer todas tus necesidades y deseos.</p>
           `,
    },
    {
      title:
        '¿Por qué un Acuerdo de Reserva es tan malditamente importante en el proceso de compra?',
      description: `
            <p>De acuerdo, hablemos de este llamado Acuerdo de Reserva. Es posible que estés pensando: "¿Qué es eso y por qué debería importarme?" Bueno, amigo mío, permíteme explicártelo. Este joya es un elemento crucial en el emocionante viaje de comprar una propiedad. ¿Por qué? Porque es como una maldita red de seguridad, amigo mío.</p>
            <p>Cuando firmas un Acuerdo de Reserva, básicamente estás diciendo: "Espera, tengo mis ojos puestos en esta propiedad, y quiero asegurarme de que sea mía".</p>
            <p>¿Por qué es tan importante? Simple. Te brinda tranquilidad y protege tus intereses. Asegura que la propiedad no será vendida debajo de ti mientras te ocupas de los detalles.</p>
           `,
    },
    {
      title:
        '¿Qué se incluye exactamente en un Contrato de Compraventa y qué implica en la compra de una casa?',
      description: `
            <p>Un Contrato de Compraventa es como el Santo Grial de la compra de una casa. Es el documento que sella el trato y protege tus espaldas en el proceso.</p>
            <p>Déjame explicarlo: cuando estás comprando una casa, no entregas tu dinero y esperas lo mejor. Ahí es donde entra en juego esta joya. Es un acuerdo legalmente vinculante entre tú y el vendedor que detalla todo lo importante. Hablamos del precio de la casa, las condiciones de entrega y el cronograma para cerrar el trato.</p>
            <p>Esto no es un acuerdo de apretón de manos casual, amigo mío. Es un compromiso serio que asegura que ambas partes sean responsables. Así que, cuando estés listo para hacer esa gran compra, asegúrate de tener un sólido Contrato de Compraventa. Es tu red de seguridad en el salvaje mundo de la compra de viviendas.</p>
           `,

    },
    {
      title:
        `¿Cómo puedes convertir tus sueños más locos de compra de vivienda en realidad a través de los misteriosos poderes de ${textBrand} International?`,
      description: `
            <p>Así que, estás deseando saber cómo convertir tu hogar de ensueño en realidad a través de ${textBrand} International. Bueno, prepárate, amigo mío, porque te lo voy a explicar.</p>
            <p><strong>Paso uno:</strong> elige el país que deseas. ¿Dónde te ves viviendo la mejor vida posible? Elige y comencemos.</p>
            <p><strong>Paso dos:</strong> tenemos una gran cantidad de proyectos listos para que los explores. Sumérgete en los detalles, imagínate en esos espacios e imagina la vida que llevarás.</p>
            <p><strong>Paso tres:</strong> estrecha tus opciones, amigo mío. Selecciona los segmentos que te puedes permitir y el piso que hable a tu corazón.</p>
            <p><strong>Paso cuatro:</strong> Aquí es donde se pone emocionante. Ahora, prepárate para la parte alucinante. Puedes premodelar tu apartamento en un impresionante 3D instantáneo. Sí, hablamos de llevar tu hogar soñado a la vida ante tus ojos. Ajusta, juega con él y hazlo perfecto. Ah, ¿y mencioné que tendrás una visibilidad total y transparencia en los costos? No hay negocios oscuros aquí.</p>
            <p><strong>Paso cinco:</strong> Una vez que estés satisfecho con tu obra maestra, es hora de cerrar el trato. ¡Firma tu acuerdo de reserva y contrato de compraventa en línea, y voilà!</p>
            <p>Ahora, siéntate, amigo mío, y deja que la anticipación crezca. Pronto estarás disfrutando del hogar con el que siempre has soñado. ${textBrand} está aquí para hacerlo realidad.</p> 
          `,
    },

    {

      title:
        '¿Cómo puedes unirte a la lista de espera y estar al tanto de las propiedades que se lanzarán próximamente?',
      description: `
            <p>Así que estás ansioso por poner tus manos en esas elusivas propiedades "Próximamente", ¿verdad? No te culpo. Bueno, aquí está el secreto para mantenerte actualizado y adelantarte al juego.</p>
            <p>Dirígete a nuestro sitio web y busca la sección que dice "Mantente Informado". Es como un tesoro para compradores como tú. Busca la opción para unirte a la lista de espera. Ingresa tu dirección de correo electrónico y considérate oficialmente en el circuito.</p>
            <p>Nos aseguraremos de que seas el primero en saber cuándo esos emocionantes proyectos estén listos para despegar. Es como tener un pase de backstage al mundo de las propiedades. Así que no pierdas ni un segundo más, amigo mío. Únete a la lista de espera y que comience la anticipación.</p>
          `,
    },

    {
      title:
        `¿Cómo prioriza ${textBrand} International la protección de tus valiosos datos y qué asociaciones tiene para garantizar transacciones seguras?`,
      description: `
            <p>En ${textBrand} International no escatimamos esfuerzos cuando se trata de la protección de datos. Tu información es valiosa para nosotros, y la protegemos con todas nuestras fuerzas. No tomamos atajos. Nos hemos asociado con los mejores de los mejores para mantener tus datos seguros y protegidos.</p>
            <p>Tenemos a Tazapay, una empresa con sede en Singapur que se especializa en mantener seguros los detalles de pago. Pero eso no es todo. Hemos llevado las cosas un paso más allá con la innovadora tecnología de Pandadoc para la firma de contratos digitales.</p>
            <p>No dejamos piedra sin remover, amigo mío. Tus datos son nuestra máxima prioridad y tenemos las asociaciones adecuadas para garantizarlo.</p>
          `,
    },
  ];
}
