import React from 'react';

const KoreaOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <path
        d="M15.8306 10.7841H14.324L13.626 14H11.8215L11.0413 10.7841H10.6653L9.90637 14H8.10186L7.40105 10.7841H5.83203V9.8046H7.17832L7.05632 9.24584H5.83203V8.26498H6.84352L6.57114 7H8.29337L8.53738 8.26498H9.83543L10.1192 7H11.5875L11.8811 8.26498H13.1891L13.4232 7H15.1568L14.883 8.26498H15.832V9.24584H14.6602L14.5368 9.8046H15.8306V10.7841ZM12.7096 10.7841H12.4599L12.6018 11.3916L12.7139 10.7841H12.7096ZM9.25379 10.7841H9.01404L9.1247 11.3875L9.25805 10.7801L9.25379 10.7841ZM8.82111 9.8046H9.47936L9.61271 9.24584H8.71897L8.82111 9.8046ZM12.2358 9.8046H12.8969L13.0089 9.24584H12.1053L12.2358 9.8046Z"
        fill="white"
      />
    </svg>
  );
};

export default KoreaOutlined;
