import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography
} from '@mui/material';
import Button from 'components/buttons/Button';
import { PageFooter } from 'components/layouts';
import { ELanguageLocales } from 'constants/currency';
import { useLayout } from 'contexts/layoutContext';
import { i18n } from 'locales';
import { DATA_FAQ_AR } from 'locales/faqs/faq-ar';
import { DATA_FAQ_DE } from 'locales/faqs/faq-de';
import { DATA_FAQ_EL } from 'locales/faqs/faq-el';
import { DATA_FAQ_EN } from 'locales/faqs/faq-en';
import { DATA_FAQ_ES } from 'locales/faqs/faq-es';
import { DATA_FAQ_FIL } from 'locales/faqs/faq-fil';
import { DATA_FAQ_FR } from 'locales/faqs/faq-fr';
import { DATA_FAQ_IN } from 'locales/faqs/faq-in';
import { DATA_FAQ_JA } from 'locales/faqs/faq-ja';
import { DATA_FAQ_KO } from 'locales/faqs/faq-ko';
import { DATA_FAQ_MS } from 'locales/faqs/faq-ms';
import { DATA_FAQ_NL } from 'locales/faqs/faq-nl';
import { DATA_FAQ_PL } from 'locales/faqs/faq-pl';
import { DATA_FAQ_PT } from 'locales/faqs/faq-pt';
import { DATA_FAQ_TH } from 'locales/faqs/faq-th';
import { DATA_FAQ_TL } from 'locales/faqs/faq-tl';
import { DATA_FAQ_VI } from 'locales/faqs/faq-vi';
import { DATA_FAQ_ZH } from 'locales/faqs/faq-zh';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import styled from "styled-components";

const handleFlowFAQ = (textBrand: string) => {
  switch (i18n.language) {
    case ELanguageLocales.vi:
      return DATA_FAQ_VI(textBrand!);
    
    case ELanguageLocales.en:
      return DATA_FAQ_EN(textBrand!);
    
    case ELanguageLocales.ar:
      return DATA_FAQ_AR(textBrand!);

    case ELanguageLocales.de:
      return DATA_FAQ_DE(textBrand!);

    case ELanguageLocales.el:
      return DATA_FAQ_EL(textBrand!);

    case ELanguageLocales.es:
      return DATA_FAQ_ES(textBrand!);

    case ELanguageLocales.fil:
      return DATA_FAQ_FIL(textBrand!);

    case ELanguageLocales.fr:
      return DATA_FAQ_FR(textBrand!);

    case ELanguageLocales.in:
      return DATA_FAQ_IN(textBrand!);

    case ELanguageLocales.ja:
      return DATA_FAQ_JA(textBrand!);

    case ELanguageLocales.ko:
      return DATA_FAQ_KO(textBrand!);

    case ELanguageLocales.ms:
      return DATA_FAQ_MS(textBrand!);

    case ELanguageLocales.nl:
      return DATA_FAQ_NL(textBrand!);
    
    case ELanguageLocales.pl:
      return DATA_FAQ_PL(textBrand!);

    case ELanguageLocales.pt:
      return DATA_FAQ_PT(textBrand!);

    case ELanguageLocales.th:
      return DATA_FAQ_TH(textBrand!);

    case ELanguageLocales.tl:
      return DATA_FAQ_TL(textBrand!);

    case ELanguageLocales.zh:
      return DATA_FAQ_ZH(textBrand!);

    default:
      return DATA_FAQ_EN(textBrand!);
  }
}

type Faqs = {
  title: string;
  description: string;
}

function FAQ() {
  const { textContentBrand: { textBrand } } = useSelector(selectDesignHome);
  const [expanded, setExpanded] = React.useState<number>(-1);
  const [faqs, setFaqs] = useState<Faqs[]>([]);
  const { updateState, paddingTopHeaderFixed } = useLayout();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    updateState({ isFloating: true });
    window.scrollTo(0, 0);
    setFaqs(handleFlowFAQ(textBrand! || 'Levitate'));
  }, [])

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : -1);
    };

  return (
    <>
        <ContentLayout>
          <Stack
          columnGap={4}
          mt={`${paddingTopHeaderFixed}px`}
          rowGap={{ lg: 0, xs: 4 }}
          flexDirection={{ lg: 'row', xs: 'column' }} 
        >
          <Box>
            <Button
              startIcon={<ArrowBackIcon />}
              color="transparent"
              onClick={() => history.goBack()}
              className='button-back'
              width={'130px'}
            >
              {t('Back')}
            </Button>
          </Box>
          <Stack flexDirection={{ lg: 'row', xs: 'column' }}  columnGap={4}>
            <Box flex={0.3}>
              <h4 className='title'>{t('Frequently Asked Questions')}</h4>
            </Box>
            <Box flex={1}>
              <Stack flexDirection="column" rowGap={2}>
                {
                  faqs.map((item, index) => (
                    <Box>
                      <AccordionStyle className='accodian' expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary
                          expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Stack className='accodian-title' flexDirection="row">
                            <Box className="accordian-number">
                              0{index + 1}
                            </Box>
                            <Box className='accodian-title-des'>
                              {item?.title}
                            </Box>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography dangerouslySetInnerHTML={{ __html: item?.description }} className='accodian-content-des'></Typography>
                        </AccordionDetails>
                      </AccordionStyle>
                    </Box>
                  ))
                }
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </ContentLayout>
     <PageFooter/>
    </>
  );
}

export default FAQ;

export const AccordionStyle = styled(Accordion)`
  padding: 0px !important;
  background-color: transparent !important;
  background-image: none !important;
  border-radius: 2px !important;
  border: 2px solid ${p => p.theme.palette.secondary.main};

  .MuiButtonBase-root.MuiAccordionSummary-root{
    padding: 0px 0px 0px 2.5rem !important;
    height: 70px;
    
    &.Mui-expanded {
      min-height: 0 !important;
    }
  }

  .MuiTypography-root{
    font-size: 16px !important;
  }

  .accodian-content-des{
    font-size: 16px;
    color: #c9c8cc;
  }

  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${p => p.theme.palette.secondary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(7.5px);
    width: 5rem;

    &.Mui-expanded{
      background: ${p => p.theme.palette.accent.main};
      transform: rotate(0deg) !important;
    }
  }

  .MuiAccordionDetails-root{
    padding: 10px 5rem 16px calc(2.5rem + 40px) !important;
    border-top: 2px solid ${p => p.theme.palette.secondary.main};
  }
  .MuiAccordionSummary-content{
    height: auto !important;
    font-size: 18px;
    flex: 1;
    margin: 0 !important;
    
    &.Mui-expanded{
      margin: 0 !important;
    }
  }

  a{
    color: ${p => p.theme.palette.accent.main};
  }
`
const ContentLayout = styled.section`
  padding: 40px 5rem;
  margin: 0 auto;

  @media (max-width: 1280px){
    padding: 40px 40px ;
  }

  @media (max-width: 1024px){
    padding: 20px 20px;
  }

  .title{
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 25px;
  }

  .wrapper{
    margin-top: 0 !important;
  }

  .accordian-number{
    min-width: 40px;
  }

  .accodian-title{
    margin-right: 5rem;

    &-des{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .column-left{
    padding-top: 0px !important;

    &__tab{
      cursor: pointer;
      font-size: 15px;
      min-width: 300px;
      max-width: 300px;
      font-weight: 400;

      &.active{
        color: ${p => p.theme.palette.accent.main};
        text-decoration: underline;
        font-weight: 500;
      }

      &:hover{
        text-decoration: underline;
        color: ${p => p.theme.palette.accent.main};
      }
    }

    .title{
      font-weight: 500;
      margin-bottom: 2rem;
      font-size: 25px;
    }
  }
  .column-right{
    padding-top: 0px !important;

    &__content{
      font-size: 15px;
      font-weight: 400;
      &-link{
        color: #fff;

        &:hover{
          color: ${p => p.theme.palette.accent.main};
        }
      }
    }

    .title{
      font-weight: 500;
      margin-bottom: 2rem;
      font-size: 20px;
    }
  }

  .MuiAccordionDetails-root p{
    line-height: 1.4 !important;
  }

  .button-back{
    height: 40px;
    font-size: 16px;

    @media (max-width: 768px){
      height: 50px;
    }
  }
`;