import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '@mui/material/Avatar';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// COMPONENTS
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Button from 'components/buttons/Button';
import Image from 'components/images/Image';
import CurrencyDropdown from '../actionComponents/CurrencyDropdown';
import LanguageDropdown from '../actionComponents/LanguageDropdown';

// ASSETS
import logoBg from 'assets/logo.png';
import UserOutlinedIcon from 'components/sgvIcons/UserOutlined';

// CONSTANTS
import IcWorldMap from 'components/icons/IcWorldMap';
import { PageRoute, PageRouteName } from 'constants/route';
import { StorageKey } from 'constants/storage';
import { useLayout } from 'contexts/layoutContext';
import { useFunction, useRoute } from 'hooks';
import SignupLight, { TypeSignInAndSignUp } from 'pages/SignupLight/SignupLight';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, removeStorage, setStorage } from 'services/storageService';
import { setAuth } from 'store/designHome/designHomeActions';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';

// ICONS
import { fetchPersonProfileByIdApi } from 'apis/userApis';
import { FAQIcon, MyOrderIcon, SignoutIcon, UserIcon } from 'components/icons';
import IcNavbar from 'components/icons/IcNavbar';
import CustomDrawer from 'components/modals/CustomDrawer';
import QuestionDialog from 'components/modals/QuestionDialog';
import { DomId } from 'constants/homeConstants';
import { useOrderContext } from 'contexts/ordersContext';
import { toast } from 'react-toastify';

type Props = {
  floating?: boolean;
  transparent?: boolean;
};

const MainPageHeader = ({ floating, transparent }: Props) => {
  const { t } = useTranslation();

  const { isNavbarHide, isActionButtonHide, paddingTopHeaderFixed, isMenuResponsive, updateState } = useLayout();
  const { orders } = useOrderContext();
  const {
    auth: { username, tokenPMS, avatarUrl },
  } = useSelector(selectDesignHome);
  const dispatch = useDispatch();
  const [showPopupSignout, setShowPopupSignout] = useState(false);
  let portrait = window.matchMedia("(orientation: portrait)");
  const [orientationDevice, setOrientationDevice] = useState<'Portrait' | 'Landscape'>('Portrait');

  const { pathname, redirect, params } = useRoute();
  const history = useHistory();
  const { locationId, projectId } = params as {
    locationId: number;
    projectId: number;
  };
  const { handleDemoStudio } = useFunction();

  const styleAppBarRef = useRef<HTMLDivElement | null>(null);
  const [showSignupLight, setShowSignupLight] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDrawer, setShowDrawer] = useState(false);

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientationDevice('Portrait');
    } else {
      setOrientationDevice('Landscape');
    }
  });

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Initital State only one
  useEffect(() => {
    const styleAppBar: any = styleAppBarRef;
    if (styleAppBar?.current && styleAppBar?.current?.clientHeight) {
      updateState({ paddingTopHeaderFixed: styleAppBar?.current?.clientHeight });
    }
  }, [orientationDevice, pathname]);

  useEffect(() => {
    updateState({
      isNavbarHide: String(pathname) === '/'
        ? false
        : !pathname.includes(PageRouteName.YourDesign) ? isNavbarHide : false
    });
  }, [pathname])

  useLayoutEffect(() => {
    if (String(pathname) === '/') {
      styleAppBarRef.current!.style.display = 'none';
    } else {
      styleAppBarRef.current!.style.display = 'block';
    }
  }, [pathname]);

  // Refresh user data login
  useEffect(() => {
    if (tokenPMS) {
      fetchPersonProfileById();
    }
  }, [tokenPMS]);

  const fetchPersonProfileById = async () => {
    const { data } = await fetchPersonProfileByIdApi();
    if (data.id) {
      dispatch(setAuth({
        avatarUrl: data?.avatarUrl || '',
        completedProfile: data?.completedProfile,
        telephone: data?.telephone,
        phonePrefix: data?.phonePrefix,
        email: data?.email,
        nationality: data?.nationality
      }));
      setStorage(
        StorageKey.AuthDesign,
        {
          ...getStorage(StorageKey.AuthDesign),
          avatarUrl: data?.avatarUrl || '',
          completedProfile: data?.completedProfile,
          telephone: data?.telephone || '',
          phonePrefix: data?.phonePrefix || '',
          email: data?.email,
          nationality: data?.nationality
        }
      );
    }
  }

  const handleLogin = () => {
    if (!tokenPMS) {
      setShowSignupLight(true)
    }
  }

  const handleLogout = () => {
    handleClose();
    setShowDrawer(false);
    history.push(PageRoute.Dashboard);
    dispatch(setAuth({
      tokenPMS: undefined,
      tokenUMS: undefined,
      username: undefined,
      completedProfile: undefined,
      avatarUrl: undefined,
      userId: undefined,
    }));
    removeStorage(StorageKey.AuthDesign);
    setShowPopupSignout(false)
  }

  const handleRedirectOrderPage = useCallback(() => {
    if (orders?.length > 0) {
      redirect({
        path: PageRoute.MyOrderConfirm,
        params: {
          orderId: Number(orders[orders?.length - 1]?.id),
          locationId: locationId || 3,
          projectId: Number(orders[orders?.length - 1]?.projectId),
        }
      });
    } else {
      toast.error(t('You have not ordered yet!'));
    }
    handleClose();
  }, [orders]);

  const isMenuPageHeader = useMemo(() => {
    return pathname.includes(PageRouteName.YourDesign) || pathname.includes(PageRouteName.SignatureLayout);
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem("PREV_PATH") !== "") {
      const PREV_PATH = localStorage.getItem("PREV_PATH")?.split("_") || [];
      if (PREV_PATH[1] === "LOGIN") {
        localStorage.setItem("PREV_PATH", PREV_PATH[0] + "_REDIRECT");
        setShowSignupLight(true);
      }
    }
  }, [])

  const renderUserInfo = () => {
    return (
      <>
        {
          !username &&
          <Box style={{ width: 'max-content' }}>
            <Button
              color="accent"
              onClick={handleLogin}
              fullWidth
              startIcon={<UserOutlinedIcon />}
              height="42px"
            >
              {t('Connect')}
            </Button>
          </Box>
        }
        {
          username &&
          <>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              className='button-profile'
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ fontSize: '16px' }}
            >
              {avatarUrl
                ? <Avatar src={avatarUrl || ''} sx={{ width: 32, height: 32 }} alt='avatar-url' />
                : <Avatar sx={{ width: 32, height: 32, background: '#C8B6A6' }} alt='avatar-url'>{username?.slice(0, 1).toUpperCase()}</Avatar>
              }

              &nbsp;
              <span className='button-profile-name'>{username}</span>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                redirect({
                  path: PageRoute.PersonalProfile,
                  params: {
                    locationId: locationId || getStorage(StorageKey.StepYourDesign)?.locationId || 3,
                    projectId: projectId || getStorage(StorageKey.StepYourDesign)?.projectId || 4,
                  }
                })
                handleClose();
              }} disableRipple>
                <UserIcon width="16px" height="20px" />
                <span className='profile-item'>{t('My Profile')}</span>
              </MenuItem>
              <MenuItem onClick={handleRedirectOrderPage} disableRipple>
                <MyOrderIcon width="16px" height="20px" />
                <span className='profile-item'>{t('My Order')}</span>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  redirect({
                    path: PageRoute.FAQ,
                  });
                  handleClose();
                }}
                disableRipple
              >
                <FAQIcon width="16px" height="20px" />
                <span className='profile-item'>{t('FAQs')}</span>
              </MenuItem>
              <MenuItem
                onClick={() => setShowPopupSignout(true)}
                disableRipple>
                <SignoutIcon width="16px" height="20px" />
                <span className='profile-item'>{t('Sign out')}</span>
              </MenuItem>
            </StyledMenu>
          </>
        }
        {/* <a onClick={() => redirect('/')} style={{ cursor: 'pointer' }}><IcWorldMap width="29" height="30" /></a> */}
      </>
    )
  };

  return (
    <>
      {(floating && (String(pathname) !== '/') && PageRoute.ProjectDetail.includes(pathname)) ? (
        <Box style={{
          height: paddingTopHeaderFixed
        }}></Box>
      ) : null}
      <StyledAppBar
        id={DomId.mainPageHeader}
        ref={styleAppBarRef}
        position={floating ? 'fixed' : 'static'}
        elevation={0}
        sx={{
          display: {
            xs: isMenuPageHeader ? 'none !important' : 'block !important',
            md: 'block !important',
          },
          top: 0,
        }}
      >
        <Toolbar disableGutters sx={{ px: 2.5, py: 2 }}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box
              onClick={() => redirect('/')}
              className='group-image-header'
              paddingLeft={{ xs: '0', xl: isNavbarHide ? '60px' : '0' }}
            >
              <Box
                sx={{
                  width: { xs: '150px', lg: '190px' },
                  height: { xs: '25px', lg: '36px' }
                }}
              >
                <Image className='image-header' src={getStorage(StorageKey.LogoCompany) || logoBg} />
              </Box>
            </Box>
            <Stack
              className='box-menu-icon'
            >
              {(pathname.includes(PageRouteName.ProjectListing) || String(pathname) === '/') &&
                <Button
                  onClick={handleDemoStudio}
                  style={{ background: '#33AF7D', height: "42px" }}
                >
                  {t('Give it a shot!')}
                </Button>
              }
              <Box
                onClick={() => updateState({ isMenuResponsive: !isMenuResponsive })}
                style={{ cursor: 'pointer' }}
              >
                <IcNavbar width="35px" height="28px" />
              </Box>
            </Stack>
            <Stack
              className='box-menu-desktop'
              display={{ xs: 'none', md: 'flex' }}
            >
              {isActionButtonHide ? null : (
                <>
                  {(Number(projectId) === 51 && (pathname.includes(PageRouteName.ProjectListing) || 
                    pathname.includes(PageRouteName.ChooseSegment) ||
                    pathname.includes(PageRouteName.YourDesign) ||
                    pathname.includes(PageRouteName.PromotionChoosing))) ? <Stack
                    className='box-menu-desktop'
                    display={{ xs: 'none', md: 'flex' }}
                  >
                    <span className='phone-number'>Hotline: +84938639461</span>
                  </Stack> : <></>}
                  {(String(pathname) !== PageRouteName.WaitList && String(pathname) !== '/') &&
                    <Button
                      style={{ height: "42px", background: '#D5983C' }}
                      onClick={() => redirect(PageRoute.WaitList)}
                    >
                      {t('Join the Queue')}
                    </Button>
                  }
                  {(pathname.includes(PageRouteName.ProjectListing) || String(pathname) === '/') &&
                    <Button
                      onClick={handleDemoStudio}
                      style={{ background: '#33AF7D', height: "42px" }}
                    >
                      {t('Give it a shot!')}
                    </Button>
                  }
                  <Box width={104}>
                    <LanguageDropdown />
                  </Box>
                  <Box width={104}>
                    <CurrencyDropdown />
                  </Box>
                  {renderUserInfo()}
                </>
              )}
            </Stack>
            <Stack
              className='box-menu-desktop'
              display={{ xs: 'flex', md: 'none' }}
            >
              {(pathname.includes(PageRouteName.ProjectListing) || String(pathname) === '/') &&
                <Button
                  onClick={handleDemoStudio}
                  style={{ background: '#33AF7D', height: "42px" }}
                >
                  {t('Give it a shot!')}
                </Button>
              }
              <Box
                onClick={() => updateState({ isMenuResponsive: !isMenuResponsive })}
                style={{ cursor: 'pointer' }}
              >
                <IcNavbar width="35px" height="28px" />
              </Box>
            </Stack>
          </Stack>
        </Toolbar>

        <CustomDrawer
          open={showDrawer} onClose={(open: boolean) => setShowDrawer(open)}>
          <Box padding={2}>
            <Stack flexDirection="column" justifyContent="space-between" alignItems="center" rowGap={2}>
              <LanguageDropdown />
              <CurrencyDropdown />
            </Stack>
            {!username &&
              <Stack flexDirection="column" rowGap={3} mt={4}>
                <Stack flexDirection="row" columnGap={2} className='pointer'>
                  <UserIcon width="28px" height="28px" />
                  <Box
                    style={{ fontSize: 16 }}
                    onClick={handleLogin}
                  >
                    {t('Connect')}
                  </Box>
                </Stack>
                <Stack
                  flexDirection="row" columnGap={2}
                  onClick={() => {
                    setShowDrawer(false);
                    redirect({
                      path: PageRoute.FAQ,
                    });
                  }}
                  className='pointer'
                >
                  <FAQIcon width="28px" height="28px" />
                  <Box style={{ fontSize: 16 }}>{t('FAQs')}</Box>
                </Stack>
              </Stack>
            }
            {username &&
              <Box mt={4}>
                <Avatar style={{ width: 200, height: 200, fontSize: 76 }} alt='username-url'>{username?.slice(0, 1).toUpperCase()}</Avatar>
                &nbsp;
                <Box style={{ fontSize: 16 }} mb={5}>{username}</Box>
                <Stack flexDirection="column" rowGap={3}>
                  <Stack flexDirection="row" columnGap={2}>
                    <UserIcon width="28px" height="28px" />
                    <Box
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        setShowDrawer(false);
                        redirect({
                          path: PageRoute.PersonalProfile,
                          params: {
                            locationId: locationId || getStorage(StorageKey.StepYourDesign)?.locationId || 3,
                            projectId: projectId || getStorage(StorageKey.StepYourDesign)?.projectId || 4,
                          }
                        });
                      }}
                    >
                      {t('My Profile')}
                    </Box>
                  </Stack>
                  <Stack flexDirection="row" columnGap={2}>
                    <MyOrderIcon width="28px" height="28px" />
                    <Box
                      onClick={() => {
                        setShowDrawer(false);
                        handleRedirectOrderPage();
                      }}
                      style={{ fontSize: 16 }}
                    >
                      {t('My Order')}
                    </Box>
                  </Stack>
                  <Stack
                    flexDirection="row" columnGap={2}
                    onClick={() => {
                      setShowDrawer(false);
                      redirect({
                        path: PageRoute.FAQ
                      });
                    }}
                  >
                    <FAQIcon width="28px" height="28px" />
                    <Box style={{ fontSize: 16 }}>{t('FAQs')}</Box>
                  </Stack>
                  <Stack
                    flexDirection="row" columnGap={2}
                    onClick={() => setShowPopupSignout(true)}>
                    <SignoutIcon width="28px" height="28px" />
                    <Box style={{ fontSize: 16 }}>{t('Sign out')}</Box>
                  </Stack>
                </Stack>
              </Box>
            }
          </Box>
        </CustomDrawer>

        <SignupLight typePage={TypeSignInAndSignUp.SignIn} onClose={() => {
          setShowSignupLight(false);
          localStorage.removeItem("PREV_PATH");
        }} show={showSignupLight} />

        <QuestionDialog
          maxWidth="xs"
          open={showPopupSignout}
          cancelText={t("Back")}
          widthContent='100%'
          confirmText={<Box style={{ textTransform: 'capitalize' }}>{t("Sign out")}</Box>}
          onConfirm={handleLogout}
          closeDialog={() => setShowPopupSignout(false)}
          widthButton='100%'
        >
          <Box fontSize={'20px'}>{t(`You're signing out?`)}</Box>
          <Box mt={1} color="#C9C8CC" fontSize={'17px'}>{t(`Have a great day and keep being a badass. See you soon.`)}</Box>
        </QuestionDialog>
      </StyledAppBar>
    </>
  );
};

const StyledAppBar = styled(AppBar)`

  background: ${p => p.theme.palette.secondary.main} !important;
  z-index: 1;

  .button-profile-name {
    color: #fff;
  }

  .box-menu-icon {
    display: none;
  }

  .box-menu-desktop {
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;

    .phone-number {
      font-size: 1.3rem;
      font-weight: 500;
      color: #A885A2;
    }
  }

  && {
    transition: background-color 300ms;

    &.MuiAppBar-colorDefault {
      background-color: ${p => p.theme?.palette?.default?.dark | '#45424d' as any} ;
      backdrop-filter: blur(10px);
    }
  }

  .group-image-header {
    transition: .3s all ease-in-out;
    cursor: pointer;
  }

  .button-profile{
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:hover{
      background-color: transparent !important;
    }

    &-name{
      margin-left: 5px;
    }
  }

  .profile-item{
    margin-left: 10px !important;
  }

  @media only screen and (max-width: 992px) {
    .profile-item{
      font-size: 20px !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .box-menu-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;
    }

    .box-menu-desktop {
      display: none;
    }
  }
`;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 2,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: theme?.palette?.default?.dark,
    color: '#fff',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },

    },
  },
  '& .profile-item': {
    marginLeft: '10px',
  },
  '& .MuiMenuItem-root': {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));

export default MainPageHeader;
