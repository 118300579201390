import { useTheme } from '@mui/material';
import React from 'react';

function IcInfo(props: any) {
  const theme = useTheme();
  return (
    <svg
      {...props}
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 12.5C1.875 6.70156 6.57656 2 12.375 2C18.1734 2 22.875 6.70156 22.875 12.5C22.875 18.2984 18.1734 23 12.375 23C6.57656 23 1.875 18.2984 1.875 12.5ZM3.65625 12.5C3.65625 17.3141 7.56094 21.2188 12.375 21.2188C17.1891 21.2188 21.0938 17.3141 21.0938 12.5C21.0938 7.68594 17.1891 3.78125 12.375 3.78125C7.56094 3.78125 3.65625 7.68594 3.65625 12.5ZM13.375 11.5V17.5H11.375V11.5H13.375ZM13.375 7.5V9.5H11.375V7.5H13.375Z"
        fill={theme.palette.accent.main}
      />
    </svg>
  );
}

export default IcInfo;