import { Grid } from '@mui/material';
import { signinLightByPhoneNumber, signupLightByPhoneNumber, signupLightVerifyEmail, updateUserPhoneNumberApi, verifyEmailApi } from 'apis/userApis';
import Alert from 'components/alerts/Alert';
import { Button } from 'components/buttons';
import { StorageKey } from 'constants/storage';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useRoute } from 'hooks';
import moment from 'moment';
import { TypeChangePhoneNumberOrEmail } from 'pages/MyProfile/PersonalProfile';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'reactfire';
import { setFirebaseToken } from 'services/authService';
import { getStorage, setStorage } from 'services/storageService';
import { setAuth } from 'store/designHome/designHomeActions';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import { useTheme } from 'styled-components';
import { TypeSignInAndSignUp, TypeSinup } from '../SignupLight';

declare const window: any;

const RESEND_TIMEOUT = 60;
const OTP_NUMBER = 6

type SignupVerifyOtpProps = {
  onChangeTypeSignup?: () => void;
  typeVerify: string;
  typePage?: string;
  dataForm: { name?: string; phoneNumber?: string, prefix?: string, email?: string },
  onClose: () => void;
  getDataPhoneNumber?: (phoneNumber: string) => void;
  getDataEmail?: (email: string) => void;
}

function SignupVerifyOtp(props: SignupVerifyOtpProps) {
  const {
    onChangeTypeSignup,
    typeVerify,
    dataForm,
    onClose,
    typePage = TypeSignInAndSignUp.SignUp,
    getDataPhoneNumber,
    getDataEmail
  } = props;
  const { t } = useTranslation();
  const theme: any = useTheme();
  const auth = useAuth();
  const dispatch = useDispatch();
  const { auth: { username } } = useSelector(selectDesignHome)

  const { redirect } = useRoute();

  const [code, setCode] = useState('');
  const [timerCountdown, setTimerCountdown] = useState(0);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [loginErrMessage, setLoginErrMessage] = useState<string>('');

  const otpInput = useRef<any>(null);
  const timerInterval = useRef<any>(0);

  const startCountdown = useCallback(() => {
    if (timerInterval.current) clearInterval(timerInterval.current);
    let countdown = RESEND_TIMEOUT;
    setTimerCountdown(countdown);
    timerInterval.current = setInterval(() => {
      if (countdown > 0) {
        setTimerCountdown(--countdown)
      } else {
        clearInterval(timerInterval.current);
        timerInterval.current = 0;
      }
    }, 1000);
  }, []);

  const redirectToPrevPath = useCallback(() => {
    if (typePage === TypeSignInAndSignUp.SignIn) {
      if (localStorage.getItem("PREV_PATH") !== "") {
        const PREV_PATH = localStorage.getItem("PREV_PATH")?.split("_") || [];
        if (PREV_PATH[1] === "REDIRECT") {
          localStorage.removeItem("PREV_PATH");
          redirect(PREV_PATH[0]);
        }
      }
    }
  }, [])

  useEffect(() => {
    if ((typeVerify === TypeSinup.PhoneNumber
        || typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber)
        && dataForm?.phoneNumber
      ) {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier('button-send-verify-code', {
          'size': 'invisible',
        }, auth);
        window.recaptchaVerifier.render().catch((error: any) => {
          console.error('Recaptcha fail', error)
        });
      }
      console.debug("requestVerificationCode ", dataForm?.phoneNumber);
      requestVerificationCode();
    } else {
      startCountdown();
      setCode('');
    }
  }, [dataForm]);

  const requestVerificationCode = async () => {
    startCountdown();
    setCode('');

    if (typeVerify === TypeSinup.PhoneNumber || typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber) {
      setConfirmationResult(null);
      const fullPhone = `${dataForm.prefix}${dataForm.phoneNumber}`;
      signInWithPhoneNumber(auth, fullPhone, window.recaptchaVerifier)
        .then((confirmationResult) => {
          setConfirmationResult(confirmationResult);
          if (otpInput.current) {
            otpInput.current.focusInput(1);
            otpInput.current.focusInput(0);
          }
        })
        .catch((error) => {
          const errorCode = error.code;
        });
    } else {
      // try {
      //   const uuid = getStorage(StorageKey.UUID_Design);
      //   await signupLightEmail({
      //     email: dataForm?.email ? dataForm?.email : '',
      //     username: dataForm?.name ? dataForm?.name : '',
      //     uuid: uuid
      //   })

      // } catch (e) {
      // }
    }
  }

  const handleVerifyCode = async (event: any) => {
    setIsFetching(true)
    event.preventDefault();
    if (typeVerify === TypeSinup.email) {
      try {
        const resVerifyEmail = await signupLightVerifyEmail({
          email: dataForm?.email || '',
          verifyCode: code,
        })
        if (resVerifyEmail?.data) {
          const dataRedux = {
            tokenPMS: resVerifyEmail?.data?.tokenPMS,
            tokenUMS: resVerifyEmail?.data?.tokenUMS,
            username: resVerifyEmail?.data?.userName || username,
            userId: resVerifyEmail?.data?.userId,
            expiredTime: moment().add('hours', 22).toDate(),
            completedProfile: resVerifyEmail?.data?.completedProfile,
          }
          dispatch(setAuth(dataRedux));
          setStorage(StorageKey.AuthDesign, { ...getStorage(StorageKey.AuthDesign), ...dataRedux });
          setIsFetching(false);

          if (getDataEmail) {
            getDataEmail(`${dataForm?.email}`);
          }
          redirectToPrevPath();
          onClose();
        } else {
          setLoginErrMessage(t('Otp code is incorrect'));
          setIsFetching(false);
        }
      } catch (e) {
        setLoginErrMessage(t('Otp code is incorrect'));
        setIsFetching(false);
      }
    } else if (typeVerify === TypeSinup.PhoneNumber) {
      confirmationResult?.confirm(code).then((result: any) => {
        result.user.getIdToken().then(async (result: any) => {
          // save tmp firebase token

          let uuid = getStorage(StorageKey.UUID_Design);

          try {
            const resVerifyPhone = typePage === TypeSignInAndSignUp.SignUp ? 
              await signupLightByPhoneNumber({
                username: dataForm?.name || username || '',
                uuid: uuid,
                firebaseToken: result,
              }) :
              await signinLightByPhoneNumber({
                uuid: uuid,
                firebaseToken: result,
              });
            if (resVerifyPhone?.data) {
              const dataRedux = {
                tokenPMS: resVerifyPhone?.data?.tokenPMS,
                tokenUMS: resVerifyPhone?.data?.tokenUMS,
                username: resVerifyPhone?.data?.userName || username,
                userId: resVerifyPhone?.data?.userId,
                expiredTime: moment().add('hours', 22).toDate(),
                completedProfile: resVerifyPhone?.data?.completedProfile,
              }
              if (getDataPhoneNumber) {
                getDataPhoneNumber(`${dataForm.prefix}${dataForm.phoneNumber}`);
              }

              dispatch(setAuth(dataRedux));
              setStorage(StorageKey.AuthDesign, { ...getStorage(StorageKey.AuthDesign), ...dataRedux });
              setIsFetching(false);
              setFirebaseToken(result);
              redirectToPrevPath();
              onClose();
            } else {
              setIsFetching(false);
              setLoginErrMessage(t('Otp code is incorrect'));
            }

          } catch (e) {
            setIsFetching(false);
            setLoginErrMessage(t('Otp code is incorrect'));
          }
          // homebuyer login

        })
      }).catch((error: any) => {
        console.error('Verify code failed', error);
        setIsFetching(false);
        setCode('');
        setLoginErrMessage(t('Otp code is incorrect'));
        if (otpInput.current) {
          otpInput.current.focusInput(0);
        }
      });
    }else if(typeVerify === TypeChangePhoneNumberOrEmail.changeEmail){
      try {
        const resVerifyEmail = await verifyEmailApi({
          email: dataForm?.email || '',
          verifyCode: code,
        });
        if (resVerifyEmail?.data) {
          setIsFetching(false);
          if (getDataEmail) {
            getDataEmail(`${dataForm?.email}`);
          }
          onClose();
        } else {
          setLoginErrMessage(t('Otp code is incorrect'));
          setIsFetching(false);
        }
      } catch (e) {
        setLoginErrMessage(t('Otp code is incorrect'));
        setIsFetching(false);
      }
    }else if(typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber){
      confirmationResult?.confirm(code).then((result: any) => {
        result.user.getIdToken().then(async (result: any) => {
          // save tmp firebase token

          try {
            const resVerifyPhone = await updateUserPhoneNumberApi({
              telephone: `${dataForm.prefix}${dataForm.phoneNumber}`,
              firebaseToken: result
            });

            if (resVerifyPhone?.data) {
              if (getDataPhoneNumber) {
                getDataPhoneNumber(`${dataForm.prefix}${dataForm.phoneNumber}`);
              }

              setIsFetching(false);
              setFirebaseToken(result);
              onClose();
            } else {
              setIsFetching(false);
              setLoginErrMessage(t('Otp code is incorrect'));
            }

          } catch (e) {
            setIsFetching(false);
            setLoginErrMessage(t('Otp code is incorrect'));
          }
          // homebuyer login

        })
      }).catch((error: any) => {
        console.error('Verify code failed', error);
        setIsFetching(false);
        setCode('');
        setLoginErrMessage(t('Otp code is incorrect'));
        if (otpInput.current) {
          otpInput.current.focusInput(0);
        }
      });
    }
  }

  return (
    <div>
      <h3 className="title mb-1">{t("OTP Code")}</h3>
      {
        (typeVerify === TypeSinup.email || typeVerify === TypeChangePhoneNumberOrEmail.changeEmail) &&
        <p className="description mb-2">
          {t(
            'Get ready for a wild ride, my friend! With a 6-digit code sent to your email at {{email}}, you\'re seconds away from exploring the world from home.',
            { email: `${dataForm?.email}` }
          )}
          &nbsp;
          {timerCountdown > 0 ? t(
            'Resend in {{timerCountdown}} sec if you missed it.',
            { timerCountdown }
          ) : (
            <span className="resend-button" onClick={requestVerificationCode}>
              {t('Resend')}
            </span>
          )}
        </p>
      }
      {
        (typeVerify === TypeSinup.PhoneNumber || typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber) &&
        <p className="description mb-2">
          {t(
            'Get ready for a wild ride, my friend! With a 6-digit code sent to your phone at {{phone}}, you\'re seconds away from exploring the world from home.',
            { phone: `${dataForm?.prefix} ${dataForm?.phoneNumber}` }
          )}
          &nbsp;
          {timerCountdown > 0 ? t(
            'Resend in {{timerCountdown}} sec if you missed it.',
            { timerCountdown }
          ) : (
            <span className="resend-button" onClick={requestVerificationCode}>
              {t('Resend')}
            </span>
          )}
        </p>
      }
      <Alert style={{fontSize: '12px'}} message={loginErrMessage} severity="error" width="100%" />

      <div className='opt-input-wrapper mt-4 mb-4'>
        <OtpInput
          inputStyle="inputStyle text-body-book text-main inputOtp"
          focusStyle={{ outline: `solid ${theme?.palette?.accent?.main} 2px` }}
          value={code}
          errorStyle="error"
          onChange={(value?: any) => {
            setCode(value);
            setLoginErrMessage('');
          }}
          numInputs={OTP_NUMBER}
          separator=""
          placeholder="______"
          isInputNum
          ref={otpInput}
        />
      </div>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <Button
            fullWidth
            color='accent'
            disabled={code.length !== OTP_NUMBER}
            loading={isFetching}
            onClick={handleVerifyCode}
          >
            {(typeVerify === TypeSinup.PhoneNumber || typeVerify === TypeSinup.email) && t('Verify')}
            {(typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber
              || typeVerify === TypeChangePhoneNumberOrEmail.changeEmail)
              && t('Save')
            }
          </Button>
        </Grid>
        <Grid xs={12} item>
          <Button fullWidth color='primary' onClick={() => {
            onChangeTypeSignup && onChangeTypeSignup()
            setConfirmationResult(null);
          }}>{t('Back')}</Button>
        </Grid>
      </Grid>
      <div id="button-send-verify-code" />
    </div>
  );
}

export default SignupVerifyOtp;