import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';

// COMPONENTS
import MainPageHeader from './headers/MainPageHeader';

// CONTEXTS
import { fetchCollectionByApi } from 'apis/collectionApi';
import { fetchCurrencyAllByApi } from 'apis/currencyApis';
import { fetchMyOrderApi } from 'apis/orderApis';
import { fetchPhonePrefixApi } from 'apis/projectApis';
import ArabicOutlined from 'components/sgvIcons/ArabicOutlined';
import BrazilOutlined from 'components/sgvIcons/BrazilOutlined';
import ChinaOutlined from 'components/sgvIcons/ChinaOutlined';
import DollarOutlinedIcon from 'components/sgvIcons/DollarOutlined';
import EuroOutlined from 'components/sgvIcons/EuroOutlined';
import IndonesiaOutlined from 'components/sgvIcons/IndonesiaOutlined';
import JapanOutlined from 'components/sgvIcons/JapanOutlined';
import KoreaOutlined from 'components/sgvIcons/KoreaOutlined';
import MalaysiaOutlined from 'components/sgvIcons/MalaysiaOutlined';
import MexicoOutlined from 'components/sgvIcons/MexicoOutlined';
import PhilippineOutlined from 'components/sgvIcons/PhilippineOutlined';
import PolishPlotyOutlined from 'components/sgvIcons/PolishPlotyOutlined';
import PoundSterlingOutlined from 'components/sgvIcons/PoundSterlingOutlined';
import ThailandOutlined from 'components/sgvIcons/ThailandOutlined';
import VndOutlined from 'components/sgvIcons/VndOutlined';
import { ECurrency } from 'constants/currency';
import { OrderType } from 'constants/orderConstants';
import { PageRouteName, PageRouteNameOrderedValidation } from 'constants/route';
import { SessionKey, StorageKey } from 'constants/storage';
import { useCollectionContext } from 'contexts/collectionsContext';
import { useLayout } from 'contexts/layoutContext';
import { useOrderContext } from 'contexts/ordersContext';
import { useFunction, useLoadingCallback, useRoute } from 'hooks';
import { isEmpty } from 'lodash';
import Collection from 'model/collection';
import CurrencyModel from 'model/currency.model';
import Order, { OrderStatus, OrderStep } from 'model/order';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, getStorage, removeSession, removeStorage, setSession, setStorage } from 'services/storageService';
import { setAuth, setDesignHome, setFinancialPlan } from 'store/designHome/designHomeActions';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import { setCurrencyList } from 'store/global/globalActions';
import { checkFlow3D, checkInteractMobile } from 'utils/dataHelpers';
import { v4 as uuidV4 } from "uuid";
import { useLocation } from 'react-router-dom';

import fusionImg from 'assets/fusion_image.jpg'
import fusionLogo from 'assets/fusion_logo.png'
import IcLocationIpad from 'components/icons/IcLocationIpad';
import CallIcon from '@mui/icons-material/Call';

const MainLayout = ({ children }: PropsWithChildren<{}>) => {
  const { headerFloating, headerTransparent, isFloating } = useLayout();
  const dispatch = useDispatch();
  const { params, pathname, redirect } = useRoute();
  const { personalityId } = params as {
    personalityId: number;
  };

  const preferCurrency = getSession(SessionKey.Currency) as ECurrency;
  const { updateOrders } = useOrderContext();
  const { collections, updateCollections } = useCollectionContext();
  const { handleDemoStudio } = useFunction();
  const [isRenderHeader, setIsRenderHeader] = useState<boolean>(true);

  const {
    designHome: { orderStatus, orderType, staticDate },
    auth: { tokenPMS, tokenUMS },
  } = useSelector(selectDesignHome);

  const loadMyOrders = async () => {
    if (tokenPMS && tokenUMS) {
      await fetchMyOrderApi({ currencyCode: preferCurrency }).then(({ data }) => {
        const newMyOrders = (data || []).map((item: Order) => new Order().fromPayload(item));
        updateOrders(newMyOrders);
      });
    }
  };

  const [loadCollections] = useLoadingCallback(async () => {
    try {
      const { data } = await fetchCollectionByApi();
      const newCollections = (data || []).map((item: Collection) => new Collection().fromPayload(item));
      updateCollections(newCollections);
    } catch (error) {
      console.log(error);
    }
  });

  const loadCurrencyList = async () => {
    try {
      const { data } = await fetchCurrencyAllByApi();
      if (!isEmpty(data)) {
        const newCurrency = (data || []).map((item: CurrencyModel) => {
          const newItem = { ...item };
          switch (item.code) {
            case ECurrency.Usd:
              newItem.icon = <DollarOutlinedIcon width="22px" height="22px" />;
              break;

            case ECurrency.Vnd:
              newItem.icon = <VndOutlined width="22px" height="22px" />;
              break;

            case ECurrency.Brl:
              newItem.icon = <BrazilOutlined width="22px" height="22px" />;
              break;

            case ECurrency.Eur:
              newItem.icon = <EuroOutlined width="22px" height="22px" />;
              break;

            case ECurrency.Cny:
              newItem.icon = <ChinaOutlined width="22px" height="22px" />;
              break;

            case ECurrency.Thb:
              newItem.icon = <ThailandOutlined width="22px" height="22px" />
              break;

            case ECurrency.Idr:
              newItem.icon = <IndonesiaOutlined width="22px" height="22px" />
              break;

            case ECurrency.Myr:
              newItem.icon = <MalaysiaOutlined width="22px" height="22px" />
              break;

            case ECurrency.Php:
              newItem.icon = <PhilippineOutlined width="22px" height="22px" />
              break;

            case ECurrency.Krw:
              newItem.icon = <KoreaOutlined width="22px" height="22px" />
              break;

            case ECurrency.Jpy:
              newItem.icon = <JapanOutlined width="22px" height="22px" />
              break;

            case ECurrency.Mxn:
              newItem.icon = <MexicoOutlined width="22px" height="22px" />
              break;

            case ECurrency.Gbp:
              newItem.icon = <PoundSterlingOutlined width="22px" height="22px" />
              break;

            case ECurrency.Egp:
              newItem.icon = <ArabicOutlined width="22px" height="22px" />
              break;

            case ECurrency.PLN:
              newItem.icon = <PolishPlotyOutlined width="22px" height="22px" />
              break;

            default:
              newItem.icon = <DollarOutlinedIcon width="22px" height="22px" />;
              break;
          }

          return new CurrencyModel().fromPayload(newItem);
        });
        dispatch(setCurrencyList(newCurrency));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkShouldHeaderRender = () => {
    if (pathname.includes(PageRouteName.WaitList)) {
      setIsRenderHeader(false);
    } else {
      setIsRenderHeader(true);
    }
  }

  // Phone Prefix
  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchPhonePrefixApi();
        setSession(SessionKey.PhonePrefix, data?.prefix);
      } catch (error) {
        console.log('Cannot Get Phone Prefix');
      }
    })();
  }, [])

  useEffect(() => {
    if (getStorage(StorageKey.StepYourDesign)) {
      dispatch(setDesignHome({ ...getStorage(StorageKey.StepYourDesign) }));
    }
    if (getStorage(StorageKey.AuthDesign)) {
      const authDesign = getStorage(StorageKey.AuthDesign);
      //check expiredTime and remove storage
      console.debug("expiredTime: ", authDesign?.expiredTime, moment(new Date(authDesign?.expiredTime)), moment(), moment(authDesign?.expiredTime, 'DD/MM/YYYY HH:MM:SS').isBefore(moment()))
      if (authDesign?.expiredTime && moment(new Date(authDesign?.expiredTime)).isBefore(moment())) {
        removeStorage(StorageKey.AuthDesign);
      } else {
        dispatch(setAuth({ ...authDesign }));
      }
    }
    if (getStorage(StorageKey.OrderTemp)) {
      dispatch(setFinancialPlan({ ...getStorage(StorageKey.OrderTemp)?.financialPackage }));
    } else {
      if (getStorage(StorageKey.FinancialPackage)) {
        dispatch(setFinancialPlan({ ...getStorage(StorageKey.FinancialPackage) }));
      }
    }

    let uuid = getStorage(StorageKey.UUID_Design);
    if (!uuid) {
      uuid = uuidV4();
      setStorage(StorageKey.UUID_Design, uuid);
    }
  }, [])

  // Load My Orders
  useEffect(() => {
    loadMyOrders();
  }, [tokenPMS, tokenUMS]);

  // Load Currency
  useEffect(() => {
    loadCurrencyList();
  }, []);

  // Load Collections
  useEffect(() => {
    if (pathname.includes(PageRouteName.ChooseCollection) || pathname.includes(PageRouteName.YourDesign)) {
      if (isEmpty(collections)) {
        loadCollections()
      }
    }
  }, [pathname]);

  const [shortLink, setSortLink] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (navigator.languages && navigator.languages.length) {
      const browserLanguage = navigator.languages[0];
      setLanguage(browserLanguage)
    } else {
      const browserLanguage = navigator.language;
      setLanguage(browserLanguage)
    }
    if (pathname.includes(PageRouteName.ChooseSegment) || pathname.includes(PageRouteName.ChooseBlock)) {
      const newData = { layoutId: undefined, productItems: undefined, personalityProperties: undefined };
      dispatch(setDesignHome({ ...newData }));
      setStorage(StorageKey.StepYourDesign, { ...getStorage(StorageKey.StepYourDesign), ...newData });
    }

    if (PageRouteNameOrderedValidation.some(item => pathname.includes(item)) &&
      (getStorage(StorageKey.StepOrder) === OrderStep.Ordered ||
        orderStatus === OrderStatus.Booked ||
        orderStatus === OrderStatus.Bought ||
        orderStatus === OrderStatus.Cancelled ||
        orderStatus === OrderStatus.Pending ||
        orderStatus === OrderStatus.PreOrdered)) {
      if (!checkFlow3D(orderType!)) {
        redirect('/');
      } else {
        if (staticDate) {
          if (moment() > moment(staticDate)) {
            redirect('/');
          }
        } else {
          if (orderType! === OrderType.WAITTING_LIST_PERSONALISATION) return;
          redirect('/');
        }
      }
    }

    if (
      pathname.includes(PageRouteName.YourDesign) ||
      pathname.includes(PageRouteName.ChooseCollection) ||
      pathname.includes(PageRouteName.SignatureLayout)
    ) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('segmentId')) {
        const newData = {
          blockId: searchParams.get('blockId'),
          floorId: searchParams.get('floorId'),
          planId: searchParams.get('planId'),
          status: searchParams.get('status'),
          segmentId: searchParams.get('segmentId')
        }
        setStorage(StorageKey.StepYourDesign, { ...getStorage(StorageKey.StepYourDesign), ...newData })
      }
      
      const blockId = getStorage(StorageKey.StepYourDesign)?.blockId || '';
      const floorId = getStorage(StorageKey.StepYourDesign)?.floorId || '';
      const planId = getStorage(StorageKey.StepYourDesign)?.planId || '';
      const status = getStorage(StorageKey.StepYourDesign)?.status || '';
      const segmentId = getStorage(StorageKey.StepYourDesign)?.segmentId || '';
      if (!blockId || !floorId || !planId || !status || !segmentId) {
        redirect('/');
      }
    }

    if (checkInteractMobile()) {
      checkShouldHeaderRender();
      const isRedirect = pathname.includes(PageRouteName.FAQ) ||
        pathname.includes(PageRouteName.ContactUs) ||
        pathname.includes(PageRouteName.InTheSplotlight) ||
        pathname.includes(PageRouteName.OurStory) ||
        pathname.includes(PageRouteName.WaitList) ||
        pathname.includes(PageRouteName.Fusion) ||
        pathname.includes(PageRouteName.FushionOwner) ||
        pathname.includes(PageRouteName.MyOrderConfirm);
      if (!isRedirect && !personalityId) {
        handleDemoStudio();
        removeSession(SessionKey.isDemo3DInteract);
      } else {
        if (pathname.includes(PageRouteName.Fusion) || pathname.includes(PageRouteName.FushionOwner) || pathname.includes(PageRouteName.MyOrderConfirm)) {
          setSortLink(true)
          return
        }
      }
    }

    if (pathname.includes(PageRouteName.Fusion)) {
      redirect('/project-listing/2-Vietnam/51-Fusion-Suites')
    }

    if (pathname.includes(PageRouteName.FushionOwner)) {
      redirect('/project-listing-resell/2-Vietnam')
    }
  }, [pathname]);

  const [language, setLanguage] = useState<any>();

  const telShortLink = () => {
    window.open('tel:+84938639461')
  }

  const divStyle: React.CSSProperties = {
    backgroundImage: `url(${fusionImg})`,
    position: 'relative', // or 'absolute', 'fixed', etc., depending on your needs
    backgroundSize: 'cover', // Ensures the image covers the entire div
    backgroundPosition: 'left', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    height: '100vh', // Full viewport height
    width: '100%', // Full width
    overflow: 'hidden',
    zIndex: 10
  };

  const logoStyle: React.CSSProperties = {
    position: 'absolute',
    top: '6rem',
    left: '2.5rem',
    maxWidth: '50vw',
    maxHeight: '20vh'
  }

  const wrapperStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: 'calc(3rem + 50px)',
    left: '2rem',
    right: '2rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  }

  const textStyle: React.CSSProperties = {
    fontSize: '2.2rem',
    fontWeight: 'bold'
  }

  return (
    <Template>
      {shortLink ? <div style={divStyle}>
        <img src={fusionLogo} style={logoStyle} alt="" />
        <div style={wrapperStyle}>
          <p style={textStyle}>{language === "vi" ? 'Liên kết xem tốt hơn trên máy tính hoặc máy tính bảng' : 'This link is better to be viewed on a computer or laptop'}</p>
          <CallIcon onClick={telShortLink} sx={{ fontSize: 75, background: '#8eb28e', color: 'white', borderRadius: '50%', padding: '1rem', marginBottom: '1rem' }} />
        </div>
        {/* <img style={{ height: 'calc(100vh)', width: '100vw' }} src={language === "vi" ? fusionImg : fusionImg} alt="" srcSet="" /> */}
      </div> : (
        <>{isRenderHeader &&
          <MainPageHeader
            floating={headerFloating ? headerFloating : isFloating}
            transparent={isFloating ? false : headerTransparent}
          />
        }</>
      )}
      <main>{children}</main>
    </Template>
  );
};

const Template = styled.div`
  // width: 100vw;
  // display: grid;
  // grid-template-rows: auto 1fr;

  background: ${p => p.theme?.palette?.primary?.dark};
  // height: 100%;
`;

export default MainLayout;
