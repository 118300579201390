import { useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import { isEmpty, startsWith, endsWith, mapValues } from 'lodash';

import { Obj } from 'constants/types';
import { OldPageRoute, PAGE_ROUTE_STEP_MAP } from 'constants/route';

type Path = {
  path: string;
  params?: Record<string, string | number>;
};

const useRoute = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<Obj>();

  const memoParams = useMemo(
    () =>
      mapValues(params, (value, key) => {
        if (key === 'id' || endsWith(key, 'Id')) {
          return value.split('-')[0];
        }

        return value;
      }),
    [params]
  );

  const pathname = location.pathname as OldPageRoute;

  const query = useMemo(() => {
    return qs.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });
  }, [location.search]);

  const generatePath = useCallback(
    (path: string | Path, queryParams?: Obj): string => {
      if (typeof path !== 'string') {
        const params = path.params;
        path = path.path;

        if (params) {
          for (const key in params) {
            path = path.replace(`:${key}`, `${params[key]}`);
          }
        }
      }

      if (startsWith(path, './')) {
        path = pathname + path.slice(1);
      } else if (startsWith(path, '../')) {
        path = pathname.slice(0, pathname.lastIndexOf('/')) + path.slice(2);
      }

      path = path.replaceAll(' ', '-');

      if (!isEmpty(queryParams)) {
        path += `?${qs.stringify(queryParams || {})}`;
      }
      return path;
    },
    []
  );

  const redirect = useCallback((path: string | Path, queryParams?: Obj) => {
    history.push(generatePath(path, queryParams));
  }, []);

  const popHistory = useCallback(() => {
    history.goBack();
  }, []);

  return {
    query,
    params: memoParams,
    redirect,
    popHistory,
    generatePath,
    pathname,
    routeStep: PAGE_ROUTE_STEP_MAP[pathname] || null,
  };
};

export default useRoute;
