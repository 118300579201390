export const DATA_FAQ_NL = (textBrand: string) => {
  return [
    {
      title:
        `Wie is ${textBrand} International en wat brengen ze mee?`,
      description: `
            <p>Oké, laten we het hebben over de primaire vastgoedmarkt en de saaie, verouderde verkoopervaring die ermee gepaard gaat. ${textBrand} International is hier om het spel te veranderen. We zijn hier om alles op zijn kop te zetten, mijn vriend.</p>
            <p>We ontwikkelen samen de huizen van de toekomst. Hoe doen we dat? Door gebruik te maken van de kracht van personalisatietechnologie. Ja, dat klopt. ${textBrand} introduceert een verbluffende 3D-interieurontwerptool die wordt aangedreven door kunstmatige intelligentie. Ja, je hebt het goed gehoord. Met deze coole tool kun je in real-time door je leefruimte navigeren, kamer voor kamer, en je toekomstige huis vooraf modelleren.</p>
            <p>Met ${textBrand} begin je aan een soepele, plezierige en transparante reis. Geen verrassingen meer, geen vertragingen. Het is een naadloze, efficiënte reis naar jouw droomhuis.</p>
           `,
    },
    {
      title:
        'Wat is een Segment en hoe werkt het bij het kopen van mijn nieuwe huis?',
      description: `
            <p>Oké, mijn vriend, laten we het hebben over segmenten en hoe ze hun magie laten werken in de wereld van het kopen van een huis via ${textBrand}.</p>
            <p>Een segment is als een puzzelstukje dat kan worden gecombineerd om een ruimte te creëren die aansluit op jouw wensen en budget.</p>
            <p>Zie het als een aparte leefruimte die je individueel of in meervoud kunt verwerven, waardoor je jouw nieuwe huis kunt personaliseren om aan al jouw behoeften en wensen te voldoen.</p>
           `,
    },
    {
      title:
        'Waarom is een Reserveringsovereenkomst zo belangrijk in het koopproces?',
      description: `
            <p>Oké, laten we het hebben over deze Reserveringsovereenkomst. Misschien denk je: "Wat is dat, en waarom zou het mij iets kunnen schelen?" Nou, mijn vriend, laat me het voor je uitleggen. Deze overeenkomst is een cruciaal element in de wilde rit van het kopen van een woning. Waarom? Omdat het is als een verdomd vangnet, mijn vriend.</p>
            <p>Wanneer je een Reserveringsovereenkomst ondertekent, zeg je eigenlijk: "Wacht even, ik heb mijn oog op deze woning en ik wil er zeker van zijn dat het van mij is."</p>
            <p>Waarom is dat zo belangrijk? Simpel. Het geeft je gemoedsrust en beschermt jouw belangen. Het zorgt ervoor dat de woning niet onder je vandaan wordt verkocht terwijl je druk bezig bent met de puntjes op de i te zetten.</p>
           `,
    },
    {
      title:
        'Wat staat er precies in een Koop- en Verkoopovereenkomst en wat houdt het in bij het kopen van een huis?',
      description: `
            <p>Een Koop- en Verkoopovereenkomst is als de heilige graal van een woningaankoop. Het is het document dat de deal bezegelt en jouw achterwerk beschermt in het proces.</p>
            <p>Laat me het voor je uitleggen: wanneer je een huis koopt, geef je niet zomaar je geld en hoop je op het beste. Daar komt dit document om de hoek kijken. Het is een juridisch bindende overeenkomst tussen jou en de verkoper waarin alle belangrijke zaken staan vermeld. We hebben het over de prijs van het huis, de voorwaarden van de overdracht en het tijdschema voor het afronden van de deal.</p>
            <p>Dit is geen informele afspraak, mijn vriend. Het is een serieuze verbintenis die ervoor zorgt dat beide partijen verantwoordelijk worden gehouden. Dus wanneer je klaar bent om die grote aankoop te doen, zorg ervoor dat je een solide Koop- en Verkoopovereenkomst hebt. Het is jouw vangnet in de wilde wereld van het kopen van een huis.</p>
           `,

    },
    {
      title:
        `Hoe kan je jouw wildste dromen over het kopen van een huis werkelijkheid laten worden met de mysterieuze krachten van ${textBrand} International?`,
      description: `
            <p>Dus, je bent erop gebrand om jouw droomhuis werkelijkheid te laten worden via ${textBrand} International? Nou, maak je klaar, mijn vriend, want ik ga het voor je uitleggen.</p>
            <p><strong>Stap één:</strong> kies het land van jouw voorkeur. Waar zie je jezelf het beste leven leiden? Maak je keuze en laten we beginnen.</p>
            <p><strong>Stap twee:</strong> we hebben een overvloed aan projecten vermeld, die wachten om door jou ontdekt te worden. Laat je meeslepen door de details, stel jezelf voor in die ruimtes en beeld je het leven in.</p>
            <p><strong>Stap drie:</strong> beperk het, mijn vriend. Kies het aantal segmenten dat je je kunt veroorloven en de verdieping die aansluit bij jouw ziel.</p>
            <p><strong>Stap vier:</strong> Hier wordt het spannend. Maak je klaar voor het verbluffende deel. Je kunt jouw appartement in een oogverblindende, instant 3D vooraf modelleren. Ja, we hebben het over het tot leven brengen van jouw droomhuis recht voor jouw ogen. Pas het aan, speel ermee en maak het perfect. Oh, en heb ik al gezegd dat je volledig inzicht en transparantie hebt in de kosten? Geen schimmige praktijken hier.</p>
            <p><strong>Stap vijf:</strong> Zodra je tevreden bent met jouw meesterwerk, is het tijd om de deal te bezegelen. Onderteken jouw reserveringsovereenkomst en koopovereenkomst online, en voilà!</p>
            <p>Ontspan nu, mijn vriend, en laat de anticipatie toenemen. Voor je het weet, zul je lekker achteroverleunen in het huis waar je altijd van hebt gedroomd. ${textBrand} is er om het te laten gebeuren.</p> 
          `,
    },

    {

      title:
        'Hoe kun je je aanmelden voor de wachtlijst en op de hoogte blijven van binnenkort te lanceren woningen?',
      description: `
            <p>Dus je bent enthousiast om je handen te leggen op die ongrijpbare "Binnenkort beschikbaar" woningen, toch? Ik geef je geen ongelijk. Nou, hier is het geheim om bij te blijven en voorop te lopen.</p>
            <p>Ga naar onze website en zoek naar het gedeelte dat zegt "Blijf op de hoogte". Het is als een schatkamer voor huizenkopers zoals jij. Zoek de optie om je aan te melden voor de wachtlijst. Voer jouw e-mailadres in en beschouw jezelf officieel op de hoogte.</p>
            <p>We zorgen ervoor dat jij de eerste bent die weet wanneer die spannende projecten klaar zijn om van start te gaan. Het is als het hebben van een backstagepas voor de vastgoedwereld. Dus verspil geen seconde meer, mijn vriend. Meld je aan voor de wachtlijst en laat het aftellen beginnen.</p>
          `,
    },

    {
      title:
        `Hoe geeft ${textBrand} International prioriteit aan de bescherming van jouw waardevolle gegevens, en welke partnerschappen zijn er om veilige transacties te waarborgen?`,
      description: `
            <p>Bij ${textBrand} International nemen we geen genoegen als het gaat om gegevensbescherming. Jouw informatie is als goud voor ons, en we bewaken het met al onze kracht. We nemen geen genoegen met middelmatige oplossingen. We zijn een samenwerking aangegaan met de besten der besten om jouw gegevens veilig en intact te houden.</p>
            <p>We werken samen met Tazapay, een in Singapore gevestigd bedrijf dat gespecialiseerd is in het veilig bewaren van jouw betalingsgegevens. Maar daar houdt het niet op. We hebben de zaken naar een hoger niveau getild met de innovatieve technologie van Pandadoc voor digitale contractondertekening.</p>
            <p>We laten geen steen onberoerd, mijn vriend. Jouw gegevens hebben onze hoogste prioriteit, en we hebben de juiste partnerschappen om ervoor te zorgen dat dit gegarandeerd is.</p>
          `,
    },
  ];
};
