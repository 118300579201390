export const POLICY_VI = (textBrand: string) => {
  return [
    {
      title: 'Giới thiệu',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>${textBrand} International Pte. Ltd. ("<strong>chúng tôi</strong>"  hoặc “<strong>${textBrand}</strong>”), là đơn vị vận hành của <a href="https://levitate.homes">https://levitate.homes</a> ("<strong>Website</strong>"), và chúng tôi cam kết bảo vệ Dữ Liệu Cá Nhân của bạn (như đã định nghĩa tại Điều 2 bên dưới) theo các điều khoản và điều kiện tại chính sách này. </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Chính Sách Bảo Mật này (cùng với Điều Khoản Sử Dụng của chúng tôi và bất cứ tài liệu nào khác được tham chiếu đến) quy định cách Dữ Liệu Cá Nhân mà chúng tôi thu thập từ bạn, hoặc bạn cung cấp cho chúng tôi, sẽ được sử dụng, xử lý, hoặc cung cấp bởi chúng tôi, bao gồm phương thức những thông tin nhất định sử dụng để cá nhân hóa những quảng cáo được hướng đến bạn. Chính Sách Bảo Mật này cũng quy định các loại Dữ Liệu Cá Nhân mà chúng tôi thu thập, cách chúng tôi bảo mật dữ liệu đó, cũng như cách bạn có thể truy cập hoặc thay đổi Dữ Liệu Cá Nhân của mình do chúng tôi lưu giữ. Chính Sách Bảo Mật này bao gồm việc bạn sử dụng Website và bất kỳ cách nào khác mà bạn có thể kết nối hoặc tương tác với chúng tôi thông qua việc sử dụng các sản phẩm và dịch vụ của chúng tôi (sau đây được gọi chung là "<strong>Dịch Vụ</strong>").</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Vui lòng đọc kỹ phần tiếp theo để hiểu chính sách và phương thức thực hiện của chúng tôi liên quan đến Dữ Liệu Cá Nhân của bạn và cách chúng tôi xử lý dữ liệu đó.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Trừ khi bị hạn chế bởi pháp luật hiện hành, bằng cách sử dụng Website và Dịch Vụ, bạn đồng ý rằng bất kỳ và tất cả Dữ Liệu Cá Nhân liên quan đến bạn do chúng tôi thu thập hoặc do bạn cung cấp tùy từng thời điểm có thể được sử dụng, xử lý, và cung cấp cho những đối tượng và mục đích được nêu tại Chính Sách Bảo Mật này.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.5.</div>
              <div>Với nỗ lực để đảm bảo rằng chúng tôi quản lý, bảo vệ, và xử lý Dữ Liệu Cá Nhân của bạn một cách phù hợp, chúng tôi sẽ xem xét các chính sách, thủ tục, và quy trình của chúng tôi tuỳ từng thời điểm. Chúng tôi có thể sửa đổi Chính Sách Bảo Mật này bất kỳ thời điểm nào bằng cách đăng phiên bản sửa đổi lên Website. Chúng tôi có thể nỗ lực gửi thông báo mỗi khi chính sách được sửa đổi. Dẫu vậy, bạn được xem là đã thừa nhận và đồng ý với Chính Sách Bảo Mật đã được sửa đổi nếu bạn tiếp tục sử dụng Website hoặc Dịch Vụ sau khi các thay đổi có hiệu lực bất kể bạn có nhận được thông báo hay không. Bạn được nhắc nhở kiểm tra trang này tuỳ từng thời điểm để biết các cập nhật đối với Chính Sách Bảo Mật này.</div>
            </div>
          </div>
          `
    },
    {
      title: 'Thu thập Dữ Liệu Cá Nhân',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                Bạn có thể chọn sử dụng Website và dùng các chức năng giới hạn của nó mà không cần tiết lộ Dữ Liệu Cá Nhân của bạn. Bạn không bắt buộc phải cung cấp Dữ Liệu Cá Nhân như một điều kiện để sử dụng Website, ngoại trừ trường hợp cần thiết để chúng tôi có thể cung cấp Dịch Vụ mà bạn mua hoặc truy cập thông qua Website của chúng tôi, hoặc để đáp ứng yêu cầu của bạn, hoặc trong trường hợp bạn liên hệ chúng tôi trực tiếp.
              </div>
              <div>
              Khi bạn sử dụng Dịch Vụ của chúng tôi, chúng tôi thu thập nhiều thông tin khác nhau từ bạn và về bạn, thiết bị của bạn và sự tương tác của bạn với Dịch Vụ. Một số thông tin được dùng để nhận dạng bạn một cách trực tiếp hoặc có thể được sử dụng để nhận dạng bạn một cách gián tiếp khi kết hợp với dữ liệu khác. Bất kỳ dữ liệu nào, cho dù đúng hay không, về một cá nhân có thể được xác định từ dữ liệu đó; hoặc từ dữ liệu đó và thông tin khác mà chúng tôi có hoặc có khả năng có, được xem là Dữ Liệu Cá Nhân ("<strong>Dữ Liệu Cá Nhân</strong>").
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.1.</div>
                  <div>
                    <strong>Thông tin bạn tự nguyện cung cấp</strong>. Khi sử dụng Dịch Vụ của chúng tôi, bạn có thể được yêu cầu cung cấp thông tin cá nhân về bản thân, như tên của bạn, thông tin liên hệ, thông tin thanh toán, chi tiết về nhà hoặc bất động sản bạn quan tâm, thông tin tài chính, (các) sở thích của bạn, hoặc (các) phong cách của bạn và những thông tin khác. Điều này có thể xảy ra trong các trường hợp ví dụ như:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Khi bạn thực hiện (các) giao dịch với chúng tôi trên hoặc liên quan đến Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Khi bạn đăng ký tài khoản với chúng tôi trên Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Khi bạn liên hệ với bộ phận dịch vụ khách hàng hoặc nhân viên bán hàng của chúng tôi qua email, điện thoại, hoặc gặp trực tiếp, để giải quyết bất kỳ vấn đề nào mà bạn có thể gặp phải, có thắc mắc, hoặc yêu cầu;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Khi bạn đăng ký các dịch vụ mà chúng tôi đang cung cấp trên Website, hoặc nhận thông báo qua email và/hoặc bản tin của chúng tôi;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Khi bạn cung cấp hoặc để lại (các) dữ liệu đầu vào, (các) phản hồi, thông tin trên Website cho mục đích sử dụng Dịch Vụ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Khi bạn trao đổi với một chuyên gia bất động sản (như đại lý bất động sản hoặc nhà môi giới, bên cho vay, hoặc chuyên viên  tín dụng, nhà quản lý bất động sản, nhà đầu tư,  đơn vị xây dựng nhà hoặc những cá nhân, tổ chức khác) thông qua Dịch Vụ; và</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g. </div>
                        <div>khi bạn hoàn thành các biểu mẫu hoặc giao dịch khác, như yêu cầu thông tin khoản vay hoặc đăng ký kiểm tra thông tin cơ bản của bất động sản (nếu có).</div>
                      </div>
                    </div>
                    <div>
                      Bạn cũng có thể cung cấp thông tin về bên thứ ba thông qua Dịch Vụ, chẳng hạn như bạn chia sẻ danh sách bất động sản với người nhận qua email hoặc tin nhắn SMS. Bằng cách cung cấp thông tin như vậy cho chúng tôi, bạn đảm bảo với chúng tôi rằng bạn đã nhận được sự đồng ý của bên thứ ba về việc bạn cung cấp cho chúng tôi Dữ Liệu Cá Nhân của họ cho các mục đích được nêu trong Chính Sách Bảo Mật này.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.2.</div>
                  <div>
                    <strong>Cookie, Pixel và các cơ chế theo dõi khác</strong>. Chúng tôi và các đối tác của chúng tôi sử dụng các công nghệ khác nhau để thu thập thông tin tự động khi bạn truy cập và sử dụng Dịch Vụ của chúng tôi, bao gồm cookie và các công nghệ tương tự khác. Cookie là các đơn vị thông tin điện tử có thể được chuyển đến máy tính của bạn hoặc thiết bị điện tử khác để nhận dạng riêng biệt trình duyệt của bạn. Khi bạn sử dụng Dịch Vụ, chúng tôi và các đối tác của chúng tôi có thể đặt một hoặc nhiều cookie trên máy tính hoặc các thiết bị điện tử khác của bạn hoặc sử dụng các công nghệ khác cung cấp chức năng tương tự.
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Chúng tôi và các đối tác của chúng tôi có thể sử dụng cookie để kết nối hoạt động của bạn đối với Dịch Vụ và thông tin khác mà chúng tôi lưu trữ về bạn trong hồ sơ tài khoản của bạn hoặc các tương tác trước đây của bạn đối với Dịch Vụ của chúng tôi, ví dụ lưu trữ các tùy chọn của bạn. Việc sử dụng cookie giúp chúng tôi cải thiện chất lượng Dịch Vụ của chúng tôi được cung cấp đến bạn, bằng cách xác định thông tin mà bạn quan tâm nhất, theo dõi xu hướng, đo lường hiệu quả của quảng cáo, hoặc lưu trữ thông tin bạn có thể muốn truy cập định kỳ, như các bất động sản mà bạn đã lọc, hoặc các tìm kiếm ưa thích của bạn, và thông qua các tác vụ khác được pháp luật áp dụng cho phép. Bất cứ lúc nào, bạn cũng có thể điều chỉnh cài đặt trên trình duyệt của mình để từ chối cookie theo các hướng dẫn liên quan đến trình duyệt của bạn. Tuy nhiên, nếu bạn chọn vô hiệu hoá cookie, nhiều tính năng miễn phí của Dịch Vụ có thể không hoạt động một cách bình thường.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Một số trang của chúng tôi cũng có thể bao gồm pixel, là các tệp điện tử để đếm số người dùng đã truy cập trang đó, để theo dõi hoạt động theo thời gian và trên các Website khác nhau, để xác định tương tác của người dùng với email mà chúng tôi gửi, để xác định một số cookie nhất định trên máy tính hoặc thiết bị điện tử khác truy cập vào trang đó, hoặc để thu thập thông tin liên quan khác, và thông tin này có thể được liên kết với trình duyệt, mã định danh thiết bị, hoặc địa chỉ IP duy nhất của bạn. Ví dụ, chúng tôi có thể triển khai pixel trên các Dịch Vụ cụ thể nơi bạn xem một danh sách nhất định để chúng tôi có thể theo dõi xem danh sách đó có liên quan đến bạn hay không trong khi đưa ra các đề xuất. Thông qua các pixel và cookie này, chúng tôi thu thập thông tin về việc bạn sử dụng Dịch Vụ của chúng tôi, bao gồm loại trình duyệt bạn sử dụng, thời gian truy cập, các trang đã xem, địa chỉ IP của bạn, và trang bạn đã truy cập trước khi điều hướng đến Dịch Vụ của chúng tôi.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Chúng tôi cũng thu thập thông tin về máy tính hoặc thiết bị di động mà bạn sử dụng để truy cập Dịch Vụ của chúng tôi như phần cứng, hệ điều hành và phiên bản, mã định danh thiết bị, thông tin mạng di động và hành vi sử dụng trình duyệt.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Chúng tôi cũng cho phép một số bên thứ ba thu thập thông tin về các hoạt động trực tuyến của bạn thông qua cookie và các công nghệ khác khi bạn sử dụng Website. Các bên thứ ba này bao gồm (a) các đối tác kinh doanh, những người thu thập thông tin khi bạn xem hoặc tương tác với một trong các quảng cáo của họ trên Website và (b) các mạng lưới quảng cáo mà thu thập thông tin về sở thích của bạn khi bạn xem hoặc tương tác với một trong các quảng cáo họ đặt trên nhiều Website khác nhau trên Internet. Thông tin do các bên thứ ba này thu thập là thông tin ẩn danh được sử dụng để đưa ra dự đoán về đặc điểm, sở thích hoặc ưu tiên của bạn và để hiển thị quảng cáo trên Website và trên Internet phù hợp với sở thích của bạn.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Bạn có thể quản lý loại cookie được cho phép thông qua thiết lập trình duyệt của mình, bao gồm cả việc chặn hoàn toàn tất cả cookie nếu bạn muốn. Để biết thông tin về cách quản lý cookie trên trình duyệt của bạn, vui lòng xem phần hỗ trợ của trình duyệt bạn đang sử dụng.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.3.</div>
                  <div>
                    <strong>Thông tin thiết bị di động và trình duyệt di động.</strong> Bạn có thể điều chỉnh cài đặt trên thiết bị di động và trình duyệt di động của mình liên quan đến cookie và chia sẻ thông tin nhất định, như kiểu thiết bị di động hoặc ngôn ngữ thiết bị di động mà bạn sử dụng, bằng cách điều chỉnh cài đặt quyền riêng tư và bảo mật trên thiết bị di động của bạn. Vui lòng tham khảo hướng dẫn do nhà cung cấp dịch vụ di động hoặc nhà sản xuất thiết bị di động của bạn cung cấp.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.4.</div>
                  <div>
                    <strong>Thông tin về vị trí.</strong> Nếu bạn bật dịch vụ định vị trên thiết bị di động của mình, chúng tôi, với sự cho phép của bạn, có thể thu thập vị trí của thiết bị mà chúng tôi sử dụng để cung cấp cho bạn thông tin và quảng cáo dựa trên vị trí. Nếu bạn muốn tắt tính năng này, bạn có thể tắt dịch vụ định vị trên thiết bị di động của mình.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.5.</div>
                  <div>
                    <strong>Quản lý Email.</strong> Bạn có thể nhận được email từ chúng tôi vì nhiều lý do - ví dụ, nếu bạn đã có hoạt động thông qua Dịch Vụ, bạn đã đăng ký cho việc nhận báo cáo định kỳ hoặc bạn hoàn thành thiết kế của mình và chúng tôi gửi cho bạn các thông báo để theo dõi các bước sắp tới. Nếu bạn có tài khoản với chúng tôi, bạn có thể chỉnh sửa tùy chọn của mình thông qua cài đặt tài khoản. Ngoài ra, bạn có thể quản lý việc nhận một số phương thức thông tin bằng cách làm theo hướng dẫn có trong email mà chúng tôi gửi cho bạn. Vui lòng lưu ý rằng, ngay cả khi bạn hủy đăng ký nhận một số thư từ email nhất định, chúng tôi vẫn có thể cần gửi email cho bạn những thông tin giao dịch hoặc thông tin về quản trị quan trọng.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.6.</div>
                  <div>
                    <strong>Tuyên bố từ chối trách nhiệm.</strong> Chúng tôi sẽ không chịu trách nhiệm pháp lý đối với bất kỳ việc bạn tự nguyện tiết lộ Dữ Liệu Cá Nhân cho những người dùng khác liên quan đến việc sử dụng Website, tùy từng trường hợp.
                  </div>
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Mục đích sử dụng',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    Dữ Liệu Cá Nhân mà bạn cung cấp có thể được sử dụng và xử lý bởi chúng tôi (hoặc bên thứ ba (chẳng hạn như Google Analytics) thay mặt chúng tôi) một cách tổng hợp hoặc riêng lẻ và có thể được kết hợp với thông tin khác cho mục đích quản lý và vận hành Website, phạm vi bao gồm nhưng không giới hạn, các mục đích sau:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>Quản trị Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>Cải thiện trải nghiệm duyệt web của bạn bằng cách cá nhân hóa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Cho phép bạn sử dụng các Dịch Vụ có trên Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Cung cấp cho bạn các Dịch Vụ mà bạn đã yêu cầu một cách cụ thể;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Trả lời các thắc mắc hoặc yêu cầu của bạn theo email, và/hoặc (các) biểu mẫu trên Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Phản hồi và thực hiện hành động tiếp theo đối với các khiếu nại liên quan đến việc sử dụng Website hoặc bất kỳ Dịch Vụ nào do chúng tôi cung cấp;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>Gửi cho bạn thông tin liên lạc thương mại chung qua bất kỳ kênh liên lạc nào, không giới hạn ở email, cuộc gọi thoại, SMS và thông báo ứng dụng;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>Thực hiện các công việc hành chính văn phòng và vận hành;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>Cung cấp cho các bên thứ ba thông tin thống kê về người dùng của chúng tôi. Thông tin thống kê đó có thể được sử dụng để cá nhân hóa quảng cáo trên Website, nhưng không được sử dụng để xác định bất kỳ người dùng cá nhân nào;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>Tạo và quản lý tài khoản trực tuyến của bạn;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>Tạo điều kiện thuận lợi cho các hoạt động và giao dịch của bạn trên Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>Tạo điều kiện cho bạn tham gia vào các cuộc thi hoặc chương trình khuyến mại;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>Xử lý khoản thanh toán hoặc hoàn trả từ bạn hoặc cho bạn;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>Cung cấp cho bạn thông tin về các Dịch Vụ mà bạn yêu cầu từ chúng tôi; hoặc theo sự đồng ý trước của bạn, thông tin mà chúng tôi cảm thấy rằng bạn có thể quan tâm, qua email hoặc tin nhắn văn bản;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>Vận hành, đánh giá, và cải thiện hoạt động kinh doanh của chúng tôi (bao gồm phát triển các sản phẩm và dịch vụ mới; phân tích và nâng cao Dịch Vụ của chúng tôi);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>Hiểu khách hàng của chúng tôi, thực hiện phân tích dữ liệu và xử lý khác (bao gồm nghiên cứu thị trường và người tiêu dùng, phân tích xu hướng và việc ẩn danh);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>Trả lời nhận xét hoặc câu hỏi của bạn, hoặc tiếp nhận hồ sơ ứng tuyển (nếu có);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>Chia sẻ với các nhà cung cấp dịch vụ để họ có thể cung cấp Dịch Vụ cho bạn; và theo yêu cầu hoặc được pháp luật cho phép.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>Vì các mục đích mà chúng tôi có thể/sẽ thu thập, sử dụng, cung cấp, hoặc xử lý Dữ Liệu Cá Nhân của bạn tùy thuộc vào hoàn cảnh trong từng thời điểm, mục đích đó có thể không xuất hiện ở trên. Tuy nhiên, chúng tôi sẽ thông báo cho bạn về các mục đích khác như vậy tại thời điểm nhận được sự đồng ý của bạn, trừ khi việc xử lý Dữ Liệu Cá Nhân của bạn mà không có sự đồng ý của bạn được cho phép theo Luật Bảo Vệ Dữ Liệu Cá Nhân 2012 của Singapore ("<strong>PDPA</strong>") hoặc bất kỳ luật áp dụng nào khác.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>Quy định về không được gọi.</strong> Nếu bạn đã cung cấp cho chúng tôi (các) số điện thoại của bạn ("<strong>Số Điện Thoại</strong>") và cho biết rằng bạn đồng ý nhận thông tin tiếp thị hoặc khuyến mại khác qua Số Điện Thoại của mình, thì tùy từng trường hợp cụ thể, chúng tôi có thể liên hệ với bạn bằng Số Điện Thoại đó với thông tin về Dịch Vụ của chúng tôi ngay cả khi Số Điện Thoại này đã được đăng ký vào Danh sách không được gọi (nếu có, tùy thuộc vào quy định tại vùng lãnh thổ nơi bạn cư trú). Tuy nhiên, bạn có thể thông báo cho chúng tôi bằng văn bản nếu bạn không muốn được chúng tôi liên hệ với theo Số Điện Thoại của bạn cho những mục đích đó.</div>
              </div>
          </div>
          `
    },
    {
      title: 'Tính chính xác và bảo mật',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>4.1.</div>
                <div>Bạn nên đảm bảo rằng tất cả Dữ Liệu Cá Nhân được gửi cho chúng tôi là đầy đủ, chính xác, xác thực, và đúng đắn. Việc bạn không thực hiện đúng có thể dẫn đến chúng tôi không thể cung cấp cho bạn Dịch Vụ mà bạn đã yêu cầu.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.2.</div>
                <div>Chúng tôi sẽ nỗ lực một cách hợp lý để đảm bảo rằng Dữ Liệu Cá Nhân của bạn là chính xác và hoàn chỉnh nếu Dữ Liệu Cá Nhân của bạn có khả năng được chúng tôi sử dụng để đưa ra quyết định ảnh hưởng đến bạn hoặc tiết lộ cho một tổ chức khác. Tuy nhiên, điều này có nghĩa là bạn cũng phải cập nhật cho chúng tôi bất kỳ thay đổi nào trong Dữ Liệu Cá Nhân của bạn mà bạn đã cung cấp cho chúng tôi ngay từ ban đầu. Chúng tôi sẽ không chịu trách nhiệm cho việc dựa trên Dữ Liệu Cá Nhân không chính xác hoặc không đầy đủ phát sinh từ việc bạn không cập nhật cho chúng tôi bất kỳ thay đổi nào trong Dữ Liệu Cá Nhân của bạn mà bạn đã cung cấp cho chúng tôi ngay từ ban đầu.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.3.</div>
                <div>Chúng tôi sẽ thực hiện các biện pháp phòng ngừa một cách hợp lý về mặt tổ chức và kỹ thuật để ngăn chặn việc mất mát, lạm dụng hoặc thay đổi Dữ Liệu Cá Nhân của bạn.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.4.</div>
                <div>Chúng tôi sẽ lưu trữ tất cả Dữ Liệu Cá Nhân mà bạn cung cấp trên các máy chủ bảo mật ở định dạng được mã hóa.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.5.</div>
                <div>Tuy nhiên, bạn hiểu rằng việc truyền tải thông tin qua Internet không hoàn toàn an toàn. Mặc dù chúng tôi sẽ nỗ lực hết sức để bảo vệ Dữ Liệu Cá Nhân của bạn, nhưng chúng tôi không thể đảm bảo tính bảo mật của Dữ Liệu Cá Nhân của bạn được truyền đến Website; bất kỳ sự truyền tải nào đều là rủi ro của riêng bạn. Ngoài ra, chúng tôi không thể chịu trách nhiệm đối với bất kỳ việc sử dụng trái phép Dữ Liệu Cá Nhân nào của bạn bởi các bên thứ ba mà hoàn toàn do các yếu tố nằm ngoài tầm kiểm soát của chúng tôi.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.6.</div>
                <div>Khi chúng tôi cấp cho bạn (hoặc nơi bạn đã chọn) một mật khẩu cho phép bạn truy cập vào các phần nhất định của Website, bạn có trách nhiệm bảo mật mật khẩu này. Chúng tôi yêu cầu bạn không chia sẻ mật khẩu với bất kỳ ai. Chúng tôi sẽ KHÔNG BAO GIỜ yêu cầu bạn cung cấp mật khẩu của bạn trừ lúc bạn đăng nhập vào Website, tùy từng trường hợp.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.7.</div>
                <div>Chúng tôi khuyến nghị bạn thực hiện các bước để giữ an toàn cho thông tin cá nhân của mình, chẳng hạn như chọn một mật khẩu mạnh và giữ nó ở chế độ riêng tư, cũng như đăng xuất khỏi tài khoản người dùng và đóng trình duyệt web của bạn khi bạn đã sử dụng xong Dịch Vụ của chúng tôi trên một thiết bị dùng chung hoặc không được bảo mật.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Tiết lộ Dữ Liệu Cá Nhân',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>5.1.</div>
              <div>Chúng tôi sẽ không, nếu không có sự đồng ý rõ ràng của bạn, tiết lộ Dữ Liệu Cá Nhân của bạn cho các bên thứ ba vì mục đích tiếp thị trực tiếp. Sự đồng ý tiết lộ Dữ Liệu Cá Nhân cho các bên thứ ba, nhằm mục đích nhận thông tin liên lạc tiếp thị, được đưa ra thông qua biểu mẫu đăng ký của chúng tôi, trực tuyến hoặc trên giấy tờ.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>5.2.</div>
              <div>
                Để quản lý và vận hành Website, chúng tôi có thể tiết lộ Dữ Liệu Cá Nhân của bạn cho bất kỳ thành viên nào của chúng tôi, là các đơn vị liên kết và công ty con của chúng tôi. Chúng tôi cũng có thể tiết lộ Dữ Liệu Cá Nhân của bạn cho các bên thứ ba, chẳng hạn như các nhà cung cấp dịch vụ và cố vấn chuyên nghiệp của chúng tôi cho các mục đích sau:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>a.</div>
                    <div>Cho bất kỳ mục đích nào được phép theo bất kỳ luật hoặc quy định hiện hành nào;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>b.</div>
                    <div>Để tuân thủ bất kỳ quy định pháp luật nào;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>c.</div>
                    <div>Để thực thi hoặc áp dụng Điều Khoản Sử Dụng của chúng tôi;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>d.</div>
                    <div>Để bảo vệ quyền, tài sản và sự an toàn của chúng tôi cũng như các quyền, tài sản và sự an toàn của các các đơn vị liên kết và công ty con của chúng tôi, những người dùng khác của Website và những người khác;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>e.</div>
                    <div>Liên quan đến bất kỳ thủ tục pháp lý hiện hành hoặc trong tương lai; và</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>f.</div>
                    <div>liên quan đến giao dịch tài sản kinh doanh (ví dụ mua, bán, cho thuê, sáp nhập, hợp nhất, hoặc bất kỳ việc mua lại, xử lý, hoặc tài trợ nào khác của một tổ chức hoặc một phần của tổ chức hoặc của bất kỳ doanh nghiệp hoặc tài sản nào của một tổ chức) mà chúng tôi là một bên hoặc có thể là một bên.</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>5.3.</div>
              <div>
                Chúng tôi cũng sẽ không tiết lộ Dữ Liệu Cá Nhân của bạn cho các bên thứ ba mà không có sự đồng ý trước của bạn cho phép chúng tôi làm như vậy. Tuy nhiên, vui lòng lưu ý rằng chúng tôi có thể tiết lộ Dữ Liệu Cá Nhân của bạn cho các bên thứ ba mà không cần có sự đồng ý trước của bạn trong một số trường hợp nhất định, bao gồm nhưng không giới hạn, những trường hợp sau:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>a.</div>
                    <div>Các trường hợp yêu cầu hoặc cho phép tiết lộ thông tin theo quy định của pháp luật và/hoặc quy định áp dụng;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>b.</div>
                    <div>Các trường hợp trong đó mục đích của việc tiết lộ đó rõ ràng là vì lợi ích của bạn và nếu không thể đạt được sự đồng ý một cách kịp thời;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>c.</div>
                    <div>Các trường hợp cần tiết lộ thông tin để ứng phó với bất kỳ trường hợp khẩn cấp nào đe dọa tính mạng, sức khỏe, hoặc sự an toàn của chính bạn hoặc cá nhân khác;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>d.</div>
                    <div>Các trường hợp mà việc tiết lộ là cần thiết cho bất kỳ cuộc điều tra hoặc  quy trình tố tụng nào;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>e.</div>
                    <div>Các trường hợp Dữ Liệu Cá Nhân được tiết lộ cho bất kỳ nhân viên nào của một cơ quan thực thi pháp luật theo quy định;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>f.</div>
                    <div>Các trường hợp mà việc tiết lộ là cho một cơ quan công quyền và việc tiết lộ đó là cần thiết vì lợi ích của cộng đồng; và/hoặc</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>g.</div>
                    <div>Trong trường hợp việc tiết lộ đó mà không có sự đồng ý của bạn được PDPA hoặc pháp luật cho phép.</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>5.4.</div>
              <div>
                Khi chúng tôi tiết lộ Dữ Liệu Cá Nhân của bạn cho các bên thứ ba mà không có sự đồng ý của bạn, chúng tôi sẽ nỗ lực hết sức để yêu cầu các bên thứ ba đó bảo vệ Dữ Liệu Cá Nhân của bạn.
              </div>
            </div>
          </div>
          `
    },
    {
      title: 'Rút lại sự đồng ý',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Bạn có thể rút lại sự đồng ý của mình bằng cách liên hệ với bộ phận chăm sóc khách hàng của chúng tôi tại địa chỉ email <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Vui lòng lưu ý rằng nếu bạn thu hồi lại sự đồng ý đối với bất kỳ hoặc tất cả việc sử dụng Dữ Liệu Cá Nhân của bạn, tùy thuộc vào bản chất yêu cầu của bạn, chúng tôi có thể không có quyền tiếp tục cung cấp Dịch Vụ của chúng tôi cho bạn. Khi nhận được thông báo từ bạn, chúng tôi sẽ thông báo cho bạn về những hệ quả có thể xảy ra của việc thu hồi lại sự đồng ý, bao gồm nhưng không giới hạn việc chấm dứt bất kỳ thỏa thuận nào mà bạn có thể có với chúng tôi và việc bạn vi phạm nghĩa vụ hợp đồng hoặc cam kết của mình. Các quyền hợp pháp và biện pháp xử lý của chúng tôi trong trường hợp như vậy được bảo lưu một cách rõ ràng.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Truyền dữ liệu quốc tế',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>7.1.</div>
                <div>Chúng tôi hoạt động ở nhiều quốc gia và có thể tạm thời lưu trữ, xử lý, hoặc truyền Dữ Liệu Cá Nhân của bạn giữa bất kỳ quốc gia nào mà chúng tôi hoạt động để cho phép chúng tôi sử dụng Dữ Liệu Cá Nhân của bạn theo Chính Sách Bảo Mật này và Điều Khoản Sử Dụng của chúng tôi.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>7.2.</div>
                <div>Chúng tôi sẽ thực hiện tất cả các bước cần thiết một cách hợp lý để đảm bảo rằng Dữ Liệu Cá Nhân của bạn được truyền theo bất kỳ luật hoặc quy định hiện hành nào và thực hiện tất cả các bước hợp lý để đảm bảo rằng bất kỳ tổ chức nước ngoài nào nhận Dữ Liệu Cá Nhân của bạn đều bị ràng buộc bởi các nghĩa vụ có hiệu lực pháp lý để cung cấp cho người được truyền Dữ Liệu Cá Nhân một tiêu chuẩn bảo vệ ít nhất có thể so sánh được với mức độ bảo vệ theo PDPA.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Website của bên thứ ba',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Tùy từng thời điểm, Website có thể chứa các liên kết đến và đi từ các website của các mạng đối tác và nhà cung cấp của chúng tôi. Nếu bạn theo liên kết đến bất kỳ website nào trong số này, vui lòng lưu ý rằng các website này có chính sách bảo mật của riêng họ và chúng tôi không chấp nhận bất kỳ trách nhiệm hoặc nghĩa vụ pháp lý nào đối với các chính sách này. Vui lòng kiểm tra các chính sách này trước khi bạn gửi bất kỳ Dữ Liệu Cá Nhân nào đến các website này.
              </div>
            </div>
          `
    },
    {
      title: 'Truy cập, Xóa và Chỉnh sửa',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>9.1.</div>
              <div>Cán bộ Bảo vệ Dữ liệu (DPO) của chúng tôi chịu trách nhiệm đảm bảo rằng các yêu cầu truy cập, xóa hoặc sửa đổi Dữ Liệu Cá Nhân được xử lý đúng theo các thủ tục được quy định tại chính sách này.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>9.2.</div>
              <div>Tất cả các yêu cầu về thông tin hoặc yêu cầu sửa hoặc xóa đối với dữ liệu cá nhân hoặc xóa dữ liệu cá nhân do chúng tôi nắm giữ sẽ được thực hiện bằng cách gửi yêu cầu đến Cán Bộ Bảo Vệ Dữ Liệu của chúng tôi tại địa chỉ email: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>9.3.</div>
              <div>Vui lòng lưu ý rằng chúng tôi sẽ chỉ chấp nhận và thực hiện các yêu cầu tuân thủ Chính Sách Bảo Mật này bằng ngôn ngữ tiếng Anh. Bất kỳ yêu cầu nào không tuân thủ những điều trên sẽ bị từ chối và chúng tôi sẽ không thực hiện bất kỳ hành động nào liên quan đến những yêu cầu bị từ chối đó.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>9.4.</div>
              <div>Chúng tôi có thể từ chối tiết lộ, xóa hoặc sửa đổi bất kỳ Dữ Liệu Cá Nhân nào vì những lý do được nêu trong PDPA.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>9.5.</div>
              <div>Trong phạm vi có thể một cách phù hợp, chúng tôi sẽ trả lời yêu cầu của bạn trong vòng mười (10) ngày làm việc kể từ ngày nhận được yêu cầu. Nếu không thể thực hiện được yêu cầu của bạn, bạn cũng sẽ được thông báo trong vòng mười (10) ngày làm việc kể từ ngày chúng tôi nhận được yêu cầu từ bạn.</div>
            </div>
          </div>
          `
    },
    {
      title: 'Lưu giữ Dữ Liệu Cá Nhân của bạn',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>Chúng tôi lưu giữ Dữ Liệu Cá Nhân mà chúng tôi thu thập từ bạn khi chúng tôi có nhu cầu hợp pháp đang diễn ra để thực hiện việc này. Nếu bạn là khách hàng hoặc nhà cung cấp (hoặc đại diện của khách hàng hoặc nhà cung cấp) thì thông tin sẽ được lưu giữ trong một khoảng thời gian để cho phép chúng tôi cung cấp Dịch Vụ của mình, tuân thủ các yêu cầu pháp lý, thuế, hoặc kế toán hiện hành và nếu cần, thiết lập, thực hiện hoặc bảo vệ các yêu cầu pháp lý.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>Khi chúng tôi không có nhu cầu hợp pháp đang diễn ra để xử lý thông tin cá nhân của bạn, chúng tôi sẽ xóa hoặc ẩn danh thông tin đó hoặc nếu điều này là không thể (ví dụ: vì thông tin cá nhân của bạn đã được lưu trữ trong kho lưu trữ dự phòng), thì chúng tôi sẽ lưu trữ một cách an toàn thông tin cá nhân của bạn và tách biệt nó khỏi bất kỳ quá trình xử lý nào cho đến khi việc xóa có thể thực hiện được.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Liên hệ với chúng tôi',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                 <div>
                    Nếu bạn cần thêm thông tin về việc chúng tôi xử lý Dữ Liệu Cá Nhân của bạn, vui lòng liên hệ với Cán Bộ Bảo vệ Dữ liệu của chúng tôi tại địa chỉ email: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                  </div>
                </div>
              </div>
            `
    },
    {
      title: 'Luật điều chỉnh',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Toàn bộ Chính Sách Bảo Mật này được điều chỉnh bởi pháp luật Singapore.
              </div>
            </div>
            `
    }
  ]
};
