import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Stack,
  Switch,
} from '@mui/material';
import { checkExistPhoneNumber, signinLightByPhoneNumber, signupLightByPhoneNumber } from 'apis/userApis';
import { CountryPhoneCodeOptionsSortedByName } from 'components/PhoneNumberInput';
import Alert from 'components/alerts/Alert';
import { Button } from 'components/buttons';
import TextField from 'components/formControls/TextField';
import { SessionKey, StorageKey } from 'constants/storage';
import { TypeChangePhoneNumberOrEmail } from 'pages/MyProfile/PersonalProfile';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, getStorage, setStorage } from 'services/storageService';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import styled from 'styled-components';
import { object, string } from 'yup';
import { TypeSignInAndSignUp, TypeSinup } from '../SignupLight';
import moment from 'moment';
import { setAuth } from 'store/designHome/designHomeActions';

type SignupPhoneNumberProps = {
  telephone?: string;
  typeVerify?: string;
  typePage?: string,
  onChangeTypeVerify: (value: string) => void;
  onChangeStep: (data: { name: string; phoneNumber: string, prefix: string, email: string }) => void;
  setShow: (show: boolean) => void;
}

function SignupPhoneNumber(props: SignupPhoneNumberProps) {
  const { typeVerify, onChangeTypeVerify, onChangeStep, typePage, telephone, setShow } = props;
  const { t } = useTranslation();
  const [prefix, setPrefix] = useState<string>(CountryPhoneCodeOptionsSortedByName[0].value);
  const [loginErrMessage, setLoginErrMessage] = useState<string>('');
  const [loginSuccessMessage, setLoginSuccessMessage] = useState<string>('');
  const { textContentBrand: { textBrand } } = useSelector(selectDesignHome);

  useEffect(() => {
    const findLacaleVi = CountryPhoneCodeOptionsSortedByName?.find(
      item => item.value === (getSession(SessionKey.PhonePrefix) || "+84")
    );
    if (findLacaleVi) {
      setPrefix(findLacaleVi.value);
    }
  }, [])

  const validationSchema = object().shape({
    name: typeVerify === TypeSinup.PhoneNumber && typePage === TypeSignInAndSignUp.SignUp
      ? string().required(t('Name is required')).max(20)
      : string(),
    phoneNumber: string()
      .required(t('Phone number is required'))
      .matches(/^[0-9]+$/, t('Invalid phone number'))
      .max(50),
    prefix: string(),
  });

  const [isFetching, setIsFetching] = useState(false)

  const { control, handleSubmit, watch } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });

  const phoneNumber = watch('phoneNumber')

  useEffect(() => {
    setLoginErrMessage('');
  }, [phoneNumber])

  const dispatch = useDispatch();
  
  const onSubmit: SubmitHandler<{ name: string; phoneNumber: string, prefix: string }> = async (values) => {
    const phone = values?.phoneNumber?.startsWith('0') ? values?.phoneNumber?.slice(1) : values?.phoneNumber;
    let uuid = getStorage(StorageKey.UUID_Design);

    setIsFetching(true);
    if (typePage === TypeSignInAndSignUp.SignUp) {
      (async () => {
        try {
          if (telephone?.includes(phone)) {
            setLoginSuccessMessage(t('Your number has been succesfully updated.'));
            setIsFetching(false);
          } else {
            const { data } = await checkExistPhoneNumber({ phoneNumber: `${phone}` || '' });
            if (data) {
              setLoginErrMessage(t('This phone number has been registered.'));
            } else {
              const resVerifyPhone = await signupLightByPhoneNumber({
                username: values?.name,
                phoneNumber: `${prefix}${phone}`,
                uuid: uuid,
                firebaseToken: ''
              })
              if (resVerifyPhone?.data) {
                const dataRedux = {
                  tokenPMS: resVerifyPhone?.data?.tokenPMS,
                  tokenUMS: resVerifyPhone?.data?.tokenUMS,
                  username: resVerifyPhone?.data?.userName,
                  userId: resVerifyPhone?.data?.userId,
                  expiredTime: moment().add('hours', 22).toDate(),
                  completedProfile: resVerifyPhone?.data?.completedProfile,
                }
  
                dispatch(setAuth(dataRedux));
                setStorage(StorageKey.AuthDesign, { ...getStorage(StorageKey.AuthDesign), ...dataRedux });
                setIsFetching(false);
              } 
              setLoginErrMessage('');
              setShow(false)
              // onChangeStep({ ...values, email: '', prefix: prefix ? prefix : '' });
            }
            setIsFetching(false);
          }
        } catch (e) {
          setLoginErrMessage(t('Error server'));
          setIsFetching(false);
        }
      })()
    } else {
      (async () => {
        try {
          const { data } = await checkExistPhoneNumber({ phoneNumber: `${phone}` || '' });
          if (!data) {
            setLoginErrMessage(t('This phone number has not been registered yet.'));
          } else {
            const resVerifyPhone = await signinLightByPhoneNumber({
              phoneNumber: `${prefix}${phone}`,
              uuid: uuid,
              firebaseToken: ''
            })
            if (resVerifyPhone?.data) {
              const dataRedux = {
                tokenPMS: resVerifyPhone?.data?.tokenPMS,
                tokenUMS: resVerifyPhone?.data?.tokenUMS,
                username: resVerifyPhone?.data?.userName,
                userId: resVerifyPhone?.data?.userId,
                expiredTime: moment().add('hours', 22).toDate(),
                completedProfile: resVerifyPhone?.data?.completedProfile,
              }
              // const { data } = await fetchPersonProfileByIdApi();
              // console.log(data)
              dispatch(setAuth(dataRedux));
              setStorage(StorageKey.AuthDesign, { ...getStorage(StorageKey.AuthDesign), ...dataRedux });
              setIsFetching(false);
            } 
            setLoginErrMessage('');
            setShow(false)
            // onChangeStep({ ...values, email: '', prefix: prefix ? prefix : '' });
          }
          setIsFetching(false);
        } catch (e) {
          console.log(e)
          setLoginErrMessage(t('Error server'));
          setIsFetching(false);
        }
      })()
    }
  };

  return (
    <div>
      {
        typeVerify === TypeSinup.PhoneNumber && typePage === TypeSignInAndSignUp.SignUp &&
        <Box>
          <Box className="mb-1" fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>{t("{{context}} awaits. Create your account now", { context: textBrand || 'Levitate' })}!</Box>
          <p className="description mb-3">
            {t("No spam, just real talk. Give us your nickname and phone number to kick things off")}.
          </p>
        </Box>
      }
      {
        typeVerify === TypeSinup.PhoneNumber && typePage === TypeSignInAndSignUp.SignIn &&
        <Box>
          <Box className="mb-1" fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>{t("Welcome Back")}!</Box>
          <p className="description mb-3">
            {t("To {{context}}, the place where the world is at your fingertips", { context: textBrand || 'Levitate' })}.
          </p>
        </Box>
      }
      {
        typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber &&
        <Box textAlign="center">
          <Box className="mb-1" fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>{t("Change your phone")}!</Box>
          <p className="description mb-3">
            {t("Enter your new phone number. This phone number will be used to sign in for the next time.")}
          </p>
        </Box>
      }
      <Alert message={loginErrMessage} style={{ fontSize: '12px' }} severity="error" width="100%" />
      <Alert message={loginSuccessMessage} style={{ fontSize: '12px' }} severity="success" width="100%" />
      <Box marginTop={{ xs: '1rem' }} marginBottom={{ xs: '1rem' }}>
        <Grid container spacing={2}>
          {typeVerify === TypeSinup.PhoneNumber && typePage === TypeSignInAndSignUp.SignUp &&
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                placeholder={t('Nickname')}
                name="name"
                maxLength={20}
                control={control}
              />
            </Grid>
          }

          <Grid item xs={5}>
            <Autocomplete
              sx={{ width: 300 }}
              clearIcon={false}
              defaultValue={CountryPhoneCodeOptionsSortedByName?.find(item => item.value === (getSession(SessionKey.PhonePrefix) || "+84"))}
              options={CountryPhoneCodeOptionsSortedByName}
              autoHighlight
              onChange={(e, newValue) => setPrefix(newValue?.value ? newValue?.value : '')}
              getOptionLabel={(option) => option.text?.split(' ')[option.text?.split(' ').length - 1]}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                  }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.shortLocale.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.text?.split(' ')[option.text?.split(' ').length - 1]}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  name="prefix"
                  control={control}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {prefix && (
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${CountryPhoneCodeOptionsSortedByName?.find(item => item.value === prefix)?.shortLocale.toLowerCase()}.png`}
                            alt=""
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

          </Grid>
          <Grid item xs={7}>
            <TextField placeholder={t('Phone Number')} control={control} fullWidth required name="phoneNumber" />
          </Grid>
        </Grid>
      </Box>
      {
        typeVerify === TypeSinup.PhoneNumber &&
        <Stack marginBottom={{ xs: '1rem' }} direction={'row'} alignItems={'center'} gap={1}>
          <SwitchStyled
            onChange={() => onChangeTypeVerify(TypeSinup.email)} defaultChecked
          />
          <Box fontSize={{ xs: '12px', md: '16px' }}>{t('Sign in with phone instead')}</Box>
        </Stack>
      }
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Button color='accent' fullWidth loading={isFetching} onClick={handleSubmit(onSubmit)}>
            {typeVerify === TypeSinup.PhoneNumber && typePage === TypeSignInAndSignUp.SignUp && t('Join Now')}
            {typeVerify === TypeSinup.PhoneNumber && typePage === TypeSignInAndSignUp.SignIn && t('Login')}
            {typeVerify === TypeChangePhoneNumberOrEmail.changePhoneNumber && t('Save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const SwitchStyled = styled(Switch)`
  .MuiSwitch-switchBase {
    &.Mui-checked ~ .MuiSwitch-track {
      background-color: ${p => p.theme.palette.accent.main} !important;
    }
  }
`

export default SignupPhoneNumber;