import { useTheme } from '@mui/material';
import React from 'react';

function CompleteIcon(props: any) {
  const theme = useTheme();
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3089_33160)">
        <path
          d="M95.9996 50.0482C95.9373 50.7096 95.8683 51.371 95.8161 52.0341C95.4054 57.2412 94.1412 62.2379 92.0691 67.026C89.3489 73.3102 85.4554 78.7731 80.4005 83.3895C73.9282 89.3001 66.3871 93.1676 57.8107 94.9801C53.4459 95.9024 49.0256 96.207 44.5766 95.8637C34.6334 95.0979 25.7204 91.6596 17.9486 85.3906C9.69202 78.731 4.21121 70.2388 1.51457 59.9794C0.260514 55.2014 -0.200709 50.3326 0.0787183 45.3931C0.625789 35.7076 3.7685 26.9511 9.60449 19.2128C16.3579 10.2594 25.2137 4.36563 36.0608 1.49953C39.1076 0.695067 42.215 0.223835 45.3678 0.0824656C45.5277 0.0757337 45.6842 0.0286105 45.8425 0C47.28 0 48.7159 0 50.1534 0C50.7998 0.0622699 51.4445 0.134638 52.0909 0.185127C55.8396 0.479647 59.5091 1.19491 63.0609 2.42348C76.5676 7.0954 86.25 15.9714 92.0775 29.0127C94.1985 33.7604 95.4004 38.7605 95.8144 43.9474C95.8666 44.6105 95.9356 45.2719 95.9979 45.9333V50.0465L95.9996 50.0482ZM91.2005 48.0118C91.2089 24.1978 71.8477 4.81666 48.0324 4.79815C24.2205 4.77964 4.81046 24.1489 4.79699 47.9445C4.78353 71.7821 24.1583 91.18 47.9903 91.185C71.8006 91.1901 91.1921 71.8157 91.2005 48.0118Z"
          fill={theme.palette.accent.main}
        />
        <path
          d="M39.4834 68.2608C33.3141 62.091 27.1179 55.8926 20.957 49.7296C22.0512 48.6356 23.1655 47.5232 24.3001 46.3889C29.3146 51.4008 34.3864 56.4716 39.4665 61.5474C50.2413 50.7848 60.9757 40.0626 71.7067 29.3438C72.8463 30.5084 73.9472 31.636 75.0177 32.7316C63.2145 44.5326 51.354 56.3908 39.4834 68.2608Z"
          fill={theme.palette.accent.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_3089_33160">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompleteIcon;