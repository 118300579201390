import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

// REDUX
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';

// SENTRY
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// FIREBASE
import { getAuth } from 'firebase/auth';
import { AuthProvider, FirebaseAppProvider, useFirebaseApp } from 'reactfire';

// GOOGLE ANALYTICS
import ReactGA from 'react-ga';
import ReactGTM from 'react-gtm-module';

// I18N
import 'locales'; // initiate i18n inside locales/index.ts

// THEME
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DialogContainer from 'components/modals/DialogContainer';
import { ToastContainer } from 'react-toastify';

// AMCHARTS
import '@amcharts/amcharts4/charts';

// OTHERS
import env, { checkEnvAvailable } from 'constants/env';
import {
  getSessionIpAddressInfo,
  setSessionIpAddressInfo
} from 'services/locationService';
import * as serviceWorker from './serviceWorker';

// APP
import { getLocationIPApi } from 'apis/commonApi';
import { fetchProjectBrandConfig } from 'apis/projectApis';
import { StorageKey } from 'constants/storage';
import { TextContentBrand } from 'model/textContent';
import { setStorage } from 'services/storageService';
import App from './App';
import './index.css';
import customTheme from './theme/theme';

checkEnvAvailable();

// Initialize Firebase
const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  projectId: env.FIREBASE_PROJECT_ID,
  authDomain: `${env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
};

// Google Analytics
if (env.GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(env.GOOGLE_ANALYTICS_TRACKING_ID, {
    debug: env.ENV !== 'production',
    titleCase: false
  });
}

if (env.GOOGLE_TAG_MANAGER_ID) {
  ReactGTM.initialize({ gtmId: env.GOOGLE_TAG_MANAGER_ID });
}

const ipLocationIpAddress = getSessionIpAddressInfo();
if (isEmpty(ipLocationIpAddress)) {
  getLocationIPApi().then(r => {
    setSessionIpAddressInfo(r);
  }).catch((err: any) => {
    console.log(err);
  })
}

// Initialize Sentry for Bugs Tracking
if (env.SENTRY_DSN) {
  Sentry.init({
    // {PROTOCOL}://{PUBLIC_KEY}:{SECRET_KEY}@{HOST}{PATH}/{PROJECT_ID}
    dsn: env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

// Firebase
const FirebaseAuthProvider = ({ children }: any) => {
  const firebaseApp = useFirebaseApp();
  const firebaseAuth = getAuth(firebaseApp);
  return (
    <AuthProvider sdk={firebaseAuth}>
      {children}
    </AuthProvider>
  );
}

export type WhiteLabelColor = {
  primaryColor?: string;
  mainColor?: string;
}

function MyApp() {
  const [themeData, setThemeData] = useState(null);
  const [textContentBrand, setTextContentBrand] = useState(new TextContentBrand({}));

  useEffect(() => {
    (async () => {
      const { data } = await fetchProjectBrandConfig();
      if (!isEmpty(data)) {
        setThemeData(data);
        setStorage(StorageKey.LogoCompany, data?.logoPhotoUrl || '');

        // Set Text Content
        setTextContentBrand(new TextContentBrand(data));
      }
    })();
  }, []);

  if (!themeData) {
    return <div>Loading...</div>;
  }

  const theme = customTheme(themeData);

  return <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseAuthProvider>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App textContentBrand={textContentBrand} />
              <ToastContainer
                position="top-right"
                style={{ top: '12.5%' }}
                autoClose={3000}
                newestOnTop
                hideProgressBar
                closeOnClick
                draggable
                pauseOnHover
              />
              <DialogContainer />
            </LocalizationProvider>
          </ReduxProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </FirebaseAuthProvider>
  </FirebaseAppProvider>
}

ReactDOM.render(
  <MyApp />,
  document.getElementById('root')
);

serviceWorker.unregister()
