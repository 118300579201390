export const TERM_MS = (textBrand: string) => {
  return [
    {
      title: 'Penerimaan Terma',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Dengan mengakses, menggunakan <a href="https://levitate.homes">https://levitate.homes</a> (laman web ini), anda membuat perjanjian yang sah dan mengikat mengenai terma dan syarat yang terkandung di sini ("Terma Penggunaan") dengan kami, ${textBrand} International Pte. Ltd. ("kami", "kita", "kami", atau "${textBrand}"), pemilik dan pentadbir laman web ini.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Anda menerima tanpa batasan atau syarat Terma Penggunaan, termasuk Dasar Privasi.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Kami boleh mengubah Terma Penggunaan ini pada bila-bila masa dengan memaparkan versi yang telah diubah di laman web ini. Walau bagaimanapun, pemberitahuan secara individu tidak akan dihantar kepada anda. Mana-mana Terma Penggunaan yang telah diubah akan menggantikan semua versi sebelumnya. Jika anda tidak bersetuju dengan pindaan atau perubahan pada Terma Penggunaan, anda harus segera menghentikan penggunaan mana-mana perkhidmatan di laman web ini. Selepas mana-mana pindaan tersebut berkuat kuasa, kami akan menganggap penggunaan terus laman web ini sebagai penerimaan dan persetujuan anda terhadap pindaan tersebut. Anda boleh mengkaji versi terkini Terma Penggunaan pada bila-bila masa.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Dari semasa ke semasa, kami akan memaparkan garis panduan dan peraturan berkaitan dengan penggunaan laman web ini. Semua garis panduan atau peraturan tersebut dianggap dimasukkan dengan rujukan ke dalam Terma Penggunaan.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Penggunaan Laman Web',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>Anda bersetuju untuk mematuhi semua terma dan syarat di bawah Terma Penggunaan ini dalam menggunakan Laman Web. Kami boleh mengeluarkan atau menghapuskan sebarang kandungan yang anda berikan di Laman Web sekiranya kami, atas budi bicara mutlak kami, mengesyaki bahawa anda telah melanggar syarat-syarat di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  Dalam menjalankan sebarang urus niaga dengan kami di Laman Web atau berkaitan dengan Laman Web, atau mendaftar akaun dengan kami di Laman Web, anda menjamin bahawa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Anda dibenarkan secara undang-undang untuk menjalankan urus niaga sendiri;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Anda menggunakan identiti sebenar anda;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Data peribadi yang anda berikan kepada kami adalah benar, tepat, lengkap, dan terkini; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Anda akan mengekalkan dan dengan segera mengemas kini butiran peribadi anda dan memastikan bahawa maklumat tersebut adalah benar, tepat, lengkap, dan terkini.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>Pengguna di bawah umur (pengguna di bawah umur yang diatur di bawah undang-undang negara masing-masing) tidak layak untuk menggunakan Laman Web atau memberikan sebarang data peribadi di Laman Web tanpa pengawasan. Jika anda di bawah umur, anda boleh menggunakan Laman Web dan/atau memberikan sebarang data peribadi di Laman Web hanya selepas anda mendapatkan persetujuan dari ibu bapa/penjaga undang-undang atau jika anda di bawah pengawasan ibu bapa/penjaga undang-undang anda.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>Jika kami telah memberikan anda (atau jika anda telah memilih) kata laluan yang membolehkan anda mengakses bahagian-bahagian tertentu Laman Web, anda mengakui bahawa anda sepenuhnya bertanggungjawab untuk mengekalkan kerahsiaan kata laluan anda, dan segala aktiviti yang berlaku di bawah akaun anda. Kami tidak mempunyai tanggungjawab untuk mengesahkan sama ada pengguna yang log masuk dan menggunakan Laman Web adalah anda atau bukan.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>Anda bersetuju untuk memberitahu kami jika terdapat sebarang pelanggaran keselamatan terhadap kata laluan anda.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>Kami tidak akan bertanggungjawab atas sebarang kerugian atau kerosakan yang timbul daripada kegagalan anda mematuhi Fasal 2 ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.7.</div>
                <div>Anda tidak dibenarkan untuk membingkai atau menggunakan teknik pembingkaian untuk menyelitkan mana-mana cap dagang, logo, atau maklumat milik kami dan afiliasi kami (termasuk syarikat induk, "Afiliasi") tanpa persetujuan bertulis daripada kami. Penggunaan tanpa kebenaran akan mengakhiri izin atau persetujuan yang diberikan oleh kami.</div>
              </div>
            
          </div>
        `
    },
    {
      title: 'Bahan Laman Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Dengan mengakses, menggunakan Laman Web, anda mungkin terdedah kepada maklumat, data, teks, perisian, gambar, grafik, video, mesej, konsep, idea, reka bentuk, lukisan, senarai produk, harga, atau bahan lain yang kami dan/atau pengguna lain Laman Web mungkin muat naik, pos, emel, hantar, atau buat tersedia di Laman Web ("Bahan Laman Web"), sama ada secara awam atau peribadi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Anda faham bahawa Bahan Laman Web mungkin menyinggung, tidak senonoh, atau tidak patut.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Anda faham bahawa Bahan Laman Web hanya boleh digunakan untuk kegunaan peribadi dan bukan untuk penerbitan, pengedaran, penghantaran semula, pengagihan semula, siaran, pembiakan, atau pengedaran kepada orang lain dalam syarikat atau organisasi yang sama, dan tidak untuk diposkan ke laman web atau forum lain, kumpulan berita, senarai mel, papan buletin elektronik, atau perbualan relay internet yang dioperasikan oleh laman web lain.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Anda bersetuju untuk tidak menyalin, mendua, meniru, menjual, menjual semula, paparkan, atau menyediakan Bahan Laman Web di laman web atau pelayan lain tanpa mendapatkan kebenaran bertulis dari kami.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Kami, dalam keadaan apa pun, tidak bertanggungjawab atas sebarang kesilapan atau kelalaian dalam Bahan Laman Web, atau atas sebarang kerugian atau kerosakan dari mana-mana jenis yang berlaku akibat penggunaan Bahan Laman Web yang diposkan, diemel, dihantar, atau lain-lain yang dibuat tersedia di Laman Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>Anda mengakui dan bersetuju bahawa format dan kandungan Laman Web boleh diubah pada bila-bila masa tanpa notis; operasi Laman Web boleh ditangguhkan atau dihentikan untuk kerja sokongan atau penyelenggaraan, untuk mengemaskini kandungannya atau atas apa-apa sebab lain; dan akses ke Laman Web boleh dihentikan sama ada berkaitan dengan pengguna tertentu atau secara umum pada bila-bila masa dan tanpa notis.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Ketepatan Maklumat di Laman Web Kami',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Walaupun ${textBrand} menggunakan usaha yang munasabah untuk menyediakan maklumat yang tepat di laman web ini, kami tidak memberikan jaminan atau representasi mengenai ketepatan maklumat apa pun yang diberikan, sama ada tersirat atau sebaliknya, selagi yang dibenarkan oleh undang-undang. ${textBrand} menolak segala liabiliti terhadap sebarang kesilapan atau kelalaian dalam kandungan laman web ini sejauh yang dibenarkan oleh undang-undang.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Keterangan yang terdapat di laman web ini hanya untuk tujuan panduan. Ketepatan sebarang keterangan, huraian, saiz, rujukan kepada keadaan, kebenaran untuk kegunaan dan pemilikan, dan butiran lain yang terdapat di laman web ini tidak dijamin dan hanya untuk panduan am sahaja. Anda dinasihatkan supaya tidak bergantung pada keterangan tersebut sebagai penyataan fakta atau representasi dan harus memastikan kebenarannya sendiri.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand} menasihati bahawa kerana pembelian harta tanah adalah komitmen kewangan besar, anda perlu mengambil langkah-langkah munasabah untuk memastikan bahawa apa-apa harta yang anda cadangkan untuk dibeli adalah sesuai dengan keperluan anda dari segala sudut. Kami telah mengambil langkah-langkah munasabah untuk memastikan bahawa bahan-bahan yang terdapat dalam laman web ini adalah tepat, tetapi ini tidak boleh dianggap sebagai pengganti pertanyaan atau pertimbangan anda. Anda dinasihati untuk mengambil langkah-langkah yang sesuai untuk mengesahkan apa-apa maklumat yang ingin anda bergantung.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Walaupun telah berusaha untuk menyediakan gambaran yang tepat mengenai warna dan reka bentuk setiap produk di laman web ini, warna sebenar dan reka bentuk mungkin sedikit berbeza, disebabkan oleh tetapan skrin peranti yang berbeza, pencahayaan di lokasi pemasangan, perbezaan kecil dalam hasil produk dari masa ke masa dan faktor lain. ${textBrand} tidak akan bertanggungjawab atas sebarang perbezaan warna atau reka bentuk yang bukan disebabkan oleh kesalahan kami. Apabila anda membeli harta atau produk menggunakan laman web ini, anda bersetuju untuk menerima risiko kecil bahawa terdapat perbezaan kecil antara warna dan reka bentuk sebenar dengan representasi di laman web ini.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Tanggungjawab Pengguna',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Selain daripada obligasi yang ditentukan dalam Terma Penggunaan ini, anda TIDAK BOLEH:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Menggunakan Laman Web ini dengan melanggar undang-undang atau peraturan yang berkenaan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Berperilaku dengan cara yang membahayakan, menyalahgunakan, mengganggu, menghantui, mencemarkan nama baik, menfitnah, mengancam, atau menyakiti hati orang lain, atau menggunakan Laman Web ini untuk tujuan tersebut;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Menyebabkan atau boleh menyebabkan kesulitan, kerugian, atau kerosakan kepada pengguna, pihak ketiga, atau ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Melakukan tindakan memohon atau memegang beberapa pendaftaran keahlian secara penipuan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Melibatkan diri dalam aktiviti amoral atau potensi amoral, termasuk tetapi tidak terhad pada pelacuran dan pornografi;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Menyamar sebagai mana-mana individu atau entiti, atau memberi keterangan palsu atau salah mengenai persaudaraan anda dengan individu atau entiti;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Menyediakan maklumat palsu, tidak tepat, atau menyesatkan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Melakukan aktiviti dan/atau urus niaga yang mencurigakan, penipuan, atau tidak sah menurut budi bicara mutlak kami;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Menghantar emel yang tidak diminta kepada mana-mana pengguna atau menggunakan perkhidmatan di Laman Web ini untuk mengutip bayaran bagi menghantar, atau membantu dalam menghantar emel yang tidak diminta kepada pihak ketiga;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Mendedahkan atau mengedarkan maklumat peribadi pengguna lain kepada pihak ketiga, atau menggunakan maklumat tersebut untuk tujuan pemasaran tanpa kebenaran jelas daripada pengguna tersebut;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Mengambil tindakan yang memberi beban tidak munasabah atau terlalu besar pada infrastruktur Laman Web;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Mengunggah, menyebabkan diunggah, atau menyediakan kandungan apa pun ke Laman Web yang mengandungi virus, atau barang lain yang boleh merosakkan, mengganggu, atau merugikan atau menghalang akses ke Laman Web;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Melakukan atau menyebabkan pengguna lain melakukan spam, phishing, aktiviti tidak wajar, berbahaya, atau menipu atau, menurut budi bicara mutlak kami, klik, impresi, atau aktiviti pemasaran palsu yang berkaitan dengan ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>Menyulitkan atau mencemarkan reputasi ${textBrand} menurut budi bicara mutlak kami;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Menyunting, menyesuaikan, menyusun semula, merekompilasi, menghantar semula, menerbitkan, memberikan lesen, meretas balik, membongkar, menyusun semula, menjiplak atau membuat karya turunan daripada, mengalih milikkan atau menjual mana-mana perkhidmatan di Laman Web atau sebahagian daripadanya, termasuk kod sumber atau algoritma, kecuali jika dengan izin bertulis daripada kami, atau jika dibenarkan oleh undang-undang;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Mengubah, mengeluarkan, menutup atau sebaliknya mencemar identifikasi, tanda dagangan, hak cipta, atau notis lain dari mana-mana aspek perkhidmatan di Laman Web;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Menggunakan perisian automatikasi, hack, mod, atau perisian pihak ketiga yang tidak sah yang direka untuk mendapatkan akses, merayap, atau mengumpul daripada perkhidmatan ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Menggunakan perisian pihak ketiga yang tidak sah yang menangkap, "menambang" atau mengumpul maklumat dari atau melalui ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Mengunggah, menghantar, menghantar pos, atau menyediakan kandungan apa pun yang:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Dianggap bersifat menghina, diskriminasi, memfitnah, cabul, mengancam, mencemarkan, tidak senonoh, atau sebaliknya melanggar undang-undang, termasuk kandungan yang kemungkinan akan menghina, menghina, atau menghina orang lain berdasarkan ras, agama, etnik, jantina, umur, orientasi seksual, atau kecacatan fizikal atau mental;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>Tidak merupakan hasil karya asli anda, atau mungkin melanggar hak milik intelektual atau hak lain milik orang lain;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Mengandungi imej atau maklumat peribadi orang lain kecuali jika anda mempunyai kebenaran mereka; atau</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>Mengandungi kandungan yang banyak dan tidak diarahkan, tidak diingini, atau berulang-ulang; atau</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Mengganggu, mengganggu, atau menimbulkan beban yang tidak semestinya pada infrastruktur Laman Web atau perniagaan kami.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Jika anda percaya bahawa seorang pengguna telah melanggar mana-mana syarat di atas, sila hubungi kami.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Kami berhak untuk menghalang, menggantung, atau membatalkan sebarang pengguna atau pendaftaran keahlian Laman Web kami dan mengubah atau mengeluarkan apa-apa bahan yang dimuat naik, dihantar, dihantar pos, atau sebaliknya disediakan di Laman Web oleh mana-mana pengguna, tanpa notis; menentukan dan mengehadkan, menolak, dan/atau menolak pulangan daripada pelanggan pada bila-bila masa disebabkan oleh sejarah pulangan yang tidak teratur atau terlalu banyak. Dengan memuat naik, menghantar, menghantar pos, atau sebaliknya menyediakan bahan apa pun melalui Laman Web ini, anda memberikan ${textBrand} lesen bukan eksklusif, global, bebas royalti, berterusan untuk menggunakan, menghasilkan semula, mengedit, dan mengambil keuntungan daripada bahan tersebut dalam apa jua bentuk dan untuk apa jua tujuan. Permohonan keahlian mungkin tidak diluluskan jika pemohon pernah membatalkan keahliannya di masa lalu.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Kami tidak bertanggungjawab dan tidak menerima sebarang liabiliti terhadap apa-apa bahan yang dimuat naik, dihantar, dihantar pos, atau sebaliknya disediakan di Laman Web oleh mana-mana individu selain daripada kami. Kami tidak menyokong apa-apa pendapat, nasihat atau kenyataan yang dibuat oleh mana-mana individu selain ${textBrand}.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Anda bersetuju untuk menggantirugi ${textBrand} dan setiap pegawai, pekerja, ejen, kontraktor, pembekal, dan lesen (secara kolektif "Affiliates") ${textBrand} berhubung dengan apa-apa liabiliti, kerugian atau kerosakan (termasuk semua kos perundangan dan lain-lain kos pada bayaran penuh) yang dialami atau ditanggung oleh mereka berikutan (secara keseluruhan atau sebahagiannya) dari pelanggaran atau kegagalan mematuhi apa-apa daripada Terma Penggunaan ini, atau apa-apa perilaku salah atau tidak sah yang berkaitan dengan perkara yang menjadi tumpuan Terma Penggunaan ini, di pihak anda atau mana-mana rakan kongsi anda.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Penafian Waranti',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Penggunaan Laman Web ini adalah atas risiko anda sendiri. Kami menafikan semua waranti apa jua jenisnya, sama ada yang nyata atau tersirat.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Kami berhak pada bila-bila masa dan dari semasa ke semasa untuk mengubah dan menghentikan, secara sementara atau kekal, sebarang perkhidmatan Laman Web (atau sebahagian daripadanya) dengan atau tanpa notis. Anda bersetuju bahawa kami tidak akan bertanggungjawab kepada anda atau pihak ketiga mana-mana perubahan, penangguhan, atau penghentian perkhidmatan tersebut.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Kami tidak memberikan sebarang jaminan bahawa:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Laman Web ini akan memenuhi keperluan anda;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Laman Web ini akan berfungsi tanpa gangguan, tepat pada masanya, selamat, dan bebas daripada ralat;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Sebarang hasil yang mungkin diperoleh daripada penggunaan Laman Web ini akan tepat atau boleh dipercayai; dan</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Kualiti produk, perkhidmatan, maklumat, atau bahan lain yang dibeli atau diperolehi oleh anda melalui Laman Web ini akan memenuhi jangkaan anda.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Kami tidak boleh dan tidak mengesahkan, dan tidak boleh bertanggungjawab terhadap, mesej, pandangan, pendapat, penyelidikan, dan cadangan individu, pengguna, dan organisasi yang dianggap menarik, atau menyokong sebarang patronaj oleh mana-mana pembekal perkhidmatan tertentu, kaedah rawatan atau urus niaga dalam sekuriti, pelaburan, atau kelas sekuriti atau pelaburan, dan tidak juga memperlihatkan sebarang bentuk pengesahan daripada kami terhadap pautan ke laman web atau sumber lain.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Kami tidak aktif menyemak atau mengedit mesej, pandangan, pendapat, penyelidikan, dan cadangan pengguna, walaupun mesej, pandangan, pendapat, penyelidikan, dan cadangan tersebut dibuat oleh pengguna yang menggunakan Laman Web ini. Walaupun maklumat di Laman Web ini diperolehi dari sumber yang dipercayai, tiada data, berita, maklumat, laporan, pandangan, atau cadangan mana-mana bentuk yang telah diubahsuai khas untuk mana-mana individu tertentu atau kelas individu.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Dengan menyediakan maklumat dan data di Laman Web ini, termasuk fakta, pandangan, pendapat, dan cadangan individu dan organisasi yang dianggap menarik, kami dan pembekal kandungan kami tidak memberikan atau memperlihatkan nasihat kewangan, pelaburan, cukai, dan undang-undang. Anda harus sentiasa mencari nasihat profesional yang relevan sebelum membuat keputusan seumpama itu.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>Kami mungkin memantau atau menyemak kawasan apa pun di Laman Web di mana anda menghantar atau memaparkan komunikasi untuk mempromosikan kecekapan, kegunaan, dan kesesuaian penggunaannya. Walau bagaimanapun, kami tidak akan menjamin atau menyemak setiap pos atau komunikasi, dan kami menafikan sebarang liabiliti yang berkaitan dengan semua Bahan Laman Web, sama ada atau tidak timbul di bawah undang-undang hak cipta, tanda dagangan, fitnah, privasi, kecabulan, atau sebaliknya.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Laman Web ini dan semua maklumat yang terkandung di dalamnya disediakan oleh ${textBrand} atas asas "sebagaimana adanya" dan "sebagaimana tersedia". ${textBrand} dan/atau sekutu, pemegang saham, rakan kongsi, pegawai, pengarah, pekerja, dan ejen masing-masing:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Tidak membuat apa-apa perwakilan atau jaminan apa jua jenis, nyata atau tersirat, berkenaan dengan operasi dan ketersediaan Laman Web ini atau maklumat, kandungan, bahan, atau produk yang disajikan di Laman Web ini. Anda dengan ini bersetuju bahawa penggunaan Laman Web ini adalah atas risiko anda sendiri sepenuhnya selaras dengan undang-undang yang berkenaan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Menafikan semua jaminan, nyata atau tersirat, termasuk, tetapi tidak terhad kepada, jaminan tersirat tentang kesesuaian untuk tujuan tertentu;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Tidak menjamin bahawa Laman Web ini, pelayan-pelayannya, atau e-mel yang dihantar daripada kami adalah bebas daripada virus atau komponen atau ralat yang merosakkan atau lain-lain; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Tidak akan bertanggungjawab atas sebarang kerosakan apa jua jenis termasuk, tetapi tidak terhad kepada, kerosakan langsung, tidak langsung, kebetulan, punitif, dan akibat atau apa jua kerugian atau liabiliti lain yang timbul daripada (a) penggunaan atau ketidakupayaan untuk menggunakan Laman Web ini; (b) sebarang keputusan yang dibuat, tindakan atau kelalaian yang diambil oleh mana-mana pihak bergantung pada kandungan Laman Web ini; (c) gangguan perniagaan; (d) kelewatan/gangguan dalam akses kepada Laman Web ini; (e) ketidak-hantaran data, kesilapan hantaran, kerosakan, pemusnahan, atau pengubahan data lain; (f) kehilangan atau kerosakan apa jua jenis yang ditanggung akibat daripada mengakses pautan pihak ketiga di Laman Web ini; (g) virus komputer, kegagalan atau gangguan sistem berkaitan dengan penggunaan Laman Web ini; atau (h) sebarang kelewatan, ketidak tepatan, ralat dalam atau pengabaian dalam kandungan Laman Web ini.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Dengan mengakses Laman Web kami, anda menganggap semua risiko yang berkaitan dengan penggunaannya, termasuk tetapi tidak terhad kepada risiko bahawa komputer, peranti, perisian, atau data anda mungkin rosak oleh sebarang virus yang dipindahkan oleh Laman Web ini atau kandungan pihak ketiga atau laman web pihak ketiga, serta risiko berkaitan dengan kualiti perkhidmatan yang disediakan oleh pembekal perkhidmatan (jika ada). Sejauh yang dibenarkan oleh undang-undang, semua waranti, syarat, dan tuntutan (samada nyata atau tersirat) yang timbul daripada atau berkaitan dengan Laman Web ini dikecualikan di sini.
              </div>
          </div>
        `
    },
    {
      title: 'Gantirugi',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Anda bersetuju untuk memberi gantirugi dan membebaskan ${textBrand}, dan pihak berkaitan kami, pemegang saham, rakan kongsi, pegawai, pengarah, pekerja, dan ejen kami daripada sebarang tuntutan atau tuntutan, termasuk yuran peguam, yang dibuat oleh pihak ketiga disebabkan oleh atau timbul daripada sebarang kandungan yang anda serahkan, pos, hantar atau buat tersedia di Laman Web, penggunaan Laman Web oleh anda, sambungan anda ke Laman Web, pelanggaran anda terhadap Terma Penggunaan, atau pelanggaran anda terhadap hak-hak pihak lain.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Pembatasan Liabiliti',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Kami tidak akan bertanggungjawab terhadap anda atas sebarang ganti rugi secara langsung, tidak langsung, kebetulan, khas, akibat, atau contoh, termasuk tetapi tidak terhad kepada ganti rugi atas kerugian keuntungan, reputasi baik, penggunaan, data, atau kerugian tidak material lain (walaupun kami telah diberitahu mengenai kemungkinan ganti rugi tersebut), yang berbangkit daripada:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Penggunaan atau ketidakupayaan untuk menggunakan Laman Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Kos untuk memperoleh produk dan perkhidmatan ganti rugi sebagai akibat daripada produk, data, maklumat, atau perkhidmatan yang dibeli atau diperoleh atau mesej yang diterima, atau urus niaga yang dilakukan melalui atau dari Laman Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Akses tanpa kebenaran atau pengubahan transmisi atau data anda;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Pernyataan atau tindakan pihak ketiga di Laman Web; dan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Sebarang perkara lain yang berkaitan dengan Laman Web.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Sebarang tuntutan oleh mana-mana pihak terhadap pihak lain dan sebarang tindakan terhadap pihak lain mesti dimulakan dalam masa 03 bulan (atau tempoh minimum yang dibenarkan menurut undang-undang yang berkenaan) selepas tarikh berlakunya sebab tindakan, selepas itu pihak yang teraniaya tidak akan mempunyai sebarang tuntutan terhadap pihak lain.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Pautan dan Kandungan Pihak Ketiga',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>Laman Web ini mungkin mempunyai pautan hiperteks ke laman web yang dikendalikan oleh pihak ketiga semata-mata untuk tujuan rujukan dan maklumat. ${textBrand} tidak menyemak dan tidak bertanggungjawab terhadap polisi privasi dan amalan laman web lain walaupun anda mengaksesnya melalui pautan dari Laman Web ini, dan kami mengesyorkan agar anda menyemak terma dan syarat serta polisi privasi setiap laman web yang anda layari dan menghubungi pemilik atau pengendali laman tersebut jika anda mempunyai sebarang kebimbangan atau soalan. Begitu juga, jika anda memautkan ke Laman Web ini dari laman web pihak ketiga, ${textBrand} tidak bertanggungjawab terhadap polisi privasi dan amalan pemilik atau pengendali laman web pihak ketiga tersebut dan kami mengesyorkan agar anda menyemak polisi laman pihak ketiga tersebut serta menghubungi pemilik atau pengendali laman tersebut jika anda mempunyai sebarang kebimbangan atau soalan. ${textBrand} tidak memberikan jaminan atau representasi berkenaan dengan sebarang laman web pihak ketiga yang dihubungkan. ${textBrand} mengecualikan segala liabiliti bagi sebarang kerugian atau kerosakan sepenuhnya sejauh yang dibenarkan oleh undang-undang yang berlaku yang timbul berkenaan dengan sebarang kandungan pada laman web tersebut.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Untuk mengelakkan keraguan, kewujudan pautan ke laman web pihak ketiga tidak akan dianggap sebagai syor terhadap laman web tersebut atau sebarang nasihat atau maklumat mengenainya oleh ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Dasar Privasi',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Semua data peribadi tentang anda tertakluk kepada Dasar Privasi kami.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Dasar Privasi dianggap termasuk dalam Terma Penggunaan melalui rujukan kepada Klausa ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Jika mana-mana pengguna Laman Web ini, atau mana-mana orang lain, melihat maklumat dokumen yang diterbitkan oleh ${textBrand} dan memberikan maklum balas berupa sebarang maklumat atau maklum balas seperti soalan, komen, cadangan, atau seumpamanya, maklumat tersebut dianggap sebagai tidak sulit dan ${textBrand} berhak untuk menggunakan, menghasilkan semula, mendedahkan, dan mengedarkan maklumat tersebut kepada mana-mana orang lain.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Selanjutnya, ${textBrand} berhak untuk menggunakan atau mengexploitasi sebarang idea, konsep, kepakaran, atau teknik yang terkandung dalam maklumat/maklum balas tersebut untuk sebarang tujuan tanpa sebarang bayaran termasuk, tetapi tidak terhad kepada, membangun, menghasilkan, dan memasarkan produk yang mengandungi maklumat tersebut.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Hak Milik Intelek dan Hak Cipta',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Anda mengakui dan menerima bahawa semua hak cipta, hak pangkalan data dan hak milik intelek lain yang wujud dalam reka bentuk, susunan, proses, fungsi, data, dan kandungan Laman Web adalah hak milik ${textBrand}, pembekal maklumat kami, atau pemberi lesen kami. Laman Web ini dimiliki dan dikendalikan oleh ${textBrand}. Tiada sebahagian daripada Laman Web, kandungannya, atau sebarang perisian asas dan pangkalan data yang terdapat di dalamnya boleh disalin, direproduksi, dikongsi dengan pihak ketiga, diubahsuai semula, dirangka semula, atau digunakan untuk mencipta sebarang hasil turunan (sama ada dalam bentuk fizikal atau elektronik) tanpa persetujuan bertulis terdahulu dari ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Keadaan Kahar',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Kami tidak akan bertanggungjawab kepada anda atas sebarang kelewatan atau kegagalan dalam melaksanakan obligasi kami kepada anda atas sebab-sebab di luar kawalan kami, termasuk tanpa batasan, tindakan Allah, perang atau keganasan, wabak, pandemik, bencana semula jadi, masalah sambungan internet, masalah sistem, perubahan dalam undang-undang, peraturan atau dasar kerajaan, dan kekurangan bekalan dan perkhidmatan.
              </div>
          </div>
        `
    },
    {
      title: 'Pengakhiran',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Kami boleh, dalam keadaan tertentu dan tanpa notis terlebih dahulu, atas budi bicara kami, mengakhiri akaun anda (jika ada). Sebab-sebab untuk pengakhiran seperti berikut dan tidak terhad kepada:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Pelanggaran terhadap Terma Penggunaan, atau dokumen, panduan atau peraturan yang dimasukkan;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Pemintaan oleh pihak berkuasa penguatkuasaan undang-undang atau agensi kerajaan lain;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Pemadaman akaun atas inisiatif sendiri;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Pencerobohan terhadap hak milik intelektual orang lain;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Penghentian atau pengubahsuaian material terhadap perkhidmatan dalam Laman Web, atau sebahagiannya;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Isu atau masalah teknikal atau keselamatan yang tidak dijangka; atau</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Jangka masa tidak aktif yang berpanjangan.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Pengakhiran akaun anda akan mengakibatkan:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Penghapusan akses dan penghalangan penggunaan seterusnya kepada semua penawaran Laman Web yang berkaitan dengan akaun tersebut; dan</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Pemadaman kata laluan anda dan semua maklumat, fail dan kandungan yang berkaitan dengan atau terdapat dalam akaun anda (atau sebahagiannya).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Pengakhiran akaun anda tidak akan menjejaskan apa-apa hak atau pemuliharaan lain yang kami berhak memperoleh di bawah Terma Penggunaan, di sisi undang-undang atau dalam keadilan, dan tidak akan menjejaskan apa-apa hak atau liabiliti yang telah berbangkit atau berkuatkuasa atau sambungan terus dalam kuasa mana-mana peruntukan yang dengan nyata atau secara tersirat dimaksudkan untuk berkuatkuasa pada atau selepas pengakhiran tersebut.</div>
              </div
          </div>
        `
    },
    {
      title: 'Hak Milik Intelektual',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Setelah Anda memuat naik sebarang imej, gambar, dan/atau keterangan senarai ke Laman Web (berikut dengan "<strong>Kandungan</strong>"), anda memberi kami lesen global, bebas royalti, tidak boleh dibatalkan untuk menggunakan, menyimpan, memuat naik, mencetak, mengubah suai, menyesuaikan, meletak tanda air, berkomunikasi, menerbitkan, melakukan secara umum, memaparkan secara umum, membuat karya turunan, dan mengedarkan Kandungan tersebut. Dengan memuat naik Kandungan, anda menjamin bahawa anda memiliki hak cipta Kandungan tersebut dan mempunyai hak yang diperlukan untuk menglesenkannya kepada kami seperti yang dinyatakan. Kami berhak meletak tanda air pada gambar dalam Kandungan dan hak cipta dalam gambar akhir yang telah diberi tanda air tersebut sepenuhnya menjadi hak kami. Semua nama, produk, dan tanda yang disebutkan adalah hak milik intelektual pemiliknya masing-masing. Tiada bahan yang disediakan melalui Laman Web, termasuk teks, grafik, kod, penarafan, ranking, pangkalan data, maklumat yang digabungkan, Bahan Laman Web dan/atau perisian boleh disalin semula, diubahsuai, disesuaikan, diedarkan, diterbitkan, dipaparkan, dimuat naik, dipos, ditransmisikan, atau diberi pautan hiper dalam apa-apa cara dan bentuk tanpa kelulusan bertulis kami terlebih dahulu, dan kelulusan pemegang hak cipta dan tanda dagangan yang bersangkutan. Laman Web dan Bahan Laman Web adalah untuk kegunaan peribadi sahaja, dan anda tidak boleh terlibat dalam sebarang urusan dengan pihak lain berkenaan perkhidmatan dan kandungan sedemikian. Urusan sedemikian termasuk urusan komersial dan urusan yang akan menjejaskan nilai komersial Laman Web dan Bahan Laman Web.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Umum',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Syarat Penggunaan akan diatur oleh dan ditafsirkan mengikut undang-undang Republik Singapura, dan sebarang tuntutan atau pertikaian apa pun akan tunduk kepada bidang kuasa eksklusif mahkamah-mahkamah Republik Singapura.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Kegagalan kami untuk melaksanakan atau menegakkan mana-mana hak atau peruntukan dalam Syarat Penggunaan tidak akan dianggap sebagai pengabaian terhadap hak atau peruntukan tersebut. Sekiranya mana-mana peruntukan dalam Syarat Penggunaan dijumpai oleh mahkamah yang berkompeten sebagai tidak sah, pihak-pihak yang terlibat tetap bersetuju bahawa mahkamah harus berusaha untuk memberi kesan kepada niat pihak-pihak seperti yang terkandung dalam peruntukan tersebut, dan peruntukan-peruntukan lain dalam Syarat Penggunaan ini akan terus berkuat kuasa sepenuhnya.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Akaun anda dengan kami tidak boleh dipindahkan dan apa-apa hak kepada akaun tersebut akan berakhir dengan kematian anda. Selepas menerima salinan sijil kematian anda, akaun anda akan ditamatkan dan semua kandungan yang anda hasilkan dalam akaun anda akan dipadamkan secara kekal.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Semua pemberitahuan kepada kami mengenai Syarat Penggunaan hendaklah dihantar melalui e-mel kepada: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
