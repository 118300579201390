export const POLICY_EN = (textBrand: string) => {
  return [
    {
      title: 'Introduction',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>we</strong>", "<strong>us</strong>", “<strong>our</strong>” or “<strong>${textBrand}</strong>”), is the operator of <a href="https://levitate.homes">https://levitate.homes</a> (the "<strong>Website</strong>"), and we are committed to safeguarding your Personal Data (as defined in Clause 2 below) in accordance with the terms and conditions herein.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>This Privacy Policy (together with our Terms of Use and any other documents referred to in it) sets out how Personal Data that we collect from you, or that you provide to us, will be used, processed, or disclosed by us, including how certain information would be used to personalize advertising directed at you. This Privacy Policy also sets out the types of Personal Data we collect, how we keep it secured, as well as how you can access or alter your Personal Data stored by us. This Privacy Policy covers your use of the Website, and any other way you may connect to or interact with us through the use of our products and services (hereinafter collectively referred to as the "<strong>Services</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Please read the following carefully to understand our policy and practices regarding your Personal Data and how we will treat it.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>Unless restricted by applicable law, by using the Website and the Services, you agree that any and all Personal Data relating to you collected by us or provided by you from time to time may be used, processed, and disclosed for such purposes and to such persons as set out in this Privacy Policy.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>As part of our efforts to ensure that we properly manage, protect, and process your Personal Data, we will be reviewing our policies, procedures, and processes from time to time. We may amend this Privacy Policy at any time by posting a revised version on the Website. We may endeavor to send notice when the policy is amended, nevertheless, you will be deemed to have acknowledged and agreed to the amended Privacy Policy if you continue to use the Website or the Services after the changes have taken effect regardless of whether you receive the notice or not. You are reminded to check this page from time to time for updates to this Privacy Policy.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      title: 'Collection of Personal Data',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                You can choose to browse the Website and use its limited functions without disclosing your Personal Data. You are not required to provide Personal Data as a condition of using the Website, except as may be necessary for us to be able to provide the Services which you purchase or access through our Website, respond to your requests, or in cases where you contact us directly.
              </div>
              <div>
                When you use our Services, we collect a variety of information from and about you, your devices, and your interaction with the Services. Some of this information identifies you directly or can be used to loosely identify you when combined with other data. Any data, whether true or not, about an individual who can be identified from that data; or from that data and other information to which the organization has or is likely to have, is considered personal data ("<strong>Personal Data</strong>").
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.1.</div>
                  <div>
                    <strong>Information you willingly provide</strong>. When using our Services, you may be asked to provide personal information about yourself, such as, amongst others, your name, contact information, payment information, details about your home or properties you are interested in, financial information, your favorite(s), or your style(s). This may occur, for example:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>When you carry out transactions with us on or in relation to the Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>When you register an account with us on the Website;;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>When you contact our customer service or salespeople by email, telephone, or in person, to resolve any issues that you may be facing or with enquiries or requests;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>When you subscribe to the services that we are providing on the Website, or for our email notifications, and/or newsletters;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>When you provide or leave the relevant input(s), response(s), information on the Website for the purpose of using the Services;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>When you correspond with a real estate professional (such as a real estate agent or broker, mortgage lender or loan officer, property manager, investor, homebuilder, or others) via the Services; and</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g. </div>
                        <div>When you complete other forms or transactions, such as a request for loan information, background check application (if any).</div>
                      </div>
                    </div>
                    <div>
                      You may also provide information about a third party through the Services, for example, if you share a real estate listing with a recipient via email or SMS. By providing such information to us, you represent to us that you have obtained consent of the third party for you providing us with their Personal Data for the purposes set out in this Privacy Policy.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.2.</div>
                  <div>
                    <strong>Cookies, Pixels, and other tracking mechanisms</strong>. We and our partners use various technologies to collect information automatically when you access and use our Services, including cookies, and other similar technologies. Cookies are bits of electronic information that can be transferred to your computer or other electronic device to uniquely identify your browser. When you use the Services, we and our partners may place one or more cookies on your computer or other electronic devices or use other technologies that provide similar functionality.
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>We and our partners may use cookies to connect your activity on the Services with other information we store about you in your account profile or your prior interactions on our Services to, for example, store your preferences. The use of cookies helps us improve the quality of our Services to you, by identifying information, which is most interesting to you, tracking trends, measuring the effectiveness of advertising, or storing information you may want to retrieve on a regular basis, such as your shortlisted properties or preferred searches, and by doing others which are permissible under the applicable law. At any time, you may adjust settings on your browser to refuse cookies according to the instructions related to your browser. However, if you choose to disable cookies, many of the free features of the Services may not operate properly.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Some of our pages may also include pixels, which are electronic files to count users who have visited that page, to track activity over time and across different websites, to determine users’ interactions with emails we send, to identify certain cookies on the computer or other electronic device accessing that page, or to collect other related information, and this information may be associated with your unique browser, device identifier, or IP address. We may, for example, implement a pixel on specific Services where you view a certain listing so that we can track whether the listing is of relevance to you while offering recommendations. Through these pixels and cookies we collect information about your use of our Services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Services.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>We also collect information about the computer or mobile device you use to access our Services, such as the hardware model, operating system and version, unique device identifiers, mobile network information, and browsing behavior.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>We also allow certain third parties to collect information about your online activities through cookies and other technologies when you use the Website. These third parties include (a) business partners, who collect information when you view or interact with one of their advertisements on the Website, and (b) advertising networks, who collect information about your interests when you view or interact with one of the advertisements they place on many different web sites on the Internet. The information gathered by these third parties is non-personally identifiable information which is used to make predictions about your characteristics, interests, or preferences and to display advertisements on the Website, and across the Internet which are tailored to your interests.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>You can manage the type of cookies that are allowed through your browser settings, including completely blocking all cookies if you so wish. For information about how to manage cookies on your browser, please see the help section of the browser you are using. </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.3.</div>
                  <div>
                    <strong>Mobile device and mobile browser information.</strong> You may adjust settings on your mobile device and mobile browser regarding cookies and sharing of certain information, such as your mobile device model or the language your mobile device uses, by adjusting the privacy and security settings on your mobile device. Please refer to the instructions provided by your mobile service provider or mobile device manufacturer.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.4.</div>
                  <div>
                    <strong>Location Data.</strong> If you enable location services on your mobile device, we, with your permission, may collect the location of your device which we use to provide you with location-based information and advertising. If you wish to deactivate this feature, you can disable location services on your mobile device.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.5.</div>
                  <div>
                   <strong>Email Management.</strong> You may receive emails from us for a variety of reasons - for example, if you took an action through the Services, you signed up for a regular report, or you complete your design, and we send you the messages to follow-up on the coming steps. If you have an account with us, you may edit your preferences through your account settings. Also, you can manage your receipt of some types of communication by following the instructions included in the email we send you. Please note that, even if you unsubscribe from certain email correspondences, we may still need to email you with important transactional or administrative information.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.6.</div>
                  <div>
                    <strong>Disclaimer.</strong> We shall not be liable for any voluntary disclosure of Personal Data by you to other users in connection with the use of the Website, as the case may be.
                  </div>
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Purpose of Use',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    The Personal Data which you provide may be used and processed by us (or by third parties (such as Google Analytics) on our behalf) in the aggregate or individually and may be combined with other information for the purpose of managing and operating the Website, the scope of which may include, but is not limited to the following:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>Administering the Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>Improving your browsing experience by personalizing the Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Enabling your use of the Services available on the Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Providing to you the Services that you have specifically requested;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Responding to your enquiries or requests pursuant to your emails and/or submission of form(s) on the Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Responding to and taking follow-up action on complaints regarding the use of the Website or any Service provided by us;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>Sending you general commercial communications via any communication channel, not limited to email, voice call, SMS, and app notifications;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>Performing office and operational administrative matters;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>Providing third parties with statistical information about our users. Such statistical information may be used to personalize advertising on the Website, but cannot be used to identify any individual user; </div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>Creating and managing your online account;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>Facilitating your activities and transactions on the Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>Facilitating your participation in contests or promotions;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>Handling the payment, or refunding from/to you;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>Providing you with information about the Services you request from us; or subject to your prior consent, the information we feel that it may be of interest to you by email or text message;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>Operating, evaluating, and improving our business (including developing new products and services; analyzing and enhancing our Services);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>Understanding our customers and perform data analysis and other processing (including market and consumer research, trend analysis, and anonymization);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>Responding to your comments or questions or application for employment (if any);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>Sharing with the service providers so that they could provide the Services to you; and as otherwise required or permitted by law.</div>
                      </div>
                    </div>
                  </div>
                </div>
                You can choose to browse the Website and use its limited functions without disclosing your Personal Data. You are not required to provide Personal Data as a condition of using the Website, except as may be necessary for us to be able to provide the Services which you purchase or access through our Website, respond to your requests, or in cases where you contact us directly.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>As the purposes for which we may/will collect, use, disclose or process your Personal Data depend on the circumstances at hand, such purpose may not appear above. However, we will notify you of such other purposes at the time of obtaining your consent, unless processing of your Personal Data without your consent is permitted by the Personal Data Protection Act 2012 of Singapore (the "<strong>PDPA</strong>") or by any other applicable law.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>Do Not Call Provisions.</strong> If you have provided us with your telephone number(s) (the "<strong>Numbers</strong>") and have indicated that you consent to receiving marketing or other promotional information via your Numbers, then from time to time, we may contact you using such Numbers with information about our Services even if these Numbers are registered with the Do Not Call Registry (if any subject to your respective jurisdiction). You may however advise in writing should you wish not to be contacted by us at your Numbers for such purposes.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Accuracy and Security',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>4.1.</div>
                <div>You should ensure that all Personal Data submitted to us is complete, accurate, true, and correct. Failure on your part to do so may result in our inability to provide you with the Services that you have requested.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.2.</div>
                <div>We will take reasonable efforts to ensure that your Personal Data is accurate and complete if your Personal Data is likely to be used by us to make a decision that affects you or disclosed to another organization. However, this means that you must also update us of any changes in your Personal Data that you had initially provided us with. We will not be responsible for relying on inaccurate or incomplete Personal Data arising from you not updating us of any changes in your Personal Data that you had initially provided us with.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.3.</div>
                <div>We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your Personal Data.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.4.</div>
                <div>We will store all the Personal Data that you provide on secure servers in an encrypted format.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.5.</div>
                <div>You however understand that the transmission of information via the Internet is not completely secure. Although we will do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted to the Website; any transmission is at your own risk. Additionally, we cannot assume responsibility for any unauthorized use of your Personal Data by third parties which are wholly attributable to factors beyond our control.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.6.</div>
                <div>Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you are responsible for keeping this password confidential. We ask you not to share a password with anyone. We will NEVER ask you for your password other than when you log in to the Website, as the case may be.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.7.</div>
                <div>We urge you to take steps to keep your personal information safe, such as choosing a strong password and keeping it private, as well as logging out of your user account and closing your web browser when you have finished using our Services on a shared or unsecured device.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Disclosure of Personal Data',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>5.1.</div>
                <div>We will not, without your express consent, disclose your Personal Data to third parties for the purposes of direct marketing. Consent for disclosure of Personal Data to third parties, for the purposes of receiving marketing communications, is given through our registration form, either online or on paper.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.2.</div>
                <div>
                  To manage and operate the Website, we may disclose your Personal Data to any member of our group, which means our affiliates and subsidiaries. We may also disclose your Personal Data to third parties such as our service providers and professional advisors for the following purposes:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>For any purpose allowed under any applicable law or regulations;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>To comply with any legal requirement;  </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>To enforce or apply our Terms of Use; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>To protect our rights, property, and safety, and the rights, property and safety of our affiliates and subsidiaries, other users of the Website, and others;  </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>In connection with any legal proceedings or prospective legal proceedings; and</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>In connection with a business asset transaction (i.e., the purchase, sale, lease, merger or amalgamation or any other acquisition, disposal or financing of an organization or a portion of an organization or of any of the business or assets of an organization) to which we are a party or a prospective party.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.3.</div>
                <div>
                  We will also not disclose your Personal Data to third parties without first obtaining your consent permitting us to do so. However, please note that we may disclose your Personal Data to third parties without first obtaining your consent in certain situations, including, without limitation, the following:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Cases in which the disclosure is required or authorized based on the applicable laws and/or regulations;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Cases in which the purpose of such disclosure is clearly in your interests, and if consent cannot be obtained in a timely way; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>cases in which disclosure is necessary to respond to any emergency that threatens the life, health or safety of yourself or another individual; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Cases in which the disclosure is necessary for any investigation or proceedings;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Cases in which the Personal Data is disclosed to any officer of a prescribed law enforcement agency;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Cases in which the disclosure is to a public agency and such disclosure is necessary in the public interest; and/or</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Where such disclosure without your consent is permitted by the PDPA or by law. </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.4.</div>
                <div>
                  Where we disclose your Personal Data to third parties without your consent, we will employ our best efforts to require such third parties to protect your Personal Data. 
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Withdrawal of Consent',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>You can withdraw your consent by contacting our customer service at <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Please note that if you withdraw your consent to any or all use of your Personal Data, depending on the nature of your request, we may not be in a position to continue to provide our Services to you. On notice from you, we will inform you of the likely consequences of the withdrawal of consent, including but not limited to the termination of any agreements you may have with us and your being in breach of your contractual obligations or undertakings. Our legal rights and remedies in such an event are expressly reserved.</div>
              </div>
            </div>
          `
    },
    {
      title: 'International Data Transfer',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>7.1.</div>
                <div>We operate in many countries and may temporarily store, process, or transfer your Personal Data between any of the countries in which we operate in order to enable us to use your Personal Data in accordance with this Privacy Policy and our Terms of Use.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>7.2.</div>
                <div>We will take all steps reasonably necessary to ensure that your Personal Data is transferred in accordance with any applicable law or regulation and take all reasonable steps to ensure that any foreign recipient organization of your Personal Data is bound by legally enforceable obligations to provide to the transferred Personal Data a standard of protection that is at least comparable to the protection under the PDPA. </div>
              </div>
            </div>
          `
    },
    {
      title: 'Third Party Website',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                The Website may, from time to time, contain links to and from the websites of our partner networks and vendors. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Personal Data to these websites.
              </div>
            </div>
          `
    },
    {
      title: 'Access, Deletion, and Correction',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>9.1.</div>
                <div>Our Data Protection Officer is responsible for ensuring that requests for access, deletion or amendment of Personal Data are properly dealt with in accordance with the procedures specified herein.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.2.</div>
                <div>All requests for information or requests for amendments or deletion to personal data or deletion of personal data held by us will be made by submitting a request to our Data Protection Officer at: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.3.</div>
                <div>Please note that we will only accept and act on requests in the English language that comply with this Privacy Policy. Any request that does not comply with the above will be rejected, and we will not take any action in relation to such rejected requests.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.4.</div>
                <div>We may refuse to disclose, delete, or amend any Personal Data for the reasons set out in the PDPA.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.5.</div>
                <div>As far as reasonably possible, we will respond to your request within ten (10) working days from the date of receipt of the request. If that is not possible, you will be so notified within ten (10) working days from the date of receipt of the request.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Retention of your Personal Data ',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>We retain Personal Data we collect from you where we have an ongoing legitimate business need to do so. If you are a client or vendor (or a representative of a client or vendor) then the information will be retained for a period to allow us to provide our Services, to comply with applicable legal, tax or accounting requirements and, if necessary, for the establishment, exercise, or defense of legal claims.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Contact Us',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>
                    Should you require further information about the treatment of your Personal Data by us, please do not hesitate to contact our Data Protection Officer at: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                  </div>
                </div>
              </div>
            `
    },
    {
      title: 'Governing Law',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                This Privacy Policy shall be governed in all respects by the laws of Singapore.
                </div>
              </div>
            `
    },
  ];
};
