import React from 'react';

const ChinaOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41145)">
        <path
          d="M13.832 6C13.8275 6.01774 13.826 6.0371 13.8184 6.05242C12.994 7.64597 12.1697 9.23952 11.3454 10.8331C11.3378 10.8484 11.3332 10.8677 11.3325 10.8855C11.331 10.9274 11.3325 10.9685 11.3325 11.0137H13.4009V11.8169H11.3332V15H10.3475V11.8185H8.27754V11.0145H10.346C10.346 10.9621 10.3467 10.9121 10.346 10.8629C10.346 10.8508 10.3369 10.8387 10.3308 10.8266C9.73757 9.67984 9.14432 8.53387 8.55106 7.3879C8.31543 6.93468 8.08055 6.48065 7.84567 6.02742C7.84188 6.02016 7.83658 6.0129 7.83203 6.00565C7.83961 6.00323 7.84718 6 7.85476 6C8.2586 6 8.6632 6 9.06704 6C9.07007 6.00726 9.07234 6.01452 9.07537 6.02177C9.65878 7.24113 10.2429 8.46129 10.8263 9.68064C10.8301 9.68871 10.8339 9.69597 10.8392 9.70565C10.8445 9.69516 10.8483 9.6879 10.8521 9.67984C11.2635 8.81935 11.6757 7.95887 12.0871 7.09839C12.2621 6.73226 12.4372 6.36613 12.6122 6C13.019 6 13.4252 6 13.832 6Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41145">
          <rect
            width="6"
            height="9"
            fill="white"
            transform="translate(7.83203 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChinaOutlined;
