import React, { useEffect, useState } from 'react';

// COMPONENTS
import Stack from '@mui/material/Stack';
import Select from 'components/inputs/Select';

// SERVICES
import { getSession, getStorage } from 'services/storageService';

// CONSTANTS
import { SelectChangeEvent } from '@mui/material';
import { SessionKey, StorageKey } from 'constants/storage';
import { SelectOption } from 'constants/types';
import { usePriceContext } from 'contexts/priceContext';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrencyList } from 'store/global/globalSelectors';
import { fetchCurrencyIpAddressByApi } from 'apis/currencyApis';

const CurrencyDropdown = () => {
  const currencyList = useSelector(selectCurrencyList);
  const [currencyValue, setCurrencyValue] = useState<number>(1);
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>([]);

  const { updatePreferCurrency, updateCurrencyList } = usePriceContext();

  // Update Currency &  Set Currency UI
  useEffect(() => {
    (async () => {
      if (!isEmpty(currencyList)) {
        updateCurrencyList(currencyList);
        setCurrencyOptions(currencyList.map((currency) => ({
          label: (
            <Stack direction="row" gap={1} alignItems="center">
              {currency.icon} {currency.code}
            </Stack>
          ),
          value: currency.id,
        })));

        const currencyCode = getSession(SessionKey.Currency);
        const currency = currencyCode
          ? currencyList.find(({ code }) => code === currencyCode)
          : (await fetchCurrencyIpAddressByApi()).data;

        if (currency) {
          setCurrencyValue(currency.id);
          updatePreferCurrency(currency?.code, currency?.symbol, currency?.conversionRate);
        }
      }
    })();
  }, [currencyList]);

  const handleSelectCurrency = (e: SelectChangeEvent<unknown>) => {
    const { value: id } = e.target;
    const currency = currencyList.find((item) => item.id === id);
    setCurrencyValue(currency?.id!);
    updatePreferCurrency(currency?.code!, currency?.symbol ?? '', currency?.conversionRate!);
    window.location.reload();
  };

  return (
    <Select
      options={currencyOptions}
      value={currencyValue || currencyOptions[0].value}
      hideSelected
      fullWidth
      required
      height='300px'
      onChange={handleSelectCurrency}
    />
  );
};

export default CurrencyDropdown;
