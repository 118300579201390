import React from 'react';

const BrazilOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41224)">
        <path
          d="M13.8633 9.04032C13.5517 9.04032 13.2506 9.03941 12.9495 9.04123C12.9026 9.04149 12.9035 9.00939 12.8976 8.98199C12.8702 8.85334 12.8284 8.73082 12.7492 8.62356C12.6872 8.5398 12.6089 8.47821 12.5054 8.43854C12.5039 8.46307 12.5021 8.47991 12.5021 8.49661C12.502 8.80324 12.5031 9.10973 12.5008 9.41623C12.5004 9.46242 12.5168 9.4773 12.5588 9.49126C12.7839 9.56615 13.0152 9.62852 13.2298 9.72691C13.712 9.94781 13.9673 10.3318 13.9971 10.863C14.0361 11.5605 13.6385 12.1242 12.9628 12.316C12.8313 12.3533 12.6953 12.3767 12.5601 12.3991C12.5115 12.4072 12.5003 12.4237 12.501 12.4691C12.5037 12.6212 12.502 12.7735 12.502 12.9256V12.9999H11.6785V12.3994C11.2006 12.3258 10.7871 12.1506 10.505 11.7432C10.3013 11.4489 10.1678 11.0363 10.1839 10.7353H11.1071C11.1791 11.0808 11.3065 11.3905 11.673 11.5454C11.6744 11.5151 11.6763 11.4943 11.6763 11.4735C11.6764 11.0865 11.6754 10.6995 11.6777 10.3125C11.678 10.2616 11.6616 10.2416 11.6138 10.2277C11.3662 10.1554 11.1249 10.0673 10.9089 9.92236C10.142 9.40762 10.1805 8.28066 10.9799 7.81876C11.1852 7.70016 11.4086 7.63883 11.6403 7.60073C11.6488 7.5993 11.6573 7.59721 11.6735 7.59368V7H12.501V7.59825C13.0155 7.67249 13.447 7.87239 13.7001 8.34969C13.8139 8.56446 13.8627 8.79436 13.863 9.04032H13.8633ZM12.5047 11.5839C12.7511 11.5129 12.9357 11.3843 13.0201 11.145C13.0981 10.9237 13.0198 10.7161 12.8183 10.5959C12.7222 10.5386 12.6203 10.4928 12.5047 10.4717V11.5839ZM11.6743 8.41362C11.4214 8.46347 11.2588 8.634 11.2666 8.83481C11.274 9.02022 11.4689 9.21099 11.6743 9.23199V8.41362Z"
          fill="white"
        />
        <path
          d="M6.95407 10.3818V12.3444H6V7.65346C6.01886 7.65177 6.0338 7.64942 6.0486 7.64942C6.79726 7.65059 7.54592 7.64602 8.29445 7.65672C8.51741 7.65999 8.74378 7.68856 8.96163 7.73671C9.41149 7.83613 9.68383 8.13702 9.80291 8.56852C9.91295 8.9674 9.88151 9.35845 9.64742 9.71414C9.45563 10.0056 9.16599 10.1522 8.8364 10.2332C8.77299 10.2487 8.70894 10.2611 8.64029 10.276C8.64828 10.2847 8.65248 10.2924 8.65903 10.2961C9.00683 10.4961 9.25887 10.7896 9.46965 11.1225C9.71868 11.5158 9.9664 11.9099 10.2146 12.3038C10.2213 12.3145 10.2268 12.326 10.2367 12.344C10.2128 12.3459 10.1947 12.3488 10.1767 12.3488C9.83619 12.3491 9.49559 12.3481 9.15512 12.3502C9.1115 12.3505 9.08923 12.3337 9.06656 12.3C8.75636 11.8374 8.44563 11.3751 8.13267 10.9144C8.07162 10.8247 8.0035 10.7391 7.93237 10.6569C7.80177 10.5056 7.63749 10.4136 7.43418 10.4025C7.27803 10.3939 7.12162 10.3888 6.95394 10.3817L6.95407 10.3818ZM6.95852 9.62737C6.96992 9.63115 6.97398 9.63363 6.97791 9.6335C7.41191 9.62854 7.84604 9.62619 8.28004 9.61654C8.4248 9.61327 8.56641 9.58326 8.68234 9.48436C8.85867 9.33392 8.89234 9.13572 8.86587 8.92147C8.84085 8.71896 8.73121 8.5723 8.5372 8.50393C8.44654 8.47196 8.3462 8.45487 8.24991 8.45331C7.82888 8.44665 7.40759 8.44822 6.98643 8.44704C6.9783 8.44704 6.97005 8.45043 6.95839 8.45304V9.62724L6.95852 9.62737Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41224">
          <rect width="8" height="6" fill="white" transform="translate(6 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BrazilOutlined;
