import i18n from 'i18next';
import loadable from '@loadable/component';
import React, { Suspense, lazy, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// COMPONENTS
import IconProgress from 'components/progress/IconProgress';

// LAYOUTS
import MainLayout from 'components/layouts/MainLayout';

// CONTEXTS
import { LayoutProvider } from 'contexts/layoutContext';
import { PriceProvider } from 'contexts/priceContext';

// THEME and CSS
import './App.scss';

// SERVICES
import { changeLanguage } from 'services/localeService';

// OLD PAGES
import { Lang } from 'constants/i18n';

import MainSideBar from 'components/layouts/navigation/MainSideBar';
import { PageRoute } from 'constants/route';
import { OrderProvider } from 'contexts/ordersContext';
import { isEmpty } from 'lodash';
import { TextContentBrand } from 'model/textContent';
import { useDispatch } from 'react-redux';
import { setTextContentBrand } from 'store/designHome/designHomeActions';
import { CollectionProvider } from 'contexts/collectionsContext';
import { Redirect } from 'react-router-dom';

// NEW PAGES
const HomePage = loadable(() => import('pages/HomePage/HomePage'));
const Dashboard = loadable(() => import('pages/Dashboard/Dashboard'));
const HomePageSelling = loadable(() => import('pages/HomePageSelling/HomePageSelling'));
const HomePageProject = loadable(() => import('pages/HomePageProject/HomePageProject'));

// RESELL
const ChooseCountry = loadable(() => import('pages/ResellProject/ChooseCountry/ChooseCountry'));
const ProjectListResell = loadable(() => import('pages/ResellProject/ProjectListResell/ProjectListResell'));
const ChooseResellOptions = loadable(() => import('pages/ResellProject/ChooseResellOptions/ChooseResellOptions'));
const ResellUnit = loadable(() => import('pages/ResellProject/ResellOptions/ResellUnit/ResellUnit'));
const KeepModel = loadable(() => import('pages/ResellProject/ResellOptions/KeepModel/KeepModel'));
const KeepAndRemodel = loadable(() => import('pages/ResellProject/ResellOptions/KeepAndRemodel/KeepAndRemodel'));

const ProjectListing = loadable(
  () => import('pages/ProjectListing/ProjectListing')
);
const ProjectViewDetail = loadable(
  () => import('pages/ProjectListing/ProjectViewDetail')
);

// INFORMATION
const PrivacyPolicy = loadable(
  () => import('pages/PrivacyPolicy/PrivacyPolicy')
);
const FAQ = loadable(
  () => import('pages/FAQ/FAQ')
);
const CookiePolicy = loadable(() => import('pages/CookiePolicy/CookiePolicy'));
const TermsOfUse = loadable(() => import('pages/TermsOfUse/TermsOfUser'));
const YourDesign = loadable(() => import('pages/YourDesign/YourDesign'));

const BlockChoosingPage = loadable(() => import('pages/YourHome/BlockChoosing'));
const SegmentFloorChoosing = loadable(() => import('pages/YourHome/SegmentFloorChoosing'));
const CollectionChoosing = loadable(() => import('pages/YourHome/CollectionChoosing'));
const PromotionChoosing = loadable(() => import('pages/YourHome/PromotionChoosing'));
const Interact2D = loadable(() => import('pages/YourHome/Interact2D'));

// Financial Package
const MyFinancialPackage = lazy(() => import('pages/MyFinancialPackage/FinancialQuestion'));
const MyFinancialCombine = loadable(() => import('pages/MyFinancialPackage/FinancialCombine'));
const MyFinancialBank = loadable(() => import('pages/MyFinancialPackage/FinancialBank'));
const MortagePlan = loadable(() => import('pages/MortgagePlan/MortagePlan'));
const Share = loadable(() => import('pages/Social/Share'));

// Profile
const PersonalProfile = loadable(() => import('pages/MyProfile/PersonalProfile'));

// My Order Confirm
const MyOrderConfirm = loadable(() => import('pages/MyOrder/OrderConfirm'));

// Our Story
const OurStory = loadable(() => import('pages/OurStory/OurStory'));

// In the Spotlight
const InTheSplotlight = loadable(() => import('pages/InTheSpotlight/InTheSpotlight'));

// Contact Us
const ContactUs = loadable(() => import('pages/ContactUs/ContactUs'));

// Partner Levitate
const PartnerLevitate = loadable(() => import('pages/PartnerLevitate/PartnerLevitate'));

// Demo Interact 3D
const DemoInteract3D = loadable(() => import('pages/Demo/DemoInteract3D'));

// Wait list
const WaitList = loadable(() => import('pages/WaitList/WaitList'));

const NEW_PAGE_PATHS = Object.values(PageRoute);

type Props = {
  textContentBrand?: TextContentBrand;
}

const App = ({ textContentBrand }: Props) => {

  const dispatch = useDispatch();

  // useDocumentTitle({
  //   siteTitle: 'Levitate - For You By You',
  // });

  useEffect(() => {
    changeLanguage(i18n.language as Lang);
  }, []);

  useEffect(() => {
    if (!isEmpty(textContentBrand)) {
      dispatch(setTextContentBrand({ textBrand: textContentBrand?.textBrand || 'Levitate' }));
    }
  }, [textContentBrand])

  return (
    <Router>
      <Suspense fallback={<IconProgress fullScreen noRender />}>
        <Switch>
          <Route exact path={NEW_PAGE_PATHS}>
            <PriceProvider>
              <LayoutProvider>
                <CollectionProvider>
                  <OrderProvider>
                    <MainLayout>
                      <Suspense fallback={<IconProgress fullScreen noRender />}>
                        <Switch>
                          <MainSideBar>
                            <Route
                              exact
                              path={PageRoute.Fusion}
                            />
                            <Route
                              exact
                              path={PageRoute.FushionOwner}
                            />
                            <Route
                              exact
                              path={PageRoute.Dashboard}
                              component={Dashboard}
                            />
                            <Route
                              exact
                              path={PageRoute.HomePage}
                              component={HomePage}
                            />
                            <Route
                              exact
                              path={PageRoute.HomePageSelling}
                              component={HomePageSelling}
                            />
                            <Route
                              exact
                              path={PageRoute.ChooseResellOptions}
                              component={ChooseResellOptions}
                            />
                            <Route
                              exact
                              path={PageRoute.HomePageProject}
                              component={HomePageProject}
                            />
                            <Route
                              exact
                              path={PageRoute.ChooseCountry}
                              component={ChooseCountry}
                            />
                            <Route
                              exact
                              path={PageRoute.ResellUnit}
                              component={ResellUnit}
                            />
                            <Route
                              exact
                              path={PageRoute.KeepModel}
                              component={KeepModel}
                            />
                            <Route
                              exact
                              path={PageRoute.KeepAndRemodel}
                              component={KeepAndRemodel}
                            />
                            <Route
                              exact
                              path={PageRoute.ProjectListResell}
                              component={ProjectListResell}
                            />
                            <Route
                              exact
                              path={PageRoute.PartnerLevitate}
                              component={PartnerLevitate}
                            />
                            <Route
                              exact
                              path={PageRoute.DemoInteract3D}
                              component={DemoInteract3D}
                            />
                            <Route
                              exact
                              path={PageRoute.ProjectListing}
                              component={ProjectListing}
                            />
                            <Route
                              exact
                              path={PageRoute.ProjectDetail}
                              component={ProjectViewDetail}
                            />
                            <Route
                              path={PageRoute.PrivacyPolicy}
                              component={PrivacyPolicy}
                            />
                            <Route
                              path={PageRoute.FAQ}
                              component={FAQ}
                            />
                            <Route
                              path={PageRoute.TermsOfUse}
                              component={TermsOfUse}
                            />
                            <Route
                              path={PageRoute.CookiePolicy}
                              component={CookiePolicy}
                            />
                            <Route exact
                              path={PageRoute.ContactUs}
                              component={ContactUs}
                            />
                            <Route exact
                              path={PageRoute.InTheSplotlight}
                              component={InTheSplotlight}
                            />
                            <Route exact
                              path={PageRoute.OurStory}
                              component={OurStory}
                            />
                            <Route exact
                              path={PageRoute.ChooseCollection}
                              component={CollectionChoosing}
                            />
                            <Route exact
                              path={PageRoute.WaitList}
                              component={WaitList}
                            />
                            <Route exact path={NEW_PAGE_PATHS}>
                              <Route exact path={PageRoute.ChooseBlock} component={BlockChoosingPage} />
                              <Route exact path={PageRoute.ChooseSegment} component={SegmentFloorChoosing} />
                              <Route exact path={PageRoute.PromotionChoosing} component={PromotionChoosing} />
                              <Route exact path={PageRoute.SignatureLayout} component={Interact2D} />
                              <Route exact path={PageRoute.YourDesign} component={YourDesign} />
                              <Route exact path={PageRoute.MyFinancialPackage} component={MyFinancialPackage} />
                              <Route exact path={PageRoute.MyFinancialCombine} component={MyFinancialCombine} />
                              <Route exact path={PageRoute.MyFinancialBank} component={MyFinancialBank} />
                              <Route exact path={PageRoute.PersonalProfile} component={PersonalProfile} />
                              <Route exact path={PageRoute.MyOrderConfirm} component={MyOrderConfirm} />
                              <Route exact path={PageRoute.MortagePlan} component={MortagePlan} />
                              <Route exact path={PageRoute.ShareSocial} component={Share} />
                            </Route>
                          </MainSideBar>
                        </Switch>
                      </Suspense>
                    </MainLayout>
                  </OrderProvider>
                </CollectionProvider>
              </LayoutProvider>
            </PriceProvider>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
