export const DATA_FAQ_IN = (textBrand: string) => {
  return [
    {
      title:
        `Siapa sebenarnya ${textBrand} International dan apa yang mereka tawarkan di dunia ini?`,
      description: `
            <p>Baiklah, mari kita bicara tentang pasar properti utama dan pengalaman penjualan yang membosankan dan ketinggalan zaman. ${textBrand} International hadir untuk mengubah permainan. Kami hadir untuk mengguncang segalanya, teman saya. </p>
            <p>Kami berkolaborasi mengembangkan rumah-rumah masa depan. Bagaimana kami melakukannya? Dengan memanfaatkan teknologi personalisasi. Ya, betul. ${textBrand} memperkenalkan alat desain interior 3D yang menakjubkan yang didukung oleh kecerdasan buatan. Ya, Anda mendengar saya dengan benar. Alat hebat ini memungkinkan Anda menjelajahi ruang hidup Anda secara real-time, ruangan per ruangan, dan memodelkan rumah masa depan Anda.</p>
            <p>Dengan ${textBrand}, Anda akan memulai perjalanan yang lancar, menyenangkan, dan transparan. Tidak ada lagi kejutan, tidak ada lagi penundaan. Ini adalah perjalanan yang mulus dan efisien menuju rumah impian Anda.</p>
           `,
    },
    {
      title:
        'Apa itu Segmen dan bagaimana cara kerjanya dalam pembelian rumah baru?',
      description: `
            <p>Baiklah, teman, mari kita bicara tentang segmen dan bagaimana cara kerjanya dalam perjalanan pembelian rumah ${textBrand}.</p>
            <p>Sebuah segmen seperti potongan teka-teki yang dapat digabungkan untuk menciptakan ruang sesuai dengan keinginan dan anggaran Anda.</p>
            <p>Pikirkanlah itu sebagai ukuran area tinggal yang berbeda yang dapat Anda peroleh secara individu atau berulang, mempersonalisasi rumah baru Anda untuk memenuhi setiap kebutuhan dan keinginan Anda.</p>
           `,
    },
    {
      title:
        'Mengapa Perjanjian Reservasi sangat penting dalam proses pembelian?',
      description: `
            <p>Baiklah, mari kita bicarakan tentang Perjanjian Reservasi ini. Anda mungkin berpikir, "Apa itu, dan mengapa saya harus peduli?" Nah, teman saya, biarkan saya jelaskan. Perjanjian ini adalah elemen penting dalam perjalanan gila membeli properti. Mengapa? Karena itu seperti jaring pengaman, teman.</p>
            <p>Ketika Anda menandatangani Perjanjian Reservasi, Anda pada dasarnya mengatakan, "Tunggu dulu, saya tertarik dengan properti ini, dan saya ingin memastikan itu milik saya."</p>
            <p>Mengapa itu begitu penting? Sederhana. Ini memberi Anda ketenangan pikiran dan melindungi kepentingan Anda. Ini memastikan bahwa properti tidak akan terjual dari bawah hidung Anda saat Anda sibuk mengurus detail-detailnya.</p>
           `,
    },
    {
      title:
        'Apa yang tepatnya termasuk dalam Perjanjian Penjualan dan Pembelian dan apa yang dilibatkannya dalam pembelian rumah?',
      description: `
            <p>Perjanjian Penjualan dan Pembelian seperti suatu kitab suci dalam pembelian rumah. Ini adalah dokumen yang menetapkan kesepakatan dan melindungi Anda dalam prosesnya.</p>
            <p>Biarkan saya jelaskan: saat Anda membeli rumah, Anda tidak hanya memberikan uang Anda dan berharap yang terbaik. Nah, disinilah perjanjian ini berperan. Ini adalah perjanjian yang mengikat secara hukum antara Anda dan penjual yang menjelaskan semua hal penting. Kami berbicara tentang harga rumah, kondisi penyerahan, dan jadwal penyelesaian kesepakatan. </p>
            <p>Ini bukan perjanjian jabat tangan santai, teman. Ini adalah komitmen serius yang memastikan kedua belah pihak bertanggung jawab. Jadi, ketika Anda siap melakukan pembelian besar-besaran, pastikan Anda memiliki Perjanjian Penjualan & Pembelian yang kokoh. Ini adalah jaring pengaman Anda dalam dunia liar pembelian rumah.</p>
           `,

    },
    {
      title:
        `Bagaimana Anda bisa mewujudkan impian membeli rumah impian Anda melalui kekuatan misterius ${textBrand} International?`,
      description: `
            <p>Jadi, Anda sangat ingin tahu bagaimana Anda bisa mewujudkan rumah impian Anda melalui ${textBrand} International? Nah, siap-siaplah, teman saya, karena saya akan mengungkapkannya untuk Anda.</p>
            <p><strong>Langkah satu:</strong> pilih negara yang Anda inginkan. Di mana Anda melihat diri Anda menjalani kehidupan terbaik? Pilih dan mari kita mulai.</p>
            <p><strong>Langkah dua:</strong> kami memiliki beragam proyek yang terdaftar, menunggu Anda untuk menjelajahinya. Tenggelam dalam detailnya, bayangkan diri Anda di ruang-ruang itu, dan bayangkan kehidupan yang akan Anda jalani.</p>
            <p><strong>Langkah tiga:</strong> lebih spesifik, teman. Pilih jumlah segmen yang dapat Anda beli dan lantai yang sesuai dengan jiwa Anda.</p>
            <p><strong>Langkah empat:</strong> Di sinilah semuanya menjadi menyenangkan. Sekarang, bersiaplah untuk bagian yang menakjubkan. Anda dapat memprediksi apartemen Anda dalam 3D langsung. Ya, kami berbicara tentang mewujudkan rumah impian Anda di depan mata Anda. Ubah-ubah, mainkan, dan sempurnakan. Oh, dan apakah saya sudah menyebutkan bahwa Anda akan memiliki visibilitas dan transparansi penuh atas biaya-biaya tersebut? Tidak ada bisnis curang di sini.</p>
            <p><strong>Langkah lima:</strong> Begitu Anda puas dengan karya masterpiece Anda, saatnya untuk menandatangani perjanjian reservasi dan perjanjian pembelian secara online, dan voila! </p>
            <p>Sekarang, duduklah, teman, dan biarkan antusiasme tumbuh. Cukup dalam waktu singkat, Anda akan bersantai di rumah yang selalu Anda fantasi. ${textBrand} ada di sini untuk mewujudkannya.</p> 
          `,
    },

    {

      title:
        'Bagaimana cara bergabung dengan daftar tunggu dan tetap mendapat informasi tentang properti yang akan segera diluncurkan?',
      description: `
            <p>Jadi Anda bersemangat untuk mendapatkan properti "Segera Diluncurkan" yang sulit ditemukan itu, ya? Saya tidak menyalahkan Anda. Nah, berikut rahasia untuk tetap terupdate dan mendahului yang lain.</p>
            <p>Pergi ke situs web kami dan cari bagian yang bertuliskan "Tetap Terinformasi". Itu seperti harta karun bagi Anda sebagai pembeli rumah. Cari opsi untuk bergabung dengan daftar tunggu. Masukkan alamat email Anda, dan Anda secara resmi berada dalam lingkaran.</p>
            <p>Kami akan memastikan Anda menjadi yang pertama mengetahui kapan proyek-proyek menarik itu siap untuk dijalankan. Ini seperti memiliki akses khusus ke dunia properti. Jadi jangan buang waktu lebih lama lagi, teman. Bergabunglah dengan daftar tunggu dan mari menantikan kejutan yang akan datang.</p>
          `,
    },

    {
      title:
        `Bagaimana ${textBrand} International mengutamakan perlindungan data berharga Anda, dan kemitraan apa yang mereka miliki untuk memastikan transaksi aman?`,
      description: `
            <p>Di ${textBrand} International, kami tidak main-main dalam hal perlindungan data. Informasi Anda bagi kami seperti emas, dan kami menjaganya dengan sepenuh hati. Kami tidak main-main dengan solusi kelas dua. Kami telah bermitra dengan yang terbaik dari yang terbaik untuk menjaga data Anda tetap aman dan terjaga.</p>
            <p>Kami memiliki Tazapay, sebuah perusahaan berbasis di Singapura yang mengkhususkan diri dalam menjaga detail pembayaran Anda tetap aman. Tapi itu tidak berhenti di situ. Kami telah mengambil langkah lebih jauh dengan teknologi inovatif dari Pandadoc untuk penandatanganan kontrak digital.</p>
            <p>Kami tidak meninggalkan sesuatupun, teman. Data Anda adalah prioritas utama kami, dan kami memiliki kemitraan yang tepat untuk memastikan hal tersebut.</p>
          `,
    },
  ];
};
