import React from 'react';
import styled from 'styled-components';

import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';

type StyledProps = {
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

type Props = {
  label?: FormControlLabelProps['label'];
} & CheckboxProps;

export default function Checkbox({ label, disabled, ...checkboxProps }: Props) {
  return (
    <FormControlLabelStyled
      label={label}
      disabled={disabled}
      control={<MuiCheckbox {...checkboxProps} disabled={disabled} />}
    />
  );
}

const FormControlLabelStyled = styled(FormControlLabel)<StyledProps>`
  margin-bottom: ${p => p.label ? '.5rem' : '0'} !important;
  margin-right: ${p => p.label ? '15px' : '0'} !important;

  .MuiCheckbox-root {
    padding: 4px;
    color: ${p => p.theme.palette.accent.main};
    height: 0px;
    margin-left: 4px;

    &.Mui-checked {
      color: ${p => p.theme.palette.accent.main};
    }

    svg {
      width: 26px;
      margin-right: 3px;
      height: 26px;
    }
  }

  .MuiTypography-root {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
  }

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: ${p => p.theme.palette.accent.main};
  }
`;
