export const DATA_FAQ_ZH = (textBrand: string) => {
  return [
    {
      title:
        `${textBrand} International是谁，他们带来了什么？`,
      description: `
            <p>好的，让我们谈谈主要的房地产市场和伴随其而来的枯燥、过时的销售体验。${textBrand} International在这里改变游戏规则。我们在这里让一切颠覆，朋友。</p>
            <p>我们共同开发未来的家园。我们是如何做到的？通过利用个性化技术的力量。是的没错。${textBrand}引入了一款令人惊叹的3D室内设计工具，由人工智能驱动。没错，你没听错。这个小东西让你实时浏览你的生活空间，逐个房间预建你未来的家。</p>
            <p>选择${textBrand}，你将踏上一段流畅、愉快和透明的旅程。不再有意外，不再有延误。这是通往你梦想家园的无缝高效之旅。</p>
           `,
    },
    {
      title:
        '“分段”是什么？在购买新房时如何运作？',
      description: `
            <p>好的，朋友，让我们谈谈“分段”以及它在${textBrand}购房过程中的奇妙之处。</p>
            <p>“分段”就像是拼图中的一块，可以组合成满足您需求和预算的空间。</p>
            <p>把它想象成是您可以单独或组合获得的不同大小的生活区域，个性化您的新家，以满足您的一切需求和愿望。</p>
           `,
    },
    {
      title:
        '预约协议在购房过程中为什么如此重要？',
      description: `
            <p>好的，让我们谈谈这个叫做预约协议的东西。也许你会想，“这是什么，我为什么要在意？”好的，朋友，让我为你解释一下。这是购买房产的过程中至关重要的元素。为什么？因为它就像是一张安全网，朋友。</p>
            <p>当您签署预约协议时，您实际上是在说：“等等，我对这个房产很感兴趣，我想确保它是我的。”</p>
            <p>为什么这么重要？很简单。它让您安心，保护您的利益。它确保在您忙于处理细节时，房产不会被别人抢走。</p>
           `,
    },
    {
      title:
        '“销售与购买协议”中到底包括什么？在购房领域又意味着什么？',
      description: `
            <p>“销售与购买协议”就像是购房的圣杯。它是一份确立交易并保护您利益的文件。</p>
            <p>让我为您解释：当您购买房屋时，您不能只是交出您的钱然后期望一切顺利。这就是这个文件的用处。它是您和卖方之间的法律约束协议，规定了所有重要事项。我们谈论的是房屋的价格，交付条件以及交易完成的时间表。</p>
            <p>这不是一份随意的握手协议，朋友。这是一份认真的承诺，确保双方都能履行责任。所以，当您准备进行这个重要的购买时，确保您有一份牢固的销售与购买协议。它是您在购房的疯狂世界中的安全网。</p>
           `,

    },
    {
      title:
        `如何通过${textBrand} International神秘的力量将您最狂野的购房梦想变成现实？`,
      description: `
            <p>那么，您想知道如何通过${textBrand} International实现您的梦想家园？好吧，朋友，系好安全带，因为我将为您阐述。</p>
            <p><strong>第一步：</strong>选择您希望的国家。您在哪里看到自己过上最好的生活？做出您的选择，让我们开始吧。</p>
            <p><strong>第二步：</strong>我们有一系列待探索的项目。沉浸在细节中，设想自己在那些空间中生活，想象您将要过的生活。</p>
            <p><strong>第三步：</strong>收缩选择，朋友。选择您负担得起的段数和楼层，触动您的灵魂。</p>
            <p><strong>第四步：</strong>这是令人兴奋的时刻。现在，准备好体验令人惊叹的部分。您可以在惊人的实时3D中预建您的公寓。是的，我们正在谈论将您的梦想家园活灵活现地展现在您眼前。调整它，玩弄它，让它完美无缺。哦，我提过您将对费用有完全的透明度了吗？这里没有可疑交易。</p>
            <p><strong>第五步：</strong>一旦您满意您的杰作，现在是时候来封盖交易了。在线签署您的预约协议和购买协议，大功告成！</p>
            <p>现在，放轻松，朋友，让期待逐渐增长。不久之后，您将在您一直幻想的家中轻松自在。${textBrand}在这里让梦想成真。</p>
          `,
    },

    {

      title:
        '如何加入候补名单，并及时获知即将推出的房产信息？',
      description: `
            <p>所以，您渴望获得那些不易找到的“即将推出”房产，对吗？我不怪您。好的，这里是保持更新并领先一步的秘诀。</p>
            <p>前往我们的网站，寻找“保持信息通知”的部分。对于像您这样的房屋买家来说，它就像是一个宝藏。找到加入候补名单的选项。输入您的电子邮件地址，现在您就正式进入这个圈子。</p>
            <p>我们会确保您是第一个知道那些令人兴奋的项目何时准备启动的人。就像拥有房产世界的后台通行证一样。所以，朋友，别再浪费一秒钟。加入候补名单，让期待开始吧。</p>
          `,
    },

    {
      title:
        `${textBrand} International如何优先保护您宝贵的数据，并与哪些合作伙伴建立安全交易？`,
      description: `
            <p>在${textBrand} International，当涉及数据保护时，我们绝不嬉皮笑脸。您的信息对我们来说就像黄金一样，我们竭尽全力保护它。我们不会采用次等解决方案。我们与最佳合作伙伴合作，确保您的数据安全可靠。</p>
            <p>我们与Tazapay合作，这是一家位于新加坡的公司，专门负责保护您的付款详细信息。但事情并不止于此。我们还与Pandadoc的创新技术合作，用于数字合同签署。</p>
            <p>我们对此事全力以赴，朋友。您的数据是我们的首要任务，我们已经与适当的合作伙伴建立了合作关系，以确保如此。</p>
          `,
    },
  ];
};
