export const DATA_FAQ_PT = (textBrand: string) => {
  return [
    {
      title:
        `Quem diabos é a ${textBrand} International e o que eles trazem para a mesa?`,
      description: `
            <p>Certo, vamos falar sobre o mercado imobiliário principal e a experiência de venda chata e ultrapassada que vem junto com ele. A ${textBrand} International está aqui para mudar o jogo. Estamos aqui para sacudir as coisas, meu amigo.</p>
            <p>Estamos co-desenvolvendo as casas do futuro. Como fazemos isso? Através da tecnologia de personalização. Sim, é isso mesmo. A ${textBrand} apresenta uma ferramenta de design de interiores 3D incrível impulsionada pela inteligência artificial. Sim, você me ouviu certo. Essa belezinha permite que você navegue pelo seu espaço de vida em tempo real, cômodo por cômodo, e pré-modele sua futura casa.</p>
            <p>Com a ${textBrand}, você embarcará em uma jornada tranquila, agradável e transparente. Sem mais surpresas, sem mais atrasos. É uma jornada perfeita e eficiente em direção à sua casa dos sonhos.</p>
           `,
    },
    {
      title:
        'O que é um Segmento e como funciona quando se trata de comprar minha nova casa?',
      description: `
            <p>Certo, meu amigo, vamos falar sobre segmentos e como eles funcionam sua magia na jornada de compra de casas da ${textBrand}.</p>
            <p>Um segmento é como uma peça do quebra-cabeça que pode ser combinada para criar um espaço que atenda aos seus desejos e orçamento.</p>
            <p>Pense nisso como um tamanho distinto de área de estar que você pode adquirir individualmente ou em múltiplos, personalizando sua nova casa para atender a todas as suas necessidades e desejos.</p>
           `,
    },
    {
      title:
        'Por que um Contrato de Reserva é tão importante no processo de compra?',
      description: `
            <p>Certo, vamos falar sobre essa coisa chamada Contrato de Reserva. Você pode estar pensando: "O que é isso e por que devo me importar?" Bem, meu amigo, deixe-me explicar. Essa belezinha é um elemento crucial na emocionante jornada de compra de uma propriedade. Por quê? Porque é como uma rede de segurança, meu amigo.</p>
            <p>Quando você assina um Contrato de Reserva, basicamente está dizendo: "Espera aí, tenho meus olhos nesta propriedade e quero garantir que ela seja minha."</p>
            <p>Por que isso é tão importante? Simples. Isso lhe dá tranquilidade e protege seus interesses. Garante que a propriedade não será vendida enquanto você está ocupado cruzando os T's e pontuando os I's.</p>
           `,
    },
    {
      title:
        'O que exatamente está incluído em um Contrato de Compra e Venda e o que ele envolve no âmbito da compra de casa?',
      description: `
            <p>Um Contrato de Compra e Venda é como o Santo Graal da compra de uma casa. É o documento que sela o acordo e protege suas costas durante o processo.</p>
            <p>Deixe-me explicar: quando você está comprando uma casa, não está simplesmente entregando seu dinheiro e torcendo pelo melhor. É aí que entra essa belezinha. É um acordo legalmente vinculativo entre você e o vendedor que detalha tudo o que é importante. Estamos falando do preço da casa, das condições de entrega e do prazo para concluir o acordo.</p>
            <p>Isso não é um acordo casual de aperto de mão, meu amigo. É um compromisso sério que garante que ambas as partes sejam responsáveis. Então, quando você estiver pronto para fazer essa grande compra, certifique-se de ter um sólido Contrato de Compra e Venda em vigor. É sua rede de segurança no mundo selvagem da compra de casas.</p>
           `,

    },
    {
      title:
        `Como transformar seus sonhos mais loucos de compra de casa em realidade através dos misteriosos poderes da ${textBrand} International?`,
      description: `
            <p>Então, você está morrendo de vontade de saber como pode tornar sua casa dos sonhos realidade através da ${textBrand} International? Bem, se segure, meu amigo, porque estou prestes a te explicar.</p>
            <p><strong>Passo um:</strong> escolha o país desejado. Onde você se vê vivendo sua melhor vida? Faça sua escolha e vamos mergulhar.</p>
            <p><strong>Passo dois:</strong> temos uma variedade de projetos listados, esperando por você para explorar. Perca-se nos detalhes, visualize-se nesses espaços e imagine a vida que você viverá.</p>
            <p><strong>Passo três:</strong> estreite suas opções, meu amigo. Escolha a quantidade de segmentos que você pode pagar e o andar que fala à sua alma.</p>
            <p><strong>Passo quatro:</strong> Aqui é onde fica emocionante. Prepare-se para a parte de tirar o fôlego. Você pode pré-modelar seu apartamento em uma vista 3D deslumbrante. Sim, estamos falando de dar vida à sua casa dos sonhos bem diante de seus olhos. Faça ajustes, brinque com ela e deixe-a perfeita. Ah, e mencionamos que você terá visibilidade total e transparência nos custos? Nada obscuro aqui.</p>
            <p><strong>Passo cinco:</strong> Quando estiver satisfeito com sua obra-prima, é hora de selar o acordo. Assine seu contrato de reserva e contrato de compra online, e voilà!</p>
            <p>Agora, sente-se, meu amigo, e deixe a expectativa crescer. Em breve, você estará relaxando na casa com a qual sempre sonhou. A ${textBrand} está aqui para tornar isso realidade.</p> 
          `,
    },

    {

      title:
        'Como entrar para a lista de espera e ficar por dentro das propriedades prestes a serem lançadas?',
      description: `
            <p>Então você está ansioso para colocar as mãos nessas elusivas propriedades "Em Breve", né? Não te culpo. Bem, aqui está o segredo para ficar atualizado e sair na frente dos outros.</p>
            <p>Vá até nosso site e procure pela seção "Fique Informado". É como um tesouro para compradores de imóveis como você. Procure pela opção de entrar para a lista de espera. Insira seu endereço de e-mail e considere-se oficialmente por dentro.</p>
            <p>Nós garantiremos que você seja o primeiro a saber quando esses projetos empolgantes estiverem prontos para decolar. É como ter um passe dos bastidores para o mundo imobiliário. Então, não perca mais um segundo, meu amigo. Junte-se à lista de espera e que comece a expectativa.</p>
          `,
    },

    {
      title:
        `Como a ${textBrand} International prioriza a proteção de seus valiosos dados e quais parcerias eles têm para garantir transações seguras?`,
      description: `
            <p>Na ${textBrand} International, não brincamos quando se trata de proteção de dados. Suas informações são como ouro para nós, e as guardamos com todo o nosso empenho. Não usamos soluções de segunda classe. Firmamos parcerias com os melhores dos melhores para manter seus dados seguros e protegidos.</p>
            <p>Temos a Tazapay, uma empresa sediada em Cingapura especializada em manter seus detalhes de pagamento seguros. Mas não paramos por aí. Fomos ainda mais longe com a tecnologia inovadora da Pandadoc para assinatura digital de contratos.</p>
            <p>Não deixamos pedra sobre pedra, meu amigo. Seus dados são nossa principal prioridade, e temos as parcerias certas para garantir isso.</p>
          `,
    },
  ];
};
