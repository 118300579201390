import { userUploadRequest } from 'apis/requests';
import axios from 'axios';
import mime from 'mime-types';

const BASE64_MARKER = ';base64,';
const units = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

const extensionMap: { [type: string]: string } = {
  'video/quicktime': 'mov',
};

type SignUrlPayload = {
  fileName: string;
  uploadUrl: string;
};

export type DetailResponsePayload<D> = {
  data: D;
  statusCode: string;
  message: string;
};

export const downloadFile = (blob: Blob | any, fileName: string) => {
  if (!(blob instanceof Blob)) {
    blob = new Blob([blob as any], { type: 'application/pdf' });
  }
  
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const extractMimeTypeFromDataUri = (uri: string) => {
  const [prev] = uri.split(BASE64_MARKER);
  if (!prev) return '';

  const [, mimeType] = prev?.split(':');
  return mimeType;
};

export const extractFileExtensionFromDataUri = (uri: string) => {
  const mimeType = extractMimeTypeFromDataUri(uri);
  if (!mimeType) return '';

  return extensionMap[mimeType] || mime.extension(mimeType);
};

export const convertDataUriToBinaryBlob = (dataURI: string): Blob => {
  // Detach content
  const [prefix, base64] = dataURI.split(BASE64_MARKER);
  const contentType = prefix.split(':')[1];
  const raw = window.atob(base64);

  // Convert content to binary
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < rawLength; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // Return binary as Blob
  return new Blob([uInt8Array], { type: contentType });
};

export const uploadMediaApi = async (
  fileDataUri: string,
  fileName: string
): Promise<string | undefined> => {
  try {
    const { statusCode, data } = await userUploadRequest.get<
      SignUrlPayload,
      DetailResponsePayload<SignUrlPayload>
    >('/upload/signed-url', { params: { fileName } });
    if (statusCode === 'OK') {
      const blob = convertDataUriToBinaryBlob(fileDataUri);

      const filePayload = await axios.put(data.uploadUrl, blob, {
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      console.log('filePayload', filePayload);
      return data.fileName;
    }
  } catch (e) {
    console.error('uploadMediaApi fail', e);
  }
};

//format size file
export const formatFileSize = (x: any) => {
  let l = 0, n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return {
    numberSize: n.toFixed(n < 10 && l > 0 ? 1 : 0),
    units: units[l]
  };
}