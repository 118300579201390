export class User {
  public id!: number;
  public address?: string;
  public birthday?: string;
  public email?: string;
  public firstName?: string;
  public isAdmin!: boolean;
  public lastName?: string;
  public partnerBirthday?: string;
  public partnerEmail?: string;
  public partnerFirstName?: string;
  public partnerLastName?: string;
  public personalId?: string;
  public phoneNumber!: string;
  public userType?: string;
}