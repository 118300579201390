export const POLICY_ZH = (textBrand: string) => {
  return [
    {
      title: '介绍',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>我们</strong>", "<strong>我们</strong>" 或 "<strong>${textBrand}</strong>") 是<a href="https://levitate.homes">https://levitate.homes</a>的运营者（下称 "<strong>网站</strong>"），我们承诺根据本条款和条件保护您的个人数据（在下文第2条中定义）。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>本隐私政策（与我们的使用条款及其中提及的任何其他文件一并）规定了我们从您收集或您提供给我们的个人数据将如何被使用、处理或披露，包括某些信息如何用于为您量身定制的广告。本隐私政策还规定了我们收集的个人数据类型，以及我们如何保护其安全，以及您如何访问或更改由我们存储的您的个人数据。本隐私政策涵盖您对网站的使用，以及您通过使用我们的产品和服务与我们建立联系或互动的任何其他方式（以下统称为 "<strong>服务</strong>"）。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>请仔细阅读以下内容，以了解我们对您的个人数据的政策和做法，以及我们将如何处理它。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>除非受适用法律限制，通过使用网站和服务，您同意我们根据本隐私政策的规定使用、处理和披露与您相关的任何和所有个人数据，该数据由我们收集或您不时提供给我们。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>为确保我们正确管理、保护和处理您的个人数据，我们将定期审查我们的政策、程序和流程。我们可能随时通过在网站上发布修订版本来修改本隐私政策。我们可能会尽力在政策修改时发出通知，但是，无论您是否收到通知，如果您继续使用网站或服务，则视为您已认可并同意修订后的隐私政策。请时常检查此页面以获取本隐私政策的更新。</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "个人信息收集",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              您可以选择浏览网站并使用其有限的功能，而无需透露您的个人信息。除非在提供所购买的服务或通过我们的网站访问的服务时必需，您无需提供个人信息作为使用网站的条件，或者在您直接与我们联系的情况下。
            </div>
            <div>
              当您使用我们的服务时，我们会收集关于您、您的设备和您与服务的互动的各种信息。其中一些信息直接识别您，或者在与其他数据结合时可用于宽泛地识别您。任何关于能够根据这些数据识别出个人的真实或虚假的数据，或者根据这些数据和组织已经或可能拥有的其他信息能够识别个人的数据，都被视为个人数据 ("<strong>个人信息</strong>")。
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>您自愿提供的信息</strong>。当您使用我们的服务时，可能会要求您提供有关您的个人信息，例如您的姓名、联系信息、支付信息、您感兴趣的住宅或物业的详细信息、财务信息、您的收藏品或风格等。例如，在以下情况下可能会发生这种情况：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>当您在网站上进行与我们相关的交易时；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>当您在网站上创建账户时；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>当您通过电子邮件、电话或亲自联系我们的客户服务或销售代表以解决您可能遇到的问题，或者寻求咨询或提出请求时；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>当您订阅我们在网站上提供的服务或者电子邮件通知和/或新闻简报时；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>当您提供或留下相关信息、反馈或内容以使用服务时；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>当您通过服务与房地产专业人士（如房地产经纪人、抵押贷款提供商、房地产管理员、投资者、建筑商或其他人）进行通信时；以及</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>当您填写其他表格或进行其他交易时，例如申请贷款的请求，背景调查（适用的话）。</div>
                    </div>
                  </div>
                  <div>
                    您也可以通过服务提供有关第三方的信息，例如，如果您通过电子邮件或短信与收件人分享房地产列表。通过提供此类信息，您声明已经获得第三方的同意，以便我们为上述隐私政策中所述的目的向我们提供他们的个人信息。
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Cookie、像素和其他跟踪机制</strong>。我们和合作伙伴使用各种技术，在您访问和使用我们的服务时自动收集信息，包括 cookie 和其他类似技术。Cookie 是可以传输到您的计算机或其他电子设备的电子信息片段，用于唯一标识您的浏览器。当您使用服务时，我们和合作伙伴可能会在您的计算机或其他电子设备上放置一个或多个 cookie 或使用其他提供类似功能的技术。
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>我们和合作伙伴可能会使用 cookie 将您在服务上的活动与我们在您的账户资料中存储的其他信息或您与我们的服务的早期互动联系起来，例如用于存储您的偏好。使用 cookie 有助于我们为您改进我们的服务质量，例如确定您最感兴趣的信息，跟踪趋势，衡量广告效果或存储您希望定期检索的信息，例如您预先选择的房产或喜欢的搜索条件，并执行其他法律法规允许的操作。您可以随时根据与您的浏览器相关的指示调整浏览器的设置，拒绝 cookie。但是，如果您选择禁用 cookie，则可能会导致服务的许多免费功能无法正常工作。</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>我们的某些页面还可能包含像素，这是电子文件，用于计算访问该页面的用户数量，跟踪时间跨度和跨不同网站的活动，确定用户与我们发送的电子邮件的互动，识别访问该页面的计算机或其他电子设备上的某些 cookie，或收集其他相关信息，这些信息可能与您的浏览器、设备识别号或 IP 地址相关联。例如，我们可能会在您查看特定服务时实施像素，以便跟踪该服务是否与您相关，并提供相关的推荐。通过这些像素和 cookie，我们收集关于您使用我们的服务的信息，包括您使用的浏览器类型、访问时间、查看的页面、您的 IP 地址以及在导航到我们的服务之前访问的页面。</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>我们还收集有关您用于访问我们的服务的计算机或移动设备的信息，例如硬件型号、操作系统和版本、唯一设备识别号、移动网络信息和浏览行为。</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>我们还允许某些第三方在您使用网站时通过 cookie 和其他技术收集您的在线活动信息。这些第三方包括（a）商业伙伴，在您在网站上查看或与他们的广告交互时收集信息，和（b）广告网络，在您查看或与他们在互联网上的多个不同网站上放置的广告交互时收集信息。这些第三方收集的信息是用于进行关于您特征、兴趣或偏好的预测，并为您的兴趣展示网站和互联网上的广告。</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>您可以通过浏览器设置管理允许的 cookie 类型，包括完全拒绝所有 cookie。有关在您的浏览器中管理 cookie 的信息，请参阅您所使用的浏览器的帮助部分。然而，如果您选择禁用 cookie，则可能导致服务的许多免费功能无法正常工作。</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>移动设备和移动浏览器信息</strong>。您可以通过调整移动设备和移动浏览器的有关 cookie 和某些信息共享的设置，例如您的移动设备型号或移动设备使用的语言，来管理移动设备和移动浏览器的设置。请参阅您的移动服务提供商或移动设备制造商提供的说明。
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>位置信息</strong>。如果您在移动设备上启用了定位服务，我们可能会在获得您的许可后收集您设备的位置信息，这些信息将用于向您提供基于位置的信息和广告。如果您希望禁用此功能，可以在移动设备上禁用定位服务。
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                  <strong>电子邮件管理</strong>。您可能会因多种原因从我们这里收到电子邮件，例如如果您通过服务执行操作、订阅定期报告，或完成了您的设计，我们会向您发送消息以跟进下一步。如果您在我们这里有账户，您可以通过账户设置更改您的偏好。此外，您可以按照我们发送给您的电子邮件中包含的说明来管理接收某些类型的通信。请注意，即使您选择不再接收某些电子邮件，我们可能仍然需要向您发送包含重要交易或管理信息的电子邮件。
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>免责声明</strong>。对于您在使用网站时自愿向其他用户披露个人信息，我们不承担任何责任。
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: '使用目的',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    您提供的个人数据可能由我们（或我们代表的第三方（如Google Analytics））在总体上或个别上使用和处理，并可与其他信息结合，目的是管理和运营网站，范围可能包括但不限于以下内容：
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>管理网站；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>通过个性化网站改善您的浏览体验；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>启用您在网站上使用的服务；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>向您提供您专门要求的服务；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>回复您在网站上电子邮件和/或提交表单的询问或请求；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>回复并跟进涉及我们提供的网站使用或任何服务的投诉；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>通过任何通信渠道（包括电子邮件、语音电话、短信和应用通知）向您发送普通商业通讯；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>执行办公室和运营管理事项；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>向第三方提供关于我们用户的统计信息。这些统计信息可用于个性化网站上的广告，但无法用于识别任何个别用户；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>创建和管理您的在线账户；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>促进您在网站上的活动和交易；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>促进您参加比赛或促销活动；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>处理您的支付或退款；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>向您提供有关您从我们处请求的服务的信息；或在您事先同意的情况下，向您发送我们认为您可能感兴趣的信息，通过电子邮件或短信；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>经营、评估和改进我们的业务（包括开发新产品和服务；分析和增强我们的服务）；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>了解我们的客户，并进行数据分析和其他处理（包括市场和消费者研究、趋势分析和匿名化）；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>回复您的评论、问题或就业申请（如有）；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>与服务提供商共享信息，以便他们为您提供服务；并根据法律规定或许可的其他情况。</div>
                      </div>
                    </div>
                  </div>
                </div>
                您可以选择在不披露您的个人数据的情况下浏览网站并使用其有限的功能。除非对我们能够通过我们的网站向您提供您购买或访问的服务、回复您的请求或您直接联系我们所需的信息，否则无需提供个人数据作为使用网站的条件。
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>由于我们可能收集、使用、披露或处理您的个人数据的目的取决于具体情况，因此这些目的可能不会在上述列出。但是，在获得您的同意时，我们将在获得您同意时通知您这些其他目的，除非根据新加坡2012年《个人数据保护法》（"<strong>PDPA</strong>"）或其他适用法律允许在未经您同意的情况下处理您的个人数据。
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>不要拨打条款。</strong> 如果您向我们提供了您的电话号码（"电话号码"），并且已经表明您同意通过电话号码收到有关我们服务的营销或其他促销信息，则我们可能会不时地使用这些电话号码与您联系，向您提供有关我们服务的信息，即使这些电话号码已在不拨打登记处（如有，根据您的各自司法管辖区）注册。然而，如果您不希望我们以此目的与您联系，您可以书面通知我们。</div>
              </div>
            </div>
          `
    },
    {
      title: '准确性和安全性',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>您应确保向我们提交的所有个人数据是完整、准确、真实和正确的。您未能这样做可能导致我们无法为您提供所请求的服务。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>如果您的个人数据可能被我们用于影响您的决定或被披露给其他组织，我们将尽合理努力确保您的个人数据准确和完整。然而，这意味着您还必须及时向我们更新您最初提供给我们的个人数据的任何更改。如果由于您未向我们更新您最初提供给我们的个人数据的任何更改而导致数据不准确或不完整，我们将不承担责任。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>我们将采取合理的技术和组织预防措施，以防止个人数据的丢失、误用或更改。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>我们将在加密格式下将您提供的所有个人数据存储在安全服务器上。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>然而，您了解通过互联网传输信息不完全安全。虽然我们将尽最大努力保护您的个人数据，但我们不能保证您传输到网站的个人数据的安全性；任何传输均由您自行承担风险。此外，对于完全归因于我们控制范围之外因素的任何第三方未经授权使用您的个人数据，我们不能承担责任。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>如果我们向您提供（或者您选择）用于访问网站某些部分的密码，您有责任保守此密码的机密性。我们要求您不要与任何人共享密码。除非您登录网站，我们永远不会要求您提供密码。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>我们建议您采取措施保护个人信息的安全，例如选择强密码并保持其私密性，以及在使用共享或不安全设备时注销用户帐户并关闭网络浏览器。</div>
          </div>
        </div>
      `
    },
    {
      title: '个人数据的披露',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>未经您的明确同意，我们不会将您的个人数据披露给第三方进行直接营销目的。披露个人数据给第三方以接收营销通信的同意是通过我们的注册表单（无论是在线还是纸质）进行的。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              为了管理和运营本网站，我们可能向我们的集团成员（即我们的附属公司和子公司）披露您的个人数据。我们还可能将您的个人数据披露给第三方，例如我们的服务提供商和专业顾问，用于以下目的：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>在任何适用法律或法规下允许的目的；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>遵守任何法律要求；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>执行或适用我们的使用条款；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>保护我们、我们的附属公司和子公司的权利、财产和安全，以及本网站其他用户和其他人的权利、财产和安全；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>与任何法律程序或拟议的法律程序有关；和</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>与企业资产交易（即组织或组织部分或组织的任何业务或资产的购买、销售、租赁、合并或合并或任何其他收购、处置或融资）有关，我们是其中一方或拟成为其中一方。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              我们未经事先获得您允许，也不会向第三方披露您的个人数据。然而，请注意，在某些情况下，我们可能在未经事先获得您允许的情况下向第三方披露您的个人数据，其中包括但不限于以下情况：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>根据适用法律和/或法规要求或授权的情况；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>在这种披露的目的明显符合您的利益，并且在适时无法获得您的同意的情况下；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>为应对威胁您或其他人的生命、健康或安全的紧急情况而披露是必要的；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>与任何调查或司法程序有关；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>将个人数据披露给任何规定的执法机构官员；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>披露为公共机构的目的且此类披露在公共利益上是必要的；和/或</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>如果PDPA或法律允许在未经您同意的情况下进行此类披露。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              当我们在未经您同意的情况下向第三方披露您的个人数据时，我们将尽一切可能要求这些第三方保护您的个人数据。
            </div>
          </div>
        </div>
      `
    },
    {
      title: '撤回同意',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>您可以通过联系我们的客户服务：<a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>来撤回您的同意。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>请注意，如果您撤回对个人数据的任何或全部同意，根据您请求的性质，我们可能无法继续向您提供我们的服务。根据您的通知，我们将告知您撤回同意的可能后果，包括但不限于终止您可能与我们签订的任何协议，并违反您的合同义务或承诺。在此类事件中，我们明确保留我们的法律权利和救济措施。</div>
              </div>
          </div>
      `
    },
    {
      title: '国际数据传输',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>我们在许多国家开展业务，并可能在这些国家之间临时存储、处理或传输您的个人数据，以使我们能够根据本隐私政策和我们的使用条款使用您的个人数据。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>我们将采取一切合理必要的措施，确保您的个人数据依据任何适用的法律或法规进行传输，并采取一切合理措施确保任何外国接收个人数据的组织受到具有法律约束力的义务，以提供至少与PDPA下保护相当的保护标准。</div>
              </div>
          </div>
      `
    },
    {
      "title": "第三方网站",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  该网站可能不时包含指向我们合作网络和供应商网站的链接。如果您点击这些网站中的链接，请注意这些网站有其自己的隐私政策，我们不对这些政策承担任何责任。在向这些网站提交任何个人数据之前，请查阅这些政策。
                </div>
              </div>
            `
    },
    {
      "title": "访问、删除和更正",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>我们的数据保护官负责确保按照本文所述程序妥善处理有关访问、删除或更正个人数据的请求。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>所有请求获取信息或对我们持有的个人数据进行修改或删除的请求将通过向我们的数据保护官提交请求的方式进行： <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>请注意，我们只接受并处理符合本隐私政策的英语请求。任何不符合以上规定的请求将被拒绝，我们不会对此类被拒绝的请求采取任何行动。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>出于PDPA规定的原因，我们可能拒绝披露、删除或更正任何个人数据。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>我们将在合理的情况下在收到请求后的十（10）个工作日内回复您的请求。如果不可能做到这一点，我们将在收到请求后的十（10）个工作日内通知您。</div>
                </div>
              </div>
            `
    },
    {
      "title": "保留您的个人数据",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>我们在有持续合法商业需要的情况下保留从您收集的个人数据。如果您是客户或供应商（或客户或供应商的代表），那么信息将保留一段时间，以使我们能够提供我们的服务，遵守适用的法律、税收或会计要求，并在必要时用于建立、行使或维护法律权利。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>当我们没有持续合法商业需要处理您的个人信息时，我们将删除或匿名化这些信息，或者如果这是不可能的（例如，因为您的个人信息已存储在备份档案中），那么我们将安全地存储您的个人信息并将其与进一步处理隔离，直到可以删除为止。</div>
                </div>
              </div>
            `
    },
    {
      "title": "联系我们",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      如果您需要有关我们对您的个人数据处理的更多信息，请随时通过以下方式联系我们的数据保护官： <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "适用法律",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  本隐私政策将受新加坡法律管辖的各个方面。
                  </div>
                </div>
              `
    }
  ]
};
