export const TERM_IN = (textBrand: string) => {
  return [
    {
      title: 'Penerimaan Persyaratan',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>1.1.</div>
            <div>Dengan mengakses, menggunakan <a href="https://levitate.homes">https://levitate.homes</a> (dalam "Situs Web"), Anda menyetujui perjanjian yang mengikat secara hukum tentang syarat dan ketentuan yang tercantum di sini ("Syarat Penggunaan") dengan kami, ${textBrand} International Pte. Ltd. ("kami", "kami", "kami" atau "${textBrand}"), pemilik dan administrator Situs Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.2.</div>
            <div>Anda menerima Syarat Penggunaan, termasuk Kebijakan Privasi, tanpa batasan atau syarat.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.3.</div>
            <div>Kami dapat mengubah Syarat Penggunaan ini kapan saja dengan memposting versi yang telah direvisi di Situs Web. Namun, pemberitahuan individual tidak akan dikirimkan kepada Anda. Setiap perubahan pada Syarat Penggunaan akan menggantikan semua versi sebelumnya. Jika Anda tidak setuju dengan perubahan atau modifikasi pada Syarat Penggunaan, Anda harus segera menghentikan penggunaan layanan apa pun di Situs Web. Setelah perubahan tersebut berlaku, kami akan menganggap penggunaan Anda yang terus berlanjut pada Situs Web sebagai penerimaan dan persetujuan Anda terhadap perubahan tersebut. Anda dapat meninjau versi terbaru dari Syarat Penggunaan kapan saja.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>1.4.</div>
            <div>Dari waktu ke waktu, kami akan memposting panduan dan aturan terkait penggunaan Situs Web. Semua panduan atau aturan tersebut secara langsung diikutsertakan dalam Syarat Penggunaan.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Penggunaan Situs Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>2.1.</div>
              <div>Anda setuju untuk mematuhi semua syarat dan ketentuan dalam Syarat Penggunaan ini dalam menggunakan Situs Web. Kami dapat menghapus atau menghapus konten apa pun yang telah Anda berikan di Situs Web jika kami, atas kebijakan mutlak kami, mencurigai bahwa Anda telah melanggar ketentuan di sini.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.2.</div>
              <div>
                Dalam melakukan transaksi apa pun dengan kami di Situs Web atau terkait dengan Situs Web, atau saat mendaftar akun dengan kami di Situs Web, Anda menjamin bahwa:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>a.</div>
                    <div>Anda secara hukum diizinkan untuk melakukan transaksi tersebut atas nama diri Anda sendiri;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>b.</div>
                    <div>Anda menggunakan identitas sesungguhnya;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>c.</div>
                    <div>Data pribadi yang Anda berikan kepada kami benar, akurat, lengkap, dan terkini; dan</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>d.</div>
                    <div>Anda akan memelihara dan segera memperbarui data pribadi Anda dan memastikan bahwa informasi tersebut tetap benar, akurat, lengkap, dan terkini.</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.3.</div>
              <div>Pengguna di bawah umur (pengguna di bawah usia yang diatur dalam undang-undang negara masing-masing) tidak memenuhi syarat untuk menggunakan Situs Web atau memberikan data pribadi apa pun di Situs Web tanpa pengawasan. Jika Anda berusia di bawah umur, Anda hanya dapat menggunakan Situs Web dan/atau memberikan data pribadi di Situs Web setelah Anda telah memperoleh izin dari orang tua/wali hukum Anda atau jika Anda berada di bawah pengawasan orang tua/wali hukum Anda.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.4.</div>
              <div>Jika kami telah memberikan Anda (atau jika Anda telah memilih) kata sandi yang memungkinkan Anda mengakses bagian-bagian tertentu di Situs Web, Anda menyadari bahwa Anda sepenuhnya bertanggung jawab atas menjaga kerahasiaan kata sandi Anda, dan semua kegiatan yang terjadi di bawah akun Anda. Kami tidak memiliki tanggung jawab untuk memverifikasi apakah pengguna yang masuk dan menggunakan Situs Web adalah Anda atau bukan.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.5.</div>
              <div>Anda setuju untuk memberitahu kami jika terjadi pelanggaran keamanan terhadap kata sandi Anda.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.6.</div>
              <div>Kami tidak akan bertanggung jawab atas kehilangan atau kerusakan yang timbul akibat ketidakpatuhan Anda terhadap Pasal 2 ini.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>2.7.</div>
              <div>Anda tidak diizinkan untuk menempatkan atau menggunakan teknik framing untuk memuatkan merek dagang, logo, atau informasi properti milik kami dan afiliasi kami (termasuk perusahaan induk, "Afiliasi") tanpa izin tertulis dari kami. Penggunaan yang tidak sah akan mengakhiri izin atau persetujuan yang diberikan oleh kami.</div>
            </div>
          
        </div>
      `
    },
    {
      title: 'Materi Situs Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>3.1.</div>
              <div>Dengan mengakses, menggunakan Situs Web, Anda mungkin terpapar pada informasi, data, teks, perangkat lunak, foto, grafik, video, pesan, konsep, ide, desain, gambar, daftar produk, harga, atau materi lainnya yang kami dan/atau pengguna lain Situs Web mungkin unggah, posting, kirim melalui email, kirimkan, atau sebaliknya tersedia di Situs Web ("Materi Situs Web") baik secara publik atau pribadi.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>3.2.</div>
              <div>Anda memahami bahwa Materi Situs Web dapat bersifat ofensif, tidak senonoh, atau tidak menyenangkan.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>3.3.</div>
              <div>Anda memahami bahwa Materi Situs Web hanya dapat digunakan untuk penggunaan pribadi dan non-komersial Anda, dan tidak untuk publikasi, distribusi, transmisi, retransmisi, redistribusi, siaran, reproduksi, atau sirkulasi kepada orang lain dalam perusahaan atau organisasi yang sama, dan tidak untuk diposting di situs web atau forum lain, grup berita, daftar milis, papan buletin elektronik, atau percakapan relay internet yang dioperasikan oleh situs web lain.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>3.4.</div>
              <div>Anda setuju untuk tidak memperbanyak, menduplikasi, menyalin, menjual, menjual kembali, menampilkan, atau menyediakan Materi Situs Web di situs web atau server lain tanpa mendapatkan persetujuan tertulis dari kami sebelumnya.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>3.5.</div>
              <div>Dalam keadaan apa pun, kami tidak bertanggung jawab atas kesalahan atau kelalaian dalam Materi Situs Web, atau atas kerugian atau kerusakan dari jenis apapun yang timbul akibat penggunaan Materi Situs Web yang diposting, dikirim melalui email, dikirimkan, atau sebaliknya tersedia di Situs Web.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>3.6.</div>
              <div>Anda mengakui dan menyetujui bahwa format dan konten Situs Web dapat diubah kapan saja tanpa pemberitahuan; operasi Situs Web dapat dihentikan atau dihentikan sementara untuk pekerjaan dukungan atau pemeliharaan, untuk memperbarui kontennya, atau atas alasan lainnya; dan akses ke Situs Web dapat dihentikan baik terhadap pengguna tertentu maupun secara umum kapan saja dan tanpa pemberitahuan.</div>
            </div>
          
        </div>
      `
    },
    {
      title: 'Akurasi Informasi di Situs Web Kami',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>4.1.</div>
              <div>Meskipun ${textBrand} berusaha dengan sungguh-sungguh untuk menyajikan informasi yang akurat di Situs Web, kami tidak memberikan jaminan atau representasi mengenai akurasi dari setiap informasi yang diberikan, baik yang tersirat maupun sebaliknya, sejauh yang diizinkan oleh hukum. ${textBrand} mengecualikan segala tanggung jawab atas kesalahan atau kelalaian dalam konten Situs Web sejauh yang diizinkan oleh hukum.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>4.2.</div>
              <div>Detail yang terdapat di Situs Web hanya bersifat panduan. Akurasi dari setiap detail, deskripsi, dimensi, referensi kondisi, izin yang diperlukan untuk penggunaan dan okupasi, dan detail lainnya yang terdapat di Situs Web tidak dijamin dan hanya bersifat panduan umum saja. Anda disarankan untuk tidak mengandalkan mereka sebagai pernyataan fakta atau representasi, serta disarankan untuk memastikan keakuratan informasi tersebut secara mandiri.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>4.3.</div>
              <div>${textBrand} menyarankan bahwa karena pembelian properti nyata adalah komitmen finansial besar, Anda harus mengambil langkah-langkah yang wajar untuk memastikan bahwa properti apa pun yang Anda hendak beli sesuai dengan kebutuhan Anda dalam segala hal. Kami telah mengambil langkah-langkah wajar untuk memastikan bahwa materi yang terdapat di Situs Web akurat, tetapi itu tidak boleh dianggap sebagai pengganti pertanyaan, pertimbangan Anda. Anda disarankan untuk mengambil langkah-langkah yang tepat untuk memverifikasi setiap informasi yang ingin Anda andalkan.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>4.4.</div>
              <div>Meskipun telah dilakukan upaya maksimal untuk memberikan representasi akurat mengenai warna dan desain setiap produk di Situs Web, warna dan desain sebenarnya dapat sedikit berbeda karena perbedaan pengaturan layar perangkat yang berbeda, pencahayaan di lokasi pemasangan, perbedaan kecil dalam hasil akhir produk dari waktu ke waktu, dan faktor-faktor lainnya. ${textBrand} tidak bertanggung jawab atas perbedaan warna atau desain yang bukan merupakan kesalahan kami. Ketika Anda membeli properti atau produk melalui Situs Web, Anda setuju untuk menerima risiko kecil bahwa akan ada sedikit variasi antara warna dan desain sebenarnya, dan representasi di Situs Web.</div>
            </div>
        </div>
      `
    },
    {
      title: 'Kewajiban Pengguna',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>5.1.</div>
              <div>
                Selain kewajiban yang dijelaskan dalam Ketentuan Penggunaan ini, Anda TIDAK BOLEH:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>a.</div>
                    <div>Menggunakan Situs Web dengan melanggar hukum atau peraturan yang berlaku;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>b.</div>
                    <div>Berperilaku atau menggunakan Situs Web untuk menyakiti, menyalahgunakan, mengganggu, mengejar, mencemarkan nama baik, mencemarkan, mengancam, atau menyakiti perasaan orang lain;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>c.</div>
                    <div>Menyebabkan atau berpotensi menyebabkan ketidaknyamanan, kerugian, atau kerusakan bagi pengguna, pihak ketiga, atau ${textBrand};</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>d.</div>
                    <div>Melakukan tindakan yang menyalahgunakan atau memegang lebih dari satu registrasi keanggotaan secara curang;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>e.</div>
                    <div>Melibatkan diri dalam aktivitas tidak bermoral atau potensial tidak bermoral, termasuk tetapi tidak terbatas pada prostitusi dan pornografi;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>f.</div>
                    <div>Menyamar sebagai orang atau entitas lain, atau dengan sengaja menyatakan atau menyajikan informasi palsu mengenai afiliasi Anda dengan orang atau entitas;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>g.</div>
                    <div>Memberikan informasi palsu, tidak akurat, atau menyesatkan;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>h.</div>
                    <div>Terlibat dalam aktivitas dan/atau transaksi yang berpotensi curang, mencurigakan, atau ilegal menurut kebijakan kami;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>i.</div>
                    <div>Mengirim email tidak diminta kepada pengguna lain atau menggunakan layanan di Situs Web untuk mengumpulkan pembayaran untuk mengirim atau membantu mengirim email tidak diminta kepada pihak ketiga;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>j.</div>
                    <div>Mengungkapkan atau mendistribusikan informasi pribadi pengguna lain kepada pihak ketiga, atau menggunakan informasi tersebut untuk tujuan pemasaran tanpa persetujuan eksplisit dari pengguna tersebut;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>k.</div>
                    <div>Mengambil tindakan yang memberikan beban yang tidak wajar atau berlebihan pada infrastruktur Situs Web;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>l.</div>
                    <div>Mengunggah, menyebabkan diunggahnya, atau membuat tersedia konten apa pun di Situs Web yang mengandung virus atau item lain yang dapat merusak, mengganggu, atau secara negatif mempengaruhi atau menghambat akses ke Situs Web;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>m.</div>
                    <div>Terlibat dalam, atau menyebabkan pengguna lain terlibat dalam, spam, phishing, aktivitas pemasaran yang tidak pantas, jahat, atau, menurut kebijaksanaan mutlak kami, mengklik, impresi, atau tindakan pemasaran yang curang terkait dengan ${textBrand};</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>n.</div>
                    <div>Menurut kebijaksanaan mutlak kami, memberikan gambaran yang buruk atau mencemarkan reputasi ${textBrand};</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>o.</div>
                    <div>Mengubah, mengadaptasi, memformat ulang, mengompilasi ulang, mengirimkan, menerbitkan, melisensikan, meretas, merakit, membongkar, merekonstruksi, mendekompilasi, menyalin, atau membuat karya turunan, mentransfer, atau menjual layanan apa pun di Situs Web atau bagian darinya, termasuk kode sumber atau algoritma, kecuali seperti yang secara eksplisit diizinkan oleh kami secara tertulis, atau sejauh yang diizinkan oleh hukum;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>p.</div>
                    <div>Mengubah, menghapus, menutupi, atau menghapuskan identifikasi, merek dagang, hak cipta, atau pemberitahuan lain dari setiap aspek layanan di Situs Web;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>q.</div>
                    <div>Menggunakan perangkat lunak otomasi, hack, modifikasi, atau perangkat lunak pihak ketiga yang tidak diotorisasi lainnya yang dirancang untuk mengakses, menjelajah, atau mengumpulkan dari layanan ${textBrand};</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>r.</div>
                    <div>Menggunakan perangkat lunak pihak ketiga yang tidak diotorisasi lainnya yang mengintersepsi, "mendulang," atau mengumpulkan informasi dari atau melalui ${textBrand};</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>s.</div>
                    <div>
                      Mengunggah, mengirim, memposting, atau membuat tersedia konten apa pun yang:
                      <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                        <div style="display: flex; gap: 0.3rem;">
                          <div>I.</div>
                          <div>Mungkin bersifat ofensif, diskriminatif, fitnah, cabul, mengancam, kasar, atau sebaliknya melanggar hukum, termasuk konten yang mungkin menyakiti, menghina, atau menghina orang lain berdasarkan ras, agama, etnisitas, gender, usia, orientasi seksual, atau cacat fisik atau mental;</div>
                        </div>
                        <div style="display: flex; gap: 0.3rem;">
                          <div>II.</div>
                          <div>Bukan karya asli Anda, atau mungkin melanggar hak kekayaan intelektual atau hak lain dari orang lain;</div>
                        </div>
                        <div style="display: flex; gap: 0.3rem;">
                          <div>III.</div>
                          <div>Mengandung gambar atau informasi pribadi orang lain kecuali jika Anda memiliki izin mereka; atau</div>
                        </div>
                        <div style="display: flex; gap: 0.3rem;">
                          <div>IV.</div>
                          <div>Mengandung konten yang bersifat repetitif, tidak diarahkan, atau tidak diinginkan dalam jumlah besar; atau</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>t.</div>
                    <div>Mengganggu, mengacaukan, atau menyebabkan beban berlebih pada infrastruktur Situs Web atau bisnis kami.</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>Jika Anda percaya bahwa seorang pengguna telah melanggar salah satu kondisi di atas, harap hubungi kami.</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>5.2.</div>
              <div>Kami berhak untuk memblokir, menangguhkan, atau membatalkan pengguna atau registrasi keanggotaan di Situs Web kami dan untuk mengubah atau menghapus setiap materi yang diunggah, diposting, dikirimkan, atau dibuat tersedia di Situs Web oleh pengguna apa pun, tanpa pemberitahuan; serta secara eksklusif menentukan dan membatasi, menolak, dan/atau menolak pengembalian dari pelanggan kapan saja karena riwayat pengembalian yang tidak wajar atau berlebihan. Dengan mengunggah, mengirimkan, memposting, atau membuat tersedia materi apa pun melalui Situs Web, Anda memberikan ${textBrand} lisensi non-eksklusif, global, bebas royalti, berjangka waktu panjang untuk menggunakan, mereproduksi, mengedit, dan mengeksploitasi materi tersebut dalam bentuk apa pun dan untuk tujuan apa pun. Aplikasi keanggotaan mungkin tidak disetujui jika pelamar pernah memiliki keanggotaannya dibatalkan di masa lalu.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>5.3.</div>
              <div>Kami tidak bertanggung jawab atas, dan tidak menerima tanggung jawab sehubungan dengan, setiap materi yang diunggah, diposting, dikirimkan, atau dibuat tersedia di Situs Web oleh orang lain selain kami. Kami tidak menyetujui pendapat, saran, atau pernyataan yang dibuat oleh orang lain selain ${textBrand}.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>5.4.</div>
              <div>Anda setuju untuk memberi ganti rugi ${textBrand} dan masing-masing pejabat, karyawan, agen, kontraktor, pemasok, dan lisensi (secara kolektif disebut "Afiliasi") ${textBrand} atas segala tanggung jawab, kerugian, atau kerusakan (termasuk semua biaya hukum dan biaya lainnya atas dasar ganti rugi penuh) yang diderita atau ditanggung oleh mereka yang timbul (seluruhnya atau sebagian) dari pelanggaran atau kegagalan untuk mematuhi salah satu dari Ketentuan Penggunaan ini, atau setiap pelanggaran atau tindakan salah dalam hubungannya dengan pokok bahasan Ketentuan Penggunaan ini, oleh Anda atau salah satu afiliasi Anda.</div>
            </div>
        </div>
      `
    },
    {
      title: 'Penafian Jaminan',
      description: `
      <div style = "display: flex; flex-direction: column; gap: 0.5rem;" >
        <div style="display: flex; gap: 0.3rem;">
            <div>6.1.</div>
            <div>Penggunaan Anda atas Situs Web ini sepenuhnya merupakan risiko Anda sendiri. Kami menolak semua jaminan dari jenis apapun, baik tersurat maupun tersirat.</div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.2.</div>
            <div>Kami berhak setiap saat dan dari waktu ke waktu untuk memodifikasi dan menghentikan, sementara atau permanen, layanan-layanan dari Situs Web ini (atau bagian dari Situs Web ini) dengan atau tanpa pemberitahuan. Anda setuju bahwa kami tidak akan bertanggung jawab kepada Anda atau pihak ketiga manapun atas setiap modifikasi, penangguhan, atau penghentian layanan-layanan tersebut.</div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.3.</div>
            <div>
                Kami tidak memberikan jaminan bahwa:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Situs Web ini akan memenuhi kebutuhan Anda;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Situs Web ini akan tidak terganggu, tepat waktu, aman, dan bebas dari kesalahan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Hasil apa pun yang dapat diperoleh dari penggunaan Situs Web ini akan akurat atau dapat diandalkan; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Kualitas produk, layanan, informasi, atau materi lain yang dibeli atau diperoleh oleh Anda melalui Situs Web ini akan memenuhi harapan Anda.</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.4.</div>
            <div>Kami tidak dapat dan tidak mendukung, dan tidak bertanggung jawab atas, pesan, pandangan, opini, penelitian, dan rekomendasi dari individu, pengguna, dan organisasi yang dianggap menarik, atau menganjurkan dukungan apa pun terhadap penyedia layanan tertentu, jalur pengobatan, atau transaksi komersial dalam efek, investasi, atau kelas efek atau investasi, dan inklusi tautan ke situs web lain pada Situs Web ini tidak menyiratkan bentuk dukungan dari kami.</div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.5.</div>
            <div>Kami tidak secara aktif meninjau atau mengedit pesan, pandangan, opini, penelitian, dan rekomendasi dari pengguna, bahkan jika pesan, pandangan, opini, penelitian, dan rekomendasi tersebut dibuat oleh pengguna menggunakan Situs Web ini. Meskipun informasi di Situs Web ini diperoleh dari sumber-sumber yang diyakini dapat diandalkan, tidak ada data, berita, informasi, laporan, opini, atau rekomendasi (jika ada) yang telah disesuaikan untuk setiap orang atau kelas orang tertentu.</div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.6.</div>
            <div>Dengan menyediakan informasi dan data di Situs Web ini, termasuk fakta, pandangan, opini, dan rekomendasi dari individu dan organisasi yang dianggap menarik, kami dan penyedia konten kami tidak memberikan atau mengaku memberikan atau mewakili atau menyatakan memberikan nasihat keuangan, investasi, pajak, dan hukum. Anda harus selalu mencari nasihat profesional yang relevan sebelum membuat keputusan tersebut.</div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.7.</div>
            <div>Kami dapat memonitor atau meninjau area-area tertentu di Situs Web ini di mana Anda mengirim atau memposting komunikasi untuk tujuan meningkatkan efisiensi, kegunaan, dan kesesuaian penggunaan. Namun, kami tidak berkewajiban untuk memantau atau meninjau setiap postingan atau komunikasi, dan kami menolak setiap dan semua tanggung jawab yang berkaitan dengan semua Materi Situs Web, baik yang timbul dari undang-undang hak cipta, merek dagang, pencemaran nama baik, privasi, pornografi, atau sejenisnya.</div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.8.</div>
            <div>
                Situs Web ini dan semua informasi yang terkandung di dalamnya disediakan oleh ${textBrand} dengan basis "sebagaimana adanya" dan "sebagaimana tersedia". ${textBrand} dan/atau afiliasi, pemegang saham, mitra, pejabat, direktur, karyawan, dan agen masing-masing:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Tidak memberikan representasi atau jaminan dari jenis apapun, baik tersurat maupun tersirat, mengenai operasi dan ketersediaan Situs Web ini atau informasi, konten, materi, atau produk yang disajikan di Situs Web ini. Anda dengan tegas setuju bahwa penggunaan Situs Web ini merupakan risiko Anda sendiri sejauh yang diizinkan oleh hukum yang berlaku;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Menolak semua jaminan, tersurat maupun tersirat, termasuk namun tidak terbatas pada jaminan tersirat akan kelayakan untuk diperdagangkan dan kesesuaian untuk tujuan tertentu;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Tidak menjamin bahwa Situs Web ini, servernya, atau email yang dikirim dari kami bebas dari virus atau komponen atau kesalahan berbahaya lainnya; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Tidak akan bertanggung jawab atas segala kerusakan dari jenis apapun termasuk, namun tidak terbatas pada kerusakan langsung, tidak langsung, insidental, hukuman, dan kerugian akibat atau tanggung jawab lainnya yang timbul dari (a) penggunaan atau ketidakmampuan menggunakan Situs Web ini; (b) setiap keputusan yang dibuat, tindakan atau kelalaian yang diambil oleh pihak manapun yang mengandalkan isi Situs Web ini; (c) gangguan bisnis; (d) keterlambatan/gangguan dalam mengakses Situs Web ini; (e) ketidak-terkirimannya, kesalahan pengiriman, korupsi, penghancuran atau modifikasi lainnya dari data; (f) kerugian atau kerusakan dari jenis apapun yang diderita sebagai akibat mengakses tautan pihak ketiga di Situs Web ini; (g) virus komputer, kegagalan atau gangguan sistem dalam hubungannya dengan penggunaan Situs Web ini; atau (h) keterlambatan, ketidakakuratan, kesalahan dalam atau penghilangan dalam konten Situs Web ini.</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; gap: 0.3rem;">
            <div>6.9.</div>
            <div>Dengan mengakses Situs Web kami, Anda menganggap semua risiko yang terkait dengan penggunaannya, termasuk tetapi tidak terbatas pada risiko bahwa komputer, perangkat, perangkat lunak, atau data Anda dapat rusak oleh virus yang ditransmisikan melalui Situs Web ini atau konten pihak ketiga atau situs web pihak ketiga, serta risiko yang terkait dengan kualitas layanan yang diberikan oleh penyedia layanan (jika ada). Sejauh yang diizinkan oleh hukum, semua jaminan, kondisi, dan klaim (baik tersurat maupun tersirat) yang timbul dari atau terhubung dengan Situs Web ini dikecualikan.</div>
        </div>
    </div >
      `
    },
    {
      title: 'Penggantian Kerugian',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Anda setuju untuk mengganti rugi dan membebaskan ${textBrand}, serta afiliasi, pemegang saham, mitra, pejabat, direktur, karyawan, dan agen kami dari setiap tuntutan atau permintaan, termasuk biaya pengacara, yang diajukan oleh pihak ketiga karena atau yang timbul dari konten apa pun yang Anda ajukan, posting, kirim, atau buat tersedia di Situs Web, penggunaan Anda atas Situs Web, hubungan Anda dengan Situs Web, pelanggaran Anda terhadap Ketentuan Penggunaan, atau pelanggaran Anda terhadap hak-hak pihak lain.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Batasan Tanggung Jawab',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Kami tidak akan bertanggung jawab kepada Anda atas kerugian langsung, tidak langsung, kebetulan, khusus, konsekuensial, atau ganti rugi yang luar biasa, termasuk namun tidak terbatas pada kerugian atas keuntungan, reputasi baik, penggunaan, data, atau kerugian immaterial lainnya (bahkan jika kami telah diinformasikan tentang kemungkinan kerugian tersebut), yang diakibatkan oleh:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Penggunaan atau ketidakmampuan menggunakan Situs Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Biaya pengadaan produk dan layanan pengganti yang dihasilkan dari produk, data, informasi, atau layanan yang dibeli atau diperoleh atau pesan yang diterima, atau transaksi yang dilakukan melalui atau dari Situs Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Akses tanpa izin atau perubahan atas transmisi atau data Anda;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Pernyataan atau perilaku pihak ketiga di Situs Web; dan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Masalah lain yang terkait dengan Situs Web.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Setiap tuntutan dari segala jenis oleh salah satu pihak terhadap pihak lain dan setiap tindakan terhadap pihak lain harus dimulai dalam waktu 03 bulan (atau periode minimum yang diperbolehkan sesuai dengan hukum yang berlaku) setelah tanggal terjadinya sebab tindakan, setelah itu pihak yang dirugikan tidak memiliki klaim lebih lanjut terhadap pihak lain.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Penghubungan dan Isi Pihak Ketiga',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>9.1</div>
                <div>Situs Web dapat mengandung tautan hypertext ke situs web yang dioperasikan oleh pihak ketiga hanya untuk tujuan referensi dan informasi. ${textBrand} tidak meninjau dan tidak bertanggung jawab atas kebijakan privasi dan praktik situs web lainnya, bahkan jika Anda mengaksesnya menggunakan tautan dari Situs Web, dan kami menyarankan agar Anda memeriksa syarat dan ketentuan serta kebijakan privasi dari setiap situs web yang Anda kunjungi dan menghubungi pemilik atau operatornya jika Anda memiliki kekhawatiran atau pertanyaan. Demikian pula, jika Anda menghubungkan Situs Web dari situs web pihak ketiga, ${textBrand} tidak bertanggung jawab atas kebijakan privasi dan praktik pemilik atau operator situs web pihak ketiga tersebut, dan kami menyarankan agar Anda memeriksa kebijakan situs web pihak ketiga tersebut serta menghubungi pemilik atau operatornya jika Anda memiliki kekhawatiran atau pertanyaan. ${textBrand} tidak memberikan jaminan atau representasi mengenai situs web pihak ketiga yang dihubungkan. ${textBrand} mengecualikan semua tanggung jawab atas kerugian atau kerusakan sejauh diizinkan oleh hukum yang berlaku yang timbul sehubungan dengan konten di situs web tersebut.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.2</div>
                <div>Untuk menghindari keraguan, keberadaan tautan ke situs web pihak ketiga tidak akan dianggap sebagai rekomendasi atas situs web tersebut atau sebagai saran atau informasi apa pun oleh ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Kebijakan Privasi',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>Semua data pribadi tentang Anda tunduk pada Kebijakan Privasi kami.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>Kebijakan Privasi dianggap menjadi bagian dari Ketentuan Penggunaan melalui referensi pada Klausul ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.3.</div>
                <div>Jika pengguna Situs Web, atau pihak lain, melihat informasi dokumen yang dipublikasikan oleh ${textBrand} dan memberikan tanggapan berupa informasi atau umpan balik seperti pertanyaan, komentar, saran, atau sejenisnya, maka informasi tersebut dianggap tidak bersifat rahasia dan ${textBrand} berhak untuk menggunakan, mereproduksi, mengungkapkan, dan mendistribusikan informasi tersebut kepada pihak lain.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.4.</div>
                <div>Lebih lanjut, ${textBrand} berhak untuk menggunakan atau memanfaatkan ide, konsep, pengetahuan, atau teknik yang terkandung dalam informasi/umpan balik tersebut untuk tujuan apa pun tanpa dikenakan biaya, termasuk namun tidak terbatas pada pengembangan, pembuatan, dan pemasaran produk yang mencakup informasi tersebut.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Kekayaan Intelektual dan Hak Cipta',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Anda mengakui dan menerima bahwa semua hak cipta, hak basis data, dan hak kekayaan intelektual lain yang ada dalam desain, tata letak, proses, fungsi, data, dan konten dari Situs Web adalah milik ${textBrand}, penyedia informasi kami, atau pemberi lisensi kami. Situs Web dimiliki dan dioperasikan oleh ${textBrand}. Tidak ada bagian dari Situs Web, kontennya, atau perangkat lunak dan basis data dasarnya yang boleh disalin atau direproduksi, dibagikan dengan pihak ketiga, dipecahkan secara terbalik, diambil secara terbalik, atau digunakan untuk membuat karya turunan apa pun (baik dalam bentuk fisik atau elektronik) tanpa persetujuan tertulis sebelumnya dari ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Force Majeure',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Kami tidak bertanggung jawab atas keterlambatan atau kegagalan dalam melaksanakan kewajiban kami kepada Anda karena alasan di luar kendali kami, termasuk namun tidak terbatas pada bencana alam, perang atau terorisme, wabah, pandemi, bencana alam, masalah koneksi internet, masalah sistem, perubahan dalam hukum yang berlaku, peraturan, atau kebijakan pemerintah, dan kekurangan pasokan dan layanan.
              </div>
          </div>
        `
    },
    {
      title: 'Pengakhiran',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Dalam keadaan tertentu dan tanpa pemberitahuan sebelumnya, kami berhak sepenuhnya dan atas kebijaksanaan kami segera mengakhiri akun Anda (jika ada). Alasan untuk pengakhiran tersebut dapat mencakup, namun tidak terbatas pada:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Pelanggaran terhadap Ketentuan Penggunaan, atau dokumen, pedoman, atau aturan lain yang disertakan;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Permintaan dari penegak hukum atau lembaga pemerintah lainnya;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Penghapusan akun atas inisiatif sendiri;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Pelanggaran hak kekayaan intelektual milik orang lain;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Penghentian atau modifikasi material pada layanan di Situs Web, atau sebagian daripadanya;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Masalah teknis atau keamanan yang tak terduga; atau</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Periode ketidakaktifan yang panjang.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Pengakhiran akun Anda akan menyebabkan:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Penghapusan akses dan larangan penggunaan selanjutnya dari semua penawaran Situs Web yang terkait dengan akun tersebut; dan</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Penghapusan kata sandi Anda dan semua informasi, file, dan konten terkait dalam akun Anda (atau bagian daripadanya).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Pengakhiran akun Anda tidak mempengaruhi hak atau upaya hukum lain yang kami miliki berdasarkan Ketentuan Penggunaan, hukum, atau keadilan, dan tidak mempengaruhi hak atau kewajiban yang sudah ada maupun ketentuan yang secara tegas atau dengan implikasi dimaksudkan untuk berlaku atau tetap berlaku setelah pengakhiran tersebut.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Hak Kekayaan Intelektual',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Dengan mengunggah gambar, foto, dan/atau deskripsi daftar apapun ke Situs Web ("<strong>Konten</strong>"), Anda memberikan kami lisensi global, bebas royalti, tidak dapat dicabut untuk menggunakan, menyimpan, menyimpan dalam server, mereproduksi, mengubah, menyesuaikan, memberi tanda air, berkomunikasi, menerbitkan, melakukan publikasi, menampilkan secara publik, membuat karya turunan, dan mendistribusikan Konten tersebut. Dengan mengunggah Konten, Anda menjamin bahwa Anda memiliki hak cipta atas Konten tersebut dan dengan demikian memiliki hak yang diperlukan untuk memberikan lisensinya kepada kami sebagaimana dinyatakan. Kami berhak memberi tanda air pada foto dalam Konten, dan hak cipta dalam foto terakhir yang telah diberi tanda air akan sepenuhnya menjadi milik kami. Semua nama, produk, dan merek yang disebutkan adalah hak kekayaan intelektual pemilik masing-masing. Tidak ada materi yang disediakan melalui Situs Web, termasuk teks, grafik, kode, peringkat, peringkat, basis data, informasi agregat, Materi Situs Web dan/atau perangkat lunak yang dapat direproduksi, dimodifikasi, disesuaikan, didistribusikan, diterbitkan, ditampilkan, diunggah, diposting, ditransmisikan, atau dihiperlink dalam bentuk dan cara apapun tanpa persetujuan tertulis kami sebelumnya, serta persetujuan dari pemegang hak cipta dan merek dagang terkait. Situs Web dan Materi Situs Web hanya untuk penggunaan pribadi Anda, dan Anda tidak boleh terlibat dalam transaksi dengan pihak lain terkait layanan dan konten tersebut. Transaksi tersebut mencakup transaksi komersial dan transaksi yang akan merugikan nilai komersial Situs Web dan Materi Situs Web.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Umum',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Ketentuan Penggunaan akan diatur dan ditafsirkan sesuai dengan hukum Republik Singapura, dan segala klaim atau sengketa apa pun akan tunduk pada yurisdiksi eksklusif pengadilan Republik Singapura.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Kegagalan kami untuk mengenakan atau menegakkan hak atau ketentuan dalam Ketentuan Penggunaan tidak akan dianggap sebagai pengabaian terhadap hak atau ketentuan tersebut. Jika ada ketentuan dalam Ketentuan Penggunaan yang dinyatakan tidak sah oleh pengadilan yang berwenang, para pihak setuju bahwa pengadilan tersebut harus berusaha memberlakukan niat para pihak sebagaimana tercermin dalam ketentuan tersebut, dan ketentuan-ketentuan lain dalam Ketentuan Penggunaan tetap berlaku sepenuhnya.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Akun apa pun yang Anda miliki bersama kami tidak dapat dipindahkan, dan hak atas akun tersebut akan berakhir jika Anda meninggal dunia. Setelah menerima salinan akta kematian Anda, akun Anda akan dihentikan dan semua konten yang Anda hasilkan dalam akun Anda akan dihapus secara permanen.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Semua pemberitahuan kepada kami sesuai dengan Ketentuan Penggunaan harus dikirim melalui email ke: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Penggunaan Website',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>Anda setuju untuk mematuhi semua syarat dan ketentuan dalam Ketentuan Penggunaan ini ketika menggunakan Website. Kami dapat menghapus atau menghapus konten apa pun yang Anda berikan di Website jika kami, atas kebijakan mutlak kami, mencurigai bahwa Anda telah melanggar ketentuan di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  Dalam melakukan transaksi apa pun dengan kami di Website atau terkait dengan Website, atau mendaftar akun dengan kami di Website, Anda menjamin bahwa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Anda memiliki izin hukum untuk melakukan transaksi sendiri;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Anda menggunakan identitas sesungguhnya;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Data pribadi yang Anda berikan kepada kami benar, akurat, lengkap, dan terkini; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Anda akan menjaga dan segera memperbarui data pribadi Anda dan memastikan bahwa informasi tersebut tetap benar, akurat, lengkap, dan terkini.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>Pengguna di bawah umur (pengguna di bawah batas usia yang diatur dalam hukum negara masing-masing) tidak memenuhi syarat untuk menggunakan Website atau memberikan data pribadi apa pun di Website tanpa pengawasan. Jika Anda di bawah umur, Anda dapat menggunakan Website dan/atau memberikan data pribadi di Website hanya setelah Anda memperoleh persetujuan dari orang tua/wali hukum Anda atau jika Anda di bawah pengawasan orang tua/wali hukum Anda.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>Jika kami telah memberikan Anda (atau jika Anda telah memilih) kata sandi yang memungkinkan Anda mengakses bagian-bagian tertentu dari Website, Anda mengakui bahwa Anda sepenuhnya bertanggung jawab untuk menjaga kerahasiaan kata sandi Anda, dan semua aktivitas yang terjadi di bawah akun Anda. Kami tidak bertanggung jawab untuk memverifikasi apakah pengguna yang masuk dan menggunakan Website adalah Anda atau bukan.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>Anda menyetujui untuk memberi tahu kami tentang setiap pelanggaran keamanan terhadap kata sandi Anda.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>Kami tidak akan bertanggung jawab atas kehilangan atau kerusakan yang timbul akibat ketidakpatuhan Anda terhadap Klausul 2 ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.7.</div>
                <div>Anda tidak dapat menggunakan teknik framing atau menggabungkan teknik framing untuk menyertakan merek dagang, logo, atau informasi milik kami dan afiliasi kami (termasuk perusahaan induk, "Afiliasi") tanpa persetujuan tertulis dari kami. Penggunaan tanpa izin akan mengakhiri izin atau persetujuan yang diberikan oleh kami.</div>
              </div>
            
          </div>
        `
    }
  ]
};
