export const TERM_FIL = (textBrand: string) => {
  return [
    {
      title: 'Pagtanggap sa mga Tuntunin',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Sa pamamagitan ng pag-access at paggamit ng <a href="https://levitate.homes">https://levitate.homes</a> (ang "Website"), ikaw ay nakikipagkasundo sa legal na nakabind na kasunduan sa mga tuntunin at kondisyon dito ("Mga Tuntunin ng Paggamit") sa amin, ${textBrand} International Pte. Ltd. ("kami", "atin", "aming" o "${textBrand}"), ang may-ari at tagapamahala ng Website.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Tumatanggap ka nang walang anumang limitasyon o kondisyon sa Mga Tuntunin ng Paggamit, kasama na ang Patakaran sa Privacy.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Maaari naming baguhin ang Mga Tuntunin ng Paggamit anumang oras sa pamamagitan ng pag-post ng isang bagong bersyon sa Website. Gayunpaman, hindi ipapadala sa iyo ang indibidwal na abiso. Ang anumang binagong Mga Tuntunin ng Paggamit ay papalit sa lahat ng naunang bersyon nito. Kung hindi ka sumasang-ayon sa mga pagbabago o pagbabago sa Mga Tuntunin ng Paggamit, dapat mong kaagad itigil ang paggamit ng anumang mga serbisyo sa Website. Pagkatapos maging epektibo ang anumang pagbabago, ituturing naming ang iyong patuloy na paggamit ng Website bilang pagtanggap at pagsang-ayon mo sa mga pagbabago na iyon. Maaari mong tingnan ang pinakabagong bersyon ng Mga Tuntunin ng Paggamit anumang oras.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Mula sa panahon hanggang sa panahon, ilalathala namin sa Website ang mga gabay at mga patakaran na may kinalaman sa paggamit ng Website. Ang lahat ng mga ganitong gabay o patakaran ay itinataguyod dito sa Mga Tuntunin ng Paggamit.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Paggamit ng Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>Sang-ayon ka na sumunod sa lahat ng mga tuntunin at kundisyon sa ilalim ng Kasunduan ng Paggamit na ito sa paggamit ng Website. Maaari naming tanggalin o burahin ang anumang nilalaman na iyong ibinigay sa Website kung kami, sa aming lubos na pagpapasya, ay may hinala na nilabag mo ang mga kondisyon dito.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>
              Sa paggawa ng anumang mga transaksiyon sa amin sa o may kaugnayan sa Website o pagpaparehistro ng account sa amin sa Website, ikaw ay nagwawasto na:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Ikaw ay legal na pinapayagang gawin ang transaksiyon sa iyong sarili;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Ginagamit mo ang iyong tunay na pagkakakilanlan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Ang personal na datos na iyong ibinigay sa amin ay totoo, tama, kumpleto, at kasalukuyan; at</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Iyo nangangalagaan at agaran na binabago ang iyong personal na impormasyon at tinitiyak na ang gayong impormasyon ay nananatiling totoo, tama, kumpleto, at kasalukuyan.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Ang mga menor de edad (mga user na nasa ilalim ng edad na itinatakda ng batas ng iyong sinaliksik na bansa) ay hindi karapat-dapat na gamitin ang Website o magbigay ng anumang personal na datos sa Website ng walang pagbabantay. Kung ikaw ay menor de edad, maaari kang gumamit ng Website at/o magbigay ng anumang personal na datos sa Website lamang pagkatapos mong makakuha ng pahintulot mula sa iyong mga magulang/o tagapag-alaga o kung ikaw ay nasa ilalim ng pangangalaga ng iyong mga magulang/o tagapag-alaga.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>Kung ibinigay namin sa iyo (o kung pinili mo) ang isang password na nagpapahintulot sa iyo na mag-access sa ilang bahagi ng Website, kinikilala mo na ikaw ay ganap na responsable sa pag-iingat ng pagiging kumpidensyal ng iyong password, at sa lahat ng mga aktibidad na nagaganap sa ilalim ng iyong account. Wala kaming responsibilidad na patunayan kung ikaw ba ang gumagamit na nag-log in at gumagamit ng Website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.5.</div>
            <div>Sang-ayon ka na ipaalam sa amin ang anumang paglabag sa seguridad ng iyong password.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.6.</div>
            <div>Wala kaming pananagutan para sa anumang pagkawala o pinsala na nagmumula sa hindi pagsunod mo sa Seksyon 2 na ito.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.7.</div>
            <div>Hindi ka pinapayagan na mag-frame o gumamit ng mga teknik ng pag-framing para makuhang lulan ang anumang aming at aming mga kaakibat na (kasama na ang kumpanya ng magulang, "Kaakibat") trademark, logo, o iba pang pagmamay-ari na impormasyon (kasama na ang mga larawan, teksto, page layout, form, deskripsyon, konsepto, ideya, disenyo, guhit, larawan, mga litrato, o anumang iba pang nilalaman) nang walang malinaw na nakasulat na pahintulot mula sa amin. Ang anumang hindi awtorisadong paggamit ay magpapatapos sa pahintulot o pahintulot na ibinigay ng amin.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Materyal ng Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Sa pamamagitan ng pag-access at paggamit sa Website, maaaring maharap ka sa impormasyon, data, teksto, software, mga litrato, grapiks, mga video, mensahe, konsepto, ideya, disenyo, mga guhit, listahan ng produkto, presyo, o iba pang materyal na maaaring i-upload, i-post, ipadala sa email, ipasa, o ibang paraan maipagamit sa Website ("Materyal ng Website"), maging pampubliko o pribado.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Naiintindihan mo na ang Materyal ng Website ay maaaring nakasasakit ng damdamin, hindi kanais-nais, o nakakabastos.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Naiintindihan mo na ang Materyal ng Website ay maaaring gamitin lamang para sa iyong personal at hindi-komersyal na gamit, at hindi para sa paglalathala, pamamahagi, pagpapadala, pagpapadala muli, pagbabahagi, pagreproduksyon, o paglipat nito sa ibang tao sa parehong kumpanya o organisasyon, at hindi para ilathala sa ibang mga website o mga forum, mga grupong pang-balita, mga listahan ng email, mga bulletin board sa electronic, o internet relay chats na pinamamahalaan ng ibang mga website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Sumasang-ayon ka na hindi i-reproduksi, i-duplicate, kopyahin, ibenta, i-resell, ipakita, o ibigay sa ibang paraan ang Materyal ng Website sa ibang website o server nang walang aming pahintulot na nakasulat.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Hindi kami, sa anumang kalagayan, mananagot para sa anumang mga pagkakamali o kakulangan sa anumang Materyal ng Website, o para sa anumang pagkawala o pinsalang anumang uri na naranasan dahil sa paggamit ng anumang Materyal ng Website na i-post, ipinadala, ipinasa, o ibang paraan maipagamit sa Website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div:Pinapahalagahan mo at sumasang-ayon na maaaring baguhin ang format at nilalaman ng Website anumang oras nang walang abiso; ang operasyon ng Website ay maaaring itigil o itigil para sa suporta o trabaho sa pagmamanteni, upang i-update ang nilalaman nito o sa anumang iba pang dahilan; at ang access sa Website ay maaaring tapusin, alinsunod sa partikular na mga user o pangkalahatan, anumang oras at walang abiso.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Accuracy ng Impormasyon sa Aming Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Bagaman gumagawa ng makatwirang pagsisikap ang ${textBrand} upang isama ang tumpak na impormasyon sa aming website, walang mga warranty o representasyon hinggil sa tumpak ng anumang impormasyon na ibinigay, kung ito man ay ipinahiwatig o hindi, hanggang sa saklaw ng batas. Inihihiwalay ng ${textBrand} ang lahat ng pananagutan para sa anumang mga error o pagkalimot sa nilalaman ng website hanggang sa pinakamalawak na saklaw na pinapayagan ng batas.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Ang mga detalye na matatagpuan sa website ay para lamang sa layuning panggabay. Ang tumpak na impormasyon ng anumang mga detalye, paglalarawan, dimensyon, sanggunian sa kalagayan, kinakailangang pahintulot para sa paggamit at okupasyon, at iba pang mga detalye na matatagpuan sa website ay hindi pinaniniwalaang tumpak at para lamang sa pangkalahatang layunin ng panggabay. Pinapayuhan kang hindi umasa sa mga ito bilang mga pahayag ng katotohanan o representasyon at mas mabuting tiyakin ang kanilang tumpak na impormasyon.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Ipinauukit ng ${textBrand} na dahil ang pagbili ng mga ari-arian na totoo ay isang malaking pangangalakal, dapat kang gumawa ng lahat ng makatwirang hakbang upang matiyak na ang anumang ari-arian na iyong balak bilhin ay angkop para sa iyong mga pangangailangan sa lahat ng aspeto. Kinuha namin ang makatwirang hakbang upang matiyak na ang mga materyales na makikita sa website ay tumpak, ngunit hindi ito dapat gawing kapalit ng iyong mga pagtatanong o pag-iisip. Pinapayuhan kang gumawa ng mga angkop na hakbang upang patunayan ang anumang impormasyon na nais mong pagkatiwalaan.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Kahit na may lahat ng pagsisikap na magbigay ng tumpak na representasyon ng kulay at disenyo ng bawat produkto sa aming website, maaaring may bahagyang pagkakaiba ang tunay na kulay at disenyo, dahil sa iba't ibang mga setting ng screen ng aparato, ang ilaw sa lugar ng pag-install, ang bahagyang pagkakaiba-iba sa pagtatapos ng produkto sa paglipas ng panahon, at iba pang mga kadahilanan. Hindi tatanggapin ng ${textBrand} ang pananagutan para sa anumang pagkakaiba sa kulay o disenyo na hindi namin pagkakasala. Kapag binili mo ang isang ari-arian o produkto gamit ang aming website, sumasang-ayon ka sa maliit na panganib na maaaring magkaroon ng bahagyang pagkakaiba sa pagitan ng tunay na kulay at disenyo at ang representasyon sa aming website.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Obligasyon ng Gumagamit',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Bukod sa mga obligasyong nakasaad sa mga Tuntunin ng Paggamit na ito, HUWAG kang gumawa ng sumusunod:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Gamitin ang Website nang labag sa anumang mga batas o regulasyon na may bisa;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Kumilos sa paraang nagdudulot ng pinsala, pang-aabuso, pagha-harass, panunukso, pagmumura, paninirang-puri, pagbabanta, o anumang paraan ng paglapastangan sa iba;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Gumawa ng mga hakbang na nagdudulot, o maaaring magdulot, ng inkompyensiya, kapinsalaan, o pinsala sa mga user, third party, o ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Gumawa ng mga aktong pandaraya sa pag-apply o pagkakaroon ng maramihang mga paga-register sa pagiging miyembro;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Engage sa anumang imoral o potensyal na imoral na gawain, kabilang ngunit hindi limitado sa prostitusyon at pornograpiya;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Magtangkang magkunwari bilang ibang tao o entidad, o huwag totoong sabihin o hindi wastong ipahayag ang iyong kaugnayan sa isang tao o entidad;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Magbigay ng mga pekeng, hindi wasto, o nakalilitong impormasyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Engage sa mga potensyal na pandarayang, kaduda-dudang, o ilegal na gawain at/o transaksyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Magpadala ng mga hindi hinihiling na email sa anumang user o gamitin ang mga serbisyo sa Website upang mangolekta ng mga pagbabayad para sa pagpapadala, o pagtulong sa pagpapadala ng mga hindi hinihiling na email sa mga third party;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Isapubliko o ipamahagi ang personal na impormasyon ng ibang user sa isang third party, o gamitin ang gayong impormasyon para sa layuning pang-marketing nang walang pahintulot na pahayag mula sa nasabing user;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Gawin ang anumang hakbang na nagdadala ng hindi makatwirang o labis na malaking pasanin sa imprastraktura ng Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>I-upload, o sanhi na i-upload, ang anumang nilalaman sa Website na naglalaman ng mga virus, o anumang ibang bagay na maaaring magdulot ng pinsala, makialam, o makapanira sa pag-access sa Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Engage sa, o sanhi na makilahok ang ibang mga user sa, spamming, phishing, hindi tamang, mapanirang pag-uugali, o, sa aming ganap na pagpapasya, pandarayang pag-click, impressions o mga aktibidad sa pang-mamarketing na may kaugnayan sa ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>Na sa aming ganap na pagpapasya, makapagdudulot ng masamang imahe o sirain ang reputasyon ng ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Baguhin, bagayin, ayusin muli, muling kompilin, ipasa, i-publish, lisensyahan, reverse engineer, disasemble, gawing muli, i-decompile, kopyahin, o lumikha ng mga likas na gawain ng mga serbisyo sa Website o bahagi nito, kasama ang mga source code o algorithm, maliban kung malinaw na awtorisado namin ito sa pagsusulat, o kung pinapahintulutan ng batas;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Baguhin, alisin, takpan, o anumang paraan na sirain ang anumang pagkilala, tatak, karapatan ng may-ari, o iba pang paunawa mula sa anumang aspeto ng mga serbisyo sa Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Gamitin ang anumang software ng otomasyon, mga hack, mod, o anumang hindi awtorisadong software ng third party na dinisenyo upang mag-access, mag-crawl, o mangolekta mula sa mga serbisyo ng ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Gamitin ang anumang hindi awtorisadong software ng third party na nag-iintercept, "mina" o sa ibang paraan nakokolekta ang impormasyon mula sa o sa pamamagitan ng ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    I-upload, ilipat, ipost, o sa anumang paraan ay maglingkod ng anumang nilalaman na, o maaaring maaasahan nang makatwirang:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Na maging nakasasakit, diskriminatoryo, nagdudulot ng pagdudulit, obscenity, pagbabanta, pang-aabuso, indecent, o iba pang paraan na labag sa batas, kabilang ang nilalaman na maaaring maging nakasasakit, nagbibigay-insulto o nakakahiya sa ibang tao batay sa lahi, relihiyon, etnisidad, kasarian, edad, seksuwal na pag-orientasyon o anumang pisikal o mental na kapansanan;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>Na hindi ang iyong orihinal na gawain, o maaaring labag sa karapatan sa intellectual property o iba pang karapatan ng ibang tao;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Na maglaman ng larawan o personal na impormasyon ng ibang tao maliban kung may kanilang pahintulot; o</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>Na maglaman ng malalaking halaga ng hindi target, hindi hinihiling, o paulit-ulit na nilalaman; o</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Mangialam, manggulo, o lumikha ng labis na pasanin sa imprastraktura ng Website o sa aming negosyo.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Kung naniniwala ka na nilabag ng isang user ang alinman sa mga nakalista na kondisyon, mangyaring makipag-ugnayan sa amin.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Itinatago namin ang karapatan na harangin, suspindihin, o kanselahin ang anumang user, pagrerehistro bilang miyembro sa aming Website, at baguhin o tanggalin ang anumang materyal na i-upload, ipost, ipasa, o anumang iba pang paraan na maaaring magamit sa Website ng anumang user, nang walang abiso; itinataguyod, at kinokondena ang mga pagsusuri mula sa mga customer sa anumang oras dahil sa hindi regular o labis na maraming kasaysayan ng pagsusuri. Sa pamamagitan ng pag-upload, pagpapasa, pagpo-post, o anumang iba pang paraan na magagamit ang anumang materyal sa pamamagitan ng Website, ipinagkakaloob mo sa ${textBrand} ang isang hindi-eksklusibong lisensya, pandaigdig, walang royalty, at permanenteng lisensya upang gamitin, palitan, at paunlarin ang materyal sa anumang anyo at para sa anumang layunin. Ang aplikasyon para sa pagiging miyembro ay maaaring hindi maaprubahan kung ang aplikante ay mayroon nang dating kanselasyon ng kanyang pagiging miyembro.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Hindi kami responsable at hindi kami tumatanggap ng pananagutan sa anumang materyal na i-upload, ipost, ipasa, o anumang iba pang paraan na magagamit sa Website ng anumang tao maliban sa amin. Hindi namin sinusuportahan ang anumang opinyon, payo, o pahayag na gawa ng sinumang tao maliban sa ${textBrand}.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Sumasang-ayon ka na gantimpalaan ang ${textBrand} at bawat opisyal, kawani, ahente, kontraktor, supplier, at lisensyador (tinutukoy bilang "Mga Kaugnay na Kumpanya") ng ${textBrand} mula sa anumang pananagutan, kawalan o pinsala (kasama ang lahat ng legal at iba pang mga gastos sa kabuuan) na dinanas o naranasan nila na sanhi (sa kabuuan o bahagi) ng paglabag o pagkabigo sa anumang mga Tuntunin ng Paggamit na ito, o anumang iba pang pagkukulang o maling paggawi kaugnay ng paksa ng mga Tuntunin ng Paggamit na ito, sa iyong bahagi o sa bahagi ng iyong mga kaugnay na kumpanya.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Pahayag Tungkol sa Mga Garantiya',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Ang paggamit mo ng Website ay nasa iyong sariling kagustuhan at kagustuhan. Kami ay hindi nagbibigay ng anumang uri ng garantiya, maging ito man ay tuwiran o hindi tuwirang ipahayag.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Pinanatili namin ang karapatan sa anumang oras at mula sa panahon hanggang sa panahon na baguhin at itigil, pansamantalang o permanente, anumang mga serbisyo ng Website (o bahagi nito) nang may o walang abiso. Sumasang-ayon ka na hindi kami mananagot sa iyo o sa anumang ikatlong partido para sa anumang pagbabago, pagpapahinto, o pagtanggal ng mga naturang serbisyo.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Walang garantiya ang aming ibinibigay na:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Ang Website ay tutugma sa iyong mga pangangailangan;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Ang Website ay hindi madudugtungan, may oras, ligtas at walang error;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Anumang mga resulta na maaaring makuha mula sa paggamit ng Website ay wasto o maaasahan; at</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Ang kalidad ng anumang mga produkto, serbisyo, impormasyon, o iba pang materyal na binili o nakuha mo sa pamamagitan ng Website ay tutugma sa iyong mga inaasahan.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Hindi namin at hindi rin namin sinusuportahan, at hindi rin kami responsable sa mga mensahe, pananaw, opinyon, pananaliksik, at rekomendasyon ng mga indibidwal, user, at organisasyon na may kinalaman, o ipinag-aadvocate ang pagtangkilik sa anumang partikular na tagapagkaloob ng serbisyo, landas ng paggamot o mga komersyal na transaksyon sa anumang mga sekuriti, pamumuhunan, o uri ng mga sekuriti o pamumuhunan, o ang pagkakasama ng isang link sa ibang website(s) o mga mapagkukunan ay hindi nangangahulugan ng anumang uri ng pag-endorso mula sa amin.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Hindi namin aktibong sinusuri o ini-edit ang mga mensahe, pananaw, opinyon, pananaliksik, at rekomendasyon ng mga user, kahit na ang mga mensahe, pananaw, opinyon, pananaliksik, at rekomendasyong ito ay ginawa ng mga user gamit ang Website. Bagaman ang impormasyon sa Website ay kinuha mula sa mga pinaniniwalaang mapagkakatiwalaang mga pinagmulan, wala sa mga datos, balita, impormasyon, ulat, opinyon, o anumang mga rekomendasyon (kung meron man) ang isinasapersonal sa alinmang partikular na tao o klase ng mga tao.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Sa pamamagitan ng pagbibigay ng impormasyon at data sa Website, kabilang ang mga katotohanan, pananaw, opinyon, pananaliksik, at rekomendasyon ng mga indibidwal at organisasyon na may kinalaman, hindi kami at ang aming mga tagapagbigay ng nilalaman ay nagbibigay o nagpapakilala ng pagbibigay ng mga patakaran ng pananalapi, pamumuhunan, buwis, at legal na payo. Kailanman ay dapat kang humingi ng angkop na propesyonal na payo bago gumawa ng anumang desisyon.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>Maaari naming bantayan o suriin ang anumang mga lugar sa Website kung saan mo ipinadala o ipinaskil ang mga komunikasyon upang mapalaganap ang kahusayan, kagamitan, at angkop na paggamit nito. Gayunpaman, hindi kami nagsasang-ayon na bantayan o suriin ang bawat pagpapaskil o komunikasyon, at kami ay nagkakaila ng anumang uri ng pananagutan kaugnay ng lahat ng Materyal ng Website, maging ito man ay nagmula o hindi nagmula sa batas ng karapatan ng may-ari, mga trademark, paninirang-puri, privacy, obscenity, o anumang iba pang batas.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Ang Website na ito at lahat ng impormasyon na naroroon ay ibinibigay ng ${textBrand} batay sa "kung ano ang mayroon" at "kung ano ang available" na batayan. Ang ${textBrand} at/o ang kani-kanilang mga kaakibat na affiliates, mga shareholder, partners, mga opisyal, direktor, empleyado, at mga ahente:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Walang binibigay na mga representasyon o garantiya ng anumang uri, tuwiran man o hindi tuwiran, patungkol sa operasyon at kahandaan ng Website o ang impormasyon, nilalaman, materyal, o mga produkto na inilalahad sa Website. Pinanunumpaan mo na ang iyong paggamit ng Website ay nasa iyong sariling risk hanggang sa buong saklaw ng batas na naaangkop;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Inaalis ang lahat ng garantiya, tuwiran man o hindi tuwiran, kabilang ang mga tuwirang garantiya ng kalakalan at kahusayan para sa partikular na layunin;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Walang katiyakan na ang Website, ang mga server nito, o ang mga email na ipinadala mula sa amin ay walang mga virus o iba pang masamang bahagi o error; at</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Hindi mananagot sa anumang pinsalang anumang uri, kabilang ngunit hindi limitado sa direktang, hindi direktang, insidental, pinsalang-panghalip, at consequential na mga pinsala o anumang iba pang mga pagkawala o pananagutan na nagmumula mula sa (a) ang paggamit o hindi paggamit ng Website; (b) anumang desisyon na ginawa, aksyon o hindi pagkilos na ginawa ng alinmang partido sa pagtitiwala sa nilalaman ng Website; (c) pagkaantala o paghinto ng negosyo; (d) pagkaantala/paghinto sa pag-access sa Website; (e) hindi paghahatid, maling paghahatid, katiwalian, pagkasira o iba pang mga pagbabago sa data; (f) pagkawala o mga pinsala ng anumang uri na naranasan dahil sa pag-access sa mga link ng mga ikatlong partido sa Website; (g) mga virus sa kompyuter, mga pagkabigo o mga pagkakabigo ng sistema kaugnay ng paggamit ng Website; o (h) anumang pagkaantala, hindi pagkakatugma, mga error sa nilalaman ng Website.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Sa pamamagitan ng pag-access sa aming Website, hinuhulaan mo lahat ng panganib na kaakibat ng paggamit nito, kabilang ngunit hindi limitado sa panganib na maaaring masira ang iyong kompyuter, mga aparato, software, o data sa pamamagitan ng anumang virus na ipinasa ng Website na ito o ng anumang nilalaman ng ikatlong partido o website, pati na rin ang panganib na may kinalaman sa kalidad ng serbisyo na ibinigay ng tagapagkaloob ng serbisyo (kung mayroon man). Hangga't pinapayagan ng batas, ang lahat ng mga garantiya, kundisyon, at mga reklamo (tuwiran man o hindi tuwiran) na may kaugnayan sa Website na ito ay kasalukuyang itinatanggi.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Mga Indemnities',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Sumasang-ayon ka na pagbayaran at pababain ang ${textBrand}, at ang aming mga kaakibat, mga shareholder, mga kasosyo, mga opisyal, mga direktor, mga empleyado, at mga ahente mula sa anumang pag-angkin o hiling, kabilang ang bayad ng mga abogado, na isinampa ng anumang ikatlong partido dahil sa o nagmula sa anumang nilalaman na iyong isinumite, ipinaskil, ipinadala o inilathala sa Website, ang iyong paggamit ng Website, ang iyong koneksyon sa Website, ang iyong paglabag sa Mga Tuntunin ng Paggamit, o ang iyong paglabag sa mga karapatan ng ibang partido.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Limitasyon ng Pananagutan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Hindi kami mananagot sa inyo para sa anumang direktang, hindi direktang, insidental, espesyal, consequential, o huwarang pinsala, kabilang ngunit hindi limitado sa mga pinsala para sa pagkawala ng kita, mabuting reputasyon, paggamit, data, o iba pang mga hindi materyal na pagkalugi (kahit na binigyan kami ng abiso tungkol sa posibilidad ng gayong mga pinsala), na nagresulta mula sa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Ang paggamit o hindi paggamit sa Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Ang gastos sa pagbili ng mga pamalit na produkto at serbisyo na nagresulta mula sa anumang mga produkto, data, impormasyon, o serbisyo na nabili o nakuha o mga mensahe na natanggap, o mga transaksyon na naganap sa pamamagitan o mula sa Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Hindi awtorisadong pag-access o pagbabago ng inyong mga paglipat o data;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Mga pahayag o pag-uugali ng anumang ikatlong partido sa Website; at</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Anumang iba pang bagay na may kaugnayan sa Website.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Anumang pagmamalabis ng anuman mang kalikasan ng alinmang partido laban sa isa pang partido at anumang aksyon laban sa isa pang partido ay dapat simulan sa loob ng 03 buwan (o ang pinapayagang minimum na panahon batay sa kaukulang batas) pagkatapos ng petsa kung saan nagkaroon ng sanhi ang aksyon, pagkatapos nito, ang nasalantang partido ay walang karagdagang pagmamalabis laban sa isa pang partido.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Mga Link at Nilalaman ng Ikatlong Partido',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>Ang Website ay maaaring maglaman ng mga kawing ng hypertext papunta sa mga website na pinapatakbo ng mga ikatlong partido para lamang sa mga layuning sanggunian at impormasyon. Hindi sinuri ng ${textBrand} at hindi maaaring managot sa mga patakaran sa privacy at mga kasanayan ng ibang mga website kahit na ma-access mo ang mga ito gamit ang mga kawing mula sa Website. Pinapayuhan ka namin na suriin ang mga tuntunin at kundisyon at patakaran sa privacy ng bawat website na iyong binibisita at makipag-ugnay sa may-ari o operator nito kung may mga alalahanin o katanungan ka. Gayundin, kung ikaw ay nakakawing sa Website mula sa isang website ng ikatlong partido, hindi maaaring managot ang ${textBrand} sa mga patakaran sa privacy at mga kasanayan ng mga may-ari o operator ng nasabing website ng ikatlong partido, at inirerekomenda namin na suriin mo ang patakaran ng nasabing website ng ikatlong partido at makipag-ugnay sa may-ari o operator nito kung may mga alalahanin o katanungan ka. Wala ang ${textBrand} ng mga garantiya o representasyon sa anumang mga naka-link na website ng ikatlong partido. Itinatanggal ng ${textBrand} ang anumang pananagutan para sa pagkawala o pinsala sa pinakamalawak na saklaw na pinahihintulutan ng batas na nagmumula sa anumang nilalaman sa mga website na iyon.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Upang maiwasan ang pagkalito, hindi ituring na rekomendasyon ng ${textBrand} ang pagkakaroon ng isang link patungo sa isang website ng ikatlong partido o anumang payo o impormasyon na makukuha rito.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Patakaran sa Pagkapribado',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Ang lahat ng personal na data tungkol sa iyo ay sumasailalim sa aming Patakaran sa Pagkapribado.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Itinuturing na kasama sa Mga Tuntunin ng Paggamit ang Patakaran sa Pagkapribado sa pamamagitan ng pagtukoy sa klausulang ito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Kung ang sinumang gumagamit ng Website, o anumang ibang tao, na nakakita ng isang dokumento na inilathalang ${textBrand} at nagbigay ng anumang impormasyon o puna tulad ng mga tanong, komento, mungkahi, o kahalintulad, ang impormasyong iyon ay ituturing na hindi pribado at may karapatan ang ${textBrand} na gamitin, reproduktahan, isapubliko, at ipamahagi ang impormasyong iyon sa sinumang ibang tao.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Dagdag pa, may karapatan ang ${textBrand} na gamitin o paggamitin ang anumang mga ideya, konsepto, kaalaman, o teknikong matatagpuan sa gayong impormasyon/puna para sa anumang layunin nang walang bayad, kasama na, ngunit hindi limitado sa, pagbuo, pagmamanupaktura, at pagmemerkado ng mga produkto na naglalaman ng gayong impormasyon.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Intellectual Property at Karapatan sa Pag-aari',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>Kinikilala at tinatanggap mo na ang lahat ng karapatan sa karapatang-ari, karapatang pang-almasya at anumang ibang karapatang pagmamay-ari sa disenyo, layout, proseso, mga function, data, at nilalaman ng Website ay pagmamay-ari ng ${textBrand}, aming mga tagapagbigay impormasyon o mga naglisensya. Ang Website ay pag-aari at pinamamahalaan ng ${textBrand}. Walang bahagi ng Website, ang nilalaman nito, o anumang mga ilalim na software at mga database nito ang maaaring kopyahin o muling buhayin, ibahagi sa mga ikatlong partido, baligtarin ang pagkakakumpuni, baligtarin ang pagkakatambal, o gamitin upang lumikha ng anumang gawang hango (sama ba sa pisikal o elektronikong anyo) nang walang pahintulot na nakasulat mula sa ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Dahil sa Hindi Maiwasang mga Bagay',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  Wala kaming pananagutan sa iyo para sa anumang pagkaantala o pagkabigo sa pagtupad ng aming mga obligasyon sa iyo dahil sa mga dahilan na hindi namin kontrolado, kabilang ang ngunit hindi limitado sa mga gawain ng Diyos, digmaan o terorismo, epidemya, pandemya, kalamidad na dulot ng kalikasan, mga isyu sa koneksyon ng Internet, mga isyu sa sistema, mga pagbabago sa mga batas, regulasyon o patakarang pampamahalaan, at kawalan ng mga suplay at serbisyo.
              </div>
          </div>
        `
    },
    {
      title: 'Pagtatapos',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Maaari kaming, sa ilang mga kalagayan at walang paunang abiso, sa aming tanging pagpapasya, agad na tapusin ang iyong account (kung mayroon man). Ang mga sanhi para sa gayong pagtatapos ay maaaring isama ngunit hindi limitado sa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Mga paglabag o paglabag sa Mga Tuntunin ng Paggamit, o iba pang mga isinama na mga dokumento, gabay o mga patakaran;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Kahilingan ng mga ahensya ng batas o iba pang mga ahensya ng pamahalaan;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Sariling inisyal na pagtanggal ng account;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Paglabag sa mga karapatan sa pag-aari ng iba;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Pagtanggal o malawakang pagbabago sa mga serbisyo sa Website, o bahagi nito;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Di-inaasahang mga isyu sa teknikal o seguridad o mga problema; o</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Mahabang panahon ng hindi pagiging aktibo.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Ang pagtatapos ng iyong account ay magreresulta sa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Pagtanggal ng access at pagpigil sa karagdagang paggamit sa lahat ng alok ng Website na nauugnay sa gayong account; at</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Pagtanggal ng iyong password at lahat ng kaugnay na impormasyon, mga file at nilalaman na nauugnay o nasa loob ng iyong account (o bahagi nito).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Ang pagtatapos ng iyong account ay walang epekto sa anumang iba pang mga karapatan o paraan ng remedyo na maaaring nararapat sa ilalim ng Mga Tuntunin ng Paggamit, sa batas o katarungan at hindi makakaapekto sa anumang mga akumuladong karapatan o mga pananagutan o ang pagpasok o pagpapatuloy sa bisa ng anumang probisyon na malinaw o hindi malinaw na layunin na magpatuloy sa bisa o manatili sa bisa pagkatapos ng gayong pagtatapos.</div>
              </div
          </div>
        `
    },
    {
      title: 'Mga Karapatan sa Karapatang Sipi',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Kapag nag-upload ka ng anumang mga imahe, larawan, at/o deskripsyon ng mga listahan sa Website (ang "<strong>Laman</strong>"), nagkakaloob ka sa amin ng isang pandaigdigang, walang royalty, hindi mapapawalang bisa na lisensya upang gamitin, imbakin, pagkunan, magreproduce, mag-ayos, mag-adapt, maglagay ng watermark, makipag-ugnayan, mag-publish, pampublikong isagawa, pampublikong ipakita, lumikha ng mga gawang may pinagmulang laman, at mag-distribute ng Laman. Sa pag-upload ng Laman, ipinapanumpa mo na ikaw ang may-ari ng karapatang-sipi ng Laman at sa gayon ay mayroon ka ng kinakailangang karapatan upang lisensiyahan ito sa amin ayon sa inilahad. Mayroon kaming karapatan na maglagay ng watermark sa mga litrato sa loob ng Laman at ang karapatang-sipi sa mga huling litratong may watermark ay eksklusibo naming pag-aari. Lahat ng ibang pangalan, produkto, at mga marka na nabanggit ay ang mga karapatan sa karapatang sipy ng kanilang mga kaukulang may-ari. Ang anumang materyales na ibinigay sa pamamagitan ng Website, kabilang ang teksto, grapika, kodigo, rating, ranking, mga database, inagregang impormasyon, Materyales ng Website, at/o software ay hindi maaaring muling magsilbing, baguhin, bagayin, ipamahagi, i-publish, ipakita, i-upload, ipost, ipadala, o i-hyperlink sa anumang paraan at anyo nang walang aming pahintulot, ngunitan, at pahintulot ng mga may-ari ng may-ari ng karapatang-sipi at trademark. Ang Website at Materyales ng Website ay para lamang sa iyong personal na paggamit, at hindi ka maaaring makipag-transaksyon sa ibang partido tungkol sa gayong mga serbisyo at nilalaman. Ang mga transaksyong ito ay kasama na ang mga komersyal na transaksyon at mga transaksyong makakaapekto nang hindi maganda sa halaga ng komersyal ng Website at Materyales ng Website.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Pangkalahatan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Ang Mga Tuntunin sa Paggamit ay pamamahalaan at ituturing ayon sa mga batas ng Republika ng Singgapur, at anumang mga reklamo o pagtatalo ng ano mang kalikasan ay sakop sa eksklusibong hurisdiksyon ng mga hukuman ng Republika ng Singgapur.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Ang aming pagkukulang na magamit o ipatupad ang anumang karapatan o probisyon ng Mga Tuntunin sa Paggamit ay hindi magiging pag-alis ng gayong karapatan o probisyon. Kung ang anumang probisyon ng Mga Tuntunin sa Paggamit ay matuklasang hindi wasto ng isang hukuman na may hurisdiksyon, ang mga partido ay napipilitang sumasang-ayon na dapat subukan ng hukuman na bigyan ng epekto ang mga layunin ng mga partido na nalalabing ipinahiwatig sa probisyon, at mananatiling ganap at epektibo ang iba pang mga probisyon ng Mga Tuntunin sa Paggamit.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Ang anumang account na maaaring mayroon ka sa amin ay hindi maaaring ma-transfer at lahat ng mga karapatan sa gayong account ay magwawakas sa iyong pagkamatay. Pagtanggap ng kopya ng iyong sertipiko ng kamatayan, ang iyong account ay isasara at lahat ng nilalaman na iyong nilikha sa iyong account ay permanente nang mabubura.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Ang lahat ng abiso para sa amin alinsunod sa Mga Tuntunin sa Paggamit ay dapat ipadala sa pamamagitan ng email sa: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ];
}