import { TCurrencyObject } from './currency';
import { debugEnv } from './env';

export const PRODUCT_PAGE_SIZE = debugEnv.DEV_ENABLE_DESIGN_DEBUG_MODE ? 20 : 3;

export type ProductItemParams = {
  id?: number;
  placeHolderId?: number;
  productChooseId?: number;
  quantity?: number;
}

export type THomeData = {
  projectId: number;
  projectName: string;
  blockId: number;
  blockName: string;
  planId: number;
  floorId: number;
  floorName: string;
  segmentId: number;
  segmentName: string;
  layoutId: number;
  layoutName: string;
  projectCurrency?: TCurrencyObject;
  personalityId?: string;
  isMortgage: boolean;
};

export enum PixelStreamingResponseAction {
  LayoutInitiated = 'layoutInitDone',
  ClickObject = 'clickObject',
  ObjectChanged = 'changeObjectDone', // for single object and whole collection
}

type LayoutInitDoneResponse = {
  actionKey: PixelStreamingResponseAction.LayoutInitiated;
  objectCountList: string; // json
}

type ClickObjectResponse = {
  actionKey: PixelStreamingResponseAction.ClickObject;
  placeHolderId: string;
  productId: string;
}

export type PixelStreamingResponse =
  LayoutInitDoneResponse | ClickObjectResponse;

export type PixelStreamingResponseE = {
  actionKey: PixelStreamingResponseAction;
  placeHolderId?: string;
  productId?: string;
};
