enum UserDataTypes {
  FetchCurrentUserDataSuccess = 'userData/FETCH_CURRENT_USER_DATA_SUCCESS',
  FetchCurrentUserDataFailed = 'userData/FETCH_CURRENT_USER_DATA_FAILED',
  SaveCurrentUserDataSuccess = 'userData/SAVE_CURRENT_USER_DATA_SUCCESS',
  EditCurrentUserChatBot = 'userData/EDIT_CURRENT_USER_CHAT_BOT',
  EditCurrentUserHome = 'userData/EDIT_CURRENT_USER_HOME',
  EditCurrentUserMortgagePlan = 'userData/EDIT_CURRENT_USER_MORTGAGE_PLAN',
  EditCurrentUserProjectHistory = 'userData/EDIT_CURRENT_USER_PROJECT_HISTORY',
}

export default UserDataTypes;
