import React from 'react';

const IcContact = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.125 6.1875C16.125 6.08437 16.0406 6 15.9375 6H6.9375C6.83438 6 6.75 6.08437 6.75 6.1875V7.3125C6.75 7.41563 6.83438 7.5 6.9375 7.5H15.9375C16.0406 7.5 16.125 7.41563 16.125 7.3125V6.1875ZM15.9375 9.375H6.9375C6.83438 9.375 6.75 9.45937 6.75 9.5625V10.6875C6.75 10.7906 6.83438 10.875 6.9375 10.875H15.9375C16.0406 10.875 16.125 10.7906 16.125 10.6875V9.5625C16.125 9.45937 16.0406 9.375 15.9375 9.375ZM11.25 12.75H6.9375C6.83438 12.75 6.75 12.8344 6.75 12.9375V14.0625C6.75 14.1656 6.83438 14.25 6.9375 14.25H11.25C11.3531 14.25 11.4375 14.1656 11.4375 14.0625V12.9375C11.4375 12.8344 11.3531 12.75 11.25 12.75ZM10.125 19.9688H4.875V3.46875H18V11.5312C18 11.6344 18.0844 11.7188 18.1875 11.7188H19.5C19.6031 11.7188 19.6875 11.6344 19.6875 11.5312V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H10.125C10.2281 21.6562 10.3125 21.5719 10.3125 21.4688V20.1562C10.3125 20.0531 10.2281 19.9688 10.125 19.9688ZM18.4875 18.225C19.1672 17.6086 19.5938 16.718 19.5938 15.7266C19.5938 13.8633 18.082 12.3516 16.2188 12.3516C14.3555 12.3516 12.8438 13.8633 12.8438 15.7266C12.8438 16.718 13.2703 17.6086 13.95 18.225C12.6141 18.9867 11.6953 20.3977 11.625 22.0242C11.6203 22.1297 11.707 22.2188 11.8125 22.2188H12.9398C13.0383 22.2188 13.1203 22.1414 13.1273 22.0406C13.2188 20.4188 14.5711 19.125 16.2188 19.125C17.8664 19.125 19.2188 20.4188 19.3102 22.0406C19.3148 22.1391 19.3969 22.2188 19.4977 22.2188H20.625C20.7328 22.2188 20.8172 22.1297 20.8125 22.0242C20.7445 20.3953 19.8234 18.9867 18.4875 18.225ZM16.2188 13.8516C17.2547 13.8516 18.0938 14.6906 18.0938 15.7266C18.0938 16.7625 17.2547 17.6016 16.2188 17.6016C15.1828 17.6016 14.3438 16.7625 14.3438 15.7266C14.3438 14.6906 15.1828 13.8516 16.2188 13.8516Z"
        fill={props.color || '#C9C8CC'}
      />
    </svg>
  );
};

export default IcContact;
