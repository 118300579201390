export const TERM_FR = (textBrand: string) => {
  return [
    {
      title: 'Acceptation des Conditions',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>En accédant et en utilisant <a href="https://levitate.homes">https://levitate.homes</a> (le "Site Web"), vous concluez un accord légalement contraignant sur les termes et conditions ici décrits ("Conditions d'Utilisation") avec nous, ${textBrand} International Pte. Ltd. ("nous", "notre" ou "${textBrand}"), le propriétaire et l'administrateur du Site Web.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Vous acceptez sans limitation ou réserve les Conditions d'Utilisation, y compris la Politique de Confidentialité.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Nous pouvons modifier ces Conditions d'Utilisation à tout moment en publiant une version révisée sur le Site Web. Cependant, aucun avis individuel ne vous sera envoyé. Toutes les Conditions d'Utilisation modifiées remplaceront toutes les versions précédentes des mêmes. Si vous n'acceptez pas les modifications ou les changements apportés aux Conditions d'Utilisation, vous devez immédiatement cesser d'utiliser l'un des services sur le Site Web. Après l'entrée en vigueur de l'une de ces modifications, nous considérerons que votre utilisation continue du Site Web constitue votre acceptation et votre accord avec ladite modification. Vous pouvez consulter la version la plus récente des Conditions d'Utilisation à tout moment.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Nous publierons périodiquement sur le Site Web des directives et des règles relatives à l'utilisation du Site Web. Toutes ces directives ou règles sont incorporées par référence dans les Conditions d'Utilisation.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Utilisation du Site Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>En accédant et en utilisant le site web <a href="https://levitate.homes">https://levitate.homes</a> (le "Site Web"), vous concluez un accord juridiquement contraignant concernant les termes et conditions énoncés ici ("Conditions d'Utilisation") avec nous, ${textBrand} International Pte. Ltd. ("nous" ou "${textBrand}"), le propriétaire et administrateur du Site Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>Vous acceptez sans limitation ni réserve les Conditions d'Utilisation, y compris la Politique de Confidentialité.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Nous pouvons modifier ces Conditions d'Utilisation à tout moment en publiant une version révisée sur le Site Web. Cependant, aucun avis individuel ne vous sera envoyé. Toute Condition d'Utilisation modifiée remplacera toutes les versions précédentes de celle-ci. Si vous n'acceptez pas les amendements ou les changements apportés aux Conditions d'Utilisation, vous devez cesser immédiatement d'utiliser l'un quelconque des services du Site Web. Après l'entrée en vigueur de toute telle modification, nous considérerons que votre utilisation continue du Site Web constitue votre acceptation et votre accord avec cette modification. Vous pouvez consulter à tout moment la version la plus récente des Conditions d'Utilisation.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>Nous publierons de temps à autre sur le Site Web des directives et des règles concernant l'utilisation du Site Web. Toutes ces directives ou règles sont ici incorporées par référence dans les Conditions d'Utilisation.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Matériel du site Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>En accédant au site Web et en l'utilisant, vous pouvez être exposé à des informations, des données, des textes, des logiciels, des photographies, des graphiques, des vidéos, des messages, des concepts, des idées, des conceptions, des dessins, des listes de produits, des prix ou d'autres matériaux que nous et/ou d'autres utilisateurs du site Web pouvons télécharger, publier, envoyer par e-mail, transmettre ou rendre disponibles sur le site Web ("Matériel du site Web"), que ce soit publiquement ou en privé.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Vous comprenez que le Matériel du site Web peut être offensant, indécent ou contestable.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Vous comprenez que le Matériel du site Web ne peut être utilisé que pour votre usage personnel et non commercial, et non pour la publication, la distribution, la transmission, la retransmission, la redistribution, la diffusion, la reproduction ou la circulation à une autre personne dans la même entreprise ou organisation, et non pour la publication sur d'autres sites Web ou forums, groupes de discussion, listes de diffusion électroniques, babillards électroniques ou chats Internet exploités par d'autres sites Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre, afficher ou fournir autrement le Matériel du site Web sur un autre site Web ou serveur sans notre consentement écrit préalable.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Nous ne sommes en aucun cas responsables des erreurs ou omissions dans tout Matériel du site Web, ni des pertes ou dommages de quelque nature que ce soit résultant de l'utilisation de tout Matériel du site Web publié, envoyé par e-mail, transmis ou rendu disponible d'une autre manière sur le site Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>Vous reconnaissez et acceptez que le format et le contenu du site Web peuvent être modifiés à tout moment sans préavis ; le fonctionnement du site Web peut être suspendu ou interrompu pour des travaux de support ou de maintenance, pour mettre à jour son contenu ou pour toute autre raison ; et l'accès au site Web peut être résilié, que ce soit par rapport à des utilisateurs spécifiques ou de manière générale, à tout moment et sans préavis.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Précision des informations sur notre site Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Bien que ${textBrand} fasse des efforts raisonnables pour inclure des informations précises sur notre site Web, nous ne fournissons aucune garantie ni représentation quant à l'exactitude des informations fournies, qu'elles soient implicites ou non, dans la mesure permise par la loi. ${textBrand} exclut toute responsabilité pour les erreurs ou omissions dans le contenu du site Web dans toute la mesure permise par la loi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Les détails contenus sur le site Web sont uniquement à titre indicatif. L'exactitude de tout détail, description, dimension, référence à l'état, autorisations nécessaires à l'utilisation et à l'occupation et autres détails contenus sur le site Web n'est pas garantie et est uniquement à titre indicatif. Il est recommandé de ne pas s'y fier comme à des déclarations de fait ou de représentations et de vérifier par vous-même leur exactitude.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand} conseille que, comme l'achat de biens immobiliers est un engagement financier important, vous devriez prendre toutes les mesures raisonnables pour vous assurer que tout bien immobilier que vous envisagez d'acheter convient à vos besoins à tous égards. Nous avons pris des mesures raisonnables pour nous assurer que les matériaux contenus sur le site Web sont exacts, mais cela ne doit pas être considéré comme un substitut à vos propres enquêtes et considérations. Il est recommandé de prendre les mesures appropriées pour vérifier toute information sur laquelle vous souhaitez vous appuyer.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Malgré tous les efforts pour fournir une représentation précise de la couleur et du design de chaque produit sur notre site Web, les couleurs et le design réels peuvent varier légèrement en raison des différents paramètres d'écran du dispositif, de l'éclairage sur le lieu d'installation, des petites différences dans les finitions du produit au fil du temps et d'autres facteurs. ${textBrand} n'accepte aucune responsabilité pour les différences de couleur ou de design qui ne sont pas de notre faute. En achetant une propriété ou un produit via notre site Web, vous acceptez le faible risque qu'il puisse y avoir de légères variations entre les couleurs et le design réels et la représentation sur notre site Web.</div>
          </div>
        </div>
      `
    },
    {
      title: "Obligations de l'utilisateur",
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              En plus des obligations spécifiées dans ces Conditions d'utilisation, vous NE DEVEZ PAS :
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Utiliser le site Web en violation de toute loi ou réglementation applicable ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Agir de manière à nuire, abuser, harceler, traquer, diffamer, calomnier, menacer ou offenser autrui, ou utiliser le site Web à de telles fins ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Effectuer des actions qui peuvent causer un inconvénient, un désavantage ou des dommages à l'utilisateur, à des tiers ou à ${textBrand} ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Effectuer des actes de demande frauduleuse ou de détention de plusieurs inscriptions d'adhésion ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>S'engager dans une activité immorale ou potentiellement immorale, y compris, mais sans s'y limiter, la prostitution et la pornographie ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Se faire passer pour une personne ou une entité, ou déclarer faussement ou autrement représenter votre affiliation avec une personne ou une entité ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Fournir des informations fausses, inexactes ou trompeuses ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>S'engager dans des activités potentiellement frauduleuses, suspectes ou illégales et/ou des transactions de ce type ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Envoyer des e-mails non sollicités à tout utilisateur ou utiliser les services sur le site Web pour collecter des paiements pour l'envoi, ou aider à l'envoi, d'e-mails non sollicités à des tiers ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Dévoiler ou distribuer les informations personnelles d'un autre utilisateur à un tiers, ou utiliser ces informations à des fins de marketing sans le consentement exprès de cet utilisateur ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Prendre des mesures qui imposent une charge déraisonnable ou disproportionnée sur l'infrastructure du site Web ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Télécharger, causer le téléchargement ou mettre à disposition un contenu sur le site Web contenant des virus ou d'autres éléments pouvant endommager, interférer avec, ou affecter négativement ou entraver l'accès au site Web ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Se livrer, ou inciter d'autres utilisateurs à se livrer, à du spam, du phishing, à des activités incorrectes, malveillantes ou, à notre entière discrétion, à des clics frauduleux, des impressions ou des activités de marketing liées à ${textBrand} ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>À notre entière discrétion, porter préjudice à la réputation de ${textBrand} ou la ternir ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Modifier, adapter, reformater, recompiler, transmettre, publier, concéder sous licence, effectuer une rétro-ingénierie, désassembler, reconstruire, décompiler, copier ou créer des œuvres dérivées des services sur le site Web ou d'une partie de ceux-ci, y compris des codes source ou des algorithmes, sauf autorisation expresse de notre part par écrit, ou dans la mesure autorisée par la loi ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Modifier, supprimer, recouvrir ou autrement défigurer toute identification, marque de commerce, droit d'auteur ou autre avis provenant de tout aspect des services sur le site Web ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Utiliser un logiciel d'automatisation, des hacks, des mods ou tout autre logiciel non autorisé de tiers conçu pour accéder, explorer ou collecter les services de ${textBrand} ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Utiliser un logiciel non autorisé de tiers qui intercepte, "exploite" ou collecte autrement des informations de ou par le biais de ${textBrand} ;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Télécharger, transmettre, poster ou rendre autrement disponible tout contenu qui est, ou pourrait raisonnablement être attendu :
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>D'être offensant, discriminatoire, diffamatoire, obscène, menaçant, abusif, indécent ou autrement illégal, y compris un contenu susceptible d'offenser, d'insulter ou d'humilier autrui en fonction de la race, de la religion, de l'ethnicité, du sexe, de l'âge, de l'orientation sexuelle ou de tout handicap physique ou mental ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>De ne pas être votre œuvre originale, ou qui pourrait enfreindre les droits de propriété intellectuelle ou autres droits d'une autre personne ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>De comprendre une image ou des informations personnelles d'une autre personne à moins que vous n'ayez leur consentement ; ou</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>De contenir de grandes quantités de contenu non ciblé, non désiré ou répétitif ; ou</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Interférer, perturber ou créer une charge excessive sur l'infrastructure du site Web ou notre entreprise.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Si vous pensez qu'un utilisateur a enfreint l'une des conditions ci-dessus, veuillez nous contacter.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Nous nous réservons le droit de bloquer, suspendre ou annuler tout utilisateur, toute inscription d'adhésion sur notre site Web et de modifier ou supprimer tout matériel téléchargé, publié, transmis ou rendu disponible sur le site Web par tout utilisateur, sans préavis ; définir et limiter, refuser et/ou rejeter unilatéralement les retours des clients à tout moment en raison d'un historique de retours irrégulier ou excessif. En téléchargeant, transmettant, publiant ou rendant autrement disponible tout matériel via le site Web, vous accordez à ${textBrand} une licence non exclusive, mondiale, sans redevance et perpétuelle pour utiliser, reproduire, éditer et exploiter le matériel sous quelque forme que ce soit et pour quelque but que ce soit. La demande d'adhésion peut ne pas être approuvée si le demandeur a déjà annulé son adhésion par le passé.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Nous ne sommes pas responsables, et n'acceptons aucune responsabilité, en ce qui concerne tout matériel téléchargé, publié, transmis ou rendu disponible sur le site Web par une personne autre que nous. Nous n'approuvons aucune opinion, conseil ou déclaration faite par une personne autre que ${textBrand}.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Vous acceptez d'indemniser ${textBrand} et chacun de ses responsables, employés, agents, sous-traitants, fournisseurs et concédants de licence (collectivement désignés "Affiliés") pour toute responsabilité, perte ou dommage (y compris tous les coûts juridiques et autres sur une base d'indemnisation complète) qu'ils subissent ou encourrent découlant en totalité ou en partie de la violation ou du non-respect de l'une de ces Conditions d'utilisation, ou de tout autre manquement ou comportement fautif concernant l'objet de ces Conditions d'utilisation, de votre part ou de l'une de vos sociétés affiliées.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Clause de non-responsabilité des garanties',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Votre utilisation du Site Web est à vos propres risques. Nous déclinons toutes les garanties de quelque nature que ce soit, qu'elles soient expresses ou implicites.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Nous nous réservons le droit, à tout moment et de temps à autre, de modifier ou d'interrompre, temporairement ou définitivement, tout service du Site Web (ou une partie de celui-ci) avec ou sans préavis. Vous convenez que nous ne serons pas responsables envers vous ou envers un tiers pour toute modification, suspension ou interruption de tels services.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Nous ne garantissons pas que :
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Le Site Web répondra à vos exigences ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Le Site Web sera ininterrompu, opportun, sécurisé et exempt d'erreurs ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Tout résultat pouvant être obtenu par l'utilisation du Site Web sera exact ou fiable ; et</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>La qualité de tout produit, service, information ou autre matériel acheté ou obtenu par vous par le biais du Site Web répondra à vos attentes.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Nous ne pouvons pas et ne cautionnons pas les messages, points de vue, opinions, recherches et recommandations des individus, utilisateurs et organisations d'intérêt, ni ne soutenons quelque patronage que ce soit d'un prestataire de services particulier, d'un parcours de traitement ou de transactions commerciales dans des valeurs mobilières, des investissements ou des catégories de valeurs mobilières ou d'investissements, et l'inclusion sur le Site Web d'un lien vers un autre site Web ou des ressources n'implique aucune forme d'approbation de notre part.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Nous ne révisons ni n'éditons activement les messages, points de vue, opinions, recherches et recommandations des utilisateurs, même si ces messages, points de vue, opinions, recherches et recommandations sont formulés par les utilisateurs via le Site Web. Bien que les informations sur le Site Web proviennent de sources réputées fiables, aucune des données, actualités, informations, rapports, opinions, ni aucune des recommandations (le cas échéant) n'a été personnalisée pour une personne spécifique ou une catégorie de personnes.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>En mettant à disposition des informations et des données sur le Site Web, y compris des faits, des points de vue, des opinions et des recommandations d'individus et d'organisations d'intérêt, nous et nos fournisseurs de contenu ne donnons pas de conseils financiers, d'investissement, fiscaux et juridiques. Vous devez toujours solliciter les conseils professionnels appropriés avant de prendre toute décision.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>Nous pouvons surveiller ou examiner certaines zones du Site Web où vous transmettez ou publiez des communications dans le but de promouvoir son efficacité, son utilité et sa pertinence. Cependant, nous ne nous engageons pas à surveiller ou à examiner chaque publication ou communication, et nous déclinons toute responsabilité concernant l'ensemble du matériel du Site Web, qu'il relève ou non des lois sur le droit d'auteur, les marques de commerce, la diffamation, la vie privée, l'obscénité ou autre.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Ce Site Web et toutes les informations qu'il contient sont fournis par ${textBrand} "tel quel" et "tel que disponible". ${textBrand} et/ou ses affiliés respectifs, actionnaires, partenaires, dirigeants, administrateurs, employés et agents :
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Ne font aucune déclaration ni garantie d'aucune sorte, expresse ou implicite, quant au fonctionnement et à la disponibilité de ce Site Web ou des informations, contenus, matériaux ou produits présentés sur le Site Web. Vous acceptez expressément que votre utilisation de ce Site Web est à vos propres risques dans toute la mesure permise par la loi applicable ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Déclinent toutes les garanties, qu'elles soient expresses ou implicites, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande et d'adaptation à un usage particulier ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Ne garantissent pas que le Site Web, ses serveurs ou les courriels qui nous sont envoyés sont exempts de virus ou d'autres composants ou erreurs nuisibles ; et</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Ne seront pas responsables de tout dommage de quelque nature que ce soit, y compris, mais sans s'y limiter, les dommages directs, indirects, accessoires, punitifs et consécutifs ou toute autre perte ou responsabilité découlant de (a) l'utilisation ou l'impossibilité d'utiliser le Site Web ; (b) toute décision prise, action ou inaction entreprise par une partie qui se fie au contenu du Site Web ; (c) interruption d'activité ; (d) retard/rupture dans l'accès au Site Web ; (e) non-livraison, mauvaise livraison, corruption, destruction ou autre modification de données ; (f) perte ou dommages de quelque nature que ce soit résultant de l'accès à des liens de tiers sur le Site Web ; (g) les virus informatiques, les défaillances ou les dysfonctionnements du système en relation avec l'utilisation du Site Web ; ou (h) les retards, les inexactitudes, les erreurs ou les omissions dans le contenu du Site Web.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>En accédant à notre Site Web, vous assumez tous les risques associés à son utilisation, y compris, mais sans s'y limiter, le risque que votre ordinateur, vos appareils, votre logiciel ou vos données puissent être endommagés par un virus transmis par ce Site Web ou par un contenu ou un site Web tiers, ainsi que les risques liés à la qualité du service fourni par le prestataire de services (le cas échéant). Dans toute la mesure permise par la loi, toutes les garanties, conditions et réclamations (qu'elles soient expresses ou implicites) concernant ce Site Web sont par la présente exclues.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Indemnités',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Vous acceptez d'indemniser et de protéger ${textBrand}, ainsi que nos affiliés, actionnaires, partenaires, dirigeants, employés et agents, de toute réclamation ou demande, y compris les honoraires d'avocat, formulée par un tiers en raison ou à la suite de tout contenu que vous soumettez, publiez, transmettez ou rendez disponible sur le site Web, de votre utilisation du site Web, de votre connexion au site Web, de votre violation des conditions d'utilisation ou de votre violation des droits d'une autre partie.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Limitation de Responsabilité',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Nous ne serons pas responsables envers vous de tout dommage direct, indirect, accessoire, spécial, consécutif ou exemplaire, y compris mais sans s'y limiter, les dommages pour perte de profits, de réputation, d'utilisation, de données ou autres pertes incorporelles (même si nous avons été informés de la possibilité de tels dommages), résultant de :
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>L'utilisation ou l'incapacité d'utiliser le Site web ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Les coûts d'acquisition de produits de substitution et de services résultant de produits, de données, d'informations ou de services achetés ou obtenus ou de messages reçus ou de transactions conclues par le biais du Site web ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Un accès non autorisé ou une altération de vos transmissions ou données ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Les déclarations ou le comportement de toute tierce partie sur le Site web ; et</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Toute autre question liée au Site web.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Toute réclamation de quelque nature que ce soit par l'une des parties contre l'autre partie et toute action contre l'autre partie doivent être intentées dans les 03 mois (ou la période minimale autorisée conformément à la loi applicable) à compter de la date à laquelle la cause de l'action se produit, après quoi la partie lésée n'aura plus aucune réclamation à l'encontre de l'autre partie.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Liens et Contenus Tiers',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>Le site Web peut contenir des liens hypertexte vers des sites Web exploités par des tiers uniquement à des fins de référence et d'information. ${textBrand} n'a pas examiné et ne peut être tenu responsable des politiques de confidentialité et des pratiques des autres sites Web, même si vous y accédez via des liens depuis le site Web. Nous vous recommandons de vérifier les conditions d'utilisation et la politique de confidentialité de chaque site Web que vous visitez et de contacter son propriétaire ou exploitant si vous avez des questions ou des préoccupations. De même, si vous avez accédé au site Web depuis un site Web tiers, ${textBrand} ne peut être tenu responsable des politiques de confidentialité et des pratiques des propriétaires ou exploitants de ce site Web tiers, et nous vous recommandons de vérifier la politique de ce site Web tiers et de contacter son propriétaire ou exploitant si vous avez des questions ou des préoccupations. ${textBrand} ne fait aucune garantie ni représentation concernant les sites Web tiers liés. ${textBrand} exclut toute responsabilité pour les pertes ou dommages dans toute la mesure permise par la loi, découlant de tout contenu sur ces sites Web.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Pour éviter toute confusion, la présence d'un lien vers un site Web tiers ne sera pas considérée comme une recommandation de ces sites Web, ni de tout conseil ou information qui y serait présenté par ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Politique de Confidentialité',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Toutes les données personnelles vous concernant sont soumises à notre Politique de Confidentialité.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>La Politique de Confidentialité est réputée intégrée aux Conditions d'Utilisation par référence à cette Clause.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Si un utilisateur du site Web, ou toute autre personne, visualise un document publié par ${textBrand} et répond avec des informations ou des commentaires tels que des questions, des commentaires, des suggestions ou autres, ces informations seront considérées comme non confidentielles et ${textBrand} est libre d'utiliser, reproduire, divulguer et distribuer ces informations à toute autre personne.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>De plus, ${textBrand} est libre d'utiliser ou d'exploiter toute idée, concept, savoir-faire ou technique contenue dans ces informations/commentaires, à des fins quelconques et sans frais, y compris, mais sans s'y limiter, le développement, la fabrication et la commercialisation de produits incorporant ces informations.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Propriété Intellectuelle et Droits d\'Auteur',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Vous reconnaissez et acceptez que tous les droits d'auteur, les droits de base de données et tout autre droit de propriété intellectuelle qui subsistent dans la conception, la mise en page, les processus, les fonctions, les données et le contenu du Site Web sont la propriété de ${textBrand}, de nos fournisseurs d'informations ou concédants de licence. Le Site Web appartient à ${textBrand} et est exploité par nous. Aucune partie du Site Web, de son contenu ou de l'un de ses logiciels et bases de données sous-jacents ne peut être copiée ou reproduite, partagée avec des tiers, inversée, désassemblée ou utilisée pour créer une œuvre dérivée (que ce soit sous forme physique ou électronique) sans le consentement préalable par écrit de ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Force Majeure',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Nous ne serons pas responsables envers vous pour tout retard ou défaut dans l'exécution de nos obligations envers vous pour des raisons indépendantes de notre volonté, y compris, sans limitation, les actes de Dieu, la guerre ou le terrorisme, les épidémies, les pandémies, les catastrophes naturelles, les problèmes de connexion Internet, les problèmes système, les changements dans les lois, réglementations ou politiques gouvernementales applicables, et les pénuries d'approvisionnement et de services.
              </div>
          </div>
        `
    },
    {
      title: 'Résiliation',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Nous pouvons, dans certaines circonstances et sans préavis, à notre seule discrétion, résilier immédiatement votre compte (le cas échéant). Les motifs de résiliation peuvent inclure, sans limitation :
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Manquements ou violations des Conditions d'utilisation, ou d'autres documents, directives ou règles incorporées ;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Demande des forces de l'ordre ou d'autres agences gouvernementales ;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Suppressions de compte auto-initiées ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Violation des droits de propriété intellectuelle d'autrui ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Discontinuité ou modification substantielle des services sur le site Web, ou d'une partie de ceux-ci ;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Problèmes techniques ou de sécurité imprévus ; ou</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Périodes prolongées d'inactivité.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  La résiliation de votre compte entraînera :
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Suppression de l'accès à toutes les offres du site Web associées à ce compte et interdiction de leur utilisation future ; et</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Suppression de votre mot de passe et de toutes les informations, fichiers et contenus associés à votre compte (ou à une partie de celui-ci).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>La résiliation de votre compte n'affectera aucunement les autres droits ou recours auxquels nous pourrions avoir droit en vertu des Conditions d'utilisation, de la loi ou de l'équité, ni n'affectera les droits ou obligations accumulés ni l'entrée en vigueur ou la continuité de toute disposition expressément ou implicitement destinée à entrer en vigueur ou à rester en vigueur après une telle résiliation.</div>
              </div
          </div>
        `
    },
    {
      title: 'Droits de Propriété Intellectuelle',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Lorsque vous téléchargez des images, des photographies et/ou des descriptions de liste sur le site Web ("<strong>Contenu</strong>"), vous nous accordez une licence mondiale, libre de redevance et irrévocable pour utiliser, stocker, héberger, reproduire, modifier, adapter, apposer un filigrane, communiquer, publier, exécuter publiquement, afficher publiquement, créer des œuvres dérivées et distribuer le Contenu. En téléchargeant le Contenu, vous garantissez que vous possédez les droits d'auteur du Contenu et avez donc les droits nécessaires pour nous accorder la licence telle que mentionnée. Nous avons le droit d'apposer un filigrane sur les photographies du Contenu, et les droits d'auteur sur les photographies finales avec filigrane appartiendront exclusivement à nous. Tous les autres noms, produits et marques mentionnés sont les droits de propriété intellectuelle de leurs propriétaires respectifs. Aucun matériel fourni via le site Web, y compris le texte, les graphiques, le code, les évaluations, les classements, les bases de données, les informations agrégées, le matériel du site Web et/ou le logiciel, ne peut être reproduit, modifié, adapté, distribué, publié, affiché, téléchargé, publié, transmis ou lié d'aucune manière et sous quelque forme que ce soit sans notre autorisation expresse préalable par écrit, et l'autorisation des détenteurs respectifs des droits d'auteur et des marques déposées. Le site Web et le matériel du site Web sont réservés à votre usage personnel uniquement, et vous ne pouvez pas effectuer de transactions avec d'autres parties concernant ces services et contenus. Ces transactions incluent les transactions commerciales et les transactions qui affecteront négativement la valeur commerciale du site Web et du matériel du site Web.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Général',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Les conditions d'utilisation seront régies et interprétées conformément aux lois de la République de Singapour, et toute réclamation ou litige de quelque nature que ce soit sera soumis à la compétence exclusive des tribunaux de la République de Singapour.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Notre omission d'exercer ou d'appliquer un droit ou une disposition des conditions d'utilisation ne constitue pas une renonciation à ce droit ou à cette disposition. Si une disposition des conditions d'utilisation est jugée invalide par un tribunal compétent, les parties conviennent néanmoins que le tribunal devrait s'efforcer de donner effet aux intentions des parties telles qu'exprimées dans la disposition, et les autres dispositions des conditions d'utilisation resteront en pleine vigueur et effet.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Tout compte que vous pourriez avoir chez nous n'est pas transférable et tous les droits sur ce compte prendront fin à votre décès. À réception d'une copie de votre certificat de décès, votre compte sera résilié et tout le contenu que vous avez généré dans votre compte sera définitivement supprimé.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Toutes les notifications à notre intention en vertu des conditions d'utilisation doivent être envoyées par e-mail à l'adresse suivante: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
