export const TERM_NL = (textBrand: string) => {
  return [
    {
      title: 'Aanvaarding van Voorwaarden',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Door toegang te krijgen tot en gebruik te maken van <a href="https://levitate.homes">https://levitate.homes</a> (de "Website"), sluit u een juridisch bindende overeenkomst af over de hierin beschreven voorwaarden ("Gebruiksvoorwaarden") met ons, ${textBrand} International Pte. Ltd. ("wij", "ons", "onze" of "${textBrand}"), de eigenaar en beheerder van de Website.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>U accepteert de Gebruiksvoorwaarden zonder beperkingen of voorbehouden, inclusief het Privacybeleid.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Wij kunnen deze Gebruiksvoorwaarden te allen tijde wijzigen door een herziene versie op de Website te plaatsen. Er wordt echter geen individuele kennisgeving naar u verzonden. Alle gewijzigde Gebruiksvoorwaarden zullen alle eerdere versies ervan vervangen. Als u het niet eens bent met de wijzigingen of aanpassingen in de Gebruiksvoorwaarden, dient u onmiddellijk het gebruik van een van de diensten op de Website te staken. Na het ingaan van een dergelijke wijziging beschouwen we uw voortgezet gebruik van de Website als uw acceptatie en instemming met die wijziging. U kunt te allen tijde de meest recente versie van de Gebruiksvoorwaarden raadplegen.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Van tijd tot tijd zullen we op de Website richtlijnen en regels met betrekking tot het gebruik van de Website plaatsen. Al deze richtlijnen of regels worden hierbij door verwijzing in de Gebruiksvoorwaarden opgenomen.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Gebruik van de Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>Door toegang te krijgen tot en gebruik te maken van de website <a href="https://levitate.homes">https://levitate.homes</a> (de "Website"), sluit u een juridisch bindende overeenkomst af over de hierin opgenomen voorwaarden ("Gebruiksvoorwaarden") met ons, ${textBrand} International Pte. Ltd. ("wij" of "${textBrand}"), de eigenaar en beheerder van de Website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>U accepteert zonder beperkingen of voorbehouden de Gebruiksvoorwaarden, inclusief het Privacybeleid.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Wij kunnen deze Gebruiksvoorwaarden te allen tijde wijzigen door een herziene versie op de Website te plaatsen. Er wordt echter geen individuele kennisgeving aan u verzonden. Eventuele gewijzigde Gebruiksvoorwaarden zullen alle eerdere versies ervan vervangen. Indien u niet akkoord gaat met de wijzigingen in de Gebruiksvoorwaarden, dient u het gebruik van alle diensten op de Website onmiddellijk te staken. Na het in werking treden van een dergelijke wijziging, beschouwen wij uw voortgezette gebruik van de Website als uw aanvaarding en instemming met die wijziging. U kunt op elk moment de meest recente versie van de Gebruiksvoorwaarden raadplegen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>Van tijd tot tijd zullen we op de Website richtlijnen en regels publiceren met betrekking tot het gebruik van de Website. Al dergelijke richtlijnen of regels worden hierbij door verwijzing in deze Gebruiksvoorwaarden opgenomen.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Materiaal van de Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Door toegang te krijgen tot en gebruik te maken van de Website, kunt u worden blootgesteld aan informatie, gegevens, tekst, software, foto's, afbeeldingen, video's, berichten, concepten, ideeën, ontwerpen, tekeningen, productlijsten, prijzen of andere materialen die wij en/of andere gebruikers van de Website kunnen uploaden, plaatsen, e-mailen, verzenden of anderszins beschikbaar stellen op de Website ("Materiaal van de Website"), zowel publiekelijk als privé.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>U begrijpt dat het Materiaal van de Website beledigend, onfatsoenlijk of aanstootgevend kan zijn.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>U begrijpt dat het Materiaal van de Website alleen mag worden gebruikt voor uw eigen persoonlijke en niet-commerciële gebruik, en niet voor publicatie, distributie, verzending, herverzending, herverdeling, uitzending, reproductie of circulatie naar iemand anders in hetzelfde bedrijf of organisatie, en niet voor plaatsing op andere websites of fora, nieuwsgroepen, mailinglijsten, elektronische bulletinboards of internetrelaichats die worden geëxploiteerd door andere websites.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>U stemt ermee in om Materiaal van de Website niet te reproduceren, dupliceren, kopiëren, verkopen, doorverkopen, weergeven of anderszins beschikbaar te stellen op een andere website of server zonder onze voorafgaande schriftelijke toestemming.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Wij zijn onder geen enkele omstandigheid aansprakelijk voor fouten of omissies in enig Materiaal van de Website, noch voor enig verlies of schade van welke aard dan ook die voortvloeit uit het gebruik van enig Materiaal van de Website dat op de Website is geplaatst, per e-mail is verzonden, is overgedragen of anderszins beschikbaar is gesteld.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>U erkent en stemt ermee in dat het formaat en de inhoud van de Website op elk moment zonder kennisgeving kunnen worden gewijzigd; de werking van de Website kan worden opgeschort of stopgezet voor ondersteunings- of onderhoudswerkzaamheden, om de inhoud bij te werken of om welke andere reden dan ook; en de toegang tot de Website kan te allen tijde en zonder kennisgeving worden beëindigd, zowel met betrekking tot specifieke gebruikers als in het algemeen.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Nauwkeurigheid van informatie op onze website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Hoewel ${textBrand} redelijke inspanningen levert om nauwkeurige informatie op onze website op te nemen, geven wij geen garanties of verklaringen met betrekking tot de nauwkeurigheid van enige verstrekte informatie, hetzij impliciet hetzij anderszins, voor zover toegestaan door de wet. ${textBrand} sluit alle aansprakelijkheid uit voor eventuele fouten of weglatingen in de inhoud van de website voor zover toegestaan door de wet.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>De gegevens die op de website staan, zijn alleen bedoeld voor informatieve doeleinden. De nauwkeurigheid van enige gegevens, beschrijvingen, afmetingen, verwijzingen naar de staat, de vereiste toestemmingen voor gebruik en bezetting en andere details op de website wordt niet gegarandeerd en dient alleen ter algemene richtlijn. U wordt geadviseerd om niet op deze gegevens te vertrouwen als uitspraken van feiten of voorstellingen en om uzelf te overtuigen van de juistheid ervan.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand} adviseert dat u, aangezien de aankoop van onroerend goed een belangrijke financiële verplichting is, alle redelijke stappen onderneemt om ervoor te zorgen dat elk onroerend goed dat u overweegt te kopen, geschikt is voor uw behoeften in alle opzichten. We hebben redelijke stappen ondernomen om ervoor te zorgen dat de materialen op de website nauwkeurig zijn, maar deze moeten niet worden beschouwd als vervanging voor uw eigen onderzoek en overwegingen. U wordt geadviseerd om de nodige stappen te ondernemen om informatie te verifiëren waarop u wilt vertrouwen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Ondanks alle inspanningen om een nauwkeurige weergave van kleur en ontwerp van elk product op onze website te bieden, kunnen de werkelijke kleuren en ontwerpen licht variëren, vanwege verschillende instellingen van het apparaatscherm, verlichting op de installatieplaats, kleine verschillen in de productafwerking in de loop van de tijd en andere factoren. ${textBrand} aanvaardt geen verantwoordelijkheid voor kleur- of ontwerpverschillen die niet aan ons te wijten zijn. Bij aankoop van een woning of product via onze website gaat u akkoord met het kleine risico dat er kleine variaties kunnen zijn tussen de werkelijke kleuren en het ontwerp en de weergave op onze website.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Verplichtingen van de gebruiker',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Naast de verplichtingen die in deze Gebruiksvoorwaarden zijn gespecificeerd, mag u NIET:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>De Website gebruiken in strijd met enige toepasselijke wetten of voorschriften;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Zich op een manier gedragen die anderen schade berokkent, misbruikt, lastigvalt, stalkt, belastert, beledigt, bedreigt of anderszins beledigt, of de Website voor dergelijke doeleinden gebruiken;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Handelingen verrichten die ongemak, nadeel of schade kunnen veroorzaken aan de gebruiker(s), derde partij(en) of ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Handelingen verrichten om frauduleus meerdere lidmaatschapsregistraties aan te vragen of te behouden;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Zich bezighouden met immorele activiteiten of potentieel immorele activiteiten, waaronder maar niet beperkt tot prostitutie en pornografie;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Zich voordoen als een persoon of entiteit, of valse verklaringen afleggen of anderszins uw verbondenheid met een persoon of entiteit verkeerd voorstellen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Valse, onnauwkeurige of misleidende informatie verstrekken;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Zich bezighouden met mogelijk frauduleuze, verdachte of illegale activiteiten en/of transacties;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Ongewenste e-mails naar enige gebruiker sturen of de diensten op de Website gebruiken om betalingen te innen voor het verzenden of helpen bij het verzenden van ongevraagde e-mails naar derden;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>De persoonlijke informatie van een andere gebruiker aan een derde bekendmaken of verspreiden, of dergelijke informatie gebruiken voor marketingdoeleinden zonder de uitdrukkelijke toestemming van die gebruiker;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Enige actie ondernemen die een onredelijke of onevenredig grote belasting vormt voor de infrastructuur van de Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Inhoud op de Website uploaden, veroorzaken of anderszins beschikbaar stellen die virussen of andere elementen bevat die de toegang tot de Website kunnen beschadigen, verstoren of nadelig kunnen beïnvloeden;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Spammen, phishing, ongepaste, kwaadwillige of, naar ons absolute oordeel, frauduleuze klikken, impressies of marketingactiviteiten met betrekking tot ${textBrand} uitvoeren of andere gebruikers aanzetten om dit te doen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>Naar ons absolute oordeel nadelig zijn voor de reputatie van ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Diensten op de Website of delen daarvan, inclusief broncodes of algoritmen, wijzigen, aanpassen, herformatteren, opnieuw compileren, verzenden, publiceren, licentiëren, reverse-engineeren, demonteren, reconstrueren, decompileren, kopiëren of afgeleide werken maken, overdragen of verkopen, behalve voor zover uitdrukkelijk door ons schriftelijk toegestaan, of voor zover wettelijk toegestaan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Identificaties, handelsmerken, auteursrechten of andere kennisgevingen van welke aspect van de diensten op de Website dan ook wijzigen, verwijderen, bedekken of anderszins verminken;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Elke ongeautoriseerde software van derden gebruiken die is ontworpen om toegang te krijgen tot, te doorzoeken of te verzamelen uit de diensten van ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Elke ongeautoriseerde software van derden gebruiken die informatie onderschept, "minet" of anderszins verzamelt van of via ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Inhoud uploaden, verzenden, posten of anderszins beschikbaar stellen die, of die redelijkerwijs verwacht kan worden:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Beledigend, discriminerend, lasterlijk, obsceen, bedreigend, misbruikend, onfatsoenlijk of anderszins onwettig zijn, inclusief inhoud die anderen waarschijnlijk zal beledigen, beledigen of vernederen op basis van ras, religie, etniciteit, geslacht, leeftijd, seksuele geaardheid of enige fysieke of mentale handicap;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>Niet uw eigen werk zijn of inbreuk kunnen maken op het intellectueel eigendom of andere rechten van een andere persoon;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Een afbeelding of persoonlijke informatie van een andere persoon bevatten, tenzij u hun toestemming hebt; of</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>Grote hoeveelheden niet-gericht, ongewenst of herhaaldelijk inhoud bevatten; of</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>De Website of ons bedrijf verstoren, onderbreken of een onredelijke last vormen.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Als u van mening bent dat een gebruiker een van de bovengenoemde voorwaarden heeft geschonden, neem dan contact met ons op.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Wij behouden ons het recht voor om elke gebruiker te blokkeren, op te schorten of te annuleren, evenals elke lidmaatschapsregistratie op onze Website te wijzigen of te verwijderen, die door een gebruiker is geüpload, gepost, verzonden of anderszins beschikbaar is gesteld, zonder kennisgeving; eenzijdig retourzendingen van klanten te definiëren, te beperken, te weigeren en/of af te wijzen als gevolg van een onregelmatige of overmatige retourgeschiedenis. Door materiaal te uploaden, te verzenden, te posten of anderszins beschikbaar te stellen via de Website verleent u ${textBrand} een niet-exclusieve, wereldwijde, royaltyvrije, eeuwigdurende licentie om het materiaal in elke vorm en voor elk doel te gebruiken, te reproduceren, te bewerken en te exploiteren. De lidmaatschapsaanvraag kan worden geweigerd als de aanvrager in het verleden zijn/haar lidmaatschap heeft opgezegd.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Wij zijn niet verantwoordelijk voor en aanvaarden geen aansprakelijkheid met betrekking tot materiaal dat door een persoon, die niet wij is, op de Website is geüpload, gepost, verzonden of anderszins beschikbaar is gesteld. Wij onderschrijven geen enkele mening, advies of verklaring die door een persoon, die niet ${textBrand} is, wordt gedaan.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>U stemt ermee in ${textBrand} en elk van de functionarissen, werknemers, agenten, aannemers, leveranciers en licentiegevers (gezamenlijk "Gelieerde ondernemingen") van ${textBrand} te vrijwaren voor aansprakelijkheid, verliezen of schade (inclusief alle juridische en andere kosten op volledige basis van schadeloosstelling) die zij lijden of oplopen als gevolg van schending of het niet naleven van een van deze Gebruiksvoorwaarden, of enige andere wanprestatie of onrechtmatig gedrag met betrekking tot het onderwerp van deze Gebruiksvoorwaarden, van u of een van uw gelieerde ondernemingen.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Vrijwaring van Garanties',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Uw gebruik van de Website is geheel voor uw eigen risico. Wij wijzen alle garanties van welke aard dan ook af, hetzij uitdrukkelijk of stilzwijgend.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Wij behouden ons het recht voor om op elk moment en van tijd tot tijd de diensten van de Website (of een deel daarvan) te wijzigen of stop te zetten, al dan niet met kennisgeving. U stemt ermee in dat wij niet aansprakelijk zijn jegens u of een derde partij voor enige wijziging, opschorting of stopzetting van dergelijke diensten.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Wij geven geen garantie dat:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>De Website zal voldoen aan uw vereisten;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>De Website ononderbroken, tijdig, veilig en foutloos zal zijn;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Enig resultaat dat kan worden verkregen door het gebruik van de Website accuraat of betrouwbaar zal zijn; en</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>De kwaliteit van producten, diensten, informatie of ander materiaal dat u via de Website heeft aangeschaft of verkregen, aan uw verwachtingen zal voldoen.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Wij kunnen de berichten, standpunten, meningen, onderzoeken en aanbevelingen van individuen, gebruikers en organisaties van belang niet goedkeuren, en zijn niet verantwoordelijk voor dergelijke berichten, standpunten, meningen, onderzoeken en aanbevelingen, en onderschrijven geen enkele aanbeveling van een specifieke dienstverlener, behandelingstraject of commerciële transacties in effecten, investeringen of klassen van effecten, noch impliceert de opname op de Website van een link naar andere website(s) of bronnen enige vorm van goedkeuring door ons.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Wij bekijken of bewerken de berichten, standpunten, meningen, onderzoeken en aanbevelingen van gebruikers niet actief, zelfs niet als dergelijke berichten, standpunten, meningen, onderzoeken en aanbevelingen door gebruikers worden gedaan via de Website. Hoewel de informatie op de Website is verkregen uit bronnen waarvan wordt aangenomen dat ze betrouwbaar zijn, is geen van de gegevens, nieuws, informatie, rapporten, meningen of aanbevelingen (indien aanwezig) op maat gemaakt voor een specifieke persoon of groep.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Door informatie en gegevens beschikbaar te stellen op de Website, inclusief feiten, standpunten, meningen en aanbevelingen van individuen en organisaties van belang, geven wij en onze inhoudsproviders geen financieel, investerings-, fiscaal en juridisch advies en stellen wij ons ook niet voor als zodanig, noch stellen wij ons voor als het geven van dergelijk advies. U moet altijd relevant professioneel advies inwinnen voordat u dergelijke beslissingen neemt.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>Wij kunnen bepaalde gebieden op de Website monitoren of beoordelen waar u communicaties verzendt of plaatst om de efficiëntie, nuttigheid en passendheid van het gebruik te bevorderen. Wij verbinden ons er echter niet toe om alle berichten of communicaties te monitoren of beoordelen, en wij wijzen elke aansprakelijkheid met betrekking tot alle Website-materialen af, ongeacht of dit voortvloeit uit auteursrecht, handelsmerken, laster, privacy, obsceniteit of anderszins.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Deze Website en alle daarin opgenomen informatie worden door ${textBrand} verstrekt "zoals het is" en "zoals beschikbaar". ${textBrand} en/of zijn respectievelijke gelieerde ondernemingen, aandeelhouders, partners, bestuurders, medewerkers en agenten:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Doen geen enkele expliciete of impliciete verklaringen of garanties met betrekking tot de werking en beschikbaarheid van deze Website of de informatie, inhoud, materialen of producten die op de Website worden gepresenteerd. U stemt uitdrukkelijk in met het feit dat uw gebruik van deze Website geheel voor uw eigen risico is, voor zover maximaal toegestaan door de toepasselijke wetgeving;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Wijzen alle expliciete of impliciete garanties af, waaronder, maar niet beperkt tot, impliciete garanties van verkoopbaarheid en geschiktheid voor een bepaald doel;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Garanderen niet dat de Website, de servers ervan, of e-mails die door ons worden verzonden, vrij zijn van virussen of andere schadelijke componenten of fouten; en</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Zijn niet aansprakelijk voor enige schade van welke aard dan ook, inclusief, maar niet beperkt tot directe, indirecte, incidentele, bestraffende en gevolgschade, of enige andere verliezen of aansprakelijkheden die voortvloeien uit (a) het gebruik of de onmogelijkheid om de Website te gebruiken; (b) enige beslissing, handeling of nalatigheid van een partij die vertrouwt op de inhoud van de Website; (c) bedrijfsonderbrekingen; (d) vertragingen/onderbrekingen bij de toegang tot de Website; (e) het niet leveren, verkeerd leveren, beschadigen, vernietigen of anderszins wijzigen van gegevens; (f) verlies of schade van welke aard dan ook als gevolg van het openen van links naar derden op de Website; (g) computervirussen, systeemstoringen of -storingen in verband met het gebruik van de Website; of (h) vertragingen, onnauwkeurigheden, fouten of weglatingen in de inhoud van de Website.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Door toegang te krijgen tot onze Website neemt u alle risico's die gepaard gaan met het gebruik ervan, inclusief, maar niet beperkt tot het risico dat uw computer, apparaten, software of gegevens kunnen worden beschadigd door een virus dat wordt overgedragen door deze Website of door inhoud of websites van derden, evenals de risico's met betrekking tot de kwaliteit van de service die wordt geleverd door de dienstverlener (indien van toepassing). Voor zover toegestaan door de wet, worden hierbij alle garanties, voorwaarden en claims (hetzij expliciet of stilzwijgend) met betrekking tot deze Website uitgesloten.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Vrijwaringen',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>U stemt ermee in om ${textBrand}, onze partners, aandeelhouders, partners, directeuren, medewerkers en agenten te vrijwaren van en schadeloos te stellen voor alle claims of vorderingen, inclusief advocatenkosten, ingediend door een derde partij als gevolg van of voortvloeiend uit enige inhoud die u indient, plaatst, verzendt of beschikbaar stelt op de website, uw gebruik van de website, uw verbinding met de website, uw schending van de gebruiksvoorwaarden of uw schending van de rechten van een andere partij.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Aansprakelijkheidsbeperking',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Wij zijn niet aansprakelijk jegens u voor enige directe, indirecte, incidentele, bijzondere, gevolg- of exemplarische schade, inclusief maar niet beperkt tot schade door gederfde winst, goodwill, gebruik, gegevens of andere immateriële verliezen (zelfs als we op de hoogte zijn gebracht van de mogelijkheid van dergelijke schade), voortvloeiend uit:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Het gebruik of de onmogelijkheid om de Website te gebruiken;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>De kosten van de aanschaf van vervangende producten en diensten als gevolg van producten, gegevens, informatie of diensten die zijn gekocht of verkregen of berichten die zijn ontvangen of transacties die zijn aangegaan via de Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Ongerechtigde toegang tot of wijziging van uw transmissies of gegevens;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Uitspraken of gedragingen van een derde op de Website; en</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Elke andere aangelegenheid met betrekking tot de Website.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Elke vordering van welke aard dan ook door een van beide partijen tegen de andere partij en elke actie tegen de andere partij moet worden ingesteld binnen 03 maanden (of de toegestane minimumperiode zoals bepaald in de respectievelijk van toepassing zijnde wet) na de datum waarop de oorzaak van de actie zich voordoet, na afloop waarvan de benadeelde partij geen verdere vorderingen meer heeft tegen de andere partij.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Koppelingen en Inhoud van Derden',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>De Website kan hyperlinks bevatten naar websites die worden beheerd door derden, uitsluitend voor referentie- en informatiedoeleinden. ${textBrand} heeft deze websites niet beoordeeld en kan niet verantwoordelijk worden gehouden voor het privacybeleid en de praktijken van andere websites, zelfs als u ze bezoekt via links vanaf de Website. We raden u aan de algemene voorwaarden en het privacybeleid van elke bezochte website te controleren en contact op te nemen met de eigenaar of exploitant als u vragen of zorgen heeft. Op dezelfde manier kan ${textBrand} niet verantwoordelijk worden gehouden voor het privacybeleid en de praktijken van de eigenaren of exploitanten van die websites van derden als u naar de Website bent gelinkt vanaf een website van derden. We raden u aan het beleid van die website van derden te controleren en contact op te nemen met de eigenaar of exploitant als u vragen of zorgen heeft. ${textBrand} geeft geen garanties of vertegenwoordigingen met betrekking tot een gelinkte website van derden. ${textBrand} sluit elke aansprakelijkheid voor verlies of schade volledig uit voor zover toegestaan door de wet, die voortvloeit uit enige inhoud op dergelijke websites.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Ter verduidelijking, het aanwezig zijn van een link naar een website van derden wordt niet beschouwd als een aanbeveling van die websites of enig advies of informatie erop door ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Privacybeleid',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Alle persoonlijke gegevens over u vallen onder ons privacybeleid.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Het privacybeleid wordt geacht te zijn opgenomen in de gebruiksvoorwaarden door verwijzing naar deze clausule.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Indien een gebruiker van de Website, of een andere persoon, een door ${textBrand} gepubliceerd document bekijkt en reageert met enige informatie of feedback, zoals vragen, opmerkingen, suggesties of dergelijke, wordt deze informatie als niet-vertrouwelijk beschouwd en heeft ${textBrand} de vrijheid om deze informatie te gebruiken, te reproduceren, bekend te maken en te verspreiden naar enige andere persoon.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Bovendien heeft ${textBrand} het recht om ideeën, concepten, knowhow of technieken die in die informatie/feedback zijn opgenomen, voor elk doel zonder enige vergoeding te gebruiken of te exploiteren, inclusief, maar niet beperkt tot, het ontwikkelen, produceren en vermarkten van producten die dergelijke informatie bevatten.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Intellectueel Eigendom en Auteursrecht',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>U erkent en aanvaardt dat alle auteursrechten, databaserechten en alle andere intellectuele eigendomsrechten die bestaan ​​in het ontwerp, de lay-out, processen, functies, gegevens en inhoud van de Website eigendom zijn van ${textBrand}, onze informatieleveranciers of licentiegevers. De Website is eigendom van en wordt beheerd door ${textBrand}. Geen enkel deel van de Website, de inhoud of een van de onderliggende software en databases mag worden gekopieerd of gereproduceerd, gedeeld met derden, omgekeerd geëngineerd, gedemonteerd of gebruikt om enig afgeleid werk te maken (zowel in fysieke als elektronische vorm) zonder de voorafgaande schriftelijke toestemming van ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Overmacht',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Wij zijn niet aansprakelijk jegens u voor enige vertraging of tekortkoming in de nakoming van onze verplichtingen jegens u om redenen buiten onze controle, waaronder, maar niet beperkt tot, overmacht, oorlog of terrorisme, epidemie, pandemie, natuurrampen, internetverbinding problemen, systeemproblemen, veranderingen in toepasselijke wetten, voorschriften of overheidsbeleid, en tekorten aan leveringen en diensten.
              </div>
          </div>
        `
    },
    {
      title: 'Beëindiging',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Wij kunnen onder bepaalde omstandigheden en zonder voorafgaande kennisgeving naar eigen goeddunken uw account (indien van toepassing) onmiddellijk beëindigen. Redenen voor een dergelijke beëindiging omvatten onder meer, maar niet uitsluitend:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Overtredingen van de Gebruiksvoorwaarden, of andere opgenomen documenten, richtlijnen of regels;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Verzoek van wetshandhavingsinstanties of andere overheidsinstanties;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Zelf geïnitieerde verwijdering van het account;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Overtreding van intellectuele eigendomsrechten van anderen;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Stopzetting of materiële wijziging van de diensten op de website, of een deel daarvan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Onverwachte technische of beveiligingsproblemen; of</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Langdurige periodes van inactiviteit.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  De beëindiging van uw account zal resulteren in:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Verwijdering van de toegang en blokkering van verder gebruik van alle aanbiedingen van de website die met dat account zijn verbonden; en</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Verwijdering van uw wachtwoord en alle gerelateerde informatie, bestanden en inhoud die verband houden met of zich in uw account bevinden (of een deel daarvan).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>De beëindiging van uw account laat andere rechten of vorderingen die wij krachtens de Gebruiksvoorwaarden, de wet of billijkheid zouden kunnen hebben, onverlet en heeft geen invloed op enige opgebouwde rechten of verplichtingen, noch op het in werking treden of de voortzetting van enige bepaling die uitdrukkelijk of impliciet bedoeld is om in werking te treden of van kracht te blijven na een dergelijke beëindiging.</div>
              </div
          </div>
        `
    },
    {
      title: 'Intellectuele Eigendomsrechten',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Bij het uploaden van afbeeldingen, foto's en/of beschrijvingen van aanbiedingen naar de Website ("<strong>Inhoud</strong>"), verleent u ons een wereldwijde, royaltyvrije, onherroepelijke licentie om de Inhoud te gebruiken, op te slaan, te hosten, te reproduceren, te wijzigen, aan te passen, van een watermerk te voorzien, te communiceren, te publiceren, openbaar uit te voeren, openbaar weer te geven, afgeleide werken te maken en de Inhoud te verspreiden. Door de Inhoud te uploaden, garandeert u dat u het auteursrecht van de Inhoud bezit en dus de vereiste rechten heeft om het aan ons in licentie te geven zoals vermeld. We hebben het recht om watermerken aan te brengen op de foto's in de Inhoud, en het auteursrecht op de eindfoto's met watermerk berust uitsluitend bij ons. Alle andere genoemde namen, producten en merken zijn de intellectuele eigendomsrechten van hun respectieve eigenaren. Geen materiaal dat via de Website wordt verstrekt, inclusief tekst, afbeeldingen, code, beoordelingen, ranglijsten, databases, geaggregeerde informatie, Website-materiaal en/of software, mag op geen enkele wijze en in geen enkele vorm worden gereproduceerd, gewijzigd, aangepast, verspreid, gepubliceerd, getoond, geüpload, gepost, verzonden, of gekoppeld zonder onze uitdrukkelijke, voorafgaande schriftelijke goedkeuring, en de goedkeuring van de respectieve auteursrechthouders en merkhouders. De Website en Website-materiaal zijn uitsluitend voor persoonlijk gebruik en u mag geen zaken doen met andere partijen met betrekking tot dergelijke diensten en inhoud. Deze transacties omvatten commerciële transacties en transacties die nadelige gevolgen hebben voor de commerciële waarde van de Website en Website-materiaal.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Algemeen',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>De Gebruiksvoorwaarden worden beheerst door en geïnterpreteerd naar de wetten van de Republiek Singapore, en alle claims of geschillen van welke aard dan ook zullen onderworpen zijn aan de exclusieve jurisdictie van de rechtbanken van de Republiek Singapore.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Ons nalaten om enig recht of bepaling van de Gebruiksvoorwaarden uit te oefenen of af te dwingen, zal niet worden beschouwd als een afstand van een dergelijk recht of een dergelijke bepaling. Indien enige bepaling van de Gebruiksvoorwaarden door een bevoegde rechter ongeldig wordt bevonden, komen de partijen echter overeen dat de rechter moet proberen de intenties van de partijen zoals weergegeven in de bepaling uit te voeren, en de overige bepalingen van de Gebruiksvoorwaarden blijven volledig van kracht en effect.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Elk account dat u bij ons heeft, is niet overdraagbaar en alle rechten op een dergelijk account komen te vervallen bij uw overlijden. Bij ontvangst van een kopie van uw overlijdensakte wordt uw account beëindigd en worden alle inhoud die u in uw account heeft gegenereerd permanent verwijderd.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Alle meldingen aan ons krachtens de Gebruiksvoorwaarden dienen te worden verzonden via e-mail naar: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
