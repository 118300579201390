import React from 'react';

function SignoutIcon(props: any) {
  return (
    <svg {...props} viewBox="0 0 20 18" fill="none">
      <path
        d="M5 13L0.999996 9M0.999996 9L5 5M0.999996 9L15 9M9 13V14C9 15.6569 10.3431 17 12 17H16C17.6569 17 19 15.6569 19 14V4C19 2.34315 17.6569 1 16 1H12C10.3431 1 9 2.34315 9 4V5"
        stroke={props.color || '#C9C8CC'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SignoutIcon;