import React, { useState } from 'react';
import styled from 'styled-components';

// COMPONENTS
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from './Dialog';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

// ICONS
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from 'components/buttons';

export type QuestionDialogProps = Omit<DialogProps, 'buttons' | 'onClose'> & {
  confirmText?: React.ReactNode,
  cancelText?: string,
  message?: string;
  onConfirm: Function,
  onAfterClose?: Function,
  onCancel?: Function,
  closeDialog?: Function,
  onClose?: Function,
  actionRight?: boolean,
  textCenter?: boolean,
  widthButton?: string,
  widthContent?: string,
  paddingRightAction?: number,
}

const QuestionDialog = ({
  confirmText,
  cancelText,
  message,
  onConfirm,
  onCancel,
  onAfterClose,
  widthContent,
  closeDialog,
  children,
  onClose,
  actionRight = false,
  textCenter = true,
  widthButton,
  paddingRightAction,
  ...restProps
}: QuestionDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }

    if (onAfterClose) {
      onAfterClose();
    }
  }

  const handleCloseDialog = () => {
    if (closeDialog) {
      closeDialog()
    }
  }

  return (
    <DialogStyled
      onClose={onClose && handleCloseDialog}
      {...restProps}
    >
      <Stack flexDirection="row" justifyContent="flex-end" mb={2}>
        <div onClick={handleCloseDialog}>
          <ClearIcon style={{ color: '#fff', cursor: 'pointer' }} fontSize='medium' />
        </div>
      </Stack>
      <Stack flexDirection="row" justifyContent="center" mb={2}>
        <Box width={`${widthContent ? widthContent : '90%'}`} textAlign={textCenter ? 'center' : 'start'}>
          {message ? (
            <Typography mt={2} variant="body1">
              {message}
            </Typography>
          ) : children}
        </Box>
      </Stack>
      <Stack
        sx={{
          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
          pb: 2,
          pr: {
            xs: 0,
            md: paddingRightAction || 0,
            lg: paddingRightAction || 0,
            xl: paddingRightAction || 0,
          },
          justifyContent: actionRight ? 'flex-end' : 'center',
        }}
      >
        <Stack
          sx={{
            flexDirection: { xs: 'row', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
            width: {
              xs: '100%',
              md: widthButton ? widthButton : 'auto',
              lg: widthButton ? widthButton : 'auto',
              xl: widthButton ? widthButton : 'auto'
            },
            columnGap: 2,
            rowGap: 2,
            alignItems: { xs: 'center', md: 'center' },
            justifyContent: actionRight ? 'flex-end' : 'center',
          }}
        >
          {onCancel &&
            <Button
              onClick={handleClose}
              color='primary'
              width={actionRight ? '150px' : 'fit-content'}
              style={{ lineHeight: '1rem' }}
            >
              {cancelText || t('Cancel')}
            </Button>
          }
          <Button
            onClick={handleConfirm}
            color='accent'
            width={actionRight ? '150px' : 'fit-content'}
            style={{ lineHeight: '1rem' }}
          >
            {confirmText || t('Confirm')}
          </Button>
        </Stack>
      </Stack>
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog)`
  .title-popup {
    font-weight: 500;
  }

  .title-info {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .title-info-sub {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: .2rem;
  }
  
  .title-info-content {
    font-size: 13px;
    font-weight: 400;
  }
  
  .box-selected-bareshell {
    border: 1px solid ${p => p.theme.palette.primary.contrastText};
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    cursor: pointer;

    .title-box-bareshell {
      font-weight: 500;
    }

    .sub-box-bareshell {
      font-size: 400.;
    }

    &.active {
      background: ${p => p.theme.palette.secondary.dark};
      border: 1px solid ${p => p.theme.palette.accent.main};
    }
  }
`

export default QuestionDialog;
