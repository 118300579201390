export const DATA_FAQ_FR = (textBrand: string) => {
  return [
    {
      title:
        `Mais qui est donc ${textBrand} International et qu'apportent-ils vraiment ?`,
      description: `
              <p>Ok, parlons du marché immobilier principal et de l'expérience de vente ennuyeuse et dépassée qui l'accompagne. ${textBrand} International est là pour changer la donne. Nous sommes là pour secouer les choses, mon ami.</p>
              <p>Nous co-développons les maisons du futur. Comment ? En exploitant la puissance de la technologie de personnalisation. Ouais, tu as bien entendu. ${textBrand} présente un outil de design intérieur 3D époustouflant piloté par une intelligence artificielle. Ouais, tu m'as bien compris. Ce petit bijou te permet de naviguer dans ton espace de vie en temps réel, pièce par pièce, et de prémodéliser ta future maison.</p>
              <p>Avec ${textBrand}, tu embarques pour un voyage fluide, agréable et transparent. Plus de surprises, plus de retards. C'est un voyage sans accroc, efficace, vers la maison de tes rêves.</p>
             `,
    },
    {
      title:
        "Qu'est-ce qu'un Segment et comment fonctionne-t-il lors de l'achat de ma nouvelle maison ?",
      description: `
              <p>Ok, mon ami, parlons des segments et de leur magie dans le parcours d'achat de maison de ${textBrand}.</p>
              <p>Un segment est comme une pièce du puzzle qui peut être combinée pour créer un espace qui répond à tes désirs et à ton budget.</p>
              <p>Imagine-le comme une surface de vie distincte que tu peux acquérir individuellement ou en plusieurs exemplaires, en personnalisant ta nouvelle maison selon tes besoins et tes envies.</p>
             `,
    },
    {
      title:
        "Pourquoi un Accord de Réservation est-il si important dans le processus d'achat ?",
      description: `
              <p>Ok, parlons de cette chose qu'on appelle un Accord de Réservation. Tu te demandes peut-être : "Qu'est-ce que c'est et pourquoi devrais-je m'en soucier ?" Eh bien, mon ami, laisse-moi t'expliquer. Ce petit bijou est un élément crucial dans l'aventure folle de l'achat d'une propriété. Pourquoi ? Parce que c'est comme un filet de sécurité, mon ami.</p>
              <p>Lorsque tu signes un Accord de Réservation, tu dis essentiellement : "Attends, j'ai les yeux sur cette propriété et je veux m'assurer qu'elle est à moi."</p>
              <p>Pourquoi est-ce si important ? Tout simplement parce que cela te donne la tranquillité d'esprit et protège tes intérêts. Cela garantit que la propriété ne sera pas vendue sous ton nez pendant que tu es occupé à régler les détails.</p>
             `,
    },
    {
      title:
        "Que contient exactement un Contrat de Vente et d'Achat et quel est son rôle dans l''achat d'une maison ?",
      description: `
              <p>Un Contrat de Vente et d'Achat est comme le Saint Graal d'un achat de maison. C'est le document qui scelle l'accord et te protège dans le processus.</p>
              <p>Laisse-moi t'expliquer : lorsque tu achètes une maison, tu ne te contentes pas de remettre ton argent et d'espérer le meilleur. C'est là que ce petit bijou entre en jeu. C'est un accord juridiquement contraignant entre toi et le vendeur qui énonce tout l'essentiel. Nous parlons du prix de la maison, des conditions de remise et du calendrier de clôture de la transaction.</p>
              <p>Il ne s'agit pas d'un simple accord de poignée de main, mon ami. C'est un engagement sérieux qui garantit que les deux parties sont responsables. Alors, lorsque tu es prêt à faire cet achat important, assure-toi d'avoir un solide Contrat de Vente et d'Achat en place. C'est ton filet de sécurité dans le monde sauvage de l'achat d'une maison.</p>
             `,

    },
    {
      title:
        `Comment transformer tes rêves les plus fous d''achat de maison en réalité grâce aux pouvoirs mystérieux de ${textBrand} International ?`,
      description: `
              <p>Alors, tu meurs d'envie de savoir comment tu peux réaliser ton rêve de maison grâce à ${textBrand} International ? Eh bien, accroche-toi, mon ami, car je vais te l'expliquer en détail.</p>
              <p><strong>Étape un :</strong> choisis le pays de tes rêves. Où te vois-tu vivre ta meilleure vie ? Fais ton choix et plonge.</p>
              <p><strong>Étape deux :</strong> nous avons une multitude de projets répertoriés, qui n'attendent que toi pour être explorés. Perds-toi dans les détails, imagine-toi dans ces espaces, et visualise la vie que tu mèneras.</p>
              <p><strong>Étape trois :</strong> réduis tes options, mon ami. Sélectionne le nombre de segments que tu peux te permettre et l'étage qui te parle.</p>
              <p><strong>Étape quatre :</strong> C'est là que les choses deviennent excitantes. Prépare-toi pour la partie époustouflante. Tu peux prémodéliser ton appartement en 3D, instantanément. Ouais, on parle de donner vie à ta maison de rêve sous tes yeux. Ajuste-le, joue avec, et rends-le parfait. Ah, et ai-je mentionné que tu auras une visibilité complète et une transparence sur les coûts ? Pas de magouilles ici.</p>
              <p><strong>Étape cinq :</strong> Une fois satisfait de ton chef-d'œuvre, il est temps de conclure l'accord. Signe ton accord de réservation et ton contrat d'achat en ligne, et voilà !</p>
              <p>Maintenant, assieds-toi, mon ami, et laisse monter l'anticipation. Avant que tu ne le réalises, tu te détendras dans la maison dont tu as toujours rêvé. ${textBrand} est là pour que ça se réalise.</p> 
            `,
    },

    {

      title:
        "Comment rejoindre la liste d''attente et rester informé des propriétés bientôt lancées ?",
      description: `
              <p>Alors, tu es impatient de mettre la main sur ces propriétés tant attendues, qui arrivent bientôt, hein ? Je ne te blâme pas. Eh bien, voici le secret pour rester informé et prendre de l'avance sur le jeu.</p>
              <p>Rends-toi sur notre site web et cherche la section "Restez Informé". C'est comme un trésor pour les acheteurs comme toi. Recherche l'option pour rejoindre la liste d'attente. Indique ton adresse e-mail, et considère-toi officiellement dans la boucle.</p>
              <p>Nous veillerons à ce que tu sois le premier informé lorsque ces projets passionnants seront prêts à être lancés. C'est comme avoir un pass backstage dans le monde de l'immobilier. Alors n'attends pas une seconde de plus, mon ami. Rejoins la liste d'attente et que l'anticipation commence.</p>
            `,
    },

    {
      title:
        `Comment ${textBrand} International accorde-t-il la priorité à la protection de tes précieuses données, et quels partenariats ont-ils mis en place pour assurer des transactions sécurisées ?`,
      description: `
              <p>Chez ${textBrand} International, nous ne plaisantons pas en matière de protection des données. Tes informations sont comme de l'or pour nous, et nous les protégeons de toutes nos forces. Nous n'utilisons pas de solutions médiocres. Nous nous sommes associés aux meilleurs parmi les meilleurs pour garantir la sécurité de tes données.</p>
              <p>Nous avons Tazapay, une entreprise basée à Singapour qui se spécialise dans la conservation sécurisée des détails de paiement. Mais ça ne s'arrête pas là. Nous avons poussé les choses plus loin avec la technologie innovante de Pandadoc pour la signature numérique des contrats.</p>
              <p>Nous ne laissons aucune pierre non retournée, mon ami. Tes données sont notre priorité absolue, et nous avons les bons partenariats en place pour nous en assurer.</p>
            `,
    },
  ];
}
