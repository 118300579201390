export const DATA_FAQ_AR = (textBrand: string) => {
  return [
    {
      title:
        `من هو ${textBrand} International وماذا يقدمون؟`,
      description: `
            <p>حسنًا، دعنا نتحدث عن سوق العقارات الأساسية وتجربة البيع المملة والقديمة التي تأتي معها. ${textBrand} International هنا لتغيير اللعبة. نحن هنا لنقلب الأمور رأسًا على عقب، يا صديقي.</p>
            <p>نحن نشارك في تطوير منازل المستقبل. كيف نفعل ذلك؟ من خلال استغلال قوة تقنية التخصيص الشخصي. نعم، صحيح. ${textBrand} يقدم أداة تصميم داخلي ثلاثية الأبعاد مذهلة تعمل بواسطة الذكاء الاصطناعي. نعم، سمعتني صحيحًا. يتيح لك هذا الأداة الرائعة تنقل مساحة معيشتك في الوقت الفعلي، غرفة بغرفة، وتصمم منزلك المستقبلي.</p>
            <p>مع ${textBrand}، ستبدأ في رحلة سلسة وممتعة وشفافة. لا مفاجآت بعد الآن، ولا تأخيرات بعد الآن. إنها رحلة متسقة وفعالة نحو منزل أحلامك.</p>
           `,
    },
    {
      title:
        'ما هو القطاع وكيف يعمل عند شراء منزل جديد؟',
      description: `
            <p>حسنًا، يا صديقي، دعنا نتحدث عن القطاع وكيف يعمل سحره في رحلة شراء منزل ${textBrand}.</p>
            <p>القطاع هو عبارة عن قطعة من اللغز يمكن دمجها لإنشاء فضاء يلبي رغباتك وميزانيتك.</p>
            <p>فكر فيه كحجم مميز لمساحة المعيشة يمكنك الحصول عليها بشكل فردي أو متعدد، وتخصيص منزلك الجديد لتلبية كل احتياجاتك ورغباتك.</p>
           `,
    },
    {
      title:
        'لماذا الاتفاقية التأمينية مهمة جدًا في عملية الشراء؟',
      description: `
            <p>حسنًا، دعنا نتحدث عن هذا الشيء المسمى اتفاقية التأمينية. قد تفكر، "ما هذا، ولماذا يجب علي أن أهتم بذلك؟" حسنًا، دعني أشرح لك. إنه عنصر حاسم في جولة شراء الممتلكات. لماذا؟ لأنه عبارة عن شبكة سلامة تقريبًا، يا صديقي.</p>
            <p>عند توقيع اتفاقية التأمينية، فأنت تقول ببساطة، "انتظر، لدي عيني على هذا الممتلكات، وأريد التأكد أنه سيكون ملكي."</p>
            <p>لماذا هذا مهم جدًا؟ ببساطة. إنه يمنحك راحة البال ويحمي مصالحك. إنه يضمن أن الممتلكات لن تباع من تحتك بينما أنت مشغول بإجراءات الشراء.</p>
           `,
    },
    {
      title:
        'ما هو مُضمَّن بالضبط في اتفاقية البيع والشراء وماذا تعني في عملية شراء المنزل؟',
      description: `
            <p>اتفاقية البيع والشراء هي مثل الكأس المقدس لشراء المنزل. إنها الوثيقة التي تختتم الصفقة وتحميك في العملية.</p>
            <p>دعني أشرح لك: عند شرائك منزلاً، لا يمكنك مجرد تسليم أموالك والأمل في أفضل. هذا حيث يأتي هذا الأمر. إنه اتفاق قانوني ملزم بينك وبين البائع يحدد جميع الأمور المهمة. نتحدث عن سعر المنزل، وشروط التسليم، والجدول الزمني لإغلاق الصفقة.</p>
            <p>هذا ليس اتفاق مصافحة عابرة يا صديقي. إنه التزام جدي يضمن مساءلة الطرفين. لذلك، عندما تكون جاهزًا لإجراء تلك الصفقة الكبيرة، تأكد من أن لديك اتفاقية البيع والشراء القوية. إنها الشبكة الأمانية في عالم شراء المنازل الغامض.</p>
           `,

    },
    {
      title:
        `كيف يمكنك تحويل أحلامك العقارية إلى واقع من خلال قوى ${textBrand} International الغامضة؟`,
      description: `
            <p>إذا كنت تحمم نفسك بعشق معرفة كيف يمكنك تحويل منزل أحلامك إلى واقع من خلال ${textBrand} International، حسنًا، اربط حزام الأمان يا صديقي لأنني على وشك أن أخبرك.</p>
            <p><strong>الخطوة الأولى:</strong> اختر البلد الذي ترغب في العيش فيه. أين ترى نفسك تعيش أفضل حياتك على الإطلاق؟ اختر ولنبدأ الانطلاق.</p>
            <p><strong>الخطوة الثانية:</strong> لدينا مجموعة متنوعة من المشاريع المدرجة، في انتظارك لاستكشافها. تعثر في التفاصيل، تصوّر نفسك في تلك الأماكن، وتخيل الحياة التي ستعيشها.</p>
            <p><strong>الخطوة الثالثة:</strong> اضيق نطاق الاختيارات، يا صديقي. حدد عدد الأقساط التي تستطيع تحملها والطابق الذي يتحدث إلى روحك.</p>
            <p><strong>الخطوة الرابعة:</strong> هنا حيث تصبح الأمور شيقة. الآن، استعد للجزء المذهل. يمكنك تصميم شقتك مسبقًا بتقنية ثلاثية الأبعاد رائعة. نعم، نحن نتحدث عن تحويل منزل أحلامك إلى حقيقة أمام عينيك. قم بضبطها، العب معها، واجعلها مثالية. أوه، هل ذكرت لك أنك ستحصل على رؤية وشفافية كاملة بشأن التكاليف؟ هناك أي عمل غير شريف هنا.</p>
            <p><strong>الخطوة الخامسة:</strong> بمجرد أن تكون راضيًا عن أعمالك الفنية، حان الوقت لختم الصفقة. قم بتوقيع اتفاقية الحجز واتفاقية الشراء عبر الإنترنت، ويلاه!</p>
            <p>الآن، اجلس يا صديقي ودع الترقب ينمو. في غضون وقت قصير، ستكون مسترخيًا في المنزل الذي طالما تخيلته. ${textBrand} هنا لجعل هذا يحدث.</p>
          `,
    },

    {

      title:
        'كيف يمكنك الانضمام إلى قائمة الانتظار والبقاء على اطلاع على العقارات القادمة قريبًا؟',
      description: `
            <p>إذا كنت حريصًا على الحصول على تلك العقارات القادمة قريبًا، فأنا لا ألومك. حسنًا، هذا هو السر للبقاء محدثًا والتفوق على الجميع.</p>
            <p>اذهب إلى موقعنا على الويب وابحث عن القسم المسمى "ابقَ على علم". إنها مثل كنز للمشترين المثلي. ابحث عن خيار الانضمام إلى قائمة الانتظار. أدخل عنوان بريدك الإلكتروني، واعتبر نفسك رسميًا على دراية.</p>
            <p>سنتأكد من أنك الأول من يعلم عندما تكون تلك المشاريع المثيرة جاهزة للانطلاق والتنفيذ. إنها مثل الحصول على بطاقة دخول خلف الكواليس لعالم العقارات. لذلك لا تضيع وقتًا آخر يا صديقي. انضم إلى قائمة الانتظار ودع الترقب يبدأ</p>
          `,
    },

    {
      title:
        `كيف يعتبر ${textBrand} International أولوية حماية بياناتك القيمة، وما هي الشراكات المعتمدة لضمان المعاملات الآمنة؟`,
      description: `
            <p>في ${textBrand} International، لا نمزح عندما يتعلق الأمر بحماية البيانات. معلوماتك تعد كالذهب بالنسبة لنا، ونحرص على حمايتها بكل ما نملك. نحن لا نلعب بحلول من الدرجة الثانية. لقد شكلنا شراكات مع أفضل الموردين للحفاظ على بياناتك آمنة وسليمة.</p>
            <p>لدينا Tazapay، الشركة المتخصصة ومقرها في سنغافورة، التي تعمل على الحفاظ على تفاصيل الدفع الخاصة بك في أمان تام. ولكن لا يتوقف الأمر هنا. قمنا برفع المستوى باستخدام تقنية Pandadoc الابتكارية لتوقيع العقود الرقمية.</p>
            <p>نحن لا نترك أي جانب دون مراقبة يا صديقي. بياناتك هي أولويتنا العليا، ونحن نملك الشراكات المناسبة لضمان ذلك.</p>
          `,
    },
  ];
}
