export const DATA_FAQ_VI = (textBrand: string) => {
  return [
    {
      title:
        `${textBrand} International là ai và họ mang đến điều gì cho bàn thờ giao dịch này?`,
      description: `
            <p>Được rồi, chúng ta hãy nói về thị trường bất động sản chính và trải nghiệm bán hàng cũ kỹ, nhàm chán. ${textBrand} International đã đến để thay đổi trò chơi. Chúng tôi đã đến để làm lay động mọi thứ, bạn ơi.</p>
            <p>Chúng tôi đang cùng nhau phát triển những ngôi nhà của tương lai. Làm thế nào chúng tôi làm được điều đó? Bằng cách khai thác sức mạnh của công nghệ cá nhân hóa. Đúng, đúng vậy. ${textBrand} giới thiệu một công cụ thiết kế nội thất 3D đỉnh cao được điều khiển bởi trí tuệ nhân tạo chẳng hạn. Đúng vậy, bạn nghe đúng rồi. Con quái vật này cho phép bạn điều hướng không gian sống của mình theo thời gian thực, từ phòng này đến phòng khác, và dự kiến ngôi nhà tương lai của bạn.</p>
            <p>Với ${textBrand}, bạn sẽ bắt đầu một hành trình mà trên đó mọi thứ diễn ra trôi chảy, thú vị và minh bạch. Không còn bất ngờ, không còn chậm trễ. Đó là một cuộc hành trình liền mạch, hiệu quả đến ngôi nhà mơ ước của bạn.</p>
           `,
    },
    {
      title:
        'Segment là gì và làm thế nào nó hoạt động khi mua nhà mới?',
      description: `
            <p>Được rồi, bạn ơi, hãy nói về các đoạn và cách chúng hoạt động kỳ diệu trong thế giới hành trình mua nhà của ${textBrand}.</p>
            <p>Một đoạn là như một mảnh ghép trong bức tranh có thể kết hợp để tạo ra một không gian phù hợp với mong muốn và ngân sách của bạn.</p>
            <p>Hãy tưởng tượng đó như một khu vực sống có diện tích riêng biệt mà bạn có thể mua từng phần hoặc nhiều phần, cá nhân hóa ngôi nhà mới của bạn đáp ứng mọi nhu cầu và mong muốn.</p>
           `,
    },
    {
      title:
        'Tại sao Hợp đồng đặt cọc lại quan trọng đến vậy trong quá trình mua nhà?',
      description: `
            <p>Được rồi, hãy nói về cái gọi là Hợp đồng đặt cọc. Bạn có thể đang nghĩ, "Đó là cái gì và tại sao tôi lại quan tâm?" À, bạn ơi, để tôi giải thích cho bạn hiểu. Đứa con hoang này là một yếu tố quan trọng trong cuộc hành trình điên rồ để mua một tài sản. Tại sao? Vì nó giống như một mạng an toàn, bạn ơi.</p>
            <p>Khi bạn ký một Hợp đồng đặt cọc, bạn đang nói rằng, "Đợi đã, tôi đang chú ý đến tài sản này, và tôi muốn đảm bảo nó là của tôi."</p>
            <p>Tại sao điều đó lại quan trọng đến vậy? Đơn giản. Nó mang lại sự yên tâm và bảo vệ lợi ích của bạn. Nó đảm bảo rằng tài sản sẽ không được bán trong khi bạn đang bận đi tìm đường chấm dứt và gạch đầu dòng.</p>
           `,
    },
    {
      title:
        'Hợp đồng mua bán bao gồm những gì và nó có liên quan gì đến việc mua nhà?',
      description: `
            <p>Hợp đồng Mua bán là như chìa khóa thánh thần của một giao dịch mua nhà. Đó là tài liệu gói gọn mọi thứ và bảo vệ bạn trong quá trình đó.</p>
            <p>Để tôi nói rõ: khi bạn mua một ngôi nhà, bạn không chỉ đưa tiền mặt và hy vọng mọi thứ tốt đẹp. Đó là lúc chàng trai này xuất hiện. Đó là một hợp đồng có hiệu lực pháp lý giữa bạn và người bán mô tả tất cả những điều quan trọng. Chúng ta nói về giá nhà, điều kiện giao nhà và thời gian hoàn tất giao dịch.</p>
            <p>Đây không phải là một thỏa thuận bằng cái bắt tay thông thường, bạn ơi. Đó là cam kết nghiêm túc đảm bảo cả hai bên chịu trách nhiệm. Vì vậy, khi bạn sẵn sàng thực hiện giao dịch lớn đó, hãy đảm bảo bạn có Hợp đồng Mua & Bán đáng tin cậy. Nó là mạng an toàn của bạn trong thế giới đầy rẫy thách thức của mua nhà.</p>
           `,
    },
    {
      title:
        `Làm thế nào để biến những giấc mơ mua nhà mơ ước của bạn thành hiện thực thông qua những sức mạnh bí ẩn của ${textBrand} International?`,
      description: `
            <p>Vậy, bạn muốn biết làm thế nào để biến ngôi nhà mơ ước của bạn thành hiện thực thông qua ${textBrand} International? Được rồi, hãy chuẩn bị tinh thần, bạn ơi, vì tôi sẽ giải thích cho bạn.</p>
            <p><strong>Bước một:</strong> chọn quốc gia mong muốn của bạn. Bạn thấy mình sống cuộc sống tốt nhất ở đâu? Chọn lựa và chúng ta sẽ khám phá ngay.</p>
            <p><strong>Bước hai:</strong> chúng tôi có một loạt các dự án đang chờ bạn khám phá. Lạc mất trong chi tiết, tưởng tượng mình trong những không gian đó và mường tượng cuộc sống bạn sẽ sống.</p>
            <p><strong>Bước ba:</strong> hạn chế lựa chọn, bạn ơi. Chọn số lượng đoạn bạn có thể mua và tầng phù hợp với linh hồn của bạn.</p>
            <p><strong>Bước bốn:</strong> Đây là lúc thú vị. Chuẩn bị sẵn sàng cho phần quan trọng khiến bạn phát rồ. Bạn có thể trước mô phỏng căn hộ của mình trong 3D tuyệt đẹp. Đúng, chúng tôi đang nói về việc đưa ngôi nhà mơ ước của bạn trở thành hiện thực trước mắt. Điều chỉnh nó, chơi với nó và làm cho nó hoàn hảo. Ồ, và tôi đã nói chưa, bạn sẽ có toàn bộ khả năng quan sát và minh bạch về các chi phí? Không có việc gian lận ở đây.</p>
            <p><strong>Bước năm:</strong> Khi bạn hài lòng với kiệt tác của mình, đến lúc ký kết hợp đồng đặt cọc và hợp đồng mua bán trực tuyến, và voilà!</p>
            <p>Bây giờ, ngồi lại, bạn ơi, và để sự háo hức tích tụ. Chẳng bao lâu nữa, bạn sẽ thư giãn trong ngôi nhà mà bạn luôn mơ ước. ${textBrand} ở đây để biến nó thành hiện thực.</p>
          `,
    },
    {
      title:
        'Làm thế nào để tham gia danh sách chờ và cập nhật thông tin về các tài sản sắp ra mắt?',
      description: `
            <p>Vậy bạn háo hức muốn sở hữu những tài sản "Sắp ra mắt" khó nắm bắt đó, phải không? Tôi không trách bạn. À, đây là bí quyết để luôn cập nhật và tiên phong.</p>
            <p>Hãy ghé trang web của chúng tôi và tìm đoạn "Thông tin cập nhật." Đó giống như một kho tàng báu dành cho những người mua nhà như bạn. Tìm tùy chọn tham gia danh sách chờ. Nhập địa chỉ email của bạn và coi mình đã chính thức nằm trong vòng nguyệt quế.</p>
            <p>Chúng tôi sẽ đảm bảo bạn là người đầu tiên biết khi những dự án hấp dẫn đó sẵn sàng chào đón. Đó giống như có một vé backstage đến thế giới bất động sản. Vậy nhanh chóng đừng lãng phí thêm một giây nào nữa, bạn ơi. Tham gia danh sách chờ và để sự háo hức bắt đầu.</p>
          `,
    },
    {
      title:
        `${textBrand} International làm thế nào để ưu tiên bảo vệ dữ liệu quý giá của bạn và có những đối tác nào để đảm bảo giao dịch an toàn?`,
      description: `
            <p>Tại ${textBrand} International, chúng tôi không chơi xấu khi đến việc bảo vệ dữ liệu. Thông tin của bạn đối với chúng tôi như vàng, và chúng tôi bảo vệ nó bằng mọi cách. Chúng tôi không đùa với những giải pháp kém chất lượng. Chúng tôi đã hợp tác với những đối tác tốt nhất trong việc bảo vệ dữ liệu của bạn.</p>
            <p>Chúng tôi đã có Tazapay, một công ty có trụ sở tại Singapore chuyên về việc giữ chi tiết thanh toán của bạn an toàn. Nhưng nó không chỉ dừng lại ở đó. Chúng tôi đã đưa mọi thứ lên một tầm cao mới với công nghệ đột phá của Pandadoc cho việc ký hợp đồng kỹ thuật số.</p>
            <p>Chúng tôi không bỏ sót bất kỳ điều gì, bạn ơi. Dữ liệu của bạn là ưu tiên hàng đầu của chúng tôi, và chúng tôi đã có các đối tác phù hợp để đảm bảo điều đó.</p>
          `,
    },
  ];
};
