export const TERM_DE = (textBrand: string) => {
  return [
    {
      title: 'Akzeptanz der Bedingungen',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Durch den Zugriff auf und die Nutzung von <a href="https://levitate.homes">https://levitate.homes</a> (die "Website") schließen Sie eine rechtlich bindende Vereinbarung über die hier festgelegten Bedingungen ("Nutzungsbedingungen") mit uns, ${textBrand} International Pte. Ltd. ("wir", "uns", "unser" oder "${textBrand}"), dem Eigentümer und Betreiber der Website, ab.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Sie akzeptieren die Nutzungsbedingungen uneingeschränkt und bedingungslos, einschließlich der Datenschutzrichtlinie.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Wir können diese Nutzungsbedingungen jederzeit ändern, indem wir eine überarbeitete Version auf der Website veröffentlichen. Es wird jedoch keine individuelle Benachrichtigung an Sie gesendet. Alle geänderten Nutzungsbedingungen ersetzen alle früheren Versionen davon. Wenn Sie den Änderungen oder Modifikationen der Nutzungsbedingungen nicht zustimmen, sollten Sie sofort die Nutzung eines der Dienste auf der Website einstellen. Nachdem eine solche Änderung in Kraft getreten ist, werden wir Ihr fortgesetztes Nutzen der Website als Ihre Zustimmung und Vereinbarung mit einer solchen Änderung betrachten. Sie können jederzeit die aktuellste Version der Nutzungsbedingungen einsehen.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Von Zeit zu Zeit werden wir auf der Website Richtlinien und Regeln im Zusammenhang mit der Nutzung der Website veröffentlichen. Alle diese Richtlinien oder Regeln werden hiermit durch Bezugnahme in die Nutzungsbedingungen aufgenommen.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Nutzung der Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>Indem Sie auf die Website <a href="https://levitate.homes">https://levitate.homes</a> (die "Website") zugreifen und sie nutzen, schließen Sie eine rechtlich bindende Vereinbarung über die hier festgelegten Bedingungen ("Nutzungsbedingungen") mit uns, ${textBrand} International Pte. Ltd. ("wir" oder "${textBrand}"), dem Eigentümer und Betreiber der Website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>Sie akzeptieren die Nutzungsbedingungen einschließlich der Datenschutzrichtlinie uneingeschränkt und bedingungslos.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Wir können diese Nutzungsbedingungen jederzeit durch Veröffentlichung einer überarbeiteten Version auf der Website ändern. Es wird jedoch keine individuelle Benachrichtigung an Sie gesendet. Jegliche geänderten Nutzungsbedingungen ersetzen alle früheren Versionen davon. Wenn Sie mit den Änderungen an den Nutzungsbedingungen nicht einverstanden sind, sollten Sie die Nutzung aller Dienste auf der Website umgehend einstellen. Nachdem eine solche Änderung in Kraft getreten ist, gilt Ihre fortgesetzte Nutzung der Website als Ihre Zustimmung und Vereinbarung mit dieser Änderung. Sie können jederzeit die aktuellste Version der Nutzungsbedingungen einsehen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>Von Zeit zu Zeit werden wir auf der Website Richtlinien und Regeln in Bezug auf die Nutzung der Website veröffentlichen. Alle diese Richtlinien oder Regeln sind hier durch Bezugnahme in die Nutzungsbedingungen einbezogen.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Website-Material',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Durch den Zugriff auf die Website und die Nutzung können Sie Informationen, Daten, Texte, Software, Fotos, Grafiken, Videos, Nachrichten, Konzepte, Ideen, Designs, Zeichnungen, Produktlisten, Preise oder andere Materialien ausgesetzt sein, die wir und/oder andere Benutzer der Website auf der Website hochladen, posten, per E-Mail senden, übertragen oder anderweitig verfügbar machen ("Website-Material"), sei es öffentlich oder privat.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Sie verstehen, dass das Website-Material beleidigend, anstößig oder bedenklich sein kann.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Sie verstehen, dass das Website-Material nur für Ihre persönliche und nichtkommerzielle Nutzung verwendet werden darf und nicht für Veröffentlichung, Vertrieb, Übertragung, Weiterübertragung, Weiterverteilung, Übertragung, Vervielfältigung oder Zirkulation an jemand anderen im selben Unternehmen oder in derselben Organisation, und nicht für die Veröffentlichung auf anderen Websites oder Foren, Nachrichtengruppen, E-Mail-Listen, elektronischen Anschlagtafeln oder Internet-Chats, die von anderen Websites betrieben werden.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Sie stimmen zu, das Website-Material ohne unsere vorherige schriftliche Zustimmung nicht auf einer anderen Website oder einem anderen Server zu reproduzieren, zu duplizieren, zu kopieren, zu verkaufen, wiederzuverkaufen, anzuzeigen oder anderweitig bereitzustellen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Wir haften unter keinen Umständen für Fehler oder Auslassungen in jedem Website-Material oder für Verluste oder Schäden jeglicher Art, die durch die Nutzung von auf der Website veröffentlichtem, per E-Mail gesendetem, übertragenem oder anderweitig verfügbarem Website-Material entstehen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>Sie erkennen an und stimmen zu, dass das Format und der Inhalt der Website jederzeit ohne vorherige Ankündigung geändert werden können; der Betrieb der Website kann aus Gründen der Unterstützung oder Wartung ausgesetzt oder eingestellt werden, um ihren Inhalt zu aktualisieren oder aus jedem anderen Grund; und der Zugriff auf die Website kann entweder in Bezug auf bestimmte Benutzer oder im Allgemeinen jederzeit und ohne vorherige Ankündigung beendet werden.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Genauigkeit der Informationen auf unserer Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Obwohl ${textBrand} angemessene Anstrengungen unternimmt, um genaue Informationen auf unserer Website einzuschließen, geben wir keine Gewährleistungen oder Zusicherungen hinsichtlich der Genauigkeit der bereitgestellten Informationen, sei es stillschweigend oder anderweitig, soweit gesetzlich zulässig. ${textBrand} schließt jegliche Haftung für Fehler oder Auslassungen im Inhalt der Website in vollem Umfang, soweit gesetzlich zulässig, aus.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Die auf der Website enthaltenen Angaben dienen nur zu Orientierungszwecken. Die Genauigkeit der Angaben zu Einzelheiten, Beschreibungen, Abmessungen, Zustandsbeschreibungen, notwendigen Genehmigungen für die Nutzung und Besetzung sowie anderen Details auf der Website ist nicht garantiert und dient nur der allgemeinen Orientierung. Es wird empfohlen, sich nicht auf diese Angaben als Tatsachenbehauptungen oder Darstellungen zu verlassen und sich hinsichtlich ihrer Genauigkeit selbst zu überzeugen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand} empfiehlt, dass Sie, da der Kauf von Immobilien eine bedeutende finanzielle Verpflichtung darstellt, alle angemessenen Schritte unternehmen, um sicherzustellen, dass jede von Ihnen in Betracht gezogene Immobilie Ihren Anforderungen in jeder Hinsicht entspricht. Wir haben angemessene Schritte unternommen, um sicherzustellen, dass die auf der Website enthaltenen Materialien korrekt sind, jedoch sollten sie nicht als Ersatz für Ihre eigenen Untersuchungen und Überlegungen angesehen werden. Es wird empfohlen, geeignete Schritte zu unternehmen, um Informationen zu verifizieren, auf die Sie sich verlassen möchten.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Trotz aller Bemühungen, eine genaue Darstellung von Farbe und Design jedes Produkts auf unserer Website bereitzustellen, können tatsächliche Farben und Designs aufgrund unterschiedlicher Gerätebildschirme, Beleuchtung am Installationsort, geringfügiger Unterschiede in der Produktveredelung im Laufe der Zeit und anderer Faktoren geringfügig variieren. ${textBrand} übernimmt keine Haftung für Farb- oder Designunterschiede, die nicht auf unsere Fehler zurückzuführen sind. Bei Kauf einer Immobilie oder eines Produkts über unsere Website akzeptieren Sie das geringe Risiko, dass es geringfügige Abweichungen zwischen den tatsächlichen Farben und Designs und der Darstellung auf unserer Website geben kann.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Verpflichtungen des Nutzers',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Zusätzlich zu den in diesen Nutzungsbedingungen festgelegten Verpflichtungen dürfen Sie NICHT:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Die Website in Verletzung geltender Gesetze oder Vorschriften nutzen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Sich auf eine Weise verhalten, die anderen schadet, missbraucht, belästigt, verfolgt, diffamiert, verleumdet, bedroht oder anderweitig beleidigt, oder die Website zu solchen Zwecken nutzen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Handlungen vornehmen, die dem Benutzer(n), Dritten oder ${textBrand} Unannehmlichkeiten, Nachteile oder Schäden verursachen oder verursachen könnten;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Handlungen betrügerisch beantragen oder die Mitgliedschaftsregistrierung mehrfach und betrügerisch halten;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Sich an unmoralischen oder potenziell unmoralischen Aktivitäten beteiligen, einschließlich, aber nicht beschränkt auf Prostitution und Pornografie;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Sich als eine andere Person oder Einheit ausgeben oder falsch angeben oder anderweitig Ihre Zugehörigkeit zu einer Person oder Einheit fälschlicherweise darstellen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Falsche, ungenaue oder irreführende Informationen bereitstellen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Sich an potenziell betrügerischen, verdächtigen oder illegalen Aktivitäten und/oder Transaktionen beteiligen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Unaufgeforderte E-Mails an jeden Benutzer senden oder die Dienste auf der Website nutzen, um Zahlungen für das Versenden oder Unterstützen des Versendens von unaufgeforderten E-Mails an Dritte zu sammeln;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Die persönlichen Informationen eines anderen Benutzers an einen Dritten weitergeben oder verteilen oder diese Informationen für Marketingzwecke ohne die ausdrückliche Zustimmung dieses Benutzers verwenden;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Maßnahmen ergreifen, die eine unangemessene oder unverhältnismäßig hohe Belastung für die Infrastruktur der Website darstellen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Inhalte auf die Website hochladen oder veranlassen, die Viren oder andere Elemente enthalten, die den Zugriff auf die Website beeinträchtigen, stören oder negativ beeinflussen könnten;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Sich oder andere Benutzer zum Spamming, Phishing, unangemessenen, bösartigen oder, nach unserem alleinigen Ermessen, betrügerischen Klicken, Impressions oder Marketingaktivitäten im Zusammenhang mit ${textBrand} veranlassen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>Nach unserem alleinigen Ermessen das Ansehen von ${textBrand} beeinträchtigen oder beflecken;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Dienste auf der Website oder Teile davon, einschließlich Quellcodes oder Algorithmen, modifizieren, anpassen, umformatieren, neu kompilieren, übertragen, veröffentlichen, lizenzieren, rückentwickeln, zerlegen, rekonstruieren, dekompilieren, kopieren oder abgeleitete Werke erstellen, übertragen oder verkaufen, außer soweit von uns ausdrücklich schriftlich genehmigt oder gesetzlich gestattet;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Identifikationen, Marken, Urheberrechtsvermerke oder andere Hinweise von jedem Aspekt der Dienste auf der Website verändern, entfernen, verdecken oder anderweitig entstellen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Jede nicht autorisierte Software von Dritten verwenden, die darauf abzielt, auf die Dienste von ${textBrand} zuzugreifen, diese zu durchsuchen oder zu sammeln;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Jede nicht autorisierte Software von Dritten verwenden, die Informationen von oder durch ${textBrand} abfängt, "schürft" oder anderweitig sammelt;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Inhalte hochladen, übertragen, veröffentlichen oder anderweitig verfügbar machen, die oder die vernünftigerweise erwartet werden können:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Beleidigend, diskriminierend, diffamierend, obszön, bedrohlich, missbräuchlich, anstößig oder anderweitig rechtswidrig sind, einschließlich Inhalte, die voraussichtlich aufgrund von Rasse, Religion, Ethnizität, Geschlecht, Alter, sexueller Orientierung oder einer physischen oder geistigen Behinderung andere beleidigen, beleidigen oder demütigen;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>Nicht Ihr eigenes Werk sind oder die geistigen Eigentumsrechte oder andere Rechte einer anderen Person verletzen könnten;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Bilder oder persönliche Informationen einer anderen Person enthalten, es sei denn, Sie haben deren Zustimmung;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>Große Mengen von nicht zielgerichtetem, unerwünschtem oder wiederholtem Inhalt enthalten; oder</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Die Website oder unser Geschäft stören, unterbrechen oder eine unangemessene Belastung darstellen.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Wenn Sie der Meinung sind, dass ein Benutzer gegen eine der oben genannten Bedingungen verstoßen hat, kontaktieren Sie uns bitte.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Wir behalten uns das Recht vor, jeden Benutzer zu blockieren, zu suspendieren oder zu kündigen, sowie jede Mitgliedschaftsregistrierung auf unserer Website zu ändern oder zu entfernen, die von einem Benutzer hochgeladen, veröffentlicht, übertragen oder anderweitig verfügbar gemacht wird, ohne Benachrichtigung; Rückerstattungen von Kunden aufgrund einer unregelmäßigen oder übermäßigen Rücknahmegeschichte einseitig zu definieren, zu begrenzen, abzulehnen und/oder abzulehnen. Durch das Hochladen, Übertragen, Veröffentlichen oder anderweitige Zur Verfügung Stellen von Material über die Website gewähren Sie ${textBrand} eine nicht-exklusive, weltweite, gebührenfreie und unbefristete Lizenz zur Nutzung, Vervielfältigung, Bearbeitung und Auswertung des Materials in jeglicher Form und für jeden Zweck. Die Mitgliedschaftsanfrage kann abgelehnt werden, wenn der Antragsteller in der Vergangenheit seine Mitgliedschaft gekündigt hat.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Wir übernehmen keine Verantwortung und haften nicht für Material, das von einer Person, die nicht wir ist, auf die Website hochgeladen, veröffentlicht, übertragen oder anderweitig verfügbar gemacht wird. Wir unterstützen keine Meinungen, Ratschläge oder Aussagen, die von einer Person stammen, die nicht ${textBrand} ist.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Sie erklären sich damit einverstanden, ${textBrand} und jeden der Mitarbeiter, Angestellten, Agenten, Auftragnehmer, Lieferanten und Lizenzgeber (gemeinsam "Partner") von ${textBrand} in Bezug auf jede Haftung, Verluste oder Schäden (einschließlich aller rechtlichen und sonstigen Kosten auf vollständiger Indemnitätbasis) zu entschädigen, die durch Ihre Verletzung oder Nichtbefolgung dieser Nutzungsbedingungen oder durch andere Verstöße oder unrechtmäßiges Verhalten im Zusammenhang mit dem Gegenstand dieser Nutzungsbedingungen entstehen, sei es von Ihnen oder einem Ihrer Partner.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Haftungsausschluss für Gewährleistungen',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Ihre Nutzung der Website erfolgt auf eigene Gefahr. Wir lehnen alle Gewährleistungen jeglicher Art ab, ob ausdrücklich oder stillschweigend.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Wir behalten uns das Recht vor, jederzeit und von Zeit zu Zeit, mit oder ohne Ankündigung, jegliche Dienste der Website (oder Teile davon) zu ändern oder einzustellen. Sie erklären sich damit einverstanden, dass wir Ihnen oder einem Dritten gegenüber nicht für Änderungen, Aussetzungen oder Einstellungen solcher Dienste haften.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Wir geben keine Gewährleistung für Folgendes:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Die Website entspricht Ihren Anforderungen;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Die Website ist ununterbrochen, pünktlich, sicher und fehlerfrei;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Ergebnisse, die durch die Nutzung der Website erzielt werden können, sind korrekt oder verlässlich; und</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Die Qualität von Produkten, Dienstleistungen, Informationen oder anderen Materialien, die Sie über die Website erworben oder erhalten haben, entspricht Ihren Erwartungen.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Wir können die Nachrichten, Ansichten, Meinungen, Forschungen und Empfehlungen von Einzelpersonen, Benutzern und Organisationen von Interesse weder befürworten noch für diese verantwortlich sein und lehnen auch jede Empfehlung eines bestimmten Dienstleistungsanbieters, Behandlungsverlaufs oder kommerzieller Transaktionen in Wertpapieren, Investitionen oder Wertpapierklassen auf der Website ab. Die Einbindung eines Links zu anderen Website(s) oder Ressourcen auf der Website impliziert keinerlei Form von Befürwortung durch uns.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Wir überprüfen oder bearbeiten die Nachrichten, Ansichten, Meinungen, Forschungen und Empfehlungen der Benutzer nicht aktiv, auch wenn solche Nachrichten, Ansichten, Meinungen, Forschungen und Empfehlungen von den Benutzern über die Website gemacht werden. Obwohl die Informationen auf der Website aus als zuverlässig geltenden Quellen stammen, wurden keine der Daten, Nachrichten, Informationen, Berichte, Meinungen oder Empfehlungen (falls vorhanden) speziell für eine bestimmte Person oder Gruppe von Personen angepasst.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Durch die Bereitstellung von Informationen und Daten auf der Website, einschließlich Fakten, Ansichten, Meinungen und Empfehlungen von Einzelpersonen und Organisationen von Interesse, geben wir und unsere Inhaltsanbieter keine finanzielle, investitionsbezogene, steuerliche und rechtliche Beratung und stellen auch nicht dar oder geben nicht vor, eine solche Beratung zu erteilen. Sie sollten vor der Entscheidung stets relevante professionelle Beratung einholen.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>Wir können bestimmte Bereiche auf der Website überwachen oder überprüfen, in denen Sie Kommunikationen übermitteln oder veröffentlichen, um die Effizienz, Nützlichkeit und Angemessenheit der Nutzung zu fördern. Wir verpflichten uns jedoch nicht dazu, alle Beiträge oder Kommunikationen zu überwachen oder zu überprüfen, und lehnen jede Haftung für sämtliches Website-Material ab, unabhängig davon, ob es sich um Urheberrechts-, Marken-, Verleumdungs-, Datenschutz-, Obszönitäts- oder sonstige Rechtsverletzungen handelt.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Diese Website und alle darin enthaltenen Informationen werden von ${textBrand} auf einer "wie ist" und "wie verfügbar" Basis bereitgestellt. ${textBrand} und/oder seine jeweiligen verbundenen Unternehmen, Aktionäre, Partner, leitenden Angestellten, Direktoren, Mitarbeiter und Beauftragten:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Geben keinerlei Zusicherungen oder Gewährleistungen jeglicher Art, sei es ausdrücklich oder stillschweigend, in Bezug auf den Betrieb und die Verfügbarkeit dieser Website oder die Informationen, Inhalte, Materialien oder Produkte, die auf der Website präsentiert werden. Sie stimmen ausdrücklich zu, dass Ihre Nutzung dieser Website bis zum maximal zulässigen Umfang nach geltendem Recht auf Ihr eigenes Risiko erfolgt;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Lehnen alle Gewährleistungen, sei es ausdrücklich oder stillschweigend, ab, einschließlich, aber nicht beschränkt auf stillschweigende Gewährleistungen der Marktgängigkeit und Eignung für einen bestimmten Zweck;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Gewährleisten nicht, dass die Website, ihre Server oder von uns gesendete E-Mails frei von Viren oder anderen schädlichen Komponenten oder Fehlern sind; und</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Haften nicht für Schäden jeglicher Art, einschließlich, aber nicht beschränkt auf direkte, indirekte, zufällige, strafende und Folgeschäden oder sonstige Verluste oder Haftungen, die sich aus (a) der Nutzung oder Unfähigkeit zur Nutzung der Website ergeben; (b) jeder von einer Partei aufgrund des Website-Inhalts getroffenen Entscheidung, Aktion oder Untätigkeit; (c) Betriebsunterbrechungen; (d) Verzögerungen/Unterbrechungen beim Zugriff auf die Website; (e) Nichtlieferung, fehlerhafte Lieferung, Korruption, Zerstörung oder sonstige Änderung von Daten; (f) Verlust oder Schäden jeglicher Art, die sich aus dem Zugriff auf Links von Dritten auf der Website ergeben; (g) Computerviren, Systemfehler oder -störungen im Zusammenhang mit der Nutzung der Website; oder (h) Verzögerungen, Ungenauigkeiten, Fehler oder Auslassungen im Inhalt der Website ergeben.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Durch den Zugriff auf unsere Website übernehmen Sie alle Risiken, die mit der Nutzung verbunden sind, einschließlich, aber nicht beschränkt auf das Risiko, dass Ihr Computer, Ihre Geräte, Ihre Software oder Ihre Daten durch Viren, die von dieser Website oder von Inhalten oder Websites Dritter übertragen werden, beschädigt werden können, sowie die Risiken in Bezug auf die Servicequalität, die von dem Dienstleister (sofern vorhanden) bereitgestellt wird. Soweit gesetzlich zulässig, werden hiermit alle Gewährleistungen, Bedingungen und Ansprüche (ob ausdrücklich oder stillschweigend) in Bezug auf diese Website ausgeschlossen.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Haftungsansprüche',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Sie erklären sich damit einverstanden, ${textBrand}, unsere Partner, Aktionäre, Partner, Direktoren, Mitarbeiter und Vertreter von jeglichen Ansprüchen oder Forderungen, einschließlich Anwaltsgebühren, freizustellen und schadlos zu halten, die von Dritten aufgrund oder im Zusammenhang mit Inhalten, die Sie auf der Website eingeben, veröffentlichen, übertragen oder zur Verfügung stellen, Ihrem Gebrauch der Website, Ihrer Verbindung zur Website, Ihrer Verletzung der Nutzungsbedingungen oder Ihrer Verletzung von Rechten einer anderen Partei, erhoben werden.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Haftungsbeschränkung',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Wir haften Ihnen gegenüber nicht für direkte, indirekte, zufällige, besondere, Folge- oder exemplarische Schäden, einschließlich, aber nicht beschränkt auf Schäden wegen entgangener Gewinne, Ruf, Nutzung, Daten oder anderer immaterieller Verluste (auch wenn wir auf die Möglichkeit solcher Schäden hingewiesen wurden), die sich ergeben aus:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Der Nutzung oder der Unfähigkeit, die Website zu nutzen;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Die Kosten für den Erwerb von Ersatzprodukten und -dienstleistungen, die sich aus Produkten, Daten, Informationen oder Dienstleistungen ergeben, die über die Website erworben oder erhalten wurden, oder aus Nachrichten, die empfangen wurden, oder aus Transaktionen, die über die Website getätigt wurden;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Unbefugtem Zugriff oder Änderung Ihrer Übertragungen oder Daten;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Aussagen oder Verhalten von Dritten auf der Website; und</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Jede andere Angelegenheit in Bezug auf die Website.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Jegliche Ansprüche einer beliebigen Art von einer Partei gegen die andere Partei und jegliche Klagen gegen die andere Partei müssen innerhalb von 03 Monaten (oder der gemäß den jeweils anwendbaren Gesetzen zulässigen Mindestfrist) nach dem Datum, an dem der Grund für die Klage entsteht, eingeleitet werden, nach deren Ablauf die betroffene Partei keinerlei weitere Ansprüche gegen die andere Partei geltend machen kann.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Verlinkung und Inhalte von Dritten',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>Die Website kann Hypertext-Links zu Websites enthalten, die von Dritten nur zu Referenz- und Informationszwecken betrieben werden. ${textBrand} hat diese Websites nicht überprüft und kann nicht für die Datenschutzrichtlinien und -praktiken anderer Websites verantwortlich gemacht werden, auch wenn Sie auf sie über Links von der Website aus zugreifen. Wir empfehlen Ihnen, die Geschäftsbedingungen und Datenschutzrichtlinien jeder besuchten Website zu überprüfen und bei Fragen oder Bedenken den Eigentümer oder Betreiber zu kontaktieren. Ebenso kann ${textBrand} nicht für die Datenschutzrichtlinien und -praktiken der Eigentümer oder Betreiber dieser Websites von Dritten verantwortlich gemacht werden, wenn Sie von einer Website eines Dritten auf die Website gelinkt wurden. Wir empfehlen Ihnen, die Richtlinien dieser Website von Dritten zu überprüfen und den Eigentümer oder Betreiber zu kontaktieren, wenn Sie Fragen oder Bedenken haben. ${textBrand} gibt keine Garantien oder Zusicherungen in Bezug auf verlinkte Websites von Dritten ab. ${textBrand} schließt jegliche Haftung für Verluste oder Schäden im gesetzlich zulässigen Umfang aus, die sich aus Inhalten auf solchen Websites ergeben.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Zur Klarstellung wird durch die Anwesenheit eines Links zu einer Website von Dritten eine Empfehlung für diese Websites oder jegliche Ratschläge oder Informationen auf ihnen durch ${textBrand} nicht angenommen.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Datenschutzrichtlinie',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Alle personenbezogenen Daten über Sie unterliegen unserer Datenschutzrichtlinie.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Die Datenschutzrichtlinie gilt durch Verweis auf diese Klausel als Bestandteil der Nutzungsbedingungen.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Falls ein Nutzer der Website oder eine andere Person ein von ${textBrand} veröffentlichtes Dokument einsehen und mit Informationen oder Rückmeldungen wie Fragen, Kommentaren, Vorschlägen oder Ähnlichem antworten sollte, gelten diese Informationen als nicht vertraulich, und ${textBrand} ist berechtigt, diese Informationen für andere Personen zu verwenden, zu reproduzieren, offenzulegen und zu verteilen.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Zusätzlich ist ${textBrand} berechtigt, Ideen, Konzepte, Know-how oder Techniken, die in diesen Informationen/Rückmeldungen enthalten sind, für jeden Zweck kostenlos zu verwenden oder zu verwerten, einschließlich, aber nicht beschränkt auf die Entwicklung, Herstellung und Vermarktung von Produkten, die diese Informationen enthalten.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Geistiges Eigentum und Urheberrecht',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Sie erkennen an und akzeptieren, dass alle Urheberrechte, Datenbankrechte und sonstigen geistigen Eigentumsrechte, die im Design, Layout, Prozessen, Funktionen, Daten und Inhalten der Website bestehen, Eigentum von ${textBrand}, unseren Informationsanbietern oder Lizenzgebern sind. Die Website ist im Besitz von ${textBrand} und wird von uns betrieben. Kein Teil der Website, ihres Inhalts oder einer ihrer zugrunde liegenden Software und Datenbanken darf ohne vorherige schriftliche Zustimmung von ${textBrand} kopiert oder reproduziert, mit Dritten geteilt, rückentwickelt, rückassembliert oder verwendet werden, um irgendein abgeleitetes Werk zu erstellen (ob in physischer oder elektronischer Form).</div>
              </div>
          </div>
        `
    },
    {
      title: 'Höhere Gewalt',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Wir haften Ihnen gegenüber nicht für Verzögerungen oder Ausfälle bei der Erfüllung unserer Verpflichtungen Ihnen gegenüber aus Gründen, die außerhalb unserer Kontrolle liegen, einschließlich, aber nicht beschränkt auf höhere Gewalt, Krieg oder Terrorismus, Epidemie, Pandemie, Naturkatastrophen, Internetverbindungsprobleme, Systemprobleme, Änderungen an geltenden Gesetzen, Vorschriften oder staatlichen Richtlinien sowie Engpässe bei Lieferungen und Dienstleistungen.
              </div>
          </div>
        `
    },
    {
      title: 'Kündigung',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Wir können unter bestimmten Umständen und ohne vorherige Ankündigung nach unserem alleinigen Ermessen Ihr Konto sofort kündigen (falls vorhanden). Gründe für eine solche Kündigung sind unter anderem, jedoch nicht ausschließlich:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Verstöße gegen die Nutzungsbedingungen oder andere eingebundene Dokumente, Richtlinien oder Regeln;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Anfragen von Strafverfolgungsbehörden oder anderen Regierungsbehörden;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Eigeninitiierte Löschung des Kontos;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Verletzung der geistigen Eigentumsrechte Dritter;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Einstellung oder wesentliche Änderung der Dienste auf der Website oder eines Teils davon;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Unerwartete technische oder Sicherheitsprobleme; oder</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Längere Zeiträume der Inaktivität.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Die Kündigung Ihres Kontos hat zur Folge:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Aufhebung des Zugriffs und Verbot der weiteren Nutzung aller Angebote der Website, die mit diesem Konto verbunden sind; und</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Löschung Ihres Passworts sowie aller damit verbundenen Informationen, Dateien und Inhalte in Ihrem Konto (oder Teilen davon).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Die Kündigung Ihres Kontos beeinträchtigt keine anderen Rechte oder Ansprüche, die wir nach den Nutzungsbedingungen, nach geltendem Recht oder nach Billigkeit haben könnten, und berührt keine bestehenden Rechte oder Verbindlichkeiten oder das Inkrafttreten oder die Fortsetzung einer Regelung, die ausdrücklich oder stillschweigend beabsichtigt ist, nach einer solchen Kündigung in Kraft zu treten oder in Kraft zu bleiben.</div>
              </div
          </div>
        `
    },
    {
      title: 'Kündigung',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Wir können unter bestimmten Umständen und ohne vorherige Ankündigung nach unserem alleinigen Ermessen Ihr Konto sofort kündigen (falls vorhanden). Gründe für eine solche Kündigung sind unter anderem, jedoch nicht ausschließlich:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Verstöße gegen die Nutzungsbedingungen oder andere eingebundene Dokumente, Richtlinien oder Regeln;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Anfragen von Strafverfolgungsbehörden oder anderen Regierungsbehörden;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Eigeninitiierte Löschung des Kontos;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Verletzung der geistigen Eigentumsrechte Dritter;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Einstellung oder wesentliche Änderung der Dienste auf der Website oder eines Teils davon;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Unerwartete technische oder Sicherheitsprobleme; oder</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Längere Zeiträume der Inaktivität.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Die Kündigung Ihres Kontos hat zur Folge:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Aufhebung des Zugriffs und Verbot der weiteren Nutzung aller Angebote der Website, die mit diesem Konto verbunden sind; und</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Löschung Ihres Passworts sowie aller damit verbundenen Informationen, Dateien und Inhalte in Ihrem Konto (oder Teilen davon).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Die Kündigung Ihres Kontos beeinträchtigt keine anderen Rechte oder Ansprüche, die wir nach den Nutzungsbedingungen, nach geltendem Recht oder nach Billigkeit haben könnten, und berührt keine bestehenden Rechte oder Verbindlichkeiten oder das Inkrafttreten oder die Fortsetzung einer Regelung, die ausdrücklich oder stillschweigend beabsichtigt ist, nach einer solchen Kündigung in Kraft zu treten oder in Kraft zu bleiben.</div>
              </div
          </div>
        `
    },
    {
      title: 'Geistiges Eigentum',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Bei Hochladen von Bildern, Fotografien und/oder Angebotsbeschreibungen auf die Website (der "<strong>Inhalt</strong>"), räumen Sie uns eine weltweite, gebührenfreie, unwiderrufliche Lizenz ein, den Inhalt zu nutzen, zu speichern, zu hosten, zu reproduzieren, zu ändern, anzupassen, mit einem Wasserzeichen zu versehen, zu kommunizieren, zu veröffentlichen, öffentlich aufzuführen, öffentlich darzustellen, abgeleitete Werke zu erstellen und den Inhalt zu verbreiten. Durch das Hochladen des Inhalts garantieren Sie, dass Sie das Urheberrecht des Inhalts besitzen und somit die erforderlichen Rechte haben, ihn uns wie angegeben zu lizenzieren. Wir haben das Recht, Wasserzeichen auf den Fotos im Inhalt anzubringen, und das Urheberrecht an den endgültigen mit Wasserzeichen versehenen Fotos liegt ausschließlich bei uns. Alle anderen erwähnten Namen, Produkte und Marken sind die geistigen Eigentumsrechte ihrer jeweiligen Inhaber. Kein über die Website bereitgestelltes Material, einschließlich Text, Grafiken, Code, Bewertungen, Rankings, Datenbanken, aggregierte Informationen, Website-Material und/oder Software, darf ohne unsere ausdrückliche schriftliche Genehmigung und die Zustimmung der jeweiligen Urheberrechts- und Markeninhaber in irgendeiner Weise und in irgendeiner Form reproduziert, modifiziert, angepasst, verbreitet, veröffentlicht, dargestellt, hochgeladen, veröffentlicht, übertragen oder verlinkt werden. Die Website und das Website-Material sind nur für Ihren persönlichen Gebrauch bestimmt, und Sie dürfen keine Geschäfte mit anderen Parteien in Bezug auf solche Dienstleistungen und Inhalte tätigen. Zu solchen Geschäften gehören Geschäfte und Transaktionen, die den kommerziellen Wert der Website und des Website-Materials beeinträchtigen.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Allgemein',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Die Nutzungsbedingungen unterliegen dem Recht der Republik Singapur und werden entsprechend ausgelegt. Alle Ansprüche oder Streitigkeiten jeglicher Art unterliegen der ausschließlichen Zuständigkeit der Gerichte der Republik Singapur.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Unsere Unterlassung oder Nichtausübung eines Rechts oder einer Bestimmung der Nutzungsbedingungen stellt keinen Verzicht auf dieses Recht oder diese Bestimmung dar. Sollte eine Bestimmung der Nutzungsbedingungen von einem zuständigen Gericht für ungültig erklärt werden, stimmen die Parteien jedoch zu, dass das Gericht sich bemühen sollte, den Absichten der Parteien gemäß der Bestimmung gerecht zu werden, und die übrigen Bestimmungen der Nutzungsbedingungen in vollem Umfang in Kraft und wirksam bleiben.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Jedes Konto, das Sie bei uns haben, ist nicht übertragbar, und alle Rechte an einem solchen Konto erlöschen mit Ihrem Tod. Nach Erhalt einer Kopie Ihrer Sterbeurkunde wird Ihr Konto geschlossen und alle Inhalte, die Sie in Ihrem Konto erstellt haben, werden dauerhaft gelöscht.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Alle Benachrichtigungen an uns gemäß den Nutzungsbedingungen sind per E-Mail an folgende Adresse zu senden: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ];
}
