import DesignHub, { TTimeSlots } from 'model/designHub';
import DesignHubTypes from './designHubTypes';

export type TDesignHubState = {
  designHubs: DesignHub[];
  designHubDetail?: DesignHub;
  designHubBookings: TTimeSlots[];
  saveYourDesign: boolean;
};

const initialData: TDesignHubState = {
  designHubs: [],
  designHubDetail: undefined,
  designHubBookings: [],
  saveYourDesign: false,
};

const DesignHubReducer = (
  state: TDesignHubState = initialData,
  actions: any
): TDesignHubState => {
  const { data } = actions;
  switch (actions.type) {
    case DesignHubTypes.SetDesignHubs:
      return {
        ...state,
        designHubs: data,
      };

    case DesignHubTypes.SetDesignHubDetail:
      return {
        ...state,
        designHubDetail: data,
      };
    case DesignHubTypes.SetBookingsByDesignHubId:
      return {
        ...state,
        designHubBookings: data,
      };
    case DesignHubTypes.SetSaveYourDesign:
      return {
        ...state,
        saveYourDesign: !state.saveYourDesign,
      };

    default:
      return state;
  }
};

export default DesignHubReducer;
