export const POLICY_MS = (textBrand: string) => {
  return [
    {
      title: 'Pengenalan',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>kami</strong>", "<strong>kami</strong>", "<strong>kami</strong>" atau "<strong>${textBrand}</strong>"), adalah pengendali <a href="https://levitate.homes">https://levitate.homes</a> (laman web "<strong>Laman web</strong>"), dan kami komited untuk melindungi Data Peribadi anda (seperti yang ditakrifkan dalam Fasal 2 di bawah) selaras dengan terma dan syarat di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Dasar Privasi ini (bersama dengan Terma Penggunaan kami dan dokumen lain yang dirujuk di dalamnya) menggariskan bagaimana Data Peribadi yang kami kumpulkan daripada anda, atau yang anda berikan kepada kami, akan digunakan, diproses, atau didedahkan oleh kami, termasuk bagaimana beberapa maklumat akan digunakan untuk memperibadikan pengiklanan yang diarahkan kepada anda. Dasar Privasi ini juga menerangkan jenis Data Peribadi yang kami kumpulkan, bagaimana kami menjaga keselamatannya, serta bagaimana anda boleh mengakses atau mengubahsuai Data Peribadi anda yang disimpan oleh kami. Dasar Privasi ini merangkumi penggunaan anda terhadap Laman web, dan cara lain di mana anda mungkin bersambung atau berinteraksi dengan kami melalui penggunaan produk dan perkhidmatan kami (seterusnya dirujuk sebagai "Perkhidmatan").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Sila baca dengan teliti untuk memahami dasar dan amalan kami mengenai Data Peribadi anda dan bagaimana kami akan memprosesnya.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>Kecuali terhad oleh undang-undang yang berkenaan, dengan menggunakan Laman web dan Perkhidmatan, anda bersetuju bahawa sebarang Data Peribadi yang berkaitan dengan anda yang dikumpulkan oleh kami atau yang diberikan oleh anda dari semasa ke semasa boleh digunakan, diproses, dan didedahkan untuk tujuan dan kepada pihak-pihak seperti yang dinyatakan dalam Dasar Privasi ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Sebagai sebahagian daripada usaha kami untuk memastikan bahawa kami mengurus, melindungi, dan memproses Data Peribadi anda dengan betul, kami akan mengkaji semula dasar, prosedur, dan proses kami dari semasa ke semasa. Kami boleh meminda Dasar Privasi ini pada bila-bila masa dengan memaparkan versi yang terkini di Laman web. Kami akan berusaha untuk menghantar notis apabila dasar ini dipinda, namun, anda dianggap telah mengakui dan bersetuju dengan Dasar Privasi yang telah dipinda jika anda terus menggunakan Laman web atau Perkhidmatan selepas perubahan tersebut berkuat kuasa, tanpa mengira sama ada anda menerima notis atau tidak. Anda diingatkan untuk menyemak halaman ini dari semasa ke semasa untuk mendapatkan kemaskini tentang Dasar Privasi ini.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "Koleksi Data Peribadi",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              Anda boleh memilih untuk melayari Laman Web dan menggunakan fungsi terhadnya tanpa mendedahkan Data Peribadi anda. Anda tidak diwajibkan untuk menyediakan Data Peribadi sebagai syarat untuk menggunakan Laman Web ini, kecuali jika diperlukan bagi kami untuk menyediakan Perkhidmatan yang anda beli atau akses melalui Laman Web kami, menanggapi permintaan anda, atau dalam kes di mana anda menghubungi kami secara terus.
            </div>
            <div>
              Apabila anda menggunakan Perkhidmatan kami, kami mengumpul pelbagai maklumat dari dan tentang anda, peranti anda, dan interaksi anda dengan Perkhidmatan. Sebahagian daripada maklumat ini mengenal pasti anda secara langsung atau boleh digunakan untuk mengenal pasti anda apabila dikombinasikan dengan data lain. Sebarang data, sama ada benar atau tidak, tentang individu yang boleh dikenal pasti daripada data tersebut; atau daripada data tersebut dan maklumat lain yang mungkin dimiliki atau mungkin dimiliki oleh organisasi, dianggap sebagai data peribadi ("<strong>Data Peribadi</strong>").
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>Maklumat yang anda berikan secara sukarela</strong>. Apabila menggunakan Perkhidmatan kami, anda mungkin diminta untuk menyediakan maklumat peribadi tentang diri anda, seperti, antara lain, nama anda, maklumat hubungan, maklumat pembayaran, butiran tentang rumah atau hartanah yang anda minati, maklumat kewangan, kegemaran anda, atau gaya anda. Ini mungkin berlaku, sebagai contoh:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Apabila anda menjalankan urus niaga dengan kami pada atau berhubung dengan Laman Web ini;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Apabila anda mendaftar akaun dengan kami di Laman Web ini;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Apabila anda menghubungi perkhidmatan pelanggan atau wakil jualan kami melalui e-mel, telefon, atau secara langsung, untuk menyelesaikan sebarang masalah yang anda hadapi atau dengan pertanyaan atau permintaan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Apabila anda melanggan perkhidmatan yang kami sediakan di Laman Web ini, atau untuk pemberitahuan e-mel dan/atau buletin kami;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Apabila anda menyediakan atau meninggalkan maklumat relevan, respons, atau maklumat lain di Laman Web ini untuk tujuan menggunakan Perkhidmatan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Apabila anda berhubungan dengan profesional hartanah (seperti ejen atau broker hartanah, pemberi pinjaman perumahan atau pegawai pinjaman, pengurus hartanah, pelabur, pembina rumah, atau orang lain) melalui Perkhidmatan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Apabila anda mengisi borang atau membuat urus niaga lain, seperti permohonan maklumat pinjaman, permohonan pemeriksaan latar belakang (jika ada).</div>
                    </div>
                  </div>
                  <div>
                    Anda juga boleh menyediakan maklumat tentang pihak ketiga melalui Perkhidmatan ini, sebagai contoh, jika anda berkongsi senarai hartanah real estat dengan penerima melalui e-mel atau SMS. Dengan menyediakan maklumat tersebut kepada kami, anda mewakili kepada kami bahawa anda telah memperoleh kebenaran pihak ketiga untuk memberikan Data Peribadi mereka kepada kami untuk tujuan yang dinyatakan dalam Polisi Privasi ini.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Cookie, Piksel, dan mekanisme pengesan lain</strong>. Kami dan rakan kongsi kami menggunakan pelbagai teknologi untuk mengumpul maklumat secara automatik apabila anda mengakses dan menggunakan Perkhidmatan kami, termasuk kuki dan teknologi serupa lain. Kuki adalah butiran maklumat elektronik yang boleh dipindahkan ke komputer atau peranti elektronik lain untuk mengenal pasti unik peramban anda. Apabila anda menggunakan Perkhidmatan, kami dan rakan kongsi kami boleh meletakkan satu atau lebih kuki pada komputer atau peranti elektronik anda atau menggunakan teknologi lain yang menyediakan fungsi serupa.
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Kami dan rakan kongsi kami boleh menggunakan kuki untuk menghubungkan aktiviti anda dalam Perkhidmatan dengan maklumat lain yang kami simpan tentang anda dalam profil akaun anda atau interaksi sebelumnya di Perkhidmatan kami, sebagai contoh, menyimpan keutamaan anda. Penggunaan kuki membantu kami meningkatkan kualiti Perkhidmatan kami kepada anda, dengan mengenal pasti maklumat yang paling menarik bagi anda, menjejaki trend, mengukur keberkesanan pengiklanan, atau menyimpan maklumat yang mungkin anda ingin dapatkan secara berkala, seperti hartanah yang anda pilih atau carian yang anda keutamaan, dan melakukan tindakan lain yang dibenarkan di bawah undang-undang yang berkenaan. Pada bila-bila masa, anda boleh mengubah tetapan pada peramban anda untuk menolak kuki mengikut arahan berkaitan dengan peramban anda. Namun, jika anda memilih untuk melumpuhkan kuki, banyak ciri-ciri percuma Perkhidmatan mungkin tidak berfungsi dengan baik.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Sesetengah halaman kami juga mungkin termasuk piksel, iaitu fail elektronik untuk mengira pengguna yang telah melawat halaman tersebut, menjejaki aktiviti dari masa ke masa dan di laman web berbeza, menentukan interaksi pengguna dengan e-mel yang kami hantar, mengenal pasti kuki tertentu di komputer atau peranti elektronik yang mengakses halaman tersebut, atau mengumpulkan maklumat berkaitan lain, dan maklumat ini boleh dikaitkan dengan peramban unik anda, pengenal peranti, atau alamat IP anda. Sebagai contoh, kami boleh melaksanakan piksel pada Perkhidmatan tertentu apabila anda melihat senarai hartanah tertentu supaya kami boleh mengesan sama ada senarai tersebut relevan bagi anda sambil menawarkan cadangan. Melalui piksel dan kuki ini, kami mengumpul maklumat tentang penggunaan Perkhidmatan kami, termasuk jenis peramban yang anda gunakan, masa akses, halaman yang dilihat, alamat IP anda, dan halaman yang anda lawati sebelum melayari ke Perkhidmatan kami.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Kami juga mengumpul maklumat tentang komputer atau peranti mudah alih yang anda gunakan untuk mengakses Perkhidmatan kami, seperti model perkakas keras, sistem operasi dan versi, pengenal peranti unik, maklumat rangkaian mudah alih, dan tingkah laku pelayaran.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Kami juga membenarkan pihak ketiga tertentu mengumpul maklumat tentang aktiviti atas talian anda melalui kuki dan teknologi lain apabila anda menggunakan Laman Web ini. Pihak ketiga ini termasuk (a) rakan kongsi perniagaan kami, yang mengumpul maklumat apabila anda melihat atau berinteraksi dengan salah satu iklan mereka di Laman Web ini, dan (b) rangkaian pengiklanan, yang mengumpul maklumat tentang minat anda apabila anda melihat atau berinteraksi dengan salah satu iklan yang mereka letakkan di banyak laman web berbeza di Internet. Maklumat yang dikumpulkan oleh pihak ketiga ini adalah maklumat yang tidak boleh dikenal pasti secara peribadi yang digunakan untuk membuat ramalan tentang ciri, minat, atau keutamaan anda dan untuk menampilkan iklan di Laman Web ini, dan di seluruh Internet, yang disesuaikan dengan minat anda.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Anda boleh menguruskan jenis kuki yang dibenarkan melalui tetapan peramban anda, termasuk melumpuhkan sepenuhnya semua kuki jika anda ingin. Untuk maklumat tentang cara menguruskan kuki di peramban anda, sila lihat bahagian bantuan peramban yang anda gunakan.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>Maklumat Peranti Mudah Alih dan Peramban Mudah Alih.</strong> Anda boleh mengatur tetapan peranti mudah alih dan peramban mudah alih anda berkenaan kuki dan perkongsian beberapa maklumat, seperti model peranti mudah alih anda atau bahasa yang digunakan peranti mudah alih anda, dengan mengatur tetapan privasi dan keselamatan pada peranti mudah alih anda. Sila rujuk arahan yang diberikan oleh pembekal perkhidmatan mudah alih atau pengeluar peranti mudah alih anda.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>Data Lokasi.</strong> Jika anda mengaktifkan perkhidmatan lokasi pada peranti mudah alih anda, kami, dengan izin anda, boleh mengumpul lokasi peranti anda yang kami gunakan untuk memberikan maklumat dan pengiklanan berasaskan lokasi kepada anda. Jika anda ingin menonaktifkan ciri ini, anda boleh melumpuhkan perkhidmatan lokasi pada peranti mudah alih anda.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                 <strong>Pengurusan Email.</strong> Anda mungkin menerima e-mel daripada kami atas pelbagai sebab - sebagai contoh, jika anda mengambil tindakan melalui Perkhidmatan, anda mendaftar untuk laporan berkala, atau anda menyelesaikan reka bentuk anda, dan kami menghantar mesej kepada anda untuk tindakan susulan pada langkah yang akan datang. Jika anda mempunyai akaun dengan kami, anda boleh mengedit keutamaan anda melalui tetapan akaun anda. Selain itu, anda boleh menguruskan penerimaan beberapa jenis komunikasi dengan mengikuti arahan yang termasuk dalam e-mel yang kami hantar kepada anda. Sila ambil perhatian bahawa, walaupun anda berhenti berlangganan dari beberapa korespondensi e-mel, kami mungkin masih perlu menghantar e-mel kepada anda dengan maklumat transaksi atau pentadbiran penting.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>Pengecualian Tanggungjawab.</strong> Kami tidak akan bertanggungjawab atas sebarang pendedahan sukarela Data Peribadi oleh anda kepada pengguna lain berkaitan dengan penggunaan Laman Web ini, seperti yang berlaku.
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: 'Tujuan Penggunaan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                  <div style="display: flex; gap: 0.3rem;">
                      <div>3.1</div>
                      <div>
                          Data Peribadi yang anda berikan boleh digunakan dan diproses oleh kami (atau oleh pihak ketiga (seperti Google Analytics) bagi pihak kami) secara keseluruhan atau individu dan mungkin digabungkan dengan maklumat lain untuk tujuan pengurusan dan operasi Laman web, skop yang mungkin termasuk, tetapi tidak terhad kepada yang berikut:
                          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>a.</div>
                                  <div>Menguruskan Laman web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>b.</div>
                                  <div>Meningkatkan pengalaman pelayaran anda dengan memperibadikan Laman web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>c.</div>
                                  <div>Membolehkan penggunaan Perkhidmatan yang tersedia di Laman web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>d.</div>
                                  <div>Menyediakan Perkhidmatan yang anda telah minta khususnya;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>e.</div>
                                  <div>Menanggapi pertanyaan atau permintaan anda berdasarkan emel atau penghantaran borang di Laman web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>f.</div>
                                  <div>Menanggapi dan mengambil tindakan susulan atas aduan mengenai penggunaan Laman web atau Perkhidmatan yang disediakan oleh kami;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>g.</div>
                                  <div>Menghantar komunikasi komersial am melalui saluran komunikasi mana-mana, tidak terhad kepada emel, panggilan suara, SMS, dan pemberitahuan aplikasi;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>h.</div>
                                  <div>Melaksanakan urusan pentadbiran pejabat dan operasi;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>i.</div>
                                  <div>Menyediakan pihak ketiga dengan maklumat statistik tentang pengguna kami. Maklumat statistik tersebut boleh digunakan untuk memperibadikan pengiklanan di Laman web, tetapi tidak dapat digunakan untuk mengenal pasti mana-mana pengguna secara individu;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>j.</div>
                                  <div>Mencipta dan menguruskan akaun atas talian anda;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>k.</div>
                                  <div>Memudahkan aktiviti dan urusan transaksi di Laman web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>l.</div>
                                  <div>Memudahkan penyertaan anda dalam pertandingan atau promosi;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>m.</div>
                                  <div>Menangani pembayaran, atau pemulangan dana dari atau kepada anda;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>n.</div>
                                  <div>Menyediakan maklumat tentang Perkhidmatan yang anda minta daripada kami; atau tertakluk kepada persetujuan terdahulu anda, maklumat yang kami rasa mungkin menarik bagi anda melalui emel atau mesej teks;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>o.</div>
                                  <div>Mengendalikan, menilai, dan memperbaiki perniagaan kami (termasuk membangunkan produk dan perkhidmatan baru; menganalisis dan meningkatkan Perkhidmatan kami);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>p.</div>
                                  <div>Memahami pelanggan kami dan menjalankan analisis data dan pemprosesan lain (termasuk penyelidikan pasaran dan pengguna, analisis trend, dan penanoniman);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>q.</div>
                                  <div>Menanggapi komen atau soalan anda atau permohonan pekerjaan (jika ada);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>r.</div>
                                  <div>Berkongsi dengan pembekal perkhidmatan supaya mereka boleh menyediakan Perkhidmatan kepada anda; dan mengikut yang diperlukan atau dibenarkan oleh undang-undang.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  Anda boleh memilih untuk melayari Laman web dan menggunakan fungsi terhadnya tanpa mendedahkan Data Peribadi anda. Anda tidak dikehendaki untuk memberikan Data Peribadi sebagai syarat penggunaan Laman web, kecuali jika diperlukan bagi kami untuk menyediakan Perkhidmatan yang anda beli atau akses melalui Laman web kami, menanggapi permintaan anda, atau dalam kes di mana anda menghubungi kami secara langsung.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.2. </div>
                  <div>Kerana tujuan di mana kami mungkin akan mengumpul, menggunakan, mengungkapkan, atau memproses Data Peribadi anda bergantung pada keadaan semasa, tujuan tersebut mungkin tidak tersenarai di atas. Walau bagaimanapun, kami akan memaklumkan anda tentang tujuan-tujuan lain tersebut pada saat memperoleh persetujuan anda, kecuali jika pemprosesan Data Peribadi anda tanpa persetujuan anda dibenarkan oleh Akta Perlindungan Data Peribadi 2012 Singapura ("<strong>PDPA</strong>") atau undang-undang yang berkenaan lain.
                  </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.3. </div>
                  <div><strong>Peruntukan Jangan Hubungi.</strong> Jika anda telah memberikan kami nombor telefon anda (nombor "<strong>Numbers</strong>") dan telah menunjukkan bahawa anda bersetuju untuk menerima maklumat pemasaran atau promosi lain melalui Numbers anda, maka dari semasa ke semasa, kami mungkin menghubungi anda menggunakan Numbers tersebut dengan maklumat tentang Perkhidmatan kami, walaupun Numbers tersebut didaftarkan dalam Daftar Jangan Hubungi (jika ada dan tertakluk kepada bidang kuasa masing-masing). Walau bagaimanapun, anda boleh memberi makluman secara bertulis sekiranya anda tidak ingin dihubungi oleh kami di Numbers anda untuk tujuan tersebut.
                  </div>
              </div>
          </div>
      `
    },
    {
      title: 'Ketepatan dan Keselamatan',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Anda perlu memastikan bahawa semua Data Peribadi yang dikemukakan kepada kami adalah lengkap, tepat, benar, dan betul. Kegagalan dari pihak anda untuk berbuat demikian boleh menyebabkan kami tidak dapat menyediakan perkhidmatan yang anda minta.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Kami akan mengambil langkah-langkah yang munasabah untuk memastikan bahawa Data Peribadi anda adalah tepat dan lengkap jika Data Peribadi anda kemungkinan akan digunakan oleh kami untuk membuat keputusan yang mempengaruhi anda atau didedahkan kepada organisasi lain. Namun, ini bermakna anda juga perlu memaklumkan kepada kami tentang sebarang perubahan dalam Data Peribadi anda yang pertama kali anda berikan kepada kami. Kami tidak akan bertanggungjawab jika kami bergantung kepada Data Peribadi yang tidak tepat atau tidak lengkap yang disebabkan oleh anda tidak memaklumkan kami tentang perubahan dalam Data Peribadi anda yang pertama kali anda berikan kepada kami.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Kami akan mengambil langkah-langkah teknikal dan organisasi yang munasabah untuk mencegah kehilangan, penyalahgunaan, atau pengubahan Data Peribadi anda.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Kami akan menyimpan semua Data Peribadi yang anda berikan di server yang selamat dalam format terenkripsi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Bagaimanapun, anda memahami bahawa transmisi maklumat melalui Internet tidak sepenuhnya selamat. Walaupun kami akan berusaha yang terbaik untuk melindungi Data Peribadi anda, kami tidak dapat menjamin keselamatan Data Peribadi anda yang dihantar ke Laman Web ini; sebarang transmisi adalah atas risiko anda sendiri. Selain itu, kami tidak bertanggungjawab bagi sebarang penggunaan tidak sah Data Peribadi anda oleh pihak ketiga yang sepenuhnya disebabkan oleh faktor-faktor di luar kawalan kami.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Jika kami telah memberikan anda (atau jika anda telah memilih) kata laluan yang membolehkan anda mengakses bahagian-bahagian tertentu Laman Web ini, anda bertanggungjawab untuk menjaga kerahsiaan kata laluan ini. Kami meminta anda untuk tidak berkongsi kata laluan dengan sesiapa pun. Kami TIDAK akan pernah meminta kata laluan anda kecuali ketika anda log masuk ke Laman Web ini, jika ada.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Kami menasihatkan anda untuk mengambil langkah-langkah untuk menjaga keselamatan maklumat peribadi anda, seperti memilih kata laluan yang kuat dan menjaganya sebagai rahsia, serta log keluar daripada akaun pengguna anda dan menutup pelayar web anda apabila anda telah selesai menggunakan Perkhidmatan kami di peranti yang dikongsi atau tidak selamat.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Pendedahan Data Peribadi',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Kami tidak akan, tanpa kebenaran terlebih dahulu, mendedahkan Data Peribadi anda kepada pihak ketiga untuk tujuan pemasaran langsung. Kebenaran untuk pendedahan Data Peribadi kepada pihak ketiga, untuk tujuan menerima komunikasi pemasaran, diberikan melalui borang pendaftaran kami, sama ada dalam talian atau kertas.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Untuk mengurus dan mengendalikan Laman Web kami, kami mungkin mendedahkan Data Peribadi anda kepada mana-mana ahli kumpulan kami, iaitu syarikat-syarikat sekutu dan anak syarikat kami. Kami juga mungkin mendedahkan Data Peribadi anda kepada pihak ketiga seperti pembekal perkhidmatan dan penasihat profesional kami untuk tujuan-tujuan berikut:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Untuk tujuan yang dibenarkan di bawah mana-mana undang-undang atau peraturan yang berkenaan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Untuk mematuhi sebarang keperluan undang-undang; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Untuk menguatkuasakan atau menerapkan Terma Penggunaan kami; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Untuk melindungi hak, harta benda, dan keselamatan kami, serta hak, harta benda, dan keselamatan ahli kumpulan dan anak syarikat kami, pengguna lain Laman Web, dan pihak lain;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Sambutan dengan apa-apa prosiding undang-undang atau prosiding undang-undang yang mungkin;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Hubungannya dengan transaksi aset perniagaan (iaitu, pembelian, penjualan, sewa, penggabungan atau penggabungan atau apa-apa akuisisi, pelepasan atau pembiayaan organisasi atau bahagian organisasi atau apa-apa daripada perniagaan atau aset organisasi) yang kami menjadi pihak atau pihak yang bakal menjadi pihak.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Kami juga tidak akan mendedahkan Data Peribadi anda kepada pihak ketiga tanpa mendapatkan kebenaran anda terlebih dahulu untuk melakukannya. Walau bagaimanapun, perhatikan bahawa kami mungkin mendedahkan Data Peribadi anda kepada pihak ketiga tanpa mendapatkan kebenaran anda terlebih dahulu dalam situasi tertentu, termasuk, tanpa had, situasi-situasi berikut:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Kes di mana pendedahan diperlukan atau dibenarkan berdasarkan undang-undang dan/atau peraturan yang berkenaan; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Kes di mana tujuan pendedahan tersebut jelas dalam kepentingan anda, dan jika kebenaran tidak dapat diperolehi dengan cepat; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Kes di mana pendedahan diperlukan untuk merespon apa-apa kecemasan yang mengancam nyawa, kesihatan, atau keselamatan anda atau individu lain; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Kes di mana pendedahan diperlukan untuk apa-apa siasatan atau prosiding undang-undang; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Kes di mana Data Peribadi diungkapkan kepada mana-mana pegawai agensi penguatkuasa undang-undang yang ditetapkan; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Kes di mana pendedahan tersebut adalah kepada agensi awam dan pendedahan tersebut diperlukan demi kepentingan awam; dan/atau</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Di mana pendedahan tersebut tanpa kebenaran anda dibenarkan oleh PDPA atau undang-undang.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Apabila kami mendedahkan Data Peribadi anda kepada pihak ketiga tanpa kebenaran anda, kami akan menggunakan usaha terbaik untuk memastikan pihak ketiga tersebut melindungi Data Peribadi anda.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Penarikan Persetujuan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Anda boleh menarik balik persetujuan anda dengan menghubungi perkhidmatan pelanggan kami di <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Sila ambil perhatian bahawa jika anda menarik balik persetujuan anda terhadap penggunaan Data Peribadi anda, bergantung kepada jenis permintaan anda, kami mungkin tidak dapat terus menyediakan Perkhidmatan kami kepada anda. Apabila menerima notis dari anda, kami akan memaklumkan kepada anda tentang kemungkinan akibat penarikan persetujuan, termasuk tetapi tidak terhad kepada pengakhiran sebarang perjanjian yang mungkin anda miliki dengan kami dan anda akan melanggar obligasi atau janji kontrak anda. Hak dan pilihan undang-undang kami dalam keadaan tersebut adalah dikekalkan.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Pemindahan Data Antarabangsa',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Kami beroperasi di pelbagai negara dan mungkin menyimpan, memproses, atau memindahkan Data Peribadi anda antara mana-mana negara yang kami beroperasi untuk membolehkan kami menggunakan Data Peribadi anda mengikut Polisi Privasi ini dan Terma Penggunaan kami.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Kami akan mengambil semua langkah yang wajar untuk memastikan bahawa Data Peribadi anda dipindahkan mengikut undang-undang atau peraturan yang berkenaan dan mengambil semua langkah yang munasabah untuk memastikan bahawa mana-mana penerima asing Data Peribadi anda terikat dengan obligasi yang boleh dikuatkuasakan secara undang-undang untuk memberikan tahap perlindungan yang sekurang-kurangnya setanding dengan perlindungan di bawah PDPA.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Laman Web Pihak Ketiga',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Laman web ini mungkin mengandungi pautan ke laman web rangkaian rakan kongsi dan vendor kami dari semasa ke semasa. Jika anda mengikuti pautan ke mana-mana laman web ini, sila ambil perhatian bahawa laman web ini mempunyai polisi privasi masing-masing dan kami tidak menerima sebarang tanggungjawab atau liabiliti terhadap polisi-polisi tersebut. Sila semak polisi-polisi ini sebelum anda menghantar sebarang Data Peribadi kepada laman web-laman web ini.
              </div>
            </div>
          `
    },
    {
      title: 'Akses, Pemadaman, dan Pembetulan',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>9.1.</div>
                <div>Pegawai Perlindungan Data kami bertanggungjawab untuk memastikan permintaan akses, pemadaman atau pindaan Data Peribadi dilaksanakan dengan sewajarnya mengikut prosedur yang ditetapkan di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.2.</div>
                <div>Semua permintaan maklumat atau permintaan pembetulan atau pemadaman data peribadi yang kami miliki akan dilakukan dengan menghantar permintaan kepada Pegawai Perlindungan Data kami di: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.3.</div>
                <div>Sila ambil perhatian bahawa kami hanya akan menerima dan bertindak balas terhadap permintaan dalam bahasa Inggeris yang mematuhi Polisi Privasi ini. Sebarang permintaan yang tidak mematuhi syarat-syarat di atas akan ditolak, dan kami tidak akan mengambil sebarang tindakan berhubung dengan permintaan yang ditolak tersebut.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.4.</div>
                <div>Kami boleh menolak untuk mendedahkan, memadam atau meminda sebarang Data Peribadi atas sebab-sebab yang tertera dalam PDPA.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.5.</div>
                <div>Sejauh yang munasabah, kami akan memberi respons kepada permintaan anda dalam masa sepuluh (10) hari bekerja dari tarikh penerimaan permintaan tersebut. Sekiranya itu tidak memungkinkan, anda akan diberitahu dalam masa sepuluh (10) hari bekerja dari tarikh penerimaan permintaan tersebut.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Penyimpanan Data Peribadi Anda',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>Kami menyimpan Data Peribadi yang kami kumpulkan dari anda apabila kami mempunyai keperluan perniagaan yang sah untuk berbuat demikian. Sekiranya anda adalah seorang pelanggan atau vendor (atau wakil seorang pelanggan atau vendor), maklumat akan disimpan untuk tempoh yang membenarkan kami untuk menyediakan Perkhidmatan kami, mematuhi keperluan undang-undang, cukai atau perakaunan yang berkenaan dan, jika perlu, untuk penubuhan, pelaksanaan atau pertahanan tuntutan undang-undang.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>Apabila kami tidak mempunyai keperluan perniagaan yang sah untuk memproses maklumat peribadi anda, kami akan memadam atau menjadikan maklumat tersebut menjadi tidak dikenali atau, jika ini tidak dimungkinkan (contohnya, kerana maklumat peribadi anda disimpan dalam arkib sandaran), maka kami akan menyimpan maklumat peribadi anda dengan selamat dan mengasingkannya daripada sebarang pemprosesan lanjut sehingga ia boleh dipadam.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Hubungi Kami',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>
                    Jika anda memerlukan maklumat lanjut mengenai perlakuan Data Peribadi anda oleh kami, jangan ragu untuk menghubungi Pegawai Perlindungan Data kami di: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                  </div>
                </div>
              </div>
            `
    },
    {
      title: 'Undang-Undang Yang Mengawal',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                Polisi Privasi ini akan ditadbir oleh undang-undang Singapura.
                </div>
              </div>
            `
    }
  ]
};
