import { useState } from 'react';

type Props<CE = any, V = any> = {
  value?: V;
  defaultValue?: V;
  onChange?: (event: CE, value: V) => void;
};

const useControlledValue = <CE = any, V = any>({
  value,
  onChange,
  defaultValue,
}: Props<CE, V>) => {
  const [stateValue, setStateValue] = useState<V | undefined>(
    () => defaultValue
  );

  const onStateValueChange: typeof onChange = (event, newValue) => {
    if (value === undefined) {
      setStateValue(newValue);
    }

    if (onChange !== undefined) {
      onChange(event, newValue);
    }
  };

  return [(value ?? stateValue) as V, onStateValueChange as typeof onChange];
};

export default useControlledValue;
