import React from 'react';
import BaseIcon from './BaseIcon';

const IcOutlineMenu = (props: any) => {
  return (
    <BaseIcon
      viewBox="0 0 24 24"
      {...props}
    >
      <rect x="3" y="17" width="18" height="2" rx="0.4" fill="currentColor"/>
      <rect x="3" y="11" width="18" height="2" rx="0.4" fill="currentColor"/>
      <rect x="3" y="5" width="18" height="2" rx="0.4" fill="currentColor"/>

    </BaseIcon>
  )
}

export default IcOutlineMenu
