import { TCountryPhoneCode } from "constants/i18n";

export const COUNTRY_PHONE_CODE_OPTIONS_FR: TCountryPhoneCode[] = [
  {
    "text": "Arménie +374",
    "value": "+374",
    "locale": "am-AM",
    "shortLocale": "AM"
  },
  {
    "text": "Émirats arabes unis +971",
    "value": "+971",
    "locale": "ar-AE",
    "shortLocale": "AE"
  },
  {
    "text": "Bahreïn +973",
    "value": "+973",
    "locale": "ar-BH",
    "shortLocale": "BH"
  },
  {
    "text": "Algérie +213",
    "value": "+213",
    "locale": "ar-DZ",
    "shortLocale": "DZ"
  },
  {
    "text": "Égypte +20",
    "value": "+20",
    "locale": "ar-EG",
    "shortLocale": "EG"
  },
  {
    "text": "Irak +964",
    "value": "+964",
    "locale": "ar-IQ",
    "shortLocale": "IQ"
  },
  {
    "text": "Jordanie +962",
    "value": "+962",
    "locale": "ar-JO",
    "shortLocale": "JO"
  },
  {
    "text": "Koweït +965",
    "value": "+965",
    "locale": "ar-KW",
    "shortLocale": "KW"
  },
  {
    "text": "Libye +218",
    "value": "+218",
    "locale": "ar-LY",
    "shortLocale": "LY"
  },
  {
    "text": "Arabie saoudite +966",
    "value": "+966",
    "locale": "ar-SA",
    "shortLocale": "SA"
  },
  {
    "text": "Syrie +963",
    "value": "+963",
    "locale": "ar-SY",
    "shortLocale": "SY"
  },
  {
    "text": "Tunisie +216",
    "value": "+216",
    "locale": "ar-TN",
    "shortLocale": "TN"
  },
  {
    "text": "Biélorussie +375",
    "value": "+375",
    "locale": "be-BY",
    "shortLocale": "BY"
  },
  {
    "text": "Bulgarie +359",
    "value": "+359",
    "locale": "bg-BG",
    "shortLocale": "BG"
  },
  {
    "text": "Bangladesh +880",
    "value": "+880",
    "locale": "bn-BD",
    "shortLocale": "BD"
  },
  {
    "text": "République tchèque +420",
    "value": "+420",
    "locale": "cs-CZ",
    "shortLocale": "CZ"
  },
  {
    "text": "Danemark +45",
    "value": "+45",
    "locale": "da-DK",
    "shortLocale": "DK"
  },
  {
    "text": "Allemagne +49",
    "value": "+49",
    "locale": "de-DE",
    "shortLocale": "DE"
  },
  {
    "text": "Autriche +43",
    "value": "+43",
    "locale": "de-AT",
    "shortLocale": "AT"
  },
  {
    "text": "Suisse +41",
    "value": "+41",
    "locale": "de-CH",
    "shortLocale": "CH"
  },
  {
    "text": "Grèce +30",
    "value": "+30",
    "locale": "el-GR",
    "shortLocale": "GR"
  },
  {
    "text": "Australie +61",
    "value": "+61",
    "locale": "en-AU",
    "shortLocale": "AU"
  },
  {
    "text": "Royaume-Uni +44",
    "value": "+44",
    "locale": "en-GB",
    "shortLocale": "GB"
  },
  {
    "text": "Ghana +233",
    "value": "+233",
    "locale": "en-GH",
    "shortLocale": "GH"
  },
  {
    "text": "Hong Kong +852",
    "value": "+852",
    "locale": "en-HK",
    "shortLocale": "HK"
  },
  {
    "text": "Macao +853",
    "value": "+853",
    "locale": "en-MO",
    "shortLocale": "MO"
  },
  {
    "text": "Irlande +353",
    "value": "+353",
    "locale": "en-IE",
    "shortLocale": "IE"
  },
  {
    "text": "Inde +91",
    "value": "+91",
    "locale": "en-IN",
    "shortLocale": "IN"
  },
  {
    "text": "Kenya +254",
    "value": "+254",
    "locale": "en-KE",
    "shortLocale": "KE"
  },
  {
    "text": "Malte +356",
    "value": "+356",
    "locale": "en-MT",
    "shortLocale": "MT"
  },
  {
    "text": "Maurice +230",
    "value": "+230",
    "locale": "en-MU",
    "shortLocale": "MU"
  },
  {
    "text": "Nigéria +234",
    "value": "+234",
    "locale": "en-NG",
    "shortLocale": "NG"
  },
  {
    "text": "Nouvelle-Zélande +64",
    "value": "+64",
    "locale": "en-NZ",
    "shortLocale": "NZ"
  },
  {
    "text": "Pakistan +92",
    "value": "+92",
    "locale": "en-PK",
    "shortLocale": "PK"
  },
  {
    "text": "Rwanda +250",
    "value": "+250",
    "locale": "en-RW",
    "shortLocale": "RW"
  },
  {
    "text": "Singapour +65",
    "value": "+65",
    "locale": "en-SG",
    "shortLocale": "SG"
  },
  {
    "text": "Sierra Leone +232",
    "value": "+232",
    "locale": "en-SL",
    "shortLocale": "SL"
  },
  {
    "text": "Tanzanie +255",
    "value": "+255",
    "locale": "en-TZ",
    "shortLocale": "TZ"
  },
  {
    "text": "Ouganda +256",
    "value": "+256",
    "locale": "en-UG",
    "shortLocale": "UG"
  },
  {
    "text": "États-Unis +1",
    "value": "+1",
    "locale": "en-US",
    "shortLocale": "US"
  },
  {
    "text": "Afrique du Sud +27",
    "value": "+27",
    "locale": "en-ZA",
    "shortLocale": "ZA"
  },
  {
    "text": "Zambie +260",
    "value": "+260",
    "locale": "en-ZM",
    "shortLocale": "ZM"
  },
  {
    "text": "Zimbabwe +263",
    "value": "+263",
    "locale": "en-ZW",
    "shortLocale": "ZW"
  },
  {
    "text": "Colombie +57",
    "value": "+57",
    "locale": "es-CO",
    "shortLocale": "CO"
  },
  {
    "text": "Chili +56",
    "value": "+56",
    "locale": "es-CL",
    "shortLocale": "CL"
  },
  {
    "text": "Costa Rica +506",
    "value": "+506",
    "locale": "es-CR",
    "shortLocale": "CR"
  },
  {
    "text": "Équateur +593",
    "value": "+593",
    "locale": "es-EC",
    "shortLocale": "EC"
  },
  {
    "text": "Espagne +34",
    "value": "+34",
    "locale": "es-ES",
    "shortLocale": "ES"
  },
  {
    "text": "Mexique +52",
    "value": "+52",
    "locale": "es-MX",
    "shortLocale": "MX"
  },
  {
    "text": "Panama +507",
    "value": "+507",
    "locale": "es-PA",
    "shortLocale": "PA"
  },
  {
    "text": "Paraguay +595",
    "value": "+595",
    "locale": "es-PY",
    "shortLocale": "PY"
  },
  {
    "text": "Uruguay +598",
    "value": "+598",
    "locale": "es-UY",
    "shortLocale": "UY"
  },
  {
    "text": "Estonie +372",
    "value": "+372",
    "locale": "et-EE",
    "shortLocale": "EE"
  },
  {
    "text": "Iran +98",
    "value": "+98",
    "locale": "fa-IR",
    "shortLocale": "IR"
  },
  {
    "text": "Finlande +358",
    "value": "+358",
    "locale": "fi-FI",
    "shortLocale": "FI"
  },
  {
    "text": "Fidji +679",
    "value": "+679",
    "locale": "fj-FJ",
    "shortLocale": "FJ"
  },
  {
    "text": "Îles Féroé +298",
    "value": "+298",
    "locale": "fo-FO",
    "shortLocale": "FO"
  },
  {
    "text": "France +33",
    "value": "+33",
    "locale": "fr-FR",
    "shortLocale": "FR"
  },
  {
    "text": "Guyane française +594",
    "value": "+594",
    "locale": "fr-GF",
    "shortLocale": "GF"
  },
  {
    "text": "Guadeloupe +590",
    "value": "+590",
    "locale": "fr-GP",
    "shortLocale": "GP"
  },
  {
    "text": "Martinique +596",
    "value": "+596",
    "locale": "fr-MQ",
    "shortLocale": "MQ"
  },
  {
    "text": "Réunion +262",
    "value": "+262",
    "locale": "fr-RE",
    "shortLocale": "RE"
  },
  {
    "text": "Israël +972",
    "value": "+972",
    "locale": "he-IL",
    "shortLocale": "IL"
  },
  {
    "text": "Hongrie +36",
    "value": "+36",
    "locale": "hu-HU",
    "shortLocale": "HU"
  },
  {
    "text": "Indonésie +62",
    "value": "+62",
    "locale": "id-ID",
    "shortLocale": "ID"
  },
  {
    "text": "Italie +39",
    "value": "+39",
    "locale": "it-IT",
    "shortLocale": "IT"
  },
  {
    "text": "Japon +81",
    "value": "+81",
    "locale": "ja-JP",
    "shortLocale": "JP"
  },
  {
    "text": "Kazakhstan +7",
    "value": "+7",
    "locale": "kk-KZ",
    "shortLocale": "KZ"
  },
  {
    "text": "Groenland +299",
    "value": "+299",
    "locale": "kl-GL",
    "shortLocale": "GL"
  },
  {
    "text": "Corée du Sud +82",
    "value": "+82",
    "locale": "ko-KR",
    "shortLocale": "KR"
  },
  {
    "text": "Lituanie +370",
    "value": "+370",
    "locale": "lt-LT",
    "shortLocale": "LT"
  },
  {
    "text": "Malaisie +60",
    "value": "+60",
    "locale": "ms-MY",
    "shortLocale": "MY"
  },
  {
    "text": "Norvège +47",
    "value": "+47",
    "locale": "nb-NO",
    "shortLocale": "NO"
  },
  {
    "text": "Népal +977",
    "value": "+977",
    "locale": "ne-NP",
    "shortLocale": "NP"
  },
  {
    "text": "Belgique +32",
    "value": "+32",
    "locale": "nl-BE",
    "shortLocale": "BE"
  },
  {
    "text": "Pays-Bas +31",
    "value": "+31",
    "locale": "nl-NL",
    "shortLocale": "NL"
  },
  {
    "text": "Pologne +48",
    "value": "+48",
    "locale": "pl-PL",
    "shortLocale": "PL"
  },
  {
    "text": "Brésil +55",
    "value": "+55",
    "locale": "pt-BR",
    "shortLocale": "BR"
  },
  {
    "text": "Portugal +351",
    "value": "+351",
    "locale": "pt-PT",
    "shortLocale": "PT"
  },
  {
    "text": "Roumanie +40",
    "value": "+40",
    "locale": "ro-RO",
    "shortLocale": "RO"
  },
  {
    "text": "Russie +7",
    "value": "+7",
    "locale": "ru-RU",
    "shortLocale": "RU"
  },
  {
    "text": "Slovénie +386",
    "value": "+386",
    "locale": "sl-SI",
    "shortLocale": "SI"
  },
  {
    "text": "Slovaquie +421",
    "value": "+421",
    "locale": "sk-SK",
    "shortLocale": "SK"
  },
  {
    "text": "Serbie +381",
    "value": "+381",
    "locale": "sr-RS",
    "shortLocale": "RS"
  },
  {
    "text": "Suède +46",
    "value": "+46",
    "locale": "sv-SE",
    "shortLocale": "SE"
  },
  {
    "text": "Thaïlande +66",
    "value": "+66",
    "locale": "th-TH",
    "shortLocale": "TH"
  },
  {
    "text": "Turquie +90",
    "value": "+90",
    "locale": "tr-TR",
    "shortLocale": "TR"
  },
  {
    "text": "Ukraine +380",
    "value": "+380",
    "locale": "uk-UA",
    "shortLocale": "UA"
  },
  {
    "text": "Viêt Nam +84",
    "value": "+84",
    "locale": "vi-VN",
    "shortLocale": "VN"
  },
  {
    "text": "Chine +86",
    "value": "+86",
    "locale": "zh-CN",
    "shortLocale": "CN"
  },
  {
    "text": "Taïwan +886",
    "value": "+886",
    "locale": "zh-TW",
    "shortLocale": "TW"
  },
  // {
  //   "text": "Canada +1",
  //   "value": "+1",
  //   "locale": "en-CA",
  //   "shortLocale": "CA"
  // }
];