export const TERM_PT = (textBrand: string) => {
  return [
    {
      title: 'Aceitação dos Termos',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Ao acessar e usar <a href="https://levitate.homes">https://levitate.homes</a> (o "Website"), você estabelece um acordo legalmente vinculativo nos termos e condições aqui estabelecidos ("Termos de Uso") conosco, ${textBrand} International Pte. Ltd. ("nós", "nosso" ou "${textBrand}"), proprietário e administrador do Website.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Você aceita sem limitações ou restrições os Termos de Uso, incluindo a Política de Privacidade.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Podemos alterar estes Termos de Uso a qualquer momento, publicando uma versão revisada no Website. No entanto, você não receberá um aviso individual. Quaisquer Termos de Uso alterados substituirão todas as versões anteriores dos mesmos. Se você não concordar com as alterações ou modificações nos Termos de Uso, você deve cessar imediatamente o uso de qualquer um dos serviços no Website. Após qualquer uma dessas alterações entrar em vigor, consideraremos que seu uso contínuo do Website constitui sua aceitação e concordância com tais alterações. Você pode revisar a versão mais recente dos Termos de Uso a qualquer momento.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>De tempos em tempos, publicaremos no Website diretrizes e regras relacionadas ao uso do Website. Todas essas diretrizes ou regras são incorporadas por referência nos Termos de Uso.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Uso do Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>Você concorda em cumprir todos os termos e condições deste Termos de Uso ao usar o Website. Podemos remover ou excluir qualquer conteúdo que você tenha fornecido no Website se suspeitarmos, a nosso critério absoluto, que você violou as condições aqui estabelecidas.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>
              Ao realizar qualquer transação conosco no Website ou ao registrar uma conta conosco no Website, você declara que:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Você está legalmente autorizado a realizar a transação por si mesmo;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Você está usando sua identidade real;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Os dados pessoais que você nos forneceu são verdadeiros, precisos, completos e atualizados; e</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Você manterá e atualizará prontamente seus dados pessoais e garantirá que tais informações sejam verdadeiras, precisas, completas e atualizadas.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Menores de idade (usuários com idade inferior à regulada pela lei do seu país) não estão autorizados a usar o Website ou fornecer qualquer dado pessoal no Website sem supervisão. Se você é menor de idade, pode usar o Website e/ou fornecer qualquer dado pessoal no Website somente após obter o consentimento de seus pais/tutor legal ou se estiver sob a supervisão de seus pais/tutor legal.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>Se tivermos fornecido a você (ou se você tiver escolhido) uma senha que permita o acesso a determinadas partes do Website, você reconhece que é totalmente responsável por manter a confidencialidade de sua senha e por todas as atividades que ocorram em sua conta. Não teremos responsabilidade em verificar se o usuário que faz login e usa o Website é você ou não.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.5.</div>
            <div>Você concorda em nos notificar sobre qualquer violação de segurança de sua senha.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.6.</div>
            <div>Não seremos responsáveis por quaisquer perdas ou danos decorrentes do seu não cumprimento desta Cláusula 2.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.7.</div>
            <div>Você não pode enquadrar ou utilizar técnicas de enquadramento para envolver qualquer uma de nossas marcas comerciais ou logotipos nossos e de nossas afiliadas (incluindo a empresa matriz, "Afiliadas") ou qualquer outra informação proprietária (incluindo imagens, texto, layout da página, formulários, descrições, conceitos, ideias, designs, desenhos, imagens, fotos ou qualquer outro conteúdo) sem o consentimento expresso por escrito de nós. Qualquer uso não autorizado encerrará a permissão ou consentimento concedido por nós.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Material do Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Ao acessar e usar o Website, você pode ser exposto a informações, dados, textos, software, fotografias, gráficos, vídeos, mensagens, conceitos, ideias, desenhos, listagens de produtos, preços ou outros materiais que nós e/ou outros usuários do Website podem enviar, postar, enviar por e-mail, transmitir ou disponibilizar no Website ("Material do Website"), seja publicamente ou privadamente.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Você entende que o Material do Website pode ser ofensivo, indecente ou questionável.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Você entende que o Material do Website só pode ser usado para o seu próprio uso pessoal e não comercial, e não para publicação, distribuição, transmissão, retransmissão, redistribuição, transmissão, reprodução ou circulação para outra pessoa na mesma empresa ou organização, e não para postagem em outros sites ou fóruns, grupos de discussão, listas de e-mails, quadros de avisos eletrônicos ou chats de internet operados por outros sites.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Você concorda em não reproduzir, duplicar, copiar, vender, revender, exibir ou fornecer de qualquer forma o Material do Website em outro site ou servidor sem nossa prévia autorização por escrito.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Nós não nos responsabilizamos, em nenhuma circunstância, por quaisquer erros ou omissões em qualquer Material do Website, nem por qualquer perda ou dano de qualquer tipo incorrido como resultado do uso de qualquer Material do Website postado, enviado por e-mail, transmitido ou disponibilizado de outra forma no Website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>Você reconhece e concorda que o formato e o conteúdo do Website podem ser alterados a qualquer momento sem aviso prévio; a operação do Website pode ser suspensa ou descontinuada para trabalhos de suporte ou manutenção, para atualizar seu conteúdo ou por qualquer outro motivo; e o acesso ao Website pode ser encerrado, seja em relação a usuários específicos ou de forma geral, a qualquer momento e sem aviso prévio.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Precisão das Informações em nosso Site',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Embora a ${textBrand} faça esforços razoáveis para incluir informações precisas em nosso site, não fornecemos garantias ou representações sobre a precisão de qualquer informação fornecida, quer implícitas ou não, na extensão permitida por lei. A ${textBrand} exclui toda responsabilidade por quaisquer erros ou omissões no conteúdo do site na extensão máxima permitida por lei.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Os detalhes contidos em nosso site destinam-se apenas a fins orientativos. A precisão de quaisquer detalhes, descrições, dimensões, referências ao estado, permissões necessárias para uso e ocupação e outros detalhes contidos em nosso site não é garantida e é apenas para orientação geral. Recomenda-se que não confie neles como declarações de fato ou representações e que satisfaça você mesmo a sua precisão.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>A ${textBrand} aconselha que, como a compra de imóveis é um compromisso financeiro importante, você deve tomar todas as medidas razoáveis para garantir que qualquer propriedade que você pretende comprar seja adequada para suas necessidades em todos os aspectos. Tomamos medidas razoáveis para garantir que os materiais contidos no site sejam precisos, mas eles não devem ser considerados como substitutos de suas investigações e considerações. É aconselhável que você tome as medidas apropriadas para verificar qualquer informação em que deseje confiar.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Apesar de todos os esforços para fornecer uma representação precisa da cor e design de cada produto em nosso site, as cores e o design reais podem variar ligeiramente, devido a diferentes configurações de tela do dispositivo, a iluminação no local de instalação, pequenas diferenças nos acabamentos do produto ao longo do tempo e outros fatores. A ${textBrand} não aceitará responsabilidade por quaisquer diferenças de cor ou design que não sejam culpa nossa. Ao comprar uma propriedade ou produto usando nosso site, você concorda em aceitar o pequeno risco de que haverá pequenas variações entre as cores e o design reais e a representação em nosso site.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Obrigações do Utilizador',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Além das obrigações especificadas nestes Termos de Utilização, não deve:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Utilizar o Website em violação de quaisquer leis ou regulamentos aplicáveis;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Agir de forma a prejudicar, abusar, assediar, perseguir, difamar, caluniar, ameaçar ou ofender outros, ou utilizar o Website para tais fins;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Realizar quaisquer ações que causem ou possam causar inconvenientes, desvantagens ou danos ao(s) utilizador(es), terceiros ou à ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Realizar quaisquer atos de candidatura fraudulenta ou manter múltiplos registos de membros de forma fraudulenta;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Participar em qualquer atividade imoral ou potencialmente imoral, incluindo, mas não se limitando à prostituição e pornografia;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Fazer-se passar por qualquer pessoa ou entidade, ou declarar falsamente ou de qualquer outra forma representar a sua afiliação com uma pessoa ou entidade;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Fornecer informações falsas, imprecisas ou enganosas;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Participar em atividades potencialmente fraudulentas, suspeitas ou ilegais e/ou transações;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Enviar emails não solicitados a qualquer utilizador ou utilizar os serviços do Website para recolher pagamentos por envio, ou auxiliar no envio de emails não solicitados a terceiros;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Divulgar ou distribuir informações pessoais de outro utilizador a terceiros ou utilizar essas informações para fins de marketing sem o consentimento expresso desse utilizador;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Tomar qualquer ação que imponha uma carga irrazoável ou desproporcionalmente grande na infraestrutura do Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Carregar, ou causar o carregamento, de qualquer conteúdo no Website que contenha vírus, ou quaisquer outros itens que possam danificar, interferir, afetar adversamente ou dificultar o acesso ao Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Participar, ou fazer com que outros utilizadores participem, em spam, phishing, atividades impróprias, maliciosas ou, a nosso critério absoluto, cliques fraudulentos, impressões ou atividades de marketing relacionadas com a ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>A nosso critério absoluto, prejudicar ou manchar a reputação da ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Modificar, adaptar, reformatar, recompilar, transmitir, publicar, licenciar, engenharia reversa, desmontar, reconstruir, copiar ou criar trabalhos derivados, transferir ou vender quaisquer serviços no Website ou parte deles, incluindo códigos-fonte ou algoritmos, exceto conforme expressamente autorizado por nós por escrito, ou na medida permitida por lei;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Alterar, remover, encobrir ou de outra forma danificar qualquer identificação, marca registrada, direito autoral ou outro aviso de qualquer aspecto dos serviços no Website;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Utilizar qualquer software de automação, hacks, mods ou qualquer outro software não autorizado de terceiros projetado para acessar, rastrear ou coletar os serviços da ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Utilizar qualquer software de terceiros não autorizado que intercepte, "minere" ou colete informações de ou através da ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Carregar, transmitir, publicar ou de qualquer outra forma disponibilizar qualquer conteúdo que seja, ou que possa ser razoavelmente esperado:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Ofensivo, discriminatório, difamatório, obsceno, ameaçador, abusivo, indecente ou ilegal, incluindo conteúdo que seja provável de ofender, insultar ou humilhar outros com base em raça, religião, etnia, gênero, idade, orientação sexual ou qualquer deficiência física ou mental;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>Não ser trabalho original seu, ou que possa infringir a propriedade intelectual ou outros direitos de outra pessoa;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Incluir uma imagem ou informações pessoais de outra pessoa, a menos que você tenha o consentimento dela; ou</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>Conter grandes quantidades de conteúdo não direcionado, indesejado ou repetitivo; ou</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Interferir, perturbar ou criar uma carga excessiva no Website ou em nosso negócio.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Se você acredita que um utilizador violou alguma das condições acima, por favor, entre em contato conosco.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Reservamo-nos o direito de bloquear, suspender ou cancelar qualquer utilizador, registro de membro em nosso Website, modificar ou remover qualquer material carregado, postado, transmitido ou disponibilizado de qualquer outra forma no Website por qualquer utilizador, sem aviso prévio; definir e limitar, recusar e/ou rejeitar devoluções de clientes a qualquer momento devido a histórico de devoluções irregular ou excessivo. Ao carregar, transmitir, publicar ou disponibilizar qualquer material via Website, você concede à ${textBrand} uma licença não exclusiva, mundial, isenta de royalties e perpétua para utilizar, reproduzir, editar e explorar o material de qualquer forma e para qualquer finalidade. A candidatura de membro pode não ser aprovada se o candidato tiver tido sua adesão cancelada no passado.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>Não somos responsáveis, e não aceitamos qualquer responsabilidade, com relação a qualquer material carregado, postado, transmitido ou disponibilizado no Website por qualquer pessoa que não nós. Não endossamos qualquer opinião, conselho ou declaração feita por qualquer pessoa que não seja a ${textBrand}.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Você concorda em indenizar a ${textBrand} e cada um dos seus funcionários, empregados, agentes, contratados, fornecedores e licenciantes (coletivamente "Afiliadas") em relação a qualquer responsabilidade, perdas ou danos (incluindo todos os custos legais e outros em base de indenização completa) sofridos ou incorridos por eles decorrentes (total ou parcialmente) do descumprimento ou falha em cumprir qualquer um destes Termos de Utilização, ou qualquer outro padrão ou conduta imprópria em relação ao objeto destes Termos de Utilização, por você ou qualquer uma de suas afiliadas.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Isenção de Garantias',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>O uso do site é por sua conta e risco. Nós negamos todas as garantias de qualquer tipo, sejam expressas ou implícitas.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Reservamo-nos o direito de modificar e interromper, temporária ou permanentemente, quaisquer serviços do site (ou qualquer parte dele), com ou sem aviso prévio. Você concorda que não seremos responsáveis perante você ou qualquer terceiro por qualquer modificação, suspensão ou interrupção de tais serviços.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    Não fornecemos nenhuma garantia de que:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>O site atenderá aos seus requisitos;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>O site será ininterrupto, pontual, seguro e livre de erros;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Quaisquer resultados obtidos com o uso do site serão precisos ou confiáveis; e</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>A qualidade de quaisquer produtos, serviços, informações ou outros materiais adquiridos ou obtidos por você através do site atenderá às suas expectativas.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>Não endossamos e não podemos ser responsáveis pelas mensagens, opiniões, pesquisas e recomendações de indivíduos, usuários e organizações de interesse. Também não endossamos qualquer patrocínio de prestadores de serviços específicos, tratamentos ou transações comerciais em quaisquer títulos, investimentos ou classes de títulos ou investimentos, nem a inclusão de um link para outro(s) site(s) ou recursos implica qualquer forma de endosso de nossa parte.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>Não revisamos ou editamos ativamente as mensagens, opiniões, pesquisas e recomendações dos usuários, mesmo que sejam feitas por meio do site. Embora as informações no site sejam obtidas de fontes consideradas confiáveis, nenhum dos dados, notícias, informações, relatórios, opiniões ou recomendações (se houver) foi personalizado para qualquer pessoa ou grupo específico.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Ao disponibilizar informações e dados no site, incluindo fatos, opiniões e recomendações de indivíduos e organizações de interesse, nós e nossos provedores de conteúdo não estamos fornecendo, alegando fornecer ou nos apresentando como fornecedores de conselhos financeiros, de investimento, fiscais ou jurídicos. Você deve sempre buscar o aconselhamento profissional relevante antes de tomar qualquer decisão.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div.Podemos monitorar ou revisar quaisquer áreas do site onde você transmita ou publique comunicações para promover sua eficiência, utilidade e adequação de uso. No entanto, não nos comprometemos a monitorar ou revisar todas as postagens ou comunicações, e negamos qualquer responsabilidade relacionada a todo o material do site, independentemente de surgir ou não sob as leis de direitos autorais, marcas registradas, difamação, privacidade, obscenidade ou outras.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Este site e todas as informações contidas nele são fornecidos pela ${textBrand} em uma base "como está" e "conforme disponível". A ${textBrand} e/ou suas respectivas afiliadas, acionistas, parceiros, diretores, funcionários e agentes:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Não fazem representações ou garantias de qualquer tipo, expressas ou implícitas, quanto ao funcionamento e disponibilidade deste site ou das informações, conteúdos, materiais ou produtos apresentados no site. Você concorda expressamente que o uso deste site é por sua conta e risco, na medida máxima permitida pela lei aplicável;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Negam todas as garantias, expressas ou implícitas, incluindo, mas não se limitando a, garantias implícitas de comercialização e adequação a uma finalidade específica;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Não garantem que o site, seus servidores ou e-mails enviados por nós estejam livres de vírus, componentes ou erros prejudiciais; e</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Não serão responsáveis por quaisquer danos de qualquer natureza, incluindo, mas não se limitando a danos diretos, indiretos, incidentais, punitivos e consequenciais ou quaisquer outras perdas ou responsabilidades decorrentes de (a) uso ou incapacidade de uso do site; (b) qualquer decisão tomada, ação ou inação tomada por qualquer parte com base no conteúdo do site; (c) interrupção dos negócios; (d) atraso/interrupção no acesso ao site; (e) não entrega, entrega equivocada, corrupção, destruição ou outra modificação de dados; (f) perda ou danos de qualquer tipo incorridos como resultado do acesso a links de terceiros no site; (g) vírus de computador, falhas ou mau funcionamento do sistema relacionado ao uso do site; ou (h) quaisquer atrasos, imprecisões, erros ou omissões no conteúdo do site.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Ao acessar nosso site, você assume todos os riscos associados ao seu uso, incluindo, mas não se limitando ao risco de que seu computador, dispositivos, software ou dados possam ser danificados por qualquer vírus transmitido por este site ou por qualquer conteúdo de terceiros ou site de terceiros, bem como os riscos relacionados à qualidade dos serviços fornecidos pelo prestador de serviços (se houver). Na extensão permitida por lei, todas as garantias, condições e reivindicações (sejam expressas ou implícitas) relacionadas a este site são excluídas.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Indenizações',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Você concorda em indenizar e isentar a ${textBrand}, nossas afiliadas, acionistas, parceiros, diretores, funcionários e agentes de qualquer reivindicação ou demanda, incluindo honorários advocatícios, feita por qualquer terceiro devido a ou resultante de qualquer conteúdo que você enviar, postar, transmitir ou disponibilizar no site, seu uso do site, sua conexão com o site, sua violação dos Termos de Uso ou sua violação de quaisquer direitos de outra parte.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Limitação de Responsabilidade',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Não seremos responsáveis por você por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou exemplares, incluindo, mas não se limitando a, danos por perda de lucros, boa vontade, uso, dados ou outras perdas intangíveis (mesmo que tenhamos sido avisados da possibilidade de tais danos), resultantes de:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>O uso ou a incapacidade de usar o Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>O custo da aquisição de produtos e serviços substitutos resultantes de quaisquer produtos, dados, informações ou serviços adquiridos ou obtidos ou mensagens recebidas, ou transações realizadas através do Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Acesso não autorizado ou alteração de suas transmissões ou dados;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Declarações ou conduta de qualquer terceiro no Website; e</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Qualquer outra questão relacionada ao Website.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Qualquer reivindicação de qualquer natureza, seja por parte de uma das partes contra a outra e qualquer ação contra a outra parte, deve ser iniciada dentro de 03 meses (ou o período mínimo permitido conforme previsto na lei aplicável, respectivamente) após a data em que a causa da ação surgir, após o qual a parte prejudicada não terá mais qualquer reivindicação contra a outra parte.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Ligações e Conteúdos de Terceiros',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>O Website pode conter links de hipertexto para websites operados por terceiros apenas para fins de referência e informação. ${textBrand} não analisou e não pode ser responsabilizado pelas políticas de privacidade e práticas de outros websites, mesmo que você os acesse através de links do Website. Recomendamos que você verifique os termos e condições e a política de privacidade de cada website que visitar e entre em contato com seu proprietário ou operador caso tenha alguma dúvida ou preocupação. Da mesma forma, se você chegou ao Website a partir de um website de terceiros, ${textBrand} não pode ser responsabilizado pelas políticas de privacidade e práticas dos proprietários ou operadores desse website de terceiros, e recomendamos que você verifique a política desse website de terceiros e entre em contato com seu proprietário ou operador caso tenha alguma dúvida ou preocupação. ${textBrand} não oferece garantias ou representações em relação a quaisquer websites de terceiros vinculados. ${textBrand} exclui toda responsabilidade por perdas ou danos na medida máxima permitida por lei, decorrentes de qualquer conteúdo nesses websites.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Para evitar dúvidas, a presença de um link para um website de terceiros não será considerada uma recomendação desses websites ou qualquer conselho ou informação contida neles por parte da ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Política de Privacidade',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Todos os dados pessoais sobre você estão sujeitos à nossa Política de Privacidade.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>A Política de Privacidade é considerada incorporada aos Termos de Uso por referência a esta Cláusula.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Se algum usuário do Website, ou qualquer outra pessoa, visualizar um documento publicado pela ${textBrand} e responder com qualquer informação ou feedback, como perguntas, comentários, sugestões ou similares, essas informações serão consideradas não confidenciais, e a ${textBrand} tem a liberdade de usar, reproduzir, divulgar e distribuir essas informações para qualquer outra pessoa.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Além disso, a ${textBrand} tem a liberdade de usar ou explorar quaisquer ideias, conceitos, know-how ou técnicas contidas nessas informações/feedback para qualquer finalidade, sem cobrar nenhuma taxa, incluindo, mas não se limitando a, desenvolver, fabricar e comercializar produtos que incorporem essas informações.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Propriedade Intelectual e Direitos Autorais',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Você reconhece e aceita que todos os direitos autorais, direitos de banco de dados e quaisquer outros direitos de propriedade intelectual que subsistem no design, layout, processos, funções, dados e conteúdo do Website são propriedade da ${textBrand}, de nossos provedores de informações ou licenciantes. O Website é de propriedade e operado pela ${textBrand}. Nenhuma parte do Website, seu conteúdo ou qualquer um de seus softwares e bancos de dados subjacentes pode ser copiado ou reproduzido, compartilhado com terceiros, engenharia reversa, desmontado ou usado para criar qualquer trabalho derivado (seja em formato físico ou eletrônico) sem o consentimento prévio por escrito da ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Força Maior',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Não teremos responsabilidade perante você por qualquer atraso ou falha no cumprimento de nossas obrigações para com você por motivos além do nosso controle, incluindo, entre outros, atos de Deus, guerra ou terrorismo, epidemia, pandemia, desastres naturais, problemas de conexão à internet, problemas de sistema, alterações em leis aplicáveis, regulamentos ou políticas governamentais, e escassez de suprimentos e serviços.
              </div>
          </div>
        `
    },
    {
      title: 'Terminação',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Podemos, em certas circunstâncias e sem aviso prévio, a nosso exclusivo critério, terminar imediatamente a sua conta (se houver). Causas para tal terminação incluem, sem limitação:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Violações dos Termos de Uso, ou outros documentos incorporados, diretrizes ou regras;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Pedido por autoridades policiais ou outras agências governamentais;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Exclusão de conta feita por iniciativa própria;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Violação dos direitos de propriedade intelectual de terceiros;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Descontinuação ou modificação material dos serviços no site, ou parte deles;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Questões técnicas ou problemas de segurança inesperados; ou</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Períodos prolongados de inatividade.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  A terminação da sua conta resultará em:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Remoção do acesso e impedimento de uso futuro de todas as ofertas do site associadas a essa conta; e</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Exclusão da sua senha e de todas as informações, arquivos e conteúdo relacionados à sua conta (ou parte dela).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>A terminação da sua conta não prejudicará quaisquer outros direitos ou recursos a que possamos ter direito nos termos dos Termos de Uso, na lei ou na equidade, nem afetará quaisquer direitos ou responsabilidades acumulados nem o início ou a continuação da vigência de qualquer disposição que seja expressamente ou implicitamente destinada a entrar em vigor ou continuar em vigor após tal terminação.</div>
              </div
          </div>
        `
    },
    {
      title: 'Direitos de Propriedade Intelectual',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Ao carregar qualquer imagem, fotografia e/ou descrição de listagem no Website (o "<strong>Conteúdo</strong>"), você concede a nós uma licença mundial, livre de royalties e irrevogável para usar, armazenar, hospedar, reproduzir, modificar, adaptar, adicionar marca d'água, comunicar, publicar, executar publicamente, exibir publicamente, criar obras derivadas e distribuir o Conteúdo. Ao carregar o Conteúdo, você garante que possui os direitos autorais do Conteúdo e, portanto, tem os direitos necessários para licenciá-lo para nós conforme declarado. Teremos o direito de adicionar marca d'água nas fotografias do Conteúdo, e os direitos autorais nas fotografias finais com marca d'água pertencerão exclusivamente a nós. Todos os outros nomes, produtos e marcas mencionados são os direitos de propriedade intelectual de seus respectivos proprietários. Nenhum material fornecido através do Website, incluindo texto, gráficos, código, classificações, rankings, bancos de dados, informações agregadas, Material do Website e/ou software, pode ser reproduzido, modificado, adaptado, distribuído, publicado, exibido, enviado, transmitido ou hiperlink de qualquer maneira e em qualquer forma sem nossa aprovação expressa por escrito, e a aprovação dos detentores de direitos autorais e marcas registradas respectivos. O Website e o Material do Website são apenas para uso pessoal, e você não pode se envolver em qualquer transação com outras partes em relação a esses serviços e conteúdos. Essas transações incluem transações comerciais e transações que afetem adversamente o valor comercial do Website e do Material do Website.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Geral',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Os Termos de Uso serão regidos e interpretados de acordo com as leis da República de Singapura, e quaisquer reivindicações ou disputas de qualquer natureza estarão sujeitas à jurisdição exclusiva dos tribunais da República de Singapura.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>A nossa omissão ou não exercício de qualquer direito ou disposição dos Termos de Uso não constituirá uma renúncia a tal direito ou disposição. Se qualquer disposição dos Termos de Uso for considerada inválida por um tribunal de jurisdição competente, as partes concordam que o tribunal deverá se esforçar para dar efeito às intenções das partes refletidas na disposição, e as outras disposições dos Termos de Uso permanecerão em pleno vigor e efeito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Qualquer conta que você possa ter conosco não é transferível e quaisquer direitos para tal conta terminarão com a sua morte. Ao receber uma cópia do seu atestado de óbito, sua conta será encerrada e todo o conteúdo que você gerou em sua conta será permanentemente excluído.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Todas as notificações para nós de acordo com os Termos de Uso devem ser enviadas por e-mail para: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
