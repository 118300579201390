import { cloneDeep, merge } from 'lodash';

import Types from './userDataTypes';

// MODELS
import MyHome from 'model/myHome';

// CONSTANTS
import { ModuleType, StorageKey } from 'constants/storage';
import { Obj } from 'constants/types';

export type EditMethod =
  | 'merge'
  | 'edit'
  | 'editFull'
  | 'markCompleted'
  | 'reset';

const genNewStateByMethod = (
  data: any = {},
  stateData: any,
  editMethod: EditMethod
) => {
  switch (editMethod) {
    case 'merge':
      return merge({ ...cloneDeep(stateData) }, data);
    case 'editFull':
      return data;
    case 'reset':
      return {
        actionKey: stateData.actionKey,
      };
    case 'edit':
      return {
        ...stateData,
        ...data,
      };
    case 'markCompleted':
      return {
        ...stateData,
        ...data,
        completed: true,
      };
    default:
      console.error('"type" is undefined');
      return null;
  }
};

type ModulePayload = {
  actionKey: ModuleType;
  [key: string]: any;
};

type ModuleBaseState = {
  actionKey?: string;
  completed?: boolean;
};

export type UserDataState = {
  meta: {
    id: number;
    creatorName: string;
    email: string;
  };
  loaded: boolean;
  userHome: ModuleBaseState & {
    data: MyHome;
  };
  userMortgagePlan: ModuleBaseState & Obj;
  projectHistory: ModuleBaseState & {
    data: MyHome[];
  };
};

const initialState: UserDataState = {
  meta: {
    id: 0,
    creatorName: '',
    email: '',
  },
  loaded: false,
  userHome: { actionKey: ModuleType.YourHome, data: new MyHome() },
  userMortgagePlan: { actionKey: ModuleType.Payment },
  projectHistory: { actionKey: ModuleType.ProjectHistory, data: [] },
};

export const moduleTypeReducerKeyMap: {
  [key in ModuleType]?: keyof UserDataState;
} = {
  [ModuleType.YourHome]: 'userHome',
  [ModuleType.Payment]: 'userMortgagePlan',
  [ModuleType.ProjectHistory]: 'projectHistory',
};

type PayloadToStateFunc = (payload: any) => any;

const payloadToStateMap: { [key in ModuleType]?: PayloadToStateFunc } = {
  [ModuleType.YourHome]: (payload: any) => ({
    actionKey: ModuleType.YourHome,
    data: new MyHome(payload?.data ? payload?.data : payload),
  }),
};

const userDataReducer = (state = initialState, action: any): UserDataState => {
  switch (action.type) {
    case Types.SaveCurrentUserDataSuccess:
    case Types.FetchCurrentUserDataSuccess: {
      const {
        item: { id, creatorName, email, moduleState },
      } = action.payload;

      const modules = moduleState?.dataInLocalDB.find(
        ({ key }: any) => key === StorageKey.Modules
      );

      if (!modules?.data) {
        return {
          // @ts-ignore
          loaded: true,
          ...state,
        };
      }

      return {
        ...state,
        meta: { id, creatorName, email },
        loaded: true,
        ...modules.data.reduce(
          (newState: any, modulePayload: ModulePayload) => {
            const reducerKey = moduleTypeReducerKeyMap[modulePayload.actionKey];
            if (reducerKey) {
              const payloadToState = payloadToStateMap[modulePayload.actionKey];
              newState[reducerKey] = payloadToState
                ? payloadToState(modulePayload)
                : modulePayload;
            }
            return newState;
          },
          {}
        ),
      };
    }

    case Types.FetchCurrentUserDataFailed:
      if (action.error?.message === 'Network Error') {
        return state;
        
      }
  
      console.error('Types.FetchCurrentUserDataFailed', action.error);
  
      return {
        ...state,
        loaded: true,
      };

    case Types.EditCurrentUserMortgagePlan:

    case Types.EditCurrentUserHome: {
      if (action.editMethod === 'reset') {
        return {
          ...state,
          userHome: {
            ...state.userHome,
            data: new MyHome(),
          },
        };
      }

      let userHomeData = state.userHome.data.clone();
      switch (action.editMethod) {
        case 'edit':
          userHomeData.assignByScenario(action.data);
          break;
        case 'editFull':
          userHomeData.assign(action.data);
          break;
        default:
          throw new Error(
            `UserHome has no handler of "${action.editMethod}" method.`
          );
      }

      const projectHistoryData = [...state.projectHistory.data];

      // Handle project history
      const index = projectHistoryData.findIndex(
        (item: MyHome) => item.projectId === userHomeData.projectId
      );

      if (index !== -1) {
        projectHistoryData[index] = userHomeData;
      } else {
        if (projectHistoryData.length === 10) {
          projectHistoryData.pop();
        }
        projectHistoryData.unshift(userHomeData);
      }

      return {
        ...state,
        userHome: {
          ...state.userHome,
          data: userHomeData,
        },
        projectHistory: {
          ...state.projectHistory,
          data: projectHistoryData,
        },
      };
    }

    case Types.EditCurrentUserProjectHistory: {
      // Handle project history
      const projectHistoryData = [...state.projectHistory.data];

      const index = projectHistoryData.findIndex(
        (item) => item.projectId === action.data.projectId
      );

      if (index !== -1) {
        projectHistoryData[index] = {
          ...projectHistoryData[index],
          ...action.data,
        };
      } else {
        if (projectHistoryData.length === 10) {
          projectHistoryData.pop();
        }
        projectHistoryData.unshift(action.data);
      }

      return {
        ...state,
        projectHistory: {
          ...state.projectHistory,
          data: projectHistoryData,
        },
      };
    }

    default:
      return state;
  }
};

export default userDataReducer;
