export const TERM_JA = (textBrand: string) => {
  return [
    {
      title: '利用規約の承諾',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>「https://levitate.homes」(以下「ウェブサイト」) へのアクセスおよび利用により、当社${textBrand} International Pte. Ltd. (以下「当社」「弊社」「私たち」または「${textBrand}」) との間で本利用規約（以下「利用規約」）に基づく法的拘束力を持つ契約が成立します。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>プライバシーポリシーを含む、利用規約に制約や条件なしで同意します。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>当社はいつでも利用規約を修正し、修正版をウェブサイトに掲載することがあります。ただし、個別の通知はお送りしません。修正された利用規約は、以前の全てのバージョンと置き換えられます。利用規約の修正や変更に同意されない場合は、直ちにウェブサイト上のサービスの利用を中止してください。修正が効力を発揮した後は、ウェブサイトの継続的な利用は、その修正を受け入れたものとみなします。いつでも最新の利用規約をご確認いただけます。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>当社は定期的に、ウェブサイトの利用に関するガイドラインやルールをウェブサイトに掲載する場合があります。これらのガイドラインやルールは、利用規約に参照として取り込まれます。</div>
            </div>
          </div>
        `
    },
    {
      title: 'ウェブサイトの利用',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>本契約のウェブサイトの利用に従ってすべての条件と条件に従うことに同意します。ウェブサイトに提供したコンテンツのうち、私たちが直接的な裁量によりここでの条件を違反した可能性があると疑わしい場合は、当該コンテンツを削除または削除することがあります。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>
              ウェブサイトにおいて私たちとのいかなる取引もしくはウェブサイトにおける私たちとのアカウント登録を行う際、あなたは次のことを保証します：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>ア.</div>
                  <div>あなたは自身で取引を行う合法的な権利があります；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>イ.</div>
                  <div>あなたは自分自身の正当な身元を使用しています；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>ウ.</div>
                  <div>提供した個人データは真実で正確であり、完全であり、最新である；および</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>エ.</div>
                  <div>提供した個人データを適切に保管および速やかに更新し、そのような情報が真実であることを確認します。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>未成年者（お住まいの国の法律によって定められた年齢未満のユーザー）は、保護者の監視なしでウェブサイトを使用したり、ウェブサイトに個人データを提供することはできません。未成年者の場合、保護者/法定代理人の許可を得て、または保護者/法定代理人の監督下にある場合に限り、ウェブサイトを使用したり、ウェブサイトに個人データを提供することができます。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>私たちがあなたに（またはあなたが選択した場合）ウェブサイトの一部にアクセスすることを可能にするパスワードを提供した場合、あなたはそのパスワードの機密性を保持し、あなたのアカウントの下で発生したすべてのアクティビティに対して完全に責任を負うことを認識します。私たちは、ログインしてウェブサイトを使用するユーザーがあなたであるかどうかを確認する責任はありません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.5.</div>
            <div>パスワードのセキュリティ違反が発生した場合には、私たちに通報することに同意します。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.6.</div>
            <div>私たちのセクション2の順守の失敗によって生じる損失または損害に対して私たちは責任を負いません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.7.</div>
            <div>私たちの商標、ロゴ、またはその他の所有情報（写真、テキスト、ページレイアウト、フォーム、説明、コンセプト、アイデア、デザイン、図面、写真、画像、その他のコンテンツを含む）を、私たちの明示的な書面による許可なしにフレームに取り込むことはできません。無許可の使用は、私たちが付与した許可または同意を終了します。</div>
          </div>
        </div>
      `
    },
    {
      title: 'ウェブサイトの資料',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>ウェブサイトへのアクセスと使用により、情報、データ、テキスト、ソフトウェア、写真、グラフィックス、ビデオ、メッセージ、コンセプト、アイデア、デザイン、図面、商品リスト、価格、または他のウェブサイトのユーザーがウェブサイトにアップロード、投稿、電子メールで送信、転送、またはウェブサイト上で公開または非公開で提供できる他の資料（「ウェブサイトの資料」）にさらされる可能性があります。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>ウェブサイトの資料は不適切であり、不適切または不快である可能性があることを理解しています。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>ウェブサイトの資料は、あなた自身の個人的な非商業利用のためにのみ使用されることを理解しています。同じ会社や組織の他の誰かに対して公開、配布、送信、再送信、再配布、放送、複製、または回覧することはできません。他のウェブサイトが運営するウェブサイトで他のウェブサイトの資料を投稿することもできません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>私たちの事前の書面による同意なしに、ウェブサイトの資料を他のウェブサイトまたはサーバーに複製、複製、コピー、販売、転売、表示、または提供しないことに同意します。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>私たちは、ウェブサイトの資料のいかなる誤りまたは遺漏についても一切責任を負いません。ウェブサイト上で投稿、電子メールで送信、転送、または他の方法で提供されるウェブサイトの資料の使用によって発生したいかなる損失または損害についても一切責任を負いません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>私たちのウェブサイトのフォーマットとコンテンツは予告なくいつでも変更される可能性があることを認識し、同意します。ウェブサイトの運営は、サポートやメンテナンス作業、コンテンツの更新、またはその他の理由で一時的または永久的に中止される可能性があります。ウェブサイトへのアクセスは、特定のユーザーに対しても一般的にもいつでも予告なく中止される可能性があります。</div>
          </div>
        </div>
      `
    },
    {
      title: '当ウェブサイト上の情報の正確性',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>${textBrand}は当ウェブサイトに正確な情報を含めるために合理的な努力をしますが、法律で許可される範囲内で、暗示されたものであるか他の方法であるかにかかわらず、提供された情報の正確性についてのいかなる保証または表現も行いません。 ${textBrand}は法律で許可される最大限度まで、当ウェブサイトの内容におけるいかなる誤りまたは省略に対しても責任を排除します。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>当ウェブサイトに含まれる詳細はガイド目的でのみです。当ウェブサイトに含まれる詳細、記述、寸法、状態への参照、使用と占有のための必要な許可、およびその他の詳細の正確性は保証されず、一般的なガイダンスとして提供されています。それらを事実の陳述として依存しないよう忠告します。自分自身の正確性を確認するようお勧めします。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand}は不動産を購入することが重要な財務的な取引であるため、提案されているいかなる不動産もすべての点であなたの要件に適していることを確認するためのすべての合理的な手段を講じるようお勧めします。当ウェブサイト内の材料が正確であるように合理的な手段を講じていますが、それはあなたの問い合わせや考慮の代替手段として受け入れられるべきではありません。依存したい情報を検証するための適切な手段を講じるようお勧めします。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>当ウェブサイト上の各製品の色とデザインの正確な反映を提供するために最善を尽くしていますが、実際の色とデザインは異なる場合があります。異なるデバイスの画面設定、設置場所の照明、時間の経過による製品仕上げのわずかな違いなどの理由により、実際の色とデザインが若干異なる場合があります。 ${textBrand}は、当社の責任ではない色やデザインの違いに対して責任を負いません。当ウェブサイトを使用して不動産または製品を購入する際には、実際の色とデザインとウェブサイト上の表示との間にわずかな違いがある可能性を受け入れることに同意するものとします。</div>
          </div>
        </div>
      `
    },
    {
      title: 'ユーザーの義務',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>5.1.</div>
                <div>
                  本利用規約に定められた義務に加えて、以下の行為を行ってはいけません：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>関連法令に違反してウェブサイトを利用すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>他者を害すること、虐待すること、嫌がらせをすること、ストーキングすること、中傷すること、名誉を傷つけること、脅迫すること、その他他者を攻撃するような行為を行うこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>ユーザーや第三者、または${textBrand}に不利益や損害をもたらす行為を行うこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>不正に複数の会員登録を申請したり保持したりする行為を行うこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>売春やポルノグラフィを含む、不道徳な行為または潜在的な不道徳な行為に従事すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>他の個人または団体をなりすまし、または虚偽の陳述をしたり、自分がその個人または団体との関係を偽って示したりすること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>虚偽、不正確、または誤解を招く情報を提供すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>h.</div>
                      <div>詐欺的、疑わしい、または違法な行為や取引に従事すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>i.</div>
                      <div>他のユーザーに対して迷惑メールを送信したり、第三者に対して迷惑メールを送信するためにウェブサイトのサービスを利用すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>j.</div>
                      <div>他のユーザーの個人情報を第三者に開示または配布し、その情報をユーザーの明確な同意なしでマーケティング目的で使用すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>k.</div>
                      <div>ウェブサイトのインフラに不合理または極端に大きな負荷をかける行為を行うこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>l.</div>
                      <div>ウイルスやウェブサイトのアクセスを妨げる可能性のあるその他のアイテムを含むコンテンツをウェブサイトにアップロードしたり、アップロードさせたりする行為を行うこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>m.</div>
                      <div>スパムの活動に参加したり、攻撃的な行為、侵害、または${textBrand}の倫理規定に合わないマーケティング活動、または${textBrand}のマーケティングに対する意図的なクリックや攻撃行為に参加したりすること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>n.</div>
                      <div>${textBrand}の裁量権または${textBrand}の商標に関連している、または${textBrand}のブランドに関連しているあらゆる判断に対して抗議すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>o.</div>
                      <div>
                        ${textBrand}の著作権、特許、商標、またはその他の知的財産権に対して何らかの手続きを行ったり、それらの知的財産権を侵害したり、または${textBrand}のウェブサイトまたは関連する部分に対してライセンス、サブライセンス、コンパイル、送信、公開、公表、送信、複製、設計変更、複製、または作成し、${textBrand}の明示的な同意なしにそのようなコンテンツまたはコードを送信すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>p.</div>
                      <div>任意の種類のサービスからの標識、商標、または著作権を削除、変更、または不適切に使用すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>q.</div>
                      <div>${textBrand}のサービスに不正にアクセスするソフトウェア、ハッキング、変更、または${textBrand}のサービスのデータを解析または収集するソフトウェアを使用すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>r.</div>
                      <div>
                        "著作物"を検知するような第三者のソフトウェアを使用して、${textBrand}からデータまたは情報を収集するか、または${textBrand}を通じてデータまたは情報を収集すること；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>s.</div>
                      <div>
                        以下のデータをアップロード、送信、投稿、または提供しないこと：
                        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                          <div style="display: flex; gap: 0.3rem;">
                            <div>I.</div>
                            <div>差別的、人種差別的、または宗教的な内容、または人間や動物に対する侮辱的な内容を含む内容；</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>II.</div>
                            <div>あなた自身の著作物でないか、他の個人または団体の知的財産権または著作権を侵害しているか；</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>III.</div>
                            <div>他の個人の個人情報を不正に公開または配布し、その情報を明確な同意なしでマーケティング目的で使用するか；または</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>IV.</div>
                            <div>目的にそぐわない、無用な、または重複した大量のコンテンツ；または</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>t.</div>
                      <div>ウェブサイトまたは${textBrand}のビジネスに不適切なトラフィックを発生させる行為を行うこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>もし、どのユーザーが本利用規約に違反していると考える場合は、お知らせください。</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.2.</div>
                <div>私たちはウェブサイトのメンバーシップをブロック、停止、またはキャンセルする権利を留保し、ユーザーがウェブサイトにアップロード、投稿、送信、または提供するコンテンツを予告なしに変更または削除する権利を留保します。過去にメンバーシップがキャンセルされたことがある場合、再度のメンバーシップ申請は承認されない可能性があります。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.3.</div>
                <div>私たちは、他の個人または団体がウェブサイトにアップロード、投稿、送信、または提供するコンテンツに対して一切の責任を負いません。私たちは、${textBrand}から外部の個人または団体によって行われたコメント、アドバイス、または発言をサポートしていません。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.4.</div>
                <div>あなたは、${textBrand}および${textBrand}の関係者、従業員、代理店、請負業者、提供業者、およびライセンス（すべてを合わせて「${textBrand}のお客様」）に対して、本利用規約に定められた条件の違反または違反による損失または損害（違法手続きとすべての補償金を含む）を補償することに同意します。これには、あなたのコンテンツまたはあなたの関連パートナーによる違反または違反行為も含まれます。
              </div>
          </div>
      `
    },
    {
      title: '免責事項',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>6.1.</div>
              <div>ウェブサイトの利用は、お客様自身のリスクによるものです。私たちは、明示的または黙示的ないかなる種類の保証も放棄します。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.2.</div>
              <div>私たちはいつでも、ウェブサイトのサービス（またはその一部）を予告なく一時的または永久に変更または停止する権利を留保します。変更、一時停止、またはサービスの永久停止に関して、私たちはあなたまたは第三者に対して一切の責任を負わないことに同意いただきます。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.3.</div>
              <div>
                  私たちは以下についての保証は一切ありません：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>ア.</div>
                      <div>ウェブサイトがお客様の要件を満たすこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>イ.</div>
                      <div>ウェブサイトが中断されず、適時に提供され、安全でエラーがないこと；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>ウ.</div>
                      <div>ウェブサイトの利用によって得られる結果が正確かつ信頼性があること；および</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>エ.</div>
                      <div>ウェブサイトを通じて購入または入手した製品、サービス、情報、その他の資料の品質がお客様の期待に適合すること。</div>
                    </div>
                  </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.4.</div>
              <div>私たちは個人、ユーザー、また興味を持つ組織のメッセージ、意見、研究、および推奨事項、または証券、投資、証券または投資クラスに関連する任意のサービスプロバイダー、治療法、または商業取引の支援を推奨することはできませんし、責任を負うことはできません。ウェブサイトに他のウェブサイトのリンク（s）やリソースの含まれることは、私たちによるいかなる形式の支持を意味するものではありません。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.5.</div>
              <div>私たちはユーザーのメッセージ、意見、研究、および推奨事項を積極的にレビューまたは編集していませんが、ウェブサイトを使用してユーザーによって行われる場合でも、そのようなメッセージ、意見、研究、および推奨事項に関しては一切の責任を負いません。ウェブサイトの情報は信頼性のある情報源から得られていますが、データ、ニュース、情報、レポート、意見、および推奨事項（あれば）のいずれもが特定の個人または人のクラスに対してカスタマイズされていないことに注意してください。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.6.</div>
              <div>ウェブサイトに情報やデータを提供することにより、個人や組織の事実、意見、研究、および推奨事項が含まれる場合でも、私たちや私たちのコンテンツ提供者は金融、投資、税務、および法的なアドバイスを提供していると見なされることはありません。このような決定を行う前に常に適切な専門的なアドバイスを求める必要があります。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.7.</div>
              <div>私たちはウェブサイト上でコミュニケーションを送信または投稿する場所を効率的で有用、適切な利用に向けて監視またはレビューすることがあります。ただし、私たちはすべての投稿やコミュニケーションを監視またはレビューすることを保証せず、著作権、商標、名誉毀損、プライバシー、わいせつ、またはその他の法律の下で生じるウェブサイトの材料に関連するすべての責任を否認します。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.8.</div>
              <div>
                このウェブサイトおよびその内部に含まれるすべての情報は、${textBrand}によって「現状有姿」および「現状有姿で利用可能」の基礎で提供されます。${textBrand}および/またはそれに関連する関連会社、株主、パートナー、役員、取締役、従業員、および代理店：
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ア.</div>
                    <div>このウェブサイトの運営と利用可能性に関して、明示的または黙示的な保証を一切行わないこと。適用可能な法律により認められる範囲で、このウェブサイトの使用は、完全にあなたの個人のリスクによるものであることを明確に合意します。</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>イ.</div>
                    <div>商業利用および特定の目的への適合性など、明示的または黙示的な保証をすべて放棄します。</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ウ.</div>
                    <div>ウェブサイト、そのサーバー、または当社から送信される電子メールがウイルスまたはその他の有害な部分またはエラーを含まないことを保証しません。</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>エ.</div>
                    <div>（ア）ウェブサイトの使用または使用不能によるいかなる種類の損害、（イ）ウェブサイトの内容に基づいて行われたいかなる決定、行動または不作為、（ウ）事業の中断、（エ）ウェブサイトへのアクセスの遅延または中断、（オ）データの非配信、誤配信、壊滅またはその他の変更、（カ）ウェブサイト上の第三者リンクのアクセスによるいかなる種類の損失または損害、（キ）コンピュータウイルス、システムの障害または動作不良による損害、および（ク）ウェブサイトのコンテンツの遅延、誤り、エラーに起因するいかなる種類の損失または損害について、一切の責任を負わないこと。</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.9.</div>
              <div>ウェブサイトにアクセスすることにより、ウェブサイトによって送信されるウイルスまたは第三者コンテンツまたは第三者ウェブサイトによって損傷を受ける可能性を含む、その使用に関連するすべてのリスクを負うものとします。法律に許可される範囲内で、このウェブサイトに関連するすべての保証、条件、および請求（明示的または黙示的であれ）はここに除外されます。</div>
            </div>
        </div>
      `
    },
    {
      title: '免責事項',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>お客様は、当ウェブサイトに提出、投稿、送信または利用可能にするすべてのコンテンツに関連して、第三者による要求、弁護士費用を含む、いかなる請求に対しても${textBrand}、当社の提携会社、株主、パートナー、役員、取締役、従業員、および代理人を無傷にし、免責します。当ウェブサイトの利用、当ウェブサイトへの接続、利用規約の違反、または他の当事者の権利の侵害に起因する要求または請求。</div>
              </div>
          </div>
        `
    },
    {
      title: '責任の制限',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  当社は、以下により生じる直接的、間接的、付随的、特別、結果的または模範的な損害、ただし、利益の損失、評判の損失、使用料の損失、データの損失、その他の無形の損失（当社がそのような損害の可能性を指摘された場合であっても）、についてあなたに対して一切の責任を負いません：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>ウェブサイトの使用または使用不能；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>ウェブサイトを通じて購入または取得された製品、データ、情報、またはサービス、受信されたメッセージ、またはウェブサイトを通じて入力された取引によって生じた代替製品およびサービスの調達コスト；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>無許可のアクセスまたはデータの改変；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>ウェブサイト上の第三者の声明または行動；および</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>ウェブサイトに関連するその他の事項。</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>当事者のいずれかが他の当事者に対して行ういかなる性質の請求および他の当事者に対するいかなる行動も、原因が発生した日から03か月以内（またはそれに対して適用される該当する法律の最低期間）に開始されなければなりません。この期間の後、被害を受けた当事者は他の当事者に対して一切の追加の請求を行うことはありません。</div>
              </div>
          </div>
        `
    },
    {
      title: 'リンクと第三者コンテンツ',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>当ウェブサイトは、参照および情報提供の目的で第三者によって運営されるウェブサイトにハイパーテキストリンクされている場合があります。${textBrand}は、これらのウェブサイトのプライバシーポリシーやプラクティスを審査していないため、これらのウェブサイトへのリンクを使用してアクセスした場合であっても、他のウェブサイトのプライバシーポリシーやプラクティスに対して責任を持つことはできません。したがって、訪問する各ウェブサイトの利用規約、プライバシーポリシーを確認し、懸念や質問がある場合はそのウェブサイトの所有者または運営者に連絡することをお勧めします。同様に、第三者のウェブサイトから当ウェブサイトにリンクを張った場合、${textBrand}はその第三者のウェブサイトのプライバシーポリシーやプラクティスに責任を持つことはできません。そのため、その第三者のウェブサイトのポリシーを確認し、懸念や質問がある場合はそのウェブサイトの所有者または運営者に連絡することをお勧めします。${textBrand}は、リンクされた第三者のウェブサイトに関していかなる保証や表現も行いません。${textBrand}は、当該ウェブサイトのコンテンツに起因する一切の損害賠償責任を法律で認められる限度において免除します。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>不明確にするために、第三者のウェブサイトへのリンクの存在は、${textBrand}によるこれらのウェブサイトへの推薦またはそのウェブサイト上で提供されるアドバイスまたは情報を意味するものではありません。</div>
              </div>
          </div>
        `
    },
    {
      title: 'プライバシーポリシー',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>お客様に関するすべての個人データは、弊社のプライバシーポリシーの対象となります。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>プライバシーポリシーは、本契約条件に言及して本項に組み込まれたものとみなされます。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>ウェブサイトのユーザーやその他の人が${textBrand}によって公開されたドキュメントを閲覧し、質問、コメント、提案などの情報で応答した場合、その情報は機密情報とみなされず、${textBrand}は当該情報を他の人物に使用、複製、公開、配布する権利を有します。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>さらに、${textBrand}は当該情報/フィードバックに含まれるアイデア、コンセプト、ノウハウ、または技術を無償で使用または利用する権利を有します。これには、それらの情報を取り入れた製品の開発、製造、マーケティングを含みますが、これらに限定されるものではありません。</div>
              </div>
          </div>
        `
    },
    {
      title: '知的財産権と著作権',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>ウェブサイトのデザイン、レイアウト、プロセス、機能、データ、およびコンテンツに存在するすべての著作権、データベース権およびその他の知的財産権は、${textBrand}、情報提供者、またはライセンサーの財産であることを認識し、受け入れます。ウェブサイトは${textBrand}が所有し、運営しています。ウェブサイトの一部、そのコンテンツ、またはその基礎となるソフトウェアとデータベースのいずれも、${textBrand}の事前の書面による同意なしに、第三者と共有したり、逆アセンブルしたり、派生作品を作成したり（物理的または電子的な形式であるかにかかわらず）、複製したりすることはできません。</div>
              </div>
          </div>
        `
    },
    {
      title: '不可抗力',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  我々は我々のコントロール外の理由によるあなたへの義務の遅延や不履行に対して責任を負いません。これには、天災、戦争またはテロリズム、流行病、パンデミック、自然災害、インターネット接続の問題、システムの問題、適用法、規制、または政府のポリシーの変更、および供給品とサービスの不足が含まれます。
              </div>
          </div>
        `
    },
    {
      title: '終了',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  私たちは、特定の状況下で、事前の通知なしに、私たちの単独の裁量で、あなたのアカウント（もしあれば）を即座に終了することがあります。そのような終了の理由は、以下を含むがこれに限定されないものとします：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>利用規約またはその他の組み込まれた文書、ガイドライン、またはルールの違反；</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>法執行機関またはその他の政府機関による要請；</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>自己によるアカウントの削除；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>他者の知的財産権の侵害；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>ウェブサイト上のサービス、またはその一部の廃止または重大な変更；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>予期しない技術的またはセキュリティ上の問題；または</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>長期間の非アクティブ。</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  あなたのアカウントの終了により、以下の結果となります：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>当ウェブサイトのすべての提供物へのアクセスの削除、およびそのアカウントに関連するすべての情報、ファイル、およびコンテンツ（またはその一部）の削除；および</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>あなたのパスワードおよびそれに関連するすべての情報、ファイル、およびコンテンツ（またはその一部）の削除。</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>あなたのアカウントの終了は、利用規約、法律、または公平性の下で私たちが享有する他の権利または救済を妨げるものではありません。また、蓄積された権利または負債またはその終了後に有効になるか継続することを意図的または暗黙のうちに示す規定の有効化または継続に影響を与えるものではありません。</div>
              </div
          </div>
        `
    },
    {
      title: '知的財産権',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>あなたがウェブサイトに画像、写真、および/またはリストの説明をアップロードする際（以下「コンテンツ」といいます）、あなたはそのコンテンツに対して、私たちに対して世界中で無償で、譲渡不可のライセンスを付与し、それを使用、保存、ホスト、複製、修正、適応、ウォーターマークを入れ、連絡、公開、公衆に展示、コンテンツを基にした派生作品を作成し、配布することができるものとします。コンテンツをアップロードすることにより、あなたはそのコンテンツの著作権を保有していることを表明し、保証するものとします。私たちはコンテンツに含まれる写真にウォーターマークを付ける権利を有し、ウォーターマーク付きの写真の著作権は私たちに帰属します。すべての他の言及されている名前、製品、および商標はそれぞれの所有者の知的財産権を構成します。ウェブサイトを通じて提供されるすべての素材、テキスト、グラフィックス、コード、レビュー、ランキング、データベース、関連情報、ウェブサイトの素材および/またはソフトウェアは、私たちの事前に書面による承認なしに、いかなる手段または形態でも複製、修正、複製、公開、展示、アップロード、投稿、送信、またはリンクすることはできません。すべてのその他の権利保有者および商標権所有者の書面による許可を得ることなく、いかなる手段または形態でも行動することはできません。</div>
              </div>
          </div>
        `
    },
    {
      title: '一般的な事項',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>利用規約はシンガポール共和国の法律に従い解釈されるものとします。また、いかなる請求や紛争も、シンガポール共和国の裁判所の専属管轄権に服するものとします。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>私たちが利用規約のいずれかの権利または規定を行使しない場合であっても、それはその権利または規定を放棄したものではありません。もし利用規約のいずれかの規定が裁判所によって無効と判断された場合、当事者はその規定の意図を実現しようとする裁判所の努力に同意し、利用規約の他の規定は完全に有効であり続けるものとします。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>私たちとの間で持つかもしれないアカウントは譲渡不可であり、そのようなアカウントに関連する権利はあなたの死亡と共に終了します。あなたの死亡の証明書のコピーの受領を受け取った後、あなたのアカウントは終了され、アカウントで作成したすべてのコンテンツは永久に削除されます。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>利用規約に基づくすべての通知は、次のメールアドレスにメールで送信する必要があります：<a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。</div>
              </div>
          </div>
        `
    }
  ]
};
