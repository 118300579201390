export const TERM_ES = (textBrand: string) => {
  return [
    {
      title: 'Aceptación de Términos',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Al acceder y utilizar <a href="https://levitate.homes">https://levitate.homes</a> (el "Sitio web"), usted celebra un acuerdo legalmente vinculante en los términos y condiciones aquí establecidos ("Términos de Uso") con nosotros, ${textBrand} International Pte. Ltd. ("nosotros", "nuestro" o "${textBrand}"), propietario y administrador del Sitio web.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Acepta sin limitaciones o restricciones los Términos de Uso, incluida la Política de Privacidad.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Podemos modificar estos Términos de Uso en cualquier momento al publicar una versión revisada en el Sitio web. Sin embargo, no se enviará un aviso individual. Cualquier Término de Uso modificado reemplazará todas las versiones anteriores del mismo. Si no está de acuerdo con las modificaciones o cambios en los Términos de Uso, debe cesar inmediatamente el uso de cualquiera de los servicios en el Sitio web. Después de que cualquiera de dichas modificaciones entre en vigencia, consideraremos que su uso continuado del Sitio web constituye su aceptación y acuerdo con dicha modificación. Puede revisar la versión más reciente de los Términos de Uso en cualquier momento.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>De vez en cuando, publicaremos en el Sitio web directrices y reglas relacionadas con el uso del Sitio web. Todas estas directrices o reglas se incorporan por referencia en los Términos de Uso.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Uso del Sitio Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>Al acceder y utilizar el sitio web <a href="https://levitate.homes">https://levitate.homes</a> (el "Sitio Web"), usted acepta un acuerdo legalmente vinculante en los términos y condiciones aquí establecidos ("Términos de Uso") con nosotros, ${textBrand} International Pte. Ltd. ("nosotros", "nuestro" o "${textBrand}"), el propietario y administrador del Sitio Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>Acepta sin limitaciones ni calificaciones los Términos de Uso, incluida la Política de Privacidad.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Podemos modificar estos Términos de Uso en cualquier momento publicando una versión revisada en el Sitio Web. Sin embargo, no se enviará un aviso individual a usted. Cualquier Término de Uso enmendado reemplazará todas las versiones anteriores del mismo. Si no está de acuerdo con las enmiendas o cambios en los Términos de Uso, debe cesar inmediatamente el uso de cualquier servicio en el Sitio Web. Después de que cualquier enmienda entre en vigor, consideraremos que su uso continuado del Sitio Web constituye su aceptación y acuerdo con dicha enmienda. Puede revisar la versión más actual de los Términos de Uso en cualquier momento.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>De vez en cuando, publicaremos en el Sitio Web directrices y reglas relacionadas con el uso del Sitio Web. Todas esas directrices o reglas se incorporan aquí por referencia a los Términos de Uso.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Material del Sitio Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Al acceder y utilizar el Sitio Web, puede estar expuesto a información, datos, textos, software, fotografías, gráficos, videos, mensajes, conceptos, ideas, diseños, dibujos, listados de productos, precios u otros materiales que nosotros y/o otros usuarios del Sitio Web pueden cargar, publicar, enviar por correo electrónico, transmitir o poner a disposición en el Sitio Web ("Material del Sitio Web"), ya sea públicamente o en privado.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Usted comprende que el Material del Sitio Web puede ser ofensivo, indecente u objetable.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Usted comprende que el Material del Sitio Web solo puede ser utilizado para su propio uso personal y no comercial, y no para publicación, distribución, transmisión, retransmisión, redistribución, difusión, reproducción o circulación a otra persona en la misma empresa u organización, ni para publicar en otros sitios web o foros, grupos de noticias, listas de correo electrónico, tablones de anuncios electrónicos o chats en Internet operados por otros sitios web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Usted acepta no reproducir, duplicar, copiar, vender, revender, mostrar o proporcionar de cualquier manera el Material del Sitio Web en otro sitio web o servidor sin nuestro consentimiento previo por escrito.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>No nos hacemos responsables, en ningún caso, de los errores u omisiones en cualquier Material del Sitio Web, ni de las pérdidas o daños de cualquier tipo incurridos como resultado del uso de cualquier Material del Sitio Web publicado, enviado por correo electrónico, transmitido o puesto a disposición de otra manera en el Sitio Web.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>Usted reconoce y acepta que el formato y el contenido del Sitio Web pueden cambiar en cualquier momento sin previo aviso; la operación del Sitio Web puede suspenderse o interrumpirse por trabajos de soporte o mantenimiento, para actualizar su contenido o por cualquier otro motivo; y el acceso al Sitio Web puede ser terminado, ya sea en relación con usuarios específicos o de manera general, en cualquier momento y sin previo aviso.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Precisión de la Información en nuestro Sitio Web',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Aunque ${textBrand} realiza esfuerzos razonables para incluir información precisa en nuestro sitio web, no proporcionamos garantías ni representaciones sobre la precisión de cualquier información proporcionada, ya sea implícita o no, en la medida permitida por la ley. ${textBrand} excluye toda responsabilidad por cualquier error u omisión en el contenido del sitio web en la medida máxima permitida por la ley.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Los detalles contenidos en nuestro sitio web son solo con fines orientativos. No se garantiza la precisión de ningún detalle, descripción, dimensiones, referencias al estado, permisos necesarios para el uso y ocupación y otros detalles contenidos en nuestro sitio web, y es solo para orientación general. Se recomienda no confiar en ellos como declaraciones de hecho o representaciones y que usted mismo se asegure de su precisión.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand} aconseja que, dado que la compra de bienes raíces es un compromiso financiero importante, debe tomar todas las medidas razonables para asegurarse de que cualquier propiedad que esté considerando comprar sea adecuada para sus necesidades en todos los aspectos. Hemos tomado medidas razonables para asegurar que los materiales contenidos en el sitio web sean precisos, pero no deben considerarse como un sustituto de sus investigaciones y consideraciones. Se le recomienda que tome las medidas apropiadas para verificar cualquier información en la que desee confiar.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>A pesar de todos los esfuerzos para proporcionar una representación precisa del color y diseño de cada producto en nuestro sitio web, los colores y el diseño reales pueden variar ligeramente, debido a diferentes configuraciones de pantalla del dispositivo, la iluminación en el lugar de instalación, pequeñas diferencias en los acabados del producto con el tiempo y otros factores. ${textBrand} no aceptará responsabilidad por cualquier diferencia de color o diseño que no sea culpa nuestra. Al comprar una propiedad o producto utilizando nuestro sitio web, usted acepta el pequeño riesgo de que haya pequeñas variaciones entre los colores y el diseño reales y la representación en nuestro sitio web.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Obligaciones del Usuario',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>
              Además de las obligaciones especificadas en estos Términos de Uso, NO debe:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Utilizar el Sitio Web en violación de las leyes o regulaciones aplicables;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Actuar de manera que perjudique, abuse, acose, persiga, difame, calumnie, amenace u ofenda a otros, o utilizar el Sitio Web con tales fines;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Llevar a cabo acciones que causen o puedan causar inconvenientes, desventajas o daños al(los) usuario(s), a terceros o a ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Realizar actos de solicitud de membresía fraudulentos o mantener múltiples registros de membresía de manera fraudulenta;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Participar en cualquier actividad inmoral o potencialmente inmoral, incluyendo, pero no limitado a la prostitución y pornografía;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Hacerse pasar por cualquier persona o entidad, o declarar falsamente o de cualquier otra forma representar su afiliación con una persona o entidad;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Proporcionar información falsa, inexacta o engañosa;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>Participar en actividades potencialmente fraudulentas, sospechosas o ilegales y/o transacciones;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>Enviar correos electrónicos no solicitados a cualquier usuario o utilizar los servicios del Sitio Web para cobrar pagos por el envío, o ayudar en el envío de correos electrónicos no solicitados a terceros;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>Divulgar o distribuir información personal de otro usuario a terceros o utilizar dicha información con fines de marketing sin el consentimiento expreso de ese usuario;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>Tomar cualquier acción que imponga una carga irrazonable o desproporcionadamente grande en la infraestructura del Sitio Web;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>Cargar, o causar la carga, de cualquier contenido en el Sitio Web que contenga virus, o cualquier otro elemento que pueda dañar, interferir, afectar adversamente o dificultar el acceso al Sitio Web;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>Participar, o hacer que otros usuarios participen, en spam, phishing, actividades inapropiadas, maliciosas o, a nuestro criterio absoluto, clics fraudulentos, impresiones o actividades de marketing relacionadas con ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>A nuestro criterio absoluto, perjudicar o manchar la reputación de ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>Modificar, adaptar, reformatear, recompilar, transmitir, publicar, licenciar, ingeniería inversa, desmontar, reconstruir, copiar o crear trabajos derivados, transferir o vender cualquier servicio en el Sitio Web o parte de ellos, incluidos los códigos fuente o algoritmos, excepto según lo autorizado expresamente por nosotros por escrito, o en la medida permitida por la ley;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>Alterar, eliminar, cubrir o dañar de otra forma cualquier identificación, marca comercial, derecho de autor u otro aviso de cualquier aspecto de los servicios en el Sitio Web;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>Utilizar cualquier software de automatización, hacks, mods o cualquier otro software no autorizado de terceros diseñado para acceder, rastrear o recolectar los servicios de ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>Utilizar cualquier software de terceros no autorizado que intercepte, "mine" o recolecte información de o a través de ${textBrand};</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    Cargar, transmitir, publicar o de cualquier otra forma poner a disposición cualquier contenido que sea, o pueda razonablemente esperarse que sea:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>Ofensivo, discriminatorio, difamatorio, obsceno, amenazante, abusivo, indecente o ilegal, incluido el contenido que es probable que ofenda, insulte o humille a otros en función de su raza, religión, etnia, género, edad, orientación sexual o cualquier discapacidad física o mental;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>No sea su trabajo original, o que pueda infringir la propiedad intelectual u otros derechos de otra persona;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>Incluya una imagen o información personal de otra persona, a menos que cuente con su consentimiento; o</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>Contenga grandes cantidades de contenido no dirigido, no deseado o repetitivo; o</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>Interferir, interrumpir o crear una carga excesiva en el Sitio Web o en nuestro negocio.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>Si cree que un usuario ha incumplido alguna de las condiciones anteriores, comuníquese con nosotros.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>Nos reservamos el derecho de bloquear, suspender o cancelar cualquier usuario, registro de membresía en nuestro Sitio Web y modificar o eliminar cualquier material cargado, publicado, transmitido o puesto a disposición de cualquier otra forma en el Sitio Web por cualquier usuario, sin previo aviso; definir y limitar, rechazar y/o rechazar devoluciones de clientes en cualquier momento debido a un historial de devoluciones irregular o excesivo. Al cargar, transmitir, publicar o poner a disposición de cualquier otra forma cualquier material a través del Sitio Web, usted otorga a ${textBrand} una licencia no exclusiva, mundial, libre de regalías y perpetua para utilizar, reproducir, editar y explotar el material en cualquier forma y para cualquier propósito. La solicitud de membresía puede no ser aprobada si el solicitante ha tenido su membresía cancelada en el pasado.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>No somos responsables, y no aceptamos ninguna responsabilidad, con respecto a cualquier material cargado, publicado, transmitido o puesto a disposición en el Sitio Web por cualquier persona que no sea nosotros. No respaldamos ninguna opinión, consejo o declaración realizada por cualquier persona que no sea ${textBrand}.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>Usted acepta indemnizar a ${textBrand} y a cada uno de los empleados, empleados, agentes, contratistas, proveedores y licenciantes (colectivamente, "Afiliados") de ${textBrand} con respecto a cualquier responsabilidad, pérdida o daño (incluidos todos los costos legales y otros en base a indemnización completa) sufrido o incurrido por ellos que surja (total o parcialmente) del incumplimiento o la falta de cumplimiento de cualquiera de estos Términos de Uso, o cualquier otro incumplimiento o conducta incorrecta con respecto al objeto de estos Términos de Uso, por usted o alguno de sus afiliados.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Exención de Responsabilidades',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Su uso del sitio web es bajo su propio riesgo. Rechazamos todas las garantías de cualquier tipo, ya sean expresas o implícitas.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>Nos reservamos el derecho de modificar y discontinuar, de forma temporal o permanente, cualquier servicio del sitio web (o cualquier parte del mismo) con o sin previo aviso. Usted acepta que no seremos responsables ante usted ni ante ningún tercero por cualquier modificación, suspensión o interrupción de dichos servicios.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    No ofrecemos ninguna garantía de que:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>El sitio web cumpla con sus requisitos;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>El sitio web sea ininterrumpido, oportuno, seguro y libre de errores;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Cualquier resultado obtenido del uso del sitio web sea preciso o confiable; y</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>La calidad de cualquier producto, servicio, información u otro material adquirido u obtenido a través del sitio web cumpla con sus expectativas.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>No podemos y no avalamos, ni somos responsables de los mensajes, puntos de vista, opiniones, investigaciones y recomendaciones de individuos, usuarios y organizaciones de interés, ni abogamos por ningún patrocinio de un proveedor de servicios en particular, curso de tratamiento o transacciones comerciales en títulos, inversiones o clases de valores, ni implica la inclusión en el sitio web de un enlace a otro(s) sitio(s) o recursos algún tipo de respaldo por parte nuestra.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>No revisamos ni editamos activamente los mensajes, puntos de vista, opiniones, investigaciones y recomendaciones de los usuarios, incluso si dichos mensajes, puntos de vista, opiniones, investigaciones y recomendaciones son realizados por los usuarios a través del sitio web. Aunque la información del sitio web se ha obtenido de fuentes que se consideran confiables, ninguno de los datos, noticias, información, informes, opiniones o recomendaciones (si las hubiera) ha sido adaptado para una persona o grupo específico.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>Al poner a disposición información y datos en el sitio web, incluidos hechos, puntos de vista, opiniones y recomendaciones de individuos y organizaciones de interés, nosotros y nuestros proveedores de contenido no estamos proporcionando, afirmando proporcionar ni presentándonos como proveedores de asesoramiento financiero, de inversión, tributario o legal. Siempre debe buscar el asesoramiento profesional pertinente antes de tomar decisiones de otro tipo.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div.Podemos monitorear o revisar cualquier área del sitio web donde usted transmita o publique comunicaciones con el propósito de promover su eficiencia, utilidad y adecuación de uso. Sin embargo, no nos comprometemos a monitorear o revisar todas las publicaciones o comunicaciones, y rechazamos toda responsabilidad relacionada con todo el material del sitio web, ya sea que surja o no bajo las leyes de derechos de autor, marcas registradas, difamación, privacidad, obscenidad u otras.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  Este sitio web y toda la información contenida en él se proporcionan por ${textBrand} "tal cual" y "según disponibilidad". ${textBrand} y/o sus respectivas afiliadas, accionistas, socios, directores, empleados y agentes:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>No hacen representaciones ni garantías de ningún tipo, expresas o implícitas, respecto al funcionamiento y disponibilidad de este sitio web o de la información, contenidos, materiales o productos presentados en el sitio web. Usted acepta expresamente que el uso de este sitio web es bajo su propio riesgo, hasta el máximo permitido por la ley aplicable;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Rechazan todas las garantías, expresas o implícitas, incluidas, entre otras, las garantías implícitas de comerciabilidad y adecuación para un fin determinado;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>No garantizan que el sitio web, sus servidores o los correos electrónicos enviados por nosotros estén libres de virus o componentes dañinos o errores; y</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>No serán responsables de los daños de cualquier tipo, incluidos, entre otros, daños directos, indirectos, incidentales, punitivos y consecuenciales, ni de otras pérdidas o responsabilidades derivadas de (a) el uso o la imposibilidad de uso del sitio web; (b) cualquier decisión tomada, acción u omisión realizada por cualquier parte en función del contenido del sitio web; (c) la interrupción de las actividades comerciales; (d) demoras/interrupciones en el acceso al sitio web; (e) la no entrega, entrega equivocada, corrupción, destrucción u otra modificación de datos; (f) pérdidas o daños de cualquier tipo incurridos como resultado del acceso a enlaces de terceros en el sitio web; (g) virus informáticos, fallas o mal funcionamiento del sistema relacionado con el uso del sitio web; o (h) cualquier demora, imprecisión, error u omisión en el contenido del sitio web.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>Al acceder a nuestro sitio web, asume todos los riesgos asociados con su uso, incluido, entre otros, el riesgo de que su computadora, dispositivos, software o datos puedan ser dañados por cualquier virus transmitido por este sitio web o por cualquier contenido o sitio web de terceros, así como los riesgos relacionados con la calidad del servicio proporcionado por el proveedor de servicios (si lo hubiera). En la medida permitida por la ley, se excluyen todas las garantías, condiciones y reclamaciones (ya sean expresas o implícitas) relacionadas con este sitio web.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Indemnizaciones',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Aceptas indemnizar y eximir de responsabilidad a ${textBrand}, y a nuestras afiliadas, accionistas, socios, directores, empleados y agentes, de cualquier reclamación o demanda, incluidos los honorarios de abogados, realizada por cualquier tercero debido a o resultante de cualquier contenido que envíes, publiques, transmitas o pongas a disposición en el sitio web, tu uso del sitio web, tu conexión al sitio web, tu violación de los Términos de Uso o tu violación de los derechos de otra parte.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Limitación de Responsabilidad',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  No seremos responsables ante usted por daños directos, indirectos, incidentales, especiales, consecuentes o ejemplares, incluidos, entre otros, daños por pérdida de beneficios, reputación, uso, datos u otras pérdidas intangibles (incluso si se nos ha informado de la posibilidad de dichos daños), resultantes de:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>El uso o la imposibilidad de usar el Sitio web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>El costo de adquisición de productos y servicios de sustitución resultantes de cualquier producto, datos, información o servicios adquiridos u obtenidos o mensajes recibidos o transacciones realizadas a través del Sitio web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Acceso no autorizado o alteración de sus transmisiones o datos;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Declaraciones o conducta de cualquier tercero en el Sitio web; y</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Cualquier otro asunto relacionado con el Sitio web.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Cualquier reclamo de cualquier naturaleza por parte de una de las partes contra la otra parte y cualquier acción contra la otra parte debe iniciarse dentro de los 03 meses (o el período mínimo permitido según lo previsto en la ley aplicable) después de la fecha en que surja la causa de la acción, después de lo cual la parte agraviada no tendrá ningún reclamo adicional contra la otra parte.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Enlaces y Contenido de Terceros',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>El sitio web puede contener enlaces de hipertexto a sitios web operados por terceros solo con fines de referencia e información. ${textBrand} no ha revisado y no puede ser responsable de las políticas de privacidad y prácticas de otros sitios web, incluso si accede a ellos mediante enlaces desde el sitio web. Recomendamos que verifique los términos y condiciones y la política de privacidad de cada sitio web que visite y se ponga en contacto con su propietario u operador si tiene alguna inquietud o pregunta. Del mismo modo, si ha llegado al sitio web desde un sitio web de terceros, ${textBrand} no puede ser responsable de las políticas de privacidad y prácticas de los propietarios u operadores de ese sitio web de terceros, y recomendamos que verifique la política de ese sitio web de terceros y se ponga en contacto con su propietario u operador si tiene alguna inquietud o pregunta. ${textBrand} no ofrece garantías o representaciones con respecto a ningún sitio web de terceros vinculado. ${textBrand} excluye toda responsabilidad por pérdidas o daños en la máxima medida permitida por la ley, derivados de cualquier contenido en dichos sitios web.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Para evitar dudas, la presencia de un enlace a un sitio web de terceros no se considerará una recomendación de esos sitios web o cualquier consejo o información contenida en ellos por parte de ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Política de Privacidad',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Todos los datos personales sobre usted están sujetos a nuestra Política de Privacidad.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>La Política de Privacidad se considera incorporada a los Términos de Uso por referencia a esta Cláusula.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Si algún usuario del sitio web, o cualquier otra persona, ve un documento publicado por ${textBrand} y responde con cualquier información o comentario, como preguntas, comentarios, sugerencias o similares, esta información se considerará no confidencial y ${textBrand} tiene la libertad de usar, reproducir, divulgar y distribuir esa información a cualquier otra persona.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Además, ${textBrand} tiene la libertad de usar o explotar cualquier idea, concepto, conocimiento técnico o técnica contenida en esa información/comentario para cualquier propósito, sin cargo alguno, incluyendo, pero sin limitarse a, desarrollar, fabricar y comercializar productos que incorporen esa información.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Propiedad Intelectual y Derechos de Autor',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Reconoces y aceptas que todos los derechos de autor, derechos de bases de datos y cualquier otro derecho de propiedad intelectual que subsista en el diseño, el diseño, los procesos, las funciones, los datos y el contenido del Sitio web son propiedad de ${textBrand}, nuestros proveedores de información o licenciantes. El Sitio web es propiedad y está operado por ${textBrand}. Ninguna parte del Sitio web, su contenido o cualquiera de sus programas y bases de datos subyacentes puede ser copiado o reproducido, compartido con terceros, ingeniería inversa, desarmado o utilizado para crear cualquier trabajo derivado (ya sea en forma física o electrónica) sin el previo consentimiento por escrito de ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Fuerza Mayor',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                No tendremos responsabilidad ante usted por ningún retraso o falla en el cumplimiento de nuestras obligaciones hacia usted por motivos fuera de nuestro control, incluidos, entre otros, actos de Dios, guerra o terrorismo, epidemia, pandemia, desastres naturales, problemas de conexión a Internet, problemas del sistema, cambios en las leyes, regulaciones o políticas gubernamentales aplicables, y escasez de suministros y servicios.
              </div>
          </div>
        `
    },
    {
      title: 'Terminación',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Podemos, en determinadas circunstancias y sin previo aviso, a nuestra entera discreción, dar por terminada su cuenta (si la hubiera). Las razones para dicha terminación incluirán, sin limitación:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Infracciones o violaciones de los Términos de Uso, u otros documentos incorporados, directrices o reglas;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Solicitud de fuerzas policiales u otras agencias gubernamentales;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Eliminación de cuenta iniciada por el propio usuario;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Infracción de los derechos de propiedad intelectual de terceros;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Interrupción o modificación sustancial de los servicios en el sitio web, o parte de ellos;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Problemas técnicos o de seguridad inesperados; o</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Períodos prolongados de inactividad.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  La terminación de su cuenta dará lugar a:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Eliminación del acceso y prohibición de un uso futuro de todas las ofertas del sitio web asociadas a dicha cuenta; y</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Eliminación de su contraseña y toda la información, archivos y contenido relacionados con su cuenta (o parte de ella).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>La terminación de su cuenta no afectará a ningún otro derecho o recurso del que podamos disponer en virtud de los Términos de Uso, la ley o la equidad, ni afectará a ningún derecho o responsabilidad acumulado ni a la entrada en vigor o continuación de cualquier disposición que esté expresamente o implícitamente destinada a entrar en vigor o continuar en vigor después de dicha terminación.</div>
              </div
          </div>
        `
    },
    {
      title: 'Derechos de Propiedad Intelectual',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Al cargar cualquier imagen, fotografía y/o descripción de listado en el Sitio web (el "<strong>Contenido</strong>"), usted nos otorga una licencia mundial, libre de regalías e irrevocable para utilizar, almacenar, alojar, reproducir, modificar, adaptar, agregar una marca de agua, comunicar, publicar, interpretar públicamente, mostrar públicamente, crear obras derivadas y distribuir el Contenido. Al cargar el Contenido, usted garantiza que posee los derechos de autor del Contenido y, por lo tanto, tiene los derechos necesarios para otorgarnos la licencia según se indica. Tendremos el derecho de agregar una marca de agua a las fotografías dentro del Contenido, y los derechos de autor en las fotografías finales con marca de agua pertenecerán exclusivamente a nosotros. Todos los demás nombres, productos y marcas mencionados son los derechos de propiedad intelectual de sus respectivos propietarios. Ningún material proporcionado a través del Sitio web, incluidos texto, gráficos, código, calificaciones, clasificaciones, bases de datos, información agregada, Material del Sitio web y/o software, puede ser reproducido, modificado, adaptado, distribuido, publicado, mostrado, transmitido o vinculado de ninguna manera y en ninguna forma sin nuestro expreso y previo consentimiento por escrito, y el consentimiento de los respectivos titulares de derechos de autor y marcas comerciales. El Sitio web y el Material del Sitio web son solo para su uso personal, y no puede realizar transacciones con otras partes con respecto a dichos servicios y contenidos. Dichas transacciones incluyen transacciones comerciales y transacciones que afectarán adversamente el valor comercial del Sitio web y el Material del Sitio web.</div>
              </div>
          </div>
        `
    },
    {
      title: 'General',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Los Términos de Uso se regirán e interpretarán de acuerdo con las leyes de la República de Singapur, y cualquier reclamo o disputa de cualquier naturaleza estará sujeto a la jurisdicción exclusiva de los tribunales de la República de Singapur.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Nuestra omisión o no ejercicio de cualquier derecho o disposición de los Términos de Uso no constituirá una renuncia a dicho derecho o disposición. Si alguna disposición de los Términos de Uso es considerada inválida por un tribunal de jurisdicción competente, las partes acuerdan que el tribunal deberá esforzarse por dar efecto a las intenciones de las partes reflejadas en la disposición, y las demás disposiciones de los Términos de Uso permanecerán en pleno vigor y efecto.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Cualquier cuenta que pueda tener con nosotros no es transferible y cualquier derecho sobre dicha cuenta terminará con su fallecimiento. Al recibir una copia de su certificado de defunción, su cuenta se cerrará y todo el contenido que haya generado en su cuenta se eliminará de forma permanente.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Todas las notificaciones para nosotros conforme a los Términos de Uso deben enviarse por correo electrónico a: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
