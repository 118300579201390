import {
  Divider,
  Stack,
  Box,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { i18n } from 'locales';
import { PageFooter } from 'components/layouts';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/buttons/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLayout } from 'contexts/layoutContext';
import { AccordionStyle } from 'pages/FAQ/FAQ';
import { ELanguageLocales, ELocales } from 'constants/currency';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { padZero } from 'utils/numberUtil';
import { TERM_VI } from 'locales/termOfUse/term-vi';
import { TERM_EN } from 'locales/termOfUse/term-en';
import { TERM_AR } from 'locales/termOfUse/term-ar';
import { TERM_DE } from 'locales/termOfUse/term-de';
import { TERM_EL } from 'locales/termOfUse/term-el';
import { TERM_ES } from 'locales/termOfUse/term-es';
import { TERM_FIL } from 'locales/termOfUse/term-fil';
import { TERM_FR } from 'locales/termOfUse/term-fr';
import { TERM_IN } from 'locales/termOfUse/term-in';
import { TERM_JA } from 'locales/termOfUse/term-ja';
import { TERM_KO } from 'locales/termOfUse/term-ko';
import { TERM_MS } from 'locales/termOfUse/term-ms';
import { TERM_NL } from 'locales/termOfUse/term-nl';
import { TERM_PL } from 'locales/termOfUse/term-pl';
import { TERM_PT } from 'locales/termOfUse/term-pt';
import { TERM_TH } from 'locales/termOfUse/term-th';
import { TERM_TL } from 'locales/termOfUse/term-tl';
import { TERM_ZH } from 'locales/termOfUse/term-zh';
import { useSelector } from 'react-redux';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';

export const handleFlowTerm = (textBrand: string) => {
  switch (i18n.language) {
    case ELanguageLocales.vi:
      return TERM_VI(textBrand!);

    case ELanguageLocales.en:
      return TERM_EN(textBrand!);

    case ELanguageLocales.ar:
      return TERM_AR(textBrand!);

    case ELanguageLocales.de:
      return TERM_DE(textBrand!);

    case ELanguageLocales.el:
      return TERM_EL(textBrand!);

    case ELanguageLocales.es:
      return TERM_ES(textBrand!);

    case ELanguageLocales.fil:
      return TERM_FIL(textBrand!);

    case ELanguageLocales.fr:
      return TERM_FR(textBrand!);

    case ELanguageLocales.in:
      return TERM_IN(textBrand!);

    case ELanguageLocales.ja:
      return TERM_JA(textBrand!);

    case ELanguageLocales.ko:
      return TERM_KO(textBrand!);

    case ELanguageLocales.ms:
      return TERM_MS(textBrand!);

    case ELanguageLocales.nl:
      return TERM_NL(textBrand!);

    case ELanguageLocales.pl:
      return TERM_PL(textBrand!);

    case ELanguageLocales.pt:
      return TERM_PT(textBrand!);

    case ELanguageLocales.th:
      return TERM_TH(textBrand!);

    case ELanguageLocales.tl:
      return TERM_TL(textBrand!);

    case ELanguageLocales.zh:
      return TERM_ZH(textBrand!);

    default:
      return TERM_EN(textBrand!);
  }
};

function TermsOfUser() {
  const { textContentBrand: { textBrand } } = useSelector(selectDesignHome);
  const [selected, setSelected] = useState<any>({});
  const [expanded, setExpanded] = useState<number>(-1);
  const history = useHistory();
  const { t } = useTranslation();
  const { paddingTopHeaderFixed, updateState } = useLayout();
  const [dataTermOfUser, setDataTermOfUser] = useState(handleFlowTerm(textBrand! || 'Levitate'));

  useEffect(() => {
    if (i18n.language === "vi") {
      setSelected({ id: 1, index: 1, ...dataTermOfUser[0] })
    } else {
      setSelected({ id: 1, index: 1, ...dataTermOfUser[0] })
    }
    updateState({ isFloating: true });
    window.scrollTo(0, 0);
  }, [])

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : -1);
  };

  return (
    <>
      <ContentLayout $paddingTopHeaderFixed={paddingTopHeaderFixed}>
        <Stack
          rowGap={{ lg: 0, xs: 4 }}
          flexDirection={{ lg: 'row', xs: 'column' }}
          columnGap={4}
        >
          <Box>
            <Button
              startIcon={<ArrowBackIcon />}
              color="transparent"
              onClick={() => history.goBack()}
              className='button-back'
            >
              {t('Back')}
            </Button>
          </Box>
          <Box>
            <Stack
              divider={<Divider orientation="vertical" flexItem />}
              columnGap={4}
              flexDirection={{ md: 'row', xs: 'column' }}
              rowGap={{ md: 0, xs: 4 }}
            >
              <Box className='column-left' >
                <h4 className='title'>{i18n.t('messages|TermsOfUse:Terms of use')}</h4>
                <Stack flexDirection="column" rowGap={2.5}>
                  {
                    i18n.language === 'vi' ?
                      dataTermOfUser?.map((item: { title: string, description: string }, index: number) => (
                        <Box key={item.title}>
                          <Box
                            component="div"
                            onClick={() => setSelected({ id: `${index + 1}`, ...item, index: index + 1 })}
                            className={`column-left__tab ${index + 1 === selected?.index ? 'active' : ''}`}
                            flex={1}
                            key={index}
                            display={{ md: 'flex', xs: 'none' }}
                          >
                            {index + 1}. {item.title}
                          </Box>
                          <Box display={{ md: 'none', xs: 'block' }}>
                            <AccordionStyle className='accodian' expanded={expanded === index} onChange={handleChange(index)}>
                              <AccordionSummary
                                expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Stack className='accodian-title' flexDirection="row">
                                  <Box className="accordian-number">
                                    {padZero(index + 1)}
                                  </Box>
                                  <Box ml={1} className='accodian-title-des'>
                                    {i18n.language === ELocales.en ? item?.title : item?.title}
                                  </Box>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography dangerouslySetInnerHTML={{ __html: item?.description }} className='accodian-content-des'></Typography>
                              </AccordionDetails>
                            </AccordionStyle>
                          </Box>
                        </Box>
                      )) :
                      dataTermOfUser?.map((item: { title: string, description: string }, index: number) => (
                        <Box key={item.title}>
                          <Box
                            component="div"
                            onClick={() => setSelected({ id: `${index + 1}`, ...item, index: index + 1 })}
                            className={`column-left__tab ${index + 1 === selected?.index ? 'active' : ''}`}
                            flex={1}
                            key={index}
                            display={{ md: 'flex', xs: 'none' }}
                          >
                            {index + 1}. {item?.title}
                          </Box>
                          <Box display={{ md: 'none', xs: 'block' }}>
                            <AccordionStyle className='accodian' expanded={expanded === index} onChange={handleChange(index)}>
                              <AccordionSummary
                                expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Stack className='accodian-title' flexDirection="row">
                                  <Box className="accordian-number">
                                    {padZero(index + 1)}
                                  </Box>
                                  <Box ml={1} className='accodian-title-des'>
                                    {item?.title}
                                  </Box>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography dangerouslySetInnerHTML={{ __html: item.description }} className='accodian-content-des'></Typography>
                              </AccordionDetails>
                            </AccordionStyle>
                          </Box>
                        </Box>
                      ))
                  }

                </Stack>
              </Box>
              <Box display={{ md: 'block', xs: 'none' }} className='column-right' flex={1}>
                <h4 className='title'>
                  {
                    `${selected?.index}. ${selected.title}`
                  }
                </h4>

                <div className="column-right__content" dangerouslySetInnerHTML={{ __html: selected?.description }}></div>
              </Box>
            </Stack>

          </Box>
        </Stack>

      </ContentLayout>
      <PageFooter />
    </>

  );
}

export default TermsOfUser;

const ContentLayout = styled.section<{ $paddingTopHeaderFixed?: number; }>`
  padding: 40px 150px;
  margin: ${props => props.$paddingTopHeaderFixed ? `${props.$paddingTopHeaderFixed}px auto 0` : "0 auto"};
  min-height: calc(100vh - 64px);
  
  @media (max-width: 1280px){
    padding: 40px 40px;
  }

  @media (max-width: 1024px){
    padding: 20px 20px;
  }

  .wrapper{
    margin-top: 0 !important;
  }

  .button-back{
    white-space: nowrap;
  }
  .column-left{
    padding-top: 0px !important;

    &__tab{
      cursor: pointer;
      font-size: 15px;
      min-width: 300px;
      max-width: 300px;
      font-weight: 400;

      &.active{
        color: ${p => p.theme.palette.accent.main};
        text-decoration: underline;
        font-weight: 500;
      }

      &:hover{
        text-decoration: underline;
        color: ${p => p.theme.palette.accent.main};
      }
    }

    .title{
      font-weight: 500;
      margin-bottom: 2rem;
      font-size: 25px;
    }
  }
  .column-right{
    padding-top: 0px !important;


    &__content{
      font-size: 15px;
      font-weight: 400;
      margin-left: 10px;
      a{
        color: ${p => p.theme.palette.accent.main};
        text-decoration: underline;

        &:hover{
          opacity: 0.8;
        }
      }
    }

    .title{
      font-weight: 500;
      margin-bottom: 2rem;
      font-size: 20px;
    }
  }
  .MuiAccordionDetails-root{
    padding: 10px 5rem 16px calc(-0.2rem + 32px) !important;
  }
 
  .MuiAccordionDetails-root p{
    line-height: 1.4 !important;
  }
  .button-back{
    height: 40px;
    font-size: 16px;

    @media (max-width: 768px){
      height: 50px;
    }
  }
`;