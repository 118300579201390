export const POLICY_PT = (textBrand: string) => {
  return [
    {
      title: 'Introdução',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>nós</strong>", "<strong>nós</strong>", "<strong>nosso</strong>" ou "<strong>${textBrand}</strong>"), é o operador do <a href="https://levitate.homes">https://levitate.homes</a> (o "<strong>Website</strong>"), e estamos comprometidos em proteger os seus Dados Pessoais (conforme definido na Cláusula 2 abaixo) de acordo com os termos e condições aqui estabelecidos.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Esta Política de Privacidade (em conjunto com os nossos Termos de Uso e quaisquer outros documentos a que se faça referência) estabelece como os Dados Pessoais que coletamos de você, ou que você nos fornece, serão utilizados, processados ou divulgados por nós, incluindo como determinadas informações serão utilizadas para personalizar a publicidade direcionada a você. Esta Política de Privacidade também descreve os tipos de Dados Pessoais que coletamos, como os mantemos seguros, bem como como você pode acessar ou alterar os Dados Pessoais armazenados por nós. Esta Política de Privacidade abrange o seu uso do Website e qualquer outra forma como você possa se conectar ou interagir conosco por meio do uso de nossos produtos e serviços (doravante referidos coletivamente como os "<strong>Serviços</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Leia atentamente o seguinte para entender nossa política e práticas em relação aos seus Dados Pessoais e como os trataremos.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>A menos que restrito por lei aplicável, ao utilizar o Website e os Serviços, você concorda que quaisquer e todos os Dados Pessoais relacionados a você coletados por nós ou fornecidos por você de tempos em tempos poderão ser utilizados, processados e divulgados para tais fins e para tais pessoas conforme estabelecido nesta Política de Privacidade.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Como parte de nossos esforços para garantir que gerenciamos, protegemos e processamos adequadamente seus Dados Pessoais, revisaremos periodicamente nossas políticas, procedimentos e processos. Podemos alterar esta Política de Privacidade a qualquer momento, publicando uma versão revisada no Website. Podemos nos esforçar para enviar um aviso quando a política for alterada, no entanto, você será considerado como tendo reconhecido e concordado com a Política de Privacidade alterada se continuar a utilizar o Website ou os Serviços após as alterações entrarem em vigor, independentemente de você receber o aviso ou não. Lembre-se de verificar esta página periodicamente para atualizações desta Política de Privacidade.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "Coleta de Dados Pessoais",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              Você pode optar por navegar pelo Website e usar suas funções limitadas sem divulgar seus Dados Pessoais. Você não é obrigado a fornecer Dados Pessoais como condição para usar o Website, exceto quando for necessário para que possamos fornecer os Serviços que você adquire ou acessa por meio do nosso Website, responder às suas solicitações ou nos casos em que você nos contata diretamente.
            </div>
            <div>
              Ao utilizar nossos Serviços, coletamos diversas informações sobre você, seus dispositivos e suas interações com os Serviços. Algumas dessas informações o identificam diretamente ou podem ser usadas para identificá-lo de forma genérica quando combinadas com outros dados. Qualquer dado, verdadeiro ou não, sobre um indivíduo que possa ser identificado a partir desses dados; ou a partir desses dados e outras informações às quais a organização tenha ou provavelmente terá acesso, é considerado dado pessoal ("<strong>Dados Pessoais</strong>").
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>Informações que você fornece voluntariamente</strong>. Ao utilizar nossos Serviços, podemos solicitar que você forneça informações pessoais sobre si mesmo, como, entre outras, seu nome, informações de contato, informações de pagamento, detalhes sobre sua casa ou propriedades de seu interesse, informações financeiras, suas preferências ou estilos. Isso pode ocorrer, por exemplo:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Quando você realiza transações conosco no Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Quando você cria uma conta conosco no Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Quando você entra em contato com nosso atendimento ao cliente ou vendedores por e-mail, telefone ou pessoalmente, para resolver qualquer problema que você possa enfrentar ou para fazer perguntas ou solicitações;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Quando você se inscreve para os serviços que oferecemos no Website ou para nossas notificações por e-mail e/ou boletins informativos;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Quando você fornece ou deixa informações relevantes, respostas ou dados no Website com o objetivo de usar os Serviços;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Quando você se corresponde com um profissional de imóveis (como um agente imobiliário ou corretor, credor hipotecário ou oficial de empréstimos, gerente de propriedade, investidor, construtor de imóveis ou outros) por meio dos Serviços; e</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Quando você preenche outros formulários ou realiza outras transações, como solicitação de informações de empréstimo, aplicação de verificação de antecedentes (se houver).</div>
                    </div>
                  </div>
                  <div>
                    Você também pode fornecer informações sobre terceiros por meio dos Serviços, por exemplo, se você compartilhar uma lista de imóveis com um destinatário por e-mail ou SMS. Ao fornecer essas informações a nós, você declara que obteve o consentimento da terceira parte para nos fornecer seus Dados Pessoais para os fins estabelecidos nesta Política de Privacidade.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Cookies, Pixels e outros mecanismos de rastreamento</strong>. Nós e nossos parceiros utilizamos diversas tecnologias para coletar informações automaticamente quando você acessa e usa nossos Serviços, incluindo cookies e outras tecnologias semelhantes. Os cookies são pequenos arquivos eletrônicos que podem ser transferidos para o seu computador ou outro dispositivo eletrônico para identificar exclusivamente seu navegador. Quando você usa os Serviços, nós e nossos parceiros podemos colocar um ou mais cookies em seu computador ou outro dispositivo eletrônico ou utilizar outras tecnologias que ofereçam funcionalidades semelhantes.
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Nós e nossos parceiros podemos usar cookies para vincular sua atividade nos Serviços com outras informações que armazenamos sobre você em seu perfil de conta ou suas interações anteriores com nossos Serviços, por exemplo, para armazenar suas preferências. O uso de cookies nos ajuda a melhorar a qualidade de nossos Serviços para você, identificando informações que são mais interessantes para você, rastreando tendências, medindo a eficácia da publicidade ou armazenando informações que você pode querer recuperar regularmente, como propriedades selecionadas ou buscas preferidas, além de realizar outras ações permitidas pela legislação aplicável. A qualquer momento, você pode ajustar as configurações do seu navegador para recusar cookies de acordo com as instruções relacionadas ao seu navegador. No entanto, se você optar por desativar os cookies, muitas das funcionalidades gratuitas dos Serviços podem não funcionar corretamente.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Algumas de nossas páginas também podem incluir pixels, que são arquivos eletrônicos para contar usuários que visitaram aquela página, rastrear atividades ao longo do tempo e em diferentes sites, determinar as interações dos usuários com os e-mails que enviamos, identificar determinados cookies no computador ou outro dispositivo eletrônico acessando aquela página ou coletar outras informações relacionadas, e essas informações podem ser associadas ao identificador exclusivo do seu navegador, identificador de dispositivo ou endereço IP. Por exemplo, podemos implementar um pixel em Serviços específicos, quando você visualiza uma determinada oferta, para que possamos rastrear se a oferta é relevante para você e oferecer recomendações. Através desses pixels e cookies, coletamos informações sobre o uso de nossos Serviços, incluindo o tipo de navegador que você usa, horários de acesso, páginas visualizadas, seu endereço IP e a página que você visitou antes de navegar para nossos Serviços.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Também coletamos informações sobre o computador ou dispositivo móvel que você usa para acessar nossos Serviços, como o modelo de hardware, sistema operacional e versão, identificadores únicos do dispositivo, informações da rede móvel e comportamento de navegação.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Também permitimos que certas terceiras partes coletem informações sobre suas atividades online por meio de cookies e outras tecnologias quando você usa o Website. Essas terceiras partes incluem (a) parceiros comerciais, que coletam informações quando você visualiza ou interage com um de seus anúncios no Website, e (b) redes de publicidade, que coletam informações sobre seus interesses quando você visualiza ou interage com um dos anúncios que eles colocam em muitos sites diferentes na Internet. As informações coletadas por essas terceiras partes são informações não identificáveis pessoalmente que são usadas para fazer previsões sobre suas características, interesses ou preferências e para exibir anúncios no Website e em toda a Internet que são adaptados aos seus interesses.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Você pode gerenciar o tipo de cookies permitidos através das configurações do seu navegador, incluindo bloquear completamente todos os cookies, se assim desejar. Para obter informações sobre como gerenciar cookies em seu navegador, consulte a seção de ajuda da própria ferramenta.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>Informações do dispositivo móvel e do navegador móvel.</strong> Você pode ajustar as configurações do seu dispositivo móvel e do navegador móvel em relação aos cookies e ao compartilhamento de certas informações, como o modelo do seu dispositivo móvel ou o idioma usado pelo dispositivo móvel, ajustando as configurações de privacidade e segurança em seu dispositivo móvel. Consulte as instruções fornecidas pelo seu provedor de serviços móveis ou fabricante de dispositivos móveis.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>Dados de Localização.</strong> Se você ativar os serviços de localização em seu dispositivo móvel, nós, com sua permissão, poderemos coletar a localização do seu dispositivo, que usamos para fornecer informações e publicidade baseadas em localização. Se você deseja desativar essa função, pode desativar os serviços de localização em seu dispositivo móvel.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                  <strong>Gerenciamento de E-mails.</strong> Você poderá receber e-mails de nossa parte por diversas razões - por exemplo, se você tomou uma ação por meio dos Serviços, se inscreveu para receber um relatório regular ou concluiu seu projeto, e nós lhe enviamos mensagens para acompanhar os próximos passos. Se você tiver uma conta conosco, poderá editar suas preferências por meio das configurações de sua conta. Além disso, você poderá gerenciar o recebimento de determinados tipos de comunicações, seguindo as instruções incluídas no e-mail que enviamos a você. Observe que, mesmo que você cancele a assinatura de determinadas correspondências por e-mail, ainda poderemos enviar-lhe e-mails com informações transacionais ou administrativas importantes.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>Aviso Legal.</strong> Não seremos responsáveis por qualquer divulgação voluntária de Dados Pessoais por você a outros usuários em conexão com o uso do Website, conforme o caso.
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: 'Propósito de Uso',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                  <div style="display: flex; gap: 0.3rem;">
                      <div>3.1</div>
                      <div>
                          Os Dados Pessoais que você fornece podem ser usados e processados por nós (ou por terceiros (como o Google Analytics) em nosso nome) em conjunto ou individualmente e podem ser combinados com outras informações para fins de gestão e operação do Website, cujo escopo pode incluir, mas não se limita ao seguinte:
                          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>a.</div>
                                  <div>Administrar o Website;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>b.</div>
                                  <div>Melhorar sua experiência de navegação personalizando o Website;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>c.</div>
                                  <div>Possibilitar o uso dos Serviços disponíveis no Website;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>d.</div>
                                  <div>Fornecer a você os Serviços que você solicitou especificamente;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>e.</div>
                                  <div>Responder às suas perguntas ou solicitações relacionadas aos seus e-mails e/ou envio de formulário(s) no Website;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>f.</div>
                                  <div>Responder e tomar medidas de acompanhamento em relação a reclamações sobre o uso do Website ou qualquer Serviço fornecido por nós;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>g.</div>
                                  <div>Enviar comunicações comerciais gerais por qualquer canal de comunicação, não se limitando a e-mail, chamada de voz, SMS e notificações de aplicativo;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>h.</div>
                                  <div>Realizar assuntos administrativos e operacionais do escritório;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>i.</div>
                                  <div>Fornecer informações estatísticas sobre nossos usuários a terceiros. Essas informações estatísticas podem ser usadas para personalizar a publicidade no Website, mas não podem ser usadas para identificar qualquer usuário individual;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>j.</div>
                                  <div>Criar e gerenciar sua conta online;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>k.</div>
                                  <div>Facilitar suas atividades e transações no Website;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>l.</div>
                                  <div>Facilitar sua participação em concursos ou promoções;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>m.</div>
                                  <div>Lidar com o pagamento ou reembolso para/vindo de você;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>n.</div>
                                  <div>Fornecer informações sobre os Serviços que você solicita de nós; ou, mediante seu consentimento prévio, as informações que consideramos que podem ser de seu interesse por e-mail ou mensagem de texto;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>o.</div>
                                  <div>Operar, avaliar e melhorar nossos negócios (incluindo desenvolver novos produtos e serviços; analisar e aprimorar nossos Serviços);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>p.</div>
                                  <div>Entender nossos clientes e realizar análise de dados e outros processamentos (incluindo pesquisas de mercado e consumidor, análise de tendências e anonimização);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>q.</div>
                                  <div>Responder aos seus comentários ou perguntas ou candidatura a emprego (se houver);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>r.</div>
                                  <div>Compartilhar com os provedores de serviços para que eles possam fornecer os Serviços a você; e conforme exigido ou permitido por lei.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  Você pode optar por navegar no Website e usar suas funções limitadas sem divulgar seus Dados Pessoais. Não é necessário fornecer Dados Pessoais como condição para usar o Website, exceto quando necessário para que possamos fornecer os Serviços que você compra ou acessa por meio de nosso Website, responder às suas solicitações ou em casos em que você nos contata diretamente.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.2. </div>
                  <div>Como os propósitos para os quais podemos/coletaremos, usaremos, divulgaremos ou processaremos seus Dados Pessoais dependem das circunstâncias específicas, esses propósitos podem não ser mencionados acima. No entanto, informaremos você sobre esses outros propósitos no momento de obter o seu consentimento, a menos que o processamento de seus Dados Pessoais sem o seu consentimento seja permitido pela Lei de Proteção de Dados Pessoais de 2012 de Singapura ("<strong>PDPA</strong>") ou por qualquer outra lei aplicável.
                  </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.3. </div>
                  <div><strong>Disposições de Não Chamar.</strong> Se você nos forneceu seu número de telefone(s) (os "<strong>Numbers</strong>") e indicou que consente em receber informações de marketing ou outras informações promocionais por meio dos seus Numbers, então, ocasionalmente, podemos entrar em contato com você usando esses Numbers com informações sobre nossos Serviços, mesmo que esses Numbers estejam registrados no Registro de Não Chamar (se houver, sujeito à sua jurisdição específica). No entanto, você pode nos informar por escrito caso não deseje ser contatado por nós em seus Numbers para tais fins.
                  </div>
              </div>
          </div>
      `
    },
    {
      title: 'Precisão e Segurança',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Você deve garantir que todos os Dados Pessoais enviados a nós sejam completos, precisos, verdadeiros e corretos. Sua falha em fazê-lo pode resultar na nossa incapacidade de fornecer os Serviços que você solicitou.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Tomaremos esforços razoáveis para garantir que seus Dados Pessoais sejam precisos e completos, caso seus Dados Pessoais sejam provavelmente utilizados por nós para tomar uma decisão que afete você ou sejam divulgados a outra organização. No entanto, isso significa que você também deve nos atualizar sobre quaisquer alterações nos seus Dados Pessoais que você inicialmente nos forneceu. Não seremos responsáveis por confiar em Dados Pessoais imprecisos ou incompletos que resultem de você não nos atualizar sobre quaisquer alterações nos seus Dados Pessoais que você inicialmente nos forneceu.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Tomaremos precauções técnicas e organizacionais razoáveis para evitar a perda, mau uso ou alteração dos seus Dados Pessoais.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Armazenaremos todos os Dados Pessoais que você fornecer em servidores seguros, em formato criptografado.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>No entanto, você compreende que a transmissão de informações pela Internet não é completamente segura. Embora façamos o nosso melhor para proteger seus Dados Pessoais, não podemos garantir a segurança dos seus Dados Pessoais transmitidos para o Website; qualquer transmissão é de sua responsabilidade. Além disso, não podemos assumir a responsabilidade por qualquer uso não autorizado dos seus Dados Pessoais por terceiros que seja totalmente atribuível a fatores além do nosso controle.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Se tivermos fornecido a você (ou se você escolheu) uma senha que permite o acesso a determinadas partes do Website, você é responsável por manter essa senha confidencial. Pedimos que você não compartilhe uma senha com ninguém. NUNCA pediremos sua senha, exceto quando você fizer login no Website, se for o caso.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Recomendamos que você tome medidas para manter suas informações pessoais seguras, como escolher uma senha forte e mantê-la em sigilo, além de fazer logout de sua conta de usuário e fechar o navegador da web quando terminar de usar nossos Serviços em um dispositivo compartilhado ou não seguro.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Divulgação de Dados Pessoais',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Não divulgaremos, sem o seu consentimento expresso, os seus Dados Pessoais a terceiros para fins de marketing direto. O consentimento para a divulgação de Dados Pessoais a terceiros, para fins de receber comunicações de marketing, é concedido através do nosso formulário de registro, seja online ou em papel.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Para gerir e operar o Website, podemos divulgar os seus Dados Pessoais a qualquer membro do nosso grupo, o que inclui nossas afiliadas e subsidiárias. Também podemos divulgar os seus Dados Pessoais a terceiros, como nossos prestadores de serviços e consultores profissionais, para os seguintes fins:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Para qualquer finalidade permitida por qualquer lei ou regulamento aplicável;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Para cumprir qualquer requisito legal;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Para fazer cumprir ou aplicar os nossos Termos de Uso;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Para proteger os nossos direitos, propriedade e segurança, assim como os direitos, propriedade e segurança de nossas afiliadas e subsidiárias, outros usuários do Website e outras pessoas;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>No âmbito de qualquer processo legal ou processo legal em perspectiva; e</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>No âmbito de uma transação de ativos empresariais (ou seja, a compra, venda, arrendamento, fusão ou fusão ou qualquer outra aquisição, alienação ou financiamento de uma organização ou parte de uma organização ou de qualquer dos negócios ou ativos de uma organização) na qual sejamos ou venhamos a ser parte ou parte interessada.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Também não divulgaremos os seus Dados Pessoais a terceiros sem obter previamente o seu consentimento para tal. No entanto, observe que podemos divulgar os seus Dados Pessoais a terceiros sem obter previamente o seu consentimento em determinadas situações, incluindo, entre outras, as seguintes:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Casos em que a divulgação é exigida ou autorizada com base nas leis e/ou regulamentos aplicáveis;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Casos em que o propósito dessa divulgação está claramente em seu interesse, e se o consentimento não puder ser obtido de forma oportuna;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Casos em que a divulgação é necessária para responder a qualquer emergência que ameace a vida, a saúde ou a segurança sua ou de outra pessoa;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Casos em que a divulgação é necessária para qualquer investigação ou processo judicial;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Casos em que os Dados Pessoais são divulgados a qualquer oficial de uma agência de aplicação da lei prescrita;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Casos em que a divulgação é para um órgão público e tal divulgação é necessária no interesse público; e/ou</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Onde tal divulgação sem o seu consentimento é permitida pelo PDPA ou por lei.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Quando divulgamos os seus Dados Pessoais a terceiros sem o seu consentimento, faremos o possível para exigir que esses terceiros protejam os seus Dados Pessoais.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Revogação do Consentimento',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Você pode revogar seu consentimento entrando em contato com o nosso serviço de atendimento ao cliente em <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Observe que, se você revogar seu consentimento para qualquer ou todo uso de seus Dados Pessoais, dependendo da natureza de sua solicitação, podemos não estar em posição de continuar a fornecer nossos Serviços a você. Mediante aviso seu, informaremos sobre as prováveis consequências da revogação do consentimento, incluindo, mas não se limitando, ao término de quaisquer acordos que você possa ter conosco e ao seu descumprimento de obrigações ou compromissos contratuais. Nossos direitos e recursos legais em tal evento são expressamente reservados.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Transferência Internacional de Dados',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Operamos em vários países e podemos armazenar, processar ou transferir temporariamente seus Dados Pessoais entre qualquer um dos países em que operamos, a fim de nos permitir usar seus Dados Pessoais de acordo com esta Política de Privacidade e nossos Termos de Uso.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Tomaremos todas as medidas razoavelmente necessárias para garantir que seus Dados Pessoais sejam transferidos de acordo com a legislação ou regulamentação aplicável e tomaremos todas as medidas razoáveis para garantir que qualquer organização estrangeira receptora de seus Dados Pessoais esteja vinculada por obrigações legalmente exigíveis para fornecer aos Dados Pessoais transferidos um padrão de proteção que seja pelo menos comparável à proteção nos termos do PDPA.</div>
              </div>
          </div>
      `
    },
    {
      "title": "Website de Terceiros",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  O Website pode, ocasionalmente, conter links para e de sites de nossas redes de parceiros e fornecedores. Se você seguir um link para qualquer um desses sites, observe que eles possuem suas próprias políticas de privacidade e que não aceitamos nenhuma responsabilidade ou responsabilidade por essas políticas. Verifique essas políticas antes de enviar quaisquer Dados Pessoais para esses sites.
                </div>
              </div>
            `
    },
    {
      "title": "Acesso, Exclusão e Correção",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>O nosso Encarregado de Proteção de Dados é responsável por garantir que os pedidos de acesso, exclusão ou alteração de Dados Pessoais sejam tratados adequadamente de acordo com os procedimentos aqui especificados.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>Todas as solicitações de informações, solicitações de alterações ou exclusão de dados pessoais ou exclusão de dados pessoais mantidos por nós serão feitas mediante envio de uma solicitação ao nosso Encarregado de Proteção de Dados no seguinte endereço de e-mail: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>Observe que só aceitaremos e agiremos em relação a solicitações no idioma inglês que estejam em conformidade com esta Política de Privacidade. Qualquer solicitação que não cumpra o acima será rejeitada, e não tomaremos nenhuma ação em relação a tais solicitações rejeitadas.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>Podemos recusar a divulgação, exclusão ou alteração de quaisquer Dados Pessoais pelos motivos estabelecidos no PDPA.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>Na medida do possível, responderemos à sua solicitação dentro de dez (10) dias úteis a partir da data de recebimento da solicitação. Se isso não for possível, você será notificado dentro de dez (10) dias úteis a partir da data de recebimento da solicitação.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Retenção dos seus Dados Pessoais",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Retemos os Dados Pessoais que coletamos de você quando temos uma necessidade comercial legítima contínua para fazê-lo. Se você é um cliente ou fornecedor (ou representante de um cliente ou fornecedor), as informações serão retidas por um período para permitir-nos fornecer nossos Serviços, cumprir requisitos legais, fiscais ou contábeis aplicáveis e, se necessário, para o estabelecimento, exercício ou defesa de reivindicações legais.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Quando não temos uma necessidade comercial legítima contínua para processar suas informações pessoais, as excluiremos ou as anonimizaremos, ou, se isso não for possível (por exemplo, porque suas informações pessoais foram armazenadas em arquivos de backup), então armazenaremos suas informações pessoais de forma segura e as isolaremos de qualquer processamento adicional até que a exclusão seja possível.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Entre em Contato Conosco",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      Se você precisar de mais informações sobre o tratamento de seus Dados Pessoais por nós, não hesite em entrar em contato com nosso Encarregado de Proteção de Dados em: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "Lei Aplicável",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  Esta Política de Privacidade será regida em todos os aspectos pelas leis de Singapura.
                  </div>
                </div>
              `
    }
  ]
};
