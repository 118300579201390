import BaseModel from './baseModel';

class Collection extends BaseModel<Collection> {
  id?: number;
  name?: string;
  tag?: string;
  isSignature?: boolean;
  code?: string;
  backgroundModuleImageUri?: string;
  thumbnailImageUri?: string;
  description?: string;
  descriptionTitle?: string;
  gallery?: string;

  // adapter process
  showMore?: boolean = false;

  fromPayload({
    id,
    name,
    tag,
    isSignature,
    code,
    backgroundModuleImageUri,
    thumbnailImageUri,
    description,
    descriptionTitle,
    gallery,
  }: Collection): this {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (tag !== undefined) this.tag = tag;
    if (descriptionTitle !== undefined)
      this.descriptionTitle = descriptionTitle;
    if (isSignature !== undefined) this.isSignature = isSignature;
    if (code !== undefined) this.code = code;
    if (backgroundModuleImageUri !== undefined)
      this.backgroundModuleImageUri = backgroundModuleImageUri;
    if (thumbnailImageUri !== undefined)
      this.thumbnailImageUri = thumbnailImageUri;
    if (description !== undefined) this.description = description;
    if (gallery !== undefined) this.gallery = gallery;

    return this;
  }
}

export default Collection;

export enum CollectionType{
  MyFavorites,
  MyLayout
}
