import React from 'react';
import BaseIcon from './BaseIcon';

const IcCheckedOutline = (props: any) => {
  return (
    <svg
      width="96"
      height="97"
      viewBox="0 0 96 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6448_72836)">
        <path
          d="M96 50.5482C95.9377 51.2096 95.8687 51.871 95.8165 52.5341C95.4058 57.7412 94.1416 62.7379 92.0695 67.526C89.3493 73.8102 85.4558 79.2731 80.4009 83.8895C73.9286 89.8001 66.3874 93.6676 57.8111 95.4801C53.4463 96.4024 49.0259 96.707 44.577 96.3637C34.6338 95.5979 25.7207 92.1596 17.9489 85.8906C9.69238 79.231 4.21157 70.7388 1.51494 60.4794C0.26088 55.7014 -0.200342 50.8326 0.0790845 45.8931C0.626155 36.2076 3.76887 27.4511 9.60485 19.7128C16.3582 10.7594 25.214 4.86563 36.0612 1.99953C39.108 1.19507 42.2153 0.723835 45.3681 0.582466C45.528 0.575734 45.6846 0.52861 45.8428 0.5C47.2804 0.5 48.7162 0.5 50.1537 0.5C50.8001 0.56227 51.4448 0.634638 52.0912 0.685127C55.8399 0.979647 59.5095 1.69491 63.0613 2.92348C76.568 7.5954 86.2503 16.4714 92.0779 29.5127C94.1988 34.2604 95.4007 39.2605 95.8148 44.4474C95.867 45.1105 95.936 45.7719 95.9983 46.4333V50.5465L96 50.5482ZM91.2009 48.5118C91.2093 24.6978 71.8481 5.31666 48.0328 5.29815C24.2209 5.27964 4.81083 24.6489 4.79736 48.4445C4.78389 72.2821 24.1586 91.68 47.9907 91.685C71.8009 91.6901 91.1925 72.3157 91.2009 48.5118Z"
          fill="#A885A2"
        />
        <path
          d="M39.4851 68.7647C33.3158 62.5949 27.1196 56.3965 20.9587 50.2335C22.0529 49.1395 23.1672 48.0271 24.3018 46.8928C29.3163 51.9047 34.3881 56.9755 39.4683 62.0513C50.243 51.2887 60.9774 40.5665 71.7084 29.8477C72.848 31.0123 73.9489 32.1399 75.0194 33.2355C63.2162 45.0365 51.3557 56.8947 39.4851 68.7647Z"
          fill="#A885A2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6448_72836">
          <rect
            width="96"
            height="96"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcCheckedOutline
