export const DATA_FAQ_MS = (textBrand: string) => {
  return [
    {
      title:
        `Siapa ${textBrand} International dan apa yang mereka tawarkan?`,
      description: `
            <p>Baiklah, mari kita berbicara tentang pasaran harta tanah utama dan pengalaman jualan yang membosankan dan ketinggalan zaman yang datang bersamanya. ${textBrand} International di sini untuk mengubah permainan. Kami di sini untuk menggoncangkan segalanya, kawan.</p>
            <p>Kami membangunkan rumah-rumah masa depan. Bagaimana kami melakukannya? Dengan memanfaatkan teknologi personalisasi. Ya, betul. ${textBrand} memperkenalkan alat reka bentuk dalaman 3D yang mengagumkan yang digerakkan oleh kecerdasan buatan. Ya, dengar betul. Alat hebat ini membolehkan anda menyusuri ruang tamu anda secara masa nyata, bilik demi bilik, dan membentuk rumah masa depan anda.</p>
            <p>Dengan ${textBrand}, anda akan memulakan perjalanan yang lancar, menyeronokkan, dan telus. Tiada lagi kejutan, tiada lagi kelewatan. Ia perjalanan yang lancar dan cekap menuju rumah impian anda.</p>
           `,
    },
    {
      title:
        'Apakah Segmen dan bagaimana ia berfungsi dalam membeli rumah baru?',
      description: `
            <p>Baiklah, kawan, mari kita berbicara tentang segmen dan bagaimana ia berfungsi dalam perjalanan pembelian rumah ${textBrand}.</p>
            <p>Segmen adalah seperti kepingan teka-teki yang boleh digabungkan untuk mencipta ruang yang memenuhi kehendak dan bajet anda.</p>
            <p>Bayangkan ia sebagai saiz kawasan tinggal yang berbeza yang boleh anda peroleh secara individu atau berganda, memperibadikan rumah baru anda untuk memenuhi setiap keperluan dan keinginan anda.</p>
           `,
    },
    {
      title:
        'Mengapa Perjanjian Tempahan begitu penting dalam proses pembelian?',
      description: `
            <p>Baiklah, mari kita berbicara tentang perkara yang dipanggil Perjanjian Tempahan ini. Anda mungkin berfikir, "Apa yang itu, dan mengapa saya perlu ambil peduli?" Nah, kawan, biar saya jelaskan. Perkara yang sangat penting dalam perjalanan liar membeli hartanah. Mengapa? Kerana ia seperti jaring keselamatan, kawan.</p>
            <p>Apabila anda menandatangani Perjanjian Tempahan, anda sebenarnya berkata, "Tunggu sekejap, saya mempunyai mata saya pada hartanah ini, dan saya mahu memastikan ia menjadi milik saya."</p>
            <p>Mengapa itu begitu penting? Mudah. Ia memberikan anda ketenangan fikiran dan melindungi kepentingan anda. Ia memastikan bahawa hartanah tidak akan dijual kepada orang lain ketika anda sibuk menyemak dan menandatangani perjanjian.</p>
           `,
    },
    {
      title:
        'Apakah yang termasuk dalam Perjanjian Jualan dan Pembelian dan apakah maknanya dalam dunia pembelian rumah?',
      description: `
            <p>Perjanjian Jualan dan Pembelian adalah seperti cawan suci bagi pembelian rumah. Ia adalah dokumen yang mengesahkan perjanjian dan melindungi diri anda dalam prosesnya.</p>
            <p>Biarkan saya terangkan: apabila anda membeli rumah, anda tidak sekadar memberikan wang anda dan berharap yang terbaik. Itulah di mana perkara ini datang. Ia adalah perjanjian sah yang mengikat antara anda dan penjual yang menetapkan semua perkara penting. Kami bercakap tentang harga rumah, syarat-syarat penyerahan, dan jadual masa untuk menutup perjanjian.</p>
            <p>Ini bukan perjanjian berjabat tangan yang santai, kawan. Ia adalah komitmen serius yang memastikan kedua-dua pihak bertanggungjawab. Jadi, apabila anda sedia untuk membuat pembelian besar ini, pastikan anda mempunyai Perjanjian Jualan & Pembelian yang kukuh. Ia adalah jaring keselamatan anda dalam dunia pembelian rumah yang liar.</p>
           `,

    },
    {
      title:
        `Bagaimana anda boleh mengubah impian pembelian rumah anda menjadi kenyataan melalui kuasa misteri ${textBrand} International?`,
      description: `
            <p>Jadi, anda ingin tahu bagaimana anda boleh menjadikan rumah impian anda kenyataan melalui ${textBrand} International? Nah, ikatlah tali pinggang anda, kawan, kerana saya akan menerangkannya untuk anda.</p>
            <p><strong>Langkah satu:</strong> pilih negara yang anda inginkan. Di mana anda melihat diri anda menjalani kehidupan terbaik anda? Pilihlah dan mari kita mula.</p>
            <p><strong>Langkah dua:</strong> kami mempunyai pelbagai projek yang disenaraikan, hanya menunggu anda untuk meneroka. Terjebaklah dalam butiran, bayangkan diri anda di ruang-ruang tersebut, dan bayangkan kehidupan yang akan anda jalani.</p>
            <p><strong>Langkah tiga:</strong> sekurangkan pilihan, kawan. Pilihlah jumlah segmen yang mampu anda beli dan tingkat yang menyentuh jiwa anda.</p>
            <p><strong>Langkah empat:</strong> Ini di mana ia menjadi menarik. Kini, bersedia untuk bahagian yang menakjubkan. Anda boleh pra-memodelkan apartmen anda dalam 3D yang menakjubkan secara langsung. Ya, kami bercakap tentang menjadikan rumah impian anda hidup di depan mata anda. Tetapkan ia, main-main dengannya, dan jadikan ia sempurna. Oh, dan adakah saya sebutkan anda akan mempunyai pandangan dan ketelusan penuh mengenai kos? Tiada urusan teduh di sini.</p>
            <p><strong>Langkah lima:</strong> Setelah anda berpuas hati dengan hasil karya anda, tiba masanya untuk mengesahkan perjanjian. Tandatangani perjanjian tempahan dan perjanjian pembelian dalam talian, dan voila!</p>
            <p>Sekarang, duduklah kawan dan biarkan ketegangan meningkat. Dalam masa yang tidak lama, anda akan bersantai di rumah yang selama ini anda fantasi tentangnya. ${textBrand} di sini untuk menjadikannya kenyataan.</p>
          `,
    },

    {

      title:
        'Bagaimana cara untuk menyertai senarai menunggu dan terus berada dalam lingkaran untuk hartanah yang akan dilancarkan tidak lama lagi?',
      description: `
            <p>Jadi anda bersemangat untuk mendapatkan hartanah "Segera Dilancarkan" yang sukar ditemui, betul? Saya tidak salahkan anda. Nah, inilah rahsia untuk tetap dikemaskini dan mendahului permainan.</p>
            <p>Pergilah ke laman web kami dan cari bahagian yang bertajuk "Dapatkan Maklumat Terkini". Ia seperti harta karun untuk pembeli rumah seperti anda. Cari pilihan untuk menyertai senarai menunggu. Masukkan alamat e-mel anda, dan anggap diri anda berada dalam lingkaran itu.</p>
            <p>Kami akan memastikan anda yang pertama mengetahui apabila projek-projek menarik itu bersedia untuk bergelar. Ia seperti mempunyai pas backstage ke dunia hartanah. Jadi jangan buang masa lagi, kawan. Sertai senarai menunggu dan biarkan ketegangan bermula.</p>
          `,
    },

    {
      title:
        `Bagaimana ${textBrand} International mengutamakan perlindungan data berharga anda, dan kemitraan apa yang telah mereka bentuk untuk memastikan transaksi yang selamat?`,
      description: `
            <p>Di ${textBrand} International, kami tidak main-main apabila ia berkaitan dengan perlindungan data. Maklumat anda adalah seperti emas bagi kami, dan kami menjaga ia dengan sebaik-baiknya. Kami tidak main-main dengan penyelesaian kelas kedua. Kami telah membentuk kemitraan dengan yang terbaik daripada yang terbaik untuk menjaga data anda dengan selamat dan cekap.</p>
            <p>Kami mempunyai Tazapay, syarikat yang berpangkalan di Singapura yang pakar dalam menjaga butiran pembayaran anda dengan selamat. Tetapi ia tidak berhenti di situ. Kami telah mengambil langkah lebih tinggi dengan teknologi inovatif Pandadoc untuk penandatanganan kontrak digital.</p>
            <p>Kami tidak tinggalkan sebarang batu yang tidak dipusingkan, kawan. Data anda adalah keutamaan kami yang utama, dan kami mempunyai kemitraan yang tepat untuk memastikan itu.</p>
          `,
    },
  ];
};
