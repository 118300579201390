import React from 'react';
import styled, { css } from 'styled-components';

// COMPONENTS
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button, { ButtonProps } from 'components/buttons/Button';
import IconButton, { IconButtonProps } from 'components/buttons/IconButton';

// ICONS
import CloseIcon from '@mui/icons-material/Close';

export type DialogButtonProps = ButtonProps & {
  render?: () => JSX.Element;
};

export type DialogProps = MuiDialogProps & {
  title?: string;
  buttons?: DialogButtonProps[];
  fullHeight?: boolean;
  onClose?: IconButtonProps['onClick'];
};

const Dialog = ({
  title,
  buttons,
  onClose,
  children,
  ...restProps
}: DialogProps) => {
  return (
    <MuiDialog onClose={onClose} {...restProps}>
      {title && (
        <DialogTitle>
          {title}
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>

      {buttons && (
        <DialogActions>
          <Grid container columnSpacing={1.5} justifyContent="flex-end">
            {buttons.map(({ render, ...buttonProps }, key) => (
              <Grid item xs="auto" key={key}>
                {render ? render() : <Button key={key} {...buttonProps} />}
              </Grid>
            ))}
          </Grid>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

const StyledDialog = styled(Dialog)`
  ${p => p.fullHeight && css`
    .MuiDialog-paper {
      height: 100%;
    }
  `}
  
  .MuiDialogContent-root {
    padding-top: 1rem !important;
  }

  .MuiDialog-paper {
    background-color: ${(p) => p.theme.palette.secondary.main};
    background-image: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .MuiDialogTitle-root {
    border-bottom: solid 1px ${(p) => p.theme.palette.primary.contrastText};
  }

  .MuiDialogActions-root {
    border-top: solid 1px ${(p) => p.theme.palette.primary.contrastText};
    padding: 1rem;

    .MuiButton-root {
      min-width: 10rem;
    }
  }
`;

export default StyledDialog;
