import { A_YEAR_TO_MONTH } from 'utils/numberUtil';

export enum MORTGAGE_TYPE {
  CAPITAL_INTEREST = 'CAPITAL_INTEREST',
  FLEXIBLE_SAVER = 'FLEXIBLE_SAVER',
  INTEREST_ONLY = 'INTEREST_ONLY'
}

const PREPAYMENT_FEE_RATIO = 0.02;

export const MORTGAGE_CHART_CURRENCY_EN_FORMAT = '$ #,###.##';
export const MORTGAGE_CHART_CURRENCY_VI_FORMAT = 'đ #,###.##';

export interface IMortgageDataItem {
  id: number,
  rates: number,
  maxTerm: number,
  bank: {
    name: string, logoUri: string, enable: boolean
  }
}

export interface IPaymentScheduleItem {
  id: number,
  name: string,
  amountRatio: number,
  useAmountFixed: boolean,
  dueDate: string,
  paymentMilestone: string,
  note: string,
  index: number,
  projectId: number,
  amountFixed: number
}

export class MortgageItem {
  public id: number;
  public bankName: string;
  public logo: string;
  public initialRate: number;
  public duration: number;
  public monthlyPayment: number;
  public averageAnnualCost: number;
  public maxTerm: number;
  public bankId: number;
  
  constructor({id, bankName, logo, rate, cost, deposit, duration, mortgageType, maxTerm, bankId, monthlyPayment} : {
    id: number, bankName: string, logo: string, rate: number, cost: number, deposit: number, duration: number, mortgageType: MORTGAGE_TYPE, maxTerm: number,
    bankId: number, monthlyPayment: number
  }) {
    this.id = id;
    this.bankName = bankName;
    this.logo = logo;
    this.initialRate = rate;
    this.duration = duration;
    this.maxTerm = maxTerm;
    this.monthlyPayment = monthlyPayment;
    this.averageAnnualCost = this.monthlyPayment * A_YEAR_TO_MONTH;
    this.bankId = bankId
  }
}

export class MortgageDetail {
  public cost: number;
  public fixedFor: number;
  public duration: number;
  public ltv: number;
  public prepayment: number;
  public currency: string = 'US$';
  public deposit: number;
  public amount: number;
  public mortgageType: MORTGAGE_TYPE;
  public rate: number;
  public bank: string;
  
  constructor(data: {
    cost: number, deposit: number, duration: number, mortgageType: MORTGAGE_TYPE, fixedFor: number, rate: number, bank: string
  }) {
    this.cost = data.cost;
    this.fixedFor = data.fixedFor;
    this.ltv = Math.round((data.cost - data.deposit) * 100.0 / data.cost);
    this.deposit = data.deposit;
    this.mortgageType = data.mortgageType;
    this.rate = data.rate;
    this.duration = data.duration;
    this.bank = data.bank;
    this.amount = data.cost - data.deposit;
    this.prepayment = this.amount * PREPAYMENT_FEE_RATIO;
  }
}
