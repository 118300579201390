import React from 'react';
import styled from 'styled-components';

// const getSize = (number: number) => `${number}px`
const getSizeV2 = (number: number, sizeem: number = 0, sizerem = 0) => {
  if (sizerem) return `${sizerem}rem`;
  if (sizeem) return `${sizeem}em`;
  return `${number}px`;
}

const getStrokeWidth = (size: number) => {
  if (size >= 32) {
    return 2
  }
  if (size >= 24) {
    return 1.5
  }
  return 1.5
}

type Props = {
  className?: string,
  color?: string,
  viewBox: string,
  ariaHidden?: string,
  ariaLabel?: string,
  children: any
};

const StyledSvg = ({
  color = "currentColor",
  children = undefined,
  ariaHidden = "",
  ariaLabel = "",
  ...props
}: Props) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    aria-hidden={ariaHidden ? "true" : undefined}
    aria-label={ariaLabel}
    color={color}
    {...props}
  >
    {children}
  </svg>
)


const StyledIcon = styled<any>(StyledSvg)`
  // @ts-ignore
  width: ${({ width, size, sizeem, sizerem }) => getSizeV2(width || size, sizeem, sizerem)};
  // @ts-ignore
  height: ${({ height, sizeem, sizerem }) => getSizeV2(height, sizeem, sizerem)};
  flex-shrink: 0;
  vertical-align: middle;
  fill: transparent;

  path {
    strokeWidth: ${({ size, strokeWidth}) => strokeWidth || getStrokeWidth(size)}px;
  }
`

export type BaseIconProps = {
  size: number,
  sizeem?: number,
  sizerem?: number,
  width: number,
  height: number,
  color: string,
  customColor?: string,
  className: string,
  children: React.ReactNode,
  viewBox: string,
  ariaLabel: string,
  strokeWidth?: number,
  onClick?: (...args: any) => void,
}

const BaseIcon = (props: BaseIconProps) => {
  const {
    size = 24,
    sizeem,
    sizerem,
    width,
    height,
    color,
    // customColor,
    className,
    children,
    viewBox,
    ariaLabel,
    strokeWidth,
    onClick
  } = props

  return (
    <StyledIcon
      viewBox={viewBox}
      size={size}
      sizeem={sizeem}
      sizerem={sizerem}
      width={width}
      height={height || size}
      color={color}
      className={className}
      // customColor={customColor}
      ariaLabel={ariaLabel}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      {children}
    </StyledIcon>
  )
}

export default BaseIcon
