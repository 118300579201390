export const POLICY_JA = (textBrand: string) => {
  return [
    {
      title: 'はじめに',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>私たち</strong>", "<strong>我々</strong>", "<strong>${textBrand}</strong>") は <a href="https://levitate.homes">https://levitate.homes</a> (以下、「ウェブサイト」といいます) の運営者であり、私たちはこの文書に記載された条件に従って、お客様の個人データ（以下、条項2で定義されます）の保護に取り組んでいます。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>このプライバシーポリシー（以下、「本ポリシー」といいます。 本ポリシーには、当社の利用規約およびそれに関連するその他の文書が含まれます）は、当社がお客様から収集する個人データ、またはお客様が当社に提供する個人データの利用、処理、または開示方法について記載しています。また、一部の情報を使用して個別にターゲティングされた広告をカスタマイズする方法についても述べています。本ポリシーには、当社が収集する個人データの種類、データを安全に保管する方法、およびお客様が当社が保管する個人データにアクセスまたは変更する方法も含まれています。本ポリシーは、お客様がウェブサイトを利用する場合、および当社の製品およびサービスを利用することで当社と連携または対話する場合（以下、総称して「サービス」といいます）に適用されます。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>お客様の個人データおよび当社がそれを取り扱う方法に関する当社の方針および実践を理解するため、以下を慎重にお読みください。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>適用される法律によって制約されることなく、ウェブサイトおよびサービスの利用により、当社がお客様に関連するすべての個人データを、本ポリシーで定める目的および者に対して使用、処理、および開示することに同意するものとします。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>当社がお客様の個人データを適切に管理、保護、および処理するための努力の一環として、定期的に当社のポリシー、手順、およびプロセスを再評価します。当社はウェブサイトに修正版を掲載することで、いつでも本ポリシーを変更することがあります。ポリシーが変更された場合、お知らせを送信するよう努めますが、お客様は、通知を受け取ったかどうかに関係なく、変更が適用された後もウェブサイトまたはサービスを使用し続けることにより、修正後のプライバシーポリシーを承認したものと見なされることに注意してください。本ポリシーの更新については、定期的にこのページを確認してください。</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      title: '個人情報の収集',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                ウェブサイトの閲覧やウェブサイトの機能のうち、ウェブサイトの制限付きの一部を開示せずに利用することができます。ウェブサイトの使用には、お客様の個人情報を提供する必要はありませんが、お客様が当社のウェブサイトを通じて購入またはアクセスしたサービスを提供するために必要な場合、またはお客様が直接当社に連絡を取る場合は、個人情報を提供する必要があります。
              </div>
              <div>
                当社のサービスを利用する際に、お客様、お客様のデバイス、お客様のサービスとのやり取りからさまざまな形式のデータを収集します。これらのデータの一部は直接的にお客様を識別するものであり、または他のデータと組み合わされてお客様を識別することができるものです。これには、当社または第三者が個人を特定することができる情報に関連するすべての情報が含まれます ("<strong>個人情報</strong>")。
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.1.</div>
                  <div>
                    <strong>お客様が意図的に提供する情報</strong> 当社のサービスを利用する際に、お客様にお客様自身に関連する個人情報を提供していただく場合があります。例えば、お客様の名前、連絡先情報、支払い情報、興味のある物件や資産に関する詳細情報、金融情報、お客様の趣味やスタイルなどの情報が含まれます。これらの情報は以下のような場面で発生する可能性があります：
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>当社のウェブサイト上で取引を行う場合;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>当社のウェブサイト上でアカウントを登録する場合;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>お客様が当社のカスタマーサービス担当者や営業担当者とメール、電話、または個人的な会合で問題の解決、質問またはリクエストを行う場合;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>当社のウェブサイトまたは当社の電子メール通知および/またはニュースレターに登録する場合;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>当社のサービスを利用するために、サービスへのアクセスに関連するデータを提供する場合;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>不動産関連サービスの提供者（不動産エージェントやブローカー、融資代理店または融資担当者、不動産マネージャー、投資家、建築業者、その他）と連絡を取る場合。</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>フォームを記入したり、他のトランザクションを行う場合、例えば、クレジットチェック要求、歴史検証アプリケーション（必要な場合）。</div>
                      </div>
                    </div>
                    <div>
                      お客様が当社のサービスを通じて第三者に関する情報を提供することがあります。例えば、お客様が当社のサービスを通じて物件リストを受信者と共有した場合、当該情報を提供する際にお客様が当該個人の個人情報を当社に提供する場合、お客様はその個人が本プライバシーポリシーに記載された目的のためにその個人の個人情報を提供していることを確認します。
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.2.</div>
                  <div>
                    <strong>Cookie、ピクセル、およびその他のトラッキングメカニズム</strong> 当社およびパートナーは、お客様が当社のサービスにアクセスし、利用する際に自動的にデータを収集するためにさまざまなテクノロジを使用します。これには、CookieやCookieと類似のテクノロジが含まれます。Cookieは、お客様が当社のサービスを使用する際にブラウザを識別する電子データの断片であり、他の電子デバイスに送信できます。当社とパートナーは、当社のサービスを利用する際にCookieや類似のテクノロジを配置するかもしれず、お客様のコンピュータまたはデバイス上でこれらと類似のテクノロジを使用するかもしれません。また、他の同様の機能を提供する別のテクノロジを使用する場合もあります。お客様が当社のサービスを使用する際、お客様のサービスとのやり取り、お客様が私たちに提供する情報に関連して、当社と当社のパートナーが自動的に収集するデータには以下が含まれます。
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>当社および当社のパートナーは、Cookieを使用してお客様の活動を当社のサービスの他の情報と関連付け、お客様のアカウントプロファイルまたは当社のサービス内の前回のやり取りを追跡したり、お客様が要求したデータを渡すときのお客様の前回の応答を追跡したり、お客様が定期的に戻ってくる可能性のあるデータを提供したりするためにCookieを使用するかもしれません。お客様が関心を持つリスト、トレンドを追跡し、広告の効果を測定したり、お客様が一定のアセットを選択または検索したことを追跡したり、お客様が繰り返し行う可能性のある他の操作を追跡したりする場合があります。これらのCookieと類似のトラッキングメカニズムを通じて、当社はお客様のサービス利用、アクセス時間、閲覧ページ、お客様のIPアドレス、および当社のサービスへのナビゲーションの前のページなどの情報を収集します。
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>当社のウェブサイトには、当社のサービスを向上させるためにピクセルが含まれることがあります。ピクセルは、特定のページを訪れたことや特定のトランザクションを行ったことをカウントするために、ウェブページに埋め込まれた電子ファイルです。お客様のコンピュータやデバイスでこれらの情報を確認できない場合がありますが、当社はピクセルを使用して当社のサービスを利用するお客様の活動を追跡し、当社のサービスの効果を測定します。また、当社のサービスで重要なリストの場合に限り、特定のページが当社のサービスで重要であることが確認される場合に限り、当社はサービス内で特定のページが表示されるタイミングを追跡するためにピクセルを使用する場合があります。
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>当社はまた、お客様が使用するデバイスに関するデータを収集します。これには、デバイスのハードウェアモデル、オペレーティングシステム、およびバージョン、ユーザーのデバイスの識別子、モバイルネットワークのデータ、およびウェブブラウザの一般的なデータとトラフィックパターンが含まれます。
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>また、一部のページは、特定のページのアクティビティを追跡するためにピクセルとCookieを含む可能性があります。これらのページは、特定の広告が効果的であるかどうかを理解し、お客様の広告に対する反応を測定するのに役立ちます。
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>当社のウェブサイトには、ウェブサイトに関連する情報の集計データを追跡するために使用されるCookieとCookieの類似したテクノロジが含まれている場合があります。これらのCookieとCookieの類似したテクノロジを使用して、当社はウェブサイトへのアクセスパターンを理解し、ウェブサイトの使用に関する情報を収集します。
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>当社のウェブサイト上で提供されるサービスや特定のページに特化した情報を提供するために、Cookieやピクセルを使用することがあります。
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>当社は、ビジネスパートナーや第三者のウェブサイトから情報を収集するために、Cookieやピクセルを使用することがあります。これには、当社のサービスにアクセスしたり、当社のサービスを使用したりすることが含まれます。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.3.</div>
                  <div>
                    <strong>モバイルデバイスおよびモバイルブラウザに関するデータ</strong> お客様は、モバイルデバイスおよびモバイルブラウザの設定をカスタマイズして、Cookieとデータ共有の要求を拒否することができます。ただし、Cookieを無効にすると、お客様がいくつかのウェブサイトの機能を制限することがあります。
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.4.</div>
                  <div>
                    <strong>アドレス情報</strong> お客様がモバイルデバイスで位置情報サービスを有効にした場合、当社は位置情報を収集することがあります。位置情報サービスを無効にする場合は、お客様のモバイルデバイスで位置情報サービスを無効にすることができます。
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.5.</div>
                  <div>
                    <strong>メールの管理</strong> お客様は、さまざまな理由で当社からのメールを受け取る場合があります。たとえば、お客様が当社のサービスを利用すると、定期的なレポートを登録したり、お客様が設計した場合など、当社はお客様にメッセージを送信します。また、当社と直接連絡を取る場合もあります。これらのメールまたはニュースレターのいずれかで当社からのメールを受信することは選択事項です。お客様が当社からのメールを受信しないようにするには、お客様のアカウントの設定を変更するか、当社から送信されたメール内の指示に従って解除することができます。
                  </div>
                </div>
              </div>
            </div>
        `
    },
    {
      title: '利用目的',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    提供された個人データは、当社（または当社を代表して（例：Google Analyticsなどの第三者））によって集計または個別に使用および処理され、他の情報と組み合わせることがあります。これらの目的はウェブサイトの管理と運営を目的とし、以下に限定されない範囲を含む場合があります：
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>ウェブサイトの管理;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>ウェブサイトのパーソナライズによる閲覧体験の向上;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>ウェブサイトで利用可能なサービスの有効化;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>特定のリクエストに応じてサービスを提供する;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>お問い合わせやウェブサイト上のフォームの提出に対する対応;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>ウェブサイトの利用または当社によって提供されるサービスに関する苦情の対応と追跡;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>電子メール、音声通話、SMS、アプリ通知など、あらゆる通信チャネルを介して一般的な商業的コミュニケーションの送信;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>オフィスおよび運用管理の業務の実行;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>ユーザーに関する統計情報を第三者に提供する。この統計情報はウェブサイト上の広告をパーソナライズするために使用されますが、個々のユーザーを特定することはできません;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>オンラインアカウントの作成と管理;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>ウェブサイトでの活動とトランザクションの支援;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>コンテストやプロモーションへの参加の支援;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>お支払いの取り扱い、返金への対応;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>お客様が当社に要請したサービスに関する情報の提供; またはあなたの事前の同意に基づいて、Eメールやテキストメッセージで興味を引く可能性がある情報を提供;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>当社のビジネスの運営、評価、および改善（新しい製品とサービスの開発; サービスの分析と強化）;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>お客様の理解とデータ分析およびその他の処理（市場および消費者リサーチ、トレンド分析、匿名化を含む）;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>コメント、質問、または雇用申請への対応;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>サービス提供者との共有、彼らがサービスを提供できるようにするため、および法律で要求または許可されているその他の目的に対して。</div>
                      </div>
                    </div>
                  </div>
                </div>
                個人データを開示せずにウェブサイトを閲覧し、その限られた機能を使用することを選択できます。ウェブサイトの利用にあたって個人データを提供することは、ウェブサイトを利用する条件では必要ありませんが、当社がウェブサイトを通じて提供するサービスを提供し、お客様のリクエストに応じ、または直接お問い合わせいただいた場合には必要な場合があります。
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>個人データを収集、利用、開示、または処理する目的は状況によって異なりますので、上記に表示されている目的に限られる場合があります。ただし、個人データの処理が個別の同意なしに行われることが個人データ保護法2012（シンガポール）（「PDPA」）またはその他の適用法によって許可されている場合を除き、その他の目的については同意を得る際に通知します。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>電話番号による営業連絡の制限に関する規定。</strong>お客様がお客様の電話番号（「番号」）を提供し、番号を介したマーケティングやその他のプロモーション情報の受信に同意した場合、当社は番号を使用して当社のサービスに関する情報を提供することがあります。これらの番号は無視登録済みであっても連絡する場合があります（所管管轄区域による）。ただし、上記の目的に対しては当社からの電話連絡を希望しない場合は書面で通知してください。</div>
              </div>
            </div>
          `
    },
    {
      title: '正確性とセキュリティ',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>提出されるすべての個人データが完全で、正確で、真実で、正確であることを確認してください。これを怠ると、ご要望に応じたサービスを提供できない場合があります。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>個人データが他の組織に開示されるか、当社によって利用される可能性がある場合、当社はあなたの個人データが正確かつ完全であることを合理的に確認します。ただし、これは、最初に提供した個人データの変更も当社に通知する必要があることを意味します。あなたが最初に提供した個人データの変更を当社に通知しないことによって生じる不正確または不完全な個人データについては、当社は責任を負いません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>個人データの紛失、誤用、または変更を防ぐために合理的な技術的および組織的な予防措置を講じます。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>提供されたすべての個人データを暗号化された形式で安全なサーバーに保存します。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>ただし、インターネットを介した情報の送信は完全に安全ではないことを理解してください。私たちはあなたの個人データを保護するため最善を尽くしますが、ウェブサイトに送信される個人データのセキュリティを保証することはできません。すべての送信はあなたの責任です。さらに、当社の管理範囲を超える要因によって完全に当社の管理範囲外の第三者によるあなたの個人データの不正使用については責任を負いません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>当社がウェブサイトの特定の部分にアクセスできるようにするパスワードを提供した場合（または選択した場合）、このパスワードの機密性を保持する責任はあなたにあります。パスワードを他の誰とも共有しないでください。ウェブサイトへのログイン時以外で、私たちは決してパスワードを要求しません。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>共有またはセキュリティの脆弱なデバイスで当社のサービスを使用した後は、強力なパスワードを選択し、秘密に保ち、ユーザーアカウントからログアウトし、ウェブブラウザを閉じるなど、個人情報の安全を確保するための手順を講じることをお勧めします。</div>
          </div>
        </div>
      `
    },
    {
      title: '個人情報の開示',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>直接的なマーケティング目的で第三者に個人情報を開示することは、あなたの明確な同意を得ることなしに行われることはありません。マーケティングコミュニケーションを受け取るための第三者への個人情報の開示についての同意は、当社の登録フォーム（オンラインまたは紙のいずれであるかにかかわらず）を通じて得られます。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              当サイトを管理および運営するために、お客様の個人情報を当社グループのメンバー（つまり、当社の関連会社および子会社）に開示する場合があります。また、当社は次の目的でサービス提供業者や専門アドバイザーなど、第三者に対してお客様の個人情報を開示することがあります：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>適用される法律または規制に基づく目的のために；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>法的要件を遵守するために；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>当社の利用規約を遵守または適用するために；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>当社、当社の関連会社および子会社、他のウェブサイトのユーザー、および他の個人の権利、財産、および安全性を保護するために；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>任意の法的手続きまたは予定されている法的手続きと関連して；および</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>ビジネスアセットのトランザクション（すなわち、組織または組織の一部または組織の任意の業務または資産の売買、貸与、合併または合併、または他の取得、譲渡または融資）に関連して、私たちが当事者または関係者の一つであるか、または当事者または関係者の一つになる可能性がある場合。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              当社は、あなたの同意なしに個人情報を第三者に開示することはありません。ただし、一部の状況においては、以下の場合に限り、あなたの同意なしに個人情報を第三者に開示する場合があります：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>適用される法律および/または規制に基づく場合；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>開示の目的が明らかにあなたの利益にかなっており、適切なタイミングで同意を得ることができない場合；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>命の危険、健康、または安全を脅かす緊急の状況に対応するために開示が必要な場合；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>調査または司法手続きに関連して開示が必要な場合；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>公式の執行機関の役員に対して個人情報を開示する場合；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>公共機関の目的のために開示が必要な場合；および/または</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>PDPAまたは法律によってこのような開示を許可される場合。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              あなたの同意なしに個人情報を第三者に開示する際には、その第三者にあなたの個人情報の保護を求めるために可能な限り努力します。
            </div>
          </div>
        </div>
      `
    },
    {
      title: '同意の取り消し',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>お客様は、弊社のカスタマーサービスに<a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a> で連絡することで、同意を取り消すことができます。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>個人データのいずれかまたはすべての利用に対する同意を取り消す場合は、お客様の要請の性質に応じて、弊社はサービスの提供を継続できない場合がありますので、ご留意ください。お客様の通知により、同意の取り消しの影響、契約の終了、契約上の義務違反などの可能性についてお知らせいたします。このような場合における当社の法的権利と救済措置は明示的に保留いたします。</div>
              </div>
          </div>
      `
    },
    {
      title: '国際データ転送',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>当社は多くの国で運営しており、当社が運営する国の間でお客様の個人データを一時的に保管、処理、または転送することがあります。これにより、本プライバシーポリシーおよび利用規約に従ってお客様の個人データを利用することができるようになります。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>当社は適用される法律または規制に従ってお客様の個人データが転送されることを保証するために必要なすべての合理的な措置を講じます。また、お客様の個人データの外国受信組織がPDPAの下での保護に少なくとも準拠する法的に強制力のある義務に拘束されるよう、すべての合理的な措置を講じます。</div>
              </div>
          </div>
      `
    },
    {
      "title": "第三者のウェブサイト",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  当ウェブサイトは、当社の提携ネットワークやベンダーのウェブサイトへのリンクを時折含む場合があります。これらのウェブサイトにリンクを辿る場合は、これらのウェブサイトには独自のプライバシーポリシーがあることをご留意ください。これらのポリシーについて、当社はいかなる責任も負いません。これらのウェブサイトに個人データを提出する前に、これらのポリシーをご確認ください。
                </div>
              </div>
            `
    },
    {
      "title": "アクセス、削除、および修正",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>当社のデータ保護責任者は、個人データのアクセス、削除、または修正に関する要求が適切に処理されるように責任を持っています。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>当社が保持する個人データに関する情報の提供、または個人データの修正または削除の要求は、次のメールアドレスにリクエストを提出することで行われます： <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>英語でこのプライバシーポリシーに準拠した要求のみを受け付け、対応します。上記に準拠しない要求は拒否され、拒否された要求に対しては何らの対応も行いませんのでご注意ください。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>PDPAに記載された理由により、個人データの開示、削除、または修正を拒否する場合があります。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>できる限り、リクエストに対する回答はリクエストの受領日から10営業日以内に行います。それが不可能な場合は、リクエストの受領日から10営業日以内に通知されます。</div>
                </div>
              </div>
            `
    },
    {
      "title": "個人データの保持",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>当社は、継続的な合法的なビジネス上の必要性がある場合には、お客様から収集した個人データを保持します。お客様がクライアントまたはベンダーである場合（またはクライアントまたはベンダーの代表である場合）、情報は当社のサービスを提供するために一定期間保持されます。これには、適用される法律、税金、または会計の要件を遵守するためだけでなく、必要に応じて法的な請求の確立、行使、または防御のためも含まれます。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>お客様の個人情報を処理する合法的なビジネス上の必要性がなくなった場合、個人情報を削除するか匿名化するか、または（バックアップアーカイブにお客様の個人情報が保存されている場合など）削除が不可能な場合は個人情報を安全に保管し、それ以降の処理から分離します。</div>
                </div>
              </div>
            `
    },
    {
      "title": "お問い合わせ",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      弊社によるお客様の個人データの取り扱いに関する詳細な情報が必要な場合は、遠慮なく次のデータ保護責任者にお問い合わせください： <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "適用法",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  このプライバシーポリシーは、シンガポールの法律によってすべての点で適用されるものとします。
                  </div>
                </div>
              `
    }
  ]
};
