import { useTheme } from '@mui/material';
import React from 'react';

const IcNavbar = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      {...props}
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69395 5.8335C4.47303 5.8335 4.29395 6.01258 4.29395 6.2335V7.76683C4.29395 7.98774 4.47303 8.16683 4.69394 8.16683H29.5522C29.7731 8.16683 29.9522 7.98774 29.9522 7.76683V6.2335C29.9522 6.01258 29.7731 5.8335 29.5522 5.8335H4.69395ZM4.29395 13.2335C4.29395 13.0126 4.47303 12.8335 4.69395 12.8335H29.5522C29.7731 12.8335 29.9522 13.0126 29.9522 13.2335V14.7668C29.9522 14.9877 29.7731 15.1668 29.5522 15.1668H4.69394C4.47303 15.1668 4.29395 14.9877 4.29395 14.7668V13.2335ZM4.29395 20.2335C4.29395 20.0126 4.47303 19.8335 4.69395 19.8335H29.5522C29.7731 19.8335 29.9522 20.0126 29.9522 20.2335V21.7668C29.9522 21.9877 29.7731 22.1668 29.5522 22.1668H4.69394C4.47303 22.1668 4.29395 21.9877 4.29395 21.7668V20.2335Z"
        fill={theme.palette.text.disabled || '#9B98A5'}
      />
    </svg>
  );
};

export default IcNavbar;
