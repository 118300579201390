export const POLICY_PL = (textBrand: string) => {
  return [
    {
      title: 'Wprowadzenie',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>my</strong>", "<strong>nas</strong>", "<strong>nasze</strong>" lub "<strong>${textBrand}</strong>"), jest operatorem strony <a href="https://levitate.homes">https://levitate.homes</a> (dalej "<strong>Strona internetowa</strong>"), i zobowiązujemy się do ochrony Twoich danych osobowych (zdefiniowanych w Klauzuli 2 poniżej) zgodnie z warunkami i postanowieniami tutaj.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Niniejsza Polityka Prywatności (wraz z naszymi Warunkami korzystania i innymi dokumentami, do których się odwołuje) określa, w jaki sposób dane osobowe, które zbieramy od Ciebie lub które nam dostarczasz, będą przez nas wykorzystywane, przetwarzane lub ujawniane, w tym jakie informacje będą wykorzystywane do personalizacji reklam skierowanych do Ciebie. Niniejsza Polityka Prywatności określa również rodzaje danych osobowych, które zbieramy, jak je zabezpieczamy, a także jak możesz uzyskać dostęp do swoich danych osobowych przechowywanych przez nas lub je zmienić. Niniejsza Polityka Prywatności dotyczy korzystania z Strony internetowej oraz wszelkiego innego sposobu łączności lub interakcji z nami poprzez korzystanie z naszych produktów i usług (dalej łącznie określanych jako "<strong>Usługi</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Prosimy o dokładne zapoznanie się z niniejszymi informacjami, aby zrozumieć nasze zasady i praktyki dotyczące Twoich danych osobowych oraz sposób, w jaki będziemy z nimi postępować.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>O ile nie ogranicza tego obowiązujące prawo, korzystając z Strony internetowej i Usług, zgadzasz się, że wszelkie dane osobowe dotyczące Ciebie, które są przez nas zbierane lub dostarczane przez Ciebie od czasu do czasu, mogą być wykorzystywane, przetwarzane i ujawniane na cele i dla osób określonych w niniejszej Polityce Prywatności.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>W ramach naszych wysiłków mających na celu właściwe zarządzanie, ochronę i przetwarzanie Twoich danych osobowych, będziemy okresowo przeglądać nasze zasady, procedury i procesy. Możemy w każdej chwili zmienić niniejszą Politykę Prywatności, publikując zaktualizowaną wersję na Stronie internetowej. Postaramy się wysłać powiadomienie o zmianie polityki, niemniej jednak uznasz za akceptację zmienionej Polityki Prywatności, jeśli nadal korzystasz ze Strony internetowej lub Usług po wejściu w życie zmian, niezależnie od tego, czy otrzymasz powiadomienie czy nie. Przypominamy, aby regularnie sprawdzać tę stronę w celu aktualizacji niniejszej Polityki Prywatności.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "Zbiór danych osobowych",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              Możesz wybrać przeglądanie Strony internetowej i korzystanie z jej ograniczonych funkcji bez ujawniania swoich danych osobowych. Nie jesteś zobowiązany do podawania danych osobowych jako warunek korzystania z Strony internetowej, chyba że jest to konieczne, abyśmy mogli świadczyć Usługi, które nabywasz lub uzyskujesz poprzez naszą Stronę internetową, odpowiadać na Twoje żądania lub w przypadkach, gdy skontaktujesz się z nami bezpośrednio.
            </div>
            <div>
              Korzystając z naszych Usług, zbieramy różne informacje o Tobie, Twoich urządzeniach i Twoich interakcjach z Usługami. Część tych informacji identyfikuje Cię bezpośrednio lub może być używana do Twojego luźnego zidentyfikowania w połączeniu z innymi danymi. Każde dane, prawdziwe czy nie, dotyczące osoby, która może być zidentyfikowana na podstawie tych danych; lub na podstawie tych danych i innych informacji, które organizacja ma lub prawdopodobnie będzie miała, uważane jest za dane osobowe ("<strong>Dane osobowe</strong>").
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>Informacje, które dobrowolnie podajesz</strong>. Korzystając z naszych Usług, możesz zostać poproszony o podanie danych osobowych na swój temat, takich jak, między innymi, Twoje imię, dane kontaktowe, informacje dotyczące płatności, szczegóły dotyczące Twojego domu lub nieruchomości, które Cię interesują, informacje finansowe, Twoje ulubione rzeczy lub style. Może to nastąpić, na przykład:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Gdy wykonujesz transakcje z nami na lub w związku z naszą Stroną internetową;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Gdy zakładasz konto u nas na Stronie internetowej;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Gdy kontaktujesz się z naszym działem obsługi klienta lub przedstawicielami handlowymi pocztą elektroniczną, telefonem lub osobiście, w celu rozwiązania ewentualnych problemów, zapytań lub żądań;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Gdy subskrybujesz usługi, które świadczymy na Stronie internetowej lub nasze powiadomienia e-mailowe i/lub biuletyny;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Gdy udostępniasz lub pozostawiasz odpowiednie dane wejściowe, odpowiedzi lub informacje na Stronie internetowej w celu korzystania z Usług;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Gdy korespondujesz z profesjonalistą w dziedzinie nieruchomości (takim jak agent lub pośrednik nieruchomościowy, pożyczkodawca hipoteczny lub specjalista ds. kredytów, zarządca nieruchomości, inwestor, deweloper nieruchomości lub inni) za pośrednictwem Usług;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Gdy wypełniasz inne formularze lub transakcje, takie jak wniosek o informacje o pożyczce, aplikacja sprawdzająca tło (jeśli dotyczy).</div>
                    </div>
                  </div>
                  <div>
                    Możesz również podać informacje dotyczące osób trzecich za pośrednictwem Usług, na przykład, jeśli udostępnisz dane dotyczące nieruchomości na sprzedaż odbiorcy za pośrednictwem poczty elektronicznej lub SMS. Podając takie informacje nam, oświadczasz, że uzyskałeś zgodę osoby trzeciej na przekazanie nam jej danych osobowych w celach określonych w Polityce prywatności.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Ciasteczka, Piksele i inne mechanizmy śledzenia</strong>. My i nasi partnerzy używamy różnych technologii do automatycznego zbierania informacji podczas korzystania z naszych Usług, w tym ciasteczek i innych podobnych technologii. Ciasteczka są elementami informacji elektronicznych, które mogą być przesyłane na twoje komputerowe urządzenie lub inne urządzenie elektroniczne w celu unikatowej identyfikacji twojej przeglądarki. Podczas korzystania z Usług, my i nasi partnerzy możemy umieścić jedno lub więcej ciasteczek na twoim komputerze lub innym urządzeniu elektronicznym lub używać innych technologii zapewniających podobne funkcje.
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>My i nasi partnerzy możemy używać ciasteczek do powiązania twojej aktywności na Usługach z innymi informacjami, które przechowujemy o tobie w profilu twojego konta lub twoich wcześniejszych interakcjach z naszymi Usługami, na przykład w celu przechowywania twoich preferencji. Używanie ciasteczek pomaga nam poprawić jakość naszych Usług dla ciebie, identyfikując informacje, które są dla ciebie najbardziej interesujące, śledząc trendy, mierząc skuteczność reklam, czy przechowując informacje, do których chcesz regularnie powracać, takie jak wybrane nieruchomości lub preferowane wyszukiwania, a także wykonując inne działania dozwolone przez obowiązujące prawo. W dowolnym momencie możesz dostosować ustawienia w swojej przeglądarce, aby odrzucić ciasteczka zgodnie z instrukcjami dotyczącymi twojej przeglądarki. Jednak jeśli wybierzesz wyłączenie ciasteczek, wiele bezpłatnych funkcji Usług może nie działać poprawnie.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Niektóre nasze strony mogą także zawierać piksele, które są plikami elektronicznymi służącymi do zliczania użytkowników, którzy odwiedzili daną stronę, śledzenia aktywności w czasie i na różnych stronach internetowych, ustalania interakcji użytkowników z e-mailami, które do nich wysyłamy, identyfikowania określonych ciasteczek na komputerze lub innym urządzeniu elektronicznym, które ma dostęp do tej strony, lub zbierania innych powiązanych informacji, a te informacje mogą być powiązane z unikalnym identyfikatorem twojej przeglądarki, identyfikatorem urządzenia lub adresem IP. Na przykład możemy wdrożyć piksele na konkretnych Usługach, gdy przeglądasz określoną ofertę, abyśmy mogli śledzić, czy oferta ta jest dla ciebie istotna i oferować rekomendacje. Dzięki tym pikselom i ciasteczkom zbieramy informacje na temat korzystania z naszych Usług, w tym typu przeglądarki, czasów dostępu, wyświetlanych stron, adresu IP i strony, którą odwiedziłeś przed nawigacją do naszych Usług.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Przechowujemy także informacje na temat komputera lub urządzenia mobilnego, które używasz do korzystania z naszych Usług, takie jak model sprzętu, system operacyjny i wersja, unikalne identyfikatory urządzeń, informacje o sieci komórkowej i zachowaniu przeglądania.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Przyjmujemy również, że określone podmioty trzecie będą zbierać informacje o twoich działaniach online za pomocą ciasteczek i innych technologii, gdy korzystasz z Strony internetowej. Do tych podmiotów trzecich należą (a) nasi partnerzy biznesowi, którzy zbierają informacje, gdy oglądasz lub współdziałasz z jednym z ich reklam na Stronie internetowej, oraz (b) sieci reklamowe, które zbierają informacje na temat twoich zainteresowań, gdy oglądasz lub współdziałasz z jedną z reklam, które umieszczają na wielu różnych stronach internetowych w Internecie. Informacje zebrane przez te podmioty trzecie są informacjami niezidentyfikowalnymi osobiście, które są używane do prognozowania twoich cech, zainteresowań lub preferencji oraz do wyświetlania reklam na Stronie internetowej i w Internecie dostosowanych do twoich zainteresowań.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Możesz zarządzać typem ciasteczek, które są dozwolone w ustawieniach przeglądarki, w tym całkowicie blokować wszystkie ciasteczka, jeśli tego chcesz. Aby uzyskać informacje na temat zarządzania ciasteczkami w przeglądarce, proszę zobacz sekcję pomocy przeglądarki, której używasz.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>Informacje na temat urządzenia mobilnego i przeglądarki mobilnej.</strong> Możesz zmieniać ustawienia swojego urządzenia mobilnego i przeglądarki mobilnej dotyczące ciasteczek i udostępniania pewnych informacji, takich jak model urządzenia mobilnego lub język używany przez urządzenie mobilne, poprzez zmianę ustawień prywatności i bezpieczeństwa na swoim urządzeniu mobilnym. Proszę odnieść się do instrukcji dostarczanych przez dostawcę usług mobilnych lub producenta urządzenia mobilnego.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>Dane lokalizacji.</strong> Jeśli włączysz usługi lokalizacji na urządzeniu mobilnym, my, za twoją zgodą, możemy zbierać dane lokalizacji twojego urządzenia, które wykorzystujemy do dostarczania informacji i reklam opartych na lokalizacji. Jeśli chcesz wyłączyć tę funkcję, możesz dezaktywować usługi lokalizacyjne na swoim urządzeniu mobilnym.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                 <strong>Zarządzanie Emailem.</strong> Możesz otrzymywać od nas wiadomości e-mail z różnych powodów - na przykład, jeśli podjąłeś działania za pośrednictwem Usług, zapisując się na regularny raport lub wypełniając swoje projekty, a my wysyłamy ci wiadomości w celu zapewnienia ci kolejnych kroków. Jeśli masz konto u nas, możesz edytować swoje preferencje za pośrednictwem ustawień swojego konta. Ponadto możesz zarządzać odbieraniem pewnych typów komunikacji, postępując zgodnie z instrukcjami zawartymi w otrzymanej wiadomości e-mail. Proszę pamiętać, że nawet jeśli zrezygnujesz z subskrypcji pewnych korespondencji e-mail, nadal możemy wysyłać ci e-maile z istotnymi informacjami transakcyjnymi lub administracyjnymi.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>Oświadczenie o wyłączeniu odpowiedzialności.</strong> Nie ponosimy odpowiedzialności za dobrowolne ujawnienie danych osobowych przez Ciebie innym użytkownikom w związku z korzystaniem z Strony internetowej, o ile dotyczy.
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: 'Cel wykorzystania danych',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                  <div style="display: flex; gap: 0.3rem;">
                      <div>3.1</div>
                      <div>
                          Dane osobowe, które nam przekazujesz, mogą być przez nas przetwarzane (lub przez podmioty trzecie (np. Google Analytics) w naszym imieniu) zbiorczo lub indywidualnie oraz mogą być łączone z innymi informacjami w celu zarządzania i obsługi strony internetowej, których zakres może obejmować, lecz nie ogranicza się do:
                          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>a.</div>
                                  <div>Administrowanie stroną internetową;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>b.</div>
                                  <div>Poprawianie Twojego doświadczenia przeglądania poprzez personalizację strony internetowej;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>c.</div>
                                  <div>Umożliwianie korzystania z dostępnych na stronie internetowej usług;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>d.</div>
                                  <div>Zapewnianie Ci usług, których konkretnie zażądałeś;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>e.</div>
                                  <div>Odpowiadanie na Twoje zapytania lub wnioski zgodnie z przesyłaniem e-maili i/lub przekazywaniem formularzy na stronie internetowej;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>f.</div>
                                  <div>Odpowiadanie na reklamacje dotyczące korzystania ze strony internetowej lub jakiejkolwiek usługi świadczonej przez nas i podejmowanie działań w związku z nimi;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>g.</div>
                                  <div>Wysyłanie ogólnych komunikatów handlowych za pomocą różnych kanałów komunikacji, w tym nie tylko e-maili, ale także połączeń głosowych, SMS-ów i powiadomień w aplikacji;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>h.</div>
                                  <div>Wykonywanie spraw administracyjnych związanych z działalnością biurową i operacyjną;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>i.</div>
                                  <div>Przekazywanie stronom trzecim informacji statystycznych dotyczących naszych użytkowników. Informacje statystyczne mogą być wykorzystywane do spersonalizowania reklam na stronie internetowej, ale nie mogą być wykorzystane do identyfikacji konkretnego użytkownika;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>j.</div>
                                  <div>Tworzenie i zarządzanie twoim kontem online;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>k.</div>
                                  <div>Ułatwianie twoich działań i transakcji na stronie internetowej;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>l.</div>
                                  <div>Ułatwianie twojego udziału w konkursach lub promocjach;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>m.</div>
                                  <div>Zajmowanie się płatnościami lub zwrotami środków od ciebie lub dla ciebie;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>n.</div>
                                  <div>Przekazywanie informacji o usługach, których od nas zażądałeś; lub zgodnie z twoją wcześniejszą zgodą, informacje, które mogą cię zainteresować, za pomocą e-maila lub wiadomości tekstowych;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>o.</div>
                                  <div>Działanie, ocena i doskonalenie naszej działalności (w tym opracowywanie nowych produktów i usług; analiza i ulepszanie naszych usług);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>p.</div>
                                  <div>Zrozumienie naszych klientów i przeprowadzanie analizy danych i innych procesów (w tym badania rynków i konsumentów, analiza trendów i anonimizacja);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>q.</div>
                                  <div>Odpowiadanie na twoje komentarze lub pytania lub aplikacje o pracę (jeśli takie występują);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>r.</div>
                                  <div>Udostępnianie dostawcom usług, dzięki czemu mogą one świadczyć usługi na twoją rzecz; i zgodnie z tym, co wymaga lub zezwala na to prawo.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  Możesz zdecydować się przeglądać stronę internetową i korzystać z jej ograniczonych funkcji bez ujawniania swoich danych osobowych. Nie jesteś zobowiązany do podawania danych osobowych jako warunku korzystania ze strony internetowej, chyba że jest to konieczne, abyśmy mogli świadczyć usługi, które zakupiłeś lub uzyskałeś za pośrednictwem naszej strony internetowej, odpowiadać na twoje żądania lub w przypadku, gdy skontaktujesz się z nami bezpośrednio.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.2. </div>
                  <div>Ponieważ cele, w jakich możemy/ będziemy zbierać, używać, ujawniać lub przetwarzać Twoje dane osobowe, zależą od okoliczności, cel taki może nie być wymieniony powyżej. Jednakże, powiadomimy Cię o innych celach w czasie uzyskiwania Twojej zgody, chyba że przetwarzanie Twoich danych osobowych bez Twojej zgody jest dozwolone zgodnie z Ustawą o Ochronie Danych Osobowych z 2012 roku w Singapurze ("<strong>PDPA</strong>") lub zgodnie z innym obowiązującym prawem.
                  </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.3. </div>
                  <div><strong>Postanowienia dotyczące rejestru DND (Nie Dzwonić).</strong> Jeśli podałeś nam swój numer telefonu (numery "<strong>Numbers</strong>") i wyraziłeś zgodę na otrzymywanie informacji marketingowych lub innych informacji promocyjnych za pośrednictwem tych numerów, to od czasu do czasu możemy skontaktować się z Tobą korzystając z tych numerów, aby przekazywać informacje dotyczące naszych usług, nawet jeśli numery te są zarejestrowane w Rejestrze DND (jeśli jest to stosowane w Twojej jurysdykcji). Możesz jednak poinformować nas pisemnie, jeśli nie chcesz, abyśmy kontaktowali się z Tobą pod tymi numerami w celach takich jak wyżej.
                  </div>
              </div>
          </div>
      `
    },
    {
      title: 'Dokładność i Bezpieczeństwo',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Należy upewnić się, że wszystkie dane osobowe przekazywane nam są kompletne, dokładne, prawdziwe i poprawne. Niepowodzenie w tym zakresie może skutkować naszą niemożnością świadczenia usług, o które prosiłeś/prosiłaś.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Podejmiemy odpowiednie działania, aby zapewnić, że Twoje dane osobowe są dokładne i kompletne, jeżeli istnieje prawdopodobieństwo, że dane te będą przez nas wykorzystane do podjęcia decyzji mającej wpływ na Ciebie lub ujawnione dla innej organizacji. Jednak oznacza to, że musisz również informować nas o wszelkich zmianach w Twoich danych osobowych, które początkowo nam udostępniłeś/podałaś. Nie będziemy ponosić odpowiedzialności za poleganie na niedokładnych lub niekompletnych danych osobowych, które wynikają z nieaktualizowania przez Ciebie zmian w danych osobowych, które początkowo nam udostępniłeś/podałaś.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Podejmiemy rozsądne środki techniczne i organizacyjne, aby zapobiec utracie, nadużyciu lub zmianie Twoich danych osobowych.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Przechowywać będziemy wszystkie dane osobowe, które nam dostarczysz, na bezpiecznych serwerach w formacie zaszyfrowanym.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Mimo to, zdajesz sobie sprawę, że transmisja informacji przez Internet nie jest całkowicie bezpieczna. Chociaż będziemy robić co w naszej mocy, aby chronić Twoje dane osobowe, nie możemy zagwarantować bezpieczeństwa transmisji Twoich danych osobowych do strony internetowej; każda transmisja odbywa się na Twoje ryzyko. Ponadto nie możemy ponosić odpowiedzialności za nieuprawnione wykorzystanie Twoich danych osobowych przez osoby trzecie, które w całości wynika z czynników poza naszą kontrolą.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Gdzie daliśmy ci (lub gdzie wybrałeś/wybrałaś) hasło umożliwiające dostęp do określonych części strony internetowej, masz obowiązek zachować to hasło w poufności. Prosimy, nie udostępniaj hasła nikomu. NIGDY nie poprosimy cię o podanie hasła, chyba że logujesz się na stronie internetowej.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Zalecamy podjęcie kroków w celu zabezpieczenia twoich danych osobowych, takich jak wybranie mocnego hasła i zachowanie go w poufności, a także wylogowanie się z konta użytkownika i zamknięcie przeglądarki internetowej po zakończeniu korzystania z naszych usług na współdzielonym lub niezabezpieczonym urządzeniu.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Ujawnienie danych osobowych',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Nie ujawnimy, bez Państwa wyraźnej zgody, Państwa danych osobowych podmiotom trzecim w celach marketingu bezpośredniego. Zgoda na ujawnienie danych osobowych podmiotom trzecim, w celu otrzymywania informacji marketingowych, jest udzielana za pośrednictwem naszego formularza rejestracyjnego, zarówno w formie online, jak i papierowej.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              W celu zarządzania i działania Strony internetowej, możemy ujawniać Państwa dane osobowe każdemu członkowi naszej grupy, co oznacza naszym spółkom zależnym i filiom. Możemy również ujawniać Państwa dane osobowe podmiotom trzecim, takim jak dostawcy usług i doradcy zawodowi, w celu realizacji następujących celów:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>W celu spełnienia każdego celu dozwolonego przez obowiązujące prawo lub przepisy;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>W celu spełnienia wymogów prawnych;  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>W celu egzekwowania lub stosowania naszych Warunków korzystania; </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>W celu ochrony naszych praw, własności i bezpieczeństwa, a także praw, własności i bezpieczeństwa naszych spółek zależnych i filii, innych użytkowników Strony internetowej i innych osób;  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>W związku z jakimikolwiek postępowaniami prawny                  </div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>f.</div>
                    <div>W związku z transakcją dotyczącą aktywów przedsiębiorstwa (tj. zakupem, sprzedażą, dzierżawą, połączeniem lub połączeniem lub jakąkolwiek inną akwizycją, zbyciem lub finansowaniem przedsiębiorstwa lub części przedsiębiorstwa lub jakiejkolwiek z działalności lub aktywów przedsiębiorstwa), w której jesteśmy stroną lub stroną przyszłą.</div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.3.</div>
                <div>
                  Nie ujawnimy również Państwa danych osobowych podmiotom trzecim, jeśli wcześniej nie uzyskamy Państwa zgody na takie działanie. Niemniej jednak, prosimy zauważyć, że w pewnych sytuacjach możemy ujawnić Państwa dane osobowe podmiotom trzecim bez uzyskania wcześniejszej zgody, w tym między innymi w następujących przypadkach:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>W przypadkach, gdy ujawnienie jest wymagane lub upoważnione na podstawie obowiązujących przepisów prawnych i/lub regulacji; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>W przypadkach, gdy cel takiego ujawnienia jednoznacznie służy Państwa interesom, a zgody nie można uzyskać w odpowiednim czasie; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>W przypadkach, gdy ujawnienie jest konieczne do reagowania na sytuację awaryjną zagrażającą życiu, zdrowiu lub bezpieczeństwu Państwa lub innej osoby; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>W przypadkach, gdy ujawnienie jest konieczne w związku z jakimikolwiek postępowaniami śledczymi lub sądowymi; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>W przypadkach, gdy dane osobowe są ujawnione jakimkolwiek funkcjonariuszom upoważnionej agencji egzekwującej prawo; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>W przypadkach, gdy ujawnienie jest konieczne dla organu publicznego i takie ujawnienie jest niezbędne w interesie publicznym; oraz/lub</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Gdy ujawnienie takie bez Państwa zgody jest dozwolone przez PDPA lub prawo.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.4.</div>
                <div>
                  Gdy ujawniamy Państwa dane osobowe podmiotom trzecim bez Państwa zgody, podejmiemy wszelkie możliwe środki, aby wymagać od tych podmiotów trzecich ochrony Państwa danych osobowych.
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Wycofanie Zgody',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Możesz wycofać swoją zgodę, kontaktując się z naszym działem obsługi klienta pod adresem <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Należy pamiętać, że jeśli wycofasz swoją zgodę na dowolne lub wszystkie wykorzystania Twoich Danych Osobowych, w zależności od charakteru Twojego żądania, możemy nie być w stanie kontynuować świadczenia naszych Usług. Po otrzymaniu informacji od Ciebie poinformujemy Cię o prawdopodobnych konsekwencjach wycofania zgody, w tym, ale nie tylko, o rozwiązaniu wszelkich umów, które możesz zawrzeć z nami, oraz naruszeniu Twoich zobowiązań umownych lub świadczeń. Nasze prawne prawa i środki prawne w takim przypadku są wyraźnie zastrzeżone.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Transfer Międzynarodowych Danych',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Działamy w wielu krajach i możemy tymczasowo przechowywać, przetwarzać lub przekazywać Twoje Dane Osobowe między dowolnymi krajami, w których działamy, aby umożliwić nam korzystanie z Twoich Danych Osobowych zgodnie z niniejszą Polityką Prywatności i naszymi Warunkami Korzystania.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div podejmiemy wszelkie rozsądne kroki, aby zapewnić, że Twoje Dane Osobowe są przekazywane zgodnie z obowiązującym prawem lub regulacją, oraz podejmiemy wszystkie rozsądne kroki, aby zapewnić, że każda zagraniczna organizacja odbiorcza Twoich Danych Osobowych jest związana z prawnie wiążącymi zobowiązaniami zapewniającymi ochronę przekazywanych Danych Osobowych, co najmniej porównywalną z ochroną przewidzianą w PDPA.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Strona trzecia',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Strona internetowa może czasami zawierać odnośniki do witryn naszych partnerów i dostawców. Jeśli klikniesz na link do którejkolwiek z tych stron, zauważ, że posiadają one swoje własne polityki prywatności i nie ponosimy odpowiedzialności za te polityki. Sprawdź te polityki przed przekazaniem jakichkolwiek danych osobowych na tych stronach.
              </div>
            </div>
          `
    },
    {
      title: 'Dostęp, Usuwanie i Poprawa',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>9.1.</div>
                <div>Nasz Inspektor Ochrony Danych odpowiada za właściwe załatwienie żądań dotyczących dostępu, usunięcia lub poprawy danych osobowych zgodnie z określonymi procedurami.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.2.</div>
                <div>Wszystkie żądania informacji, zmiany lub usunięcia danych osobowych przechowywanych przez nas zostaną zrealizowane poprzez przesłanie żądania do naszego Inspektora Ochrony Danych pod adresem: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.3.</div>
                <div>Pamiętaj, że akceptujemy i rozpatrujemy jedynie żądania w języku angielskim, które są zgodne z niniejszą Polityką Prywatności. Wszelkie żądania, które nie spełniają powyższych warunków, zostaną odrzucone, i nie podejmiemy żadnych działań związanych z odrzuconymi żądaniami.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.4.</div>
                <div>Możemy odmówić ujawnienia, usunięcia lub zmiany danych osobowych z przyczyn wymienionych w PDPA.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.5.</div>
                <div>Zgodnie z możliwościami, odpowiemy na Twoje żądanie w ciągu dziesięciu (10) dni roboczych od daty otrzymania żądania. Jeśli to nie będzie możliwe, zostaniesz o tym powiadomiony w ciągu dziesięciu (10) dni roboczych od daty otrzymania żądania.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Zachowanie Twoich danych osobowych',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>Przechowujemy dane osobowe, które zbieramy od Ciebie, jeśli istnieje nasze uzasadnione uzasadnione potrzeby biznesowe do tego. Jeśli jesteś klientem lub dostawcą (lub przedstawicielem klienta lub dostawcy), informacje będą przechowywane przez okres umożliwiający nam świadczenie naszych usług, spełnienie obowiązujących wymogów prawnych, podatkowych lub rachunkowych oraz, jeśli to konieczne, w celu ustalenia, wykonania lub obrony roszczeń prawnych.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>Gdy nie ma naszych uzasadnionych potrzeb biznesowych do przetwarzania Twoich danych osobowych, usuniemy lub zanonimizujemy je, lub jeśli to niemożliwe (na przykład ze względu na przechowywanie Twoich danych osobowych w archiwach kopii zapasowych), będziemy przechowywać Twoje dane osobowe w sposób bezpieczny i izolować je od dalszego przetwarzania do czasu możliwości ich usunięcia.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Skontaktuj się z nami',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>
                    Jeśli potrzebujesz dodatkowych informacji na temat przetwarzania Twoich danych osobowych przez nas, nie wahaj się skontaktować z naszym Inspektorem Ochrony Danych pod adresem: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                  </div>
                </div>
              </div>
            `
    },
    {
      title: 'Prawo właściwe',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                Niniejsza Polityka Prywatności podlega w całości prawu Singapuru.
                </div>
              </div>
            `
    }
  ]
};
