import { Obj, SelectOption } from 'constants/types';
import { MetaParams } from 'constants/requestConstants';
import { AxiosRequestConfig } from 'axios';
import Project from 'model/project';
import { ECurrency } from 'constants/currency';
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';

declare const window: any;

type ToOptionsFunc = (
  items: Obj[],
  keys?: { labelKey?: string; valueKey?: string; setData?: boolean }
) => SelectOption[];

const DEFAULT_LABEL_KEY = 'name';
const DEFAULT_VALUE_KEY = 'id';

export const toOptions: ToOptionsFunc = (items, keys) =>
  items.map(item => ({
    label: item[keys?.labelKey || DEFAULT_LABEL_KEY],
    value: item[keys?.valueKey || DEFAULT_VALUE_KEY],
    data: keys?.setData ? item : undefined,
  }));

export const arrayParamToString = (arr: (number | string)[] | undefined) =>
  (arr || []).length > 0 ? (arr || []).join(',') : undefined;

export const parseMetaParams = (meta: MetaParams | undefined) => {
  const { page, size } = meta || {};
  
  return {
    page,
    size: size || 1000,
  }
};

export const parseRequestParams = (dataParams?: Obj, metaParams?: MetaParams, fetchMultiple = false): AxiosRequestConfig => {
  const configs: AxiosRequestConfig = { params: {}, options: {} };
  
  // Set query params
  if (dataParams) {
    Object.keys(dataParams).forEach(field => {
      const value = dataParams[field];
      if (value == null) return;
      
      if (Array.isArray(value)) {
        configs.params[field] = arrayParamToString(value);
      } else {
        configs.params[field] = value;
      }
    });
  }
  
  // Set meta params
  if (fetchMultiple) {
    const { page, size } = metaParams || {};

    if (size) {
      configs.params.size = size;
      configs.params.page = page;
    } else {
      configs.params.size = 1000;
    }
  }
  
  // Additional functions
  if (metaParams) {
    const { newAbortSignal } = metaParams

    if (newAbortSignal) configs.options!.newAbortSignal = newAbortSignal;
  }
  
  return configs;
}

export const buildSlider = (
  videoUrl: string,
  photos: Project['galleryPhotos'] = []
) => {
  const imageUrls = photos.map(({ photoUri }) => {
    return { mediaUrl: photoUri };
  });
  if (videoUrl) {
    imageUrls.unshift({ mediaUrl: videoUrl });
  }
  return imageUrls;
};

export const buildSliderThumbnail = (
  videoUrl: string,
  photos: { thumbnailUri: string }[] = []
) => {
  const imageUrls = photos.map(({ thumbnailUri }) => {
    return { mediaUrl: thumbnailUri };
  });
  if (videoUrl) {
    imageUrls.unshift({ mediaUrl: videoUrl });
  }
  return imageUrls;
};


export const formatCurrency = (maxBudget: number) => {
  return maxBudget * getStorage(StorageKey.ConversionRate);
};
