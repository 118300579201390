import { ReactNode } from 'react';

// COMPONENTS
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import React from 'react';

type Props = MuiAlertProps & {
  message?: ReactNode;
  width?: number | string;
};

const Alert = ({ message, width = '100%', children, ...props }: Props) => {
  if (!message && !children) return null;

  return (
    <Stack spacing={2} sx={{ width }}>
      <MuiAlert {...props}>{message || children}</MuiAlert>
    </Stack>
  );
};

export default Alert;
