import { get } from 'lodash';

export const bindValueToText = (sourceStr = '', replaceValueMap: {[key in string]: string | number | boolean} = {}) => {
  const keys = sourceStr.match(/\{\#(.*?)\#\}/g);
  if (keys) {
    keys.forEach((key) => {
      sourceStr = sourceStr.replace(key, `${get(replaceValueMap, key.split("#")[1])}`);
    })
  }
  return sourceStr;
}

export const toJSON = (stringJson: string | null, defaultValue = {}) => {
  if (stringJson === null) {
    return defaultValue;
  }
  try {
    return JSON.parse(stringJson);
  } catch (e) {
    return defaultValue;
  }
}

export const generateDomUniqueId = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}
