import { pmsCustomerRequest, pmsPublicRequest, userPublicRequest } from 'apis/requests';
import { ECurrency } from 'constants/currency';
import { ProductItemParams } from 'constants/designConstants';
import { FilterRoomType } from 'constants/homeConstants';
import {
  BasicResponsePayload, MetaParams
} from 'constants/requestConstants';
import { SessionKey, StorageKey } from 'constants/storage';
import { ID, Obj } from 'constants/types';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import ChoosePlaceholder from 'model/choosePlaceholder.model';
import { getSession, getStorage, hasStorage } from 'services/storageService';
import { formatCurrency, parseRequestParams } from 'utils/dataMappers';

export type FetchProjectsParams = {
  locationId?: ID;
  noOfBedrooms?: FilterRoomType | FilterRoomType[];
  minBudget?: number;
  maxBudget?: number;
  currencyCode?: ECurrency;
  viewRating?: number;
  projectId?: number;
  blockId?: number | string;
  floorId?: number | string;
  floorPlanId?: number | string;
  planId?: number | string;
  segmentId?: number | string;
  layoutId?: number | string;
  productItems?: ProductItemParams[];
  type?: string;
  personalityProperties?: number;
  personalityId?: number;
  defaultType?: 'block' | 'floor';
  buyPurpose?: string
  personalized?: boolean,
  isRemodel?: boolean
};

export type FetchVerifyProductChooseParams = {
  layoutIdChange?: ID;
  productListId?: number;
  personalityProperties?: number;
  choosePlaceholders: ChoosePlaceholder[]
};

export type FetchProjectMeta = {
  page?: number;
  size?: number;
  isLayoutSignature?: boolean;
  floorId?: number;
  floorPlanId?: number;
  segmentId?: number;
}

type LocationProjectPayload = {
  id: number;
  regionName: string;
  projectList: Obj[];
};

export type customerInfo = {
  email: string,
  username: string,
  phoneNumber: string,
  homeCode: number,
  phonePrefix: string,
  homeCodeProjectId: number
}

export const fetchLocationsApi = async () =>
  pmsPublicRequest.get(`/projects/locations${hasStorage(StorageKey.AccessLocation)
    ? `?ip=${getStorage(StorageKey.AccessLocation)}`
    : ''}`);

export const fetchPhonePrefixApi = async () =>
  pmsPublicRequest.get(`/phone${hasStorage(StorageKey.AccessLocation)
    ? `?ip=${getStorage(StorageKey.AccessLocation)}`
    : ''}`);

export const fetchProjectSecondaryApi = async (locationId: number) =>
  pmsPublicRequest.get(`/projects/list`, {
    params: {
      locationId
    }
  });

export const fetchProjectsApi = async ({
  noOfBedrooms = [],
  minBudget,
  maxBudget,
  ...restParams
}: FetchProjectsParams): Promise<
  BasicResponsePayload<{
    locationProject: LocationProjectPayload;
    locationProjectNearby: LocationProjectPayload[];
  }>
> => {
  const params: any = {};
  if (hasStorage(StorageKey.AccessLocation)) {
    params['ip'] = getStorage(StorageKey.AccessLocation);
  }
  return pmsPublicRequest.get('/projects/filter', {
    params: {
      noOfBedrooms: !isEmpty(noOfBedrooms) ? Array(noOfBedrooms).join(',') : FilterRoomType.All,
      minBudget: formatCurrency(minBudget as number),
      maxBudget: formatCurrency(maxBudget as number),
      languageCode: i18n.language,
      ...params,
      ...restParams,
    },
  })
};

export const fetchProjectByIdApi = async (id: number | string) => {
  const currencyCode = getSession(SessionKey.Currency);
  return pmsPublicRequest.get(`/projects/detail/${id}`, {
    params: {
      languageCode: i18n.language,
      currencyCode: currencyCode
    },
  });
};

export const fetchProjectPriceByIdApi = async ({
  projectId,
  defaultType,
  ...params
}: FetchProjectsParams) => {
  const url = defaultType === 'block'
    ? `/project/${projectId}/blocks/defaultPrice`
    : `/floor/project/${projectId}/defaultPrice`;
  return pmsPublicRequest.get(url, {
    params: { ...params }
  })
};

export const fetchProjectPriceByDesignHomeApi = async ({
  ...params
}: FetchProjectsParams) => {
  return pmsPublicRequest.post(`/calculate/getPrice`, { ...params })
};

export const fetchProjectBlockByIdApi = async ({
  projectId,
  ...params
}: FetchProjectsParams) => {
  return pmsPublicRequest.get(`/project/${projectId}/blocks`, {
    params: { ...params }
  });
};

export const fetchProjectFloorPlanByIdApi = async ({
  blockId,
  ...params
}: FetchProjectsParams, meta?: MetaParams) => {
  return pmsPublicRequest.get(`/floor/block/${blockId}`, parseRequestParams({
    currencyCode: params?.currencyCode,
    ...params
  }, meta, true))
};

export const fetchAvailableSegmentCount = async ({
  blockId,
  ...params
}: FetchProjectsParams, meta?: MetaParams) => {
  return pmsPublicRequest.get(`/floor/block/${blockId}/floorAvailableSegmentCount`, parseRequestParams({
    currencyCode: params?.currencyCode,
    ...params
  }, meta, true))
};

export const fetchMostSuitableFloorApi = async ({
  blockId,
  ...params
}: FetchProjectsParams, meta?: MetaParams) => {
  return pmsPublicRequest.get(`/floor/block/${blockId}/mostSuitableFloor`, parseRequestParams({
    currencyCode: params?.currencyCode,
    ...params
  }, meta, true))
}

export const fetchSegmentByFloorIdApi = async ({
  floorId,
  ...params
}: FetchProjectsParams) => {
  return pmsPublicRequest.get(`/floor/${floorId}/segments`, {
    params: { ...params }
  });
};

export const fetchSegmentFilterByFloorIdApi = async ({
  noOfBedrooms,
  ...params
}: FetchProjectsParams) => {
  return pmsPublicRequest.get(`/floor/segments/filter`, {
    params: {
      ...params,
      noOfBedrooms: Array(noOfBedrooms).join(',') || FilterRoomType.All
    }
  });
};

export const fetchLayoutById = (projectId: number, params: FetchProjectsParams) => {
  const { noOfBedrooms } = params;
  return pmsPublicRequest.get(`/layouts/project/${projectId}`, {
    params: {
      ...params,
      languageCode: i18n.language,
      noOfBedrooms: Array(noOfBedrooms).join(',') || FilterRoomType.All,
    },
  });
}

export const fetchVerifyProductChoose = (payload: FetchVerifyProductChooseParams) =>
  pmsPublicRequest.post(`/design/verify-product-choose`, payload);

export const fetchCollectionByBlockIdApi = async ({
  blockId,
  ...params
}: FetchProjectsParams) => {
  return pmsPublicRequest.get(`/design/collections`, {
    params: { ...params }
  })
};

export const fetchMyDesignApi = (params: { productList: number, placeholderId: number, personalityId: number, currencyCode: string }) =>
  pmsPublicRequest.get(`/products/productList/${params?.productList}/placeholder/${params?.placeholderId}`, {
    params: { personalityId: params?.personalityId, currencyCode: params?.currencyCode },
  });

export const fetchCalculateGetPriceApi = () => pmsPublicRequest.get(`/calculate/getPrice`);

export const fetchProjectLayoutApi = async ({
  projectId,
  noOfBedrooms,
  ...meta
}: (FetchProjectMeta & FetchProjectsParams)) => {
  return pmsPublicRequest.get(`/layouts/project/${projectId}`, {
    params: {
      ...meta,
      noOfBedrooms: Array(noOfBedrooms).join(',') || FilterRoomType.All,
    }
  })
};

export const fetchSignatureByLayoutId = async (layoutId: number) =>
  pmsPublicRequest.get(`/personality-properties/signature/layout/${layoutId}`);

export const fetchProjectDemoApi = async () => pmsPublicRequest.get('/projects/demo');

export const fetchLanguageApi = async () => pmsPublicRequest.get(`/language`);

export const fetchProjectBrandConfig = async () => pmsPublicRequest.get(`/brand-config`);

export const fetchInfoByHomeNumber = async (code: string, projectId: number) => pmsPublicRequest.get(`customer-home?code=${code}&projectId=${projectId}`)

export const confirmCustomerInfo = async (params: customerInfo) => userPublicRequest.post('confirm-customer-infor', {
  ...params
})

export const resellMyUnitApi = async (params: any) => pmsCustomerRequest.post('customer-home/resell-my-unit', {
  ...params
})