import { configureStore } from '@reduxjs/toolkit';
import { Action, AnyAction } from 'redux';
import logger from 'redux-logger';
import { ThunkAction } from 'redux-thunk';

import {
  TypedUseSelectorHook,
  useDispatch as reduxUseDispatch,
  useSelector as reduxUseSelector,
} from 'react-redux';
import rootReducer from './rootReducer';

export type RootState = ReturnType<typeof rootReducer>;

const store =
  process.env.NODE_ENV === 'production'
    ? configureStore({ reducer: rootReducer })
    : configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
          getDefaultMiddleware({
            serializableCheck: false,
          }).concat(logger),
      });

// Infer the `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => reduxUseDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = reduxUseSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  AnyAction
>;

export type AppAction<T, ExtraProps extends Record<string, any>> = Action<T> &
  ExtraProps;

export default store;
