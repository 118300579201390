import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 25 24" {...props}>
    <path
      d="M20.4571 17.8969C20.015 16.8496 19.3734 15.8984 18.568 15.0961C17.7651 14.2915 16.814 13.65 15.7672 13.207C15.7579 13.2023 15.7485 13.2 15.7391 13.1953C17.1993 12.1406 18.1485 10.4227 18.1485 8.48438C18.1485 5.27344 15.5469 2.67188 12.336 2.67188C9.12506 2.67188 6.5235 5.27344 6.5235 8.48438C6.5235 10.4227 7.47271 12.1406 8.93287 13.1977C8.9235 13.2023 8.91412 13.2047 8.90475 13.2094C7.85475 13.6523 6.91256 14.2875 6.10397 15.0984C5.29938 15.9013 4.65788 16.8524 4.2149 17.8992C3.77972 18.924 3.54502 20.0228 3.5235 21.1359C3.52287 21.161 3.52726 21.1858 3.5364 21.2091C3.54554 21.2324 3.55925 21.2537 3.57673 21.2716C3.5942 21.2895 3.61508 21.3037 3.63814 21.3134C3.6612 21.3231 3.68597 21.3281 3.711 21.3281H5.11725C5.22037 21.3281 5.3024 21.2461 5.30475 21.1453C5.35162 19.3359 6.07818 17.6414 7.36256 16.357C8.69146 15.0281 10.4563 14.2969 12.336 14.2969C14.2157 14.2969 15.9805 15.0281 17.3094 16.357C18.5938 17.6414 19.3204 19.3359 19.3672 21.1453C19.3696 21.2484 19.4516 21.3281 19.5547 21.3281H20.961C20.986 21.3281 21.0108 21.3231 21.0338 21.3134C21.0569 21.3037 21.0778 21.2895 21.0953 21.2716C21.1127 21.2537 21.1265 21.2324 21.1356 21.2091C21.1447 21.1858 21.1491 21.161 21.1485 21.1359C21.1251 20.0156 20.893 18.9258 20.4571 17.8969ZM12.336 12.5156C11.2602 12.5156 10.2477 12.0961 9.486 11.3344C8.72428 10.5727 8.30475 9.56016 8.30475 8.48438C8.30475 7.40859 8.72428 6.39609 9.486 5.63437C10.2477 4.87266 11.2602 4.45312 12.336 4.45312C13.4118 4.45312 14.4243 4.87266 15.186 5.63437C15.9477 6.39609 16.3672 7.40859 16.3672 8.48438C16.3672 9.56016 15.9477 10.5727 15.186 11.3344C14.4243 12.0961 13.4118 12.5156 12.336 12.5156Z"
      fill="white"
    />
  </SvgIcon>
);

export default UserOutlinedIcon;
