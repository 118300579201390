import { get } from 'lodash';

import { toJSON } from 'utils/stringUtils';
import { getLangCode } from 'locales';

import { SessionKey } from 'constants/storage';
import { TCountryPhoneCode } from 'constants/i18n';
import { COUNTRY_PHONE_CODE_OPTIONS } from 'locales/countries/en';



export const setSessionIpAddressInfo = (payload: any) =>
  sessionStorage.setItem(SessionKey.IpAddressInfo, JSON.stringify(payload));

export const getSessionIpAddressInfo = () =>
  toJSON(sessionStorage.getItem(SessionKey.IpAddressInfo), {});

export const getOptionPhoneByIpCountry = (): TCountryPhoneCode | undefined => {
  const ipLocationIpAddress = getSessionIpAddressInfo();
  const loc = ipLocationIpAddress.loc || '';
  return COUNTRY_PHONE_CODE_OPTIONS.find((o) => o.shortLocale === loc);
};

export type TPhoneNumberState = {
  phoneNumber: string,
  prefix: string,
  option: TCountryPhoneCode | { locale: string }
}

export const getInitialPhoneNumberState = (): TPhoneNumberState => {
  const option = getOptionPhoneByIpCountry();
  
  return {
    phoneNumber: '',
    prefix: option?.value || '',
    option: option || { locale: '' },
  };
};

export const standardizePhone = (phoneWithPrefix: TPhoneNumberState) => {
  const langcode = getLangCode();
  let _phoneWithPrefix = phoneWithPrefix;
  if (langcode === 'vi' || get(phoneWithPrefix, 'option.locale') === 'vi-VN') {
    /* VietNam phone number full format like that:  +84916843278 */
    
    _phoneWithPrefix = Object.assign({},
      phoneWithPrefix,
      {phoneNumber: `${phoneWithPrefix.phoneNumber ? parseInt(phoneWithPrefix.phoneNumber) : phoneWithPrefix.phoneNumber}`}
    )
  }
  return _phoneWithPrefix;
}