import { useTheme } from '@mui/material';
import React from 'react';

const IcCameraProfile = (props: any) => {
  const theme = useTheme();
  return (
    <svg {...props} viewBox="0 0 37 36" fill="none">
      <rect x="1.5" y="1" width="34" height="34" rx="17" fill={theme.palette.accent.main} />
      <path
        d="M23.5 13.5H21.7672L20.9156 12.2227C20.87 12.1542 20.8081 12.098 20.7356 12.0592C20.663 12.0203 20.5819 12 20.4996 12H16.4996C16.4173 12 16.3363 12.0203 16.2637 12.0592C16.1911 12.098 16.1293 12.1542 16.0836 12.2227L15.2321 13.5H13.5C13.1023 13.5005 12.721 13.6586 12.4398 13.9398C12.1586 14.221 12.0005 14.6023 12 15V22C12.0005 22.3977 12.1586 22.779 12.4398 23.0602C12.721 23.3414 13.1023 23.4995 13.5 23.5H23.5C23.8977 23.4995 24.279 23.3414 24.5602 23.0602C24.8414 22.779 24.9995 22.3977 25 22V15C24.9995 14.6023 24.8414 14.221 24.5602 13.9398C24.279 13.6586 23.8977 13.5005 23.5 13.5ZM20.75 18.25C20.75 18.695 20.618 19.13 20.3708 19.5C20.1236 19.87 19.7722 20.1584 19.361 20.3287C18.9499 20.499 18.4975 20.5436 18.061 20.4568C17.6246 20.37 17.2237 20.1557 16.909 19.841C16.5943 19.5263 16.38 19.1254 16.2932 18.689C16.2064 18.2525 16.251 17.8001 16.4213 17.389C16.5916 16.9778 16.88 16.6264 17.25 16.3792C17.62 16.132 18.055 16 18.5 16C19.0965 16.0007 19.6684 16.2379 20.0903 16.6597C20.5121 17.0816 20.7493 17.6535 20.75 18.25Z"
        fill="white"
      />
      <rect
        x="1.5"
        y="1"
        width="34"
        height="34"
        rx="17"
        stroke="#3E3B45"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IcCameraProfile;
