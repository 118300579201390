import React from 'react';
import styled from 'styled-components';

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import { ButtonColor } from './constants/button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';

type ButtonShape = 'circle' | 'round';

const NATIVE_COLORS: ButtonColor[] = ['inherit', 'transparent'];

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
  label?: string;
  color?: ButtonColor;
  className?: string;
  shape?: ButtonShape;
  bold?: boolean;
  fontSize?: number | string;
  width?: number | string;
  height?: number | string;
  loading?: boolean;
};

const Button = ({
  label,
  className,
  color = 'default',
  shape,
  loading,
  startIcon,
  disabled,
  children,
  ...restProps
}: ButtonProps) => {
  const theme = useTheme();
  const classNames: string[] = [];
  if (className) classNames.push(className);
  if (shape) classNames.push(`button-shape-${shape}`);

  return (
    <StyledButton
      color={color}
      className={classNames.join(' ')}
      startIcon={loading ? <CircularProgress size=".8em" style={{ color: '#fff' }} /> : startIcon}
      disabled={loading || disabled}
      {...restProps}
    >
      {children || label}
    </StyledButton>
  );
};

Button.defaultProps = {
  color: 'default',
  variant: 'contained',
  fontSize: '0.8125rem', // 13px
  height: '2.5rem', // 40px
};

type StyleProps = {
  color: ButtonColor;
  transparent?: boolean;
  bold?: boolean;
  fontSize?: number | string;
  width?: number | string;
  height?: number | string;
};

const StyledButton = styled(MuiButton)<StyleProps>`
  && {
    box-shadow: none;
    text-transform: none;
    font-size: ${(p) => p.theme.func.length(p.fontSize)};
    ${(p) => p.bold && `font-weight: ${p.theme.typography.fontWeightBold};`}
    color: #fff;

    height: ${(p) => p.height};
    ${(p) => p.width && `width: ${p.width};`}

    &:hover {
      background-color: ${(p) =>
        NATIVE_COLORS.includes(p.color)
          ? p.color
          : p.theme.palette[p.color].mainHover ||
            p.theme.palette[p.color].main};
    }

    &:focus {
      outline: none;
    }

    &.Mui-disabled {
      background-color: ${(p) =>
        NATIVE_COLORS.includes(p.color)
          ? p.color
          : p.theme.palette[p.color].mainDisabled ||
            p.theme.palette[p.color].main};
      color: rgba(255, 255, 255, 0.7);
    }

    &.button-shape-circle {
      border-radius: 50%;
    }

    &.button-shape-round {
      border-radius: ${(p) => p.height};
    }

    &.MuiButton-containedTransparent {
      font-weight: 400;
      border: solid ${(p) => p.theme.palette.primary.contrastText} 1px;

      &:hover {
        box-shadow: none;
      }
    }

    &.MuiButton-sizeSmall {
      font-size: 9px;
      padding: 4px 6px;
      min-width: 50px;
    }
  }
`;

export default Button;
