import React from 'react';

const IcFAQHead = (props: any) => {
  return (
  <svg {...props} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5963 6.74727L13.183 2.12383C13.06 1.99492 12.8938 1.92188 12.7195 1.92188H4.00781C3.64482 1.92188 3.35156 2.2291 3.35156 2.60938V20.4844C3.35156 20.8646 3.64482 21.1719 4.00781 21.1719H17.1328C17.4958 21.1719 17.7891 20.8646 17.7891 20.4844V7.23496C17.7891 7.05234 17.7193 6.87617 17.5963 6.74727ZM16.2756 7.55078H12.416V3.50742L16.2756 7.55078ZM16.3125 19.625H4.82812V3.46875H11.0215V8.10938C11.0215 8.34869 11.1122 8.57821 11.2738 8.74743C11.4353 8.91665 11.6544 9.01172 11.8828 9.01172H16.3125V19.625ZM10.4062 13.8242H6.63281C6.54258 13.8242 6.46875 13.9016 6.46875 13.9961V15.0273C6.46875 15.1219 6.54258 15.1992 6.63281 15.1992H10.4062C10.4965 15.1992 10.5703 15.1219 10.5703 15.0273V13.9961C10.5703 13.9016 10.4965 13.8242 10.4062 13.8242ZM6.46875 11.0742V12.1055C6.46875 12.2 6.54258 12.2773 6.63281 12.2773H14.5078C14.598 12.2773 14.6719 12.2 14.6719 12.1055V11.0742C14.6719 10.9797 14.598 10.9023 14.5078 10.9023H6.63281C6.54258 10.9023 6.46875 10.9797 6.46875 11.0742Z" fill="#C9C8CC"/>
  </svg>
  );
};

export default IcFAQHead;
