import BaseModel from './baseModel';
import { Icon, Unit, GalleryPhoto } from './commonTypes';
import { Obj } from 'constants/types';
import { TCurrencyObject } from 'constants/currency';
import { ChipProps } from '@mui/material';
import { i18n } from 'locales';

type Essential = {
  id: number;
  name: string;
  icon: Icon;
  unit: Unit<'LENGTH'>;
  distance: number;
};

type Facility = {
  id: number;
  name: string;
  details: string;
};

type Architect = {
  id: number;
  name: string;
  description: string;
  authorAvatarUri: string;
};

type Currency = {
  id: number;
  name: string;
  code: string;
  symbol: string;
  conversionRate: number;
};

type ProjectPrice = {
  id: number;
  price: number;
  currency: Currency;
  unit: Unit<'AREA'>;
};

type ProjectUtilitiesHighlight = {
  id: number;
  isListingShow: boolean;
  essentialId: number;
  essential: UtilitiesHighlight;
  projectId: number;
  state: boolean;
};

type UtilitiesIcon = {
  id: number;
  name: number;
  type: string;
  imageUrl: string;
};

type UtilitiesHighlight = {
  id: number;
  name: string;
  state: number;
  type: string;
  icon: UtilitiesIcon;
  distance: number;
};

type UtilitiesEssential = {
  id?: number,
  essentialId?: number,
  essential: UtilitiesHighlight,
  isListingShow?: boolean,
  projectId?: number,
  state?: number,
}

type Region = {
  id: number;
  parentId: number;
  type: string;
  name: string;
  shortName: any;
};

export type Developers = {
  name: string;
  description: string;
  logo: string;
};

export type HighlightUtility = {
  id: number;
  name: string;
  type: 'UTILITIES_HIGHLIGHT';
  icon: Icon<'ESSENTIAL'>;
  distance: number;
};

export enum ProjectStatus {
  Publish = 'PUBLISH',
  Upcoming = 'UP_COMING',
  Draft = 'DRAFT',
}

export enum SellType {
  Resell = 'EXISTING',
  New = 'NEW'
}

export enum Envision {
  TO_INVEST = 'TO_INVEST',
  TO_ENJOY = 'TO_ENJOY'
}

export enum ChosenOption {
  RESELL = 'RESELL',
  KEEP = 'KEEP',
  REMODEL = 'REMODEL',
}

export const ProjectStatusText: Record<string, string> = {
  [ProjectStatus.Publish]: 'For Sale',
  [ProjectStatus.Upcoming]: 'Coming Soon',
};

export const ProjectStatusStyle: Record<string, string> = {
  [ProjectStatus.Publish]: 'publish',
  [ProjectStatus.Upcoming]: 'upcoming',
};

export type ProjectTimeline = {
  deadlineDate: string;
  id: number;
  order: number;
  state: number;
  title: string;
  description: string;
  isBeforeCurrentDate?: boolean;
};

export type Facilities = {
  details?: string;
  id?: number;
  name?: string;
  state?: number;
}

export type ProjectFacilities = {
  facilityId?: number;
  id?: number;
  project?: any;
  projectId?: number;
  state?: number;
  thumbnailUri?: string;
  details?: string;
  facility?: Facilities;
}

export type ProjectVideo = {
  videoURI?: string;
  videoDescription?: string;
}

export const tProjectStatus = (): Record<ProjectStatus, string> => ({
  [ProjectStatus.Draft]: i18n.t('Draft'),
  [ProjectStatus.Publish]: i18n.t('Publish'),
  [ProjectStatus.Upcoming]: i18n.t('Upcoming'),
});

export const PROJECT_STATUS_COLOR_MAP: Record<
  ProjectStatus,
  ChipProps['color']
> = {
  [ProjectStatus.Publish]: 'success',
  [ProjectStatus.Upcoming]: 'warning',
  [ProjectStatus.Draft]: 'warning',
};

class Project extends BaseModel<Project> {
  id?: number;

  name?: string;
  address?: string;
  locationId?: number; // locationId is regionId
  lat?: number;
  lng?: number;
  staticMapUrl?: string;

  availableBedroomNumbers?: number[];
  price?: ProjectPrice;
  projectPrice?: number;
  soldPercent?: number;
  displaySoldPercent?: boolean;
  startingPrice?: number;
  utilitiesHighlight?: UtilitiesEssential[];
  totalBlock?: number;
  totalSegment?: number;
  projectTimelines?: ProjectTimeline[];
  projectFacilities?: ProjectFacilities[];

  startDate?: string;
  endDate?: string;

  essentials?: Essential[];
  facilities?: Facility[];
  facilityPhotos?: GalleryPhoto[];

  shortDescription?: string;
  description?: string;
  signatureDescription?: string;
  designIntroduction?: string;
  timeline?: string;

  logoUri?: string;
  thumbnailUri?: string;
  videoIntroductionUri?: string;
  galleryPhotos?: GalleryPhoto[];
  signatureGallery?: GalleryPhoto[];
  architect?: Architect;
  region?: Region;
  vatPercent?: number;
  currency?: TCurrencyObject;
  descriptionPhotos?: GalleryPhoto[];
  thumbnailGallery?: GalleryPhoto[];
  projectVideo?: ProjectVideo;

  status?: ProjectStatus;
  sellType?: SellType;
  developers?: Developers[];
  previewHighlightUtilities?: ProjectUtilitiesHighlight[];
  highlightUtilities?: ProjectUtilitiesHighlight[];

  viewStar?: number;
  vatName?: string;

  waitingList?: number;
  purchasedPercent?: number;
  reservedPercent?: number;

  totalOrdersOf48hour?: number;
  totalBlocks?: number;
  totalSegments?: number;

  // 3D Arcware
  packageId?: string;
  shareId?: string;

  // NEED UPDATE WHEN INTERACTIVE API

  // city: {id: 491, parentId: 490, type: "CITY", name: "Thành phố Hồ Chí Minh", shortName: null}
  // continent: {id: 488, parentId: null, type: "CONTINENT", name: "Asia", shortName: null}
  // country: {id: 489, parentId: 488, type: "COUNTRY", name: "Vietnam", shortName: null}
  // defaultLanguage: {id: 1, name: "English", code: "en", enable: true}
  // district: {id: 635, parentId: 491, type: "DISTRICT", name: "Quận 10", shortName: null}
  // mortgagePackages: []
  // plainPrice: 5000
  // region: {id: 490, parentId: 489, type: "REGION", name: "Đông Nam Bộ", shortName: null}
  // ward: {id: 640, parentId: 635, type: "WARD", name: "Bến Nghé", shortName: null}

  fromPayload({
    id,
    name,
    address,
    region,
    lat,
    lng,
    availabelBedroomNumbers,
    price,
    soldPercent,
    displaySoldPercent,
    startDate,
    endDate,
    essentials,
    facilities,
    shortDescription,
    description,
    signatureDescription,
    designIntroduction,
    timeline,
    logoUri,
    thumbnailUri,
    videoIntroductionUri,
    gallery,
    signatureGallery,
    architect,
    vatPercent,
    status,
    sellType,
    descriptionGalleries,
    facilitiesThumbnails,
    developers,
    staticMapUrl,
    viewStar,
    totalSegment,
    totalBlock,
    startingPrice,
    utilitiesHighlightListingPage,
    waitingList,
    purchased,
    reserved,
    utilitiesHighlight,
    projectTimelines,
    totalOrdersOf48hour,
    totalBlocks,
    totalSegments,
    projectFacilities,
    thumbnailGallery,
    packageId,
    shareId,
    projectVideo,
    vatName,
  }: Obj): this {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (address !== undefined) this.address = address;
    if (region?.id !== undefined) {
      this.locationId = region.id; // locationId is regionId
      this.region = region;
    }
    if (lat !== undefined) this.lat = lat;
    if (lng !== undefined) this.lng = lng;
    if (soldPercent !== undefined) this.soldPercent = soldPercent;
    if (availabelBedroomNumbers !== undefined)
      this.availableBedroomNumbers = availabelBedroomNumbers;
    if (price?.price !== undefined) {
      this.price = price;
      this.projectPrice = price?.price || null;
      this.currency = price?.currency || null;
      this.vatPercent = vatPercent;
    }
    if (displaySoldPercent !== undefined)
      this.displaySoldPercent = displaySoldPercent;
    if (startDate !== undefined) this.startDate = startDate;
    if (endDate !== undefined) this.endDate = endDate;
    if (essentials !== undefined) this.essentials = essentials;
    if (facilities !== undefined) this.facilities = facilities;
    if (shortDescription !== undefined)
      this.shortDescription = shortDescription;
    if (description !== undefined) this.description = description;
    if (signatureDescription !== undefined) this.signatureDescription = signatureDescription;
    if (designIntroduction !== undefined)
      this.designIntroduction = designIntroduction;
    if (timeline !== undefined) this.timeline = timeline;
    if (logoUri !== undefined) this.logoUri = logoUri;
    if (thumbnailUri !== undefined) this.thumbnailUri = thumbnailUri;
    if (videoIntroductionUri !== undefined)
      this.videoIntroductionUri = videoIntroductionUri;
    if (gallery?.photos !== undefined) this.galleryPhotos = gallery.photos;
    if (thumbnailGallery?.photos !== undefined) this.thumbnailGallery = thumbnailGallery.photos;
    if (signatureGallery?.photos !== undefined) this.signatureGallery = signatureGallery.photos;
    if (architect !== undefined) this.architect = architect;
    if (status !== undefined) this.status = status;
    if (sellType !== undefined) this.sellType = sellType;
    if (descriptionGalleries !== undefined)
      this.descriptionPhotos = descriptionGalleries;
    if (facilitiesThumbnails !== undefined) {
      this.facilityPhotos = (facilitiesThumbnails || []).map(
        ({ id, thumbnailsUri }: Obj) => ({
          id,
          photoUri: thumbnailsUri,
        })
      );
    }
    if (developers !== undefined) this.developers = developers;
    if (staticMapUrl !== undefined) this.staticMapUrl = staticMapUrl;
    if (viewStar !== undefined) this.viewStar = viewStar;
    if (waitingList !== undefined) this.waitingList = waitingList;
    if (purchased !== undefined) this.purchasedPercent = purchased;
    if (reserved !== undefined) this.reservedPercent = reserved;
    if (utilitiesHighlightListingPage !== undefined)
      this.previewHighlightUtilities = utilitiesHighlightListingPage;
    if (startingPrice !== undefined) this.startingPrice = startingPrice;
    if (utilitiesHighlight !== undefined)
      this.utilitiesHighlight = utilitiesHighlight;
    if (totalBlock !== undefined) this.totalBlock = totalBlock;
    if (totalSegment !== undefined) this.totalSegment = totalSegment;
    if (projectTimelines !== undefined)
      this.projectTimelines = projectTimelines;
    if (totalOrdersOf48hour !== undefined)
      this.totalOrdersOf48hour = totalOrdersOf48hour;
    if (totalBlock !== undefined)
      this.totalBlock = totalBlock;
    if (totalSegment !== undefined)
      this.totalSegment = totalSegment;
    if (projectFacilities !== undefined) this.projectFacilities = projectFacilities;
    if (packageId !== undefined) this.packageId = packageId;
    if (shareId !== undefined) this.shareId = shareId;
    if (projectVideo !== undefined) this.projectVideo = projectVideo;
    if (vatName !== undefined) this.vatName = vatName;

    return this;
  }
}

export default Project;
