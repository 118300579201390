import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Grid, Stack, useTheme, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import TextField from 'components/formControls/TextField';
import { getStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';
import { signinLightEmail, signupLightEmail, updateUserEmailApi } from 'apis/userApis';
import { TypeSignInAndSignUp, TypeSinup } from '../SignupLight';
import Alert from 'components/alerts/Alert';
import { TypeChangePhoneNumberOrEmail } from 'pages/MyProfile/PersonalProfile';
import { useSelector } from 'react-redux';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import { Button } from 'components/buttons';

type SignupPhoneNumberProps = {
  typeVerify?: string;
  onChangeTypeVerify: (value: string) => void;
  typePage?: string,
  onChangeStep: (email?: string, name?: string) => void;
}

function SignupEmail(props: SignupPhoneNumberProps) {
  const theme = useTheme();
  const { typeVerify, onChangeTypeVerify, onChangeStep, typePage } = props;
  const { t } = useTranslation();
  const {
    auth: { username },
    textContentBrand: { textBrand }
  } = useSelector(selectDesignHome);

  const validationSchema = object().shape({
    name: typePage === TypeSignInAndSignUp.SignUp ? string().required(t('Name is required')).max(20) : string(),
    email: string()
      .required(t('Email is required'))
      .email(t('Email invalid'))
      .max(100),
  });
  const { control, handleSubmit, setValue, watch } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });

  const [loginErrMessage, setLoginErrMessage] = useState<string>('');
  const [isFetching, setIsFetching] = useState(false);
  const email = watch('email');
  const nickname = watch('name');

  useEffect(() => {
    setLoginErrMessage('')
  }, [email, nickname])

  useEffect(() => {
    if (username) {
      setValue('name', username);
    }
  }, [])

  const onSubmit: SubmitHandler<{ name: string; email: string }> = async ({ email, name }) => {
    setIsFetching(true);
    if (typeVerify === TypeChangePhoneNumberOrEmail.changeEmail) {
      try {
        await updateUserEmailApi({
          email,
        })
        setIsFetching(false);
        onChangeStep(email, name);
      } catch (e) {
        setIsFetching(false);
        setLoginErrMessage(t('Error server'));
      }
    } else {
      try {
        const uuid = getStorage(StorageKey.UUID_Design);
        if (typePage === TypeSignInAndSignUp.SignUp) {
          await signupLightEmail({
            email,
            username: name,
            uuid: uuid
          })
        } else {
          await signinLightEmail({
            email,
            uuid: uuid
          })
        }
        setIsFetching(false);
        onChangeStep(email, name);
      } catch (error) {
        const errorMessage = (error as any).response.data.message;
        if (errorMessage === 'User is not exist by email.') {
          setLoginErrMessage(t('This email has not been registered yet.'));
        } else if (errorMessage === 'User is exist by email.') {
          setLoginErrMessage(t('This email has been registered.'));
        } else if (errorMessage === 'Email is exist db.') {
          setLoginErrMessage(t('This email has been registered.'));
        } else {
          setLoginErrMessage(t('Error server'));
        }
        setIsFetching(false)
      }
    }

  };

  return (
    <div>
      {
        typeVerify === TypeSinup.email && typePage === TypeSignInAndSignUp.SignUp &&
        <Box>
          <Box className="mb-1" fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>{t("{{context}} awaits. Create your account now", { context: textBrand || 'Levitate' })}!</Box>
          <p className="description mb-2">
            {t("No spam, just real talk. Give us your nickname and email to kick things off")}.
          </p>
        </Box>
      }
      {
        typeVerify === TypeSinup.email && typePage === TypeSignInAndSignUp.SignIn &&
        <Box>
          <Box className="mb-1" fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>{t("Welcome Back")}!</Box>
          <p className="description mb-3">
            {t("To {{context}}, the place where the world is at your fingertips", { context: textBrand || 'Levitate' })}.
          </p>
        </Box>
      }
      {
        typeVerify === TypeChangePhoneNumberOrEmail.changeEmail &&
        <Box textAlign="center">
          <Box className="mb-1" fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>{t("Change your email")}!</Box>
          <p className="description mb-3">
            {t("Enter your new email. This email will be used to sign in for the next time")}.
          </p>
        </Box>
      }
      <Alert className='' style={{ fontSize: '12px' }} message={loginErrMessage} severity="error" width="100%" />
      <Box marginTop={{ xs: '1rem' }} marginBottom={{ xs: '1rem' }}>
        <Grid container spacing={2}>
          {typeVerify === TypeSinup.email && typePage === TypeSignInAndSignUp.SignUp &&
            <Grid item xs={12}>
              <FormControl>
                <TextField control={control} maxLength={20} placeholder={t('Nickname')} fullWidth required name="name" />
              </FormControl>
            </Grid>
          }
          <Grid item xs={12}>
            <FormControl>
              <TextField control={control} placeholder="Email" fullWidth required name="email" />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {
        typeVerify === TypeSinup.email &&
        <Stack marginBottom={{ xs: '1rem' }} direction={'row'} alignItems={'center'} gap={1}>
          <Switch
            onChange={() => onChangeTypeVerify(TypeSinup.PhoneNumber)} />
          <Box fontSize={{ xs: '12px', md: '16px' }}>{t('Sign in with phone instead')}</Box>
        </Stack>
      }
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Button fullWidth color='accent' loading={isFetching} onClick={handleSubmit(onSubmit)}>
            {typeVerify === TypeSinup.email && typePage === TypeSignInAndSignUp.SignUp && t('Join Now')}
            {typeVerify === TypeSinup.email && typePage === TypeSignInAndSignUp.SignIn && t('Login')}
            {typeVerify === TypeChangePhoneNumberOrEmail.changeEmail && t('Save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignupEmail;