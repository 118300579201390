export const POLICY_KO = (textBrand: string) => {
  return [
    {
      title: '소개',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>우리</strong>", "<strong>우리</strong>", "<strong>${textBrand}</strong>")는 <a href="https://levitate.homes">https://levitate.homes</a> (이하 "<strong>웹사이트</strong>")의 운영자이며, 우리는 본 문서에 기재된 조건에 따라 귀하의 개인 데이터(아래에서 정의된 조항 2에 따름)를 보호하기 위해 노력하고 있습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>이 개인 정보 취급 방침(본 조건과 그와 관련된 다른 문서를 포함합니다)은 우리가 귀하로부터 수집하는 개인 데이터, 또는 귀하가 우리에게 제공하는 개인 데이터가 어떻게 사용되고, 처리되고, 또는 우리에 의해 공개되는지, 특정 정보가 귀하에게 맞춤형 광고에 사용되는 방법도 설명합니다. 이 개인 정보 취급 방침은 또한 우리가 수집하는 개인 데이터의 유형, 그것을 어떻게 보호하는지, 그리고 우리가 보관하는 귀하의 개인 데이터에 어떻게 액세스하거나 수정할 수 있는지에 대해서도 설명합니다. 이 개인 정보 취급 방침은 귀하의 웹사이트 이용 및 당사 제품 및 서비스를 통해 귀하가 당사와 연결하거나 상호 작용하는 방법(이하 통칭하여 "<strong>서비스</strong>")에 적용됩니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>당사의 정책 및 관행에 대해 이해하시려면 귀하의 개인 데이터 및 당사가 그것을 처리하는 방법에 대해 신중하게 다음을 읽어 주시기 바랍니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>해당 법률에 제한되지 않는 한, 웹사이트와 서비스를 사용함으로써, 귀하는 본 개인 정보 취급 방침에서 명시된 목적과 자에게 귀하와 관련된 모든 개인 데이터가 사용, 처리 및 공개될 수 있음에 동의합니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>귀하의 개인 데이터를 적절하게 관리, 보호 및 처리하기 위한 노력의 일환으로, 우리는 정기적으로 정책, 절차 및 절차를 검토할 것입니다. 우리는 웹사이트에 개정된 버전을 게시함으로써 본 개인 정보 취급 방침을 언제든지 수정할 수 있습니다. 변경 사항이 발생할 경우, 공지를 보낼 수도 있으나, 귀하는 변경 사항이 시행된 후에도 웹사이트나 서비스를 계속 사용하는 경우 공지를 받았던지 여부와 상관없이 수정된 개인 정보 취급 방침에 동의한 것으로 간주됩니다. 본 개인 정보 취급 방침의 업데이트를 위해 본 페이지를 정기적으로 확인하시기 바랍니다.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      title: '개인 데이터 수집',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                웹 사이트를 둘러보고 제한된 기능을 사용하는 동안 개인 데이터를 공개하지 않고도 이용할 수 있습니다. 웹 사이트를 이용하는 조건으로 개인 데이터를 제공할 필요는 없습니다. 다만, 웹 사이트를 통해 구매하거나 접근하는 서비스를 제공하거나 요청에 응답하거나 당사와 직접 연락하는 경우에는 필요할 수 있습니다.
              </div>
              <div>
                서비스를 이용하는 경우, 여러분, 여러분의 기기, 그리고 서비스와의 상호작용과 관련하여 다양한 정보를 수집합니다. 이 정보 중 일부는 여러분을 직접 식별하거나 다른 데이터와 결합하여 여러분을 불명확하게 식별할 수 있는 정보입니다. 그러한 데이터나 그와 관련된 다른 정보를 통해 식별될 수 있는 개인에 관한 정보를 ("<strong>개인 데이터</strong>")라고 합니다.
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.1.</div>
                  <div>
                    <strong>자발적으로 제공하는 정보</strong>. 서비스를 이용하는 과정에서 이름, 연락처 정보, 결제 정보, 관심 있는 주택 또는 재산에 관한 세부 정보, 재정 정보, 관심사, 또는 취향과 같은 여러분 자신에 관한 개인 정보를 제공하도록 요청받을 수 있습니다. 이러한 정보를 제공하는 경우, 다음과 같은 상황에서 발생할 수 있습니다:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>웹 사이트를 통해 당사와 거래를 수행하는 경우;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>웹 사이트에서 계정을 등록하는 경우;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>고객 서비스 또는 영업 담당자에게 이메일, 전화, 또는 직접 문의하여 직면하는 문제를 해결하거나 문의 사항을 해결하는 경우;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>웹 사이트에서 제공하는 서비스에 가입하거나 이메일 통지 및/또는 뉴스레터를 구독하는 경우;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>서비스 이용을 목적으로 웹 사이트에 관련 입력 정보, 응답, 정보를 제공하거나 남기는 경우;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>서비스를 통해 부동산 전문가(부동산 중개인 또는 중개사, 모기지 대출인 또는 대출 담당자, 부동산 관리인, 투자가, 주택 건축업자 등)와 통신하는 경우; 및</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g. </div>
                        <div>대출 정보 요청, 배경 확인 신청(있는 경우)과 같은 기타 양식 또는 거래를 완료하는 경우.</div>
                      </div>
                    </div>
                    <div>
                      또한 이 서비스를 통해 제3자에 관한 정보를 제공할 수 있습니다. 예를 들어, 부동산 목록을 이메일이나 SMS를 통해 수신자와 공유하는 경우입니다. 이러한 정보를 제공함으로써 여러분은 해당 제3자로부터 이 개인 정보를 제공하는 데 동의한 것으로 간주됩니다. 이에 따라 해당 제3자의 동의를 얻었다고 당사에 보증하는 것입니다. 이러한 개인 정보는 본 개인 정보 보호 정책에 명시된 목적을 위해 여러분의 동의하에 사용됩니다.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.2.</div>
                  <div>
                    <strong>쿠키, 픽셀 및 기타 추적 메커니즘</strong>. 당사와 파트너는 쿠키 및 기타 유사한 기술을 사용하여 서비스에 접속하고 이용할 때 자동으로 정보를 수집합니다. 쿠키는 여러분의 브라우저를 고유하게 식별하기 위해 여러분의 컴퓨터나 기타 전자 기기로 전송되는 전자 정보입니다. 서비스를 이용할 때, 당사와 파트너는 여러분의 컴퓨터나 기타 전자 기기에 하나 이상의 쿠키를 설치하거나 비슷한 기능을 제공하는 다른 기술을 사용할 수 있습니다.
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>당사와 파트너는 여러분의 서비스 이용 활동을 여러분의 계정 프로필에 저장되어 있는 다른 정보와 연결하여 여러분에 대한 정보를 파악하기 위해 쿠키를 사용할 수 있습니다. 쿠키의 사용을 통해 여러분의 관심 정보를 파악하고 추세를 파악하며, 광고의 효과를 측정하거나, 정기적으로 조회하는 여러분의 정보(예: 찜한 속성 또는 선호하는 검색어)를 저장하는 등 서비스 품질을 개선하는 데 도움이 됩니다. 이러한 쿠키를 통해 수집된 정보는 해당 법률에 따라 허용되는 한 가능한 목적으로 사용됩니다. 원하는 경우, 브라우저 설정을 조정하여 쿠키를 거부할 수 있습니다. 그러나 쿠키를 비활성화하는 경우, 서비스의 무료 기능 중 많은 기능이 올바르게 작동하지 않을 수 있습니다.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>당사의 일부 페이지에는 픽셀이 포함될 수 있습니다. 픽셀은 특정 페이지를 방문한 사용자를 세는데 사용되는 전자 파일로, 시간에 따른 활동을 추적하고 다른 웹사이트를 통해 이루어진 활동을 추적하며, 당사가 보낸 이메일과 상호작용한 사용자를 확인하고 해당 페이지를 접근한 컴퓨터나 기타 전자 기기에 관련된 정보를 수집할 수 있습니다. 이 정보는 여러분의 고유한 브라우저, 기기 식별자 또는 IP 주소와 연관될 수 있습니다. 예를 들어, 특정 서비스에서 특정 목록을 보는 경우 해당 목록이 여러분에게 적합한지 추적하고 추천을 제공하기 위해 픽셀을 구현할 수 있습니다. 이러한 픽셀과 쿠키를 통해 여러분의 서비스 이용에 대한 정보를 수집하며, 여러분이 사용하는 브라우저 유형, 접속 시간, 본 서비스로 이동하기 전에 방문한 페이지, IP 주소 등의 정보를 파악합니다.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>당사는 여러분이 서비스를 이용하는 데 사용하는 컴퓨터 또는 모바일 기기에 대한 정보도 수집합니다. 해당 정보에는 하드웨어 모델, 운영 체제 및 버전, 고유한 기기 식별자, 모바일 네트워크 정보, 브라우징 동작 등이 포함될 수 있습니다.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>당사는 또한 웹 사이트를 이용하는 경우 특정 타사가 쿠키와 기타 기술을 통해 여러분의 온라인 활동에 대한 정보를 수집하도록 허용합니다. 이러한 타사에는 (a) 웹 사이트에서 그들의 광고와 상호작용하는 경우 정보를 수집하는 비즈니스 파트너, (b) 여러분이 인터넷의 많은 다른 웹 사이트에서 그들이 게재한 광고와 상호작용하는 경우 여러분의 관심사를 수집하는 광고 네트워크 등이 포함됩니다. 이러한 타사가 수집한 정보는 개인 식별 정보가 아니며, 여러분의 특성, 관심사 또는 선호도에 대한 예측을 수행하고 여러분의 관심사에 맞춘 광고를 웹 사이트와 인터넷 상의 다른 위치에서 표시하기 위해 사용됩니다.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>브라우저 설정을 통해 허용되는 쿠키 유형을 관리할 수 있으며, 필요한 경우 모든 쿠키를 완전히 차단할 수 있습니다. 브라우저에서 쿠키 관리에 대한 정보는 사용 중인 브라우저의 도움말 섹션을 참조하세요.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.3.</div>
                  <div>
                    <strong>모바일 기기 및 모바일 브라우저 정보.</strong> 모바일 기기의 쿠키와 특정 정보를 공유하는 것과 관련하여 모바일 기기 및 모바일 브라우저 설정을 조정할 수 있습니다. 모바일 서비스 제공업체 또는 모바일 기기 제조업체에서 제공하는 안내사항을 참조하세요.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.4.</div>
                  <div>
                    <strong>위치 데이터.</strong> 모바일 기기에서 위치 서비스를 활성화하는 경우 여러분의 허락을 받아 당사는 여러분의 기기의 위치 정보를 수집합니다. 이 정보는 위치 기반 정보와 광고를 제공하기 위해 사용됩니다. 이 기능을 비활성화하려면 모바일 기기에서 위치 서비스를 비활성화할 수 있습니다.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.5.</div>
                  <div>
                   <strong>이메일 관리.</strong> 여러분은 여러 가지 이유로 당사로부터 이메일을 받을 수 있습니다. 예를 들어 서비스를 통해 특정 조치를 취한 경우, 정기 보고서를 구독한 경우, 디자인을 완료한 경우 등이 있습니다. 여러분은 계정 설정을 통해 이메일 환경을 수정할 수 있습니다. 또한, 당사로부터 받는 일부 유형의 통신을 관리하기 위해 이메일에 포함된 지침을 따라 관리할 수 있습니다. 다만 중요한 거래 또는 관리적 정보와 관련된 이메일은 해지를 하더라도 여전히 수신될 수 있음을 참고하세요.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.6.</div>
                  <div>
                    <strong>면책 조항.</strong> 여러분이 웹 사이트 이용 과정에서 타 사용자에게 개인 데이터를 자발적으로 공개하는 경우, 이에 대해 당사는 책임을 지지 않습니다.
                  </div>
                </div>
              </div>
            </div>
          `
    },
    {
      title: '사용 목적',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    제공하신 개인 데이터는 우리(또는 우리를 대신하여 제3자(예: Google Analytics와 같은))에 의해 집계 또는 개별적으로 사용되고 처리될 수 있으며, 다른 정보와 결합될 수 있습니다. 이러한 목적은 웹사이트의 관리 및 운영을 목적으로 하며, 아래와 같은 범위를 포함할 수 있습니다:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>웹사이트 관리;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>웹사이트 개인화를 통한 브라우징 경험 개선;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>웹사이트에서 이용 가능한 서비스 활성화;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>특정 서비스를 제공하기 위한 요청에 대한 서비스 제공;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>이메일이나 웹사이트에 제출한 양식을 통한 문의나 요청에 대한 응답;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>웹사이트 사용 또는 우리가 제공하는 서비스와 관련된 불만 사항에 대한 응답과 후속 조치;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>이메일, 음성 통화, SMS 및 앱 알림 등 모든 통신 채널을 통한 일반적인 상업적 통신 전송;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>사무 및 운영 관리 업무 수행;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>사용자에 관한 통계 정보를 제3자와 공유합니다. 이 통계 정보는 웹사이트에서 광고를 개인화하는 데 사용될 수 있지만 개별 사용자를 식별할 수 없습니다;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>온라인 계정의 생성 및 관리;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>웹사이트에서의 활동 및 거래 지원;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>콘테스트 또는 프로모션 참가 지원;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>결제 처리 또는 환불 처리;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>우리로부터 요청한 서비스에 관한 정보 제공; 또는 사전 동의를 받은 경우 이메일 또는 문자 메시지를 통해 흥미로운 정보 제공;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>비즈니스 운영, 평가 및 개선(새로운 제품 및 서비스 개발; 서비스 분석 및 강화);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>고객의 이해 및 데이터 분석 및 기타 처리(시장 및 소비자 조사, 트렌드 분석 및 익명화);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>의견 또는 질문 또는 채용 신청에 대한 응답;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>서비스 제공 업체와 공유하여 그들이 서비스를 제공할 수 있도록 하며, 기타 법률에 따라 요구되거나 허용되는 경우.</div>
                      </div>
                    </div>
                  </div>
                </div>
                개인 데이터를 노출하지 않고 웹사이트를 둘러보고 제한된 기능을 사용할 수 있습니다. 웹사이트 이용 시 개인 데이터를 제공하는 것은 웹사이트를 이용하는 조건으로 필수적이지 않으며, 우리가 제공하는 서비스를 제공하고 요청에 응답하거나 직접 문의하신 경우에 필요한 경우가 있습니다.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>우리가 개인 데이터를 수집, 이용, 공개 또는 처리하는 목적은 상황에 따라 다르므로, 위에 나열된 목적에 한정되지 않을 수 있습니다. 그러나 개인 데이터의 처리가 개별적인 동의 없이 수행되는 경우는 개인 데이터 보호법 2012(싱가포르) 또는 기타 적용 법률에 의해 허용되는 경우에 한합니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>통화 제한 조항.</strong>고객이 전화번호("번호")를 제공하고 번호를 통한 마케팅 또는 기타 프로모션 정보 수신에 동의한 경우, 우리는 해당 번호를 사용하여 서비스에 관한 정보를 제공할 수 있습니다. 이러한 번호는 무시 등록된 경우에도 연락할 수 있습니다(해당 지역의 규정에 따라). 그러나 해당 목적을 위해 우리로부터의 전화 연락을 원하지 않는 경우 서면으로 통지하시면 됩니다.</div>
              </div>
            </div>
          `
    },
    {
      title: '정확성과 보안',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>당신은 우리에게 제출하는 모든 개인 데이터가 완전하고 정확하며 진실하고 올바른지 확인해야 합니다. 이를 어기면 우리가 요청한 서비스를 제공할 수 없을 수 있습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>당사가 당신의 개인 데이터를 당신에게 영향을 미치는 결정을 내릴 때나 다른 기관에 공개할 가능성이 있는 경우, 당사는 합리적인 노력을 다해 당신의 개인 데이터가 정확하고 완전한지 확인합니다. 그러나 이는 당신이 최초로 당사에 제공한 개인 데이터의 변경 사항도 우리에게 알려주어야 함을 의미합니다. 당신이 당사에 당신이 최초로 제공한 개인 데이터의 변경 사항을 알리지 않음으로써 발생하는 부정확하거나 불완전한 개인 데이터에 대해 우리는 책임지지 않습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>당사는 당신의 개인 데이터의 손실, 오용 또는 변경을 방지하기 위해 합리적인 기술적 및 조직적 조치를 취합니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>당사는 당신이 제공한 모든 개인 데이터를 암호화된 형식으로 안전한 서버에 저장합니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>그러나 당신은 인터넷을 통한 정보 전송이 완전히 안전하지 않다는 점을 이해해야 합니다. 우리는 당신의 개인 데이터를 보호하기 위해 최선을 다할 것이지만, 당신이 웹사이트로 전송하는 개인 데이터의 보안을 보장할 수 없습니다. 모든 전송은 당신의 책임하에 이루어집니다. 또한 우리의 통제 범위를 벗어난 요인으로 인한 제3자의 무단 사용에 대해 책임지지 않습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>당사가 웹사이트의 특정 부분에 접근할 수 있게 해주거나(또는 선택한 경우) 비밀번호를 제공한 경우, 당신은 이 비밀번호를 비밀로 유지할 책임이 있습니다. 당사는 다른 사람과 비밀번호를 공유하지 말도록 요청합니다. 당사는 웹사이트에 로그인할 때를 제외하고 당사가 당신에게 비밀번호를 요청하지 않을 것입니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>당사의 서비스를 사용한 후 공유되거나 보안이 취약한 장치에서는 강력한 비밀번호를 선택하고 비밀로 유지하며 사용자 계정에서 로그아웃하고 웹 브라우저를 닫는 등 개인 정보의 안전을 유지하는 조치를 취할 것을 권장합니다.</div>
          </div>
        </div>
      `
    },
    {
      title: '개인 정보 공개',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>직접적인 마케팅 목적으로 개인 정보를 제3자에게 공개하는 경우에는 명확한 동의 없이 이루어지지 않습니다. 마케팅 커뮤니케이션을 수신하려는 경우 제3자에게 개인 정보를 공개하는 동의는 저희의 등록 양식을 통해 얻어집니다(온라인 또는 종이 양식 상관없이).</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              웹사이트를 관리하고 운영하기 위해, 귀하의 개인 정보를 당사 그룹의 구성원(즉, 관련 회사 및 자회사)에게 공개할 수 있습니다. 또한, 다음과 같은 목적으로 서비스 제공 업체 및 전문 상담가와 같은 제3자에게 귀하의 개인 정보를 공개할 수 있습니다:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>적용 가능한 법률 또는 규제에 기반한 목적으로;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>법적 요건을 준수하기 위해;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>당사의 이용 약관을 준수하거나 적용하기 위해;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>당사, 당사의 관련 회사 및 자회사, 웹사이트 사용자 및 다른 개인의 권리, 재산 및 안전을 보호하기 위해;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>어떤 법적 절차 또는 계획 중인 법적 절차와 관련하여;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>비즈니스 자산 거래(즉, 조직 또는 조직의 일부 또는 조직의 어떤 비즈니스 또는 자산의 매수, 매도, 임대, 합병 또는 합병, 또는 다른 거래, 양도 또는 대여를 포함함)과 관련하여, 저희가 당사자 또는 이해당사자 중 하나인 경우 또는 당사자 또는 이해당사자 중 하나가 될 가능성이 있는 경우.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              우리는 귀하의 동의 없이 개인 정보를 제3자에게 공개하지 않습니다. 그러나 일부 상황에서는 다음과 같은 경우에 한하여 귀하의 동의 없이 개인 정보를 제3자에게 공개할 수 있습니다:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>적용 가능한 법률 및/또는 규정에 따라 개시가 필요한 경우;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>공개의 목적이 명확히 귀하의 이익에 대응되며, 적절한 타이밍에서 동의를 얻을 수 없는 경우;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>생명, 건강 또는 안전을 위협하는 긴급한 상황에 대응하기 위해 공개가 필요한 경우;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>조사 또는 법적 절차와 관련하여 공개가 필요한 경우;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>공식적인 집행 기관의 담당자에게 개인 정보를 공개하는 경우;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>공공 기관의 목적을 위해 공개가 필요한 경우; 그리고/또는</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>PDPA 또는 법률에 의해 이러한 공개가 허용되는 경우.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              귀하의 동의 없이 개인 정보를 제3자에게 공개할 때에는, 그 제3자에게 귀하의 개인 정보를 보호하도록 최선을 다하겠습니다.
            </div>
          </div>
        </div>
      `
    },
    {
      title: '동의 철회',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>고객 서비스로 연락하여 <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a> 로 동의를 철회할 수 있습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>고객의 개인 데이터에 대한 모든 또는 일부 동의를 철회하는 경우, 요청의 성격에 따라 서비스를 계속 제공할 수 없을 수 있습니다. 고객의 통지에 따라, 동의 철회의 가능한 결과, 당사와의 모든 계약의 종료 및 계약상 의무 또는 약정 위반에 대한 가능성을 포함하되 이에 국한되지 않음을 알려드립니다. 이와 같은 사건에서 당사의 법적 권리와 구제 조치는 명시적으로 보존됩니다.</div>
              </div>
          </div>
      `
    },
    {
      title: '국제 데이터 이전',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>우리는 여러 국가에서 운영하며 우리가 운영하는 국가 간에 임시로 고객의 개인 데이터를 저장, 처리 또는 전송할 수 있습니다. 이는 이 개인 정보 보호 정책과 이용 약관에 따라 고객의 개인 데이터를 사용할 수 있게 하는 데 도움이 됩니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>우리는 적용 가능한 법률 또는 규정에 따라 고객의 개인 데이터가 이전되도록 필요한 모든 합리적인 조치를 취하며, 고객의 개인 데이터의 외국 수취 조직이 PDPA에 따른 보호와 적어도 비슷한 보호 수준을 제공하도록 법적으로 구속되도록 필요한 모든 합리적인 조치를 취하겠습니다.</div>
              </div>
          </div>
      `
    },
    {
      "title": "제3자 웹사이트",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  본 웹사이트에는 때로는 파트너 네트워크와 공급업체의 웹사이트로의 링크가 포함될 수 있습니다. 이러한 웹사이트로의 링크를 따라가는 경우 이러한 웹사이트에는 고유한 개인정보 처리 방침이 있으며, 우리는 이러한 정책에 대해 어떠한 책임도 지지 않습니다. 이러한 웹사이트로 개인 데이터를 제출하기 전에 해당 정책을 확인하시기 바랍니다.
                </div>
              </div>
            `
    },
    {
      "title": "액세스, 삭제 및 수정",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>저희의 데이터 보호 담당자는 개인 데이터의 액세스, 삭제 또는 수정에 대한 요청이 적절하게 처리되도록 책임을 지고 있습니다.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>우리가 보유하는 귀하에 대한 정보에 대한 모든 요청 또는 개인 데이터의 수정 또는 삭제 요청은 다음 주소로 요청을 제출함으로써 이루어집니다: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>우리는 본 개인 정보 보호 정책을 준수하는 영어로 된 요청만 수용하고 조치합니다. 위의 조건을 충족시키지 않는 요청은 거부되며, 우리는 이러한 거부된 요청에 대해 어떠한 조치도 취하지 않습니다.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>우리는 PDPA에 명시된 이유로 인해 개인 데이터의 공개, 삭제 또는 수정을 거부할 수 있습니다.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>가능한 한 요청에 대한 답변은 요청을 접수한 날로부터 업무일 기준 열(10)일 이내에 이루어집니다. 그렇지 않은 경우 요청을 접수한 날로부터 업무일 기준 열(10)일 이내에 통지합니다.</div>
                </div>
              </div>
            `
    },
    {
      "title": "개인 데이터 보존",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>우리는 비즈니스 운영상 계속해서 정당한 필요성이 있는 경우 귀하로부터 수집한 개인 데이터를 보존합니다. 귀하가 클라이언트 또는 공급업체 (또는 클라이언트 또는 공급업체의 대리인)인 경우 해당 정보는 우리가 서비스를 제공하고, 관련 법적, 세무 또는 회계 요구 사항을 준수하기 위해, 필요한 경우 법적 소송을 위해 보관됩니다.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>개인 정보를 처리하는 정당한 비즈니스 운영상의 필요성이 없어지면, 개인 정보를 삭제하거나 익명화하거나 (백업 아카이브에 개인 정보가 저장된 경우와 같이) 삭제가 불가능한 경우에는 안전하게 개인 정보를 보관하고 추가적인 처리로부터 분리합니다.</div>
                </div>
              </div>
            `
    },
    {
      "title": "문의하기",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      귀하의 개인 데이터 처리에 관한 자세한 정보가 필요한 경우 저희의 데이터 보호 담당자에게 언제든지 연락하십시오: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "법률 적용",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  본 개인 정보 보호 정책은 모든면에서 싱가포르 법에 따라 규정됩니다.
                  </div>
                </div>
              `
    }
  ]
};
