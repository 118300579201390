import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BuildOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 13" {...props}>
    <path d="M16.8906 0.560547H6.34375C5.99805 0.560547 5.71875 0.839844 5.71875 1.18555V5.79492H1.10938C0.763672 5.79492 0.484375 6.07422 0.484375 6.41992V11.7324C0.484375 12.0781 0.763672 12.3574 1.10938 12.3574H11.6562C12.002 12.3574 12.2812 12.0781 12.2812 11.7324V7.12305H16.8906C17.2363 7.12305 17.5156 6.84375 17.5156 6.49805V1.18555C17.5156 0.839844 17.2363 0.560547 16.8906 0.560547ZM7.04688 1.88867H10.9531V5.79492H7.04688V1.88867ZM5.71875 11.0293H1.8125V7.12305H5.71875V11.0293ZM10.9531 11.0293H7.04688V7.12305H10.9531V11.0293ZM16.1875 5.79492H12.2812V1.88867H16.1875V5.79492Z" />
  </SvgIcon>
);

export default BuildOutlinedIcon;
