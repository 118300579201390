export const TERM_ZH = (textBrand: string) => {
  return [
    {
      title: '接受条款',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>通过访问和使用 <a href="https://levitate.homes">https://levitate.homes</a> (以下简称"网站")，您与我们，${textBrand} International Pte. Ltd. (以下简称"我们"、"我们的"或"${textBrand}")，即网站的所有者和管理员，根据本《使用条款》订立了具有法律约束力的协议。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>您无条件接受本《使用条款》，包括隐私政策。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>我们可能随时通过在网站上发布修订版本来修改这些《使用条款》。但是，我们不会向您发送个别通知。任何修改后的《使用条款》将取代所有先前版本。如果您不同意《使用条款》的修改或变更，您应立即停止使用网站上的任何服务。在任何此类修改生效后，我们将认为您继续使用网站即表示您接受和同意该修改。您可以随时查看最新版本的《使用条款》。</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>我们将不时在网站上发布有关使用网站的指南和规则。所有这些指南或规则在此通过引用并纳入《使用条款》。</div>
            </div>
          </div>
        `
    },
    {
      title: '使用本网站',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>您同意遵守本使用条款下的所有条款和条件来使用本网站。如果我们完全酌情怀疑您违反了此处的条件，我们可能会删除或删除您在本网站上提供的任何内容。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>
              在与我们进行任何与本网站有关的交易或在本网站上注册帐户时，您保证：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>您在法律上有权进行交易；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>您使用的是您的真实身份；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>您向我们提供的个人数据是真实、准确、完整和最新的；以及</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>您将维护并及时更新您的个人资料，并确保该信息保持真实、准确、完整和最新。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>未成年人（根据您所在国家法律规定的年龄以下的用户）不得在未监督的情况下使用本网站或在本网站上提供任何个人数据。如果您是未成年人，只有在获得您的父母/法定监护人的同意或在父母/法定监护人的监督下，您才可以使用本网站和/或在本网站上提供任何个人数据。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>如果我们为您提供（或您选择）密码以使您能够访问本网站的某些部分，您承认您完全负责保守您的密码，并对在您的帐户下发生的所有活动负全责。我们无需核实登录并使用本网站的用户是否为您本人。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.5.</div>
            <div>您同意立即通知我们您的密码安全遭到侵犯的情况。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.6.</div>
            <div>由于您未遵守本条款第2条而导致的任何损失或损害，我们概不负责。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.7.</div>
            <div>未经我们的明确书面同意，您不得框架或使用框架技术来囊括我们和我们的附属公司（包括母公司，“附属公司”）的商标、标识或其他专有信息（包括图像、文字、页面布局、表单、描述、概念、思想、设计、绘图、图像、照片或任何其他内容）。任何未经授权的使用将终止我们授予的许可或同意。</div>
          </div>
        </div>
      `
    },
    {
      title: '网站材料',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>通过访问和使用网站，您可能接触到我们和/或其他网站用户可能在网站上上传、发布、发送电子邮件、传输或以其他方式提供的信息、数据、文字、软件、照片、图形、视频、信息、概念、思想、设计、图纸、产品列表、价格或其他材料（“网站材料”），无论是公开还是私下。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>您了解网站材料可能是冒犯性的、不端的或令人反感的。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>您了解网站材料仅可用于您个人和非商业用途，不得用于发布、分发、传输、转发、再分发、广播、复制或在同一公司或组织中向其他人员传播，也不得用于发布到其他网站或论坛、新闻组、邮件列表、电子公告板或其他由其他网站经营的Internet Relay Chat。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>您同意未经我们事先书面同意，不得在其他网站或服务器上复制、重复、复制、出售、转售、展示或以其他方式提供网站材料。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>在任何情况下，我们不对任何网站材料中的错误或遗漏负责，也不对因使用网站上发布、发送、传输或以其他方式提供的任何网站材料而导致的任何损失或损害负责。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>您承认并同意，网站的格式和内容可能随时更改，无需通知；网站的运营可能因支持或维护工作、更新内容或其他任何原因而暂停或终止；对网站的访问可能随时终止，无论是针对特定用户还是总体上，无需通知。</div>
          </div>
        </div>
      `
    },
    {
      title: '我们网站上信息的准确性',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>虽然${textBrand}努力在我们的网站上包含准确的信息，但我们不对所提供的任何信息的准确性提供任何担保或陈述，无论是暗示的还是其他方式，均在法律允许的范围内。 ${textBrand}在法律允许的最大范围内排除对网站内容中任何错误或遗漏的所有责任。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>网站上包含的细节仅供参考。网站上包含的任何细节，描述，尺寸，对条件的参考，必要的使用和占有权限以及其他详细信息的准确性不能保证，仅供一般指导，并建议您不要依赖它们作为陈述事实或陈述，并请自行核实其准确性。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand}建议，由于购买房地产是重大的财务承诺，您应采取一切合理措施确保您打算购买的任何房地产在各方面都适合您的需求。我们已采取合理措施确保网站内的材料准确，但这不应视为您的查询和考虑的替代品。建议您采取适当措施来核实您希望依赖的任何信息。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>尽管我们尽一切努力在我们的网站上提供每种产品的准确色彩和设计，但实际色彩和设计可能会因不同设备屏幕设置，安装位置的照明，产品表面在时间上的轻微变化和其他因素而略有不同。对于不是我们的过错导致的任何颜色或设计差异，${textBrand}不承担任何责任。当您通过我们的网站购买房产或产品时，您同意接受在实际色彩和设计与我们网站上的呈现之间存在轻微差异的小风险。</div>
          </div>
        </div>
      `
    },
    {
      title: '用户义务',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1。</div>
            <div>
              除了在本《使用条款》中规定的义务外，您不得：
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>违反任何适用的法律法规使用本网站；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>以损害、滥用、骚扰、跟踪、诽谤、诬蔑、威胁或以其他方式冒犯他人的方式行事，或使用本网站从事此类行为；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>采取可能给用户、第三方或${textBrand}带来不便、不利或损害的行动；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>进行欺诈申请或持有多个会员注册；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>从事任何不道德活动或潜在的不道德活动，包括但不限于卖淫和色情活动；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>冒充任何个人或实体，或虚假陈述或以其他方式误导您与某个个人或实体的关联；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>提供虚假、不准确或误导性信息；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>h.</div>
                  <div>从事潜在的欺诈、可疑或非法活动和/或交易；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>i.</div>
                  <div>向任何用户发送未经请求的电子邮件，或使用本网站的服务收取、或帮助收取未经请求的电子邮件的付款；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>j.</div>
                  <div>未经该用户明确同意，披露或向第三方分发其他用户的个人信息，或将这些信息用于营销目的；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>k.</div>
                  <div>采取对本网站的基础设施施加不合理或不成比例的巨大负荷的任何行动；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>l.</div>
                  <div>上传、或导致上传到本网站包含病毒或任何其他可能损害、干扰或对本网站访问造成不利影响的物品的任何内容；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>m.</div>
                  <div>参与或导致其他用户参与垃圾邮件、网络钓鱼、不当、恶意或在我们的绝对判断下，虚假点击、印象或与${textBrand}相关的营销活动；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>n.</div>
                  <div>在我们的绝对判断下，影响或损害${textBrand}的声誉；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>o.</div>
                  <div>未经我们书面授权或法律许可，修改、适应、重新格式化、重新编译、传输、发布、许可、反向工程、拆解、重构、反编译、复制或创建派生作品、转让或销售本网站的任何服务或其中的任何部分，包括源代码或算法；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>p.</div>
                  <div>更改、删除、覆盖或以其他方式玷污网站上任何服务的任何标识、商标、版权或其他通知；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>q.</div>
                  <div>使用任何未经授权的第三方软件，旨在访问、抓取或收集${textBrand}的服务；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>r.</div>
                  <div>使用任何未经授权的第三方软件拦截、"挖掘"或以其他方式从${textBrand}获取信息；</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>s.</div>
                  <div>
                    上传、传输、张贴或以其他方式提供任何可能合理地期望：
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>I.</div>
                        <div>具有攻击性、歧视性、诽谤、淫秽、威胁、滥用、不端或以其他方式非法的内容，包括基于种族、宗教、族裔、性别、年龄、性取向或任何身体或精神残疾而可能冒犯、侮辱或羞辱他人的内容；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>II.</div>
                        <div>不是您的原创作品，或可能侵犯其他人的知识产权或其他权利的内容；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>III.</div>
                        <div>包含另一个人的图像或个人信息，除非您获得了他们的同意；或</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>IV.</div>
                        <div>包含大量不经过滤的、不需要或重复的内容；或</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>t.</div>
                  <div>干扰、中断或对网站或我们的业务施加过度负担。</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>如果您认为某个用户违反了以上任何条件，请与我们联系。</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2。</div>
            <div>我们保留权利，随时封锁、暂停或取消任何用户、我们网站上的会员注册，并修改或删除任何用户上传、发布、传输或以其他方式提供在本网站上的任何材料，而无需提前通知；完全自主决定和限制，拒绝和/或拒绝客户的退货，因为退货记录异常或过多。通过上传、传输、张贴或以其他方式在本网站上提供任何材料，您授予${textBrand}非排他性、全球范围、免费的、永久的许可，以任何形式和任何目的使用、复制、编辑和利用该材料。如果申请人过去曾取消其会员资格，其会员申请可能不会获得批准。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3。</div>
            <div>我们对任何非我们自己上传、发布、传输或以其他方式在本网站上提供的任何材料不负责任，也不承担任何责任。我们不认可任何由${textBrand}以外的任何人作出的意见、建议或陈述。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4。</div>
            <div>您同意赔偿${textBrand}及其每个责任人、员工、代理商、承包商、供应商和许可方（统称为"联属公司"）因您或您的任何联属公司违反或未能遵守本《使用条款》的任何规定，或在与本《使用条款》的对象有关的任何其他违规行为或不当行为导致的所有责任、损失或损害（包括所有法律和其他成本，完全补偿）而遭受或产生。</div>
          </div>
        </div>
      `
    },
    {
      title: '免责声明',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>您对本网站的使用完全由您自行承担风险。我们对任何种类的担保，无论是明示还是暗示，一概不予承认。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>我们保留随时修改或暂时或永久地中止本网站的任何服务（或其任何部分）的权利，无论是否提前通知。您同意我们对于此类服务的任何修改、暂停或中止对您或任何第三方均不承担责任。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    我们不作以下保证：
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>本网站将满足您的要求；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>本网站将是不间断的、及时的、安全的和无错误的；</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>通过使用本网站获得的任何结果将是准确或可靠的；以及</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>您通过本网站购买或获取的任何产品、服务、信息或其他材料的质量将满足您的期望。</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>我们不会并且不能代表，也不能对个人、用户和组织的观点、意见、研究和推荐做出认可，并且对任何特定服务提供商、治疗过程或证券、投资或证券或投资类别的任何商业交易进行倡导，而网站上包含对其他网站或资源的链接并不意味着我们的任何形式认可。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>我们不主动审查或编辑用户的消息、观点、意见、研究和推荐，即使这些消息、观点、意见、研究和推荐是用户通过本网站发表的。虽然本网站上的信息来自被认为是可靠的来源，但任何数据、新闻、信息、报告、观点和推荐（如果有的话）都没有针对任何特定人士或类别的人进行定制。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>通过在本网站上提供信息和数据，包括个人和组织的观点、意见、研究和推荐，我们及我们的内容提供者不提供金融、投资、税务和法律建议，并且不以任何方式明示或暗示提供或代表提供此类建议。在做出任何此类决定之前，您应该始终寻求相关的专业建议。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>我们可能会监视或审查您传输或发布通信的本网站上的某些区域，以促进其使用的效率、有用性和适用性。但是，我们不保证监视或审查所有发布或通信，对于所有网站材料，无论是否产生于版权、商标、诽谤、隐私、淫秽或其他法律下，我们均不承担任何责任。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  本网站及其中包含的所有信息均由${textBrand}按"现状"和"可用性"的原则提供。${textBrand}和/或其各自的关联公司、股东、合作伙伴、官员、董事、雇员和代理商：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>对本网站的运行和可用性以及网站上呈现的信息、内容、材料或产品不作任何明示或暗示的陈述或保证。您明确同意您对本网站的使用完全由您自行承担风险，最大限度地受到适用法律的允许；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>拒绝所有明示或暗示的保证，包括但不限于适销性和特定用途的默示保证；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>不保证本网站、其服务器或我们发送的电子邮件不含病毒或其他有害组件或错误；以及</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>对因（a）使用或无法使用本网站；（b）任何一方依赖网站内容做出的决定、行动或不作为；（c）业务中断；（d）访问本网站的延迟/中断；（e）数据不传递、错误传递、损坏、破坏或其他修改；（f）访问本网站上的第三方链接而导致的任何损失或损害；（g）计算机病毒，与使用本网站有关的系统故障或故障；或（h）本网站内容的延迟、不准确、错误或遗漏而导致的任何损害，不承担任何责任。</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>通过访问我们的网站，您承担与其使用相关的所有风险，包括但不限于计算机、设备、软件或数据可能因通过本网站或任何第三方内容或第三方网站传输的病毒而受到损害的风险，以及与服务提供商（如有）提供的服务质量相关的风险。在法律允许的范围内，特此排除对本网站的所有明示或暗示的保证、条件和索赔。</div>
              </div>
          </div>
        `
    },
    {
      title: '免责条款',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>您同意赔偿并使${textBrand}，以及我们的附属公司，股东，合作伙伴，官员，董事，员工和代理商免受任何索赔或要求的损害，包括律师费，由任何第三方因您提交，发布，传输或提供在网站上的任何内容，您对网站的使用，您与网站的连接，您违反使用条款，或您侵犯他人的任何权利而引起或产生的。</div>
              </div>
          </div>
        `
    },
    {
      title: '责任限制',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  对于任何直接、间接、附带、特殊、后果性或惩罚性损害，包括但不限于利润损失、商誉损失、使用损失、数据损失或其他无形损失（即使我们已被告知可能发生此类损害），我们对您不负责，这些损害是由以下情况引起的：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>使用或无法使用网站；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>通过网站购买或获得的任何产品、数据、信息或服务，或收到的信息，或通过网站进行的交易，导致的替代产品和服务的采购成本；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>未经授权访问或更改您的传输或数据；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>网站上任何第三方的陈述或行为；以及</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>与网站相关的任何其他事项。</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>任何一方针对另一方的任何性质的索赔以及任何针对另一方的诉讼必须在引起该索赔的原因发生后的03个月内（或依据适用法律的最低允许期限）提起，此后，受损害方将不再对另一方提起任何进一步的索赔。</div>
              </div>
          </div>
        `
    },
    {
      title: '链接和第三方内容',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>该网站可能包含超链接，用于引用和信息目的，链接到由第三方运营的网站。${textBrand} 未经审查，不能对其他网站的隐私政策和做法负责，即使您通过网站上的链接访问它们。我们建议您检查您访问的每个网站的条款、条件和隐私政策，并在您有任何担忧或问题时与其所有者或运营者联系。同样，如果您通过第三方网站链接到本网站，${textBrand} 无法对该第三方网站的隐私政策和做法负责，并建议您检查该第三方网站的政策，并在您有任何担忧或问题时与其所有者或运营者联系。${textBrand} 对于任何链接的第三方网站不提供任何保证或陈述。${textBrand} 在法律允许的最大范围内，排除对因任何此类网站上的内容而产生的损失或损害的所有责任。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>为明确起见，存在指向第三方网站的链接不意味着 ${textBrand} 就这些网站或其中的任何建议或建议或信息作出认可。</div>
              </div>
          </div>
        `
    },
    {
      title: '隐私政策',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>关于您的所有个人数据都受到我们的隐私政策的约束。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>通过对本条款的引用，隐私政策被视为并入使用条款。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>如果网站的任何用户或其他人查看 ${textBrand} 发布的文档并以任何信息或反馈回应，例如问题、评论、建议或类似内容，该信息将被视为非机密信息，${textBrand} 有权将该信息用于任何其他人。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>此外，${textBrand} 有权免费使用或利用该信息/反馈中包含的任何想法、概念、技术或技巧，包括但不限于开发、制造和推广包含该信息的产品。</div>
              </div>
          </div>
        `
    },
    {
      title: '知识产权和版权',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>您确认并接受网站的设计、布局、流程、功能、数据和内容中所涉及的所有版权、数据库权利以及其他知识产权归属于${textBrand}，我们的信息提供者或许可方。网站由${textBrand}所有并运营。未经${textBrand}事先书面同意，您不得复制或复制网站的任何部分、内容或其中任何基础软件和数据库，也不得与第三方共享、进行反向工程、反汇编或用于创作任何衍生作品（无论是物理形式还是电子形式）。</div>
              </div>
          </div>
        `
    },
    {
      title: '不可抗力',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  对于因我们无法控制的原因导致的任何延迟或未能履行向您提供的义务，包括但不限于天灾、战争或恐怖主义、流行病、大流行病、自然灾害、互联网连接问题、系统问题、适用法律、法规或政府政策的变更，以及供应和服务短缺，我们不对您负责。
              </div>
          </div>
        `
    },
    {
      title: '终止',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  我们可能在特定情况下且无需事先通知，在我们自行决定的情况下立即终止您的账户（若有）。此类终止的原因包括但不限于：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>违反使用条款、其他合并文件、指南或规则；</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>执法部门或其他政府机构的要求；</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>自行删除账户；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>侵犯他人知识产权；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>网站服务的终止或实质性修改，或网站的部分终止或实质性修改；</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>意外的技术或安全问题；或</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>长时间的不活跃。</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  您的账户终止将导致：
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>删除所有与该账户关联的网站提供的服务的访问权，并禁止进一步使用；以及</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>删除您的密码以及与您的账户（或其部分）相关的所有信息、文件和内容。</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>您的账户终止不影响我们根据使用条款、法律或公平原则可能享有的其他权利或救济，也不影响任何已积累的权利或责任，亦不影响任何旨在在该终止后明确或暗示生效或继续生效的条款的生效或继续生效。</div>
              </div
          </div>
        `
    },
    {
      title: '知识产权',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>在您将任何图像、照片和/或列表描述上传到网站上（“<strong>内容</strong>”），您将授予我们全球范围内、免费、不可撤销的使用许可，用于使用、存储、托管、复制、修改、适应、加水印、沟通、发布、公开执行、公开展示、创建衍生作品和分发内容。通过上传内容，您保证您拥有内容的版权，因此有权按照所述向我们授予许可。我们有权在内容中添加水印，并且带有水印的最终照片的版权将完全属于我们。所有其他提及的名称、产品和商标均为其各自所有者的知识产权。未经我们明确的书面批准以及相应版权和商标持有人的批准，不得以任何方式和任何形式复制、修改、适应、分发、发布、展示、上传、发布、传输或超链接我们通过网站提供的任何材料，包括文本、图形、代码、评级、排名、数据库、汇总信息、网站材料和/或软件。网站和网站材料仅供您个人使用，您不得与其他方进行有关此类服务和内容的任何交易。此类交易包括商业交易和会影响网站和网站材料商业价值的交易。</div>
              </div>
          </div>
        `
    },
    {
      title: '一般条款',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>使用条款将受新加坡共和国法律管辖，并根据该法律解释，任何性质的索赔或争议将受新加坡共和国法院的专属管辖权。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>我们未行使或强制执行使用条款中的任何权利或规定，不构成对该权利或规定的放弃。如果使用条款的任何规定被有管辖权的法院认定为无效，则各方仍同意法院应努力实现各方在该规定中反映的意图，并且使用条款的其他规定仍然完全有效。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>您在我们处可能拥有的任何帐户都不可转让，您对该帐户的所有权将在您死亡时终止。收到您死亡证明的副本后，您的帐户将被终止，并且您在帐户中生成的所有内容将被永久删除。</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>根据使用条款，所有对我们的通知应通过电子邮件发送至：<a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。</div>
              </div>
          </div>
        `
    }
  ]
};
