export const getLocationIPApi = () => {
  return fetch(' https://www.cloudflare.com/cdn-cgi/trace')
    .then(function (response) {
      if (!response.ok) {
        return {};
      }
      return response.text();
    })
    .then(function (response: any) {
      return response.split('\n').reduce((acc: any, value: any) => {
        const t = value.split('=');
        return {
          ...acc,
          [t[0]]: t[1],
        };
      }, {});
    })
    .catch(function (error) {
      return {};
    });
};
