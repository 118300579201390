export const DATA_FAQ_EN = (textBrand: string) => {
  return [
    {
      title:
        `Who on earth is ${textBrand} International and what in the world do they bring to the table?`,
      description: `
            <p>Alright, let's talk about the primary property market and the boring, outdated sales experience that comes with it. ${textBrand} International is here to change the game. We're here to shake things up, my friend. </p>
            <p>We're co-developing the homes of the future. How do we do it? By harnessing the power of personalization technology. Yeah, that's right. ${textBrand} introduces a mind-blowing 3D interior design tool driven by freakin' A.I. Yeah, you heard me right. This bad boy lets you navigate your living space in real-time, room by room, and premodel your future home.</p>
            <p>With ${textBrand}, you'll embark on a journey that's smooth, enjoyable, and transparent. No more surprises, no more delays. It's a seamless, efficient journey towards your dream home.</p>
           `,
    },
    {
      title:
        'What is a Segment and how does it work when it comes to purchasing my new home?',
      description: `
            <p>Alright, my friend, let's talk segments and how they work their magic in the world of ${textBrand}'s home purchasing journey.</p>
            <p>A segment is like a piece of the puzzle that can be combined to create a space that caters to your desires and budget.</p>
            <p>Think of it as a distinct size of living area that you can acquire individually or in multiples, personalizing your new home to meet your every need and desire.</p>
           `,
    },
    {
      title:
        'Why is a Reservation Agreement so darn important in the buying process?',
      description: `
            <p>Alright, let's talk about this thing called a Reservation Agreement. You might be thinking, "What is that, and why should I even care?" Well, my friend, let me break it down for you. This bad boy is a crucial element in the wild ride of buying a property. Why? Because it's like a freakin' safety net, my friend.</p>
            <p>When you sign a Reservation Agreement, you're basically saying, "Hold up, I've got my eyes on this property, and I want to make sure it's mine."</p>
            <p>Why is that so important? Simple. It gives you peace of mind and protects your interests. It ensures that the property won't be sold out from under you while you're busy dotting the i's and crossing the t's.</p>
           `,
    },
    {
      title:
        'What exactly is included in a Sales and Purchase Agreement and what does it entail in the realm of home purchasing?',
      description: `
            <p>A Sales and Purchase Agreement is like the holy grail of a home purchase. It's the document that seals the deal and protects your ass in the process.</p>
            <p>Let me break it down for you: when you're buying a home, you don't just hand over your cash and hope for the best. That's where this bad boy comes in. It's a legally binding agreement between you and the seller that spells out all the important stuff. We're talking about the price of the house, the conditions of the handover, and the timeline for closing the deal. </p>
            <p>This ain't some casual handshake agreement, my friend. It's a serious commitment that ensures both parties are held accountable. So, when you're ready to make that big purchase, make sure you've got a solid Sales & Purchase Agreement in place. It's your safety net in the wild world of home buying.</p>
           `,

    },
    {
      title:
        `How can you turn your wildest home-buying dreams into a reality through the mysterious powers of ${textBrand} International?`,
      description: `
            <p>So, you're dying to know how you can make your dream home a reality through ${textBrand} International? Well, buckle up, my friend, because I'm about to lay it out for you.</p>
            <p><strong>Step one:</strong> choose your desired country. Where do you see yourself living your best damn life? Take your pick and let's dive in. </p>
            <p><strong>Step two:</strong> we've got a smorgasbord of projects listed, just waiting for you to explore. Get lost in the details, envision yourself in those spaces, and imagine the life you'll be living.</p>
            <p><strong>Step three:</strong> narrow it down, my friend. Select the amount segments you can afford and floor that speaks to your soul.</p>
            <p><strong>Step four:</strong> This is where it gets exciting. Now, get ready for the mind-blowing part. You can pre-model your apartment in stunning, instant 3D. Yeah, we're talking about bringing your dream home to life right before your eyes. Tweak it, play with it, and make it perfect. Oh, and did I mention you'll have full visibility and transparency on the costs? No shady business here.</p>
            <p><strong>Step five:</strong> Once you're satisfied with your masterpiece, it's time to seal the deal. Sign your reservation agreement and purchase agreement online, and voila! </p>
            <p>Now, sit back, my friend, and let the anticipation build. Soon enough, you'll be kicking back in the home you've always fantasized about. ${textBrand} is here to make it happen.</p> 
          `,
    },

    {

      title:
        'How can you join the waiting list and stay in the loop on the soon-to-be-launched properties?',
      description: `
            <p>So you're eager to get your hands on those elusive "Coming Soon" properties, huh? I don't blame you. Well, here's the secret to staying updated and getting ahead of the game.</p>
            <p>Head on over to our website and scout for the section that says "Stay Informed." It's like a treasure trove for homebuyers like yourself. Look for the option to join the waiting list. Enter your email address, and consider yourself officially in the loop.</p>
            <p>We'll make sure you're the first to know when those exciting projects are ready to rock and roll. It's like having a backstage pass to the property world. So don't waste another second, my friend. Join the waiting list and let the anticipation begin</p>
          `,
    },

    {
      title:
        `How does ${textBrand} International prioritize the protection of your valuable data, and what partnerships do they have in place to ensure secure transactions?`,
      description: `
            <p>At ${textBrand} International, we don't mess around when it comes to data protection. Your information is like gold to us, and we guard it with all our might. We don't mess around with second-rate solutions. We've partnered with the best of the best to keep your data safe and sound.</p>
            <p>We've got Tazapay, a Singapore-based company that specializes in keeping your payment details under lock and key. But it doesn't stop there. We've taken things up a notch with the innovative technology of Pandadoc for digital contract signing. </p>
            <p>We leave no stone unturned, my friend. Your data is our top priority, and We've got the right partnerships in place to ensure that.</p>
          `,
    },
  ];
};
