import React from 'react';

const IcNavHide = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4543_45199)">
        <path
          d="M13.9746 15.598C11.3521 15.598 8.72915 15.5955 6.10666 15.6016C5.84042 15.6021 5.59641 15.5587 5.50901 15.2907C5.41252 14.9959 5.3726 14.675 5.58277 14.4029C5.64642 14.3207 5.77575 14.2798 5.88336 14.245C5.95965 14.2202 6.05008 14.2364 6.13394 14.2364C11.3708 14.2364 16.6077 14.2369 21.8446 14.2334C22.1018 14.2334 22.3882 14.2394 22.4564 14.5099C22.5241 14.7774 22.5064 15.0863 22.4478 15.3598C22.4054 15.5607 22.1781 15.6005 21.9689 15.6C20.1699 15.5965 18.3714 15.598 16.5724 15.598C15.7065 15.598 14.8405 15.598 13.9751 15.598L13.9746 15.598Z"
          fill="white"
        />
        <path
          d="M3.01476 8.67897C3.45883 9.16044 3.85187 9.58639 4.24441 10.0123C4.55814 10.353 4.53288 10.6043 4.14741 10.9798C3.85036 11.2695 3.573 11.271 3.2714 10.9728C2.42266 10.134 1.57645 9.29317 0.731257 8.45085C0.42359 8.144 0.423085 7.86138 0.728731 7.55403C1.57039 6.70818 2.41307 5.86385 3.25826 5.02153C3.54471 4.73588 3.83217 4.73588 4.11761 5.00992C4.49954 5.37582 4.54147 5.63724 4.253 5.95822C3.8544 6.40133 3.44822 6.83687 2.99657 7.32793C3.14712 7.32793 3.24159 7.32793 3.33556 7.32793C9.47272 7.32793 15.6099 7.32793 21.7465 7.32793C22.3659 7.32793 22.6933 7.86996 22.3785 8.41098C22.3068 8.53463 22.1224 8.60276 21.9779 8.6719C21.9092 8.70471 21.8122 8.67897 21.7284 8.67897C15.6079 8.67897 9.48788 8.67897 3.36739 8.67897L3.01324 8.67897L3.01476 8.67897Z"
          fill="white"
        />
        <path
          d="M13.9304 0.402697C16.5615 0.402697 19.1926 0.40522 21.8236 0.399164C22.0914 0.39866 22.3324 0.44711 22.4223 0.711564C22.5223 1.00731 22.5536 1.32829 22.3445 1.60031C22.2813 1.68207 22.152 1.72598 22.0439 1.75626C21.9499 1.7825 21.8438 1.76686 21.7428 1.76686C16.5392 1.76686 11.3362 1.76686 6.13264 1.76686C5.48852 1.76686 5.20055 1.34444 5.42638 0.729226C5.51782 0.480417 5.70777 0.399669 5.96088 0.400173C7.41534 0.403706 8.86931 0.402193 10.3238 0.402193C11.5256 0.402193 12.728 0.402193 13.9299 0.402193L13.9304 0.402697Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4543_45199">
          <rect
            width="22"
            height="15.2027"
            fill="white"
            transform="translate(22.5 15.6016) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcNavHide;
