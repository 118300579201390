import React, { useState } from 'react';

// COMPONENTS
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from './Dialog';
import { useTranslation } from 'react-i18next';

export type ConfirmDialogProps = Omit<DialogProps, 'buttons' | 'onClose'> & {
  confirmText?: string,
  cancelText?: string,
  message?: string;
  onConfirm: Function,
  onAfterClose?: Function,
  onCancel: Function,
  closeDialog?: Function,
}

const ConfirmDialog = ({
  confirmText,
  cancelText,
  message,
  onConfirm,
  onCancel,
  onAfterClose,
  closeDialog,
  children,
  ...restProps
}: ConfirmDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }
  
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
    
    if (onAfterClose) {
      onAfterClose();
    }
  }

  const handleCloseDialog = () => {
    if (closeDialog) {
      closeDialog()
    }
  }
  
  return (
    <Dialog
      buttons={[
        { label: cancelText || t('Cancel'), onClick: handleClose },
        {
          label: confirmText || t('Confirm'),
          color: 'accent',
          loading,
          onClick: handleConfirm,
        },
      ]}
      onClose={handleCloseDialog}
      {...restProps}
    >
      {message ? (
        <Typography mt={2} variant="body1">
          {message}
        </Typography>
      ) : children}
    </Dialog>
  );
};

export default ConfirmDialog;
