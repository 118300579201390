import React from 'react';

const MalaysiaOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41171)">
        <path
          d="M10.0068 13.0002L10.6139 8.00018H11.868L12.9259 11.513L13.9774 8.00018H15.2184L15.832 13.0002H14.5397L14.2142 10.3095L13.5363 13.0002H12.2822L11.61 10.3612L11.2926 13.0002H10.0068ZM5.83203 13.0002V8.00018H8.01709C8.22662 7.99756 8.43546 8.02431 8.63721 8.07962C8.82015 8.13055 8.9877 8.22405 9.12549 8.3521C9.27336 8.49591 9.38338 8.67253 9.44613 8.86687C9.52909 9.13047 9.56757 9.40548 9.56006 9.68114C9.56358 9.86368 9.54556 10.046 9.50635 10.2245C9.47642 10.3598 9.42653 10.4901 9.35824 10.6114C9.3004 10.7115 9.22586 10.8014 9.1377 10.8775C9.05315 10.9486 8.96186 11.0116 8.86507 11.0658L9.75456 13.0002H8.39632L7.67936 11.2771H7.16911V13.0002H5.83203ZM7.16911 10.2412H7.7762C7.84181 10.2423 7.90711 10.2321 7.96908 10.211C8.02398 10.1906 8.07269 10.157 8.11068 10.1133C8.15219 10.0632 8.18141 10.0045 8.19613 9.94171C8.21618 9.85897 8.22548 9.77409 8.2238 9.68909C8.2257 9.60538 8.21639 9.52179 8.19613 9.44044C8.18285 9.38092 8.15332 9.32602 8.11068 9.28155C8.07107 9.24282 8.02251 9.21394 7.96908 9.19735C7.90671 9.17818 7.84157 9.16906 7.7762 9.17034H7.16911V10.2412Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41171">
          <rect
            width="10"
            height="5"
            fill="white"
            transform="translate(5.83203 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MalaysiaOutlined;
