import { TCountryPhoneCode } from "constants/i18n";

export const COUNTRY_PHONE_CODE_OPTIONS_JA: TCountryPhoneCode[] = [
  {
    "text": "アルメニア +374",
    "value": "+374",
    "locale": "am-AM",
    "shortLocale": "AM"
  },
  {
    "text": "アラブ首長国連邦 +971",
    "value": "+971",
    "locale": "ar-AE",
    "shortLocale": "AE"
  },
  {
    "text": "バーレーン +973",
    "value": "+973",
    "locale": "ar-BH",
    "shortLocale": "BH"
  },
  {
    "text": "アルジェリア +213",
    "value": "+213",
    "locale": "ar-DZ",
    "shortLocale": "DZ"
  },
  {
    "text": "エジプト +20",
    "value": "+20",
    "locale": "ar-EG",
    "shortLocale": "EG"
  },
  {
    "text": "イラク +964",
    "value": "+964",
    "locale": "ar-IQ",
    "shortLocale": "IQ"
  },
  {
    "text": "ヨルダン +962",
    "value": "+962",
    "locale": "ar-JO",
    "shortLocale": "JO"
  },
  {
    "text": "クウェート +965",
    "value": "+965",
    "locale": "ar-KW",
    "shortLocale": "KW"
  },
  {
    "text": "リビア +218",
    "value": "+218",
    "locale": "ar-LY",
    "shortLocale": "LY"
  },
  {
    "text": "サウジアラビア +966",
    "value": "+966",
    "locale": "ar-SA",
    "shortLocale": "SA"
  },
  {
    "text": "シリア +963",
    "value": "+963",
    "locale": "ar-SY",
    "shortLocale": "SY"
  },
  {
    "text": "チュニジア +216",
    "value": "+216",
    "locale": "ar-TN",
    "shortLocale": "TN"
  },
  {
    "text": "ベラルーシ +375",
    "value": "+375",
    "locale": "be-BY",
    "shortLocale": "BY"
  },
  {
    "text": "ブルガリア +359",
    "value": "+359",
    "locale": "bg-BG",
    "shortLocale": "BG"
  },
  {
    "text": "バングラデシュ +880",
    "value": "+880",
    "locale": "bn-BD",
    "shortLocale": "BD"
  },
  {
    "text": "チェコ共和国 +420",
    "value": "+420",
    "locale": "cs-CZ",
    "shortLocale": "CZ"
  },
  {
    "text": "デンマーク +45",
    "value": "+45",
    "locale": "da-DK",
    "shortLocale": "DK"
  },
  {
    "text": "ドイツ +49",
    "value": "+49",
    "locale": "de-DE",
    "shortLocale": "DE"
  },
  {
    "text": "オーストリア +43",
    "value": "+43",
    "locale": "de-AT",
    "shortLocale": "AT"
  },
  {
    "text": "スイス +41",
    "value": "+41",
    "locale": "de-CH",
    "shortLocale": "CH"
  },
  {
    "text": "ギリシャ +30",
    "value": "+30",
    "locale": "el-GR",
    "shortLocale": "GR"
  },
  {
    "text": "オーストラリア +61",
    "value": "+61",
    "locale": "en-AU",
    "shortLocale": "AU"
  },
  {
    "text": "イギリス +44",
    "value": "+44",
    "locale": "en-GB",
    "shortLocale": "GB"
  },
  {
    "text": "ガーナ +233",
    "value": "+233",
    "locale": "en-GH",
    "shortLocale": "GH"
  },
  {
    "text": "香港 +852",
    "value": "+852",
    "locale": "en-HK",
    "shortLocale": "HK"
  },
  {
    "text": "マカオ +853",
    "value": "+853",
    "locale": "en-MO",
    "shortLocale": "MO"
  },
  {
    "text": "アイルランド +353",
    "value": "+353",
    "locale": "en-IE",
    "shortLocale": "IE"
  },
  {
    "text": "インド +91",
    "value": "+91",
    "locale": "en-IN",
    "shortLocale": "IN"
  },
  {
    "text": "ケニア +254",
    "value": "+254",
    "locale": "en-KE",
    "shortLocale": "KE"
  },
  {
    "text": "マルタ +356",
    "value": "+356",
    "locale": "en-MT",
    "shortLocale": "MT"
  },
  {
    "text": "モーリシャス +230",
    "value": "+230",
    "locale": "en-MU",
    "shortLocale": "MU"
  },
  {
    "text": "ナイジェリア +234",
    "value": "+234",
    "locale": "en-NG",
    "shortLocale": "NG"
  },
  {
    "text": "ニュージーランド +64",
    "value": "+64",
    "locale": "en-NZ",
    "shortLocale": "NZ"
  },
  {
    "text": "パキスタン +92",
    "value": "+92",
    "locale": "en-PK",
    "shortLocale": "PK"
  },
  {
    "text": "ルワンダ +250",
    "value": "+250",
    "locale": "en-RW",
    "shortLocale": "RW"
  },
  {
    "text": "シンガポール +65",
    "value": "+65",
    "locale": "en-SG",
    "shortLocale": "SG"
  },
  {
    "text": "シエラレオネ +232",
    "value": "+232",
    "locale": "en-SL",
    "shortLocale": "SL"
  },
  {
    "text": "タンザニア +255",
    "value": "+255",
    "locale": "en-TZ",
    "shortLocale": "TZ"
  },
  {
    "text": "ウガンダ +256",
    "value": "+256",
    "locale": "en-UG",
    "shortLocale": "UG"
  },
  {
    "text": "アメリカ合衆国 +1",
    "value": "+1",
    "locale": "en-US",
    "shortLocale": "US"
  },
  {
    "text": "南アフリカ +27",
    "value": "+27",
    "locale": "en-ZA",
    "shortLocale": "ZA"
  },
  {
    "text": "ザンビア +260",
    "value": "+260",
    "locale": "en-ZM",
    "shortLocale": "ZM"
  },
  {
    "text": "ジンバブエ +263",
    "value": "+263",
    "locale": "en-ZW",
    "shortLocale": "ZW"
  },
  {
    "text": "コロンビア +57",
    "value": "+57",
    "locale": "es-CO",
    "shortLocale": "CO"
  },
  {
    "text": "チリ +56",
    "value": "+56",
    "locale": "es-CL",
    "shortLocale": "CL"
  },
  {
    "text": "コスタリカ +506",
    "value": "+506",
    "locale": "es-CR",
    "shortLocale": "CR"
  },
  {
    "text": "エクアドル +593",
    "value": "+593",
    "locale": "es-EC",
    "shortLocale": "EC"
  },
  {
    "text": "スペイン +34",
    "value": "+34",
    "locale": "es-ES",
    "shortLocale": "ES"
  },
  {
    "text": "メキシコ +52",
    "value": "+52",
    "locale": "es-MX",
    "shortLocale": "MX"
  },
  {
    "text": "パナマ +507",
    "value": "+507",
    "locale": "es-PA",
    "shortLocale": "PA"
  },
  {
    "text": "パラグアイ +595",
    "value": "+595",
    "locale": "es-PY",
    "shortLocale": "PY"
  },
  {
    "text": "ウルグアイ +598",
    "value": "+598",
    "locale": "es-UY",
    "shortLocale": "UY"
  },
  {
    "text": "エストニア +372",
    "value": "+372",
    "locale": "et-EE",
    "shortLocale": "EE"
  },
  {
    "text": "イラン +98",
    "value": "+98",
    "locale": "fa-IR",
    "shortLocale": "IR"
  },
  {
    "text": "フィンランド +358",
    "value": "+358",
    "locale": "fi-FI",
    "shortLocale": "FI"
  },
  {
    "text": "フィジー +679",
    "value": "+679",
    "locale": "fj-FJ",
    "shortLocale": "FJ"
  },
  {
    "text": "フェロー諸島 +298",
    "value": "+298",
    "locale": "fo-FO",
    "shortLocale": "FO"
  },
  {
    "text": "フランス +33",
    "value": "+33",
    "locale": "fr-FR",
    "shortLocale": "FR"
  },
  {
    "text": "仏領ギアナ +594",
    "value": "+594",
    "locale": "fr-GF",
    "shortLocale": "GF"
  },
  {
    "text": "グアドループ +590",
    "value": "+590",
    "locale": "fr-GP",
    "shortLocale": "GP"
  },
  {
    "text": "マルティニーク +596",
    "value": "+596",
    "locale": "fr-MQ",
    "shortLocale": "MQ"
  },
  {
    "text": "レユニオン +262",
    "value": "+262",
    "locale": "fr-RE",
    "shortLocale": "RE"
  },
  {
    "text": "イスラエル +972",
    "value": "+972",
    "locale": "he-IL",
    "shortLocale": "IL"
  },
  {
    "text": "ハンガリー +36",
    "value": "+36",
    "locale": "hu-HU",
    "shortLocale": "HU"
  },
  {
    "text": "インドネシア +62",
    "value": "+62",
    "locale": "id-ID",
    "shortLocale": "ID"
  },
  {
    "text": "イタリア +39",
    "value": "+39",
    "locale": "it-IT",
    "shortLocale": "IT"
  },
  {
    "text": "日本 +81",
    "value": "+81",
    "locale": "ja-JP",
    "shortLocale": "JP"
  },
  {
    "text": "カザフスタン +7",
    "value": "+7",
    "locale": "kk-KZ",
    "shortLocale": "KZ"
  },
  {
    "text": "グリーンランド +299",
    "value": "+299",
    "locale": "kl-GL",
    "shortLocale": "GL"
  },
  {
    "text": "韓国 +82",
    "value": "+82",
    "locale": "ko-KR",
    "shortLocale": "KR"
  },
  {
    "text": "リトアニア +370",
    "value": "+370",
    "locale": "lt-LT",
    "shortLocale": "LT"
  },
  {
    "text": "マレーシア +60",
    "value": "+60",
    "locale": "ms-MY",
    "shortLocale": "MY"
  },
  {
    "text": "ノルウェー +47",
    "value": "+47",
    "locale": "nb-NO",
    "shortLocale": "NO"
  },
  {
    "text": "ネパール +977",
    "value": "+977",
    "locale": "ne-NP",
    "shortLocale": "NP"
  },
  {
    "text": "ベルギー +32",
    "value": "+32",
    "locale": "nl-BE",
    "shortLocale": "BE"
  },
  {
    "text": "オランダ +31",
    "value": "+31",
    "locale": "nl-NL",
    "shortLocale": "NL"
  },
  {
    "text": "ポーランド +48",
    "value": "+48",
    "locale": "pl-PL",
    "shortLocale": "PL"
  },
  {
    "text": "ブラジル +55",
    "value": "+55",
    "locale": "pt-BR",
    "shortLocale": "BR"
  },
  {
    "text": "ポルトガル +351",
    "value": "+351",
    "locale": "pt-PT",
    "shortLocale": "PT"
  },
  {
    "text": "ルーマニア +40",
    "value": "+40",
    "locale": "ro-RO",
    "shortLocale": "RO"
  },
  {
    "text": "ロシア +7",
    "value": "+7",
    "locale": "ru-RU",
    "shortLocale": "RU"
  },
  {
    "text": "スロベニア +386",
    "value": "+386",
    "locale": "sl-SI",
    "shortLocale": "SI"
  },
  {
    "text": "スロバキア +421",
    "value": "+421",
    "locale": "sk-SK",
    "shortLocale": "SK"
  },
  {
    "text": "セルビア +381",
    "value": "+381",
    "locale": "sr-RS",
    "shortLocale": "RS"
  },
  {
    "text": "スウェーデン +46",
    "value": "+46",
    "locale": "sv-SE",
    "shortLocale": "SE"
  },
  {
    "text": "タイ +66",
    "value": "+66",
    "locale": "th-TH",
    "shortLocale": "TH"
  },
  {
    "text": "トルコ +90",
    "value": "+90",
    "locale": "tr-TR",
    "shortLocale": "TR"
  },
  {
    "text": "ウクライナ +380",
    "value": "+380",
    "locale": "uk-UA",
    "shortLocale": "UA"
  },
  {
    "text": "ベトナム +84",
    "value": "+84",
    "locale": "vi-VN",
    "shortLocale": "VN"
  },
  {
    "text": "中国 +86",
    "value": "+86",
    "locale": "zh-CN",
    "shortLocale": "CN"
  },
  {
    "text": "台湾 +886",
    "value": "+886",
    "locale": "zh-TW",
    "shortLocale": "TW"
  },
  // {
  //   "text": "カナダ +1",
  //   "value": "+1",
  //   "locale": "en-CA",
  //   "shortLocale": "CA"
  // }
];