export const DATA_FAQ_PL = (textBrand: string) => {
  return [
    {
      title:
        `Kim diabli jest ${textBrand} International i co właściwie wnosi na stół?`,
      description: `
            <p>Dobra, porozmawiajmy o głównym rynku nieruchomości i nudnym, przestarzałym doświadczeniu sprzedaży, które go towarzyszy. ${textBrand} International zmienia reguły gry. Jesteśmy tu po to, aby to wszystko potrząsnąć, mój przyjacielu.</p>
            <p>Razem współtworzymy domy przyszłości. Jak to robimy? Wykorzystując technologię personalizacji. Tak, masz rację. ${textBrand} przedstawia niesamowite narzędzie projektowania wnętrz 3D napędzane sztuczną inteligencją. Tak, słyszałeś mnie dobrze. Dzięki niemu możesz poruszać się po swojej przestrzeni życiowej w czasie rzeczywistym, pokój po pokoju, i przemodelować swoje przyszłe mieszkanie.</p>
            <p>Z ${textBrand} wyruszysz w podróż, która będzie płynna, przyjemna i przejrzysta. Nie ma już niespodzianek, nie ma opóźnień. To bezproblemowa i efektywna droga do Twojego wymarzonego domu.</p>
           `,
    },
    {
      title:
        'Czym jest Segment i jak działa podczas zakupu mojego nowego domu?',
      description: `
            <p>Dobra, mój przyjacielu, porozmawiajmy o segmentach i ich magii w podróży zakupowej domów ${textBrand}.</p>
            <p>Segment to jak kawałek układanki, który można połączyć, aby stworzyć przestrzeń odpowiadającą Twoim pragnieniom i budżetowi.</p>
            <p>Wyobraź sobie go jako oddzielną powierzchnię mieszkalną, którą można nabyć indywidualnie lub w większej ilości, personalizując swój nowy dom zgodnie z Twoimi potrzebami i marzeniami.</p>
           `,
    },
    {
      title:
        'Dlaczego Umowa Rezerwacyjna jest tak cholernie ważna w procesie zakupu?',
      description: `
            <p>Dobra, porozmawiajmy o tym, co to jest Umowa Rezerwacyjna. Możesz się zastanawiać: "Co to jest i dlaczego powinienem o to dbać?" Cóż, mój przyjacielu, pozwól, że Ci to wyjaśnię. Ta mała rzecz to kluczowy element w dzikiej jeździe podczas zakupu nieruchomości. Dlaczego? Ponieważ działa jak cholernie bezpieczna siatka, mój przyjacielu.</p>
            <p>Kiedy podpisujesz Umowę Rezerwacyjną, praktycznie mówisz: "Stój, mam oko na tę nieruchomość i chcę się upewnić, że będzie moja."</p>
            <p>Dlaczego to takie ważne? To proste. Daje Ci spokój i chroni Twoje interesy. Zapewnia, że nieruchomość nie zostanie sprzedana przed Twoim nosem, gdy będziesz zajęty kropkowaniem i przekreślaniem.</p>
           `,
    },
    {
      title:
        'Co dokładnie zawiera Umowa Sprzedaży i Zakupu i jakie są jej zalety w zakupie domu?',
      description: `
            <p>Umowa Sprzedaży i Zakupu to jak Święty Graal zakupu domu. To dokument, który zapieczętowuje umowę i chroni Twoje tyłek w procesie.</p>
            <p>Pozwól, że Ci to wyjaśnię: kiedy kupujesz dom, nie po prostu oddajesz swoje pieniądze i liczyrzysz na najlepsze. To właśnie tutaj wchodzi na scenę ta mała rzecz. To wiążąca umowa między Tobą a sprzedawcą, która określa wszystkie ważne rzeczy. Mówimy tu o cenie domu, warunkach przekazania i harmonogramie sfinalizowania umowy.</p>
            <p>To nie jest zwykła umowa podpisywana na chybił trafił, mój przyjacielu. To poważne zobowiązanie, które zapewnia odpowiedzialność obu stron. Więc kiedy będziesz gotowy na to ważne zakupu, upewnij się, że masz solidną Umowę Sprzedaży i Zakupu. To Twoja siatka bezpieczeństwa w dzikim świecie zakupu domu.</p>
           `,

    },
    {
      title:
        `Jak zamienić swoje najdziksze marzenia o zakupie domu w rzeczywistość za sprawą tajemniczych mocy ${textBrand} International?`,
      description: `
            <p>No więc, umierasz ze zniecierpliwienia, by dowiedzieć się, jak możesz spełnić swoje marzenie o domu za sprawą ${textBrand} International? Cóż, przygotuj się, mój przyjacielu, bo zaraz wszystko Ci wyłożę.</p>
            <p><strong>Krok pierwszy:</strong> wybierz kraj, w którym widzisz siebie prowadzącego swoje najlepsze życie. Wybierz i zanurz się w tej przygodzie.</p>
            <p><strong>Krok drugi:</strong> mamy dla Ciebie wiele projektów, które tylko czekają, aż je zbadasz. Zagłęb się w szczegóły, wyobraź sobie siebie w tych przestrzeniach i wyobraź sobie, jak będziesz tam żyć.</p>
            <p><strong>Krok trzeci:</strong> zawęź swoje wybory, mój przyjacielu. Wybierz ilość segmentów, na które Cię stać, i piętro, które przemawia do Twojej duszy.</p>
            <p><strong>Krok czwarty:</strong> To jest ten najbardziej ekscytujący moment. Teraz przygotuj się na najbardziej szokującą część. Możesz przemodelować swoje mieszkanie w oszałamiającym, trójwymiarowym widoku. Tak, mówimy o przywołaniu Twojego wymarzonego domu do życia tuż przed Twoimi oczami. Popracuj nad nim, dostosuj go, i doprowadź go do perfekcji. A, i czy wspominaliśmy, że będziesz miała pełną widoczność i przejrzystość w zakresie kosztów? Nie ma tu żadnych niejasnych spraw.</p>
            <p><strong>Krok piąty:</strong> Gdy będziesz zadowolony z swojego arcydzieła, przyjdzie czas na sfinalizowanie umowy. Podpisz swoją umowę rezerwacyjną i umowę zakupu online, i voilà!</p>
            <p>Teraz usiądź, mój przyjacielu, i niech oczekiwanie narasta. Wkrótce będziesz wypoczywać w domu, o którym zawsze marzyłeś. ${textBrand} jest tu, aby to się spełniło.</p> 
          `,
    },

    {

      title:
        'Jak dołączyć do listy oczekujących i pozostać na bieżąco z nadchodzącymi nieruchomościami?',
      description: `
            <p>Czy jesteś podekscytowany, aby zdobyć te nieuchwytne nieruchomości oznaczone jako "Wkrótce"? Nie mogę Cię winić. Cóż, oto sekret, jak pozostać zaktualizowanym i wyprzedzić innych.</p>
            <p>Przejdź na naszą stronę internetową i znajdź sekcję "Bądź na Bieżąco". To jak skarbnica dla takich jak Ty poszukujących mieszkań. Znajdź opcję dołączenia do listy oczekujących. Wprowadź swój adres e-mail i oficjalnie bądź w gronie wtajemniczonych.</p>
            <p>Upewnimy się, że będziesz pierwszy, który dowie się, kiedy te ekscytujące projekty będą gotowe do działania. To jak posiadanie przepustki backstage do świata nieruchomości. Więc nie trać kolejnej sekundy, mój przyjacielu. Dołącz do listy oczekujących i niech się zacznie wyczekiwanie.</p>
          `,
    },

    {
      title:
        `Jak ${textBrand} International przywiązuje wagę do ochrony Twoich cennych danych i jakie mają partnerstwa w celu zapewnienia bezpiecznych transakcji?`,
      description: `
            <p>W ${textBrand} International nie żartujemy w kwestii ochrony danych. Twoje informacje są dla nas cenne i pilnujemy ich z najwyższą starannością. Nie korzystamy z rozwiązań drugiej klasy. Nawiązaliśmy partnerstwa z najlepszymi z najlepszych, aby zapewnić bezpieczeństwo Twoich danych.</p>
            <p>Mamy Tazapay, firmę z Singapuru, która specjalizuje się w bezpiecznym przechowywaniu szczegółów płatności. Ale to nie wszystko. Idziemy o krok dalej dzięki innowacyjnej technologii Pandadoc do cyfrowego podpisywania umów.</p>
            <p>Nie zostawiamy żadnego kamienia na kamieniu, mój przyjacielu. Twoje dane są naszym najwyższym priorytetem, i mamy właściwe partnerstwa, aby to zapewnić.</p>
          `,
    },
  ];
}
