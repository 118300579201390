import { getDebugValue } from 'services/storageService';
import { DevelopDebugKey } from 'constants/storage';
import { parseBooleanTrue, parseNumber } from 'utils/typeUtils';

const env = {
  ENV: process.env.REACT_APP_ENV as 'production' | 'staging' | 'development',
  API_USER_MANAGEMENT_HOSTNAME: process.env.REACT_APP_API_USER_MANAGEMENT_HOSTNAME as string,
  API_PROJECT_MANAGEMENT_HOSTNAME: process.env.REACT_APP_API_PROJECT_MANAGEMENT_HOSTNAME as string,
  API_QUESTIONNAIRE_HOSTNAME: process.env.REACT_APP_API_QUESTIONNAIRE_HOSTNAME as string,
  API_MATCHMAKER_HOSTNAME: process.env.REACT_APP_API_MATCHMAKER_HOSTNAME as string,
  API_MATCHMAKER_PROJECT: process.env.REACT_APP_API_MATCHMAKER_PROJECT as string,
  SSR_HOSTNAME: process.env.REACT_APP_SSR_HOSTNAME as string,
  HOME_PAGE_URL: process.env.REACT_APP_HOME_PAGE_URL as string,
  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY as string,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string,
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN as string,
  HOSPITALITY_SCORE_QUESTION_ID: process.env.REACT_APP_HOSPITALITY_SCORE_QUESTION_ID as string,
  HOSPITALITY_ARTIST_ANSWER_ID: process.env.REACT_APP_HOSPITALITY_ARTIST_ANSWER_ID as string,
  HOSPITALITY_POET_ANSWER_ID: process.env.REACT_APP_HOSPITALITY_POET_ANSWER_ID as string,
  HOSPITALITY_VISIONARY_ANSWER_ID: process.env.REACT_APP_HOSPITALITY_VISIONARY_ANSWER_ID as string,
  HOSPITALITY_PURIST_ANSWER_ID: process.env.REACT_APP_HOSPITALITY_PURIST_ANSWER_ID as string,
  HOSPITALITY_ARTIST_PERSONALITY_ID: parseNumber(process.env.REACT_APP_HOSPITALITY_ARTIST_PERSONALITY_ID)!,
  HOSPITALITY_POET_PERSONALITY_ID: parseNumber(process.env.REACT_APP_HOSPITALITY_POET_PERSONALITY_ID)!,
  HOSPITALITY_VISIONARY_PERSONALITY_ID: parseNumber(process.env.REACT_APP_HOSPITALITY_VISIONARY_PERSONALITY_ID)!,
  HOSPITALITY_PURIST_PERSONALITY_ID: parseNumber(process.env.REACT_APP_HOSPITALITY_PURIST_PERSONALITY_ID)!,
  HOSPITALITY_STYLIST_PERSONALITY_ID: parseNumber(process.env.REACT_APP_HOSPITALITY_STYLIST_PERSONALITY_ID)!,
  HOSPITALITY_ARTIST_PERSONALITY_CODE: process.env.REACT_APP_HOSPITALITY_ARTIST_PERSONALITY_CODE as string,
  HOSPITALITY_POET_PERSONALITY_CODE: process.env.REACT_APP_HOSPITALITY_POET_PERSONALITY_CODE as string,
  HOSPITALITY_VISIONARY_PERSONALITY_CODE: process.env.REACT_APP_HOSPITALITY_VISIONARY_PERSONALITY_CODE as string,
  HOSPITALITY_PURIST_PERSONALITY_CODE: process.env.REACT_APP_HOSPITALITY_PURIST_PERSONALITY_CODE as string,
}

export const debugEnv = {
  DEV_LOG_ENV: parseBooleanTrue(getDebugValue(DevelopDebugKey.LogEnv, false)),
  DEV_ENABLE_DESIGN_DEBUG_MODE: parseBooleanTrue(getDebugValue(DevelopDebugKey.EnableDesignDebugMode, false)),
  DEV_DESIGN_LAYOUT_ID: getDebugValue(DevelopDebugKey.DesignLayoutId) as number,
  DEV_DESIGN_PROJECT_ID: getDebugValue(DevelopDebugKey.DesignProjectId) as number,
  DEV_PIXEL_STREAMING_HOST: getDebugValue(DevelopDebugKey.PixelStreamingHost) as string,
  DEV_PIXEL_STREAMING_PROJECT: getDebugValue(DevelopDebugKey.PixelStreamingProject) as string,
  DEV_DISABLE_CHECK_STEP: getDebugValue(DevelopDebugKey.DisableCheckStep) as boolean,
}

export const checkEnvAvailable = () => {
  if (debugEnv.DEV_LOG_ENV) {
    console.debug('[env] Variables', env);
    console.debug('[env] Debug Variables', debugEnv);
  }
  
  let missingVars: string[] = [];
  for (const k in env) {
    // @ts-ignore
    if (env[k] === undefined) {
      missingVars.push(`"REACT_APP_${k}"`);
    }
  }
  
  if (missingVars.length > 0) {
    console.error(`[env] Missing environment variables: ${missingVars.join(', ')}`)
  }
}

export default env;
