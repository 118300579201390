import BaseModel from './baseModel';
import Product, { ProductType } from './product';
import { Obj } from 'constants/types';

class ProductDefault extends BaseModel<ProductDefault> {
  id?: number;
  roomId?: number;
  roomName?: string;
  layoutId?: number;
  projectId?: number;
  placeholderId?: number;
  placeholderName?: string;
  personalityId?: number;
  personalityName?: string;
  productType?: ProductType;
  productListId?: number;
  
  ueProductQuantity?: number; // quantity get from UE, use for creating order
  
  removable?: boolean;
  
  categoryIds?: number[];
  
  product?: Product;
  
  get isFitOut(): boolean | undefined {
    if (this.productType === undefined) return undefined;
    return this.productType === ProductType.FitOut;
  }
  
  fromPayload({
    id,
    roomId,
    roomName,
    layoutId,
    projectId,
    placeholderId,
    placeholderName,
    personalityId,
    personalityName,
    categoryIds,
    product,
    productType,
    productChooseId,
    finalPrice,
    finalPriceDiscount,
    productListId,
    removable,
    quantity,
  }: Obj): this {
    if (id !== undefined) this.id = id;
    if (roomId !== undefined) this.roomId = roomId;
    if (productListId !== undefined) this.productListId = productListId;
    if (roomName !== undefined) this.roomName = roomName;
    if (layoutId !== undefined) this.layoutId = layoutId;
    if (projectId !== undefined) this.projectId = projectId;
    if (placeholderId !== undefined) this.placeholderId = placeholderId;
    if (placeholderName !== undefined) this.placeholderName = placeholderName;
    if (personalityId !== undefined) this.personalityId = personalityId;
    if (personalityName !== undefined) this.personalityName = personalityName;
    if (categoryIds !== undefined) this.categoryIds = categoryIds;
    if (productType !== undefined) this.productType = productType;
    if (product !== undefined) {
      this.product = product
        ? new Product().fromPayload({ ...product, productChooseId, finalPrice, finalPriceDiscount })
        : product;
    }
    if (removable !== undefined) this.removable = removable;
    if (quantity !== undefined) this.ueProductQuantity = quantity;

    return this;
  }
}

export default ProductDefault;
