export const DATA_FAQ_EL = (textBrand: string) => {
  return [
    {
      title:
        `Ποιος είναι ο ${textBrand} International και τι προσφέρει στο τραπέζι;`,
      description: `
            <p>Ας μιλήσουμε για την κύρια αγορά ακινήτων και τη βαρετή, ξεπερασμένη εμπειρία πώλησης που τη συνοδεύει. Ο ${textBrand} International είναι εδώ για να αλλάξει το παιχνίδι. Είμαστε εδώ για να ταράξουμε τα νερά, φίλε μου.</p>
            <p>Συναντιόμαστε στην ανάπτυξη των σπιτιών του μέλλοντος. Πώς το κάνουμε; Εκμεταλλευόμενοι τη δύναμη της τεχνολογίας εξατομίκευσης. Ναι, ακριβώς έτσι. Ο ${textBrand} παρουσιάζει ένα καταπληκτικό εργαλείο σχεδιασμού εσωτερικών χώρων 3D που λειτουργεί με τη βοήθεια της τεχνητής νοημοσύνης. Ναι, με ακούσατε καλά. Αυτό το εργαλείο σας επιτρέπει να περιηγηθείτε στον χώρο σας σε πραγματικό χρόνο, δωμάτιο με δωμάτιο, και να προετοιμάσετε το μελλοντικό σας σπίτι.</p>
            <p>Με το ${textBrand}, ξεκινάτε ένα ταξίδι που είναι ομαλό, απολαυστικό και διαφανές. Χωρίς άλλες εκπλήξεις, χωρίς καθυστερήσεις. Είναι ένα ομαλό, αποτελεσματικό ταξίδι προς το όνειρο σας.</p>
           `,
    },
    {
      title:
        'Τι είναι ένα Τμήμα και πώς λειτουργεί όταν αγοράζω το νέο μου σπίτι;',
      description: `
            <p>Εντάξει, φίλε μου, ας μιλήσουμε για τα τμήματα και πώς λειτουργούν τη μαγεία τους στον κόσμο του αγοράς σπιτιού του ${textBrand}.</p>
            <p>Ένα τμήμα είναι σαν ένα κομμάτι του παζλ που μπορεί να συνδυαστεί για να δημιουργήσει έναν χώρο που εξυπηρετεί τις επιθυμίες και τον προϋπολογισμό σας.</p>
            <p>Σκεφτείτε το ως ένα συγκεκριμένο μέγεθος κατοικίας που μπορείτε να αποκτήσετε ατομικά ή σε πολλαπλά, εξατομικεύοντας το νέο σας σπίτι για να καλύψει κάθε σας ανάγκη και επιθυμία.</p>
           `,
    },
    {
      title:
        'Γιατί ένα Συμφωνητικό Κράτησης είναι τόσο σημαντικό στη διαδικασία αγοράς;',
      description: `
            <p>Εντάξει, ας μιλήσουμε για αυτό το πράγμα που λέγεται Συμφωνητικό Κράτησης. Μπορεί να σκέφτεστε: "Τι είναι αυτό και γιατί πρέπει να με νοιάζει;" Λοιπόν, φίλε μου, ας στο εξηγήσω. Αυτό είναι ένα κρίσιμο στοιχείο στο άγριο ταξίδι της αγοράς ακινήτου. Γιατί; Γιατί είναι σαν ένα απίστευτο δίχτυ ασφαλείας, φίλε μου.</p>
            <p>Όταν υπογράφετε ένα Συμφωνητικό Κράτησης, βασικά λέτε: "Σταματήστε, έχω τα μάτια μου πάνω σε αυτό το ακίνητο και θέλω να βεβαιωθώ ότι είναι δικό μου".</p>
            <p>Γιατί είναι τόσο σημαντικό; Απλά. Σας προσφέρει ασφάλεια και προστατεύει τα συμφέροντά σας. Βεβαιώνεται ότι το ακίνητο δεν θα πουληθεί κάτω από τα πόδια σας ενώ είστε απασχολημένος με τις λεπτομέρειες.</p>
           `,
    },
    {
      title:
        'Τι περιλαμβάνεται ακριβώς σε ένα Συμφωνητικό Πώλησης και Αγοράς και τι αφορά στην αγορά ακινήτου;',
      description: `
            <p>Ένα Συμφωνητικό Πώλησης και Αγοράς είναι σαν το ιερό δισκοπότηρο μιας αγοράς. Είναι το έγγραφο που σφραγίζει τη συμφωνία και προστατεύει τον κώλο σας στη διαδικασία.</p>
            <p>Άς το διαχωρίσουμε: όταν αγοράζετε ένα σπίτι, δεν απλά δίνετε τα λεφτά σας και ελπίζετε για το καλύτερο. Εκεί έρχεται αυτό το πράγμα. Είναι ένας νομικά δεσμευτικός συμφωνητικός μεταξύ εσάς και του πωλητή που καθορίζει όλα τα σημαντικά στοιχεία. Μιλάμε για την τιμή του σπιτιού, τις συνθήκες παράδοσης και το χρονοδιάγραμμα για το κλείσιμο της συμφωνίας.</p>
            <p>Αυτό δεν είναι μια απλή συμφωνία με χειραψία, φίλε μου. Είναι μια σοβαρή δέσμευση που εξασφαλίζει ότι και οι δύο πλευρές κρατούνται υπόψη. Οπότε, όταν είστε έτοιμοι να κάνετε αυτήν τη μεγάλη αγορά, βεβαιωθείτε ότι έχετε ένα σταθερό Συμφωνητικό Πώλησης & Αγοράς. Είναι το δίχτυ ασφαλείας σας στον άγριο κόσμο της αγοράς ακινήτου.</p>
           `,

    },
    {
      title:
        `Πώς μπορείς να κάνεις πραγματικότητα τα πιο τρελά όνειρα σου για αγορά σπιτιού μέσω των μυστηριωδών δυνάμεων του ${textBrand} International;`,
      description: `
            <p>Οπότε, θέλεις να μάθεις πώς μπορείς να κάνεις το όνειρο του σπιτιού σου πραγματικότητα μέσω του ${textBrand} International; Λοιπόν, ζώσου, φίλε μου, γιατί είμαι έτοιμος να σου το παρουσιάσω.</p>
            <p><strong>Βήμα ένα:</strong> Επίλεξε τη χώρα που επιθυμείς. Πού βλέπεις τον εαυτό σου να ζεις την καλύτερη δυνατή ζωή; Κάνε την επιλογή σου και ας το ξεκινήσουμε.</p>
            <p><strong>Βήμα δύο:</strong> Έχουμε μια πληθώρα έργων που είναι έτοιμα για να τα εξερευνήσεις. Χάνεσαι στις λεπτομέρειες, φαντάζεσαι τον εαυτό σου σε αυτούς τους χώρους και φαντασιώνεσαι τη ζωή που θα ζήσεις.</p>
            <p><strong>Βήμα τρία:</strong> Περιορίστηκε, φίλε μου. Επέλεξε τον αριθμό των τμημάτων που μπορείς να ανταποκριθείς και τον όροφο που αναζητάς.</p>
            <p><strong>Βήμα τέσσερα:</strong> Εδώ γίνεται ενδιαφέρον. Προετοιμάσου για το μέρος που θα σε αφήσει με ανοικτό το στόμα. Τώρα, ετοιμάσου για το απίστευτο μέρος. Μπορείς να προ-μοντελοποιήσεις το διαμέρισμά σου σε συναρπαστικό 3D. Ναι, μιλάμε για το να φέρεις το όνειρο σπίτι σου στη ζωή κατά την ανάπτυξη. Προσαρμόστε το, παίξτε με τον χώρο και κάντε τον τέλειο. Ω, και αναφέραμε ότι θα έχετε πλήρη διαφάνεια για τις δαπάνες; Εδώ δεν υπάρχουν υπογειότητες.</p>
            <p><strong>Βήμα πέντε:</strong> Αφού είστε ικανοποιημένοι με το αριστούργημά σας, είναι ώρα να σφραγίσετε τη συμφωνία. Υπογράψτε το συμφωνητικό κράτησης και πώλησης διαδικτυακά, και voilà!</p>
            <p>Τώρα, χαλαρώστε, φίλε μου, και αφήστε την ανυπομονησία να αυξηθεί. Σύντομα, θα απολαμβάνετε το σπίτι των ονείρων σας. Ο ${textBrand} είναι εδώ για να το κάνει πραγματικότητα.</p> 
          `,
    },

    {

      title:
        'Πώς μπορείτε να εγγραφείτε στη λίστα αναμονής και να ενημερώνεστε για τα ακίνητα που θα κυκλοφορήσουν σύντομα;',
      description: `
            <p>Έτσι, είστε περίεργοι να αποκτήσετε πρόσβαση στα επιθυμητά ακίνητα που θα κυκλοφορήσουν σύντομα, ε; Δεν μπορώ να σας κατηγορήσω. Λοιπόν, εδώ είναι το μυστικό για να παραμένετε ενήμεροι και να προλαμβάνετε τους άλλους.</p>
            <p>Επισκεφτείτε την ιστοσελίδα μας και αναζητήστε την ενότητα "Παραμείνετε ενημερωμένοι". Είναι σαν ένα θησαυροφυλάκιο για αγοραστές όπως εσείς. Ψάξτε για τη δυνατότητα εγγραφής στη λίστα αναμονής. Εισαγάγετε τη διεύθυνση email σας και είστε επίσημα μέσα.</p>
            <p>Εξασφαλίζουμε ότι θα είστε ο πρώτος που θα μάθει όταν αυτά τα συναρπαστικά έργα είναι έτοιμα να ξεκινήσουν. Είναι σαν να έχετε backstage pass στον κόσμο των ακινήτων. Οπότε, μην χάνετε άλλο χρόνο, φίλε μου. Γραφτείτε στη λίστα αναμονής και ας ξεκινήσει η ανυπομονησία.</p>
          `,
    },

    {
      title:
        `Πώς δίνει προτεραιότητα ο ${textBrand} International στην προστασία των πολύτιμων δεδομένων σας και ποιες συνεργασίες έχει αναπτύξει για ασφαλείς συναλλαγές;`,
      description: `
            <p>Στη ${textBrand} International δεν κάνουμε κανένα συμβιβασμό όσον αφορά την προστασία των δεδομένων. Οι πληροφορίες σας είναι όσο πολύτιμες, και τις προστατεύουμε με όλες μας τις δυνάμεις. Δεν υιοθετούμε δευτεροκλασσικές λύσεις. Έχουμε συνεργαστεί με τους καλύτερους από τους καλύτερους για να διασφαλίσουμε ότι τα δεδομένα σας είναι ασφαλή και προστατευμένα.</p>
            <p>Συνεργαζόμαστε με την Tazapay, μια εταιρεία με έδρα τη Σιγκαπούρη, που ειδικεύεται στην ασφάλεια των λεπτομερειών πληρωμής σας. Αλλά αυτό δεν αρκεί. Έχουμε ενισχύσει την τεχνολογία μας με την καινοτόμο λύση της Pandadoc για ψηφιακές υπογραφές συμβολαίων.</p>
            <p>Δεν αφήνουμε τίποτα ανεξερεύνητο, φίλε μου. Τα δεδομένα σας είναι πρώτης προτεραιότητας για εμάς, και έχουμε αναπτύξει τις κατάλληλες συνεργασίες για να το εξασφαλίσουμε αυτό.</p>
          `,
    },
  ];
};
