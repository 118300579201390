import { WebRTCClient, WebRTCClientProps } from '@arcware/webrtc-plugin';
import { useDesignContext } from 'components/YourDesign/contexts/designContext';
import { PixelStreamingResponseAction } from 'constants/designConstants';
import { StorageKey } from "constants/storage";
import { forEach, isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getStorage, setStorage } from "services/storageService";

const useArcware3D = () => {
  const {
    homeData: { layoutId },
    oldSelectedLayoutId,
    selectedCamera,
    selectedPlaceholderId,
    selectedProductIdByClick,
    selectedDesignId,
    selectedPersonalityId,
    productDefaultByPlaceholderId,
    initialLayout3D,
    changeSelectedPlaceholderId3D,
    updateDesignContext,
  } = useDesignContext();

  const isInitialLayout = useRef(false);
  const [isLoadingArcware, setIsLoadingArcware] = useState(true);
  const sizeContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [webrtcClient, setWebrtcClient] = useState<WebRTCClient>();
  let webrtcClientInit = false;
  const timeCheckingLoad3DForLayout = useRef<any | null>(null);

  const initialInteraction3D = (layoutId: number, cameraId: number, placeholders: any[]) => {
    console.log('InitialLayout', {
      layoutId,
      CameraAngles: cameraId,
      placeholders: placeholders,
    });
    webrtcClient?.emitUIInteraction({
      layoutId,
      CameraAngles: cameraId,
      placeholders: placeholders,
    });
  };

  const updateCameraInteraction3D = (cameraId: number) =>
    webrtcClient?.emitUIInteraction({
      CameraAngles: cameraId,
    })

  const updateProductInteraction3D = (placeholderId: number, productId: number) =>
    webrtcClient?.emitUIInteraction({
      placeHolderId: placeholderId,
      productId: productId,
    });

  const updateManyProductInteraction3D = (placeholders: any[]) =>
    webrtcClient?.emitUIInteraction({ placeholders });

  const responseCallback = (message: string) => {
    const response = JSON.parse(message);
    console.log('response from 3D', response);
    switch (response.actionKey) {
      case PixelStreamingResponseAction.LayoutInitiated:
        isInitialLayout.current = true;
        const objectCountList = JSON.parse(response.objectCountList);
        let quantityByPlaceholderId: Record<number, number> = {};

        forEach(objectCountList, (quantity, placeholderId) => {
          quantityByPlaceholderId[+placeholderId] = +quantity;
        });
        initialLayout3D(quantityByPlaceholderId);
        break;

      case PixelStreamingResponseAction.ClickObject:
        const { placeHolderId, productId } = response;
        if (placeHolderId && +placeHolderId !== selectedPlaceholderId) {
          const productDefaultByPlaceholderId = getStorage(StorageKey.ProductDefaultByPlaceholderId) || '';
          if (productDefaultByPlaceholderId[+placeHolderId]) {
            changeSelectedPlaceholderId3D(+placeHolderId);
          } else if (productId) {
            updateProductInteraction3D(placeHolderId, productId);
          }
        }
        break;
      
      case PixelStreamingResponseAction.ObjectChanged:
        isInitialLayout.current = true;
        updateDesignContext({ isLoadDesign: false, isLoadFavorite: false, isLoad3DSuccess: true });
        break;

      default:
        console.warn(`[pixel][handleResponse] Unknown`, response);
    }
  };

  useEffect(() => {
    const args: WebRTCClientProps = {
      address: "wss://signalling-client.ragnarok.arcware.cloud/",
      packageId: getStorage(StorageKey.PackageId) || '',
      shareId: getStorage(StorageKey.ShareId) || '',
      settings: {},
      sizeContainer: sizeContainerRef.current as HTMLDivElement,
      container: containerRef.current as HTMLDivElement,
      videoRef: videoRef.current as HTMLVideoElement,
      playOverlay: false,
      forceVideoToFitContainer: true,
      loader: (val) => setIsLoadingArcware(val),
      autoplay: {
        video: true,
        audio: true
      },
      // sendResolutionOnResize: true,
      applicationResponse: (response) => responseCallback(response),
    };

    // double load protection
    if (!webrtcClientInit) {
      webrtcClientInit = true;
      setWebrtcClient(new WebRTCClient(args));
    }
  }, []);

  // Update Selected Product
  useEffect(() => {
    if (selectedProductIdByClick) {
      updateProductInteraction3D(selectedPlaceholderId!, selectedProductIdByClick);
    }
  }, [selectedProductIdByClick]);

  // Initial Layout
  useEffect(() => {
    if (!isEmpty(collectionPlaceholderProducts) && !isLoadingArcware) {
      if (!isInitialLayout.current) {
        setStorage(StorageKey.SelectedPlaceholderId, 0);
      }
      if (layoutId !== oldSelectedLayoutId) {
        updateDesignContext({
          oldSelectedLayoutId: layoutId,
        });
        isInitialLayout.current = false;
        pollingCheckingLoad3DForLayout();
      } else {
        updateCameraInteraction3D(selectedCamera?.id!);
      }
    }
  }, [layoutId, selectedCamera]);

  useEffect(() => {
    if (selectedDesignId) {
      updateManyProductInteraction3D(collectionPlaceholderProducts);
    }
  }, [selectedDesignId]);

  useEffect(() => {
    if (selectedPersonalityId) {
      updateDesignContext({ isLoadDesign: true });
      isInitialLayout.current = false;
    }
  }, [selectedPersonalityId]);

  const pollingCheckingLoad3DForLayout = () => {
    if (!isInitialLayout.current) {
      timeCheckingLoad3DForLayout.current = setTimeout(() => {
        pollingCheckingLoad3DForLayout();
      }, 3000);
    } else {
      clearTimeout(timeCheckingLoad3DForLayout.current);
      updateDesignContext({ isLoadDesign: false });
    }
  }

  const collectionPlaceholderProducts = useMemo(() => {
    return Object.keys(productDefaultByPlaceholderId).map((placeholderId: string) => {
      const productId = productDefaultByPlaceholderId[+placeholderId]?.product?.unrealEngineId || 0; // 0 for empty object
      return {
        id: +placeholderId,
        items: { productId: `${productId}` },
      };
    })
  }, [productDefaultByPlaceholderId]);

  return {
    sizeContainerRef,
    containerRef,
    videoRef,
    webrtcClient,
    isLoadingArcware,
    collectionPlaceholderProducts,
    isInitialLayout,
    initialInteraction3D,
    updateCameraInteraction3D,
  }
};

export default useArcware3D;
