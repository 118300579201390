import { RootState } from '../store';

export const selectPersonalityResult = (state: RootState) =>
  state.userTaste.personalityResult;

export const selectPersonalityId = (state: RootState) =>
  state.userTaste.personalityResult?.hospitalityPersonality?.personalityId ||
  state.userTaste.personalityResult?.personalityId ||
  0;

export const selectUserTasteLoaded = (state: RootState) =>
  state.userTaste.tasteLoaded;

export const selectUserTasteCompleted = (state: RootState) =>
  !!state.userTaste.personalityResult.personalityId;
