export const DATA_FAQ_JA = (textBrand: string) => {
  return [
    {
      title:
        `${textBrand} Internationalとは何者であり、何を提供しているのですか？`,
      description: `
            <p>よし、まずは主要な不動産市場とそれに伴う退屈で時代遅れなセールス体験について話しましょう。${textBrand} Internationalはゲームを変えるためにここにいます。私たちはここにいます、友よ。 </p>
            <p>私たちは未来の家を共同開発しています。それをどうやって実現するのか？個別化技術の力を借りています。そう、それです。${textBrand}はAIによる驚くべき3Dインテリアデザインツールを導入しています。ええ、そう聞こえましたか。このすごいツールによって、リアルタイムで生活空間を部屋ごとにナビゲートし、未来の家を事前にモデル化できます。</p>
            <p>${textBrand}を選ぶと、スムーズで楽しい、そして透明性のある旅に乗り出します。もう驚きも遅れもありません。夢の家に向かうシームレスで効率的な旅です。</p>
           `,
    },
    {
      title:
        'セグメントとは、新しい家を購入する際にどのように機能するのですか？',
      description: `
            <p>よし、友よ、セグメントについて話してみましょう。${textBrand}の家の購入プロセスでどのように魔法をかけるのかを。</p>
            <p>セグメントとは、あなたの欲望と予算に合ったスペースを作成するために組み合わせることのできるパズルのピースのようなものです。</p>
            <p>それは個別の生活スペースのサイズと考えてください。個別に取得したり、複数で取得したりして、新しい家をあなたのニーズと欲求に合わせて個人的にカスタマイズできます。</p>
           `,
    },
    {
      title:
        'なぜ予約契約が購入プロセスにおいて非常に重要なのですか？',
      description: `
            <p>よし、この予約契約のことについて話しましょう。あなたはおそらく、「それは何で、なぜ私が気にすべきなのか」と思っているかもしれません。さて、友よ、私が説明しましょう。この契約は不動産の購入における重要な要素です。なぜなら、それはまるで安全ネットのようなものだからです。</p>
            <p>予約契約にサインすることで、あなたは基本的に「待ってください、私はこの物件に目をつけていて、それが私のものであることを確認したい」と言っているのです。</p>
            <p>それがなぜ重要なのか？シンプルです。それにより安心感が得られ、あなたの利益が保護されます。契約を結んでいる間に、物件があなたの手の届かないところで売り切れてしまうことが防げます。</p>
           `,
    },
    {
      title:
        '売買契約には具体的に何が含まれ、家の購入において何を意味するのですか？',
      description: `
            <p>売買契約は家の購入の聖杯のようなものです。それが契約であり、プロセスを保護する文書なのです。</p>
            <p>説明しましょう：家を購入する際、単にお金を渡してベストを期待するだけではありません。そこでこの契約が登場します。それはあなたと売り手の間の法的拘束力のある契約であり、重要なことがすべて記載されています。家の価格、引き渡しの条件、契約を完了するタイムラインなどについての内容です。</p>
            <p>これは何気ない握手の合意ではありません、友よ。これは両者が責任を負う重要な取り決めです。だから、大きな購入をする準備ができたら、しっかりとした売買契約を用意してください。それは家の購入の荒れ狂う世界におけるあなたの安全ネットです。</p>
           `,

    },
    {
      title:
        `${textBrand} Internationalの不思議な力を使って、あなたの最も夢のある家の購入の夢を現実化する方法は何ですか？`,
      description: `
            <p>では、${textBrand} Internationalの不思議な力を使って夢の家を現実化する方法について知りたいですか？よし、友よ、準備して、説明しましょう。</p>
            <p><strong>ステップ１：</strong>望む国を選んでください。どこで最高の生活を送りたいですか？選んで、始めましょう。</p>
            <p><strong>ステップ２：</strong>私たちは探検するのを待っているプロジェクトのメニューを用意しています。詳細に没頭し、その空間に自分自身をイメージし、自分が暮らす未来の生活を想像してください。</p>
            <p><strong>ステップ３：</strong>絞り込んでください、友よ。自分が手に入れることのできるセグメントの数と、魂に訴えるフロアを選んでください。</p>
            <p><strong>ステップ４：</strong>ここが面白くなるところです。いま、驚くべき部分に備えてください。驚くべき3Dであなたのアパートを事前にモデル化できます。そうです、私たちは目の前であなたの夢の家を形にするということです。調整し、遊んで、完璧に仕上げてください。ああ、そしてコストについて完全な透明性と視界が得られることを言い忘れましたね？ここには胡散臭い取引はありません。</p>
            <p><strong>ステップ５：</strong>自分の傑作に満足したら、契約を結びましょう。オンラインで予約契約と購入契約にサインし、それで完成です。</p>
            <p>それでは、友よ、くつろいで、期待感を高めてください。それもそう遠くないうちに、あなたが常に夢見ていた家でリラックスしていることでしょう。${textBrand}が実現させる用意ができています。</p> 
          `,
    },

    {

      title:
        'どのようにしてウェイティングリストに参加し、準備が整った物件の情報を入手することができますか？',
      description: `
            <p>では、あなたはその手に入れられない「近日発売」の物件に手を出すことに熱心なのですね？私もあなたを責めません。さて、ここに滞在して最新情報を得る秘訣があります。</p>
            <p>当社のウェブサイトにアクセスし、"情報を入手"と書かれたセクションを探してください。それはあなたのような家の購入者にとっての宝の庫のようなものです。ウェイティングリストに参加するオプションを探して、メールアドレスを入力し、正式に最新情報を得られるようにしてください。</p>
            <p>私たちは、それらのエキサイティングなプロジェクトが開始される準備が整ったときにはあなたが最初に知るようにします。それはまるで不動産の舞台裏へのパスを持っているようなものです。ですから、もう1秒も無駄にしないで、友よ。ウェイティングリストに参加して、カウントダウンを始めてください。</p>
          `,
    },

    {
      title:
        `${textBrand} Internationalは貴重なデータの保護をどのように優先し、安全な取引を確保するための提携関係は何ですか？`,
      description: `
            <p>${textBrand} Internationalでは、データ保護に関して妥協しません。あなたの情報は私たちにとって金と同じです。私たちはそれを最大限に保護しています。二流の解決策を受け入れるわけにはいきません。あなたのデータを安全に保ち続けるために、最高のパートナーと協力しています。</p>
            <p>私たちはシンガポールを拠点とするTazapayと提携しています。これはあなたの支払い情報を堅牢に保つことに特化している企業です。しかし、それだけではありません。私たちはPandadocの革新的な技術を使ってさらに上を目指しています。これはデジタル契約の署名に利用されています。</p>
            <p>私たちは一つも手を抜きません、友よ。あなたのデータは私たちの最優先事項ですし、私たちはそれを保証するための正しい提携関係を持っています。</p>
          `,
    },
  ];
}