import { Obj } from "constants/types";
// import BaseModel from "./baseModel";

export class TextContentBrand {
  textBrand?: string;

  constructor({ textBrand }: Obj) {
    if (textBrand !== undefined) this.textBrand = textBrand;
    return this
  }

  // fromPayload({
  //   textBrand
  // }: Obj): this {
  //   if (textBrand !== undefined) this.textBrand = textBrand;
  //   return this
  // }
}
