import React from 'react';
import styled from 'styled-components';

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ButtonColor } from './constants/button';

type ButtonShape = 'square'; // default shape is circle

export type IconButtonProps = MuiIconButtonProps & {
  className?: string;
  shape?: ButtonShape;
  enterDelay?: number;
} & StyleProps;

const IconButton = ({
  children,
  className,
  shape,
  title,
  enterDelay = 500,
  ...restProps
}: IconButtonProps) => {
  const classNames: string[] = [];
  if (className) classNames.push(className);
  if (shape) classNames.push(`button-shape-${shape}`);
  const textTooltip = title ? title : '';
  return (
    <Tooltip title={textTooltip} enterDelay={enterDelay}>
      <StyledIconButton className={classNames.join(' ')} {...restProps}>
        {children}
      </StyledIconButton>
    </Tooltip>
  );
};

type StyleProps = {
  bgcolor?: ButtonColor,
  color?: ButtonColor,
  width?: number | string,
  height?: number | string,
}

const StyledIconButton = styled(MuiIconButton) <StyleProps>`
  && {
    ${p => p.bgcolor && `background: ${p.theme.palette[p.bgcolor].main};`}
    
    &:hover {
      ${p => p.bgcolor && `background: ${p.theme.palette[p.bgcolor].mainHover || p.theme.palette[p.bgcolor].main};`}
    }
    
    &:focus {
      outline: none;
    }
  }
  
  &.button-shape-square {
    border-radius: 0;
  }
`;

export default IconButton;
