export const POLICY_TL = (textBrand: string) => {
  return [
    {
      title: 'Panimula',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>Ang ${textBrand} International Pte. Ltd. ("<strong>tayo</strong>", "<strong>atin</strong>", "<strong>${textBrand}</strong>") ang operator ng <a href="https://levitate.homes">https://levitate.homes</a> (ang "<strong>Website</strong>"), at kami ay nakatuon sa pag-iingat ng iyong Personal na Data (na itinakda sa Clause 2 sa ibaba) ayon sa mga tuntunin at kundisyon na ito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Ang Patakaran sa Pagkapribado na ito (kasama ang aming Mga Tuntunin ng Paggamit at anumang iba pang mga dokumento na binanggit dito) ay naglalatag kung paano gagamitin, ipo-proseso, o ihahayag namin ang Personal na Data na aming kinokolekta mula sa iyo, o na iyong ibinigay sa amin, kabilang kung paano gagamitin ang ilang impormasyon upang mas personalisahin ang advertising na inihahandog sa iyo. Tinalakay rin ng Patakaran sa Pagkapribado na ito ang mga uri ng Personal na Data na kinokolekta namin, kung paano namin ito pinoprotektahan, pati na rin kung paano mo maa-access o maaaring baguhin ang iyong Personal na Data na nakaimbak sa amin. Saklaw din ng Patakaran sa Pagkapribado na ito ang iyong paggamit ng Website, at anumang iba pang paraan ng iyong koneksyon o interaksyon sa amin sa pamamagitan ng paggamit ng aming mga produkto at serbisyo (magsamasama na tinutukoy bilang "<strong>Mga Serbisyo</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Maingat na basahin ang sumusunod upang maunawaan ang aming patakaran at kasanayan kaugnay ng iyong Personal na Data at kung paano namin ito tatalakayin.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div"Maliban sa mga limitasyon na ipinatutupad ng naaangkop na batas, sa pamamagitan ng paggamit sa Website at Mga Serbisyo, sumasang-ayon ka na ang anumang Personal na Data na may kinalaman sa iyo na aming kinolekta o ibinigay mo sa amin mula sa panahon-panahon ay maaaring gamitin, iproseso, at ihayag para sa mga layuning binanggit at para sa mga taong tinukoy sa Patakaran sa Pagkapribado na ito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Bilang bahagi ng aming mga pagsisikap na matiyak na tama ang pamamahala, proteksyon, at proseso ng iyong Personal na Data, rerepasuhin namin ang aming mga patakaran, proseso, at mga hakbang mula sa panahon-panahon. Maaari naming amyendahan ang Patakaran sa Pagkapribado na ito anumang oras sa pamamagitan ng pag-post ng binagong bersyon sa Website. Maaaring subukan naming magpadala ng abiso kapag binago ang patakaran, gayunpaman, ituturing kang nakakilala at pumapayag sa nabagong Patakaran sa Pagkapribado kung patuloy kang gumagamit ng Website o Mga Serbisyo pagkatapos ng pagkakapagbagong-buhay, alinsunod kung natanggap mo man ang abiso o hindi. Ipinapaalala ka na suriin nang paminsan-minsan ang pahinang ito para sa mga update sa Patakaran sa Pagkapribado na ito.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      title: 'Pagtitipon ng Personal na Data',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                Maaari kang magpasyang mag-browse sa Website at gamitin ang mga limitadong function nito nang hindi ibinubunyag ang iyong Personal na Data. Hindi mo kailangang magbigay ng Personal na Data bilang kondisyon sa paggamit ng Website, maliban na lamang kung kinakailangan upang maibigay namin ang mga Serbisyo na binibili mo o binibigyan ng access sa pamamagitan ng aming Website, tugonan ang iyong mga kahilingan, o sa mga kaso kung kailangan mong makipag-ugnayan sa amin nang direkta.
              </div>
              <div>
                Kapag ginagamit mo ang aming mga Serbisyo, kami ay nagkokolekta ng iba't ibang impormasyon mula sa iyo, sa iyong mga aparato, at sa iyong pakikipag-ugnayan sa mga Serbisyo. Ang ilan sa impormasyong ito ay nagpapakilala sa iyo nang direkta o maaaring magamit upang hinalaang ikaw ay iisa kapag pagsamahin sa iba pang mga data. Anumang data, totoo man o hindi, tungkol sa isang indibidwal na maaaring makilala mula sa data na iyon; o mula sa data na iyon at iba pang impormasyon na mayroon o malamang na magkaroon ang organisasyon, ay itinuturing na personal na data ("<strong>Personal na Data</strong>").
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.1.</div>
                  <div>
                    <strong>Impormasyong ibinibigay nang kusang-loob</strong>. Kapag gumagamit ka ng aming mga Serbisyo, maaaring hingan ka na magbigay ng personal na impormasyon tungkol sa iyo, gaya ng iyong pangalan, impormasyon sa pagkontak, impormasyon sa pagbabayad, mga detalye tungkol sa iyong tahanan o mga ari-arian na interesado ka, impormasyong pinansiyal, iyong mga paborito, o iyong mga estilo. Maaaring mangyari ito, halimbawa:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Kapag ikaw ay gumagawa ng mga transaksyon sa amin sa loob o kaugnay ng Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Kapag ikaw ay nagrerehistro ng isang account sa amin sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Kapag ikaw ay nakikipag-ugnayan sa aming customer service o mga salesperson sa pamamagitan ng email, telepono, o personal na pakikipagkita, upang malutas ang anumang mga isyu na maaaring kinakaharap mo o may mga katanungan o kahilingan;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Kapag ikaw ay nag-subscribe sa mga serbisyo na ibinibigay namin sa Website, o para sa aming email notifications, at/o newsletters;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Kapag nagbibigay ka o iniwan ang kaugnay na input, tugon, impormasyon sa Website para sa layunin ng paggamit ng mga Serbisyo;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Kapag nag-uusap ka sa isang propesyonal sa real estate (tulad ng isang ahente o broker sa real estate, tagapautang o opisyal ng pautang sa mortgage, tagapamahala ng ari-arian, mamumuhunan, tagagawa ng bahay, o iba pa) sa pamamagitan ng mga Serbisyo; at</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g. </div>
                        <div>Kapag nagtatapos ka ng iba pang mga porma o transaksyon, gaya ng request para sa impormasyon ng pautang, aplikasyon ng background check (kung mayroon man).</div>
                      </div>
                    </div>
                    <div>
                      Maaari ka rin magbigay ng impormasyon tungkol sa isang ikatlong partido sa pamamagitan ng mga Serbisyo, halimbawa, kung ibinabahagi mo ang isang listahan ng real estate sa isang tatanggap sa pamamagitan ng email o SMS. Sa pagbibigay mo ng ganitong impormasyon sa amin, itinatangi mo sa amin na ikaw ay nakakuha ng pahintulot ng ikatlong partido na ibigay sa amin ang kanilang Personal na Data para sa mga layunin na nakasaad sa Patakaran sa Pagkapribado na ito.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.2.</div>
                  <div>
                    <strong>Cookies, Pixels, at iba pang mekanismong pangseguimiento</strong>. Kami at ang aming mga partner ay gumagamit ng iba't ibang teknolohiya upang awtomatikong magkolekta ng impormasyon kapag ikaw ay nag-access at gumagamit ng aming mga Serbisyo, kasama na ang mga cookies, at iba pang katulad na teknolohiya. Ang mga cookies ay mga piraso ng elektronikong impormasyon na maaaring ma-transfer sa iyong computer o iba pang elektronikong aparato upang uniquely ma-identify ang iyong browser. Kapag gumagamit ka ng mga Serbisyo, maaaring ilagay ng aming grupo at mga partner ang isa o higit pang mga cookies sa iyong computer o iba pang elektronikong aparato o gumamit ng iba pang mga teknolohiyang nagbibigay ng kaparehong pag-andar.
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>Maaaring gamitin namin at ang aming mga partner ang mga cookies upang i-link ang iyong aktibidad sa mga Serbisyo sa ibang impormasyon na nakaimbak tungkol sa iyo sa iyong account profile o sa mga nakaraang pakikipag-ugnayan mo sa aming mga Serbisyo, upang halimbawa, itago ang iyong mga preference. Ang paggamit ng cookies ay tumutulong sa amin na mapabuti ang kalidad ng aming mga Serbisyo sa iyo, sa pamamagitan ng pagkilala sa impormasyon na pinaka-interesado sa iyo, pagtukoy sa mga trend, pagsukat sa epektibong pamamaraan ng advertising, o pag-iimbak ng impormasyon na maaaring gusto mong ibalik sa regular na panahon, gaya ng iyong listahan ng shortlisted properties o mga paboritong search, at sa pamamagitan ng iba pang mga layunin na pinapayagan ng naaangkop na batas. Sa anumang oras, maaari mong ayusin ang mga setting sa iyong browser upang tanggihan ang mga cookies ayon sa mga tagubilin na may kaugnayan sa iyong browser. Gayunpaman, kung pipiliin mong i-disable ang mga cookies, maaaring hindi nang maayos na gumana ang maraming libreng tampok ng aming mga Serbisyo.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>Maaring maglaman din ang ilan sa aming mga pahina ng pixels, na mga elektronikong file na ginagamit upang bilangin ang mga user na bumisita sa pahinang iyon, subaybayan ang aktibidad sa paglipas ng panahon at sa iba't ibang mga website, alamin ang mga interaksyon ng mga user sa mga email na ipinadala namin, kilalanin ang ilang mga cookies sa computer o iba pang elektronikong aparato na nag-a-access sa pahinang iyon, o kolektahin ang iba pang kaugnay na impormasyon, at ang impormasyon na ito ay maaaring maiugnay sa iyong unique browser, device identifier, o IP address. Maaari rin kaming magpatupad ng isang pixel sa partikular na mga Serbisyo kung saan titingnan mo ang isang partikular na listahan upang masubaybayan namin kung ang listahang iyon ay may kinalaman sa iyo habang nag-aalok ng mga rekomendasyon. Sa pamamagitan ng mga pixels at cookies na ito, kami ay nagkakolekta ng impormasyon tungkol sa iyong paggamit ng aming mga Serbisyo, kabilang ang uri ng iyong ginagamit na browser, mga oras ng pag-access, mga pahinang tiningnan, iyong IP address, at ang pahina na binisita mo bago pumasok sa aming mga Serbisyo.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Kami rin ay nagkakolekta ng impormasyon tungkol sa computer o mobile device na iyong ginagamit upang ma-access ang aming mga Serbisyo, tulad ng hardware model, operating system at bersyon, unique device identifiers, mobile network information, at iyong pag-uugali sa pag-browse.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Pinapahintulutan din namin ang ilang mga ikatlong partido na magkolekta ng impormasyon tungkol sa iyong online na mga aktibidad sa pamamagitan ng mga cookies at iba pang mga teknolohiya kapag ikaw ay gumagamit ng Website. Ang mga ikatlong partido na ito ay kasama (a) mga kasosyo sa negosyo, na nagkakolekta ng impormasyon kapag ikaw ay bumubuksan o nakikipag-ugnayan sa isa sa kanilang mga advertisement sa Website, at (b) mga advertising network, na nagkakalat ng impormasyon tungkol sa iyong mga interes kapag ikaw ay bumubuksan o nakikipag-ugnayan sa isa sa mga advertisement na inilalagay nila sa maraming iba't ibang mga website sa Internet. Ang impormasyong kinokolekta ng mga ikatlong partido na ito ay hindi maaaring personal na matukoy na impormasyon na ginagamit upang gumawa ng mga hula tungkol sa iyong mga katangian, interes, o mga nais, at upang ipakita ang mga advertisement sa Website at sa Internet na angkop sa iyong mga interes.</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Maaari mong pamahalaan ang uri ng mga cookies na pinapayagan sa pamamagitan ng iyong mga setting sa browser, kabilang ang ganap na pag-block ng lahat ng mga cookies kung nais mo. Para sa impormasyon tungkol sa pagpapamahala ng cookies sa iyong browser, mangyaring tingnan ang bahagi ng tulong ng iyong ginagamit na browser.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.3.</div>
                  <div>
                    <strong>Impormasyon tungkol sa mobile device at mobile browser.</strong> Maaari mong baguhin ang mga setting sa iyong mobile device at mobile browser kaugnay ng mga cookies at pagbabahagi ng ilang impormasyon, gaya ng modelo ng iyong mobile device o ang wika na ginagamit ng iyong mobile device, sa pamamagitan ng pag-aayos sa mga setting sa privacy at seguridad ng iyong mobile device. Mangyaring tumukoy sa mga tagubilin na ibinigay ng iyong provider ng mobile service o tagagawa ng mobile device.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.4.</div>
                  <div>
                    <strong>Location Data.</strong> Kung pinagana mo ang mga location services sa iyong mobile device, kami, sa iyong pahintulot, ay maaaring kumolekta ng lokasyon ng iyong device na ginagamit namin upang magbigay sa iyo ng impormasyon at advertising batay sa lokasyon. Kung nais mo itong i-deactivate, maaari mong i-disable ang mga location services sa iyong mobile device.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.5.</div>
                  <div>
                   <strong>Email Management.</strong> Maaaring matanggap mo ang mga email mula sa amin para sa iba't ibang mga dahilan - halimbawa, kung ikaw ay gumawa ng aksyon sa pamamagitan ng mga Serbisyo, nag-sign up ka para sa regular na report, o nai-kumpleto mo ang iyong disenyo, at nagpapadala kami sa iyo ng mga mensahe upang subaybayan ang mga susunod na hakbang. Kung may account ka sa amin, maaari mong baguhin ang iyong mga preference sa pamamagitan ng iyong account settings. Maaari mo rin pamahalaan ang iyong pagtanggap ng ilang uri ng komunikasyon sa pamamagitan ng pagsunod sa mga tagubilin na kasama sa email na aming pinadala sa iyo. Pakiunawa na, kahit mag-unsubscribe ka sa ilang mga email correspondence, maaari pa rin kaming mag-email sa iyo ng mahahalagang impormasyon ukol sa transaksyon o administratibong impormasyon.
                  </div>
                </div>
              </div>
  
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>2.6.</div>
                  <div>
                    <strong>Disclaimer.</strong> Hindi kami mananagot para sa anumang kusang-loob na pagpapahayag ng Personal na Data mula sa iyo sa ibang mga user kaugnay ng paggamit ng Website, kung mayroon man.
                  </div>
                </div>
              </div>
            </div>
          `
    },
    {
      title: 'Layunin ng Paggamit',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    Ang Personal na Data na iyong ibinigay ay maaaring gamitin at asikasuhin ng amin (o ng mga ikatlong partido (tulad ng Google Analytics) para sa amin) sa kabuuan o sa indibidwal, at maaaring pagsamahin sa iba pang impormasyon para sa layunin ng pagpapamahala at pagpapatakbo ng Website, at maaaring isama ang sumusunod subalit hindi limitado sa mga sumusunod:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>Pamamahala sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>Pagpapabuti ng iyong karanasan sa pag-browse sa pamamagitan ng pag-personalize sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Pagpapagana sa iyo ng mga Serbisyo na magagamit sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Pagbibigay ng mga Serbisyo na iyong partikular na hiningi;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Pagtugon sa iyong mga katanungan o kahilingan batay sa iyong mga email at/o pagsusumite ng form sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Pagtugon at pagsasagawa ng mga hakbang ukol sa mga reklamo hinggil sa paggamit ng Website o anumang Serbisyo na ibinigay ng amin;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>Pagpapadala sa iyo ng pangkalahatang komersyal na komunikasyon sa anumang channel ng komunikasyon, hindi lamang sa email, tawag sa telepono, SMS, at mga abiso ng app;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>Pagpapatupad ng mga gawain sa opisina at operasyonal na administratibo;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>Pagbibigay ng estadistikong impormasyon tungkol sa aming mga gumagamit sa mga ikatlong partido. Ang gayong estadistikong impormasyon ay maaaring gamitin upang personalisahin ang advertising sa Website, ngunit hindi maaaring gamitin upang makilala ang anumang indibidwal na gumagamit;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>Paglikha at pamamahala ng iyong online account;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>Pagtataguyod ng iyong mga gawain at transaksyon sa Website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>Pagtataguyod ng iyong pakikilahok sa mga paligsahan o promosyon;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>Pag-handle ng mga pagbabayad, o mga refund mula/sa iyo;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>Pagbibigay sa iyo ng impormasyon tungkol sa mga Serbisyo na iyong hinihiling sa amin; o ayon sa iyong pahintulot, ang impormasyon na sa palagay namin ay maaaring magkainteres sa iyo sa pamamagitan ng email o text message;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>Pagpapatakbo, pagtatasa, at pagpapabuti ng aming negosyo (kasama ang pag-develop ng mga bagong produkto at serbisyo; pag-aanalisa at pagpapalakas ng aming mga Serbisyo);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>Pag-unawa sa aming mga customer at paggawa ng data analysis at iba pang pagproseso (kasama na ang pananaliksik sa merkado at consumer, pag-aaral ng trend, at anonimisasyon);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>Pagtugon sa iyong mga komento o katanungan o aplikasyon para sa empleyo (kung mayroon);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>Pagbahagi sa mga tagapagbigay ng serbisyo upang sila ay makapagbigay ng Serbisyo sa iyo; at ayon sa iba pang kinakailangan o pinahihintulutang batas.</div>
                      </div>
                    </div>
                  </div>
                </div>
                Maaari kang pumili na mag-browse sa Website at gamitin ang limitadong mga function nito nang hindi nagpapakilala ng iyong Personal na Data. Hindi kinakailangan na magbigay ng Personal na Data bilang kondisyon sa paggamit ng Website, maliban kung kinakailangan ito upang magkaroon kami ng kakayahang magbigay ng mga Serbisyo na binili o ina-access mo sa pamamagitan ng aming Website, tumugon sa iyong mga kahilingan, o sa mga sitwasyon kung saan ka direktang nakipag-ugnayan sa amin.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>Dahil ang mga layunin para sa pagkolekta, paggamit, pagbibigay, o pagproseso ng iyong Personal na Data ay nakadepende sa mga kalagayan sa oras na ito, maaaring hindi ipakita ang mga layunin na ito sa itaas. Gayunpaman, ipaalam namin sa iyo ang iba pang mga layunin sa oras ng pagkuha ng iyong pahintulot, maliban kung ang pagproseso ng iyong Personal na Data nang walang iyong pahintulot ay pinapayagan ng Personal Data Protection Act 2012 ng Singapore (ang "<strong>PDPA</strong>") o ng iba pang naaangkop na batas.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>Mga Patakaran Tungkol sa Hindi Pagtawag.</strong> Kung nagbigay ka sa amin ng iyong numero ng telepono ("Numeros") at nagpahayag na sumasang-ayon ka na matanggap ang impormasyong pang-merkado o iba pang promotional gamit ang iyong Numeros, maaari naming makipag-ugnay sa iyo gamit ang Numeros na iyon para sa mga impormasyong may kinalaman sa aming mga Serbisyo. Maaari pa rin kaming tumawag sa Numeros na iyon kahit ito ay naka-enroll sa Do Not Call registry (depende sa jurisprudence). Gayunpaman, kung ayaw mong makuhanan ng tawag para sa mga layuning iyon, magpadala ng sulat o kahit anong mensahe sa amin upang ipaabot ang iyong pagtanggi.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Katumpakan at Seguridad',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Siguraduhin na ang lahat ng Personal na Data na isinusumite mo sa amin ay kumpleto, tumpak, totoo, at wasto. Ang pagkukulang sa iyong bahagi na gawin ito ay maaaring magresulta sa hindi namin kakayahang magbigay sa iyo ng Mga Serbisyo na iyong hiningi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Susumikap kami na tiyakin na ang iyong Personal na Data ay tumpak at kumpleto kung inaasahan na gagamitin ito ng aming kumpanya upang magdesisyon na may epekto sa iyo o ibunyag sa ibang organisasyon. Gayunpaman, nangangahulugan din ito na kailangan mong i-update kami sa anumang pagbabago sa iyong Personal na Data na ibinigay mo sa amin sa simula. Hindi kami mananagot sa pagtitiwala sa maling o hindi kumpletong Personal na Data na nagmula sa hindi mo pag-update sa amin ng anumang pagbabago sa iyong Personal na Data na ibinigay mo sa amin sa simula.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Susumikap kaming magkaroon ng makatuwirang teknikal at organisasyonal na mga pag-iingat upang maiwasan ang pagkawala, pang-aabuso, o pagbabago ng iyong Personal na Data.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Itatago namin ang lahat ng Personal na Data na iyong ibinigay sa aming ligtas na mga server sa isang nakakodig na format.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Gayunpaman, nauunawaan mo na ang pagpapadala ng impormasyon sa pamamagitan ng Internet ay hindi lubos na ligtas. Bagaman gagawin namin ang aming makakaya upang protektahan ang iyong Personal na Data, hindi namin maaaring garantiyahin ang seguridad ng iyong Personal na Data na ipinadala sa Website; anumang pagpapadala ay nasa iyong sariling panganib. Bukod dito, hindi kami maaaring magkaroon ng pananagutan para sa anumang hindi awtorisadong paggamit ng iyong Personal na Data ng mga ikatlong partido na lubos na maaaring maipataw sa mga kadahilanan na nasa labas ng aming kontrol.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Kung ibinigay namin sa iyo (o kung napili mo) ang isang password na magbibigay-daan sa iyo na ma-access ang partikular na mga bahagi ng Website, ikaw ay responsable sa pag-iingat ng konpisyalidad ng password na ito. Hinihiling namin na huwag ibahagi ang password sa sinuman. HINDI kami manghihingi ng iyong password maliban kapag ikaw ay mag-log in sa Website, kung gayon man.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Hinihikayat ka namin na gumawa ng mga hakbang upang mapanatili ang kaligtasan ng iyong personal na impormasyon, tulad ng pagpili ng isang malakas na password at pananatiling pribado nito, pati na rin ang pag-logout mula sa iyong user account at pag-isara ng web browser kapag tapos ka nang gumamit ng aming Mga Serbisyo sa isang ibinahaging o hindi ligtas na aparato.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Pagpapahayag ng Personal na Impormasyon',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Hinding-hindi namin ipapahayag ang iyong Personal na Impormasyon sa mga ikatlong partido para sa mga layunin ng direktang marketing nang walang iyong malinaw na pahintulot. Ang pahintulot para sa pagpapahayag ng Personal na Impormasyon sa mga ikatlong partido para sa layunin ng direktang marketing ay nakuha sa pamamagitan ng aming mga form ng pagpaparehistro (online o papel).</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Upang pamahalaan at palakihin ang aming website, maaari naming ipahayag ang iyong Personal na Impormasyon sa mga miyembro ng aming grupo (kabilang ang mga kaakibat at subsidiary na kumpanya). Gayundin, maaari kaming magpahayag ng iyong Personal na Impormasyon sa mga ikatlong partido para sa mga sumusunod na layunin:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Para sa mga layunin na kinakailangan o pinapayagan ng batas o regulasyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Para sa mga layunin na kailangan namin para sa pagsunod sa aming mga tuntunin at kundisyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Para sa mga layunin na kailangan upang protektahan ang mga karapatan, ari-arian, at kaligtasan ng aming kumpanya, ng aming mga kaakibat at subsidiary na kumpanya, ng ibang mga user ng website, at ng ibang mga tao;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Para sa mga layunin na kailangan para sa anumang kasalukuyang o inaasahang proseso ng legal na kaso;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Para sa mga layunin na may kinalaman sa transaksyon ng mga negosyo (tulad ng mga pagbili, benta, pag-upa, pagmumuni-muni, pag-aayos, o iba pang uri ng pagpapasa ng ari-arian o transaksyon ng mga negosyo) na kami ay isang partido o isang partikular na interesado o maaaring maging partido o partikular na interesado</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Hinding-hindi namin ilalathalang ang iyong Personal na Impormasyon sa mga ikatlong partido nang walang iyong pahintulot. Gayunpaman, sa ilang mga kalagayan, maaari kaming maglathala ng iyong Personal na Impormasyon sa mga ikatlong partido nang walang iyong pahintulot para sa mga sumusunod na mga layunin:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Para sa mga layunin na kinakailangan o pinapayagan ng batas at/o regulasyon;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Para sa mga layunin na malinaw na may kinalaman sa iyong kapakanan at kung hindi maaaring makuhang pahintulot sa tamang oras;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Para sa mga layunin na kinakailangan upang tugunan ang isang emerhensiyang sitwasyon na nagbabanta sa buhay, kalusugan, o kaligtasan mo o ng iba;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Para sa mga layunin na may kinalaman sa imbestigasyon o legal na proseso;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Para sa mga layunin na kinakailangan upang ilathala ang iyong Personal na Impormasyon sa mga opisyal ng tagapagpatupad ng batas;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Para sa mga layunin na kinakailangan para sa kagalingan ng publiko; at/o</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Para sa mga layunin na posible sa ilalim ng PDPA o ng batas na hindi na kailangan ng pahintulot para sa gayong pagpapahayag</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Kapag naglalathala kami ng iyong Personal na Impormasyon sa mga ikatlong partido nang walang iyong pahintulot, susubukan naming tiyakin ang proteksyon ng iyong Personal na Impormasyon sa mga ikatlong partido.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Pag-atras ng Pahintulot',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Maaari mong bawiin ang iyong pahintulot sa pamamagitan ng pakikipag-ugnayan sa aming serbisyo sa customer sa <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Pakipansin na kung bawiin mo ang iyong pahintulot sa anumang o lahat ng paggamit ng iyong Personal na Datos, depende sa kalikasan ng iyong kahilingan, maaaring hindi kami makapagpatuloy sa pagbibigay ng aming mga Serbisyo sa iyo. Sa iyong abiso, ipaalam namin sa iyo ang mga malamang na kahihinatnan ng pag-atras ng pahintulot, kabilang ngunit hindi limitado sa pagtatapos ng anumang kasunduan na maaaring mayroon ka sa amin at ang iyong paglabag sa iyong mga obligasyon o pag-aayos sa kontrata. Ang aming mga legal na karapatan at lunas sa gayong pangyayari ay malinaw na itinatakdang iniwang bukas.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Pandaigdigang Paglilipat ng Datos',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Nagtatrabaho kami sa maraming bansa at maaaring pansamantalang magimbak, magproseso, o ilipat ang iyong Personal na Datos sa pagitan ng anumang mga bansa na kung saan kami nag-ooperate upang bigyan kami ng kakayahang gumamit ng iyong Personal na Datos alinsunod sa Polisiya sa Privacy na ito at sa aming Mga Tuntunin ng Paggamit.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Gagawin namin ang lahat ng mga kaukulang hakbang upang tiyakin na ang iyong Personal na Datos ay ililipat alinsunod sa anumang nalalapat na batas o regulasyon at gagawin namin ang lahat ng karampatang hakbang upang tiyakin na ang anumang dayuhang tatanggap ng iyong Personal na Datos ay binded sa legal na mabigat na mga obligasyon na magbigay ng proteksyon na hindi bababa sa proteksyon sa ilalim ng PDPA.</div>
              </div>
          </div>
      `
    },
    {
      "title": "Third Party Website",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  Ang Website ay maaaring maglaman ng mga link patungo at mula sa mga website ng aming partner networks at mga vendor. Kung susundan mo ang isang link patungo sa alinman sa mga website na ito, pakitandaan na mayroon silang kanilang sariling mga patakaran sa privacy at hindi kami tumatanggap ng anumang responsibilidad o pananagutan para sa mga patakaran na ito. Mangyaring suriin ang mga patakaran na ito bago mo isumite ang anumang Personal na Data sa mga website na ito.
                </div>
              </div>
            `
    },
    {
      "title": "Pag-access, Pagbura, at Pagwawasto",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>Ang aming Data Protection Officer ay responsable sa pagtiyak na ang mga kahilingan para sa pag-access, pagbura, o pagwawasto ng Personal na Data ay naaayon sa mga prosedyurong nakasaad dito.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>Ang lahat ng mga kahilingan para sa impormasyon o kahilingan para sa mga pagwawasto o pagbura ng personal na data na hawak ng amin ay gagawin sa pamamagitan ng pagpapasa ng isang kahilingan sa aming Data Protection Officer sa: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>Pakitandaan na tanging aming tatanggapin at aaksyunan ang mga kahilingang nasa wikang Ingles na sumusunod sa Privacy Policy na ito. Ang anumang kahilingan na hindi sumusunod sa itaas ay itatanggi, at hindi kami gagawa ng anumang aksyon ukol sa mga tinanggihan na kahilingan.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>Maaari kaming tumanggi na ibunyag, burahin, o baguhin ang anumang Personal na Data para sa mga dahilan na nakasaad sa PDPA.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>Hangga't maaari, tayo ay magbibigay ng tugon sa iyong kahilingan sa loob ng sampung (10) araw na pantrabaho mula sa petsa ng pagtanggap ng kahilingan. Kung hindi ito maaari, ipaalam sa iyo sa loob ng sampung (10) araw na pantrabaho mula sa petsa ng pagtanggap ng kahilingan.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Pag-iingat ng Iyong Personal na Data",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Iniingatan namin ang Personal na Data na aming kinolekta mula sa iyo kung kailan mayroon kaming patuloy na malawakang pangangailangan para sa negosyo na gawin ito. Kung kayo ay isang kliyente o vendor (o kinatawan ng isang kliyente o vendor), ang impormasyon ay itatabi sa isang takdang panahon upang masiguro na makapagbigay kami ng aming mga Serbisyo, sumunod sa naaangkop na legal na, buwis, o accounting na kinakailangan, at kung kinakailangan, para sa pagtatatag, paggawa, o pagtatanggol ng mga legal na claim.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Kapag wala kaming patuloy na malawakang pangangailangan para sa pagproseso ng iyong personal na impormasyon, ilalipat namin ito sa paglilipat sa ibang pangalan o, kung hindi ito maaaring magawa (halimbawa, dahil ang iyong personal na impormasyon ay naka-imbak sa mga backup archives), pagkatapos ay aming panatilihing ligtas ang iyong personal na impormasyon at hihiwalayin ito mula sa anumang karagdagang pagproseso hanggang maaari itong burahin.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Makipag-ugnayan sa Amin",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      Kung kinakailangan mo pa ng karagdagang impormasyon tungkol sa pagtrato ng iyong Personal na Data sa amin, huwag kang mag-atubiling makipag-ugnayan sa aming Data Protection Officer sa: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "Nagsasakupang Batas",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  Ang Privacy Policy na ito ay papairalin sa lahat ng aspeto batay sa batas ng Singapore.
                  </div>
                </div>
              `
    }
  ]
};
