import React from 'react';

const ThailandOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41152)">
        <path
          d="M11.0952 15H10.021V13.8839H7.83203V6.0986H10.0146V5H11.106C11.106 5.33319 11.1088 5.65853 11.1042 5.98362C11.1029 6.07743 11.1283 6.11088 11.2249 6.11663C11.3898 6.12656 11.5609 6.13335 11.717 6.18248C12.8547 6.53971 13.5038 7.71513 13.2274 8.89186C13.1508 9.21747 13.0103 9.50911 12.8038 9.77174C12.8596 9.81355 12.9078 9.84882 12.9554 9.88541C13.3373 10.1799 13.6037 10.5586 13.7432 11.0261C13.7801 11.1497 13.803 11.2775 13.8323 11.4034V11.9248C13.8241 11.9488 13.8123 11.9721 13.8079 11.9966C13.6515 12.8749 13.1583 13.465 12.3394 13.7744C12.0255 13.893 11.6973 13.8938 11.3687 13.8904C11.2853 13.8896 11.2018 13.8904 11.1062 13.8904C11.1062 13.9657 11.1067 14.0213 11.1062 14.077C11.1029 14.3848 11.0993 14.6927 11.0957 15.0005L11.0952 15ZM11.1132 12.7652C11.3336 12.7652 11.5426 12.7806 11.7486 12.762C12.1979 12.7213 12.511 12.4717 12.6667 12.0463C12.8228 11.6195 12.7342 11.2273 12.428 10.892C12.0623 10.4914 11.5873 10.5468 11.1129 10.5609V12.7652H11.1132ZM10.002 7.23352H8.93037V9.4294H10.002V7.23352ZM10.002 10.5698H8.93037V12.7657H10.002V10.5698ZM11.1139 9.42183C11.4712 9.45214 11.8617 9.22505 12.051 8.86887C12.2675 8.46095 12.2459 8.0572 11.9832 7.67985C11.7746 7.38064 11.4833 7.22777 11.1142 7.2228V9.42183H11.1139Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41152">
          <rect
            width="6"
            height="10"
            fill="white"
            transform="translate(7.83203 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThailandOutlined;
