import {
  setLocale,
  mixed,
  string,
  number as yupNumber,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from 'yup';
import moment, { Moment } from 'moment';

setLocale({
  mixed: {
    required: 'This field is required',
  },
});

const number = () => yupNumber().typeError('Invalid number');

export enum ValidationMessage {
  DECIMAL_PRECISION = 'Allow only 2 decimal places',
  MIN_MAX = 'Min < Max',
  DATE_FORMAT = 'This field must be valid date',
}

const commonFunc = {
  // validate old > 19
  validateYearOld: (message: string) => string().test('validateOld', message, (value) => {
    const day = moment(value)
    const yearOldInvalid = (dob: Moment | null): boolean => {
      return dob ? !((moment().year() - moment(dob).year()) < 19) : true
    }
    return yearOldInvalid(day)
  }),
};


const useYup = () => {
  return { mixed, string, number, boolean, bool, date, object, array, ref, lazy, commonFunc };
};

export default useYup;