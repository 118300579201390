import { isEmpty } from 'lodash';
import { getMeInfoPortalApi } from 'apis/userApis';

// CONSTANTS
import { SessionKey } from 'constants/storage';
import { Obj } from 'constants/types';


let accessToken: string;
let userInfo: Obj;

// Load user info and access token to local variables to reduce JSON.parse session
const loadAuth = () => {
  const sessionAuthData = sessionStorage.getItem(SessionKey.PortalToken);
  if (sessionAuthData) {
    JSON.parse(sessionAuthData)
    accessToken = JSON.parse(sessionAuthData).accessToken;
  }

  const sessionUserInfo = sessionStorage.getItem(SessionKey.PortalUserInfo);
  if (sessionUserInfo) {
    userInfo = JSON.parse(sessionUserInfo);
  }
}


export const setPortalAuth = (authData: Obj) => {
  sessionStorage.setItem(SessionKey.PortalToken, JSON.stringify(authData));
  accessToken = authData.accessToken;
}

// If user is not logged in, access token will be empty
export const getPortalAccessToken = () => {
  if (!accessToken) {
    loadAuth();
  }

  return accessToken;
}

export const getPortalUserInfo = () => {
  if (!userInfo) {
    loadAuth();
  }

  return userInfo;
}

export const fetchPortalUserInfo = async () => {
  if (isEmpty(accessToken)) {
    return {};
  }

  try {
    const r = await getMeInfoPortalApi();
    const { data: user } = r;
    sessionStorage.setItem(SessionKey.PortalUserInfo, JSON.stringify(user));
    userInfo = user;
    return user;
  } catch (e) {
    console.log(e);
  }

  return {};
}


export const portalLogin = async (accessToken: string) => {
  setPortalAuth({ accessToken });
  // Load user info
  await fetchPortalUserInfo();
}
