import React from 'react';

function IcTwitterLight(props: any) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 11.9495C1.72227 12.0457 3.22833 11.6459 4.69711 10.5871C3.21342 10.3353 2.18453 9.66149 1.62535 8.26945C2.05779 8.21021 2.43803 8.32128 2.88537 8.14357C1.42405 7.50679 0.477169 6.55161 0.43989 4.90041C0.902145 4.93743 1.25257 5.24101 1.80429 5.19659C0.462261 3.89339 0.0521901 2.47173 0.894688 0.746485C2.29637 2.34586 3.93663 3.43432 5.94222 3.94523C6.05406 3.97484 6.15843 4.00446 6.27027 4.02668C6.78471 4.14515 7.39609 4.3969 7.70923 4.35988C8.24604 4.29324 7.70923 3.67866 7.84344 2.87898C8.26841 0.39107 11.027 -0.741817 13.0699 0.716868C13.6664 1.14633 14.1361 1.13892 14.7251 0.879767C15.0308 0.746486 15.3439 0.613205 15.7092 0.45771C15.6272 1.16854 15.0755 1.56098 14.6356 2.0867C15.1351 2.19777 15.5377 2.05708 16 1.90899C15.8434 2.4199 15.4557 2.70868 15.1053 2.99005C14.74 3.27882 14.5983 3.58241 14.5834 4.05629C14.3597 11.2312 6.15099 16.7772 0.536818 12.3641C-0.00745029 11.9347 0.521901 12.3641 0 11.9495Z"
        fill="white"
      />
    </svg>
  );
}

export default IcTwitterLight;