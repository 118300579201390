export const POLICY_FR = (textBrand: string) => {
  return [
    {
      title: 'Introduction',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>nous</strong>", "<strong>notre</strong>" ou "<strong>${textBrand}</strong>"), est l'exploitant du site <a href="https://levitate.homes">https://levitate.homes</a> (le "<strong>Site web</strong>"), et nous nous engageons à protéger vos Données personnelles (telles que définies à la Clause 2 ci-dessous) conformément aux termes et conditions énoncés ici.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Cette Politique de confidentialité (ainsi que nos Conditions d'utilisation et tout autre document auquel il est fait référence) décrit comment les Données personnelles que nous collectons auprès de vous, ou que vous nous fournissez, seront utilisées, traitées ou divulguées par nous, y compris comment certaines informations seront utilisées pour personnaliser la publicité qui vous est adressée. Cette Politique de confidentialité décrit également les types de Données personnelles que nous collectons, comment nous les protégeons, ainsi que la manière dont vous pouvez accéder à vos Données personnelles stockées par nous ou les modifier. Cette Politique de confidentialité couvre votre utilisation du Site web et toute autre manière dont vous pouvez vous connecter ou interagir avec nous grâce à l'utilisation de nos produits et services (ci-après désignés collectivement sous le terme "<strong>Services</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Veuillez lire attentivement ce qui suit pour comprendre notre politique et nos pratiques concernant vos Données personnelles et la manière dont nous les traiterons.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>Sauf limitation imposée par la loi applicable, en utilisant le Site web et les Services, vous acceptez que toutes les Données personnelles vous concernant collectées par nous ou fournies par vous de temps à autre puissent être utilisées, traitées et divulguées à des fins et à des personnes telles que spécifiées dans cette Politique de confidentialité.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Dans le cadre de nos efforts visant à assurer une gestion, une protection et un traitement adéquats de vos Données personnelles, nous réviserons périodiquement nos politiques, procédures et processus. Nous pouvons modifier cette Politique de confidentialité à tout moment en publiant une version révisée sur le Site web. Nous nous efforcerons de vous informer en cas de modification de la politique, néanmoins, vous serez réputé avoir pris connaissance de la Politique de confidentialité modifiée si vous continuez à utiliser le Site web ou les Services après l'entrée en vigueur des modifications, que vous ayez reçu l'avis ou non. Nous vous rappelons de vérifier cette page régulièrement pour les mises à jour de cette Politique de confidentialité.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "Collecte de Données Personnelles",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              Vous pouvez choisir de parcourir le site Web et d'utiliser ses fonctions limitées sans divulguer vos Données Personnelles. Vous n'êtes pas obligé de fournir des Données Personnelles comme condition d'utilisation du site Web, sauf si cela est nécessaire pour que nous puissions fournir les Services que vous achetez ou auxquels vous accédez via notre site Web, répondre à vos demandes, ou dans les cas où vous nous contactez directement.
            </div>
            <div>
              Lorsque vous utilisez nos Services, nous collectons différentes informations vous concernant, vos appareils et votre interaction avec les Services. Certaines de ces informations vous identifient directement ou peuvent être utilisées pour vous identifier de manière lâche lorsqu'elles sont combinées avec d'autres données. Toute donnée, qu'elle soit vraie ou non, concernant une personne identifiable à partir de ces données; ou à partir de ces données et d'autres informations auxquelles l'organisation a ou est susceptible d'avoir accès, est considérée comme des données personnelles ("<strong>Données Personnelles</strong>").
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>Informations que vous fournissez volontairement</strong>. Lorsque vous utilisez nos Services, il se peut que l'on vous demande de fournir des informations personnelles vous concernant, telles que, entre autres, votre nom, vos coordonnées, vos informations de paiement, des détails sur votre domicile ou des propriétés qui vous intéressent, des informations financières, vos favoris ou vos styles. Cela peut se produire, par exemple :
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Lorsque vous effectuez des transactions avec nous sur ou en relation avec le site Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Lorsque vous créez un compte chez nous sur le site Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Lorsque vous contactez notre service clientèle ou nos vendeurs par e-mail, téléphone ou en personne, pour résoudre tout problème que vous pourriez rencontrer ou pour des demandes de renseignements ou des demandes;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Lorsque vous vous abonnez aux services que nous proposons sur le site Web, ou pour nos notifications par e-mail et/ou nos bulletins d'information;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Lorsque vous fournissez des informations pertinentes ou laissez des commentaires sur le site Web dans le but d'utiliser les Services;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Lorsque vous correspondez avec un professionnel de l'immobilier (tel qu'un agent immobilier ou courtier, prêteur hypothécaire ou agent de crédit, gestionnaire immobilier, investisseur, constructeur de maisons ou autres) via les Services; et</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Lorsque vous remplissez d'autres formulaires ou effectuez d'autres transactions, telles qu'une demande d'informations sur un prêt, une demande de vérification des antécédents (si applicable).</div>
                    </div>
                  </div>
                  <div>
                    Vous pouvez également fournir des informations sur un tiers via les Services, par exemple si vous partagez une annonce immobilière avec un destinataire par e-mail ou SMS. En fournissant de telles informations, vous déclarez que vous avez obtenu le consentement du tiers pour nous fournir leurs Données Personnelles aux fins énoncées dans cette Politique de Confidentialité.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Cookies, Pixels et autres mécanismes de suivi</strong>. Nous et nos partenaires utilisons différentes technologies pour collecter automatiquement des informations lorsque vous accédez et utilisez nos Services, y compris des cookies et d'autres technologies similaires. Les cookies sont des fragments d'informations électroniques qui peuvent être transférés vers votre ordinateur ou autre appareil électronique pour identifier de manière unique votre navigateur. Lorsque vous utilisez les Services, nous et nos partenaires pouvons placer un ou plusieurs cookies sur votre ordinateur ou autre appareil électronique ou utiliser d'autres technologies offrant une fonctionnalité similaire.
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Nous et nos partenaires pouvons utiliser des cookies pour relier votre activité sur les Services à d'autres informations que nous stockons à votre sujet dans votre profil de compte ou vos interactions antérieures avec nos Services, par exemple pour enregistrer vos préférences. L'utilisation des cookies nous aide à améliorer la qualité de nos Services pour vous, en identifiant les informations qui vous intéressent le plus, en suivant les tendances, en mesurant l'efficacité de la publicité ou en stockant les informations que vous souhaitez récupérer régulièrement, telles que vos propriétés présélectionnées ou vos recherches préférées, et en effectuant d'autres actions autorisées par la législation applicable. À tout moment, vous pouvez ajuster les paramètres de votre navigateur pour refuser les cookies selon les instructions relatives à votre navigateur. Cependant, si vous choisissez de désactiver les cookies, de nombreuses fonctionnalités gratuites des Services pourraient ne pas fonctionner correctement.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Certaines de nos pages peuvent également inclure des pixels, qui sont des fichiers électroniques pour compter les utilisateurs qui ont visité cette page, pour suivre l'activité dans le temps et sur différents sites Web, pour déterminer les interactions des utilisateurs avec les e-mails que nous envoyons, pour identifier certains cookies sur l'ordinateur ou autre appareil électronique accédant à cette page, ou pour collecter d'autres informations connexes, et ces informations peuvent être associées à votre navigateur unique, votre identifiant d'appareil ou votre adresse IP. Par exemple, nous pouvons implémenter un pixel sur des Services spécifiques où vous consultez une annonce particulière, afin de suivre si l'annonce vous intéresse et de vous faire des recommandations. Grâce à ces pixels et cookies, nous collectons des informations sur votre utilisation de nos Services, y compris le type de navigateur que vous utilisez, les heures d'accès, les pages consultées, votre adresse IP et la page que vous avez visitée avant de naviguer vers nos Services.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Nous collectons également des informations sur l'ordinateur ou l'appareil mobile que vous utilisez pour accéder à nos Services, telles que le modèle du matériel, le système d'exploitation et la version, les identifiants uniques de l'appareil, les informations sur le réseau mobile et le comportement de navigation.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Nous permettons également à certaines tierces parties de collecter des informations sur vos activités en ligne via des cookies et d'autres technologies lorsque vous utilisez le site Web. Ces tierces parties incluent (a) des partenaires commerciaux, qui collectent des informations lorsque vous visualisez ou interagissez avec l'une de leurs publicités sur le site Web, et (b) des réseaux publicitaires, qui collectent des informations sur vos intérêts lorsque vous visualisez ou interagissez avec l'une des publicités qu'ils placent sur de nombreux sites Web différents sur Internet. Les informations recueillies par ces tierces parties sont des informations non personnellement identifiables utilisées pour faire des prédictions sur vos caractéristiques, intérêts ou préférences et pour afficher des publicités sur le site Web et sur Internet qui correspondent à vos intérêts.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Vous pouvez gérer le type de cookies autorisés via les paramètres de votre navigateur, y compris bloquer complètement tous les cookies si vous le souhaitez. Pour des informations sur la gestion des cookies sur votre navigateur, veuillez consulter la section d'aide du navigateur que vous utilisez.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>Informations des appareils mobiles et des navigateurs mobiles.</strong> Vous pouvez ajuster les paramètres de votre appareil mobile et de votre navigateur mobile concernant les cookies et le partage de certaines informations, telles que le modèle de votre appareil mobile ou la langue que votre appareil mobile utilise, en ajustant les paramètres de confidentialité et de sécurité de votre appareil mobile. Veuillez vous référer aux instructions fournies par votre fournisseur de services mobiles ou le fabricant de votre appareil mobile.
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>Données de localisation.</strong> Si vous activez les services de localisation sur votre appareil mobile, nous pouvons, avec votre permission, collecter la localisation de votre appareil que nous utilisons pour vous fournir des informations et des publicités basées sur la localisation. Si vous souhaitez désactiver cette fonctionnalité, vous pouvez désactiver les services de localisation sur votre appareil mobile.
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                  <strong>Gestion des e-mails.</strong> Vous pouvez recevoir des e-mails de notre part pour diverses raisons, par exemple si vous avez effectué une action via les Services, vous vous êtes inscrit à un rapport régulier, ou vous avez terminé votre conception, et nous vous envoyons des messages pour suivre les prochaines étapes. Si vous avez un compte chez nous, vous pouvez modifier vos préférences via les paramètres de votre compte. De plus, vous pouvez gérer la réception de certains types de communications en suivant les instructions incluses dans l'e-mail que nous vous envoyons. Veuillez noter que, même si vous vous désabonnez de certaines correspondances par e-mail, nous pouvons toujours être amenés à vous envoyer des e-mails contenant des informations importantes sur les transactions ou l'administration de votre compte.
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>Exonération de responsabilité.</strong> Nous ne sommes pas responsables de la divulgation volontaire de Données Personnelles par vous à d'autres utilisateurs dans le cadre de l'utilisation du site Web, le cas échéant.
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: `But de l'utilisation`,
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    Les données personnelles que vous fournissez peuvent être utilisées et traitées par nous (ou par des tiers (comme Google Analytics) pour notre compte), collectivement ou individuellement, et peuvent être combinées avec d'autres informations dans le but de gérer et d'exploiter le site Web, dont la portée peut inclure, mais sans s'y limiter, les éléments suivants :
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>Administration du site Web ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>Amélioration de votre expérience de navigation en personnalisant le site Web ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Activation de votre utilisation des services disponibles sur le site Web ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Fourniture des services que vous avez spécifiquement demandés ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Réponse à vos demandes ou requêtes suite à vos courriels et/ou soumissions de formulaire(s) sur le site Web ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Réponse et suivi des plaintes concernant l'utilisation du site Web ou de tout service fourni par nous ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>Envoi de communications commerciales générales via tout canal de communication, y compris par courriel, appel téléphonique, SMS et notifications d'application ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>Exécution des tâches administratives au bureau et opérationnelles ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>Fourniture d'informations statistiques à des tiers concernant nos utilisateurs. Ces informations statistiques peuvent être utilisées pour personnaliser la publicité sur le site Web, mais ne peuvent pas être utilisées pour identifier un utilisateur spécifique ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>Création et gestion de votre compte en ligne ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>Facilitation de vos activités et transactions sur le site Web ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>Facilitation de votre participation à des concours ou promotions ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>Gestion du paiement ou du remboursement de ou vers vous ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>Fourniture d'informations sur les services que vous nous demandez ; ou sous réserve de votre consentement préalable, des informations que nous estimons susceptibles de vous intéresser par courrier électronique ou message texte ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>Exploitation, évaluation et amélioration de notre activité (y compris le développement de nouveaux produits et services ; l'analyse et l'amélioration de nos services) ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>Compréhension de nos clients et réalisation d'analyses de données et autres traitements (y compris la recherche sur le marché et les consommateurs, l'analyse des tendances et l'anonymisation) ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>Réponse à vos commentaires ou questions ou à votre demande d'emploi (le cas échéant) ;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>Partage avec les prestataires de services afin qu'ils puissent vous fournir les services ; et comme requis ou autorisé par la loi.</div>
                      </div>
                    </div>
                  </div>
                </div>
                Vous pouvez choisir de parcourir le site Web et d'utiliser ses fonctions limitées sans divulguer vos données personnelles. Vous n'êtes pas obligé de fournir des données personnelles comme condition d'utilisation du site Web, sauf si cela est nécessaire pour que nous puissions vous fournir les services que vous achetez ou accédez via notre site Web, répondre à vos demandes, ou dans les cas où vous nous contactez directement.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>Étant donné que les finalités pour lesquelles nous pouvons collecter, utiliser, divulguer ou traiter vos données personnelles dépendent des circonstances, ces finalités peuvent ne pas être mentionnées ci-dessus. Cependant, nous vous informerons de ces autres finalités au moment de recueillir votre consentement, sauf si le traitement de vos données personnelles sans votre consentement est autorisé par la Personal Data Protection Act 2012 de Singapour ("<strong>PDPA</strong>") ou par toute autre loi applicable.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>Dispositions concernant les Appels Non Sollicités.</strong> Si vous nous avez communiqué votre numéro de téléphone (les "<strong>Numéros</strong>") et avez indiqué que vous consentez à recevoir des informations marketing ou promotionnelles via vos Numéros, alors de temps à autre, nous pouvons vous contacter en utilisant ces Numéros avec des informations sur nos services, même si ces Numéros sont enregistrés auprès du registre des Appels Non Sollicités (le cas échéant, conformément à votre juridiction respective). Vous pouvez toutefois nous informer par écrit si vous ne souhaitez pas être contacté par nous sur vos Numéros à ces fins.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Précision et Sécurité',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Vous devez vous assurer que toutes les Données Personnelles soumises à nous sont complètes, précises, véridiques et correctes. Votre défaut de le faire peut entraîner notre incapacité à vous fournir les Services que vous avez demandés.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Nous ferons des efforts raisonnables pour nous assurer que vos Données Personnelles sont exactes et complètes si vos Données Personnelles sont susceptibles d'être utilisées par nous pour prendre une décision vous concernant ou divulguées à une autre organisation. Cela signifie cependant que vous devez également nous informer de tout changement dans vos Données Personnelles que vous nous avez initialement fournies. Nous ne serons pas responsables de l'utilisation de Données Personnelles inexactes ou incomplètes résultant de votre omission de nous informer de tout changement dans vos Données Personnelles que vous nous avez initialement fournies.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Nous prendrons des précautions techniques et organisationnelles raisonnables pour éviter la perte, l'usage abusif ou l'altération de vos Données Personnelles.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Nous stockerons toutes les Données Personnelles que vous fournissez sur des serveurs sécurisés, dans un format crypté.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Toutefois, vous comprenez que la transmission d'informations par Internet n'est pas totalement sécurisée. Bien que nous fassions de notre mieux pour protéger vos Données Personnelles, nous ne pouvons garantir la sécurité de vos Données Personnelles transmises au Site Web ; toute transmission est à vos propres risques. De plus, nous ne pouvons assumer la responsabilité de toute utilisation non autorisée de vos Données Personnelles par des tiers qui est totalement attribuable à des facteurs indépendants de notre contrôle.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Lorsque nous vous avons donné (ou si vous avez choisi) un mot de passe vous permettant d'accéder à certaines parties du Site Web, vous êtes responsable de la confidentialité de ce mot de passe. Nous vous demandons de ne pas partager un mot de passe avec quiconque. NOUS ne vous demanderons JAMAIS votre mot de passe, sauf lorsque vous vous connectez au Site Web, le cas échéant.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Nous vous recommandons de prendre des mesures pour protéger vos informations personnelles, telles que choisir un mot de passe fort et le garder privé, ainsi que de vous déconnecter de votre compte utilisateur et de fermer votre navigateur Web lorsque vous avez fini d'utiliser nos Services sur un appareil partagé ou non sécurisé.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Divulgation des Données Personnelles',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Nous ne divulguerons pas, sans votre consentement explicite, vos Données Personnelles à des tiers à des fins de marketing direct. Le consentement pour la divulgation des Données Personnelles à des tiers, dans le cadre de la réception de communications marketing, est donné par le biais de notre formulaire d'inscription, que ce soit en ligne ou sur papier.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Pour gérer et exploiter le Site Web, nous pouvons divulguer vos Données Personnelles à tout membre de notre groupe, ce qui comprend nos filiales et nos sociétés affiliées. Nous pouvons également divulguer vos Données Personnelles à des tiers tels que nos prestataires de services et conseillers professionnels aux fins suivantes:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Pour toute fin autorisée par toute loi ou réglementation applicable;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Pour se conformer à toute exigence légale;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Pour faire respecter ou appliquer nos Conditions d'Utilisation;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Pour protéger nos droits, propriétés et sécurité, ainsi que les droits, propriétés et sécurité de nos filiales et sociétés affiliées, autres utilisateurs du Site Web et autres personnes;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Dans le cadre de toute procédure judiciaire ou procédure judiciaire potentielle; et</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Dans le cadre d'une transaction d'actifs d'entreprise (c'est-à-dire l'achat, la vente, la location, la fusion ou la fusion ou toute autre acquisition, cession ou financement d'une organisation ou d'une partie d'une organisation ou de l'une quelconque des activités ou actifs d'une organisation) à laquelle nous sommes partie ou partie prenante.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Nous ne divulguerons pas non plus vos Données Personnelles à des tiers sans obtenir au préalable votre consentement nous permettant de le faire. Cependant, veuillez noter que nous pouvons divulguer vos Données Personnelles à des tiers sans obtenir au préalable votre consentement dans certaines situations, notamment, sans s'y limiter, les situations suivantes:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Cas où la divulgation est requise ou autorisée en vertu des lois et/ou réglementations applicables;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Cas où le but d'une telle divulgation est clairement dans votre intérêt, et si le consentement ne peut pas être obtenu en temps opportun;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Cas où la divulgation est nécessaire pour répondre à une urgence mettant en danger la vie, la santé ou la sécurité de vous-même ou d'une autre personne;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Cas où la divulgation est nécessaire pour toute enquête ou procédure;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Cas où les Données Personnelles sont divulguées à tout agent d'une agence de maintien de l'ordre prescrite;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Cas où la divulgation est à un organisme public et une telle divulgation est nécessaire dans l'intérêt public; et/ou</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Cas où une telle divulgation sans votre consentement est autorisée par la PDPA ou par la loi.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Lorsque nous divulguons vos Données Personnelles à des tiers sans votre consentement, nous mettrons tout en œuvre pour exiger que ces tiers protègent vos Données Personnelles.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Retrait du Consentement',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Vous pouvez retirer votre consentement en contactant notre service clientèle à l'adresse <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Veuillez noter que si vous retirez votre consentement à toute utilisation de vos Données Personnelles, en fonction de la nature de votre demande, nous pourrions ne pas être en mesure de continuer à vous fournir nos Services. Sur votre notification, nous vous informerons des conséquences probables du retrait du consentement, y compris, mais sans s'y limiter, la résiliation de tout accord que vous pourriez avoir avec nous et le fait que vous enfreigniez vos obligations ou engagements contractuels. Nos droits et recours juridiques dans un tel cas sont expressément réservés.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Transfert International de Données',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Nous opérons dans de nombreux pays et pouvons stocker, traiter ou transférer temporairement vos Données Personnelles entre l'un de ces pays afin de nous permettre d'utiliser vos Données Personnelles conformément à cette Politique de Confidentialité et à nos Conditions d'Utilisation.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Nous prendrons toutes les mesures raisonnablement nécessaires pour garantir que vos Données Personnelles soient transférées conformément aux lois ou réglementations applicables et prendrons toutes les mesures raisonnables pour garantir que tout destinataire étranger de vos Données Personnelles soit lié par des obligations légalement contraignantes pour fournir aux Données Personnelles transférées un niveau de protection au moins comparable à la protection en vertu de la PDPA.</div>
              </div>
          </div>
      `
    },
    {
      "title": "Site Web de Tiers",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  Le Site Web peut contenir occasionnellement des liens vers les sites de nos réseaux partenaires et fournisseurs. Si vous suivez un lien vers l'un de ces sites, veuillez noter que ces sites ont leurs propres politiques de confidentialité et que nous n'acceptons aucune responsabilité quant à ces politiques. Veuillez vérifier ces politiques avant de soumettre des données personnelles à ces sites.
                </div>
              </div>
            `
    },
    {
      "title": "Accès, Suppression et Correction",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>Notre responsable de la protection des données est chargé de veiller à ce que les demandes d'accès, de suppression ou de rectification des données personnelles soient traitées conformément aux procédures spécifiées ici.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>Toutes les demandes d'informations ou de modifications ou suppressions de données personnelles détenues par nous seront faites en soumettant une demande à notre responsable de la protection des données à l'adresse suivante : <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>Veuillez noter que nous n'accepterons et n'agirons que sur les demandes en langue anglaise conformes à cette politique de confidentialité. Toute demande qui ne respecte pas ce qui précède sera rejetée, et nous ne prendrons aucune mesure à l'égard de ces demandes rejetées.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>Nous pouvons refuser de divulguer, supprimer ou modifier des données personnelles pour les raisons énoncées dans le PDPA.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>Dans la mesure du possible, nous répondrons à votre demande dans un délai de dix (10) jours ouvrables à compter de la date de réception de la demande. Si cela n'est pas possible, vous en serez informé dans un délai de dix (10) jours ouvrables à compter de la date de réception de la demande.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Conservation de vos données personnelles",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Nous conservons les données personnelles que nous recueillons auprès de vous lorsque nous avons un besoin commercial légitime continu de le faire. Si vous êtes un client ou un fournisseur (ou un représentant d'un client ou d'un fournisseur), les informations seront conservées pendant une période nous permettant de fournir nos services, de respecter les exigences légales, fiscales ou comptables applicables et, si nécessaire, pour l'établissement, l'exercice ou la défense de demandes légales.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Lorsque nous n'avons pas de besoin commercial légitime continu de traiter vos informations personnelles, nous les supprimerons ou les anonymiserons, ou si cela n'est pas possible (par exemple, parce que vos informations personnelles ont été stockées dans des archives de sauvegarde), nous stockerons vos informations personnelles de manière sécurisée et les isolerons de tout traitement ultérieur jusqu'à ce que la suppression soit possible.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Contactez-nous",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      Si vous avez besoin de plus d'informations sur le traitement de vos données personnelles par nous, n'hésitez pas à contacter notre responsable de la protection des données à l'adresse suivante : <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "Droit applicable",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  Cette politique de confidentialité sera régie en tous points par les lois de Singapour.
                  </div>
                </div>
              `
    }
  ]
};
