export const TERM_TL = (textBrand: string) => {
  return [
    {
      title: 'Pagtanggap ng Mga Tuntunin',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>Sa pamamagitan ng pag-access at paggamit sa <a href="https://levitate.homes">https://levitate.homes</a> (ang "Website"), nakakamit mo ang isang legal na nakakabit na kasunduan sa mga tuntunin at kondisyon na ito ("Mga Tuntunin ng Paggamit") sa amin, ${textBrand} International Pte. Ltd. ("kami", "amin" o "${textBrand}"), ang may-ari at tagapangasiwa ng Website.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>Walang limitasyon o kundisyon, tinatanggap mo ang Mga Tuntunin ng Paggamit, kasama ang Patakaran sa Privacy.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>Maaari naming baguhin ang Mga Tuntunin ng Paggamit anumang oras sa pamamagitan ng pag-post ng isang nabagong bersyon sa Website. Gayunpaman, hindi kami magpapadala ng indibidwal na abiso sa iyo. Papalitan ng anumang nabagong Mga Tuntunin ng Paggamit ang lahat ng naunang bersyon nito. Kung hindi ka sumasang-ayon sa mga pagbabago o pagsasawimpalad sa Mga Tuntunin ng Paggamit, agad kang dapat huminto sa paggamit ng anumang mga serbisyo sa Website. Pagkatapos na magkaroon ng gayong mga pagbabago, ituturing naming ang patuloy mong paggamit ng Website bilang pagtanggap at pagsang-ayon mo sa mga pagbabagong iyon. Maaari mong tingnan ang pinakabagong bersyon ng Mga Tuntunin ng Paggamit anumang oras.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>Mula sa panahon sa panahon, magpo-post kami sa Website ng mga alituntunin at patakaran na may kaugnayan sa paggamit ng Website. Ang lahat ng gayong mga alituntunin o patakaran ay itinuturing nang isinama sa pamamagitan ng sanggunian sa Mga Tuntunin ng Paggamit.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Paggamit ng Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>Sumasang-ayon ka na susundin ang lahat ng mga termino at kundisyon sa ilalim ng Kasunduang ito sa paggamit ng Website. Maaring naming tanggalin o alisin ang anumang nilalaman na iyong ibinigay sa Website kung sa aming tantiya, ikaw ay lumabag sa mga terminong ito.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>
              Sa paggawa ng anumang transaksyon sa amin kaugnay ng Website o sa pagrehistro ng isang akawnt sa amin sa ilalim ng Website, pinanunumpaan mong:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>May legal na karapatan kang magtaksil ng transaksyon sa iyong sarili;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Ginagamit mo ang iyong tunay na pagkakakilanlan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Ang personal na data na iyong ibinigay sa amin ay totoo, wasto, kumpleto, at napapanahon; at</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Ipag-iingat at papapanatiliin mo ang personal na data na iyong ibinigay at ito ay totoo, wasto, kumpleto, at napapanahon</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>Ang mga menor de edad (mga gumagamit na hindi pa nararating ang takdang gulang na itinakda ng batas sa inyong bansa) ay hindi maaaring gumamit ng Website o magbigay ng anumang personal na data sa Website na walang supervisyon. Kung ikaw ay menor de edad, maaari kang gumamit ng Website at/o magbigay ng anumang personal na data sa Website lamang pagkatapos makakuha ng pahintulot mula sa iyong mga magulang/o tagapag-alaga o kung ikaw ay nasa ilalim ng kanilang pangangalaga.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>Kung nagbigay kami sa iyo (o kung iyong pinili) ng password na nagbibigay-daan sa iyo na makapasok sa ilang bahagi ng Website, kinikilala mo na ikaw ay ganap na responsable sa pag-iingat ng pagiging lihim ng iyong password at sa lahat ng mga aktibidad na magaganap sa ilalim ng iyong account. Wala kaming responsibilidad na makumpirma kung ang gumagamit na naka-log in at gumagamit ng Website ay ikaw o hindi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.5.</div>
            <div>Sumasang-ayon kang ipagbigay-alam sa amin ang anumang paglabag sa seguridad ng iyong password.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.6.</div>
            <div>Wala kaming pananagutan para sa anumang pagkawala o pinsala na maaaring maging bunga ng iyong hindi pagsunod sa Seksyon 2.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.7.</div>
            <div>Hindi ka pinahihintulutan na mag-frame o gumamit ng anumang teknikal na frame upang palibutan ang alinmang mga tatak pangkalakalan, logo, o iba pang impormasyon ng aming pag-aari at mga kaugnay na kumpanya ("Mga Kaugnay na Kumpaya") na walang pahintulot mula sa amin. Ang anumang hindi awtorisadong paggamit ay magpapawalang-bisa sa pahintulot o pahintulutang ibinigay namin.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Materyal ng Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>Sa pamamagitan ng pag-access at paggamit sa Website, maaaring maharap ka sa impormasyon, data, teksto, software, mga litrato, grapiks, mga video, mensahe, konsepto, ideya, disenyo, mga guhit, listahan ng produkto, presyo, o iba pang materyal na maaaring i-upload, i-post, ipadala sa email, ipasa, o ibang paraan maipagamit sa Website ("Materyal ng Website"), maging pampubliko o pribado.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>Naiintindihan mo na ang Materyal ng Website ay maaaring nakasasakit ng damdamin, hindi kanais-nais, o nakakabastos.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>Naiintindihan mo na ang Materyal ng Website ay maaaring gamitin lamang para sa iyong sariling personal at hindi-komersyal na layunin, at hindi para sa paglathala, pamamahagi, pagpapadala, pagpapadala muli, pagre-redistribute, broadcast, pagreproduksiyon, o pagpapalaganap sa ibang tao sa parehong kumpanya o organisasyon, at hindi para i-post sa ibang mga website o forum, newsgroup, mga mailing list, electronic bulletin board, o internet relay chats na pinatatakbo ng ibang mga website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>Sumasang-ayon ka na huwag magreproduksyon, kumopya, magbenta, muling magbenta, mag-display, o ibang paraan na magbigay ng Materyal ng Website sa ibang website o server nang hindi nagkaroon ng aming pahintulot na nakasulat.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>Sa alinman pong kadahilanang di-mabilang, hindi kami mananagot sa anumang mga pagkakamali o pagkukulang sa anumang Materyal ng Website, o sa anumang pagkawala o pinsala ng alinmang uri na naranasan dahil sa paggamit ng anumang Materyal ng Website na ipinaskil, ipinadala, ipinasa, o ibinigay sa Website.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div:">Kinikilala at sumasang-ayon ka na maaaring baguhin anumang oras ang format at nilalaman ng Website nang walang paunang abiso; maaring itigil o ipahinto ang operasyon ng Website para sa suporta o pagmamatyag trabaho, upang i-update ang nilalaman, o dahil sa anumang ibang rason; at maaring kanselahin ang access sa Website, maging para sa partikular na mga gumagamit o pangkalahatang access, anumang oras at walang paunang abiso.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Kasiguraduhan ng Impormasyon sa Aming Website',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Bagaman gumagawa ang ${textBrand} ng makatuwirang pagsisikap na isama ang tumpak na impormasyon sa Website, walang garantiya o representasyon ang aming ipinahayag hinggil sa tumpak na impormasyon ng anumang impormasyong ibinigay, kahit na ipinahiwatig o hindi, sa saklaw na pinapayagan ng batas. Inihihiwalay ng ${textBrand} ang lahat ng pananagutan para sa anumang pagkakamali o pagkaligtaan sa nilalaman ng Website sa pinakamalawak na saklaw na pinapayagan ng batas.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Ang mga partikular na makikita sa Website ay para lamang sa layuning panggabay. Ang tumpak na impormasyon ng anumang mga partikular, paglalarawan, dimensyon, sanggunian sa kalagayan, kinakailangang pahintulot para sa paggamit at pag-okupa, at iba pang mga detalye na makikita sa Website ay hindi tiyak at para lamang sa pangkalahatang layuning panggabay. Pinapayuhan kang hindi umasa sa mga ito bilang mga pahayag ng katotohanan o representasyon at patunayan ang mga ito para sa iyong sariling kasiguraduhan.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Inihihiwalay ng ${textBrand} na dahil ang pagbili ng tunay na ari-arian ay isang malaking komitment pang-pinansyal, dapat kang gumawa ng lahat ng makatuwirang hakbang upang matiyak na ang anumang ari-arian na iyong pinaplano na bilhin ay angkop para sa iyong mga pangangailangan sa lahat ng aspeto. Ginawa namin ang mga makatuwirang hakbang upang matiyak na ang mga materyales na makikita sa Website ay tumpak, ngunit hindi ito dapat maging kapalit sa iyong mga katanungan o pag-iisip. Pinapayuhan kang gumawa ng angkop na hakbang upang patunayan ang anumang impormasyong nais mong pagkatiwalaan.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Kahit ginagawa namin ang lahat ng pagsisikap upang magbigay ng tumpak na paglalarawan ng bawat kulay at disenyo ng bawat produkto sa Website, maaaring may kaunting pagkakaiba ang tunay na kulay at disenyo, dahil sa iba't ibang mga setting ng screen ng aparato, ang liwanag sa lokasyon ng instalasyon, kaunting pagkakaiba sa pagtatapos ng produkto sa paglipas ng panahon, at iba pang mga kadahilanan. Hindi tatanggapin ng ${textBrand} ang pananagutan para sa anumang pagkakaiba ng kulay o disenyo na hindi namin pagkakamali. Kapag binili mo ang ari-arian o produkto gamit ang Website, sumasang-ayon ka na tanggapin ang maliit na panganib na mayroong kaunting pagkakaiba sa pagitan ng tunay na kulay at disenyo at ang pagpapakita sa Website.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Obligasyon ng Gumagamit',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>5.1.</div>
                <div>
                  Maliban sa mga obligasyong nakatakda sa kasunduang ito, hindi dapat gawin ang sumusunod na mga gawain:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Paggamit ng website nang labag sa kaugalian;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Pagpapahamak, pang-aabuso, pang-iistorbo, pagmamalupit, pagmamataas, pagbabaon ng dangal, pagbabanta, o iba pang pag-atake laban sa ibang tao;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Pagpapahamak o pagpapahirap sa ibang gumagamit, ikatlo, o ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Pagpapa-rehistro nang labag sa tama o pagkakaroon ng maraming rehistro;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Paglahok sa immorál o potensyal na immorál na gawain, kasama na ang prostitusyon o pornograpiya;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Pagpapanggap bilang ibang indibidwal o grupo, o pagbibigay ng maling pahayag tungkol sa iyong relasyon sa indibidwal o grupo na iyon;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Pagbibigay ng hindi totoo, hindi tumpak, o nakalilitong impormasyon;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>h.</div>
                      <div>Paglahok sa mapanlinlang, kaduda-dudang, o ilegal na gawain o transaksyon;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>i.</div>
                      <div>Pagpapadala ng spam sa ibang mga gumagamit, o paggamit ng serbisyo ng website upang magpadala ng spam sa mga ikatlo;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>j.</div>
                      <div>Pagpapahayag ng personal na impormasyon ng ibang gumagamit sa mga ikatlo nang walang malinaw na pahintulot mula sa gumagamit para sa layuning pang-marketing;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>k.</div>
                      <div>Pagpapalaganap ng labis o ekstremong pag-load sa imprastraktura ng website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>l.</div>
                      <div>Pag-u-upload o pagpapa-upload sa website ng anumang nilalaman na naglalaman ng virus o iba pang item na maaaring makaapekto sa access sa website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>m.</div>
                      <div>Paglahok sa spamming activity, pag-atake, paglabag, o iba pang masasamang layunin na may kinalaman sa marketing na aktibidad o pakikilahok sa pagsisikil sa ${textBrand} marketing na may layuning sadyang pag-click o pag-atake;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>n.</div>
                      <div>Pagtutol sa anumang pagpapasya kaugnay ng mga patakaran ng ${textBrand} o mga trademark ng ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>o.</div>
                      <div>
                        Pagsasagawa ng anumang hakbang ukol sa copyright, patent, trademark, o iba pang karapatan ng intelehensya ng ${textBrand}, o ang paglabag sa paglisensya, sub-license, pagkokompila, pagpapadala, paglalathala, pagpapalathala, pagpapadala, pagkopya, pagbabago ng disenyo, pagre-replica, o paggawa ng mga ito, o pagpapadala ng mga ito sa ${textBrand}'s website o mga kaugnay na bahagi nito nang walang malinaw na pahintulot mula sa ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>p.</div>
                      <div>Pagtanggal, pagbabago, o hindi wastong paggamit ng anumang uri ng tatak, trademark, o karapatan sa akda;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>q.</div>
                      <div>Paggamit ng software na maaring gumawa ng ilegal na access sa ${textBrand} service, hacking, pagbabago, o paggamit ng software para sa pagsusuri o koleksyon ng data sa ${textBrand} service;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>r.</div>
                      <div>
                        Paggamit ng software ng ikatlong tao na nagtataguyod ng pagkilala ng "Copyright" upang mangolekta ng data mula sa ${textBrand} o gamitin ang ${textBrand} upang mangolekta ng data mula sa ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>s.</div>
                      <div>
                        Hindi mag-upload, magpadala, mag-post, o magbigay ng mga sumusunod na data:
                        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                          <div style="display: flex; gap: 0.3rem;">
                            <div>I.</div>
                            <div>Mga nilalaman na naglalaman ng diskriminasyon, racial o religious hatred, o pag-insulto sa tao o hayop;</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>II.</div>
                            <div>Mga nilalaman na hindi iyo, o nilalaman na lumalabag sa mga karapatan sa intelehensya o karapatang-ari ng ibang indibidwal o grupo;</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>III.</div>
                            <div>Mga personal na impormasyon ng ibang indibidwal na ipinahayag o ibinahagi sa ilalim ng masamang layunin at ginamit ang impormasyong iyon nang walang malinaw na pahintulot para sa layunin ng pang-marketing; o</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>IV.</div>
                            <div>Mga nilalaman na hindi kinakailangan o kailangang-kailangan, o sobrang dami o pare-pareho;</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>t.</div>
                      <div>Pagpapagawa ng hindi angkop na trapiko sa website o negosyo ng ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>Kung sakaling mayroong anumang gumagamit na pinaghihinalaang naglabag sa kasunduang ito, ipagbigay-alam sa amin.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.2.</div>
                <div>Mayroon kaming karapatang i-block, itigil, o kanselahin ang iyong pagiging miyembro sa website, at mayroon kaming karapatang baguhin o tanggalin ang anumang nilalaman na iyong in-upload, ipinost, isinumite, o ibinigay sa website nang walang abiso. Kung ikaw ay naka-experience ng pagkansela ng iyong pagiging miyembro sa nakaraan, maaaring hindi na muling aprubahan ang iyong pagiging miyembro.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.3.</div>
                <div>Wala kaming pananagutan para sa anumang nilalaman na in-upload, ipinost, isinumite, o ibinigay sa website ng ibang indibidwal o grupo. Hindi sinusuportahan ng ${textBrand} ang mga komento, payo, o pahayag na ginawa ng mga panlabas na indibidwal o grupo para sa ${textBrand}.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.4.</div>
                <div>Sumasang-ayon ka na magbayad at magpapabayad ng karampatang halaga at pabayaran ang ${textBrand} at mga kinatawan, empleyado, ahente, kontratista, supplier, at lisensyado ng ${textBrand} (pagsama-samahin ang lahat bilang "${textBrand} na Parte") para sa anumang pagkawala o pinsalang nangyari dahil sa iyong paglabag o pagkakasala sa mga kondisyon na nakasaad sa kasunduang ito. Kasama na dito ang anumang paglabag o pagkakasala na dulot ng iyong nilalaman o nilalaman ng iyong kaugnayan na partner.
              </div>
          </div>
      `
    },
    {
      title: 'Disclaimer ng Mga Warranty',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>6.1.</div>
              <div>Ang iyong paggamit ng Website ay nasa iyong sariling panganib. Kami ay tumatangging lahat ng mga warranty ng anumang uri, maging ito man ay eksplisito o ipinahihiwatig.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.2.</div>
              <div>Mayroon kaming karapatan sa anumang oras at mula sa oras hanggang sa oras na baguhin at itigil, pansamantalang o permanenteng, anumang mga serbisyo ng Website (o kahit anong bahagi nito) nang may o walang abiso. Sumasang-ayon ka na hindi kami mananagot sa iyo o sa anumang ikatlong partido para sa anumang pagbabago, pansamantalang pagtigil, o permanenteng pagtigil ng mga serbisyong gaya nito.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.3.</div>
              <div>
                  Wala kaming warranty na:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Ang Website ay tutugon sa iyong mga kinakailangan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Ang Website ay hindi mahihinto, maagang matatapos, ligtas, at walang error;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Anumang mga resulta na maaaring makamit mula sa paggamit ng Website ay tama o maaasahan; at</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Ang kalidad ng anumang mga produkto, serbisyo, impormasyon, o iba pang materyal na nabili o natanggap mo sa pamamagitan ng Website ay tutugon sa iyong mga aspetasyon.</div>
                    </div>
                  </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.4.</div>
              <div>Hindi namin mapanigan, at hindi rin kami maaaring managot sa mga mensahe, pananaw, opinyon, mga pagsasaliksik, at rekomendasyon ng mga indibidwal, user, at organisasyon ng kinikilalang interes, o magpanukala ng alinmang patronato sa anumang partikular na tagapagkaloob ng serbisyo, proseso ng paggamot, o komersyal na pakikipagtransaksyon sa anumang mga securities, pamumuhunan, o mga uri ng securities o mga pamumuhunan, o di kaya ay hindi namin pinapahayag ang anumang uri ng suporta sa pamamagitan ng pagkakasama sa Website ng isang link patungo sa ibang website(s) o mapagkukunan.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.5.</div>
              <div>Hindi namin aktibong sinusuri o binabago ang mga mensahe, pananaw, opinyon, mga pagsasaliksik, at rekomendasyon ng mga user, kahit na ang mga mensahe, pananaw, opinyon, mga pagsasaliksik, at rekomendasyon ay ginawa ng mga user gamit ang Website. Bagaman ang impormasyon sa Website ay kinuha mula sa mga pinaniniwalaang mapagkukunan, wala sa mga data, balita, impormasyon, ulat, opinyon, o anumang mga rekomendasyon (kung mayroon man) ang inayos para sa anumang partikular na tao o uri ng tao.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.6.</div>
              <div>Sa pagpapagamit ng impormasyon at data sa Website, kasama na ang mga katotohanan, pananaw, opinyon, at rekomendasyon ng mga indibidwal at organisasyon ng kinikilalang interes, hindi nagbibigay o nagpapakahulugan na nagbibigay ng mga payo sa pananalapi, pamumuhunan, buwis, at legal. Dapat mong palaging hanapin ang mga kaukulang propesyonal na payo bago gumawa ng anumang mga desisyon.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.7.</div>
              <div>Maaari naming bantayan o suriin ang anumang mga lugar sa Website kung saan ka nagpapasa o nagpo-post ng komunikasyon upang mapabuti ang kahusayan, kahalagahan, at kaangkupan ng paggamit. Gayunpaman, hindi kami nagsusumpa o nagpapahayag na pahiwatig sa lahat ng pagpo-post o komunikasyon, at kami ay tumatangging managot sa anumang lahat ng Materyal ng Website, maging ito man ay may kaugnayan sa batas ng karapatang-ari, mga trademark, libelo, pagkapribado, kahalayan, o iba pa.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.8.</div>
              <div>
                Ang Website na ito at lahat ng impormasyon na nasa loob nito ay ibinibigay ng ${textBrand} base sa "as is" at "as available" na batayan. Ang ${textBrand} at/o ang mga kaugnay nitong samahan, mga shareholder, partner, opisyal, direktor, kawani, at ahente:
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ก.</div>
                    <div>Hindi nagsasagawa ng mga representasyon o warranty ng anumang uri, eksplisito o ipinahihiwatig, tungkol sa pagpapatakbo at kahandahan ng Website o impormasyon, nilalaman, materyales, o mga produkto na inihahain sa Website. Malinaw mong kinikilala na ang paggamit mo sa Website na ito ay nasa iyong sariling panganib sa buong saklaw na pinahihintulutan ng batas na may bisa;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ข.</div>
                    <div>Tumatanggi sa lahat ng warranty, eksplisito o ipinahihiwatig, kabilang ang mga warranty na ipinahihiwatig na pangangalakal at kaangkupan para sa partikular na layunin;</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ค.</div>
                    <div>Hindi nagsasaad na ang Website, ang mga server nito, o ang email na ipinadala mula sa amin ay walang mga virus o iba pang mapanganib na bahagi o error; at</div>
                  </div>
                  <div style="display: flex; gap: 0.3rem;">
                    <div>ง.</div>
                    <div>Hindi maaaring managot para sa anumang uri ng pinsala kabilang, pero hindi limitado sa, direktang, di-rektang, pagkakataong, o sanhi ng pinsala o anumang iba pang mga pagkatalo o pasaning umaakyat mula sa (a) ang paggamit o kawalan ng kakayahan sa paggamit ng Website; (b) anumang desisyon na ginawa, aksyon o hindi pagkilos na ginawa ng anumang partido na nagtitiwala sa mga nilalaman ng Website; (c) pagkaabala sa negosyo; (d) pagkaantala/pagkabigo sa pag-access sa Website; (e) hindi paghahatid, maling paghahatid, korupsyon, pagwasak o ibang pagbabago; (f) pagkawala o pinsala ng anumang uri na nasumpungan bilang resulta ng pag-access sa mga link ng third party sa Website; (g) mga virus sa computer, pagkabigo ng sistema, o mga pagkakamalfunction na may kaugnayan sa paggamit ng Website; o (h) anumang pagkaantala, pagkakamali, error sa nilalaman ng Website.</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>6.9.</div>
              <div>Sa pag-access sa aming Website, sinasang-ayunan mo ang lahat ng panganib na kaakibat ng paggamit nito, kasama na ngunit hindi limitado sa panganib na ang iyong computer, aparato, software, o data ay maaring masira ng anumang virus na ipinasa mula sa Website na ito o mula sa anumang nilalaman ng ikatlong partido, pati na rin ang panganib na may kinalaman sa kalidad ng serbisyo na ibinigay ng tagapagkaloob ng serbisyo (kung mayroon). Sa saklaw na pinahihintulutan ng batas, ang lahat ng warranty, kondisyon, at reklamo (kahit na explicit o ipinahihiwatig) na umaangkop sa Website na ito ay ipinagbabawal na rito.</div>
            </div>
        </div>
      `
    },
    {
      title: 'Mga Gantimpala',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Sumasang-ayon ka na ikompensa at hindi isisi sa ${textBrand}, at aming mga kaakibat, mga shareholder, mga kasosyo, mga opisyal, mga direktor, mga empleyado, at mga ahente mula sa anumang hinihinging reklamo o kahilingan, kasama na ang bayad ng mga abogado, na idineklara ng anumang ikatlong partido dahil sa o nabuo mula sa anumang nilalaman na iyong isinumite, ipinaskil, ipinaabot, o ginawang available sa Website, ang iyong paggamit ng Website, ang iyong koneksyon sa Website, ang iyong paglabag sa mga Tuntunin ng Paggamit, o ang iyong paglabag sa mga karapatan ng ibang partido.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Limitasyon ng Pananagutan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  Hindi kami mananagot sa iyo para sa anumang direktang, hindi direktang, kawingan, espesyal, tagapagresulta, o huwarang pinsala, kasama ngunit hindi limitado sa pinsala para sa pagkawala ng kita, goodwill, paggamit, data, o iba pang mga intangible na pagkalugi (kahit na kami ay naabisuhan ng posibilidad ng gayong mga pinsala), na nagresulta mula sa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Ang paggamit o hindi paggamit sa Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Ang gastusin sa paghahanap ng mga kalakal at serbisyo na kapalit ng mga kalakal, data, impormasyon, o serbisyo na binili o natanggap o mga mensahe na natanggap, o mga transaksyon na isinagawa sa pamamagitan o mula sa Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Di-awtorisadong pag-access o pagbabago sa iyong mga paglipat o data;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Mga pahayag o pag-uugali ng sinumang ikatlong partido sa Website; at</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Ang anumang iba pang bagay na nauugnay sa Website.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Ang anumang reklamo ng kahit na anong kalikasan mula sa isa sa mga panig laban sa isa pang panig at ang anumang aksyon laban sa isa pang panig ay dapat simulan sa loob ng 03 buwan (o ang pinapayagang minimum na panahon batay sa kaukulang batas) pagkatapos ng petsa kung kailan nabuo ang sanhi ng aksyon, pagkatapos nito, ang panig na naagrabyado ay hindi na magkakaroon ng karagdagang reklamo laban sa isa pang panig.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Pag-uugnay at Nilalaman ng Ikatlong Parte',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>Ang Website ay maaaring may mga kawing ng hypertext patungo sa mga website na pinatatakbo ng ikatlong mga partido para sa layunin ng sanggunian at impormasyon lamang. Hindi sinuri ng ${textBrand} at hindi maaaring maging responsable para sa mga patakaran at mga kasanayan sa privacy ng ibang mga website kahit na iyong pinapasok ang mga ito gamit ang mga link mula sa Website, at inirerekomenda na tingnan mo ang mga tuntunin at kondisyon at patakaran sa privacy ng bawat website na iyong binibisita at makipag-ugnayan sa may-ari o operator nito kung may mga alalahanin o tanong ka. Gayundin, kung ikaw ay may kawing patungo sa Website mula sa isang website ng ikatlong partido, hindi maaaring maging responsable ang ${textBrand} para sa mga patakaran at mga kasanayan sa privacy ng mga may-ari o operator ng nasabing website ng ikatlong partido at inirerekomenda na tingnan mo ang patakaran ng nasabing website ng ikatlong partido at makipag-ugnayan sa may-ari o operator nito kung may mga alalahanin o tanong ka. Walang mga warranty o representasyon ang ${textBrand} sa mga nauugnay na mga website ng ikatlong partido. Iniiwasan ng ${textBrand} ang anumang pananagutan para sa pagkawala o pinsala sa pinakamalawak na saklaw na pinahihintulutan ng batas na nagiging sanhi ng anumang nilalaman sa gayong mga website.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>Para sa pag-iwas ng pagkalito, hindi ituturing na rekomendasyon ng ${textBrand} ang pagkakaroon ng isang kawing patungo sa website ng ikatlong partido o anumang payo o impormasyon sa kanila ng ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Patakaran sa Pagkapribado',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Ang lahat ng personal na data tungkol sa iyo ay sumasailalim sa aming Patakaran sa Pagkapribado.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Itinuturing na kasama sa mga Tuntunin ng Paggamit ang Patakaran sa Pagkapribado sa pamamagitan ng pagtukoy sa Klausulang ito.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>Kung ang anumang gumagamit ng Website, o alinmang ibang tao, na nakakita ng isang dokumentong inilathala ng ${textBrand}, impormasyon, at tumugon dito sa anumang impormasyon o puna tulad ng mga tanong, komento, mungkahi, o gaya nito, ang impormasyong iyon ay ituturing na hindi pribado at malaya ang ${textBrand} na gamitin, buhayin, isapubliko, at ipamahagi ang impormasyong iyon sa sinumang ibang tao.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>Dagdag pa, malaya ang ${textBrand} na gamitin o pagyamanin ang anumang ideya, konsepto, kaalaman o teknik na makita sa nasabing impormasyon/puna para sa anumang layunin nang walang bayad kabilang na, pero hindi limitado sa, pag-develop, pagmamanupaktura, at pagmamarket ng mga produkto na naglalaman ng nasabing impormasyon.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Intellectual Property at Karapatan sa Pag-aari',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>Kinikilala at tinatanggap mo na ang lahat ng karapatan sa karapatang-ari, karapatan ng mga database at anumang ibang karapatan sa intelehensiyal na pag-aari na nag-eexist sa disenyo, layout, mga proseso, mga function, data, at nilalaman ng Website ay pag-aari ng ${textBrand}, aming mga tagapagbigay ng impormasyon o naglisensya. Ang Website ay pag-aari at pinapatakbo ng ${textBrand}. Walang bahagi ng Website, ang kanyang nilalaman, o anumang mga underlying software at mga database nito ang maaaring kopyahin o muling reprodukto, ibahagi sa mga ikatlong partido, baligtarin ang pagkakakumpuni, baligtarin ang pagkakakumpuni, o gamitin upang lumikha ng anumang derivative work (kung ito ay pisikal o elektronikong anyo) nang walang ang nakaraang nakasulat na pahintulot ng ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Dahil sa Hindi Maiwasang mga Bagay',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  Wala kaming pananagutan sa iyo para sa anumang pagkaantala o pagkabigo sa pagtupad ng aming mga obligasyon sa iyo dahil sa mga dahilan na hindi namin kontrolado, kabilang ang ngunit hindi limitado sa mga gawain ng Diyos, digmaan o terorismo, epidemya, pandemya, kalamidad na dulot ng kalikasan, mga isyu sa koneksyon ng Internet, mga isyu sa sistema, mga pagbabago sa mga batas, regulasyon o patakarang pampamahalaan, at kawalan ng mga suplay at serbisyo.
              </div>
          </div>
        `
    },
    {
      title: 'Pagtatapos',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  Maaari kaming, sa ilang mga pangyayari at walang paunang abiso, sa aming tanging pagpapasya, agad na ipagtapos ang iyong account (kung mayroon). Ang mga dahilan para sa gayong pagtatapos ay maaaring kinapapalooban ngunit hindi limitado sa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Mga paglabag o paglabag sa Mga Tuntunin ng Paggamit, o iba pang isinama na mga dokumento, mga patnubay o mga patakaran;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Pag-aanyaya ng mga awtoridad ng batas o iba pang ahensya ng pamahalaan;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Mga sariling inisyatiba na pagtanggal ng account;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Paglabag sa karapatan ng pag-aari ng iba;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Pagtanggal o malawakang pagbabago sa mga serbisyo sa Website, o bahagi nito;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Di-inaasahang mga isyu sa teknikal o seguridad o mga problema; o</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Mahabang panahon ng hindi pagiging aktibo.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Ang pagtatapos ng iyong account ay magreresulta sa:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Pagtanggal ng access at pagpigil sa karagdagang paggamit sa lahat ng alok ng Website na nauugnay sa gayong account; at</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Pagtanggal ng iyong password at lahat ng kaugnay na impormasyon, mga file at nilalaman na nauugnay o nasa loob ng iyong account (o bahagi nito).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Ang pagtatapos ng iyong account ay walang labis o kulang sa anumang iba pang mga karapatan o mga lunas na maaaring nararapat sa amin sa ilalim ng Mga Tuntunin ng Paggamit, sa batas o katarungan at hindi makaaapekto sa anumang mga naipong mga karapatan o mga pananagutan o ang pagpasok o patuloy na pagsasa-aktibo ng anumang probisyon na eksplisitong o sa pamamagitan ng implikasyon na inilaan upang pumasok o magpatuloy sa pagsasa-aktibo pagkatapos ng gayong pagtatapos.</div>
              </div
          </div>
        `
    },
    {
      title: 'Mga Karapatan sa Intelehensiyang Pag-aari',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Kapag nag-upload ka ng anumang mga larawan, mga litrato, at/o mga paglalarawan ng mga listahan sa Website (ang "<strong>Nilalaman</strong>"), bibigyan mo kami ng pandaigdigang, libreng lisensya upang gamitin, itago, i-host, likhain muli, baguhin, ayusin, lagyan ng watermark, ipaalam, i-publish, ipakita sa publiko, lumikha ng mga bunga mula rito, at ipamahagi ang Nilalaman. Sa pag-upload ng Nilalaman, itinuturing mo na iyo ang karapatan sa pag-aari ng karapatang-ari-likas ng Nilalaman at sa gayon ay may angkop na karapatan upang lisensyahang gamitin ito sa amin ayon sa ipinahayag. May karapatan kaming maglagay ng watermark sa mga litrato sa loob ng Nilalaman at ang karapatang-ari sa mga litratong may watermark ay eksklusibong magiging sa amin. Lahat ng iba pang pangalan, produkto, at mga markang binanggit ay pag-aari ng mga may-ari ng mga karapatang-ari. Ang lahat ng materyales na ibinigay sa pamamagitan ng Website, kabilang ang teksto, grapika, kodigo, rating, ranking, mga database, nagtatalang impormasyon, Materyal ng Website, at/o software ay hindi maaaring muling maisulat, baguhin, ayusin, ipamahagi, i-publish, ipakita, i-upload, i-post, ipadala, o i-hyperlink sa alinmang paraan at sa anumang anyo nang walang aming mabilis, nakasulat na pahintulot, at ang pahintulot ng mga may-ari ng karapatang-ari at mga may-ari ng mga marka. Ang Website at Materyal ng Website ay para lamang sa iyong personal na paggamit, at hindi ka maaaring makipag-ugnayan sa anumang mga transaksyon sa ibang mga partido kaugnay ng gayong mga serbisyo at nilalaman. Ang mga ganyang pakikipag-ugnayan ay kasama ang mga komersyal na pakikipag-ugnayan at mga pakikipag-ugnayan na magiging masama ang epekto sa komersyal na halaga ng Website at Materyal ng Website.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Pangkalahatan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>Ang Mga Tuntunin ng Paggamit ay pamamahalaan at ipatutupad ayon sa mga batas ng Republika ng Singapore, at anumang mga reklamo o alitan ng ano mang kalikasan ay sakop ng eksklusibong hurisdiksyon ng mga hukuman ng Republika ng Singapore.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Ang pagkabigo namin na gamitin o ipatupad ang anumang karapatan o probisyon ng Mga Tuntunin ng Paggamit ay hindi magbabalangkas ng pag-alis ng gayong karapatan o probisyon. Kung ang anumang probisyon ng Mga Tuntunin ng Paggamit ay matuklasang sa pamamagitan ng isang hukuman na may hurisdiksyon ay walang bisa, ang mga panig ay pumapayag na ang hukuman ay magiging masigasig na bigyang bisa ang layunin ng mga panig na ipinakikinabang sa probisyon, at mananatili sa buong bisa at epekto ang iba pang mga probisyon ng Mga Tuntunin ng Paggamit.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Ang anumang account na maaaring mayroon ka sa amin ay hindi maaaring mailipat at ang anumang mga karapatan sa gayong account ay magtatapos sa iyong kamatayan. Kapag natanggap namin ang isang kopya ng iyong sertipiko ng kamatayan, ang iyong account ay ipapahinto at ang lahat ng nilalaman na nilikha mo sa iyong account ay permanente nang mabubura.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>Ang lahat ng mga abiso sa amin batay sa Mga Tuntunin ng Paggamit ay dapat ipadala sa pamamagitan ng email sa: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    }
  ]
};
