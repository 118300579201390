export const POLICY_ID = (textBrand: string) => {
  return [
    {
      title: 'Pengantar',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>kami</strong>", "<strong>kami</strong>", “<strong>kami</strong>” atau “<strong>${textBrand}</strong>”), adalah operator dari <a href="https://levitate.homes">https://levitate.homes</a> (situs "<strong>Website</strong>"), dan kami berkomitmen untuk melindungi Data Pribadi Anda (seperti yang didefinisikan dalam Klausul 2 di bawah ini) sesuai dengan syarat dan ketentuan yang ada di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Kebijakan Privasi ini (bersama dengan Ketentuan Penggunaan kami dan dokumen lain yang kami rujuk di dalamnya) menetapkan bagaimana Data Pribadi yang kami kumpulkan dari Anda, atau yang Anda berikan kepada kami, akan digunakan, diproses, atau diungkapkan oleh kami, termasuk bagaimana beberapa informasi akan digunakan untuk personalisasi iklan yang ditujukan untuk Anda. Kebijakan Privasi ini juga menjelaskan jenis Data Pribadi yang kami kumpulkan, bagaimana kami menjaga keamanannya, serta bagaimana Anda dapat mengakses atau mengubah Data Pribadi Anda yang disimpan oleh kami. Kebijakan Privasi ini mencakup penggunaan Anda terhadap Website, dan setiap cara lain di mana Anda dapat terhubung atau berinteraksi dengan kami melalui penggunaan produk dan layanan kami (selanjutnya secara kolektif disebut "Layanan").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Harap baca dengan cermat untuk memahami kebijakan dan praktik kami mengenai Data Pribadi Anda dan bagaimana kami akan mengaturnya.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>Kecuali dibatasi oleh hukum yang berlaku, dengan menggunakan Website dan Layanan, Anda setuju bahwa segala Data Pribadi yang berkaitan dengan Anda yang dikumpulkan oleh kami atau yang Anda berikan dari waktu ke waktu dapat digunakan, diproses, dan diungkapkan untuk tujuan dan kepada pihak-pihak yang ditetapkan dalam Kebijakan Privasi ini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Sebagai bagian dari upaya kami untuk memastikan bahwa kami mengelola, melindungi, dan memproses Data Pribadi Anda dengan benar, kami akan meninjau kebijakan, prosedur, dan proses kami dari waktu ke waktu. Kami dapat mengubah Kebijakan Privasi ini setiap saat dengan memposting versi yang direvisi di Website. Kami akan berusaha memberi pemberitahuan ketika kebijakan diubah, namun, Anda dianggap telah mengakui dan menyetujui Kebijakan Privasi yang telah diubah jika Anda terus menggunakan Website atau Layanan setelah perubahan tersebut berlaku, terlepas dari apakah Anda menerima pemberitahuan atau tidak. Anda diingatkan untuk memeriksa halaman ini dari waktu ke waktu untuk pembaruan Kebijakan Privasi ini.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "Kumpulan Data Pribadi",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              Anda dapat memilih untuk menjelajahi Situs Web dan menggunakan fungsi terbatasnya tanpa mengungkapkan Data Pribadi Anda. Anda tidak diwajibkan untuk menyediakan Data Pribadi sebagai syarat penggunaan Situs Web, kecuali jika diperlukan untuk kami dapat menyediakan Layanan yang Anda beli atau akses melalui Situs kami, merespons permintaan Anda, atau dalam kasus Anda menghubungi kami secara langsung.
            </div>
            <div>
              Saat Anda menggunakan Layanan kami, kami mengumpulkan berbagai informasi dari dan tentang Anda, perangkat Anda, dan interaksi Anda dengan Layanan. Sebagian dari informasi ini mengidentifikasi Anda secara langsung atau dapat digunakan untuk mengidentifikasi Anda dengan dikombinasikan dengan data lain. Setiap data, baik benar atau tidak, tentang individu yang dapat diidentifikasi dari data tersebut; atau dari data tersebut dan informasi lain yang dimiliki atau kemungkinan akan dimiliki oleh organisasi, dianggap sebagai data pribadi ("<strong>Data Pribadi</strong>").
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>Informasi yang Anda berikan dengan sukarela</strong>. Saat menggunakan Layanan kami, Anda mungkin diminta untuk memberikan informasi pribadi tentang diri Anda, seperti, antara lain, nama Anda, informasi kontak, informasi pembayaran, rincian tentang rumah atau properti yang menarik bagi Anda, informasi keuangan, favorit Anda, atau gaya Anda. Ini mungkin terjadi, misalnya:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Saat Anda melakukan transaksi dengan kami pada atau terkait dengan Situs Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Saat Anda mendaftar akun dengan kami di Situs Web;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Saat Anda menghubungi layanan pelanggan atau tenaga penjualan kami melalui email, telepon, atau secara langsung, untuk menyelesaikan masalah yang mungkin Anda hadapi atau dengan pertanyaan atau permintaan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Saat Anda berlangganan layanan yang kami sediakan di Situs Web, atau untuk pemberitahuan email dan/atau buletin kami;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Saat Anda memberikan atau meninggalkan masukan, tanggapan, atau informasi relevan lainnya di Situs Web untuk tujuan menggunakan Layanan;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Saat Anda berkomunikasi dengan profesional real estat (seperti agen atau broker real estat, pemberi pinjaman hipotek atau petugas pinjaman, pengelola properti, investor, pembangun rumah, atau orang lain) melalui Layanan; dan</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Saat Anda mengisi formulir atau melakukan transaksi lainnya, seperti permintaan informasi pinjaman, aplikasi pemeriksaan latar belakang (jika ada).</div>
                    </div>
                  </div>
                  <div>
                    Anda juga dapat memberikan informasi tentang pihak ketiga melalui Layanan, misalnya, jika Anda berbagi daftar properti real estat dengan penerima melalui email atau SMS. Dengan memberikan informasi tersebut kepada kami, Anda menyatakan kepada kami bahwa Anda telah memperoleh izin dari pihak ketiga untuk memberikan Data Pribadi mereka kepada kami untuk tujuan yang ditetapkan dalam Kebijakan Privasi ini.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Cookies, Pixel, dan mekanisme pelacakan lainnya</strong>. Kami dan mitra kami menggunakan berbagai teknologi untuk mengumpulkan informasi secara otomatis saat Anda mengakses dan menggunakan Layanan kami, termasuk cookie dan teknologi serupa lainnya. Cookie adalah potongan informasi elektronik yang dapat ditransfer ke komputer atau perangkat elektronik lainnya untuk mengidentifikasi uniknya peramban Anda. Saat Anda menggunakan Layanan, kami dan mitra kami dapat menempatkan satu atau beberapa cookie di komputer atau perangkat elektronik Anda atau menggunakan teknologi lain yang menyediakan fungsi serupa.
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Kami dan mitra kami dapat menggunakan cookie untuk menghubungkan aktivitas Anda di Layanan dengan informasi lain yang kami simpan tentang Anda di profil akun Anda atau interaksi sebelumnya di Layanan kami, misalnya, menyimpan preferensi Anda. Penggunaan cookie membantu kami meningkatkan kualitas Layanan kami kepada Anda, dengan mengidentifikasi informasi yang paling menarik bagi Anda, melacak tren, mengukur efektivitas periklanan, atau menyimpan informasi yang ingin Anda ambil secara rutin, seperti properti yang Anda pilih atau pencarian yang Anda preferensikan, dan melakukan tindakan lain yang diizinkan berdasarkan hukum yang berlaku. Kapan saja, Anda dapat mengatur pengaturan pada peramban Anda untuk menolak cookie sesuai dengan petunjuk yang terkait dengan peramban Anda. Namun, jika Anda memilih untuk menonaktifkan cookie, banyak fitur gratis Layanan mungkin tidak berfungsi dengan baik.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Beberapa halaman kami juga dapat menyertakan piksel, yaitu file elektronik untuk menghitung pengguna yang telah mengunjungi halaman tersebut, melacak aktivitas dari waktu ke waktu dan di berbagai situs web berbeda, menentukan interaksi pengguna dengan email yang kami kirimkan, mengidentifikasi cookie tertentu di komputer atau perangkat elektronik yang mengakses halaman tersebut, atau mengumpulkan informasi terkait lainnya, dan informasi ini dapat dihubungkan dengan peramban unik Anda, pengidentifikasi perangkat, atau alamat IP Anda. Misalnya, kami dapat mengimplementasikan piksel pada Layanan tertentu ketika Anda melihat daftar properti tertentu sehingga kami dapat melacak apakah daftar tersebut relevan bagi Anda saat menawarkan rekomendasi. Melalui piksel dan cookie ini, kami mengumpulkan informasi tentang penggunaan Layanan kami, termasuk jenis peramban yang Anda gunakan, waktu akses, halaman yang dilihat, alamat IP Anda, dan halaman yang Anda kunjungi sebelum menavigasi ke Layanan kami.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Kami juga mengumpulkan informasi tentang komputer atau perangkat seluler yang Anda gunakan untuk mengakses Layanan kami, seperti model perangkat keras, sistem operasi dan versi, pengenal perangkat unik, informasi jaringan seluler, dan perilaku penjelajahan.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Kami juga mengizinkan pihak ketiga tertentu untuk mengumpulkan informasi tentang aktivitas online Anda melalui cookie dan teknologi lainnya saat Anda menggunakan Situs Web. Pihak ketiga ini termasuk (a) mitra bisnis, yang mengumpulkan informasi saat Anda melihat atau berinteraksi dengan salah satu iklan mereka di Situs Web, dan (b) jaringan periklanan, yang mengumpulkan informasi tentang minat Anda saat Anda melihat atau berinteraksi dengan salah satu iklan yang mereka pasang di berbagai situs web di Internet. Informasi yang dikumpulkan oleh pihak ketiga ini adalah informasi yang tidak dapat diidentifikasi secara pribadi yang digunakan untuk membuat prediksi tentang karakteristik, minat, atau preferensi Anda dan untuk menampilkan iklan di Situs Web, dan di seluruh Internet, yang disesuaikan dengan minat Anda.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Anda dapat mengelola jenis cookie yang diizinkan melalui pengaturan peramban Anda, termasuk sepenuhnya memblokir semua cookie jika Anda menginginkannya. Untuk informasi tentang cara mengelola cookie di peramban Anda, silakan lihat bagian bantuan dari peramban yang Anda gunakan.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>Informasi perangkat seluler dan peramban seluler.</strong> Anda dapat mengatur pengaturan perangkat seluler dan peramban seluler Anda terkait cookie dan berbagi beberapa informasi, seperti model perangkat seluler atau bahasa yang digunakan perangkat seluler Anda, dengan mengatur pengaturan privasi dan keamanan di perangkat seluler Anda. Silakan merujuk pada petunjuk yang diberikan oleh penyedia layanan seluler atau produsen perangkat seluler Anda.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>Data Lokasi.</strong> Jika Anda mengaktifkan layanan lokasi di perangkat seluler Anda, kami, dengan izin Anda, dapat mengumpulkan lokasi perangkat Anda yang kami gunakan untuk memberikan informasi dan periklanan berbasis lokasi kepada Anda. Jika Anda ingin menonaktifkan fitur ini, Anda dapat menonaktifkan layanan lokasi di perangkat seluler Anda.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                 <strong>Pengelolaan Email.</strong> Anda dapat menerima email dari kami karena berbagai alasan - misalnya, jika Anda melakukan tindakan melalui Layanan, Anda mendaftar untuk laporan berkala, atau Anda menyelesaikan desain Anda, dan kami mengirimkan pesan kepada Anda untuk tindakan selanjutnya. Jika Anda memiliki akun dengan kami, Anda dapat mengedit preferensi Anda melalui pengaturan akun Anda. Selain itu, Anda dapat mengelola penerimaan beberapa jenis komunikasi dengan mengikuti petunjuk yang tercantum dalam email yang kami kirimkan kepada Anda. Harap dicatat bahwa, meskipun Anda berhenti berlangganan dari beberapa korespondensi email, kami mungkin tetap perlu mengirimkan email kepada Anda dengan informasi transaksional atau administratif penting.
                </div>
              </div>
            </div>
    
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>Penyangkalan.</strong> Kami tidak bertanggung jawab atas pengungkapan sukarela Data Pribadi oleh Anda kepada pengguna lain dalam hubungannya dengan penggunaan Situs Web, sesuai keadaan.
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: 'Tujuan Penggunaan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                  <div style="display: flex; gap: 0.3rem;">
                      <div>3.1</div>
                      <div>
                          Data Pribadi yang Anda berikan dapat digunakan dan diproses oleh kami (atau oleh pihak ketiga (seperti Google Analytics) atas nama kami) secara keseluruhan atau individual dan dapat digabungkan dengan informasi lain untuk tujuan pengelolaan dan operasional Situs web, yang mencakup, namun tidak terbatas pada hal-hal berikut:
                          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>a.</div>
                                  <div>Mengelola Situs web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>b.</div>
                                  <div>Meningkatkan pengalaman penjelajahan Anda dengan mempersonalisasi Situs web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>c.</div>
                                  <div>Mengaktifkan penggunaan Layanan yang tersedia di Situs web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>d.</div>
                                  <div>Menyediakan Layanan yang Anda minta secara khusus;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>e.</div>
                                  <div>Menanggapi pertanyaan atau permintaan Anda berdasarkan email dan/atau pengiriman formulir di Situs web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>f.</div>
                                  <div>Menanggapi dan mengambil tindakan tindak lanjut atas keluhan mengenai penggunaan Situs web atau Layanan yang kami berikan;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>g.</div>
                                  <div>Mengirimkan komunikasi komersial umum melalui berbagai saluran komunikasi, termasuk namun tidak terbatas pada email, panggilan suara, SMS, dan pemberitahuan aplikasi;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>h.</div>
                                  <div>Melakukan urusan administratif kantor dan operasional;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>i.</div>
                                  <div>Menyediakan pihak ketiga dengan informasi statistik tentang pengguna kami. Informasi statistik tersebut dapat digunakan untuk mempersonalisasi iklan di Situs web, tetapi tidak dapat digunakan untuk mengidentifikasi pengguna perorangan;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>j.</div>
                                  <div>Membuat dan mengelola akun online Anda;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>k.</div>
                                  <div>Memfasilitasi kegiatan dan transaksi Anda di Situs web;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>l.</div>
                                  <div>Membantu partisipasi Anda dalam kontes atau promosi;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>m.</div>
                                  <div>Menangani pembayaran, atau pengembalian dana kepada Anda;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>n.</div>
                                  <div>Menyediakan informasi tentang Layanan yang Anda minta dari kami; atau sesuai dengan persetujuan sebelumnya, informasi yang kami rasa mungkin menarik bagi Anda melalui email atau pesan teks;</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>o.</div>
                                  <div>Mengoperasikan, mengevaluasi, dan meningkatkan bisnis kami (termasuk mengembangkan produk dan layanan baru; menganalisis dan meningkatkan Layanan kami);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>p.</div>
                                  <div>Memahami pelanggan kami dan melakukan analisis data dan pemrosesan lainnya (termasuk riset pasar dan konsumen, analisis tren, dan anonimisasi);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>q.</div>
                                  <div>Menanggapi komentar atau pertanyaan Anda atau aplikasi untuk pekerjaan (jika ada);</div>
                              </div>
                              <div style="display: flex; gap: 0.3rem;">
                                  <div>r.</div>
                                  <div>Berbagi dengan penyedia layanan agar mereka dapat menyediakan Layanan kepada Anda; dan sesuai dengan yang diperlukan atau diizinkan oleh hukum.</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  Anda dapat memilih untuk menjelajahi Situs web dan menggunakan fungsi terbatasnya tanpa mengungkapkan Data Pribadi Anda. Anda tidak diharuskan untuk memberikan Data Pribadi sebagai syarat penggunaan Situs web, kecuali jika diperlukan agar kami dapat menyediakan Layanan yang Anda beli atau akses melalui Situs web kami, menanggapi permintaan Anda, atau dalam kasus Anda menghubungi kami secara langsung.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.2. </div>
                  <div>Karena tujuan pengumpulan, penggunaan, pengungkapan, atau pemrosesan Data Pribadi Anda tergantung pada keadaan yang ada, tujuan tersebut mungkin tidak tercantum di atas. Namun, kami akan memberi tahu Anda tentang tujuan lain tersebut pada saat memperoleh persetujuan Anda, kecuali jika pemrosesan Data Pribadi Anda tanpa persetujuan Anda diizinkan oleh Undang-Undang Perlindungan Data Pribadi 2012 Singapura ("<strong>PDPA</strong>") atau undang-undang lain yang berlaku.
                  </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>3.3. </div>
                  <div><strong>Ketentuan Jangan Hubungi.</strong> Jika Anda telah memberikan kami nomor telepon Anda (nomor "<strong>Numbers</strong>") dan telah menunjukkan bahwa Anda menyetujui untuk menerima informasi pemasaran atau promosi lainnya melalui Numbers Anda, maka dari waktu ke waktu, kami dapat menghubungi Anda menggunakan Numbers tersebut dengan informasi tentang Layanan kami, bahkan jika Numbers tersebut terdaftar dalam Daftar Jangan Hubungi (jika ada sesuai yurisdiksi masing-masing). Namun, Anda dapat memberi tahu kami secara tertulis jika Anda tidak ingin dihubungi oleh kami di Numbers Anda untuk tujuan tersebut.
                  </div>
              </div>
          </div>
      `
    },
    {
      title: 'Akurasi dan Keamanan',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>Anda harus memastikan bahwa semua Data Pribadi yang Anda kirimkan kepada kami lengkap, akurat, benar, dan benar. Kegagalan dari pihak Anda untuk melakukannya dapat menyebabkan kami tidak dapat menyediakan layanan yang telah Anda minta.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Kami akan berusaha sebaik mungkin untuk memastikan bahwa Data Pribadi Anda akurat dan lengkap jika Data Pribadi Anda kemungkinan akan digunakan oleh kami untuk membuat keputusan yang mempengaruhi Anda atau diungkapkan kepada organisasi lain. Namun, ini berarti bahwa Anda juga harus memberitahu kami tentang setiap perubahan dalam Data Pribadi Anda yang awalnya Anda berikan kepada kami. Kami tidak bertanggung jawab atas penggunaan Data Pribadi yang tidak akurat atau tidak lengkap yang timbul karena Anda tidak memberitahu kami tentang perubahan dalam Data Pribadi Anda yang awalnya Anda berikan kepada kami.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Kami akan mengambil langkah-langkah teknis dan organisasi yang wajar untuk mencegah kehilangan, penyalahgunaan, atau perubahan Data Pribadi Anda.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Kami akan menyimpan semua Data Pribadi yang Anda berikan di server yang aman dalam format terenkripsi.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>Namun, Anda memahami bahwa transmisi informasi melalui Internet tidak sepenuhnya aman. Meskipun kami akan melakukan yang terbaik untuk melindungi Data Pribadi Anda, kami tidak dapat menjamin keamanan Data Pribadi Anda yang dikirimkan ke Situs Web; setiap transmisi dilakukan atas risiko Anda sendiri. Selain itu, kami tidak dapat bertanggung jawab atas penggunaan Data Pribadi Anda oleh pihak ketiga yang tidak sah dan sepenuhnya disebabkan oleh faktor-faktor di luar kendali kami.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Di mana kami memberi Anda (atau di mana Anda telah memilih) kata sandi yang memungkinkan Anda mengakses bagian-bagian tertentu dari Situs Web, Anda bertanggung jawab untuk menjaga kerahasiaan kata sandi ini. Kami meminta Anda untuk tidak membagikan kata sandi dengan siapa pun. Kami TIDAK akan pernah meminta kata sandi Anda kecuali saat Anda masuk ke Situs Web, jika ada.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>Kami mendesak Anda untuk mengambil langkah-langkah untuk menjaga informasi pribadi Anda tetap aman, seperti memilih kata sandi yang kuat dan menjaganya tetap pribadi, serta keluar dari akun pengguna Anda dan menutup peramban web Anda ketika Anda selesai menggunakan Layanan kami di perangkat bersama atau tidak aman.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Pengungkapan Data Pribadi',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Kami tidak akan, tanpa persetujuan eksplisit Anda, mengungkapkan Data Pribadi Anda kepada pihak ketiga untuk tujuan pemasaran langsung. Persetujuan untuk pengungkapan Data Pribadi kepada pihak ketiga, untuk tujuan menerima komunikasi pemasaran, diberikan melalui formulir pendaftaran kami, baik secara online atau dalam bentuk kertas.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Untuk mengelola dan mengoperasikan Situs Web, kami dapat mengungkapkan Data Pribadi Anda kepada anggota kelompok kami, yang berarti afiliasi dan anak perusahaan kami. Kami juga dapat mengungkapkan Data Pribadi Anda kepada pihak ketiga seperti penyedia layanan dan penasihat profesional kami untuk tujuan-tujuan berikut:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Untuk setiap tujuan yang diizinkan oleh undang-undang atau peraturan yang berlaku;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Untuk mematuhi persyaratan hukum apa pun;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Untuk menegakkan atau menerapkan Ketentuan Penggunaan kami;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Untuk melindungi hak, properti, dan keselamatan kami, serta hak, properti, dan keselamatan afiliasi dan anak perusahaan kami, pengguna lain Situs Web, dan orang lain;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Sehubungan dengan setiap proses hukum atau proses hukum yang mungkin;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Sehubungan dengan transaksi aset bisnis (yaitu, pembelian, penjualan, sewa, penggabungan atau penggabungan atau akuisisi, pelepasan atau pembiayaan organisasi atau sebagian organisasi atau dari bisnis atau aset organisasi) di mana kami menjadi pihak atau calon pihak.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Kami juga tidak akan mengungkapkan Data Pribadi Anda kepada pihak ketiga tanpa mendapatkan izin pertama dari Anda yang memperbolehkan kami melakukannya. Namun, perlu dicatat bahwa kami dapat mengungkapkan Data Pribadi Anda kepada pihak ketiga tanpa mendapatkan izin pertama dari Anda dalam situasi tertentu, termasuk namun tidak terbatas pada hal-hal berikut:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Kasus di mana pengungkapan diperlukan atau diizinkan berdasarkan undang-undang dan/atau peraturan yang berlaku;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Kasus di mana tujuan pengungkapan tersebut jelas dalam kepentingan Anda, dan jika persetujuan tidak dapat diperoleh secara tepat waktu;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Kasus di mana pengungkapan diperlukan untuk merespons keadaan darurat yang mengancam jiwa, kesehatan, atau keselamatan Anda atau individu lain;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Kasus di mana pengungkapan diperlukan untuk setiap penyelidikan atau proses hukum;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Kasus di mana Data Pribadi diungkapkan kepada pejabat agensi penegak hukum yang ditetapkan;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Kasus di mana pengungkapan tersebut ditujukan pada lembaga publik dan pengungkapan tersebut diperlukan demi kepentingan publik; dan/atau</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Di mana pengungkapan tersebut tanpa izin Anda diperbolehkan oleh PDPA atau undang-undang.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Ketika kami mengungkapkan Data Pribadi Anda kepada pihak ketiga tanpa izin Anda, kami akan berusaha sebaik mungkin untuk meminta pihak ketiga tersebut melindungi Data Pribadi Anda.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Penarikan Persetujuan',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>Anda dapat menarik persetujuan Anda dengan menghubungi layanan pelanggan kami di <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Harap dicatat bahwa jika Anda menarik persetujuan Anda terhadap salah satu atau semua penggunaan Data Pribadi Anda, tergantung pada sifat permintaan Anda, kami mungkin tidak dapat melanjutkan penyediaan Layanan kami kepada Anda. Pada pemberitahuan dari Anda, kami akan memberi tahu Anda tentang kemungkinan konsekuensi dari penarikan persetujuan, termasuk tetapi tidak terbatas pada pengakhiran segala perjanjian yang mungkin Anda miliki dengan kami dan Anda menjadi pelanggaran terhadap kewajiban atau jaminan kontrak Anda. Hak dan upaya hukum kami dalam acara tersebut secara tegas tetap berlaku.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Transfer Data Internasional',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Kami beroperasi di banyak negara dan mungkin menyimpan, memproses, atau mentransfer Data Pribadi Anda antar negara-negara di mana kami beroperasi untuk memungkinkan kami menggunakan Data Pribadi Anda sesuai dengan Kebijakan Privasi dan Ketentuan Penggunaan kami.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Kami akan mengambil semua langkah yang wajar untuk memastikan bahwa Data Pribadi Anda ditransfer sesuai dengan setiap undang-undang atau regulasi yang berlaku dan mengambil semua langkah yang wajar untuk memastikan bahwa setiap organisasi penerima asing dari Data Pribadi Anda terikat oleh kewajiban hukum yang dapat ditegakkan untuk memberikan perlindungan Data Pribadi yang ditransfer setidaknya sebanding dengan perlindungan di bawah PDPA.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Situs Pihak Ketiga',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                Situs web dapat, dari waktu ke waktu, berisi tautan ke dan dari situs web jaringan mitra dan vendor kami. Jika Anda mengikuti tautan ke salah satu situs web ini, harap dicatat bahwa situs web ini memiliki kebijakan privasi mereka sendiri dan kami tidak bertanggung jawab atas kebijakan tersebut. Harap periksa kebijakan-kebijakan ini sebelum Anda mengirimkan Data Pribadi apa pun ke situs web ini.
              </div>
            </div>
          `
    },
    {
      title: 'Akses, Penghapusan, dan Koreksi',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>9.1.</div>
                <div>Pejabat Perlindungan Data kami bertanggung jawab untuk memastikan bahwa permintaan akses, penghapusan, atau perubahan Data Pribadi ditangani dengan baik sesuai dengan prosedur yang ditentukan di sini.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.2.</div>
                <div>Semua permintaan informasi atau permintaan perubahan atau penghapusan data pribadi yang kami miliki akan dilakukan dengan mengirimkan permintaan ke Pejabat Perlindungan Data kami di: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.3.</div>
                <div>Harap dicatat bahwa kami hanya akan menerima dan menindaklanjuti permintaan dalam bahasa Inggris yang sesuai dengan Kebijakan Privasi ini. Setiap permintaan yang tidak sesuai dengan ketentuan di atas akan ditolak, dan kami tidak akan mengambil tindakan apa pun terkait permintaan yang ditolak tersebut.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.4.</div>
                <div>Kami dapat menolak untuk mengungkapkan, menghapus, atau mengubah Data Pribadi atas alasan yang tercantum dalam PDPA.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.5.</div>
                <div>Sejauh yang wajar, kami akan menanggapi permintaan Anda dalam waktu sepuluh (10) hari kerja sejak tanggal penerimaan permintaan. Jika hal tersebut tidak memungkinkan, Anda akan diberitahu dalam waktu sepuluh (10) hari kerja sejak tanggal penerimaan permintaan.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Pertahankan Data Pribadi Anda',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>Kami menyimpan Data Pribadi yang kami kumpulkan dari Anda ketika kami memiliki kebutuhan bisnis yang sah untuk melakukannya. Jika Anda adalah klien atau vendor (atau perwakilan dari klien atau vendor), maka informasi tersebut akan disimpan untuk jangka waktu yang memungkinkan kami untuk menyediakan Layanan kami, mematuhi persyaratan hukum, pajak, atau akuntansi yang berlaku, dan jika diperlukan, untuk pembentukan, pelaksanaan, atau pembelaan klaim hukum.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>Ketika kami tidak memiliki kebutuhan bisnis yang sah untuk memproses informasi pribadi Anda, kami akan menghapus atau membuatnya menjadi anonim atau, jika hal ini tidak mungkin dilakukan (misalnya, karena informasi pribadi Anda telah disimpan dalam arsip cadangan), maka kami akan menyimpan informasi pribadi Anda secara aman dan mengisolasi informasi tersebut dari setiap pengolahan lebih lanjut sampai dapat dihapus.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Hubungi Kami',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>
                    Jika Anda memerlukan informasi lebih lanjut tentang perlakuan Data Pribadi Anda oleh kami, jangan ragu untuk menghubungi Pejabat Perlindungan Data kami di: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                  </div>
                </div>
              </div>
            `
    },
    {
      title: 'Hukum yang Berlaku',
      description: `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                Kebijakan Privasi ini tunduk pada hukum Singapura.
                </div>
              </div>
            `
    }
  ]
};
