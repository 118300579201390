import React from 'react';
import { isEmpty } from 'lodash';

// COMPONENTS
import { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ICONS
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

// CONSTANTS
import { SelectOption } from 'constants/types';
import { useTheme } from '@mui/material';
import { StyledFormControl } from './StyledSelect';

export type SelectProps = MuiSelectProps & {
  options?: SelectOption[];
  getOptionDisabled?: (option: SelectOption) => boolean;
  fullWidth?: FormControlProps['fullWidth'];
  width?: number;
  height?: string;
  helperText?: string;
  noneLabel?: string;
  noneValue?: '' | 0;
  required?: boolean;
  dark?: boolean;
  hideSelected?: boolean;
  hideSelectedArray?: boolean;
  paddingButton?: string;
};

const Select = ({
  id,
  label,
  options,
  value,
  getOptionDisabled,
  fullWidth,
  helperText,
  noneLabel = 'None',
  noneValue,
  displayEmpty,
  error,
  disabled,
  required,
  dark,
  width,
  height = 'auto',
  hideSelected,
  hideSelectedArray = false,
  sx,
  paddingButton = '10px',
  ...restProps
}: SelectProps) => {
  const theme = useTheme();

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      focused={displayEmpty ? true : undefined}
      hiddenLabel={!label}
      paddingbutton={paddingButton}
    >
      {label && <InputLabel id={id}>{label}</InputLabel>}
      <MuiSelect
        id={id}
        label={label}
        value={value}
        disabled={disabled || isEmpty(options)}
        IconComponent={ExpandMoreOutlinedIcon}
        error={error}
        displayEmpty={displayEmpty}
        MenuProps={{
          disableScrollLock: true,
          marginThreshold: 0,
          PaperProps: {
            elevation: 2,
            sx: {
              '&': {
                backgroundColor: theme.palette?.default?.dark as string | '#4E4B55',
                height: height,
                width,
                ...(restProps.startAdornment
                  ? {
                    ml: -2.5,
                    py: 1,
                  }
                  : {}),
              },
              '.MuiList-padding': {
                padding: 0,
              },
              '& .MuiMenuItem-root': {
                py: 1,
                px: 2,
                fontSize: 14,
              },
            },
          },
        }}
        sx={{ ...sx, width }}
        {...restProps}
      >
        {!required && (
          <MenuItem value={noneValue}>
            <em>{noneLabel}</em>
          </MenuItem>
        )}
        {options
          ? options.map((option) => (
            <MenuItem
              hidden={
                (hideSelected && hideSelectedArray)
                  ? [...value as any].includes(option.value)
                  : option.value === value
              }
              key={option.value}
              value={option.value}
              disabled={
                getOptionDisabled ? getOptionDisabled(option) : undefined
              }
            >
              {option.label ?? option.value}
            </MenuItem>
          ))
          : null}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default Select;
