export class Auth {
  username?: string;
  tokenPMS?: string;
  tokenUMS?: string;
  userId?: number;
  expiredTime?: Date;
  avatarUrl?: string;
  completedProfile?: boolean;
  telephone?: string;
  phonePrefix?: string;
  email?: string;
  nationality?: string

  constructor({
    tokenPMS,
    tokenUMS,
    username,
    userId,
    expiredTime,
    avatarUrl,
    completedProfile,
    telephone,
    phonePrefix,
    email,
  }: Auth) {
    if (tokenPMS !== undefined) this.tokenPMS = tokenPMS;
    if (tokenUMS !== undefined) this.tokenUMS = tokenUMS;
    if (username !== undefined) this.username = username;
    if (userId !== undefined) this.userId = userId;
    if (expiredTime !== undefined) this.expiredTime = expiredTime;
    if (avatarUrl !== undefined) this.avatarUrl = avatarUrl;
    if (completedProfile !== undefined)
      this.completedProfile = completedProfile;
    if (telephone !== undefined) this.telephone = telephone;
    if (phonePrefix !== undefined) this.phonePrefix = phonePrefix;
    if (email !== undefined) this.email = email;
    return this;
  }
};