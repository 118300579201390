export const TERM_KO = (textBrand: string) => {
  return [
    {
      title: '이용 약관 수락',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div><a href="https://levitate.homes">https://levitate.homes</a> (이하 "웹사이트")에 접속하고 이용함으로써, 당사 ${textBrand} International Pte. Ltd. (이하 "당사", "우리", "저희" 또는 "${textBrand}")와 본 이용 약관("이용 약관")에 따라 법적으로 구속력 있는 계약이 체결됩니다.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>당신은 이용 약관, 개인 정보 보호 정책을 무제한으로 수락합니다.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>당사는 웹사이트에 개정된 버전을 게시하여 언제든지 이용 약관을 수정할 수 있습니다. 그러나 개별 공지는 전달되지 않습니다. 개정된 이용 약관은 모든 이전 버전을 대체합니다. 이용 약관의 개정 또는 변경에 동의하지 않는 경우 즉시 웹사이트의 모든 서비스 이용을 중단해야 합니다. 개정사항이 효력을 발생한 후에도 웹사이트의 계속적인 이용은 해당 개정사항에 대한 동의와 합의로 간주됩니다. 언제든지 최신 버전의 이용 약관을 검토하실 수 있습니다.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>당사는 웹사이트 이용에 관한 지침 및 규칙을 주기적으로 게시할 수 있습니다. 이러한 모든 지침이나 규칙은 본 이용 약관에 참조로 포함됩니다.</div>
            </div>
          </div>
        `
    },
    {
      title: '웹사이트 이용',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>2.1.</div>
            <div>본 이용 약관에 따라 웹사이트를 이용할 때 모든 조건과 조항을 준수할 것에 동의합니다. 우리가 직권으로 여기 이곳의 조건을 위반한 가능성이 있다고 의심하는 경우에는 해당 콘텐츠를 삭제하거나 제거할 수 있습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.2.</div>
            <div>
              웹사이트에서 우리와 거래를 하거나 웹사이트에서 우리와 계정을 등록할 때, 다음 사항을 보증합니다:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>가.</div>
                  <div>본인이 거래를 직접 수행하는 합법적인 권리가 있습니다;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>나.</div>
                  <div>본인의 진정한 신원을 사용하고 있습니다;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>다.</div>
                  <div>제공한 개인 데이터가 진실하고 정확하며 완전하며 최신 정보임을 보증합니다; 그리고</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>라.</div>
                  <div>제공한 개인 데이터를 적절히 보관하고 즉시 업데이트하며 해당 정보가 진실하고 정확하고 완전하고 최신 정보임을 확인합니다.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.3.</div>
            <div>미성년자(거주 국가의 법률에 따라 정해진 연령 이하의 사용자)는 보호자의 감독 없이 웹사이트를 이용하거나 웹사이트에 개인 데이터를 제공할 수 없습니다. 미성년자의 경우 보호자/법정 대리인의 허락을 받거나 보호자/법정 대리인의 감독 하에 있을 경우에만 웹사이트를 이용하거나 웹사이트에 개인 데이터를 제공할 수 있습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.4.</div>
            <div>당사가 당신에게(또는 당신이 선택한 경우) 웹사이트 일부에 접근할 수 있게 하는 패스워드를 제공한 경우, 해당 패스워드의 기밀성을 유지하고 당신의 계정 하에서 발생한 모든 활동에 대한 책임을 완전히 인정합니다. 당사는 로그인하여 웹사이트를 사용하는 사용자가 당신임을 확인하는 책임이 없습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.5.</div>
            <div>패스워드 보안 위반 사항이 발생한 경우, 우리에게 통보하는 데 동의합니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.6.</div>
            <div>섹션 2의 준수 실패로 인해 발생한 손실이나 손해에 대해 우리는 책임을 지지 않습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>2.7.</div>
            <div>당사의 상표, 로고 또는 기타 소유 정보(사진, 텍스트, 페이지 레이아웃, 양식, 설명, 개념, 아이디어, 디자인, 그림, 사진, 기타 콘텐츠 포함)을 당사의 명시적인 서면 허가 없이 프레임에 포함시키거나, 당사와 관련된 상업적인 명칭, 로고 또는 기타 소유 정보에 대해 무단으로 사용할 수 없습니다. 무단 사용은 당사가 부여한 허가 또는 동의를 종료시킵니다.</div>
          </div>
        </div>
      `
    },
    {
      title: '웹사이트 자료',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>3.1.</div>
            <div>웹사이트에 접속하고 사용함으로써 정보, 데이터, 텍스트, 소프트웨어, 사진, 그래픽, 비디오, 메시지, 개념, 아이디어, 디자인, 도면, 제품 목록, 가격 또는 웹사이트 자료를 업로드, 게시, 이메일로 전송, 전송하거나 웹사이트에서 공개적으로 또는 비공개적으로 사용할 수 있는 다른 자료("웹사이트 자료")에 노출될 수 있습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.2.</div>
            <div>웹사이트 자료가 노골적, 부적절하거나 불쾌할 수 있음을 이해합니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.3.</div>
            <div>웹사이트 자료는 개인적이고 비상업적인 용도로만 사용할 수 있으며, 동일한 회사나 조직 내의 다른 사람과 공개, 배포, 전송, 재전송, 재배포, 방송, 복제 또는 회람 등이 불가능하며 다른 웹사이트나 포럼, 뉴스그룹, 메일링 리스트, 전자 게시판 또는 다른 웹사이트에서 운영되는 인터넷 릴레이 채팅에 게시할 수 없습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.4.</div>
            <div>당사의 사전 서면 동의 없이 다른 웹사이트나 서버에 웹사이트 자료를 복제, 중복, 복사, 판매, 재판매, 표시하거나 제공하지 않기로 동의합니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.5.</div>
            <div>웹사이트 자료에 어떤 오류나 누락도 당사는 책임지지 않으며 웹사이트에 게시, 이메일로 전송, 전송하거나 기타 방법으로 제공된 웹사이트 자료의 사용으로 인한 어떠한 종류의 손실이나 손해에 대해서도 당사는 어떠한 책임도 지지 않습니다.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>3.6.</div>
            <div>당사의 웹사이트의 형식과 내용이 언제든지 사전 통보 없이 변경될 수 있으며, 웹사이트의 운영은 지원 또는 유지보수 작업, 콘텐츠 업데이트 또는 기타 이유로 일시적 또는 영구적으로 중단될 수 있으며, 웹사이트 접근은 특정 사용자에게 또는 일반적으로 언제든지 사전 통보 없이 중단될 수 있습니다.</div>
          </div>
        </div>
      `
    },
    {
      title: '当ウェブサイト上の情報の正確性',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>${textBrand}は当ウェブサイトに正確な情報を含めるために合理的な努力をしますが、法律で許可される範囲内で、暗示されたものであるか他の方法であるかにかかわらず、提供された情報の正確性についてのいかなる保証または表現も行いません。 ${textBrand}は法律で許可される最大限度まで、当ウェブサイトの内容におけるいかなる誤りまたは省略に対しても責任を排除します。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>当ウェブサイトに含まれる詳細はガイド目的でのみです。当ウェブサイトに含まれる詳細、記述、寸法、状態への参照、使用と占有のための必要な許可、およびその他の詳細の正確性は保証されず、一般的なガイダンスとして提供されています。それらを事実の陳述として依存しないよう忠告します。自分自身の正確性を確認するようお勧めします。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>${textBrand}は不動産を購入することが重要な財務的な取引であるため、提案されているいかなる不動産もすべての点であなたの要件に適していることを確認するためのすべての合理的な手段を講じるようお勧めします。当ウェブサイト内の材料が正確であるように合理的な手段を講じていますが、それはあなたの問い合わせや考慮の代替手段として受け入れられるべきではありません。依存したい情報を検証するための適切な手段を講じるようお勧めします。</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>当ウェブサイト上の各製品の色とデザインの正確な反映を提供するために最善を尽くしていますが、実際の色とデザインは異なる場合があります。異なるデバイスの画面設定、設置場所の照明、時間の経過による製品仕上げのわずかな違いなどの理由により、実際の色とデザインが若干異なる場合があります。 ${textBrand}は、当社の責任ではない色やデザインの違いに対して責任を負いません。当ウェブサイトを使用して不動産または製品を購入する際には、実際の色とデザインとウェブサイト上の表示との間にわずかな違いがある可能性を受け入れることに同意するものとします。</div>
          </div>
        </div>
      `
    },
    {
      title: '사용자 의무',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>5.1.</div>
                <div>
                  본 이용약관에 정한 의무에 더하여, 다음과 같은 행위를 해서는 안됩니다:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>관련 법률에 위배되는 웹 사이트 사용;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>다른 사람을 해치거나 학대하거나 괴롭히거나 스토킹하거나 중상하거나 비방하거나 명예를 훼손하거나 협박하거나 기타 사람을 공격하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>사용자 또는 제3자 또는 ${textBrand}에 불리한 또는 손해를 입히는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>부정하게 여러 개의 회원 등록을 신청하거나 보유하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>음란물 또는 포르노그래피를 포함하여 불도덕한 행위 또는 잠재적으로 불도덕한 행위에 관여;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>다른 개인 또는 단체를 사칭하거나 거짓된 진술을 하거나 자신이 해당 개인 또는 단체와의 관계를 가질 경우 거짓으로 보이도록 하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>거짓, 부정확 또는 오해를 불러일으키는 정보를 제공하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>h.</div>
                      <div>사기적인, 의심스러운 또는 불법적인 행위 또는 거래에 관여;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>i.</div>
                      <div>다른 사용자에게 스팸을 보내거나 제3자에게 스팸을 보내기 위해 웹 사이트 서비스를 이용하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>j.</div>
                      <div>다른 사용자의 개인정보를 제3자에게 공개하거나 배포하고 그 정보를 마케팅 목적으로 사용하기 위해 명백한 동의 없이 사용하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>k.</div>
                      <div>웹 사이트의 인프라에 불합리하거나 극단적으로 큰 부담을 주는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>l.</div>
                      <div>바이러스 또는 웹 사이트의 접근을 방해할 수 있는 기타 항목을 포함하는 콘텐츠를 웹 사이트에 업로드하거나 업로드하게 하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>m.</div>
                      <div>스팸 활동에 참여하거나 공격적인 행동, 침해 또는 ${textBrand}의 윤리 기준에 어긋나는 마케팅 활동 또는 ${textBrand}의 마케팅에 대한 의도적인 클릭 또는 공격 행위에 참여하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>n.</div>
                      <div>${textBrand}의 재량권 또는 ${textBrand}의 상표와 관련하여 이루어진 모든 판단에 이의를 제기하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>o.</div>
                      <div>
                        ${textBrand}의 저작권, 특허, 상표 또는 기타 지적 재산권에 대해 어떠한 절차도 하지 않거나 해당 지적 재산권을 침해하거나 ${textBrand}의 웹 사이트 또는 관련 부분에 대해 라이선스, 하위 라이선스, 컴파일, 전송, 게시, 공개, 송신, 복사, 디자인 수정, 재제작, 또는 작성하거나 ${textBrand}의 명백한 동의 없이 그러한 콘텐츠 또는 코드를 송신하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>p.</div>
                      <div>어떤 종류의 서비스에서도 표지, 상표 또는 저작권을 제거, 수정 또는 부적절하게 사용하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>q.</div>
                      <div>${textBrand}의 서비스에 부정하게 액세스하는 소프트웨어, 해킹, 변경 또는 ${textBrand}의 서비스 데이터를 분석 또는 수집하는 소프트웨어를 사용하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>r.</div>
                      <div>
                        ${textBrand}에서 데이터 또는 정보를 수집하거나 ${textBrand}를 통해 데이터 또는 정보를 수집하도록 하는 제3자의 소프트웨어를 탐지하는 "스크레이퍼"와 같은 소프트웨어를 사용하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>s.</div>
                      <div>
                        다음과 같은 데이터를 업로드, 전송, 게시 또는 제공하지 않는 것:
                        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                          <div style="display: flex; gap: 0.3rem;">
                            <div>I.</div>
                            <div>차별적, 인종 차별적 또는 종교적 콘텐츠 또는 인간이나 동물에 대해 모욕적인 콘텐츠;</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>II.</div>
                            <div>본인의 저작물이 아니거나 다른 개인 또는 단체의 지적 재산권 또는 저작권을 침해하는 콘텐츠;</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>III.</div>
                            <div>다른 개인의 개인정보를 부정하게 공개하거나 배포하고 그 정보를 명백한 동의 없이 마케팅 목적으로 사용하는 콘텐츠; 또는</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>IV.</div>
                            <div>목적에 적합하지 않거나 무의미한, 또는 중복된 대량의 콘텐츠; 또는</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>t.</div>
                      <div>웹 사이트 또는 ${textBrand}의 비즈니스에 부적절한 트래픽을 생성하는 행위;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>만약 어떤 사용자가 본 이용약관에 위반되는 행동을 한다고 생각되면, 알려주시기 바랍니다.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.2.</div>
                <div>우리는 웹 사이트의 멤버십을 차단, 중지 또는 취소할 권리를 보유하며, 사용자가 웹 사이트에 업로드, 게시, 전송 또는 제공하는 콘텐츠를 사전 통보없이 변경하거나 삭제할 권리를 보유합니다. 과거에 멤버십이 취소된 적이 있는 경우, 다시 멤버십을 신청하는 것이 승인되지 않을 수 있습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.3.</div>
                <div>우리는 웹 사이트에 업로드, 게시, 전송 또는 제공되는 콘텐츠에 대해 어떠한 책임도 지지 않습니다. 우리는 ${textBrand}에 의해 제공되는 외부 개인 또는 단체에 의해 작성된 코멘트, 조언 또는 발언을 지지하지 않습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.4.</div>
                <div>당신은 ${textBrand} 및 ${textBrand}의 관련자, 직원, 대리인, 계약업자, 공급업체 및 라이선스 (이하 "${textBrand} 당사자")에 대해 본 이용약관에 정한 조건 위반 또는 위반에 따른 손실이나 손해 (불법 절차 및 모든 보상금을 포함)를 배상하는 것에 동의합니다. 이에는 당신의 콘텐츠 또는 당신의 관련 파트너에 의한 위반 또는 위반행위도 포함됩니다.</div>
              </div>
          </div>
      `
    },
    {
      title: '면책 조항',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>웹사이트의 사용은 오로지 귀하의 책임하에 이루어집니다. 우리는 어떠한 종류의 보증도 (명시적 또는 묵시적인 것을 포함하여) 거부합니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>우리는 언제든지 웹사이트의 모든 서비스(또는 해당 일부)를 사전 통지 없이 일시적으로 또는 영구적으로 변경하거나 중단할 권리를 보유합니다. 귀하는 해당 서비스의 변경, 중단 또는 일시 중단에 대해 우리나 제3자에 대해 책임이 없다는 것에 동의합니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    우리는 다음과 같은 어떠한 보증도 하지 않습니다:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>웹사이트가 귀하의 요구를 충족시킬 것임;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>웹사이트가 중단 없이, 적시에, 안전하며 오류가 없이 제공될 것임;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>웹사이트의 사용으로 얻어질 수 있는 결과물이 정확하거나 신뢰할 수 있을 것임; 그리고</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>웹사이트를 통해 귀하가 구매하거나 획득하는 모든 제품, 서비스, 정보 또는 기타 자료의 품질이 귀하의 기대를 충족시킬 것임.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>우리는 개별 사용자, 사용자들, 관심 기관의 의견, 견해, 연구, 권고 사항, 또는 특정 서비스 제공자, 치료 과정, 또는 증권, 투자 또는 증권 또는 투자 종류에 대한 어떠한 판례나 권유도 보증하거나 책임지지 않으며 웹사이트에 다른 웹사이트 또는 자원으로의 링크 포함이 우리의 어떠한 형태의 보증도 시사하지 않습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>우리는 사용자가 웹사이트를 사용하여 발송하거나 게시하는 메시지, 견해, 의견, 연구, 권고 사항 등을 적극적으로 검토하거나 편집하지 않습니다. 웹사이트의 정보는 신뢰할만한 출처로부터 얻어졌으나, 데이터, 뉴스, 정보, 보고서, 의견 또는 권고 사항(있는 경우)은 특정 개인 또는 개인 그룹에 맞게 맞춤화되지 않았습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>웹사이트에 정보와 자료를 제공함으로써, 우리와 우리의 콘텐츠 제공자는 재정, 투자, 세금 및 법률 상의 조언을 제공하거나 제공한다고 주장하거나 나타내거나 그러한 조언을 받아들인다는 것을 의미하지 않습니다. 이러한 결정을 하기 전에 항상 관련 전문가의 조언을 구해야 합니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>웹사이트의 효율성, 유용성 및 적절한 사용을 촉진하기 위해 귀하가 통신을 전송하거나 게시하는 웹사이트의 영역을 모니터링하거나 검토할 수 있습니다. 그러나 우리는 게시물 또는 통신을 모니터링하거나 검토할 것을 약속하거나 보증하지 않으며, 저작권, 상표권, 명예훼손, 사생활, 음란물 또는 기타 사항에 대한 모든 웹사이트 자료와 관련된 모든 책임을 거부합니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  이 웹사이트와 그에 포함된 모든 정보는 레비테이트(${textBrand})가 "있는 그대로"와 "사용 가능한 그대로"의 기준으로 제공됩니다. 레비테이트와/또는 해당 제반 계열사, 주주, 파트너, 임원, 이사, 직원 및 대리인은 다음과 같은 내용을 포함하여 어떠한 종류의 명시적 또는 묵시적 보증도 하지 않습니다:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>이 웹사이트의 운영과 이용 가능성에 대한 보증;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>상업성과 특정 목적에의 적합성을 포함하여, 묵시적 보증을 포함하되 이에 국한되지 않고, 명시적 보증을 포함하지 않음;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>우리로부터 전송된 웹사이트, 서버 또는 전자 메일이 바이러스 또는 기타 유해 구성요소나 오류가 없을 것을 보증하지 않음; 그리고</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>웹사이트의 이용 또는 이를 통해 접근하는 결과로 인해 발생하는 모든 종류의 손실 또는 책임, 직접적인, 간접적인, 부수적인, 특별한 손해를 포함하여 어떠한 손해에 대해서도 책임을 지지 않음.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>웹사이트에 접속함으로써 귀하는 이와 관련하여 귀하의 컴퓨터, 기기, 소프트웨어 또는 데이터가 본 웹사이트 또는 제3자 콘텐츠 또는 제3자 웹사이트가 전송한 어떠한 바이러스로 인해 손상될 위험을 포함하여 서비스 제공자(있는 경우)가 제공하는 서비스 품질과 관련된 위험을 모두 가정하게 됩니다. 법률에 허용되는 한도 내에서 이 웹사이트와 관련된 모든 보증, 조건 및 요구 사항(명시적 또는 묵시적인 것을 포함하되 이에 국한되지 않음)은 여기서 제외됩니다.</div>
              </div>
          </div>
        `
    },
    {
      title: '면책조항',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>고객님은 제출, 게시, 전송 또는 웹사이트에서 제공 가능한 모든 콘텐츠와 관련하여, 제삼자가 요구하는 청구, 변호사 비용을 포함한 모든 청구로부터 ${textBrand}, 당사의 제휴사, 주주, 파트너, 임원, 이사, 직원 및 대리인을 면책시키고 보호하는 데 동의합니다. 웹사이트의 이용, 웹사이트와의 연결, 이용약관의 위반 또는 타당한 다른 당사자의 권리의 위반이 원인이 되는 모든 청구 또는 청구에 대해 해당합니다.</div>
              </div>
          </div>
        `
    },
    {
      title: '책임 제한',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  저희는 다음으로 인해 발생하는 직접적이거나 간접적인 손해, 부수적인 손해, 특별한 손해, 결과적인 손해, 또는 본보기적인 손해를 포함하여, 이유와 무관하게(저희가 그러한 손해의 가능성을 사전에 통보 받았더라도) 여러분에 대해 어떠한 책임도 지지 않습니다:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>웹사이트의 사용 또는 사용 불능;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>웹사이트를 통해 구매하거나 획득한 제품, 데이터, 정보 또는 서비스, 받은 메시지, 또는 웹사이트를 통해 수행한 거래로 인해 발생하는 대체 제품 및 서비스의 구입 비용;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>미인가된 접근 또는 데이터의 변경;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>웹사이트 상의 타사의 진술이나 행동; 및</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>웹사이트와 관련된 기타 사항.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>각 당사자가 상대방에 대해 제기하는 모든 종류의 청구 및 상대방에 대한 모든 조치는 원인이 발생한 날로부터 03개월 이내(또는 각각 해당되는 법령에 따라 허용되는 최소 기간)에 시작되어야 하며, 그 후에 해당 당사자는 더 이상의 추가 청구를 상대방에 대해 제기하지 않습니다.</div>
              </div>
          </div>
        `
    },
    {
      title: '링크 및 제3자 콘텐츠',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.1</div>
                  <div>웹사이트는 참조 및 정보 목적으로 제3자가 운영하는 웹사이트와 하이퍼텍스트 링크로 연결될 수 있습니다. ${textBrand}는 이러한 웹사이트의 개인 정보 보호 정책 및 관행을 검토하지 않았으며, 웹사이트로부터 링크를 통해 접근하더라도 다른 웹사이트의 개인 정보 보호 정책 및 관행에 대한 책임을 지지 않습니다. 방문하는 각 웹사이트의 이용 약관 및 개인 정보 보호 정책을 확인하고, 걱정 사항이나 질문이 있을 경우 해당 웹사이트의 소유자 또는 운영자에게 문의하도록 권장합니다. 마찬가지로, 제3자 웹사이트에서 웹사이트로 링크를 설정한 경우 ${textBrand}는 해당 제3자 웹사이트의 개인 정보 보호 정책 및 관행에 대해 책임을 지지 않으며 해당 제3자 웹사이트의 정책을 확인하고, 걱정 사항이나 질문이 있을 경우 해당 웹사이트의 소유자 또는 운영자에게 문의하도록 권장합니다. ${textBrand}는 링크된 제3자 웹사이트와 관련하여 어떠한 보증이나 표현도 하지 않으며, 해당 웹사이트의 콘텐츠로 인한 어떠한 손해 배상 책임도 법률이 허용하는 한도 내에서 면제됩니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>9.2</div>
                  <div>분명히 하기 위해, 제3자 웹사이트로의 링크가 해당 웹사이트를 추천하는 것이거나 해당 웹사이트에서 제공되는 조언이나 정보를 의미하는 것은 아닙니다.</div>
              </div>
          </div>
        `
    },
    {
      title: '개인 정보 보호 정책',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>귀하에 대한 모든 개인 데이터는 당사의 개인 정보 보호 정책에 따릅니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>개인 정보 보호 정책은 본 이용 약관을 참조로 인해 이 이용 약관에 포함된 것으로 간주됩니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.3.</div>
                  <div>웹사이트의 사용자 또는 기타 개인이 ${textBrand}가 게시한 문서 정보를 볼 수 있고, 질문, 의견, 제안 또는 유사한 정보로 응답한 경우 해당 정보는 비밀이 아니라고 간주되며, ${textBrand}는 해당 정보를 다른 개인에게 사용, 복제, 공개, 배포 할 수 있는 권리를 가집니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>10.4.</div>
                  <div>더 나아가, ${textBrand}는 해당 정보/피드백에 포함된 아이디어, 개념, 노하우 또는 기술을 무료로 사용하거나 활용할 수 있는 권리를 가집니다. 이에는 해당 정보를 포함하는 제품의 개발, 제조, 마케팅을 포함하지만 이에 한정되지 않습니다.</div>
              </div>
          </div>
        `
    },
    {
      title: '지적 재산권과 저작권',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>당사는 웹사이트의 디자인, 레이아웃, 프로세스, 기능, 데이터 및 콘텐츠에 존재하는 저작권, 데이터베이스권 및 기타 지적 재산권이 ${textBrand}, 당사의 정보 제공업체 또는 라이선서의 재산임을 인정하고 수용하십니다. 웹사이트는 ${textBrand}이 소유하고 운영합니다. 웹사이트의 일부, 그 내용 또는 그 기반 소프트웨어 및 데이터베이스 일체를 ${textBrand}의 사전 서면 동의 없이는 복사하거나 재생산할 수 없으며, 제3자와 공유하거나, 역공학을 시도하거나, 역어셈블하거나, 유도 저작물을 생성하는 데 사용할 수 없습니다(물리적 또는 전자적 형태의 구분 없이).</div>
              </div>
          </div>
        `
    },
    {
      title: '불가항력',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  우리는 우리의 통제 범위를 벗어난 이유로 당신에게 대한 의무의 지연이나 실패에 대해 책임을지지 않습니다. 이는 하지만 이에 국한되지 않고, 천재지변, 전쟁 또는 테러, 유행병, 대유행병, 자연 재해, 인터넷 연결 문제, 시스템 문제, 적용 법률, 규제 또는 정부 정책 변경, 그리고 공급품과 서비스의 부족을 포함합니다.
              </div>
          </div>
        `
    },
    {
      title: '종료',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  특정한 상황에서는 사전 고지 없이도 당사의 단독 재량에 따라 귀하의 계정 (있는 경우)을 즉시 종료할 수 있습니다. 이러한 종료 사유는 다음을 포함하지만 이에 국한되지 않습니다:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>이용 약관, 기타 통합 문서, 가이드라인 또는 규칙의 위반;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>법 집행 기관 또는 기타 정부 기관의 요청;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>자체적인 계정 삭제;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>타인의 지적 재산권 침해;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>웹 사이트 또는 해당 부분의 서비스 중단 또는 심각한 수정;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>예기치 않은 기술적 또는 보안 문제; 또는</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>장기간 비활동.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  귀하의 계정 종료로 인해 다음과 같은 결과가 발생합니다:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>해당 계정과 관련된 웹 사이트의 모든 제공에 대한 액세스 해제 및 향후 사용 금지; 그리고</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>귀하의 비밀번호 및 해당 계정 (또는 해당 계정의 일부)에 관련된 모든 정보, 파일 및 콘텐츠 삭제.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>귀하의 계정 종료는 본 이용 약관, 법률 또는 공정에 따라 저희가 가질 수 있는 기타 권리 또는 구제와는 무관하며 적립된 권리 또는 부채 또는 해당 종료 이후 유효하게 계속 적용되거나 지속될 목적으로 명시적 또는 묵시적으로 의도되는 규정의 효력에는 영향을 미치지 않습니다.</div>
              </div
          </div>
        `
    },
    {
      title: '지적 재산권',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>이미지, 사진 및/또는 목록 설명을 웹사이트에 업로드할 때 ("<strong>컨텐츠</strong>") 컨텐츠에 대해 전 세계적으로 무상, 비독점적 라이선스를 부여하여, 컨텐츠를 사용, 저장, 호스팅, 복제, 수정, 적응, 워터마크를 삽입, 공개, 공개적으로 전시, 컨텐츠를 기반으로 한 파생 작품을 작성하고 배포할 수 있습니다. 컨텐츠를 업로드함으로써 컨텐츠의 저작권이 소유자이며 이러한 라이선스를 부여할 권리를 갖고 있다고 명시적으로 보증합니다. 우리는 컨텐츠에 포함된 사진에 워터마크를 삽입할 권리를 가지며, 워터마크가 있는 사진의 저작권은 저희에게 귀속됩니다. 모든 기타 언급된 이름, 제품 및 상표는 해당 소유자들의 지적 재산권을 구성합니다. 웹사이트를 통해 제공되는 모든 자료, 텍스트, 그래픽, 코드, 리뷰, 랭킹, 데이터베이스, 관련 정보, 웹사이트 자료 및/또는 소프트웨어는 저희의 사전 서면 동의 없이 어떠한 방법 또는 형태로도 복사, 수정, 복제, 공개, 전시, 업로드, 게시, 발송 또는 연결할 수 없습니다. 모든 기타 권리 소유자와 상표권자의 서면 동의 없이 어떠한 방법 또는 형태로도 조치할 수 없습니다.</div>
              </div>
          </div>
        `
    },
    {
      title: '일반',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>본 이용 약관은 싱가포르 공화국의 법률에 따라 해석되고 규정되며, 어떠한 청구나 분쟁도 싱가포르 공화국 법원의 전문 관할로 간주됩니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>저희가 본 이용 약관의 어떤 권리 또는 조항도 행사하지 않은 경우라 하더라도 그것은 그 권리나 조항을 포기한 것이 아닙니다. 만약 본 이용 약관의 어떤 조항이 법원에 의해 무효로 판단된 경우 당사자들은 그 조항의 의도를 실현하려는 법원의 노력에 동의하며, 본 이용 약관의 다른 조항은 완전히 유효한 상태로 남습니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>우리와 가진 어떠한 계정도 양도가 불가능하며, 해당 계정과 관련된 모든 권리는 당신의 사망과 함께 종료됩니다. 당신의 사망 증명서 사본을 받은 후, 당신의 계정은 종료되며 계정에서 생성한 모든 컨텐츠가 영구적으로 삭제됩니다.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>이용 약관과 관련된 모든 통지는 다음 이메일 주소로 이메일로 발송되어야 합니다：<a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>。</div>
              </div>
          </div>
        `
    }
  ]
};
