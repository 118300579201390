import { getStorage, hasStorage } from 'services/storageService';
import { pmsPublicRequest } from './requests';
import { StorageKey } from 'constants/storage';

export const fetchCurrencyAllByApi = async () => {
  return pmsPublicRequest.get(`/currency/getAll`);
}

export const fetchCurrencyIpAddressByApi = async () => {
  return pmsPublicRequest.get(`/currency${hasStorage(StorageKey.AccessLocation)
    ? `?ip=${getStorage(StorageKey.AccessLocation)}`
    : ''}`);
}