import loadable from '@loadable/component';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from "styled-components";

// HOOKS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectDesignHome } from "store/designHome/designHomeSelectors";
import { object, string } from "yup";

// ICONS
import ClearIcon from '@mui/icons-material/Clear';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import IcCameraProfile from "components/icons/IcCameraProfile";

// CONSTANT
import { fetchPersonProfileByIdApi, updatePersonProfileByIdApi } from "apis/userApis";
import { Button } from "components/buttons";
import Checkbox from "components/formControls/Checkbox";
import QuestionDialog from "components/modals/QuestionDialog";
import { COUNTRY_NAME, GENDER_LIST } from "constants/homeConstants";
import { i18n } from "locales";
import SignupVerifyOtp from "pages/SignupLight/commons/SignupVerifyOtp";
import { extractFileExtensionFromDataUri, formatFileSize, uploadMediaApi } from "utils/fileUtils";
import { v4 as uuidV4 } from "uuid"
import { usePriceContext } from 'contexts/priceContext';
import { setAuth } from 'store/designHome/designHomeActions';
import { getStorage, setStorage } from 'services/storageService';
import { StorageKey } from 'constants/storage';
import { formatCountryPhone } from 'utils/dataHelpers';
import { Lang } from 'constants/i18n';
import { handleFlowPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy';
import { handleFlowTerm } from 'pages/TermsOfUse/TermsOfUser';

// COMPONENTS
import { Autocomplete, Avatar, Box, FormControl, IconButton, InputAdornment, Stack, useTheme } from "@mui/material";
import { CustomDialog, TypeSignInAndSignUp } from "pages/SignupLight/SignupLight";
const DatePicker = loadable(() => import('components/formControls/DatePicker'));
const RadioButton = loadable(() => import('components/formControls/RadioButton'));
const TextField = loadable(() => import('components/formControls/TextField'));
const SignupLightForm = loadable(() => import('pages/SignupLight/sections/SignupLightForm'));

export type PersonalInfo = {
  city?: string;
  birthday?: string;
  state?: string;
  firstName?: string;
  houseNumber?: string;
  lastName?: string;
  passportId?: string;
  passportDate?: string;
  streetAddress?: string;
  address?: string;
  zipCode?: string;
  phonePrefix?: string;

  // set
  gender?: string;
  avatarUrl?: string;
  country?: string;
  email?: string;
  telephone?: string;
  nationality?: string;
  passportPlaceOfIssue?: string;
}

enum StepChangePhoneNumberOrEmail {
  FormStep = 'FormPhoneStep',
  OtpStep = 'PhoneOtp'
}

export enum TypeChangePhoneNumberOrEmail {
  changeEmail = 'changeEmail',
  changePhoneNumber = 'changePhoneNumber',
}

type DataChangePhoneNumberOrEmail = {
  name?: string;
  phoneNumber?: string;
  prefix?: string;
  email?: string;
};

const PersonalProfile = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { localeCode } = usePriceContext();

  const {
    auth: { username, userId, tokenPMS },
    textContentBrand: { textBrand },
  } = useSelector(selectDesignHome);
  const dispatch = useDispatch();

  const [POLICY, set_POLICY] = useState(handleFlowPolicy(textBrand!));
  const [TERM_OF_USE, set_TERM_OF_USE] = useState(handleFlowTerm(textBrand!));

  const fileRef = useRef<any>(null);
  const [avatarShow, setAvatarShow] = useState('');

  const validationSchema = object().shape({
    firstName: string().required(t('First Name is required')).nullable().max(30),
    lastName: string().required(t('Last Name is required')).nullable().max(30),
    birthday: string().nullable(),
    passportId: string().required(t('Passport ID is required')).max(20).nullable(),
    passportDate: string().required(t('Passport Issue Date is required')).nullable(),
    email: string().nullable(),
    state: string().nullable().max(50),
    city: string().required(t('City is required')).nullable().max(50),
    zipCode: string().required(t('Zip code is required')).nullable().max(50),
    houseNumber: string().required(t('House Number is required')).max(20).nullable(),
    address: string().required(t('Street Address is required')).max(50).nullable(),
    telephone: string().nullable(),
  });

  const { control, handleSubmit, watch, setValue } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const country = watch('country');
  const nationality = watch('nationality');
  const avatarUrl = watch('avatarUrl');
  const telephone = watch('telephone');
  const passportPlaceOfIssue = watch('passportPlaceOfIssue');

  const [selectedGender, setSelectedGender] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState(false);
  const [selectedTermOfUse, setSelectedTermOfUse] = useState(false);
  const [popupPolicy, setPopupPolicy] = useState(false);
  const [popupTermOfUse, setPopupTermOfUse] = useState(false);
  const [stepChangePhoneNumberOrEmail, setStepChangePhoneNumberOrEmail] = useState('');
  const [dataPhoneNumber, setDataPhoneNumber] = useState<DataChangePhoneNumberOrEmail>({});
  const [typeChangePhoneNumberOrEmail, setTypeChangePhoneNumberOrEmail] = useState('');
  const [openPopup, setOpenPopup] = useState(false);

  const handleUpdateProfile = async (values: PersonalInfo) => {
    if (isSubmitted) return;
    setIsSubmitted(true);
    if (avatarShow) {
      const fileExt = extractFileExtensionFromDataUri(avatarShow);
      const imageUrl = await uploadMediaApi(avatarShow, `person-profile-${uuidV4()}.${fileExt}`);
      values.avatarUrl = imageUrl;
    }
    values.gender = selectedGender;
    values.country = country;
    values.nationality = nationality;
    values.birthday = moment(values.birthday).format("YYYY-MM-DD");
    values.passportDate = moment(values.passportDate).format("YYYY-MM-DD");
    values.phonePrefix = dataPhoneNumber?.prefix || getStorage(StorageKey.AuthDesign)?.phonePrefix;
    values.email = dataPhoneNumber.email || getStorage(StorageKey.AuthDesign)?.email;

    const { data } = await updatePersonProfileByIdApi({ ...values, userId });
    if (data?.id) {
      toast.success(t('Successfully saved!'));
      dispatch(setAuth({
        avatarUrl: data?.avatarUrl,
        telephone: values.telephone,
        phonePrefix: values.phonePrefix,
        email: values.email,
        nationality: values.nationality
      }));
      setStorage(StorageKey.AuthDesign, {
        ...getStorage(StorageKey.AuthDesign),
        avatarUrl: data?.avatarUrl,
        telephone: values.telephone,
        phonePrefix: values.phonePrefix,
        email: values.email,
        nationality: values.nationality
      });
      setIsSubmitted(false);
    } else {
      toast.error(t("Please check your information!"));
      setIsSubmitted(false);
    }
  }

  const fetchPersonProfileById = async () => {
    const { data } = await fetchPersonProfileByIdApi();
    if (data.id) {
      setValue('firstName', data?.firstName);
      setValue('lastName', data?.lastName);
      setValue('birthday', data?.birthday);
      setSelectedGender(data?.gender || GENDER_LIST[0].value);
      setValue('passportId', data?.passportId);
      setValue('passportDate', data?.passportDate);
      setValue('telephone', data?.telephone);
      setValue('email', data?.email);
      setValue('state', data?.state);
      setValue('city', data?.city);
      setValue('zipCode', data?.zipCode);
      setValue('houseNumber', data?.houseNumber);
      setValue('address', data?.address);
      setValue('avatarUrl', data?.avatarUrl);
      setValue('country', data?.country || COUNTRY_NAME[0].value);
      setValue('nationality', data?.nationality || COUNTRY_NAME[0].value);
      setValue('passportPlaceOfIssue', data?.passportPlaceOfIssue)
      setSelectedPolicy(true);
      setSelectedTermOfUse(true);
    }
  }

  const handlerOpenUpload = () => {
    fileRef?.current?.click();
  }

  const handlerChangeUpload = (e: any) => {
    const file = e.target.files ? e.target.files[0] : '';
    if (file) {
      const checkSize =
        Number(formatFileSize(file?.size)?.numberSize) <= 2 && formatFileSize(file?.size).units === 'mb'
          ? true
          : Number(formatFileSize(file?.size)?.numberSize) <= 999 && formatFileSize(file?.size).units === 'kb'
            ? true
            : false;
      if (checkSize) {
        getBase64(file);
      } else {
        toast.error(t('Allow user to upload image not much than 2 mb!'));
      }
    }
  }

  const getBase64 = (file: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setAvatarShow(String(reader.result));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const countries = formatCountryPhone(localeCode as Lang);
  useEffect(() => {
    if (tokenPMS) {
      fetchPersonProfileById();
    }
  }, [])

  return (
    <Wrapper>
      <Box className="title-profile">{t('To adjust the price of your home')}</Box>
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
          rowGap: '2rem'
        }}
      >
        <Box flex={.3}>
          <Box className="box-image-profile">
            <Box className="box-avatar-profile" onClick={handlerOpenUpload}>
              <input type="file" onChange={handlerChangeUpload} ref={fileRef} hidden />
              <Avatar
                src={String(avatarShow) || avatarUrl}
                sx={{ width: '100%', height: '100%' }}
                alt='avatar-show'
              >
                <Box className="avatar-name">{username?.slice(0, 1).toUpperCase()}</Box>
              </Avatar>
              <Box className="box-icon-camera">
                <IcCameraProfile width="37px" height="36px" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
            flex: 1.7,
          }}
        >
          <Box flex={1.2}>
            <form>
              <Box className="title-info">{t('Personal')}</Box>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label className="label-required">{t('First Name')}</label>
                  <TextField
                    name="firstName"
                    control={control}
                    placeholder={t('First Name')}
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <label className="label-required">{t('Last Name')}</label>
                  <TextField
                    name="lastName"
                    control={control}
                    placeholder={t('Last Name')}
                    fullWidth
                    required
                  />
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label>{t('Date of Birth')}</label>
                  <Box className='datepicker-profile'>
                    <DatePicker
                      control={control}
                      name="birthday"
                      fullWidth
                      maxDate={moment().subtract(1, 'day')}
                      placeholder={t('Date of Birth')}
                    />
                  </Box>
                </FormControl>
                <FormControl fullWidth>
                  <label className="label-required">{t('Nationality')}</label>
                  {nationality ? <AutoCompleteStyled>
                    <Autocomplete
                      value={countries.find(item => item.shortLocale === nationality)}
                      options={countries}
                      onChange={(e, newValue) => setValue('nationality', newValue?.shortLocale!)}
                      getOptionLabel={(option) => option.text?.split(' ')?.slice(0, -1).join(" ")}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          name="nationality"
                          control={control}
                        />
                      )}
                    />
                  </AutoCompleteStyled> : <></>}
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label className="label-required">{t('Passport Place of Issue')}</label>
                  {passportPlaceOfIssue ? <AutoCompleteStyled>
                    <Autocomplete
                      value={countries.find(item => item.shortLocale === passportPlaceOfIssue)}
                      options={countries}
                      onChange={(e, newValue) => setValue('passportPlaceOfIssue', newValue?.shortLocale!)}
                      getOptionLabel={(option) => option.text?.split(' ')?.slice(0, -1).join(" ")}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          name="passportPlaceOfIssue"
                          control={control}
                        />
                      )}
                    />
                  </AutoCompleteStyled> : <Autocomplete
                    value={countries.find(item => item.shortLocale === passportPlaceOfIssue)}
                    options={countries}
                    onChange={(e, newValue) => setValue('passportPlaceOfIssue', newValue?.shortLocale!)}
                    getOptionLabel={(option) => option.text?.split(' ')?.slice(0, -1).join(" ")}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        name="passportPlaceOfIssue"
                        control={control}
                      />
                    )}
                  />}
                </FormControl>
                <FormControl fullWidth>
                  <label className="label-required">{t('Gender')}</label>
                  <Box mt={.5}>
                    <RadioButton
                      data={GENDER_LIST}
                      flexDirection="row"
                      columnGap="3rem"
                      selected={selectedGender}
                      onChange={(value: string) => setSelectedGender(value)}
                    />
                  </Box>
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label className="label-required">{t('Passport ID')}</label>
                  <TextField
                    name="passportId"
                    control={control}
                    placeholder={t('Passport ID')}
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <label className="label-required">{t('Passport Issue Date')}</label>
                  <Box>
                    <DatePicker
                      control={control}
                      name="passportDate"
                      fullWidth
                      maxDate={moment().subtract(1, 'day')}
                      placeholder={t('Passport Issue Date')}
                    />
                  </Box>
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label>{t('Phone Number')}</label>
                  <TextField
                    name="telephone"
                    control={control}
                    placeholder={t('Phone Number')}
                    fullWidth
                    required
                    paddingRight="0px"
                    background={theme.palette.secondary.main}
                    // onClick={() => {
                    //   setOpenPopup(true);
                    //   setStepChangePhoneNumberOrEmail(StepChangePhoneNumberOrEmail.FormStep);
                    //   setTypeChangePhoneNumberOrEmail(TypeChangePhoneNumberOrEmail.changePhoneNumber);
                    // }}
                    // onChange={() => {
                    //   return;
                    // }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <CreateOutlinedIcon style={{ color: theme.palette.primary.contrastText }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <label>Email</label>
                  <TextField
                    name="email"
                    control={control}
                    placeholder={t('Email')}
                    onChange={() => {
                      return;
                    }}
                    onClick={() => {
                      setOpenPopup(true);
                      setStepChangePhoneNumberOrEmail(StepChangePhoneNumberOrEmail.FormStep);
                      setTypeChangePhoneNumberOrEmail(TypeChangePhoneNumberOrEmail.changeEmail);
                    }}
                    fullWidth
                    paddingRight="0px"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <CreateOutlinedIcon style={{ color: theme.palette.primary.contrastText }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>

              <Box className="title-info" mt={4}>{t('Address')}</Box>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label className="label-required">{t('Country')}</label>
                  {country ? <AutoCompleteStyled>
                    <Autocomplete
                      value={countries.find(item => item.shortLocale === country)}
                      options={formatCountryPhone(localeCode as Lang)}
                      onChange={(e, newValue) => setValue('country', newValue?.shortLocale!)}
                      getOptionLabel={(option) => option.text?.split(' ')?.slice(0, -1).join(" ")}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          name="country"
                          control={control}
                        />
                      )}
                    />
                  </AutoCompleteStyled> : <></>}
                </FormControl>
                <FormControl fullWidth>
                  <label>{t('State')}</label>
                  <TextField
                    name="state"
                    control={control}
                    placeholder={t('State')}
                    fullWidth
                  />
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={3} mb={2} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label className="label-required">{t('City')}</label>
                  <TextField
                    name="city"
                    control={control}
                    placeholder={t('City')}
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <label className="label-required">{t('Zip code')}</label>
                  <TextField
                    name="zipCode"
                    control={control}
                    placeholder={t('Zip code')}
                    fullWidth
                    required
                  />
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={3} mb={5} alignItems="flex-end"
                sx={{
                  flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row' },
                  rowGap: '1rem'
                }}
              >
                <FormControl fullWidth>
                  <label className="label-required">{t('House Number')}</label>
                  <TextField
                    control={control}
                    placeholder={t('House Number')}
                    fullWidth
                    name='houseNumber'
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <label className="label-required">{t('Street Address')}</label>
                  <TextField
                    name="address"
                    control={control}
                    placeholder={t('Street Address')}
                    fullWidth
                    required
                  />
                </FormControl>
              </Stack>
              <Stack flexDirection="row" columnGap={1} mb={5} alignItems="center" justifyContent="space-between">
                <Stack flexDirection="column" rowGap={1.5}>
                  <Stack flexDirection="row">
                    <Checkbox
                      label=""
                      checked={selectedPolicy}
                      onChange={() => setSelectedPolicy(!selectedPolicy)}
                    />
                    <Stack className="text-checkbox" flexDirection="row" columnGap={1}>
                      {t('Agreed with the ')}
                      <Box
                        color={theme.palette.accent.main}
                        onClick={() => setPopupPolicy(true)}
                        className="pointer">{t('Privacy Policy')}
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack flexDirection="row">
                    <Checkbox
                      label=""
                      checked={selectedTermOfUse}
                      onChange={() => setSelectedTermOfUse(!selectedTermOfUse)}
                    />
                    <Stack className="text-checkbox" flexDirection="row" columnGap={1}>
                      {t('Agreed with the ')}
                      <Box
                        color={theme.palette.accent.main}
                        onClick={() => setPopupTermOfUse(true)}
                        className="pointer">{t('Terms of Use')}
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
                <Box>
                  <Button
                    color="accent"
                    onClick={handleSubmit(handleUpdateProfile)}
                    width="150px"
                    disabled={!selectedPolicy || !selectedTermOfUse}
                  >
                    {t('Update')}
                  </Button>
                </Box>
              </Stack>
            </form>
          </Box>
          <Box flex={.8}></Box>
        </Stack>
      </Stack>

      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      >
        <Stack flexDirection="row" justifyContent="flex-end" mb={2}>
          <div className="button-close" onClick={() => setOpenPopup(false)}>
            <ClearIcon fontSize='medium' style={{ cursor: 'pointer', color: '#fff' }} />
          </div>
        </Stack>
        {
          stepChangePhoneNumberOrEmail === StepChangePhoneNumberOrEmail.FormStep &&
          <SignupLightForm
            setShow={value => setOpenPopup(value)}
            telephone={telephone}
            onSubmitFormPhoneNumber={data => {
              setDataPhoneNumber(data);
            }}
            typeVerify={typeChangePhoneNumberOrEmail}
            typePage={TypeSignInAndSignUp.SignUp}
            onChangeStep={(type) => {
              setTypeChangePhoneNumberOrEmail(type);
              setStepChangePhoneNumberOrEmail(StepChangePhoneNumberOrEmail.OtpStep);
            }}
          />
        }
        {
          stepChangePhoneNumberOrEmail === StepChangePhoneNumberOrEmail.OtpStep &&
          <SignupVerifyOtp
            onClose={() => {setOpenPopup(false)}}
            dataForm={dataPhoneNumber}
            typeVerify={typeChangePhoneNumberOrEmail}
            onChangeTypeSignup={() => setStepChangePhoneNumberOrEmail(StepChangePhoneNumberOrEmail.FormStep)}
            getDataPhoneNumber={(phoneNumber: string) => setValue('telephone', phoneNumber)}
            getDataEmail={(email: string) => setValue('email', email)}
          />
        }
      </CustomDialog>

      <QuestionDialog
        maxWidth="sm"
        open={popupPolicy}
        confirmText={t('Accept & Continue')}
        onConfirm={() => {
          setPopupPolicy(false);
          setSelectedPolicy(true);
        }}
        closeDialog={() => setPopupPolicy(false)}
        textCenter={false}
      >
        <Box>
          <Box className="title-info">
            {t('Privacy Policy')}
          </Box>
          {
            i18n.language === 'vi' ?
              POLICY?.map((item: { title: string, description: string }, index: number) => (
                <Box
                  component="div"
                  flex={1}
                  key={index}
                >
                  <Box className="title-info-sub">
                    {index + 1}. {item?.title}
                  </Box>
                  <Box className="title-info-content" dangerouslySetInnerHTML={{ __html: item?.description }}>
                  </Box>
                </Box>
              )) :
              POLICY?.map((item: { title: string, description: string }, index: number) => (
                <Box
                  component="div"
                  flex={1}
                  key={index}
                >
                  <Box className="title-info-sub">
                    {index + 1}. {item.title}
                  </Box>
                  <Box className="title-info-content" dangerouslySetInnerHTML={{ __html: item.description }}>
                  </Box>
                </Box>
              ))
          }
        </Box>
      </QuestionDialog>

      <QuestionDialog
        maxWidth="sm"
        open={popupTermOfUse}
        confirmText={t('Accept & Continue')}
        onConfirm={() => {
          setPopupTermOfUse(false);
          setSelectedTermOfUse(true);
        }}
        closeDialog={() => setPopupTermOfUse(false)}
        textCenter={false}
      >
        <Box>
          <Box className="title-info">
            {t('Terms Of Use')}
          </Box>
          {
            i18n.language === 'vi' ?
              TERM_OF_USE?.map((item: { title: string, description: string }, index: number) => (
                <Box
                  component="div"
                  flex={1}
                  key={index}
                >
                  <Box className="title-info-sub">
                    {index + 1}. {item.title}
                  </Box>
                  <Box className="title-info-content" dangerouslySetInnerHTML={{ __html: item.description }}>
                  </Box>
                </Box>
              )) :
              TERM_OF_USE?.map((item: { title: string, description: string }, index: number) => (
                <Box
                  component="div"
                  flex={1}
                  key={index}
                >
                  <Box className="title-info-sub">
                    {index + 1}. {item?.title}
                  </Box>
                  <Box className="title-info-content" dangerouslySetInnerHTML={{ __html: item?.description }}>
                  </Box>
                </Box>
              ))
          }
        </Box>
      </QuestionDialog>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 2rem 4rem;

  .title-profile {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 1.5rem;
  }

  .title-info {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .text-checkbox {
    font-weight: 400;
    font-size: 13px;
    color: #C9C8CC;
  }

  .box-image-profile {

    .box-avatar-profile {
      position: relative;
      width: 120px;
      height: 120px;
      overflow: hidden;
      cursor: pointer;

      .avatar-name {
        font-size: 72px;
        font-weight: 500;
      }
  
      .box-icon-camera {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 99;
      }
    }
  }

  label {
    font-weight: 400;
    font-size: 13px;
  }

  .label-required::after {
    content: ' *';
    color: #FF4B55;
  }
`;

const AutoCompleteStyled = styled.div`
  .MuiOutlinedInput-root{
    padding: 0px !important;
  }

  .MuiAutocomplete-root{
    width: auto !important;
  }
`;

export default PersonalProfile;