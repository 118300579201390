import { TCountryPhoneCode } from "constants/i18n";

export const COUNTRY_PHONE_CODE_OPTIONS_ZH: TCountryPhoneCode[] = [
  {
    "text": "亚美尼亚 +374",
    "value": "+374",
    "locale": "am-AM",
    "shortLocale": "AM"
  },
  {
    "text": "阿拉伯联合酋长国 +971",
    "value": "+971",
    "locale": "ar-AE",
    "shortLocale": "AE"
  },
  {
    "text": "巴林 +973",
    "value": "+973",
    "locale": "ar-BH",
    "shortLocale": "BH"
  },
  {
    "text": "阿尔及利亚 +213",
    "value": "+213",
    "locale": "ar-DZ",
    "shortLocale": "DZ"
  },
  {
    "text": "埃及 +20",
    "value": "+20",
    "locale": "ar-EG",
    "shortLocale": "EG"
  },
  {
    "text": "伊拉克 +964",
    "value": "+964",
    "locale": "ar-IQ",
    "shortLocale": "IQ"
  },
  {
    "text": "约旦 +962",
    "value": "+962",
    "locale": "ar-JO",
    "shortLocale": "JO"
  },
  {
    "text": "科威特 +965",
    "value": "+965",
    "locale": "ar-KW",
    "shortLocale": "KW"
  },
  {
    "text": "利比亚 +218",
    "value": "+218",
    "locale": "ar-LY",
    "shortLocale": "LY"
  },
  {
    "text": "沙特阿拉伯 +966",
    "value": "+966",
    "locale": "ar-SA",
    "shortLocale": "SA"
  },
  {
    "text": "叙利亚 +963",
    "value": "+963",
    "locale": "ar-SY",
    "shortLocale": "SY"
  },
  {
    "text": "突尼斯 +216",
    "value": "+216",
    "locale": "ar-TN",
    "shortLocale": "TN"
  },
  {
    "text": "白俄罗斯 +375",
    "value": "+375",
    "locale": "be-BY",
    "shortLocale": "BY"
  },
  {
    "text": "保加利亚 +359",
    "value": "+359",
    "locale": "bg-BG",
    "shortLocale": "BG"
  },
  {
    "text": "孟加拉国 +880",
    "value": "+880",
    "locale": "bn-BD",
    "shortLocale": "BD"
  },
  {
    "text": "捷克共和国 +420",
    "value": "+420",
    "locale": "cs-CZ",
    "shortLocale": "CZ"
  },
  {
    "text": "丹麦 +45",
    "value": "+45",
    "locale": "da-DK",
    "shortLocale": "DK"
  },
  {
    "text": "德国 +49",
    "value": "+49",
    "locale": "de-DE",
    "shortLocale": "DE"
  },
  {
    "text": "奥地利 +43",
    "value": "+43",
    "locale": "de-AT",
    "shortLocale": "AT"
  },
  {
    "text": "瑞士 +41",
    "value": "+41",
    "locale": "de-CH",
    "shortLocale": "CH"
  },
  {
    "text": "希腊 +30",
    "value": "+30",
    "locale": "el-GR",
    "shortLocale": "GR"
  },
  {
    "text": "澳大利亚 +61",
    "value": "+61",
    "locale": "en-AU",
    "shortLocale": "AU"
  },
  {
    "text": "英国 +44",
    "value": "+44",
    "locale": "en-GB",
    "shortLocale": "GB"
  },
  {
    "text": "加纳 +233",
    "value": "+233",
    "locale": "en-GH",
    "shortLocale": "GH"
  },
  {
    "text": "香港 +852",
    "value": "+852",
    "locale": "en-HK",
    "shortLocale": "HK"
  },
  {
    "text": "澳门 +853",
    "value": "+853",
    "locale": "en-MO",
    "shortLocale": "MO"
  },
  {
    "text": "爱尔兰 +353",
    "value": "+353",
    "locale": "en-IE",
    "shortLocale": "IE"
  },
  {
    "text": "印度 +91",
    "value": "+91",
    "locale": "en-IN",
    "shortLocale": "IN"
  },
  {
    "text": "肯尼亚 +254",
    "value": "+254",
    "locale": "en-KE",
    "shortLocale": "KE"
  },
  {
    "text": "马耳他 +356",
    "value": "+356",
    "locale": "en-MT",
    "shortLocale": "MT"
  },
  {
    "text": "毛里求斯 +230",
    "value": "+230",
    "locale": "en-MU",
    "shortLocale": "MU"
  },
  {
    "text": "尼日利亚 +234",
    "value": "+234",
    "locale": "en-NG",
    "shortLocale": "NG"
  },
  {
    "text": "新西兰 +64",
    "value": "+64",
    "locale": "en-NZ",
    "shortLocale": "NZ"
  },
  {
    "text": "巴基斯坦 +92",
    "value": "+92",
    "locale": "en-PK",
    "shortLocale": "PK"
  },
  {
    "text": "卢旺达 +250",
    "value": "+250",
    "locale": "en-RW",
    "shortLocale": "RW"
  },
  {
    "text": "新加坡 +65",
    "value": "+65",
    "locale": "en-SG",
    "shortLocale": "SG"
  },
  {
    "text": "塞拉利昂 +232",
    "value": "+232",
    "locale": "en-SL",
    "shortLocale": "SL"
  },
  {
    "text": "坦桑尼亚 +255",
    "value": "+255",
    "locale": "en-TZ",
    "shortLocale": "TZ"
  },
  {
    "text": "乌干达 +256",
    "value": "+256",
    "locale": "en-UG",
    "shortLocale": "UG"
  },
  {
    "text": "美国 +1",
    "value": "+1",
    "locale": "en-US",
    "shortLocale": "US"
  },
  {
    "text": "南非 +27",
    "value": "+27",
    "locale": "en-ZA",
    "shortLocale": "ZA"
  },
  {
    "text": "赞比亚 +260",
    "value": "+260",
    "locale": "en-ZM",
    "shortLocale": "ZM"
  },
  {
    "text": "津巴布韦 +263",
    "value": "+263",
    "locale": "en-ZW",
    "shortLocale": "ZW"
  },
  {
    "text": "哥伦比亚 +57",
    "value": "+57",
    "locale": "es-CO",
    "shortLocale": "CO"
  },
  {
    "text": "智利 +56",
    "value": "+56",
    "locale": "es-CL",
    "shortLocale": "CL"
  },
  {
    "text": "哥斯达黎加 +506",
    "value": "+506",
    "locale": "es-CR",
    "shortLocale": "CR"
  },
  {
    "text": "厄瓜多尔 +593",
    "value": "+593",
    "locale": "es-EC",
    "shortLocale": "EC"
  },
  {
    "text": "西班牙 +34",
    "value": "+34",
    "locale": "es-ES",
    "shortLocale": "ES"
  },
  {
    "text": "墨西哥 +52",
    "value": "+52",
    "locale": "es-MX",
    "shortLocale": "MX"
  },
  {
    "text": "巴拿马 +507",
    "value": "+507",
    "locale": "es-PA",
    "shortLocale": "PA"
  },
  {
    "text": "巴拉圭 +595",
    "value": "+595",
    "locale": "es-PY",
    "shortLocale": "PY"
  },
  {
    "text": "乌拉圭 +598",
    "value": "+598",
    "locale": "es-UY",
    "shortLocale": "UY"
  },
  {
    "text": "爱沙尼亚 +372",
    "value": "+372",
    "locale": "et-EE",
    "shortLocale": "EE"
  },
  {
    "text": "伊朗 +98",
    "value": "+98",
    "locale": "fa-IR",
    "shortLocale": "IR"
  },
  {
    "text": "芬兰 +358",
    "value": "+358",
    "locale": "fi-FI",
    "shortLocale": "FI"
  },
  {
    "text": "斐济 +679",
    "value": "+679",
    "locale": "fj-FJ",
    "shortLocale": "FJ"
  },
  {
    "text": "法罗群岛 +298",
    "value": "+298",
    "locale": "fo-FO",
    "shortLocale": "FO"
  },
  {
    "text": "法国 +33",
    "value": "+33",
    "locale": "fr-FR",
    "shortLocale": "FR"
  },
  {
    "text": "法属圭亚那 +594",
    "value": "+594",
    "locale": "fr-GF",
    "shortLocale": "GF"
  },
  {
    "text": "瓜德罗普 +590",
    "value": "+590",
    "locale": "fr-GP",
    "shortLocale": "GP"
  },
  {
    "text": "马提尼克 +596",
    "value": "+596",
    "locale": "fr-MQ",
    "shortLocale": "MQ"
  },
  {
    "text": "留尼旺 +262",
    "value": "+262",
    "locale": "fr-RE",
    "shortLocale": "RE"
  },
  {
    "text": "以色列 +972",
    "value": "+972",
    "locale": "he-IL",
    "shortLocale": "IL"
  },
  {
    "text": "匈牙利 +36",
    "value": "+36",
    "locale": "hu-HU",
    "shortLocale": "HU"
  },
  {
    "text": "印度尼西亚 +62",
    "value": "+62",
    "locale": "id-ID",
    "shortLocale": "ID"
  },
  {
    "text": "意大利 +39",
    "value": "+39",
    "locale": "it-IT",
    "shortLocale": "IT"
  },
  {
    "text": "日本 +81",
    "value": "+81",
    "locale": "ja-JP",
    "shortLocale": "JP"
  },
  {
    "text": "哈萨克斯坦 +7",
    "value": "+7",
    "locale": "kk-KZ",
    "shortLocale": "KZ"
  },
  {
    "text": "格陵兰 +299",
    "value": "+299",
    "locale": "kl-GL",
    "shortLocale": "GL"
  },
  {
    "text": "韩国 +82",
    "value": "+82",
    "locale": "ko-KR",
    "shortLocale": "KR"
  },
  {
    "text": "立陶宛 +370",
    "value": "+370",
    "locale": "lt-LT",
    "shortLocale": "LT"
  },
  {
    "text": "马来西亚 +60",
    "value": "+60",
    "locale": "ms-MY",
    "shortLocale": "MY"
  },
  {
    "text": "挪威 +47",
    "value": "+47",
    "locale": "nb-NO",
    "shortLocale": "NO"
  },
  {
    "text": "尼泊尔 +977",
    "value": "+977",
    "locale": "ne-NP",
    "shortLocale": "NP"
  },
  {
    "text": "比利时 +32",
    "value": "+32",
    "locale": "nl-BE",
    "shortLocale": "BE"
  },
  {
    "text": "荷兰 +31",
    "value": "+31",
    "locale": "nl-NL",
    "shortLocale": "NL"
  },
  {
    "text": "波兰 +48",
    "value": "+48",
    "locale": "pl-PL",
    "shortLocale": "PL"
  },
  {
    "text": "巴西 +55",
    "value": "+55",
    "locale": "pt-BR",
    "shortLocale": "BR"
  },
  {
    "text": "葡萄牙 +351",
    "value": "+351",
    "locale": "pt-PT",
    "shortLocale": "PT"
  },
  {
    "text": "罗马尼亚 +40",
    "value": "+40",
    "locale": "ro-RO",
    "shortLocale": "RO"
  },
  {
    "text": "俄罗斯 +7",
    "value": "+7",
    "locale": "ru-RU",
    "shortLocale": "RU"
  },
  {
    "text": "斯洛文尼亚 +386",
    "value": "+386",
    "locale": "sl-SI",
    "shortLocale": "SI"
  },
  {
    "text": "斯洛伐克 +421",
    "value": "+421",
    "locale": "sk-SK",
    "shortLocale": "SK"
  },
  {
    "text": "塞尔维亚 +381",
    "value": "+381",
    "locale": "sr-RS",
    "shortLocale": "RS"
  },
  {
    "text": "瑞典 +46",
    "value": "+46",
    "locale": "sv-SE",
    "shortLocale": "SE"
  },
  {
    "text": "泰国 +66",
    "value": "+66",
    "locale": "th-TH",
    "shortLocale": "TH"
  },
  {
    "text": "土耳其 +90",
    "value": "+90",
    "locale": "tr-TR",
    "shortLocale": "TR"
  },
  {
    "text": "乌克兰 +380",
    "value": "+380",
    "locale": "uk-UA",
    "shortLocale": "UA"
  },
  {
    "text": "越南 +84",
    "value": "+84",
    "locale": "vi-VN",
    "shortLocale": "VN"
  },
  {
    "text": "中国 +86",
    "value": "+86",
    "locale": "zh-CN",
    "shortLocale": "CN"
  },
  {
    "text": "台湾 +886",
    "value": "+886",
    "locale": "zh-TW",
    "shortLocale": "TW"
  },
  // {
  //   "text": "加拿大 +1",
  //   "value": "+1",
  //   "locale": "en-CA",
  //   "shortLocale": "CA"
  // }
]