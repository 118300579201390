// TODO CREATE NEW USER-TASTE STATE 

enum UserTasteTypes {
  EditCurrentUserTaste = 'userTaste/EDIT_CURRENT_USER_TASTE',
  ResetCurrentUserTaste = 'userTaste/RESET_CURRENT_USER_TASTE',
  FetchCurrentUserTaste = 'userTaste/FETCH_CURRENT_USER_TASTE',

}

export default UserTasteTypes;
