import React from 'react';

const DollarOutlinedIcon = (props: any) => (
  <svg
    {...props}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16797 0C3.75011 0 0.167969 3.58214 0.167969 8C0.167969 12.4179 3.75011 16 8.16797 16C12.5858 16 16.168 12.4179 16.168 8C16.168 3.58214 12.5858 0 8.16797 0ZM8.16797 14.6429C4.50011 14.6429 1.52511 11.6679 1.52511 8C1.52511 4.33214 4.50011 1.35714 8.16797 1.35714C11.8358 1.35714 14.8108 4.33214 14.8108 8C14.8108 11.6679 11.8358 14.6429 8.16797 14.6429ZM9.01976 7.58571L8.56618 7.48036V5.08214C9.24475 5.175 9.6644 5.6 9.73583 6.12143C9.74475 6.19286 9.80547 6.24464 9.8769 6.24464H10.6787C10.7626 6.24464 10.8287 6.17143 10.8215 6.0875C10.7126 4.975 9.79654 4.26071 8.57333 4.1375V3.55357C8.57333 3.475 8.50904 3.41071 8.43047 3.41071H7.92868C7.85011 3.41071 7.78583 3.475 7.78583 3.55357V4.14286C6.52154 4.26607 5.53225 4.96429 5.53225 6.26786C5.53225 7.475 6.42154 8.05714 7.35547 8.28036L7.79654 8.39286V10.9411C7.00725 10.8357 6.5644 10.4143 6.47333 9.84643C6.46261 9.77857 6.4019 9.72857 6.33225 9.72857H5.50725C5.42333 9.72857 5.35725 9.8 5.3644 9.88393C5.44475 10.8661 6.1894 11.7696 7.77868 11.8857V12.4464C7.77868 12.525 7.84297 12.5893 7.92154 12.5893H8.42868C8.50725 12.5893 8.57154 12.525 8.57154 12.4446L8.56797 11.8786C9.96618 11.7554 10.9662 11.0071 10.9662 9.66429C10.9644 8.425 10.1769 7.87143 9.01976 7.58571V7.58571ZM7.79475 7.29643C7.69475 7.26786 7.61083 7.24107 7.5269 7.20714C6.92333 6.98929 6.64297 6.6375 6.64297 6.18393C6.64297 5.53571 7.13404 5.16607 7.79475 5.08214V7.29643ZM8.56618 10.9464V8.55893C8.62154 8.575 8.67154 8.5875 8.72333 8.59821C9.56797 8.85536 9.8519 9.2125 9.8519 9.76071C9.8519 10.4589 9.3269 10.8786 8.56618 10.9464Z"
      fill="white"
    />
  </svg>
);

export default DollarOutlinedIcon;
