import React from 'react';
import BaseIcon from './BaseIcon';

const IcTag = (props: any) => {
  return (
    <BaseIcon viewBox="0 0 32 32" {...props}>
      <path
        stroke="#c9c8cc"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.66667 7.66667H7.68333M7.66667 1H16C16.8531 0.999975 17.7061 1.32541 18.357 1.97631L30.0238 13.643C31.3255 14.9447 31.3255 17.0553 30.0238 18.357L18.357 30.0237C17.0553 31.3254 14.9447 31.3254 13.643 30.0237L1.97631 18.357C1.32544 17.7061 1 16.8531 1 16V7.66667C1 3.98477 3.98477 1 7.66667 1Z"
      />
    </BaseIcon>
  );
};

export default IcTag;

