import { AnyAction } from 'redux';
import { keyBy, Dictionary } from 'lodash';
import Location from 'model/location';
import Types, {
  FetchLocationsAction,
  FetchProjectsAction,
} from 'store/project/projectTypes';
import Project from 'model/project';

export type LocationState = {
  locationMap: Dictionary<Location>;
  projectList: Project[];
  nearbyLocations: FetchProjectsAction['data']['nearbyLocations'];
};

const initialState: LocationState = {
  locationMap: {},
  projectList: [],
  nearbyLocations: [],
};

const globalReducer = (
  state = initialState,
  action: AnyAction
): LocationState => {
  switch (action.type) {
    case Types.FetchLocations: {
      const { data } = action as FetchLocationsAction;
      return {
        ...state,
        locationMap: keyBy(data, 'id'),
      };
    }

    case Types.FetchProjects: {
      const {
        data: { projects, nearbyLocations },
      } = action as FetchProjectsAction;
      return {
        ...state,
        projectList: projects,
        nearbyLocations,
      };
    }

    default:
      return state;
  }
};

export default globalReducer;
