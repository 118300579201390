import Order from 'model/order';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type OrderState = {
  orders: Order[];
};

const initOrderState: OrderState = {
  orders: [],
};

type OrderActions = {
  updateOrders: (data: Order[]) => void;
};

const defaultOrderActions: OrderActions = {
  updateOrders: () => {},
};

const OrderContext = createContext<OrderState & OrderActions>({
  ...initOrderState,
  ...defaultOrderActions,
});

export const OrderProvider = ({ children }: PropsWithChildren<{}>) => {

  const [orders, setOrders] = useState<Order[]>([]);

  const updateOrders = (data: Order[]) => {
    setOrders(data);
  }

  return (
    <OrderContext.Provider
      value={{
        orders,
        updateOrders,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const OrderConsumer = OrderContext.Consumer;

export const useOrderContext = () => useContext(OrderContext);
