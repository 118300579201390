import { ELanguageLocales } from './currency';

export const DATE_FORMAT = 'DD/MM/YYYY';

export type Lang = "en" | "vi" | "zh" | "fr" | "ms" | "pl" | "ar" | "pt" | "es" | "de" | 'th' | 'in' | 'nl' | 'fil' | 'el' | 'ja' | 'ko' | 'tl' | 'id';

const DEFAULT_CDN_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';
const DEFAULT_CDN_SUFFIX = 'svg';

export const genFlagUrl = (countryCode: string) =>
  `${DEFAULT_CDN_URL}${countryCode.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

export const formatFlagUrl = (code: ELanguageLocales) => {
  switch (code) {
    case ELanguageLocales.ms:
      return 'my';

    case ELanguageLocales.in:
      return 'id';

    case ELanguageLocales.ar:
      return 'sa';

    case ELanguageLocales.tl:
      return 'ph'

    case ELanguageLocales.vi:
      return 'vn';

    case ELanguageLocales.en:
      return 'gb';

    case ELanguageLocales.zh:
      return 'cn';

    case ELanguageLocales.ko:
      return 'kr';

    case ELanguageLocales.ja:
      return 'jp';

    case ELanguageLocales.fil:
      return 'ph';

    case ELanguageLocales.el:
      return 'gr';

    default:
      return code;
  }
}

export const formatLanguageDefault = (code: Lang): Lang => {
  switch(code) {
    case 'id':
      return 'in';
    
    default:
      return code;
  }
}

export const formatLanguageCode = (code: ELanguageLocales) => {
  switch (code) {
    case ELanguageLocales.zh:
      return 'cn';

    case ELanguageLocales.ms:
      return 'my';

    case ELanguageLocales.fil:
      return 'ph';

    case ELanguageLocales.el:
      return 'gr';

    case ELanguageLocales.in:
      return 'id';

    default:
      return code;
  }
}

type TLanguage = {
  languageCode: Lang,
  countryCode: string,
  languageName: string,
  flagUrl: string,
}

export const LANGUAGES: TLanguage[] = [
  {
    languageCode: 'en',
    countryCode: 'us',
    languageName: 'English',
    flagUrl: genFlagUrl('gb'),
  },
  {
    languageCode: 'vi',
    countryCode: 'vn',
    languageName: 'Tiếng Việt',
    flagUrl: genFlagUrl('vn'),
  },
];

export type TCountryPhoneCode = {
  text: string,
  value: string,
  locale: string,
  shortLocale: string,
}

export const COUNTRY_CURRENCY_OPTIONS = [
  {
    "currency": "AED",
    "symbol": "د.إ",
    "text": "AED د.إ",
    "value": "AED"
  },
  {
    "currency": "AFN",
    "symbol": "Af",
    "text": "AFN Af",
    "value": "AFN"
  },
  {
    "currency": "ALL",
    "symbol": "L",
    "text": "ALL L",
    "value": "ALL"
  },
  {
    "currency": "AMD",
    "symbol": "Դ",
    "text": "AMD Դ",
    "value": "AMD"
  },
  {
    "currency": "AOA",
    "symbol": "Kz",
    "text": "AOA Kz",
    "value": "AOA"
  },
  {
    "currency": "ARS",
    "symbol": "$",
    "text": "ARS $",
    "value": "ARS"
  },
  {
    "currency": "AUD",
    "symbol": "$",
    "text": "AUD $",
    "value": "AUD"
  },
  {
    "currency": "AWG",
    "symbol": "ƒ",
    "text": "AWG ƒ",
    "value": "AWG"
  },
  {
    "currency": "AZN",
    "symbol": "ман",
    "text": "AZN ман",
    "value": "AZN"
  },
  {
    "currency": "BAM",
    "symbol": "КМ",
    "text": "BAM КМ",
    "value": "BAM"
  },
  {
    "currency": "BBD",
    "symbol": "$",
    "text": "BBD $",
    "value": "BBD"
  },
  {
    "currency": "BDT",
    "symbol": "৳",
    "text": "BDT ৳",
    "value": "BDT"
  },
  {
    "currency": "BGN",
    "symbol": "лв",
    "text": "BGN лв",
    "value": "BGN"
  },
  {
    "currency": "BHD",
    "symbol": "ب.د",
    "text": "BHD ب.د",
    "value": "BHD"
  },
  {
    "currency": "BIF",
    "symbol": "₣",
    "text": "BIF ₣",
    "value": "BIF"
  },
  {
    "currency": "BMD",
    "symbol": "$",
    "text": "BMD $",
    "value": "BMD"
  },
  {
    "currency": "BND",
    "symbol": "$",
    "text": "BND $",
    "value": "BND"
  },
  {
    "currency": "BOB",
    "symbol": "Bs.",
    "text": "BOB Bs.",
    "value": "BOB"
  },
  {
    "currency": "BRL",
    "symbol": "R$",
    "text": "BRL R$",
    "value": "BRL"
  },
  {
    "currency": "BSD",
    "symbol": "$",
    "text": "BSD $",
    "value": "BSD"
  },
  {
    "currency": "BTN",
    "symbol": "",
    "text": "BTN ",
    "value": "BTN"
  },
  {
    "currency": "BWP",
    "symbol": "P",
    "text": "BWP P",
    "value": "BWP"
  },
  {
    "currency": "BYN",
    "symbol": "Br",
    "text": "BYN Br",
    "value": "BYN"
  },
  {
    "currency": "BZD",
    "symbol": "$",
    "text": "BZD $",
    "value": "BZD"
  },
  {
    "currency": "CAD",
    "symbol": "$",
    "text": "CAD $",
    "value": "CAD"
  },
  {
    "currency": "CDF",
    "symbol": "₣",
    "text": "CDF ₣",
    "value": "CDF"
  },
  {
    "currency": "CHF",
    "symbol": "₣",
    "text": "CHF ₣",
    "value": "CHF"
  },
  {
    "currency": "CLP",
    "symbol": "$",
    "text": "CLP $",
    "value": "CLP"
  },
  {
    "currency": "CNY",
    "symbol": "¥",
    "text": "CNY ¥",
    "value": "CNY"
  },
  {
    "currency": "COP",
    "symbol": "$",
    "text": "COP $",
    "value": "COP"
  },
  {
    "currency": "CRC",
    "symbol": "₡",
    "text": "CRC ₡",
    "value": "CRC"
  },
  {
    "currency": "CUP",
    "symbol": "$",
    "text": "CUP $",
    "value": "CUP"
  },
  {
    "currency": "CVE",
    "symbol": "$",
    "text": "CVE $",
    "value": "CVE"
  },
  {
    "currency": "CZK",
    "symbol": "Kč",
    "text": "CZK Kč",
    "value": "CZK"
  },
  {
    "currency": "DJF",
    "symbol": "₣",
    "text": "DJF ₣",
    "value": "DJF"
  },
  {
    "currency": "DKK",
    "symbol": "kr",
    "text": "DKK kr",
    "value": "DKK"
  },
  {
    "currency": "DOP",
    "symbol": "$",
    "text": "DOP $",
    "value": "DOP"
  },
  {
    "currency": "DZD",
    "symbol": "د.ج",
    "text": "DZD د.ج",
    "value": "DZD"
  },
  {
    "currency": "EGP",
    "symbol": "£",
    "text": "EGP £",
    "value": "EGP"
  },
  {
    "currency": "ERN",
    "symbol": "Nfk",
    "text": "ERN Nfk",
    "value": "ERN"
  },
  {
    "currency": "ETB",
    "symbol": "",
    "text": "ETB ",
    "value": "ETB"
  },
  {
    "currency": "EUR",
    "symbol": "€",
    "text": "EUR €",
    "value": "EUR"
  },
  {
    "currency": "FJD",
    "symbol": "$",
    "text": "FJD $",
    "value": "FJD"
  },
  {
    "currency": "FKP",
    "symbol": "£",
    "text": "FKP £",
    "value": "FKP"
  },
  {
    "currency": "GBP",
    "symbol": "£",
    "text": "GBP £",
    "value": "GBP"
  },
  {
    "currency": "GEL",
    "symbol": "ლ",
    "text": "GEL ლ",
    "value": "GEL"
  },
  {
    "currency": "GHS",
    "symbol": "₵",
    "text": "GHS ₵",
    "value": "GHS"
  },
  {
    "currency": "GIP",
    "symbol": "£",
    "text": "GIP £",
    "value": "GIP"
  },
  {
    "currency": "GMD",
    "symbol": "D",
    "text": "GMD D",
    "value": "GMD"
  },
  {
    "currency": "GNF",
    "symbol": "₣",
    "text": "GNF ₣",
    "value": "GNF"
  },
  {
    "currency": "GTQ",
    "symbol": "Q",
    "text": "GTQ Q",
    "value": "GTQ"
  },
  {
    "currency": "GYD",
    "symbol": "$",
    "text": "GYD $",
    "value": "GYD"
  },
  {
    "currency": "HKD",
    "symbol": "$",
    "text": "HKD $",
    "value": "HKD"
  },
  {
    "currency": "HNL",
    "symbol": "L",
    "text": "HNL L",
    "value": "HNL"
  },
  {
    "currency": "HRK",
    "symbol": "Kn",
    "text": "HRK Kn",
    "value": "HRK"
  },
  {
    "currency": "HTG",
    "symbol": "G",
    "text": "HTG G",
    "value": "HTG"
  },
  {
    "currency": "HUF",
    "symbol": "Ft",
    "text": "HUF Ft",
    "value": "HUF"
  },
  {
    "currency": "IDR",
    "symbol": "Rp",
    "text": "IDR Rp",
    "value": "IDR"
  },
  {
    "currency": "ILS",
    "symbol": "₪",
    "text": "ILS ₪",
    "value": "ILS"
  },
  {
    "currency": "INR",
    "symbol": "₹",
    "text": "INR ₹",
    "value": "INR"
  },
  {
    "currency": "IQD",
    "symbol": "ع.د",
    "text": "IQD ع.د",
    "value": "IQD"
  },
  {
    "currency": "IRR",
    "symbol": "﷼",
    "text": "IRR ﷼",
    "value": "IRR"
  },
  {
    "currency": "ISK",
    "symbol": "Kr",
    "text": "ISK Kr",
    "value": "ISK"
  },
  {
    "currency": "JMD",
    "symbol": "$",
    "text": "JMD $",
    "value": "JMD"
  },
  {
    "currency": "JOD",
    "symbol": "د.ا",
    "text": "JOD د.ا",
    "value": "JOD"
  },
  {
    "currency": "JPY",
    "symbol": "¥",
    "text": "JPY ¥",
    "value": "JPY"
  },
  {
    "currency": "KES",
    "symbol": "Sh",
    "text": "KES Sh",
    "value": "KES"
  },
  {
    "currency": "KGS",
    "symbol": "",
    "text": "KGS ",
    "value": "KGS"
  },
  {
    "currency": "KHR",
    "symbol": "៛",
    "text": "KHR ៛",
    "value": "KHR"
  },
  {
    "currency": "KPW",
    "symbol": "₩",
    "text": "KPW ₩",
    "value": "KPW"
  },
  {
    "currency": "KRW",
    "symbol": "₩",
    "text": "KRW ₩",
    "value": "KRW"
  },
  {
    "currency": "KWD",
    "symbol": "د.ك",
    "text": "KWD د.ك",
    "value": "KWD"
  },
  {
    "currency": "KYD",
    "symbol": "$",
    "text": "KYD $",
    "value": "KYD"
  },
  {
    "currency": "KZT",
    "symbol": "〒",
    "text": "KZT 〒",
    "value": "KZT"
  },
  {
    "currency": "LAK",
    "symbol": "₭",
    "text": "LAK ₭",
    "value": "LAK"
  },
  {
    "currency": "LBP",
    "symbol": "ل.ل",
    "text": "LBP ل.ل",
    "value": "LBP"
  },
  {
    "currency": "LKR",
    "symbol": "Rs",
    "text": "LKR Rs",
    "value": "LKR"
  },
  {
    "currency": "LRD",
    "symbol": "$",
    "text": "LRD $",
    "value": "LRD"
  },
  {
    "currency": "LSL",
    "symbol": "L",
    "text": "LSL L",
    "value": "LSL"
  },
  {
    "currency": "LYD",
    "symbol": "ل.د",
    "text": "LYD ل.د",
    "value": "LYD"
  },
  {
    "currency": "MAD",
    "symbol": "د.م.",
    "text": "MAD د.م.",
    "value": "MAD"
  },
  {
    "currency": "MDL",
    "symbol": "L",
    "text": "MDL L",
    "value": "MDL"
  },
  {
    "currency": "MGA",
    "symbol": "",
    "text": "MGA ",
    "value": "MGA"
  },
  {
    "currency": "MKD",
    "symbol": "ден",
    "text": "MKD ден",
    "value": "MKD"
  },
  {
    "currency": "MMK",
    "symbol": "K",
    "text": "MMK K",
    "value": "MMK"
  },
  {
    "currency": "MNT",
    "symbol": "₮",
    "text": "MNT ₮",
    "value": "MNT"
  },
  {
    "currency": "MOP",
    "symbol": "P",
    "text": "MOP P",
    "value": "MOP"
  },
  {
    "currency": "MRU",
    "symbol": "UM",
    "text": "MRU UM",
    "value": "MRU"
  },
  {
    "currency": "MUR",
    "symbol": "₨",
    "text": "MUR ₨",
    "value": "MUR"
  },
  {
    "currency": "MVR",
    "symbol": "ރ.",
    "text": "MVR ރ.",
    "value": "MVR"
  },
  {
    "currency": "MWK",
    "symbol": "MK",
    "text": "MWK MK",
    "value": "MWK"
  },
  {
    "currency": "MXN",
    "symbol": "$",
    "text": "MXN $",
    "value": "MXN"
  },
  {
    "currency": "MYR",
    "symbol": "RM",
    "text": "MYR RM",
    "value": "MYR"
  },
  {
    "currency": "MZN",
    "symbol": "MTn",
    "text": "MZN MTn",
    "value": "MZN"
  },
  {
    "currency": "NAD",
    "symbol": "$",
    "text": "NAD $",
    "value": "NAD"
  },
  {
    "currency": "NGN",
    "symbol": "₦",
    "text": "NGN ₦",
    "value": "NGN"
  },
  {
    "currency": "NIO",
    "symbol": "C$",
    "text": "NIO C$",
    "value": "NIO"
  },
  {
    "currency": "NOK",
    "symbol": "kr",
    "text": "NOK kr",
    "value": "NOK"
  },
  {
    "currency": "NPR",
    "symbol": "₨",
    "text": "NPR ₨",
    "value": "NPR"
  },
  {
    "currency": "NZD",
    "symbol": "$",
    "text": "NZD $",
    "value": "NZD"
  },
  {
    "currency": "OMR",
    "symbol": "ر.ع.",
    "text": "OMR ر.ع.",
    "value": "OMR"
  },
  {
    "currency": "PAB",
    "symbol": "B/.",
    "text": "PAB B/.",
    "value": "PAB"
  },
  {
    "currency": "PEN",
    "symbol": "S/.",
    "text": "PEN S/.",
    "value": "PEN"
  },
  {
    "currency": "PGK",
    "symbol": "K",
    "text": "PGK K",
    "value": "PGK"
  },
  {
    "currency": "PHP",
    "symbol": "₱",
    "text": "PHP ₱",
    "value": "PHP"
  },
  {
    "currency": "PKR",
    "symbol": "₨",
    "text": "PKR ₨",
    "value": "PKR"
  },
  {
    "currency": "PLN",
    "symbol": "zł",
    "text": "PLN zł",
    "value": "PLN"
  },
  {
    "currency": "PYG",
    "symbol": "₲",
    "text": "PYG ₲",
    "value": "PYG"
  },
  {
    "currency": "QAR",
    "symbol": "ر.ق",
    "text": "QAR ر.ق",
    "value": "QAR"
  },
  {
    "currency": "RON",
    "symbol": "L",
    "text": "RON L",
    "value": "RON"
  },
  {
    "currency": "RSD",
    "symbol": "din",
    "text": "RSD din",
    "value": "RSD"
  },
  {
    "currency": "RUB",
    "symbol": "р.",
    "text": "RUB р.",
    "value": "RUB"
  },
  {
    "currency": "RWF",
    "symbol": "₣",
    "text": "RWF ₣",
    "value": "RWF"
  },
  {
    "currency": "SAR",
    "symbol": "ر.س",
    "text": "SAR ر.س",
    "value": "SAR"
  },
  {
    "currency": "SBD",
    "symbol": "$",
    "text": "SBD $",
    "value": "SBD"
  },
  {
    "currency": "SCR",
    "symbol": "₨",
    "text": "SCR ₨",
    "value": "SCR"
  },
  {
    "currency": "SDG",
    "symbol": "£",
    "text": "SDG £",
    "value": "SDG"
  },
  {
    "currency": "SEK",
    "symbol": "kr",
    "text": "SEK kr",
    "value": "SEK"
  },
  {
    "currency": "SGD",
    "symbol": "$",
    "text": "SGD $",
    "value": "SGD"
  },
  {
    "currency": "SHP",
    "symbol": "£",
    "text": "SHP £",
    "value": "SHP"
  },
  {
    "currency": "SLL",
    "symbol": "Le",
    "text": "SLL Le",
    "value": "SLL"
  },
  {
    "currency": "SOS",
    "symbol": "Sh",
    "text": "SOS Sh",
    "value": "SOS"
  },
  {
    "currency": "SRD",
    "symbol": "$",
    "text": "SRD $",
    "value": "SRD"
  },
  {
    "currency": "STN",
    "symbol": "Db",
    "text": "STN Db",
    "value": "STN"
  },
  {
    "currency": "SYP",
    "symbol": "ل.س",
    "text": "SYP ل.س",
    "value": "SYP"
  },
  {
    "currency": "SZL",
    "symbol": "L",
    "text": "SZL L",
    "value": "SZL"
  },
  {
    "currency": "THB",
    "symbol": "฿",
    "text": "THB ฿",
    "value": "THB"
  },
  {
    "currency": "TJS",
    "symbol": "ЅМ",
    "text": "TJS ЅМ",
    "value": "TJS"
  },
  {
    "currency": "TMT",
    "symbol": "m",
    "text": "TMT m",
    "value": "TMT"
  },
  {
    "currency": "TND",
    "symbol": "د.ت",
    "text": "TND د.ت",
    "value": "TND"
  },
  {
    "currency": "TOP",
    "symbol": "T$",
    "text": "TOP T$",
    "value": "TOP"
  },
  {
    "currency": "TRY",
    "symbol": "₤",
    "text": "TRY ₤",
    "value": "TRY"
  },
  {
    "currency": "TTD",
    "symbol": "$",
    "text": "TTD $",
    "value": "TTD"
  },
  {
    "currency": "TWD",
    "symbol": "$",
    "text": "TWD $",
    "value": "TWD"
  },
  {
    "currency": "TZS",
    "symbol": "Sh",
    "text": "TZS Sh",
    "value": "TZS"
  },
  {
    "currency": "UAH",
    "symbol": "₴",
    "text": "UAH ₴",
    "value": "UAH"
  },
  {
    "currency": "UGX",
    "symbol": "Sh",
    "text": "UGX Sh",
    "value": "UGX"
  },
  {
    "currency": "USD",
    "symbol": "$",
    "text": "USD $",
    "value": "USD"
  },
  {
    "currency": "UYU",
    "symbol": "$",
    "text": "UYU $",
    "value": "UYU"
  },
  {
    "currency": "UZS",
    "symbol": "",
    "text": "UZS ",
    "value": "UZS"
  },
  {
    "currency": "VEF",
    "symbol": "Bs F",
    "text": "VEF Bs F",
    "value": "VEF"
  },
  {
    "currency": "VND",
    "symbol": "₫",
    "text": "VND ₫",
    "value": "VND"
  },
  {
    "currency": "VUV",
    "symbol": "Vt",
    "text": "VUV Vt",
    "value": "VUV"
  },
  {
    "currency": "WST",
    "symbol": "T",
    "text": "WST T",
    "value": "WST"
  },
  {
    "currency": "XAF",
    "symbol": "₣",
    "text": "XAF ₣",
    "value": "XAF"
  },
  {
    "currency": "XCD",
    "symbol": "$",
    "text": "XCD $",
    "value": "XCD"
  },
  {
    "currency": "XPF",
    "symbol": "₣",
    "text": "XPF ₣",
    "value": "XPF"
  },
  {
    "currency": "YER",
    "symbol": "﷼",
    "text": "YER ﷼",
    "value": "YER"
  },
  {
    "currency": "ZAR",
    "symbol": "R",
    "text": "ZAR R",
    "value": "ZAR"
  },
  {
    "currency": "ZMW",
    "symbol": "ZK",
    "text": "ZMW ZK",
    "value": "ZMW"
  },
  {
    "currency": "ZWL",
    "symbol": "$",
    "text": "ZWL $",
    "value": "ZWL"
  }
];
