export const DATA_FAQ_TL = (textBrand: string) => {
  return [
    {
      title:
        `Sino nga ba ang ${textBrand} International at anong dala nila sa mesa?`,
      description: `
            <p>Sige, pag-usapan natin ang pangunahing merkado ng ari-arian at ang nakakabagot, lumang karanasan ng pagbebenta na kasama nito. Narito ang ${textBrand} International upang baguhin ang laro. Nandito kami upang pagyanigin ang mga bagay, kaibigan.</p>
            <p>Kasama namin ang pagtatayo ng mga tahanan ng hinaharap. Paano namin ginagawa ito? Sa pamamagitan ng teknolohiyang pang-personalize. Oo, tama ka. Ibinibida ng ${textBrand} ang isang kahindik-hindik na 3D interior design tool na pinahihintulutan ng artificial intelligence. Oo, narinig mo ako nang tama. Nagpapakita ito sa iyo ng iyong espasyo sa pamumuhay sa totoong oras, isang silid pagkatapos ng isa, at premodel ang iyong hinaharap na tahanan.</p>
            <p>With ${textBrand}, you'll embark on a journey that's smooth, enjoyable, and transparent. No more surprises, no more delays. It's a seamless, efficient journey towards your dream home.</p>
           `,
    },
    {
      title:
        'Ano nga ba ang Sekmento at paano ito gumagana kapag bumibili ng bagong bahay?',
      description: `
            <p>Sige, kaibigan, pag-usapan natin ang mga Sekmento at kung paano nila pinapatakbo ang mahika sa mundo ng pagbili ng bahay ng ${textBrand}.</p>
            <p>Ang isang Sekmento ay parang isang piraso ng puzzle na maaaring pagsamahin upang lumikha ng isang espasyo na akma sa iyong mga kagustuhan at budget.</p>
            <p>Isipin ito bilang isang natatanging sukat ng lugar na maaari mong bilhin nang hiwalay o marami, personalizing ang iyong bagong bahay upang matugunan ang iyong bawat pangangailangan at nais.</p>
           `,
    },
    {
      title:
        'Bakit napakahalaga ng Kasunduan sa Pagreserba sa proseso ng pagbili?',
      description: `
            <p>Sige, pag-usapan natin ang bagay na tinatawag na Kasunduan sa Pagreserba. Baka iniisip mo, "Ano iyon at bakit ko ba dapat intindihin iyan?" E, kaibigan, hayaan mo akong ipaliwanag sa iyo. Ang Kasunduang ito ay isang mahalagang elemento sa masasayang pagbili ng ari-arian. Bakit? Dahil ito'y parang isang safety net, kaibigan.</p>
            <p>Kapag pumirma ka ng Kasunduan sa Pagreserba, praktikal mong sinasabi: "Hintay, may mata ako sa ari-ariang ito, at gusto kong tiyakin na sa akin ito."</p>
            <p>Bakit ito napakahalaga? Simple lang. Nagbibigay ito ng katahimikan ng isip at nagpapangalaga sa iyong mga interes. Ito'y tiyak na hindi mabebenta ang ari-arian habang abala ka sa pag-aayos ng mga papeles.</p>
           `,
    },
    {
      title:
        'Ano ba ang kasama sa Kasunduan sa Pagbili at Pagbebenta at ano ang mga nilalaman nito sa mundo ng pagbili ng bahay?',
      description: `
            <p>Ang Kasunduan sa Pagbili at Pagbebenta ay parang banal na kaldero sa pagbili ng bahay. Ito ang dokumentong naglalagda ng kasunduan at nagpapangalaga sa iyong puwet sa proseso.</p>
            <p>Ipaliwanag ko sa iyo: kapag bumibili ka ng bahay, hindi mo lang ibinibigay ang iyong pera at umaasa sa pinakamahusay. Dito pumapasok ang munting bagay na ito. Ito ay isang legal na nakatali sa isa't isa na nagtatakda ng lahat ng mahalagang bagay. Ito'y tumutukoy sa halaga ng bahay, mga kondisyon ng paghahatid, at takdang panahon para magsara ang kasunduan.</p>
            <p>Ito ay hindi simpleng salamangkero na kasunduan sa pagkakahawak-kamay, kaibigan. Ito ay seryosong pangako na tinitiyak na pareho ang pananagutan ng mga partido. Kaya, kapag handa ka nang gumawa ng malaking pagbili, siguraduhin na may solidong Kasunduan sa Pagbili at Pagbebenta sa lugar. Ito ay ang iyong safety net sa mabulok na mundo ng pagbili ng bahay.</p>
           `,

    },
    {
      title:
        `Paano mo mapapalitan ang iyong pinakamalulupit na pangarap sa pagbili ng bahay sa katotohanan sa pamamagitan ng misteryosong kapangyarihan ng ${textBrand} International?`,
      description: `
            <p>Kaya, nag-aalab ang iyong kagustuhan na malaman kung paano mo maisasakatuparan ang iyong pangarap na bahay sa pamamagitan ng ${textBrand} International? E, abangan, kaibigan, dahil ipapaliwanag ko iyan sa iyo.</p>
            <p><strong>Hakbang isa:</strong> Piliin ang iyong ninanais na bansa. Saan mo nakikita ang iyong pinakamagandang buhay? Pumili at magsimula tayo.</p>
            <p><strong>Hakbang dalawa:</strong> Mayroon kaming maraming mga proyektong naka-lista, naghihintay lamang na iyong pasukin. Pag-aralan mo ang mga detalye, isipin ang iyong sarili sa mga espasyong iyon, at imahinang mamumuhay ka doon.</p>
            <p><strong>Hakbang tatlo:</strong> Itinutok ang iyong mga pagpipilian, kaibigan. Pumili ng bilang ng mga Sekmento na kaya mong bilhin, at ang palapag na pumupukaw sa iyong kaluluwa.</p>
            <p><strong>Hakbang apat:</strong> Ito ang pinaka-kahanga-hanga. Ihanda ang iyong sarili para sa bahagi na magtatahimik sa iyo. Maaari mong pre-model ang iyong apartment sa stunning, 3D na pananaw. Oo, binabanggit namin na dito mo bubuhayin ang iyong pangarap na bahay sa harap ng iyong mga mata. Ayusin mo ito, ayusin mo ayon sa iyong panlasa, at gawing perpekto. Ah, at nabanggit ba naming makakakuha ka ng kumpletong pag-unawa at transparensiya sa mga gastos? Walang anumang labis-labis dito.</p>
            <p><strong>Hakbang lima:</strong> Kapag kontento ka na sa iyong obra-maestra, oras na para lagdaan ang kasunduan. Pumirma ng iyong kasunduang pagreserba at kasunduan sa pagbili online, at voilà!</p>
            <p>Ngayon, umupo ka, kaibigan, at hayaan ang inaasam-asam na dagdagan. Malapit na ay masasarapang mamamahay sa bahay ng iyong mga pangarap. Nandito ang ${textBrand} upang gawin iyon sa realidad.</p> 
          `,
    },

    {

      title:
        'Paano sumali sa listahan ng mga naghihintay at manatiling updated sa mga paparating na ari-arian?',
      description: `
            <p>Gusto mo bang agad makakuha ng mga mahiwagang ari-arian na may tatak na "Sa Makakalipas"? Hindi kita masisisi. Eto ang lihim kung paano mananatiling updated at una sa mga pangyayari.</p>
            <p>Pumunta sa aming website at hanapin ang seksyon na "Manatiling Updated". Ito ay parang isang yaman para sa mga katulad mong naghahanap ng mga bahay. Hanapin ang opsiyon na sumali sa listahan ng naghihintay. Ilagay ang iyong email address at ituring ang sarili mo na officially in the know.</p>
            <p>Tiyak naming ikaw ang una na makakaalam kapag handa nang lumipad ang mga kapana-panabik na proyektong ito. Parang pagkakaroon ng backstage pass sa mundo ng ari-arian. Kaya't huwag mag-aksaya ng kahit isang segundo, kaibigan. Sumali sa listahan ng mga naghihintay at simulan na ang pag-aabang.</p>
          `,
    },

    {
      title:
        `Paano pinahahalagahan ng ${textBrand} International ang proteksyon ng iyong mahahalagang datos at anong mga pakikipagtulungan nila para masigurong ligtas ang mga transaksyon?`,
      description: `
            <p>Para sa ${textBrand} International, hindi kami nagbibiro pagdating sa proteksyon ng mga datos. Ang iyong impormasyon ay mahalaga para sa amin at ito'y binabantayan namin ng buong pagsisikap. Hindi kami gumagamit ng pangalawang klaseng solusyon. Kumakasa kami sa mga pinakamahusay sa mga pinakamahusay upang masigurong ligtas at protektado ang iyong mga datos.</p>
            <p>Mayroon kaming Tazapay, isang kumpanya mula sa Singapore na espesyalista sa ligtas na pag-iimbak ng mga detalye ng pagbabayad. Ngunit hindi doon nagtatapos. Mas pinaigting pa namin ito sa pamamagitan ng makabagong teknolohiya ng Pandadoc para sa digital na pagpirma ng mga kasunduan.</p>
            <p>Walang iniwan, kaibigan. Ang iyong mga datos ay ang aming pangunahing prayoridad, at mayroon kaming tamang mga pakikipagtulungan upang masigurong ganito.</p>
          `,
    },
  ];
};
