import { MORTGAGE_TYPE } from 'model/payment.model';
import { Obj } from 'constants/types';
import { CURRENCY_LOCALE_CODES, ECurrency, ELocales } from 'constants/currency'
import { getSession } from 'services/storageService';
import { SessionKey } from 'constants/storage';

const currencyFormatters: Obj = {};
const numberDecimalFormatters: Obj = {};
export const A_YEAR_TO_MONTH = 12;
export const A_QUARTER_TO_MONTH = 3;

export const currencyFormat = (number: number, currency: string = 'USD') => {
  if (!currencyFormatters[currency]) {
    // todo: more logic for localization
    const locales = currency === 'USD' ? 'es-US' : 'de-DE';

    currencyFormatters[currency] =  new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
    });
  }
  
  return currencyFormatters[currency].format(Math.round(number));
}

let numberFormatter: any = null;

export const numberFormat = (number: number) => {
  if (!numberFormatter) {
    numberFormatter = new Intl.NumberFormat('en-US');
  }
  
  return numberFormatter.format(number)
}

type FenNumberFormatterFunc = (options?: {
  signDisplay?: 'always';
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}) => (number: number) => string;

export const genNumberFormatter: FenNumberFormatterFunc = (options) => {
  const { signDisplay, ...formatOptions } = options || {};
  
  const numberFormatter = new Intl.NumberFormat('en-US', formatOptions);
  
  if (signDisplay === 'always') {
    return (number: number) => (number > 0 ? '+' : '') + numberFormatter.format(number);
  }
  
  return (number: number) => numberFormatter.format(number);
}

export const calculateMonthlyPayment = (data: {loan: number, months: number, rate: number, type: MORTGAGE_TYPE}): number => {
  const monthlyCapitalRepayment = data.loan / data.months;
  const monthlyInterest = data.loan * (data.rate / 100) / A_YEAR_TO_MONTH;
  return {
    [MORTGAGE_TYPE.INTEREST_ONLY]: data.loan * (data.rate / 100) / A_YEAR_TO_MONTH,
    [MORTGAGE_TYPE.CAPITAL_INTEREST]: monthlyCapitalRepayment + monthlyInterest,
    [MORTGAGE_TYPE.FLEXIBLE_SAVER]: ((monthlyCapitalRepayment / 2) + monthlyInterest)
  }[data.type]
}

export const calculateMonthlyCapitalRepayment = (data: {loan: number, months: number, type: MORTGAGE_TYPE}): number => {
  const monthlyCapitalRepayment = data.loan / data.months;
  return {
    [MORTGAGE_TYPE.INTEREST_ONLY]: 0,
    [MORTGAGE_TYPE.CAPITAL_INTEREST]: monthlyCapitalRepayment,
    [MORTGAGE_TYPE.FLEXIBLE_SAVER]: (monthlyCapitalRepayment / 2)
  }[data.type]
}

// MIN = Minimum expected value
// MAX = Maximium expected value
// Function to normalise the values (MIN / MAX could be integrated)
// Generate % of value between min and max (0 - 100)
export const normalise = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

export const formatNumberDecimal = (number?: number, translateCode = ELocales.en): string => {
  const locales = CURRENCY_LOCALE_CODES[translateCode];
  const currency = getSession(SessionKey.Currency) as ECurrency;
  if (!numberDecimalFormatters[currency]) {
    // todo: more logic for localization
    numberDecimalFormatters[currency] = new Intl.NumberFormat(locales, {
      style: 'decimal',
      currency: currency,
      minimumFractionDigits: 0,
    });
  }

  return numberDecimalFormatters[currency].format(Math.floor(number || 0));
}

export const replaceStringByCurrency = (value: string, translateCode = ELocales.en): string => {
  let newTxt = ''
  switch(translateCode) {
    case ELocales.vi:
      newTxt = value.replace(/\./g, '')
      break; 
    case ELocales.en:
      newTxt = value.replace(/\,/g, '')
  }
  return newTxt;
}

export const padZero = (number: number): string => {
  return number >= 10 ? `${number}` : `0${number}`
}
