import { Size, SizeMap, MEDIA_BREAKPOINT } from 'theme/theme';

// Convert pixel to rem
export const rem = (pixel: number) => `${pixel / 16}rem`;

export const length = (lengthUnits: string | number) => {
  if (typeof +lengthUnits === 'number') {
    return rem(+lengthUnits);
  }
  return lengthUnits;
}

const mediaCss = (mediaMap: SizeMap) => {
  let hasDefaultMedia = false;
  let mediaCss = '';
  Object.keys(mediaMap).forEach((size) => {
    const cssString = mediaMap[size as Size];
    const mediaBreakpoint = MEDIA_BREAKPOINT[size as Size];
    
    if (cssString) {
      if (!hasDefaultMedia) {
        mediaCss = cssString;
        hasDefaultMedia = true;
      } else {
        mediaCss += `
          @media ${mediaBreakpoint} {
            ${cssString}
          }
        `;
      }
    }
  });
  
  return mediaCss;
}

export default {
  rem,
  mediaCss,
  length,
};
