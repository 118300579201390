import React from 'react';

const IcNavShow = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4506_9349)">
        <path
          d="M9.02537 0.401975C11.6479 0.401975 14.2709 0.404498 16.8933 0.398442C17.1596 0.397937 17.4036 0.44134 17.491 0.709327C17.5875 1.00406 17.6274 1.32504 17.4172 1.59706C17.3536 1.67933 17.2242 1.72021 17.1166 1.75503C17.0404 1.77976 16.9499 1.76361 16.8661 1.76361C11.6292 1.76361 6.39227 1.76311 1.15537 1.76664C0.898224 1.76664 0.611776 1.76058 0.543574 1.49007C0.475878 1.22259 0.49356 0.913724 0.552163 0.640185C0.5946 0.439321 0.821939 0.399451 1.03109 0.399956C2.83011 0.403489 4.62862 0.401975 6.42763 0.401975C7.29354 0.401975 8.15945 0.401975 9.02486 0.401975H9.02537Z"
          fill="white"
        />
        <path
          d="M19.9852 7.32103C19.5412 6.83956 19.1481 6.41361 18.7556 5.98766C18.4419 5.64699 18.4671 5.39566 18.8526 5.02018C19.1496 4.73049 19.427 4.72898 19.7286 5.02724C20.5773 5.86603 21.4235 6.70683 22.2687 7.54915C22.5764 7.85599 22.5769 8.13862 22.2713 8.44597C21.4296 9.29182 20.5869 10.1362 19.7417 10.9785C19.4553 11.2641 19.1678 11.2641 18.8824 10.9901C18.5005 10.6242 18.4585 10.3628 18.747 10.0418C19.1456 9.59867 19.5518 9.16312 20.0034 8.67207C19.8529 8.67207 19.7584 8.67207 19.6644 8.67207C13.5273 8.67207 7.39012 8.67207 1.25346 8.67207C0.634088 8.67207 0.306719 8.13004 0.621458 7.58902C0.693196 7.46537 0.877594 7.39724 1.02208 7.32809C1.09079 7.29529 1.18779 7.32103 1.27165 7.32103C7.39214 7.32103 13.5121 7.32103 19.6326 7.32103H19.9868H19.9852Z"
          fill="white"
        />
        <path
          d="M9.0696 15.5973C6.43852 15.5973 3.80745 15.5948 1.17637 15.6008C0.908613 15.6013 0.667633 15.5529 0.577707 15.2884C0.477678 14.9927 0.446356 14.6717 0.655508 14.3997C0.718658 14.3179 0.847989 14.274 0.956102 14.2437C1.05007 14.2175 1.15616 14.2331 1.2572 14.2331C6.46075 14.2331 11.6638 14.2331 16.8674 14.2331C17.5115 14.2331 17.7994 14.6556 17.5736 15.2708C17.4822 15.5196 17.2922 15.6003 17.0391 15.5998C15.5847 15.5963 14.1307 15.5978 12.6762 15.5978C11.4744 15.5978 10.272 15.5978 9.07011 15.5978L9.0696 15.5973Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4506_9349">
          <rect
            width="22"
            height="15.2027"
            fill="white"
            transform="translate(0.5 0.398438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcNavShow;
