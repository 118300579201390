import { isEmpty } from 'lodash';
import { fetchSaleAgentInfoApi } from 'apis/userApis';

// CONSTANTS
import { SessionKey, SessionValues } from 'constants/storage';

// SERVICES
import {
  getSession,
  setSession,
  removeSession,
  hasSession,
  getStorage,
  setStorage,
  removeStorage,
} from './storageService';

// UTILS
import { OldPageRoute } from 'constants/route';
import { User } from 'model/user';

// CONSTANTS
import { StorageKey } from 'constants/storage';

// Sale Agent
export enum LocalStorageKey {
  AccessToken = 'token',
  RememberMe = 'remember_me',
}

let accessToken: string;
let userInfo: User;

// Load user info and access token to local variables to reduce JSON.parse session
const loadAuth = () => {
  const {
    [SessionKey.SaleAgentAccessToken]: sessionAuthData,
    [SessionKey.SaleAgentInfo]: sessionUserInfo,
  } = getSession([
    SessionKey.SaleAgentAccessToken,
    SessionKey.SaleAgentInfo,
  ]) as Pick<
    SessionValues,
    SessionKey.SaleAgentAccessToken | SessionKey.SaleAgentInfo
  >;

  if (sessionAuthData) {
    accessToken = sessionAuthData.accessToken;
  }

  if (sessionUserInfo) {
    userInfo = sessionUserInfo;
  }
};

export const setUserInfo = (userData: User) => {
  setSession(SessionKey.SaleAgentInfo, userData);
  userInfo = userData;
};

export const setAuth = (
  authData: { accessToken: string },
  rememberMe: boolean
) => {
  setSession(SessionKey.SaleAgentAccessToken, authData);

  if (rememberMe) {
    setStorage(StorageKey.AccessToken, authData);
  }

  setStorage(StorageKey.RememberMe, rememberMe);
  accessToken = authData.accessToken;
};

// If user is not logged in, access token will be empty
export const getAgentAccessToken = () => {
  if (!accessToken) {
    loadAuth();
  }

  return accessToken;
};

export const getAgentInfo = (): User => {
  if (!userInfo) {
    loadAuth();
  }

  return userInfo as User;
};

export const fetchUserInfo = async () => {
  if (isEmpty(accessToken)) {
    return {};
  }

  try {
    const r = await fetchSaleAgentInfoApi();
    const { data: user } = r;
    setUserInfo(user);
    return user;
  } catch (e) {
    console.log(e);
  }

  return {};
};

export const pingUserInfoService = async () => {
  try {
    await fetchSaleAgentInfoApi();
  } catch (e) {
    console.log('pingUserInfoService', e);
  }
};

export const login = async (accessToken: string, rememberMe: boolean) => {
  setAuth({ accessToken }, rememberMe);

  // Load user info
  await fetchUserInfo();
};

export const agentLogout = (keepCurrentUrl = false) => {
  accessToken = '';
  userInfo = new User();

  removeSession(true);
  removeStorage(true);

  if (keepCurrentUrl) {
    setSession(
      SessionKey.LoginRedirectUrl,
      window.location.pathname + window.location.search
    );
  }
  window.location.href = OldPageRoute.SaleAgentLogin;
};

export const checkLogin = () => {
  if (hasSession(SessionKey.SaleAgentAccessToken)) return true;

  const rememberMe = getStorage(StorageKey.RememberMe);

  if (!rememberMe) return false;

  const localStorageToken = getStorage(StorageKey.AccessToken);
  if (!localStorageToken) return false;

  setSession(SessionKey.SaleAgentAccessToken, localStorageToken);

  return true;
};
