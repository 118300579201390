import { i18n } from 'locales';
import env from 'constants/env';

// RULE characters
// @read https://docs.google.com/spreadsheets/d/1SUKZoQTT01KmYRLtwP3crLYZHy3oHt_a/edit#gid=1473271337

export enum RankCharacter {
  Steady = 'S',
  Energetic = 'E',
  Raw = 'R',
  ManMade = 'M',
  Neutral = 'N',
  Vibrant = 'V',
  Period = 'P',
  Current = 'C',
}

export enum RankCategory {
  Motion = 'Motion',
  Texture = 'Texture',
  Palette = 'Palette',
  Era = 'Era',
}

export const RANK_CATEGORY_CHARACTERS_MAP: Record<RankCategory, RankCharacter[]> = {
  [RankCategory.Motion]: [RankCharacter.Steady, RankCharacter.Energetic],
  [RankCategory.Texture]: [RankCharacter.Raw, RankCharacter.ManMade],
  [RankCategory.Palette]: [RankCharacter.Neutral, RankCharacter.Vibrant],
  [RankCategory.Era]: [RankCharacter.Period, RankCharacter.Current],
}

export const tCharactersMap = (): Record<RankCharacter, {
  order: number,
  label: string,
  category: RankCategory,
}> => ({
  [RankCharacter.Steady]: {
    order: 1,
    label: i18n.t('Steady'),
    category: RankCategory.Motion,
  },
  [RankCharacter.Energetic]: {
    order: 1,
    label: i18n.t('Energetic'),
    category: RankCategory.Motion,
  },
  
  [RankCharacter.Raw]: {
    order: 2,
    label: i18n.t('Raw'),
    category: RankCategory.Texture,
  },
  [RankCharacter.ManMade]: {
    order: 2,
    label: i18n.t('Processed'),
    category: RankCategory.Texture,
  },
  
  [RankCharacter.Neutral]: {
    order: 3,
    label: i18n.t('Neutral'),
    category: RankCategory.Palette,
  },
  [RankCharacter.Vibrant]: {
    order: 3,
    label: i18n.t('Vibrant'),
    category: RankCategory.Palette,
  },
  
  [RankCharacter.Period]: {
    order: 4,
    label: i18n.t('Classic'),
    category: RankCategory.Era,
  },
  [RankCharacter.Current]: {
    order: 4,
    label: i18n.t('Modern'),
    category: RankCategory.Era,
  },
});

export enum ResidentialType {
  Residential = 'RESIDENTIAL',
  Hospitality = 'HOSPITALITY',
}

export const HOSPITALITY_PERSONALITY_MAP = {
  [env.HOSPITALITY_ARTIST_ANSWER_ID]: {
    name: 'artist',
    personalityId: env.HOSPITALITY_ARTIST_PERSONALITY_ID,
    personalityCode: env.HOSPITALITY_ARTIST_PERSONALITY_CODE,
  },
  [env.HOSPITALITY_POET_ANSWER_ID]: {
    name: 'poet',
    personalityId: env.HOSPITALITY_POET_PERSONALITY_ID,
    personalityCode: env.HOSPITALITY_POET_PERSONALITY_CODE,
  },
  [env.HOSPITALITY_VISIONARY_ANSWER_ID]: {
    name: 'visionary',
    personalityId: env.HOSPITALITY_VISIONARY_PERSONALITY_ID,
    personalityCode: env.HOSPITALITY_VISIONARY_PERSONALITY_CODE,
  },
  [env.HOSPITALITY_PURIST_ANSWER_ID]: {
    name: 'purist',
    personalityId: env.HOSPITALITY_PURIST_PERSONALITY_ID,
    personalityCode: env.HOSPITALITY_PURIST_PERSONALITY_CODE,
  },
}

export const HOSPITALITY_PERSONALITY_IDS = [
  env.HOSPITALITY_ARTIST_PERSONALITY_ID,
  env.HOSPITALITY_POET_PERSONALITY_ID,
  env.HOSPITALITY_VISIONARY_PERSONALITY_ID,
  env.HOSPITALITY_PURIST_PERSONALITY_ID,
  env.HOSPITALITY_STYLIST_PERSONALITY_ID,
]