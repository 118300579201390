export const TERM_EN = (textBrand: string) => {
  return [
    {
      title: 'Acceptance of Terms',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; gap: 0.3rem;">
              <div>1.1.</div>
              <div>By accessing, using <a href="https://levitate.homes">https://levitate.homes</a> (the "Website"), you conclude a legally binding agreement on the terms and conditions herein ("Terms of Use") with us, ${textBrand} International Pte. Ltd. ("we", "us", "our" or "${textBrand}"), the owner and administrator of the Website.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.2.</div>
              <div>You accept without limitation or qualification the Terms of Use, including the Privacy Policy.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.3.</div>
              <div>We may amend these Terms of Use at any time by posting a revised version on the Website. However, an individual notice will not be sent to you. Any amended Terms of Use shall replace all previous versions of the same. If you do not agree on the amendments or changes to the Terms of Use, you should immediately cease the use of any of the services on the Website. After any of such amendment becomes effective, we will deem your continued use of the Website to constitute your acceptance and agreement to such amendment. You can review the most current version of the Terms of Use at any time.</div>
            </div>
            <div style="display: flex; gap: 0.3rem;">
              <div>1.4.</div>
              <div>We will, from time to time, post on the Website, guidelines and rules relating to the use of the Website. All such guidelines or rules are hereby incorporated by reference into the Terms of Use.</div>
            </div>
          </div>
        `
    },
    {
      title: 'Use of the Website',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>You agree to comply with all terms and condition under this Terms of Use in using the Website. We may remove or delete any content that you have provided on the Website if we, in our absolute discretion, suspect that you have violated the conditions herein.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  In carrying out any transactions with us on or in relation to the Website or registering an account with us on the Website, you warrant that:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>You are legally allowed to conduct the transaction on your own;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>You are using your actual identity;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>The personal data that you have provided to us are true, accurate, complete, and current; and</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>You will maintain and promptly update your personal particulars and ensure that such information is kept true, accurate, complete, and current.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>Underage (users below the age regulated under the law of your respective country) are not eligible to use the Website or provide any personal data on the Website unsupervised. If you are Underage, you may use the Website and/or provide any personal data on the Website only after you have obtained consent from your parents/legal guardian or if you are under the supervision of your parent/legal guardian.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>If we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you acknowledge that you are fully responsible for maintaining the confidentiality of your password, and all activities occurring under your account. We will have no responsibility to verify whether the user logging into and using the Website is you or not.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>You agree to notify us of any breach of security of your password.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>We will not be liable for any losses or damages arising from your failure to comply with this Clause 2.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.7.</div>
                <div>You may not frame or utilize framing techniques to enclose any of our and our affiliates’ (including the parent company, "Affiliates") trademark, logo, or other proprietary information (including images, text, page layout, form, descriptions, concepts, ideas, designs, drawings, images, photos, or any other contents) without express written consent from us. Any unauthorized use shall terminate the permission or consent granted by us.</div>
              </div>
            
          </div>
        `
    },
    {
      title: 'Website Material',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>3.1.</div>
                <div>By accessing, using the Website, you may be exposed to information, data, text, software, photographs, graphics, videos, messages, concepts, ideas, designs, drawings, product listings, prices, or other materials that we and/or other users of the Website may upload, post, email, transmit, or otherwise make available on the Website ("Website Material") whether publicly or privately.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2.</div>
                <div>You understand that the Website Material may be offensive, indecent, or objectionable.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3.</div>
                <div>You understand that the Website Material may only be used for your own and non-commercial use, and not for publication, distribution, transmission, retransmission, redistribution, broadcast, reproduction, or circulation to someone else in the same company or organization, and not for posting to other websites or forums, newsgroups, mailing lists, electronic bulletin boards, or internet relay chats operated by other websites.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.4.</div>
                <div>You agree not to reproduce, duplicate, copy, sell, resell, display, or otherwise provide Website Material on another website or server without having our prior written consent.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.5.</div>
                <div>We are, under no circumstances, liable for any errors or omissions in any Website Material, or for any loss or damage of any kind incurred as a result of the use of any Website Material posted, emailed, transmitted, or otherwise made available on the Website. </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.6.</div>
                <div>You acknowledge and agree that the format and content of the Website may be changed at any time without notice; the Website’s operation may be suspended or discontinued for support or maintenance work, to update its content or for any other reason; and the access to the Website may be terminated either in relation to specific users or generally at any time and without notice.</div>
              </div>
            
          </div>
        `
    },
    {
      title: 'Accuracy of Information on our Website',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>4.1.</div>
                <div>Whilst ${textBrand} uses reasonable efforts to include accurate information on the Website, we make no warranties or representations as to the accuracy of any information given, whether implied or otherwise, to the extent permitted by law. ${textBrand} excludes all liability for any errors or omissions in the content of the Website to the fullest extent permitted by law.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.2.</div>
                <div>The particulars contained on the Website are for guidance purposes only. The accuracy of any particulars, descriptions, dimensions, references to condition, necessary permissions for use and occupation and other details contained on the Website is not guaranteed and is for general guidance only and you are advised not to rely on them as statements of fact or representations and to satisfy yourselves as to your own accuracy.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.3.</div>
                <div>${textBrand} advises that since purchasing real property is a major financial commitment, you should take all reasonable steps to ensure that any property you are proposing to purchase is suitable for your requirements in all respects. We have taken reasonable steps to ensure that materials contained within the Website are accurate, but it should not be taken as a substitute for your enquiries, considerations. You are advised to take appropriate steps to verify any information upon which you wish to rely.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>4.4.</div>
                <div>Despite every effort to provide accurate reflection of each product’s color and design on the Website, actual colors and design may vary slightly, due to different device screen settings, the lighting in the installation location, slight differences in product finishes over time and other factors. ${textBrand} shall not accept responsibility for any color or design differences that are not our faults. When you purchase the property, product using the Website, you agree to accept the small risk(s) that there will be slight variation(s) between the actual colors and design, and the representation on the Website.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Obligations of the User',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>5.1.</div>
                <div>
                  In addition to the obligations specified in this Terms of Use, you must NOT:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Use the Website in breach of any applicable laws or regulations;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Act in the manner that is to, or use the Website to, harm, abuse, harass, stalk, defame, libel, threaten, or otherwise offend others;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Conduct any actions which cause, or may cause inconvenience, disadvantage, or damage to the user(s), third party(ies), or ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Conduct any acts of fraudulently applying for or holding multiple membership registrations;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Engage in any immoral activity or potentially immoral activity, including but not limited to prostitution and pornography;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Provide false, inaccurate, or misleading information;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>h.</div>
                      <div>Engage in potentially fraudulent, suspicious, or illegal activity and/or transactions;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>i.</div>
                      <div>Send unsolicited emails to any user or use the services on the Website to collect payments for sending, or assisting in sending unsolicited emails to third parties;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>j.</div>
                      <div>Disclose or distribute another user’s personal information to a third party, or use such information for marketing purposes without the express consent of that user;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>k.</div>
                      <div>Take any action that imposes an unreasonable or disproportionately large load on the Website's infrastructure;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>l.</div>
                      <div>Upload, or cause to be uploaded, any content to the Website that contains viruses, or any other items that may damage, interfere with, or adversely affect or hinder access to the Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>m.</div>
                      <div>Engage in, nor cause other users to engage in, spamming, phishing, improper, malicious or, in our absolute discretion, fraudulent clicking, impressions or marketing activities relating to ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>n.</div>
                      <div>In our absolute discretion, reflect poorly on or tarnish the reputation of ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>o.</div>
                      <div>Modify, adapt, reformat, recompile, transmit, publish, license, reverse engineer, disassemble, reconstruct, decompile, copy or create derivative works of, transfer or sell any services on the Website or part thereof, including source codes or algorithms, except as expressly authorized by us in writing, or to the extent permitted by law;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>p.</div>
                      <div>Alter, remove, cover or otherwise deface any identification, trademark, copyright or other notice from any aspect of the services on the Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>q.</div>
                      <div>Use any automation software, hacks, mods, or any other unauthorized third-party software designed to access, crawl, or collect from ${textBrand}'s services;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>r.</div>
                      <div>Use any unauthorized third-party software that intercepts, "mines" or otherwise collects information from or through ${textBrand};</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>s.</div>
                      <div>
                        Upload, transmit, post, or otherwise make available any content that is, or could reasonably be expected:
                        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                          <div style="display: flex; gap: 0.3rem;">
                            <div>I.</div>
                            <div>To be offensive, discriminatory, defamatory, obscene, threatening, abusive, indecent, or otherwise unlawful, including content that is likely to offend, insult or humiliate others based on race, religion, ethnicity, gender, age, sexual orientation or any physical or mental disability;</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>II.</div>
                            <div>Not to be your original work, or which may infringe the intellectual property or other rights of another person;</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>III.</div>
                            <div>To include an image or personal information of another person unless you have their consent; or</div>
                          </div>
                          <div style="display: flex; gap: 0.3rem;">
                            <div>IV.</div>
                            <div>To contain large amounts of untargeted, unwanted, or repetitive content; or</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>t.</div>
                      <div>Interfere with, disrupt, or create an undue burden on the Website or our business.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>If you believe that a user has breached any of the above conditions, please contact us.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.2.</div>
                <div>We reserve the right to block, suspend, or cancel any user, membership registration of our Website and to modify or remove any material uploaded, posted, transmitted, or otherwise made available on the Website by any user, without notice; solely define and limit, refuse, and/or reject returns from customers at any time due to an irregular or excessive returns history. By uploading, transmitting, posting, or otherwise making available any material via the Website, you grant ${textBrand} a non-exclusive, worldwide, royalty-free, perpetual license to use, reproduce, edit, and exploit the material in any form and for any purpose. The membership application may not be approved if the applicant has had his/her membership canceled in the past.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.3.</div>
                <div>We are not responsible for, and accept no liability with respect to, any material uploaded, posted, transmitted, or otherwise made available on the Website by any person other than us. We do not endorse any opinion, advice or statement made by any person other than ${textBrand}.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>5.4.</div>
                <div>You agree to indemnify ${textBrand} and each of the officers, employees, agents, contractors, suppliers, and licensors (collectively "Affiliates") of ${textBrand} in respect of any liability, losses or damages (including all legal and other costs on a full indemnity basis) suffered or incurred by them arising (in whole or part) out of the breach of or failure to comply with any of these Terms of Use, or any other default or wrongful conduct in relation to the subject matter of these Terms of Use, on the part of you or any of your affiliates.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Disclaimer of Warranties',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>6.1.</div>
                <div>Your use of the Website is at your sole risk. We disclaim all warranties of any kind, whether express or implied.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.2.</div>
                <div>We reserve the right at any time and from time to time to modify and discontinue, temporarily or permanently, any services of the Website (or any part thereof) with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension, or discontinuance of such services.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.3.</div>
                <div>
                    We make no warranty that:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                        <div>a.</div>
                        <div>The Website will meet your requirements;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>b.</div>
                        <div>The Website will be uninterrupted, timely, secure and error-free;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Any results that may be obtained from the use of the Website will be accurate or reliable; and</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>The quality of any products, services, information, or other material purchased or obtained by you through the Website will meet your expectations.</div>
                      </div>
                    </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.4.</div>
                <div>We cannot and do not endorse, and cannot be responsible for, the messages, views, opinions, researches, and recommendations of individuals, users, and organizations of deemed interest, or advocate any patronage of any particular service provider, course of treatment or commercial dealings in any securities, investments, or classes of securities or investments nor does the inclusion on the Website of a link to other website(s) or resources imply any form of endorsement by us.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.5.</div>
                <div>We do not actively review or edit the messages, views, opinions, researches, and recommendations of users, even if such messages, views, opinions, researches, and recommendations are made by the users using the Website. While the information on the Website has been obtained from sources believed to be reliable, none of the data, news, information, reports, opinions, nor any of the recommendations (if any) has been customized for any specific person or class of persons. </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.6.</div>
                <div>By making available information and data on the Website, including facts, views, opinions and recommendations of individuals and organizations of deemed interest, we and our content providers are not giving or purporting to give or representing or holding themselves out as giving financial, investment, tax, and legal advice. You shall always seek the relevant professional advice before otherwise making any such decisions.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.7.</div>
                <div>We may monitor or review any areas on the Website where you transmit or post communications for the purpose of promoting its efficiency, usefulness, and appropriateness of use. However, we do not undertake to monitor or review every posting or communication, and we disclaim any and all liability relating to all Website Material, whether or not arising under the laws of copyright, trademarks, libel, privacy, obscenity, or otherwise.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.8.</div>
                <div>
                  This Website and all information contained therein are provided by ${textBrand} on an "as is" and "as available" basis. ${textBrand} and/or its respective affiliates, shareholders, partners, officers, directors, employees, and agents:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Make no representations or warranties of any kind, express or implied, as to the operation and availability of this Website or the information, contents, materials, or products presented on the Website. You expressly agree that your use of this Website is at your sole risk to the full extent permissible by applicable law; </div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Do not warrant that the Website, its servers, or email sent from us are free of viruses or other harmful components or errors; and</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Will not be liable for any damages of any kind including, but not limited to direct, indirect, incidental, punitive, and consequential damages or any other losses or liabilities arising out of (a) the use or inability to use the Website; (b) any decision made, action or inaction taken by any party in reliance upon the contents of the Website; (c) business interruption; (d) delay/interruption in access to the Website; (e) data non-delivery, mis-delivery, corruption, destruction or other modification; (f) loss or damages of any sort incurred as a result of accessing the third party links on the Website; (g) computer viruses, system failures or malfunctions in connection with use of the Website; or (h) any delays, inaccuracies, errors in or omissions in the content of the Website.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>6.9.</div>
                <div>By accessing our Website, you assume all risks associated with its use, including but not limited to the risk that your computer, devices, software or data may be damaged by any virus transmitted by this Website or by any third party content or third party website, as well as the risks relating to the service quality provided by the service provider (if any). To the extent permitted by law, all warranties, conditions, and claims (whether express or implied) arising out of or in any way connected with this Website are hereby excluded.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Indemnities',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>You agree to indemnify and hold ${textBrand}, and our affiliates, shareholders, partners, officers, directors, employees, and agents harmless from any claim or demand, including attorneys' fees, made by any third party due to or arising out of any content that you submit, post, transmit or make available on the Website, your use of the Website, your connection to the Website, your violation of the Terms of Use, or your violation of any rights of another party.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Limitation of Liability',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>8.1</div>
                <div>
                  We shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if we have been advised of the possibility of such damages), resulting from:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>The use or the inability to use the Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>The cost of procurement of substitute products and services resulting from any products, data, information, or services purchased or obtained or messages received, or transactions entered into through or from the Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Unauthorized access to or alteration of your transmissions or data;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Statements or conduct of any third party on the Website; and;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Any other matter relating to the Website.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>8.2</div>
                <div>Any claim of any nature whatsoever by either party against the other party and any action against the other party must be commenced within 03 months (or the allowable minimum period as provided under the respectively applicable law) after the date on which the cause of action arises, following which the aggrieved party shall have no further claim whatsoever against the other party.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Linking and Third-party Contents',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>9.1</div>
                <div>The Website may be hypertext linked to websites operated by third parties for reference and information purposes only. ${textBrand} has not reviewed and cannot be responsible for the privacy policies and practices of other websites even if you access them using links from the Website and recommends that you check the terms and conditions and privacy policy of each website you visit and contact its owner or operator if you have any concerns or questions. Likewise, if you linked to the Website from a third-party website, ${textBrand} cannot be responsible for the privacy policies and practices of the owners or operators of that third-party website and recommend that you check the policy of that third party website and contact its owner or operator if you have any concerns or questions. ${textBrand} makes no warranties or representations in respect of any linked third-party websites. ${textBrand} excludes all liability for loss or damage to the fullest extent permitted by law arising in respect of any content on such websites.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>9.2</div>
                <div>For the avoidance of doubt, the presence of a link to a third-party website will not be deemed to be a recommendation of such websites or any advice or information on them by ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Privacy Policy',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>10.1.</div>
                <div>All personal data about you is subject to our Privacy Policy.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.2.</div>
                <div>The Privacy Policy is deemed incorporated into the Terms of Use by reference to this Clause.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.3.</div>
                <div>If any user of the Website, or any other person, that views a ${textBrand} published document information, and responds with any information or feedback such as questions, comments, suggestions, or the like, such information will be deemed to be non-confidential and ${textBrand} is at liberty to use, reproduce, disclose, and distribute that information to any other person.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>10.4.</div>
                <div>Further, ${textBrand} is at liberty to use or exploit any ideas, concepts, know-how or techniques contained in such information/feedback for any purpose whatsoever without charge including, but not limited to, developing, manufacturing, and marketing products incorporating such information.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Intellectual Property and Copyright',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>You acknowledge and accept that all the copyright, database rights and any other intellectual property rights which subsist in the design, layout, processes, functions, data, and content of the Website are the property of ${textBrand}, our information providers or licensors. The Website is owned and operated by ${textBrand}. No part of the Website, its content or any of its underlying software and databases may be copied or reproduced, shared with third parties, reverse engineered, reverse assembled, or used to create any derivative work (whether in physical or electronic form) without the prior written consent of ${textBrand}.</div>
              </div>
          </div>
        `
    },
    {
      title: 'Force Majeure',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                We shall have no liability to you for any delay or failure in carrying out our obligations to you for reasons beyond our control, including without limitation, acts of God, war or terrorism, epidemic, pandemic, natural disasters, internet connection issues, system issues changes in applicable laws, regulations or governmental policies, and shortages of supplies and services.
              </div>
          </div>
        `
    },
    {
      title: 'Termination',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>13.1.</div>
                <div>
                  We may, under certain circumstances and without prior notice, at our sole discretion immediately terminate your account (if any). Cause for such termination shall include without limitation:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Breaches or violations of the Terms of Use, or other incorporated documents, guidelines or rules;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Request by law enforcement or other government agencies;</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>c. </div>
                      <div>Self-initiated account deletions;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d. </div>
                      <div>Infringement of intellectual property rights of others;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e. </div>
                      <div>Discontinuance or material modification to the services on the Website, or part thereof;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f. </div>
                      <div>Unexpected technical or security issues or problems; or</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g. </div>
                      <div>Extended periods of inactivity.</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.2.</div>
                <div>
                  Termination of your account shall result in:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a. </div>
                      <div>Removal of access to and barring of further use to all offerings of the Website that are associated with such account; and</div>
                    </div>
                     <div style="display: flex; gap: 0.3rem;">
                      <div>b. </div>
                      <div>Deletion of your password and all related information, files and content associated with or inside your account (or part thereof).</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>13.3.</div>
                <div>Termination of your account shall be without prejudice to any other rights or remedies we may be entitled to under the Terms of Use, at law or in equity and shall not affect any accrued rights or liabilities nor the coming into or continuance in force of any provision which is expressly or by implication intended to come into or continue in force on or after such termination.</div>
              </div
          </div>
        `
    },
    {
      title: 'Intellectual Property Rights',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>Upon your upload of any images, photographs, and/or listing descriptions onto the Website (the "<strong>Content</strong>"), you shall give us a worldwide, royalty-free, irrevocable licence to use, store, host, reproduce, modify, adapt, watermark, communicate, publish, publicly perform, publicly display, create derivative works, and distribute the Content. By uploading the Content, you warrant that you own the copyright of the Content and thus have the requisite rights to licence it to us as stated. We shall have the right to watermark the photographs within the Content and the copyright in the final watermarked photographs shall belong solely to us. All other names, products and marks mentioned are the intellectual property rights of their respective owners. No materials provided through the Website, including text, graphics, code, ratings, rankings, databases, aggregated information, Website Material and/or software may be reproduced, modified, adapted, distributed, published, displayed, uploaded, posted, transmitted, or hyperlinked in any manner and in any form without our express, prior written approval, and the approval of the respective copyright and trademark holders. The Website and Website Material are for your personal use only, and you may not engage in any dealings with other parties regarding such services and contents. Such dealings include commercial dealings and dealings which will adversely affect the commercial value of the Website and Website Material.</div>
              </div>
          </div>
        `
    },
    {
      title: 'General',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>15.1.</div>
                <div>The Terms of Use shall be governed by and construed in accordance with the laws of the Republic of Singapore, and any claims or disputes of whatever nature shall be subject to the exclusive jurisdiction of the courts of the Republic of Singapore.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.2.</div>
                <div>Our failure to exercise or enforce any right or provision of the Terms of Use shall not constitute a waiver of such right or provision. If any provision of the Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms of Use remain in full force and effect.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.3.</div>
                <div>Any account that you may have with us is not transferable and any rights to such account will terminate upon your death. Upon receipt of a copy of your death certificate, your account will be terminated and all content that you have generated in your account will be permanently deleted.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>15.4.</div>
                <div>All notifications to us pursuant to the Terms of Use shall be sent via email to: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
          </div>
        `
    },
  ];
};
