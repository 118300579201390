import React from 'react';
import styled from 'styled-components';

// COMPONENTS
import { Controller, UseControllerProps } from 'react-hook-form';
import UncontrolledTextField, {
  TextFieldProps as UncontrolledTextFieldProps,
  InputProps,
} from 'components/inputs/TextField';

export type TextFieldProps = UncontrolledTextFieldProps & {
  name: string;
  control?: UseControllerProps['control'];
  defaultValue?: string | number | boolean;
  messageErr?: string;
  height?: string;
  paddingRight?: string;
  background?: string;
} & InputProps;

export default function Input({
  control,
  required,
  defaultValue,
  name,
  height,
  ...rest
}: TextFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value = '' }, fieldState: { error } }) => {
        const val = value ? value : '';
        return (
          <UncontrolledTextFieldStyled
            value={val}
            onChange={onChange}
            error={!!error}
            height={height}
            helperText={!!error ? error.message : null}
            {...rest}
          />
        );
      }}
      rules={{ required }}
    />
  );
}

const UncontrolledTextFieldStyled = styled<any>(UncontrolledTextField)`
  .MuiOutlinedInput-root {
    fieldset {
      top: 0;
      border: 1px solid ${p => p.theme.palette.primary.contrastText} !important;
      legend {
        display: none;
      }
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${p => p.theme.palette.text.light} !important;
    }
  }

  .MuiOutlinedInput-input {
    height: ${props => (props.height ? props.height : '')};
    &::placeholder {
      font-size: 13px;
    }
  }
`;
