import { combineReducers } from '@reduxjs/toolkit';

import auth from 'store/auth/reducers';
import userData from './userData/userDataReducers';
import global from './global/globalReducers';
import userTaste from './userTaste/userTasteReducers';
import designHub from './designHub/designHubReducers';
import designHome from './designHome/designHomeReducers';
import project from 'store/project/projectReducers';

const rootReducer = combineReducers({
  auth,
  global,
  project,
  userData,
  userTaste,
  designHub,
  designHome,
});

export default rootReducer;
