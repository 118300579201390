export const DATA_FAQ_TH = (textBrand: string) => {
  return [
    {
      title:
        `${textBrand} International คือใครและพวกเขานำเสนออะไรในโลกนี้?`,
      description: `
            <p>โอเคค่ะ มาพูดคุยกันเกี่ยวกับตลาดอสังหาริมทรัพย์หลักและประสบการณ์การขายที่น่าเบื่อและล้าสมัยที่มาพร้อมกับมัน ${textBrand} International มาทำให้เกมเปลี่ยนไป พวกเรามาทำให้สิ่งที่กำลังเกิดขึ้นเขย่าตัวคุณ ค่ะ</p>
            <p>เรากำลังพัฒนาบ้านของอนาคต จะทำอย่างไร? ด้วยการใช้เทคโนโลยีการบุคคลส่วนตัว ใช่ คุณได้ยินถูกต้องค่ะ ${textBrand} นำเสนอเครื่องมือออกแบบภายใน 3 มิติที่น่าตื่นตามากที่ใช้แอร์ไลน์ ใช่คุณได้ยินถูกต้องค่ะ กับตัวช่วยเหลือมากมายนี้คุณสามารถเดินทางในพื้นที่ในเวลาจริง ห้องต่อห้อง และวางแผนสร้างบ้านอนาคตของคุณล่วงหน้าได้ค่ะ</p>
            <p>กับ ${textBrand} คุณจะเริ่มต้นเส้นทางที่เรียบร้อย มีความสุขและโปร่งใส ไม่มีความเคลือนไหว มันเป็นการเดินทางที่โอนเอียง ทันสมัยเพื่อบ้านในฝันของคุณค่ะ</p>
           `,
    },
    {
      title:
        'เซ็กเมนต์ คืออะไรและมันทำงานอย่างไรเมื่อเกี่ยวข้องกับการซื้อบ้านใหม่ของฉัน?',
      description: `
            <p>โอเคค่ะ มาพูดคุยกันเกี่ยวกับ เซ็กเมนต์ และวิธีการทำงานของมันในการเดินทางในการซื้อบ้านของ ${textBrand} ค่ะ</p>
            <p>เซ็กเมนต์ เหมือนกับเสาของประพทั่วเลยที่สามารถรวมกันเพื่อสร้างพื้นที่ที่ตอบสนองความต้องการและงบประมาณของคุณค่ะ</p>
            <p>คิดให้เป็นขนานของพื้นที่ในการใช้ชีวิตที่คุณสามารถเข้าถึงได้แยกตัวหรือหลายชิ้น ทำให้คุณสามารถปรับแต่งบ้านใหม่ของคุณให้ตรงกับความต้องการและความปรารถนาของคุณค่ะ</p>
           `,
    },
    {
      title:
        'ทำไมความตกลงการจองถูกสำคัญมากในกระบวนการซื้อขาย?',
      description: `
            <p>โอเคค่ะ มาพูดคุยกันเกี่ยวกับความตกลงการจองนี้ คุณอาจคิดว่า "มันคืออะไร และทำไมฉันถึงสนใจ?" ซึ่งโอเคค่ะ ให้ขอสรุปให้คุณเข้าใจมันด้วยค่ะ มันเป็นอะไรสักอย่างที่สำคัญในการซื้อบ้าน ทำไม? เพราะมันเหมือนกับกำแพงกันน้ำเมืองค่ะ</p>
            <p>เมื่อคุณลงนามความตกลงการจอง คุณพูดคือ "รอสักครู่ ฉันมีสายตาอยู่ที่บ้านนี้ และฉันต้องการให้แน่ใจว่ามันคือของของฉัน"</p>
            <p>ทำไมมันสำคัญขนาดนั้น? มันให้คุณรู้สึกอย่างเอิกเกริกและปกป้องสิทธิ์ของคุณ มันรับประกันว่าที่อยู่ไม่จะถูกขายให้คนอื่นขณะที่คุณยังคงต้องมีสติในการกำหนดข้อความและเครื่องหมายเสียง</p>
           `,
    },
    {
      title:
        'สิ่งที่รวมอยู่ใน Perjanjian Penjualanและการซื้อขายและสิ่งที่มันเกี่ยวข้องกับการซื้อบ้าน?',
      description: `
            <p>Perjanjian Penjualanและการซื้อขายเปรียบเสมือนกับกราฟมหาวิหารของการซื้อบ้าน มันเป็นเอกสารที่ทำให้สิ่งที่เกิดขึ้นและป้องกันความเสี่ยงของคุณค่ะ</p>
            <p>ให้ขออธิบายให้คุณเข้าใจ: เมื่อคุณซื้อบ้าน คุณไม่ได้แค่ส่งเงินและหวังสิ่งที่ดีที่สุด นั่นเป็นที่ที่มี Perjanjian Penjualanและการซื้อขาย มันเป็นสัญญาที่มีผูกพันระหว่างคุณและผู้ขายซึ่งระบุทุกสิ่งที่สำคัญ พวกเรากำลังพูดถึงราคาของบ้าน ข้อกำหนดในการส่งมอบ และเวลาในการปิดการซื้อขายค่ะ</p>
            <p>มันไม่ใช่การตกลงการจับมืออย่างปล่อยมือ มันเป็นการมีความผูกพันอย่างจริงจังที่รับรองให้ทั้งสองฝ่ายต้องรับผิดชอบ ดังนั้นเมื่อคุณพร้อมที่จะทำการซื้อขายใหญ่ ๆ อย่าลืมต้องมี Perjanjian Penjualanและการซื้อขายที่มั่นคง มันเป็นระบบการปกป้องของคุณในโลกของการซื้อบ้านค่ะ</p>
           `,

    },
    {
      title:
        `อย่างไรคุณสามารถเปลี่ยนความฝันในการซื้อบ้านของคุณให้กลายเป็นความเป็นจริงผ่านพลังลึกลับของ ${textBrand} International?`,
      description: `
            <p>ดังนั้นคุณอยากรู้ว่าคุณสามารถทำให้บ้านฝันของคุณกลายเป็นความเป็นจริงผ่าน ${textBrand} International อย่างไร? แล้วก็พร้อมเสร็จใจของคุณเพราะกำลังจะนำมาให้คุณเข้าใจ</p>
            <p><strong>ขั้นตอนที่หนึ่ง:</strong> เลือกประเทศที่คุณต้องการ ที่คุณจะมีชีวิตที่ดีที่สุดค่ะ เลือกและมาเริ่มต้นกันเถอะค่ะ</p>
            <p><strong>ขั้นตอนที่สอง:</strong> เรามีโครงการหลากหลายที่รอให้คุณสำรวจ หลงไหลในรายละเอียด สร้างภาพในส่วนของคุณเอง และสร้างภาพในชีวิตที่คุณจะได้มีค่ะ</p>
            <p><strong>ขั้นตอนที่สาม:</strong> กรองขอบเขตให้มากขึ้นค่ะ เลือกจำนวน เซ็กเมนต์ ที่คุณสามารถเป็นเจ้าของได้ และชั้นพื้นที่ที่ส่งกลิ่นในโครงสร้างใจคุณ</p>
            <p><strong>ขั้นตอนที่สี่:</strong> นี่คือส่วนที่น่าตื่นเต้น พร้อมเตรียมพร้อมสำหรับส่วนที่น่าตื่นเต้น คุณสามารถตั้งแต่งอพาร์ทเม้นท์ของคุณในมิติสุดยอดของการเรียกใช้ 3 มิติ ใช่คุณได้ยินถูกต้องค่ะ เรากำลังพูดถึงการนำบ้านฝันของคุณกลับมาพร้อมกับสายตา ปรับเปลี่ยนและตั้งค่าให้เหมาะสมกับคุณเอง อย่าลืมว่าคุณยังคงมีภาพในค่าใช้จ่ายอย่างละเอียดและโปร่งใสค่ะ ไม่มีการดำเนินการที่ไม่ซื่อตรงที่นี่เลย</p>
            <p><strong>ขั้นตอนที่ห้า:</strong> เมื่อคุณพอใจกับงานชิ้นเอกของคุณ มันก็เป็นเวลาของการทำการซื้อขาย ลงนามในข้อตกลงการจองและการซื้อขายออนไลน์ และทำเสร็จแล้วค่ะ</p>
            <p>ตอนนี้ค่ะ นั่งหมั่นๆ สิ่งที่คุณต้องการก็กำลังมาถึงเร็ว ๆ นี้ค่ะ  ${textBrand} กำลังอยู่ที่นี่เพื่อทำให้เป็นจริงค่ะ</p> 
          `,
    },

    {

      title:
        'อย่างไรคุณสามารถเข้าร่วมรายการรอคอยและอยู่ในกระแสของคุณที่กำลังจะเปิดให้ใช้บริการเร็วๆนี้?',
      description: `
            <p>ดังนั้นคุณอยากรู้ว่าคุณสามารถทำยังไงเพื่อที่จะอยู่ในรายการรอคอยสำหรับ "ความเป็นจริงเร็ว ๆ นี้" หรือไม่? ฉันไม่เห็นผิดที่เลยค่ะ โอเคค่ะ สิ่งที่ต้องมีเพื่อที่จะอยู่ในกระแสและได้รับข้อมูลล่วงหน้าคือตามนี้ค่ะ</p>
            <p>ลองไปที่เว็บไซต์ของเราและค้นหาส่วนที่ว่า "อยู่ที่รู้" มันเหมือนกับคลังของคนรักบ้านเช่นคุณ ค้นหาตัวเลือกที่จะเข้าร่วมรายการรอคอย ใส่ที่อยู่อีเมลของคุณแล้วคุณก็เป็นส่วนหนึ่งของกระแสค่ะ</p>
            <p>เราจะรับรองว่าคุณจะเป็นคนแรกที่ทราบเมื่อโครงการที่น่าตื่นตานพร้อมที่จะทำงาน มันเหมือนกับการมีบัตรเข้าชมในโลกของอสังหาริมทรัพย์ ดังนั้นอย่าเสียเวลาอีกเสียเวลาค่ะ มาเข้าร่วมรายการรอคอยและให้ความรู้สึกให้กับความตื่นเต้นที่กำลังจะมาถึงเร็ว ๆ นี้ค่ะ</p>
          `,
    },

    {
      title:
        `${textBrand} International ให้ความสำคัญในการปกป้องข้อมูลมูลค่าของคุณอย่างไร และมีพันธมิตรใดที่มีอยู่เพื่อให้การซื้อขายปลอดภัย?`,
      description: `
            <p>ที่ ${textBrand} International เราไม่ได้มีเรื่องเล่นเกี่ยวกับการปกป้องข้อมูลของคุณ ข้อมูลของคุณคือทองคำสำหรับเราและเราปกป้องมันด้วยความมีความพยุงและแข็งแรงที่สุดค่ะ เราไม่ได้เล่นเรื่องดีที่สุด จึงได้มีความร่วมมือกับคนที่ดีที่สุดเพื่อให้ข้อมูลของคุณปลอดภัยและไม่ได้ถูกสะดุด</p>
            <p>เรามี Tazapay บริษัทในสิงคโปร์ที่เชี่ยวชาญด้านรายละเอียดของการชำระเงินของคุณค่ะ แต่มันไม่จบตามนั้นค่ะ เราได้ยกระดับการดำเนินงานของเราด้วยเทคโนโลยีอย่างก้าวหน้าของ Pandadoc เพื่อให้ลูกค้าสามารถลงนามเอกสารในรูปแบบดิจิตอลค่ะ</p>
            <p>เราไม่มีการเว้นช่องว่างใด ๆ ที่น่าสนใจค่ะ ข้อมูลของคุณคือลำดับแรกในความสำคัญของเรา และเรามีพันธมิตรที่เหมาะสมเพื่อให้เป็นไปตามความต้องการความปลอดภัยค่ะ</p>
          `,
    },
  ];
};
