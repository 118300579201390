import BaseModel from './baseModel';
import { Obj } from 'constants/types';
import { RankCharacter, RankCategory, HOSPITALITY_PERSONALITY_MAP } from 'constants/yourTaste';
import PersonalityQuestion, { AnswerOption } from 'model/personalityQuestion';
import Personality from './personality';
import env from 'constants/env';

// {
//   "name": "Texture",
//   "rankName": "Raw",
//   "characters": "R",
//   "description": "Your preference for materials and processing.",
//   "score": 300,
//   "sum": 8,
//   "average": 37.5,
//   "categoryId": "5d7e0a14a8bda16187664e69"
// }
export type PersonalityRank = {
  name: RankCategory;
  rankName?: string;
  characters?: RankCharacter;
  description: string;
  score: number;
  sum: number;
  average: number; // score / sum
  categoryId: string;
}

export type PersonalityAnswer = {
  question: PersonalityQuestion;
  optionSelect: AnswerOption;
}

class PersonalityResult extends BaseModel<PersonalityResult> {
  currentQuestionIndex?: number;
  
  orderQuestionIds?: string[];
  
  answers: PersonalityAnswer[] = [];
  
  personalityId?: number;
  
  personalityCode?: string;
  
  ranks: PersonalityRank[] = [];
  
  output?: {
    id: Personality['id'];
    code: Personality['code'];
  }
  
  get hospitalityPersonality() {
    const scoredAnswer = (this.answers || []).find(
      answer => answer.question.id === env.HOSPITALITY_SCORE_QUESTION_ID
    );
    
    if (!scoredAnswer || !HOSPITALITY_PERSONALITY_MAP[scoredAnswer.optionSelect.id]) return undefined;
    
    return HOSPITALITY_PERSONALITY_MAP[scoredAnswer.optionSelect.id];
  }
  
  fromPayload({
    currentQuestionIndex,
    orderQuestionIds,
    answers,
    personalityId,
    personalityCode,
    result,
  }: Obj): this {
    
    if (currentQuestionIndex !== undefined) this.currentQuestionIndex = currentQuestionIndex;
    if (orderQuestionIds !== undefined) this.orderQuestionIds = orderQuestionIds;
    if (answers !== undefined)
      this.answers = (typeof answers === 'string' ? JSON.parse(answers) : answers) || [];
    if (personalityId !== undefined) this.personalityId = personalityId;
    if (personalityCode !== undefined) this.personalityCode = personalityCode;
    if (result !== undefined) this.ranks = typeof result === 'string'
      ? JSON.parse(result)
      : result;

    return this;
  }
  
  toPayload(): Obj {
    return {
      currentQuestionIndex: this.currentQuestionIndex,
      orderQuestionIds: this.orderQuestionIds,
      personalityId: this.personalityId,
      personalityCode: this.personalityCode,
      answers: this.answers,
      result: this.ranks,
      output: this.output,
    };
  }
}

export default PersonalityResult;
