import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getUserInfo } from 'services/authService';
import env from 'constants/env';

type Props = {
  trackPage?: boolean
}

const gaEnabled = !!env.GOOGLE_ANALYTICS_TRACKING_ID;

let userIdSet = false;

const useGoogleAnalytics = ({ trackPage = false }: Props = {}) => {
  const location = useLocation();
  
  useEffect(() => {
    if (gaEnabled) {
      if (!userIdSet) {
        const id = getUserInfo()?.id || '';
        ReactGA.set({ userId: id });
        userIdSet = true;
      }
  
      if (trackPage) {
        ReactGA.pageview(location.pathname);
        userIdSet = true;
      }
    }
  }, []);
  
  return null;
}

export default useGoogleAnalytics;
