import { cloneDeep } from 'lodash';
import { IFilters } from 'components/Project/ProjectFilterBar';

import BaseModel from './baseModel';
import { OldPageRoute } from 'constants/route';
import { TCurrencyObject } from 'constants/currency';
import { ProductItemParams } from 'constants/designConstants';

class MyHome extends BaseModel<MyHome> {
  // PROJECT
  locationId = 0;
  locationName = '';
  projectId = 0;
  projectName = '';
  // BLOCK
  blockId = 0;
  blockName = '';
  // SEGMENT
  planId = 0;
  segmentId = 0;
  segmentName = '';
  // LAYOUT
  layoutId = 0;
  layoutName = '';
  // FLOOR
  floorId = 0;
  floorName = '';
  floorPlanId = 0;

  // ORDER
  orderId = 0;
  // FILTERS
  savedFilters?: IFilters | null = null;
  maxNoOfBedInSelectedProject?: number;
  // PRICES
  vatPercent = 0;
  projectCurrency: TCurrencyObject | undefined = undefined;
  route = OldPageRoute.ProjectPlace;
  productItems: ProductItemParams[] | undefined = undefined;
  static _attrNames: (keyof MyHome)[] = [
    'locationId',
    'locationName',
    'projectId',
    'projectName',
    'blockId',
    'blockName',
    'planId',
    'segmentId',
    'layoutId',
    'layoutName',
    'floorPlanId',
    'floorId',
    'floorName',
    'orderId',
    'savedFilters',
    'maxNoOfBedInSelectedProject',
    'vatPercent',
    'projectCurrency',
    'route',
    'productItems',
    'segmentName',
  ];

  _getAttrNames(): (keyof MyHome)[] {
    return MyHome._attrNames;
  }

  constructor(attrs: Partial<MyHome> = {}) {
    super();

    const newAttrValues: { [name in keyof MyHome]?: any } = {};
    MyHome._attrNames.forEach((name) => {
      if (attrs[name] !== undefined) {
        newAttrValues[name] = attrs[name];
      }
    });
    Object.assign(this, newAttrValues);
    this._correctAttrs();
  }

  private _correctAttrs() {
    if (!this.projectId) {
      this.projectName = '';
      this.blockId = 0;
      this.projectCurrency = undefined;
    }

    if (!this.blockId) {
      this.blockName = '';
      this.planId = 0;
      this.segmentId = 0;
      this.floorId = 0;
    }

    if (!this.segmentId) {
      this.layoutId = 0;
    }

    if (!this.floorId) {
      this.floorName = '';
    }

    if (!this.layoutId) {
      this.layoutName = '';
      this.orderId = 0;
      this.productItems = [];
    }
  }

  assignByScenario(attrs: Partial<MyHome>) {
    if (attrs.projectId !== undefined && attrs.projectId !== this.projectId) {
      attrs.blockId = 0;
    }

    if (attrs.blockId !== undefined && attrs.blockId !== this.blockId) {
      attrs.segmentId = 0;
      attrs.floorId = 0;
      attrs.planId = 0;
    }

    if (attrs.segmentId !== undefined && attrs.segmentId !== this.segmentId) {
      attrs.layoutId = 0;
    }

    if (attrs.layoutId !== undefined && attrs.layoutId !== this.layoutId) {
      attrs.orderId = 0;
    }

    super.assign(attrs);
    this._correctAttrs();
  }

  clone = (): this => cloneDeep(this);
}

export default MyHome;
