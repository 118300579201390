import React, { useEffect, useState } from 'react';

// COMPONENTS
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog';

type DialogInputProps = {
  dialogType: DialogType;
} & Omit<ConfirmDialogProps, 'open' | 'onClose'>;

type DialogContainerProps = Omit<DialogInputProps, 'onCancel'>;

// eslint-disable-next-line
export let openDialogContainer = (props: DialogContainerProps) => {};

type DialogType = 'confirm';

const DialogContainer = () => {
  const [dialogState, setDialogState] = useState<DialogContainerProps | undefined>();

  useEffect(() => {
    openDialogContainer = (props: DialogContainerProps) => setDialogState(props);
    // eslint-disable-next-line
  }, []);

  if (!dialogState) return null;

  const { dialogType, onConfirm, ...restInputProps } = dialogState;

  if (dialogType === 'confirm') {
    return (
      <ConfirmDialog
        {...restInputProps}
        open
        onConfirm={async () => {
          await onConfirm();
          setDialogState(undefined);
        }}
        onCancel={() => setDialogState(undefined)}
      />
    );
  }

  return null;
};

export default DialogContainer;
