import { TCurrencyObject } from 'constants/currency';
import Types from './globalTypes';

export type GlobalState = {
  currencyList: TCurrencyObject[];
};

const initialState: GlobalState = {
  currencyList: [],
};

const globalReducer = (state = initialState, action: any): GlobalState => {
  const { data } = action;

  switch (action.type) {
    case Types.SetCurrencyList:
      return {
        ...state,
        currencyList: [...state.currencyList, ...data],
      };

    default:
      return state;
  }
}

export default globalReducer;