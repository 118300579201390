import {
  projectManagementRequest,
  userManagementRequest,
  userPublicRequest,
} from './requests';

// UTILS
import { parseRequestParams } from 'utils/dataMappers';

// CONSTANTS
import { Obj } from 'constants/types';
import { MetaParams } from 'constants/requestConstants';
import { ResidentialType } from 'constants/yourTaste';
import { pmsPublicRequest } from './requests';

type FetchDesignCollectionsParams = {
  type?: ResidentialType,
  layoutId?: number | string;
  blockId?: number | string;
}

export const fetchDesignCollectionsApi = (params: FetchDesignCollectionsParams = {}) =>
  projectManagementRequest.get('/design/collections', {
    params
  });

type FetchDefaultProductsParams = {
  projectId: number | string;
  layoutId: number | string;
  personalityId: number | string;
  currencyCode: string;
}

// default-product 3D
export const fetchDefaultProductsApi = (params: FetchDefaultProductsParams, meta?: MetaParams) =>
  pmsPublicRequest.get(`/design/default-product/project/${params?.projectId}/layout/${params.layoutId}/personality/${params.personalityId}`,
    parseRequestParams({
      currencyCode: params?.currencyCode
    }, meta, true)
  );

type FetchCategoriesParams = {
  roomId?: number,
}

export const fetchCategoriesApi = (
  params?: FetchCategoriesParams,
  meta?: MetaParams
): Promise<Obj> =>
  pmsPublicRequest.get('/design/categories', parseRequestParams(params, meta, true));

export type FetchProductsParams = {
  projectId: number;
  layoutId?: number;
  blockId?: number;
  roomId?: number;
  categoryId?: number;
  personalityId?: number;
  placeholderId?: number;
  productIds?: (number | string)[];
  ignoreIds?: (number | string)[];
  searchValue?: string;
  currencyCode: string;
};

// default-product 2D
export const fetchProductsApi = ({
  projectId,
  layoutId,
  personalityId,
  currencyCode,
}: FetchProductsParams, meta?: MetaParams) =>
  pmsPublicRequest.get(`/design/default-product/project/${projectId}/layout/${layoutId}/personality/${personalityId}`,
    parseRequestParams({
      currencyCode
    }, meta, true)
  );

export const fetchSavedDesignsApi = (uuid: string) => userPublicRequest.get('/design', {
  params: { uuid: uuid },
});

export const createSavedDesignApi = (payload: Obj) =>
  userPublicRequest.post('/design', payload);

export const updateSavedDesignByIdApi = (savedDesignId: number, payload: Obj) =>
  userPublicRequest.put(`/design/${savedDesignId}`, payload);

export const deleteSavedDesignByIdApi = (savedDesignId: number) =>
  userPublicRequest.delete(`/design/${savedDesignId}`);
