import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

// ICONS
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExpandLess from '@mui/icons-material/ExpandLess';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IcNavShow from 'components/icons/IcNavShow';
import BuildOutlinedIcon from 'components/sgvIcons/BuildOutlined';
import Logo from '../logos/Logo';

// COMPONENTS
import { Box, Collapse, Divider, Stack, useTheme } from '@mui/material';
import { FetchProjectsParams, fetchProjectPriceByDesignHomeApi, fetchProjectPriceByIdApi } from 'apis/projectApis';
import { IcOutlineClose, SignoutIcon, UserIcon } from 'components/icons';
import IcCalculator from 'components/icons/IcCalculator';
import IcContact from 'components/icons/IcContact';
import IcFAQHead from 'components/icons/IcFAQHead';
import IcNavHide from 'components/icons/IcNavHide';
import CustomDrawer from 'components/modals/CustomDrawer';
import QuestionDialog from 'components/modals/QuestionDialog';
import { ECurrency, formatPrefixOrSuffix } from 'constants/currency';
import { NavbarPrice } from 'constants/homeConstants';
import { PageRoute, PageRouteName, PageRouteNameOrderedValidation, PageRouteParent } from 'constants/route';
import { SessionKey, StorageKey } from 'constants/storage';
import { useLayout } from 'contexts/layoutContext';
import { useOrderContext } from 'contexts/ordersContext';
import { usePrice, useRoute } from 'hooks';
import { isEmpty } from 'lodash';
import { OrderStatus, OrderStep } from 'model/order';
import SignupLight, { TypeSignInAndSignUp } from 'pages/SignupLight/SignupLight';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSession, getStorage, hasStorage, removeStorage, setStorage } from 'services/storageService';
import { setDesignHome, setFinancialPlan } from 'store/designHome/designHomeActions';
import { selectDesignHome } from 'store/designHome/designHomeSelectors';
import { checkFlowBareShell, checkInteractMobile } from 'utils/dataHelpers';
import CurrencyDropdown from '../actionComponents/CurrencyDropdown';
import LanguageDropdown from '../actionComponents/LanguageDropdown';
import { SellType } from 'model/project';

type Props = {
  children: ReactNode;
}

const NEW_NAME_PATHS = Object.values(PageRouteName);

const MainSideBar = ({ children }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { location: { pathname } } = history;
  const {
    designHome: {
      blockId,
      segmentId,
      layoutId,
      floorId,
      planId,
      productItems,
      projectId: getProjectId,
      locationId: getLocationId,
      totalBlocks,
      orderStatus,
      collectionId,
      personalityId,
      activeSellBareshell,
      orderType,
      vatName,
    },
    auth: { tokenUMS, username }
  } = useSelector(selectDesignHome);
  const dispatch = useDispatch();
  const { params, redirect } = useRoute();
  const { locationId, projectId } = params as {
    locationId: number;
    projectId: number;
  };

  const preferCurrency = getSession(SessionKey.Currency) as ECurrency;
  const { exchangePriceWithCurrency } = usePrice();
  const { orders } = useOrderContext();
  const { isNavbarHide, isNavbarProfile, isNavbarHideActive, isMenuResponsive, updateState } = useLayout();
  const [priceMenu, setPriceMenu] = useState<NavbarPrice>();

  const [openMenuMyHome, setOpenMenuMyHome] = useState(false);
  const [openMenuMyDesign, setOpenMenuMyDesign] = useState(false);
  const [openMenuMyFinancial, setOpenMenuMyFinancial] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState<string>('');
  const [showSignupLight, setShowSignupLight] = useState(false);
  const [showPopupSignout, setShowPopupSignout] = useState(false);
  const [sellType, setSellType] = useState(getStorage(StorageKey.SellType))

  const isNavbarNotAvailable = useMemo(() => {
    return [
      PageRouteName.HomePage,
      PageRouteName.HomePageSelling,
      PageRouteName.HomePageProject,
      PageRouteName.PartnerLevitate,
      PageRouteName.ProjectListing,
      PageRouteName.PrivacyPolicy,
      PageRouteName.CookiePolicy,
      PageRouteName.FAQ,
      PageRouteName.TermsOfUse,
      PageRouteName.ContactUs,
      PageRouteName.ShareSocial,
      PageRouteName.ChooseCollection,
      PageRouteName.PromotionChoosing,
      PageRouteName.InTheSplotlight,
      PageRouteName.OurStory,
      PageRouteName.DemoInteract3D,
      PageRouteName.WaitList,
      PageRouteName.ChooseCountry,
      PageRouteName.ProjectListResell,
      PageRouteName.ChooseResellOptions,
      PageRouteName.Fusion,
      PageRouteName.FushionOwner
    ].some(item => pathname.includes(item) || pathname === '/');
  }, [pathname]);

  const isNavbarAvailable = useMemo(() => {
    return [
      PageRouteName.ChooseBlock,
      PageRouteName.ChooseSegment,
      PageRouteName.SignatureLayout,
      PageRouteName.YourDesign,
      PageRouteName.MyFinancialPackage,
      PageRouteName.MyFinancialCombine,
      PageRouteName.MyFinancialBank,
      PageRouteName.MortagePlan,
      PageRouteName.PersonalProfile,
      PageRouteName.ShareSocial,
      PageRouteName.MyOrderConfirm,
      
      PageRouteName.ResellUnit,
      PageRouteName.KeepModel,
      PageRouteName.KeepAndRemodel
    ].some(item => pathname.includes(item));
  }, [pathname]);

  const ItemListMenu = useMemo(() => {
    return [
      {
        menuTitle: t('My Home'),
        menuIcon: <HomeOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyHome],
        openMenu: openMenuMyHome,
        onClick: () => setOpenMenuMyHome(!openMenuMyHome),
        subMenu: [
          {
            menuSubTitle: t('Choose Block'),
            menuSubCategory: PageRouteName.ChooseBlock,
            onClick: () => {
              // Validate cannot click choose block if you ordered
              if (PageRouteNameOrderedValidation.some(item => pathname.includes(item)) &&
                (getStorage(StorageKey.StepOrder) === OrderStep.Ordered ||
                  orderStatus === OrderStatus.Booked ||
                  orderStatus === OrderStatus.Bought ||
                  orderStatus === OrderStatus.Cancelled ||
                  orderStatus === OrderStatus.Pending ||
                  orderStatus === OrderStatus.PreOrdered)) {
                return;
              }
              if (!Number(locationId) && !Number(projectId)) {
                redirect({ path: PageRoute.HomePage });
                setActiveNavbar(PageRouteName.ChooseBlock);
              } else {
                if (totalBlocks && totalBlocks > 1) {
                  redirect({
                    path: PageRoute.ChooseBlock,
                    params: {
                      locationId,
                      projectId,
                    }
                  });
                  setActiveNavbar(PageRouteName.ChooseBlock);

                  // reset floor
                  setStorage(
                    StorageKey.StepYourDesign,
                    { ...getStorage(StorageKey.StepYourDesign), floorId: undefined, segmentId: undefined }
                  );
                  dispatch(setDesignHome({
                    floorId: undefined,
                    segmentId: undefined,
                  }));
                }
              };
              updateState({ isMenuResponsive: false });
            },
            isHidden: (totalBlocks || 0) <= 1 ? true : false,
            isMobileShow: false,
          },
          {
            menuSubTitle: t('Choose Segment'),
            menuSubCategory: PageRouteName.ChooseSegment,
            onClick: () => {
              if (Number(locationId) === 114 && Number(projectId) === 5 && segmentId === 3634) return;
              if (PageRouteNameOrderedValidation.some(item => pathname.includes(item)) &&
                (getStorage(StorageKey.StepOrder) === OrderStep.Ordered ||
                  orderStatus === OrderStatus.Booked ||
                  orderStatus === OrderStatus.Bought ||
                  orderStatus === OrderStatus.Cancelled ||
                  orderStatus === OrderStatus.Pending ||
                  orderStatus === OrderStatus.PreOrdered)) {
                return;
              }
              if (!Number(locationId) && !Number(projectId)) {
                redirect({ path: PageRoute.HomePage });
              } else {
                redirect({
                  path: PageRoute.ChooseSegment,
                  params: {
                    locationId,
                    projectId,
                  }
                });
                setActiveNavbar(PageRouteName.ChooseSegment);
              }
              updateState({ isMenuResponsive: false });
            }
          }
        ],
        isHidden: isNavbarNotAvailable
          ? false
          : isNavbarAvailable ? true : false,
        isHideConfirm: true,
        isMobileShow: false,
      },
      {
        menuTitle: t('My Design'),
        menuIcon: <BuildOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyDesign],
        openMenu: openMenuMyDesign,
        onClick: () => {
          setOpenMenuMyDesign(!openMenuMyDesign);
          updateState({ isMenuResponsive: false });
        },
        isHidden: isNavbarNotAvailable
          ? false
          : isNavbarAvailable ? true : false,
        isHideConfirm: true,
        isMobileShow: false,
      },
      {
        menuTitle: t('My Financial Package'),
        menuIcon: <AccountBalanceOutlinedIcon />,
        menuCategory: [...PageRouteParent.MyFinancialPackage],
        openMenu: openMenuMyFinancial,
        onClick: () => {
          setOpenMenuMyFinancial(!openMenuMyFinancial);
          updateState({ isMenuResponsive: false });
        },
        isHidden: isNavbarNotAvailable
          ? false
          : isNavbarAvailable ? true : false,
        isHideConfirm: true,
        isMobileShow: false,
      },
      {
        menuTitle: t('My Order'),
        menuIcon: <EventNoteIcon />,
        menuCategory: [...PageRouteParent.MyOrderConfirm],
        openMenu: false,
        onClick: () => {
          handleRedirectOrderPage();
          setActiveNavbar(PageRouteName.MyOrderConfirm);
          updateState({ isMenuResponsive: false });
        },
        isHidden: tokenUMS ? true : false,
        isMobileShow: false,
      },
      {
        menuTitle: t('My Profile'),
        menuIcon: <UserIcon width="20px" height="18px" />,
        menuCategory: [...PageRouteParent.MyProfile],
        openMenu: false,
        onClick: () => {
          redirect({
            path: PageRoute.PersonalProfile,
            params: {
              locationId: locationId || getLocationId || '',
              projectId: projectId || getProjectId || '',
            }
          });
          setActiveNavbar(PageRouteName.PersonalProfile);
          updateState({ isMenuResponsive: false });
        },
        isHidden: tokenUMS ? true : false,
        isMobileShow: true,
      },
      {
        menuTitle: t('FAQs'),
        menuIcon: <IcFAQHead width="20px" height="20px" />,
        menuCategory: [],
        openMenu: false,
        onClick: () => {
          redirect({
            path: PageRoute.FAQ
          });
          updateState({ isMenuResponsive: false });
        },
        isHidden: true,
        isMobileShow: true,
      },
      {
        menuTitle: t('Contact us'),
        menuIcon: <IcContact width="20px" height="20px" />,
        menuCategory: [],
        openMenu: false,
        onClick: () => {
          redirect({
            path: PageRoute.ContactUs
          });
          updateState({ isMenuResponsive: false });
        },
        isHidden: true,
        isMobileShow: true,
      },
      {
        menuTitle: t('Connect'),
        menuIcon: <UserIcon width="20px" height="18px" />,
        menuCategory: [],
        openMenu: false,
        onClick: () => {
          if (!tokenUMS) {
            setShowSignupLight(true)
          }
        },
        isHidden: isMenuResponsive
          ? !username
            ? window.innerWidth >= 1024 ? false : true
            : false
          : false,
        isMobileShow: true,
      },
      {
        menuTitle: t('Sign out'),
        menuIcon: <SignoutIcon width="20px" height="20px" />,
        menuCategory: [],
        openMenu: false,
        onClick: () => setShowPopupSignout(true),
        isHidden: isMenuResponsive
          ? tokenUMS ? true : false
          : false,
        isMobileShow: true,
      }
    ]
  }, [
    openMenuMyHome,
    openMenuMyDesign,
    openMenuMyFinancial,
    activeNavbar,
    orderStatus,
    orders,
    isMenuResponsive,
    tokenUMS,
    pathname,
  ]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [remodel, setRemodel] = useState<string | null>(searchParams.get('homeNumber'))

  const fetchPriceMenuApi = async () => {
    if (pathname.includes(PageRouteName.PersonalProfile) || isNavbarNotAvailable) return;
    if (blockId && segmentId && floorId && planId) {
      let params: FetchProjectsParams = {
        projectId,
        blockId,
        planId,
        floorId,
        segmentId,
        layoutId: layoutId || undefined,
        productItems: productItems || undefined,
        personalityProperties: collectionId || personalityId || undefined,
        buyPurpose: getStorage(StorageKey.Envision),
        personalized: getStorage(StorageKey.personalized)
      };
      if (pathname.includes(PageRouteName.ChooseSegment) || pathname.includes(PageRouteName.ChooseBlock)) {
        params = {
          ...params,
          layoutId: undefined,
          productItems: undefined,
          personalityProperties: undefined,
        }
      }
      if (pathname.includes(PageRouteName.ResellUnit) || pathname.includes(PageRouteName.KeepModel)) {
        params = {
          ...params,
          isRemodel: false
        }
      }
      if (pathname.includes(PageRouteName.KeepAndRemodel)) {
        params = {
          ...params,
          isRemodel: true
        }
      }
      if (remodel) {
        params = {
          ...params,
          isRemodel: true
        }
      }
      const { data } = await fetchProjectPriceByDesignHomeApi({ ...params, currencyCode: preferCurrency, projectId: Number(projectId) });
      if (preferCurrency === String(getSession(SessionKey.Currency))) {
        setPriceMenu(data);

        if (!layoutId && !(productItems || []).length) {
          dispatch(setDesignHome({
            floorId: getStorage(StorageKey.StepYourDesign)?.floorId,
            blockId: data?.blockId,
            segmentId: getStorage(StorageKey.StepYourDesign)?.segmentId,
            extraPrice: data?.extraPrice || 0,
          }));
          setStorage(
            StorageKey.StepYourDesign,
            {
              ...getStorage(StorageKey.StepYourDesign),
              blockId: data?.blockId,
              floorId: getStorage(StorageKey.StepYourDesign)?.floorId || data?.floorId || '',
              extraPrice: data?.extraPrice || 0,
            }
          );
        }

        setStorage(
          StorageKey.FinancialPackage,
          {
            ...getStorage(StorageKey.FinancialPackage),
            totalPrice: data?.totalPrice || 0,
            bareshellPrice: data?.bareshellPrice || 0
          }
        );
        dispatch(setFinancialPlan({
          totalPrice: data?.totalPrice || 0,
          bareshellPrice: data?.bareshellPrice || 0
        }));
      }
    } else {
      const params: FetchProjectsParams = hasStorage(StorageKey.StepYourDesign)
        ? { projectId, blockId: getStorage(StorageKey.StepYourDesign)?.blockId }
        : { projectId }
      const { data } = await fetchProjectPriceByIdApi(
        {
          ...params,
          currencyCode: preferCurrency,
          defaultType: pathname.includes(PageRouteName.ChooseBlock) ? 'block' : 'floor',
          floorId: pathname.includes(PageRouteName.ChooseBlock) ? undefined : floorId || undefined,
        });
      if (preferCurrency === String(getSession(SessionKey.Currency))) {
        if (!isEmpty(data)) {
          setPriceMenu(data);
          setStorage(
            StorageKey.StepYourDesign,
            {
              ...getStorage(StorageKey.StepYourDesign),
              blockId: data?.blockId,
              extraPrice: data?.extraPrice || 0,
            }
          );
          dispatch(setDesignHome({
            blockId: data?.blockId,
            extraPrice: data?.extraPrice || 0,
          }));

          setStorage(
            StorageKey.FinancialPackage,
            {
              ...getStorage(StorageKey.FinancialPackage),
              totalPrice: 0,
              bareshellPrice: 0
            }
          );
          dispatch(setFinancialPlan({
            totalPrice: 0,
            bareshellPrice: 0
          }));
        }
      }
    }
  }

  const handleActiveNavbar = () => {
    const routeActive = NEW_NAME_PATHS.find(item => pathname.includes(item));
    setActiveNavbar(routeActive!);
  }

  const handleRedirectOrderPage = useCallback(() => {
    if (!isEmpty(orders)) {
      redirect({
        path: PageRoute.MyOrderConfirm,
        params: {
          orderId: Number(orders[orders?.length - 1]?.id),
          locationId: locationId || 3,
          projectId: Number(orders[orders?.length - 1]?.projectId),
        }
      });
    } else {
      toast.error(t('You have not ordered yet!'));
    }
  }, [orders])

  const handleLogout = () => {
    window.location.href = '/';
    removeStorage(StorageKey.AuthDesign);
  }

  useEffect(() => {
    handleActiveNavbar();
    setSellType(getStorage(StorageKey.SellType))
  }, [pathname])

  useEffect(() => {
    if (!pathname.includes(PageRouteName.MyOrderConfirm)) {
      fetchPriceMenuApi();
    }
  }, [blockId, segmentId, layoutId, productItems, floorId, pathname])

  const renderMenuFitOut = () => {
    return <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1}
      style={{ padding: '.75rem' }}>
      <div className='text-sub-name'>{t('Fit Out')}</div>
      <div className='text-sub-name white'>
        {exchangePriceWithCurrency(
          priceMenu?.fitoutPrice || 0,
          formatPrefixOrSuffix(preferCurrency)
        )}
      </div>
    </Stack>
  };

  const renderMenuFurniture = () => {
    return <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1}
      style={{ padding: '.75rem', background: theme.palette.default.contrastText }}>
      <div className='text-sub-name'>{t('Furniture')}</div>
      <div className='text-sub-name white'>
        {exchangePriceWithCurrency(
          priceMenu?.furniturePrice || 0,
          formatPrefixOrSuffix(preferCurrency)
        )}
      </div>
    </Stack>
  };

  const renderMenuPrice = () => {
    return (
      <Box>
        {!isNavbarProfile &&
          <Box className='group-menu-price'>
            <Stack padding=".75rem 1rem" flexDirection="row" columnGap={1.5} alignItems="center">
              <IcCalculator width="20px" height="20px" />
              <Box
                className='text-price'
                fontSize={{ xs: '12px', md: '16px' }}
              >
                {t('Price')}
              </Box>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              style={{ padding: '.75rem', background: theme.palette.default.contrastText }}>
              <div className='text-sub-name'>{t('Bareshell')}</div>
              <div className='text-sub-name white'>
                {exchangePriceWithCurrency(
                  priceMenu?.bareshellPrice || 0,
                  formatPrefixOrSuffix(preferCurrency)
                )}
              </div>
            </Stack>
            {sellType !== SellType.Resell ? <>
              {!activeSellBareshell
                ? renderMenuFitOut()
                : pathname.includes(PageRouteName.ChooseSegment)
                  ? renderMenuFitOut()
                  : !checkFlowBareShell(orderType!) && renderMenuFitOut()
              }
            </> : <></>}
            {sellType !== SellType.Resell ? <>
              {!activeSellBareshell
                ? renderMenuFurniture()
                : pathname.includes(PageRouteName.ChooseSegment)
                  ? renderMenuFurniture()
                  : !checkFlowBareShell(orderType!) && renderMenuFurniture()
              }
            </> : <></>}
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              style={{ padding: '1.5rem .75rem', background: theme.palette.accent.main }}>
              <Box style={{ width: '110px', wordBreak: 'break-word' }}>
                <Box className='text-sub-name white'>{t('Total')} ({t('incl.')} {vatName || t('VAT')})</Box>
              </Box>
              <Box className='text-sub-name white large'>
                {exchangePriceWithCurrency(
                  priceMenu?.totalVatPrice || 0,
                  formatPrefixOrSuffix(preferCurrency)
                )}
              </Box>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              style={{ padding: '.75rem', background: theme.palette.default.contrastText }}>
              <Box style={{ width: '105px', wordBreak: 'break-word' }}>
                <Box className='text-sub-name italic'>{`${vatName || t('VAT')} (${priceMenu?.vat}%)`}</Box>
              </Box>
              <div className='text-sub-name white italic'>
                {exchangePriceWithCurrency(
                  priceMenu?.vatPrice || 0,
                  formatPrefixOrSuffix(preferCurrency)
                )}
              </div>
            </Stack>
          </Box>
        }
      </Box>
    );
  }

  const renderMenuLink = () => {
    return (ItemListMenu || []).map((item, index) => (
      <Box key={index}>
        {(item.isHidden) && (
          <Box className='group-menu-header'>
            <Box>
              {(
                (pathname.includes(PageRouteName.MyOrderConfirm) ||
                  pathname.includes(PageRouteName.PersonalProfile))
              )
                ? !item.isHideConfirm
                  ? MenuListItem(item) : null
                : checkInteractMobile()
                  ? item.isMobileShow && MenuListItem(item)
                  : MenuListItem(item)
              }
            </Box>
          </Box>
        )}
        {(index === 2 && isMenuResponsive && isNavbarAvailable) && (
          <Box display={{ xs: 'none', md: 'block' }}>{renderMenuPrice()}</Box>
        )}
        {(index === 2 && !isNavbarHide && !isMenuResponsive && isNavbarAvailable) && renderMenuPrice()}
      </Box>
    ))
  }

  const MenuListItem = (item: any) => {
    return (
      <Box>
        <Stack
          className={`box-menu-header ${(item.menuCategory || []).some((menu: any) => menu.includes(activeNavbar!)) && 'active'}`}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={item.onClick}
        >
          <Stack flexDirection="row" columnGap={1} alignItems="center">
            {item.menuIcon}
            <Box
              className='text-menu'
              fontSize={{ xs: '12px', md: '14px' }}
            >
              {item.menuTitle}
            </Box>
          </Stack>
          {item.subMenu?.length && <Box>
            {item.openMenu
              ? <ExpandLess color='disabled' fontSize="medium" />
              : <KeyboardArrowRightIcon color='disabled' fontSize="medium" />}
          </Box>}
        </Stack>
        <Collapse in={item.openMenu}>
          {(item.subMenu || [])?.map((menu: any, index: number) => (
            <>
              {!menu.isHidden && (
                <Box
                  key={index}
                  onClick={menu.onClick}
                  className={`text-sub-menu ${menu.menuSubCategory.includes(activeNavbar!) && 'active'}`}
                  fontSize={{ xs: '12px', md: '14px' }}
                >
                  {menu.menuSubTitle}
                </Box>
              )}
            </>
          ))}
        </Collapse>
      </Box>
    )
  }

  return (
    <>
      <WrapperStyled>
        {isNavbarAvailable &&
          <Box sx={{ display: { xs: 'none', md: 'none', lg: 'none', xl: 'block' } }}>
            <Nav
              isNavbarHide={isNavbarHide}
              className="left-bar area-sidebar-main"
              aria-label="left-menu"
            >
              <Header
                style={{
                  display: 'flex',
                  justifyContent: !isNavbarHide ? 'space-between' : 'center',
                  alignItems: 'center',
                }}
              >
                {!isNavbarHide &&
                  <Box onClick={() => redirect('/')} style={{ cursor: 'pointer' }} pl=".5rem">
                    <Logo.LevitateLogo width="120px" />
                  </Box>
                }
                <Stack
                  onClick={() => {
                    if (!isNavbarHideActive) return;
                    updateState({ isNavbarHide: !isNavbarHide });
                  }}
                  style={{
                    cursor: 'pointer',
                    background: !isNavbarHide ? theme.palette.primary.dark : theme.palette.accent.main,
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {!isNavbarHide
                    ? <IcNavHide width="23px" height="16px" />
                    : <IcNavShow width="23px" height="16px" />}
                </Stack>
              </Header>
              {!isNavbarHide &&
                <Box>
                  <Divider />
                  {renderMenuLink()}
                </Box>
              }
            </Nav>
          </Box>
        }

        <CustomDrawer
          open={isMenuResponsive}
          onClose={(open: boolean) => updateState({ isMenuResponsive: open })}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1.25rem'
            }}
          >
            <Box onClick={() => {
              redirect('/');
              updateState({ isMenuResponsive: false });
            }} style={{ cursor: 'pointer' }}>
              <Logo.LevitateLogo width="120px" />
            </Box>
            <Stack flexDirection="row" columnGap="1rem" alignItems="center">
              <Stack
                flexDirection="row" columnGap="1rem"
                display={{
                  xs: window.innerWidth <= 500 ? 'none' : 'flex',
                  sm: window.innerWidth < window.innerHeight
                    ? 'none'
                    : (window.innerWidth > window.innerHeight && window.innerWidth < 900)
                      ? 'flex' : 'none',
                  md: 'none',
                }}
              >
                <Box width={140}>
                  <LanguageDropdown />
                </Box>
                {/* <Box width={140}>
                  <CurrencyDropdown />
                </Box> */}
              </Stack>
              <Stack
                onClick={() => {
                  updateState({ isMenuResponsive: !isMenuResponsive });
                }}
              >
                <IcOutlineClose />
              </Stack>
            </Stack>
          </Box>
          <Divider />
          <Stack
            flexDirection={{
              xs: window.innerWidth <= 500 ? 'column' : 'row',
              sm: (window.innerWidth > window.innerHeight && window.innerWidth < 900)
                ? 'row' : 'column'
            }}
            columnGap="1rem"
            width="100%"
            justifyContent="space-between"
          >
            <Box flex={1}>
              {renderMenuLink()}
            </Box>
            {/* <Box className='line-menu' width=".5px" height="100%" bgcolor={theme.palette.secondary.light}></Box>
            <Box
              display={{
                xs: 'block',
                sm: (window.innerWidth > window.innerHeight && window.innerWidth < 900) ? 'block' : 'none',
                md: 'none'
              }}
              flex={1}
            >
              {renderMenuPrice()}
            </Box> */}
          </Stack>
          <Box
            padding="1.25rem"
            display={{
              xs: window.innerWidth <= 500 ? 'block' : 'none',
              sm: (window.innerWidth > window.innerHeight && window.innerWidth < 900) ? 'none' : 'block',
              md: 'none'
            }}
          >
            <Box width="100%">
              <LanguageDropdown />
            </Box>
            <Box width="100%" mt="1rem">
              <CurrencyDropdown />
            </Box>
          </Box>
        </CustomDrawer>

        <SignupLight
          typePage={TypeSignInAndSignUp.SignIn}
          onClose={() => {
            setShowSignupLight(false);
            localStorage.removeItem("PREV_PATH");
            // updateState({ isMenuResponsive: false });
          }}
          show={showSignupLight}
        />

        <QuestionDialog
          maxWidth="xs"
          open={showPopupSignout}
          cancelText={t("Back")}
          widthContent='100%'
          confirmText={<Box style={{ textTransform: 'capitalize' }}>{t("Sign out")}</Box>}
          onConfirm={handleLogout}
          closeDialog={() => setShowPopupSignout(false)}
          widthButton='100%'
        >
          <Box fontSize={'20px'}>{t(`You're signing out?`)}</Box>
          <Box mt={1} color="#C9C8CC" fontSize={'17px'}>{t(`Have a great day and keep being a badass. See you soon.`)}</Box>
        </QuestionDialog>

        {isNavbarAvailable && (
          <Wrapper
            isNavbarHide={isNavbarHide}
          >
            {children}
          </Wrapper>
        )}
        {isNavbarNotAvailable && children}
      </WrapperStyled>
    </>
  )
};

type StyledProps = {
  isNavbarHide?: boolean;
};

const WrapperStyled = styled.section`
  .dashboardSideBar {
    position: fixed;
    top: 45%;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    padding: 1rem;
    box-shadow: 0px 4px 40px rgba(88, 149, 202, 0.16);
  }
`;

const Header = styled(Box)`
  height: 72px;
  background-color: ${(p) => p.theme.palette.default.light};
  padding: .5rem;
`;

const Nav = styled.nav<StyledProps>`
  width: ${(p) => (!p.isNavbarHide ? '18rem' : '60px')};
  background-color: ${(p) => p.theme.palette.default.light};
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  height: 100%;
  color: #C9C8CC;
  transition: all .2s ease-in-out;

  @media only screen and (min-width: 1200px) { 
    width: ${(p) => (!p.isNavbarHide ? '15rem' : '60px')};
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .MuiTypography-root {
    font-size: 0.875rem; // 14px
  }

  .MuiList-root .Mui-selected {
    background-color: ${(p) => p.theme.palette.accent.main};

    .MuiTypography-root {
      font-weight: 500;
    }

    &:hover {
      background-color: ${(p) => p.theme.palette.accent.mainHover};
    }
  }

  .group-menu-header {
    margin: 1.5rem 0 0 1.25rem;
    
    .box-menu-header {
      padding: .7rem 0 .7rem .7rem;
      cursor: pointer;

      &.active {
        background: linear-gradient(90deg, ${p => p.theme.palette.secondary.main} 18.6%, ${p => p.theme.palette.default.contrastText} 100%);
        color: ${(p) => p.theme.palette.accent.main};
        
        .text-menu {
          font-weight: 500;
        }
      }

      .text-menu {
        font-weight: 300;
      }
    }

    .text-sub-menu {
      margin: 1rem 1.5rem 0;
      font-weight: 300;
      padding-left: .5rem;
      cursor: pointer;
      
      &.active {
        border-left: 2px solid ${(p) => p.theme.palette.accent.main};
        font-weight: 500;
        color: ${(p) => p.theme.palette.accent.main};
      }
    }
  }

  .group-menu-price {
    border: 1px solid ${(p) => p.theme.palette.secondary.light};
    margin: 1rem .7rem 0;

    .text-price {
      font-weight: 500;
      color: ${(p) => p.theme.palette.accent.main};
    }

    .text-sub-name {
      width: inherit;
      word-break: break-word;

      &.large {
        font-size: 13px
      }

      &.white {
        color: #fff;
      }

      &.italic {
        font-style: italic;
      }

      @media only screen and (min-width: 0px) {
        font-size: 12px;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 13px;
      }
    }
  }
`;

const Wrapper = styled.div<StyledProps>`
  width: 100%;
  margin-left: auto;
  transition: all .2s ease-in-out;

  @media only screen and (min-width: 1420px) { 
    width: ${(p) => (!p.isNavbarHide ? 'calc(100% - 15rem)' : 'calc(100% - 60px)')};
  }
`

export default MainSideBar;

// color={[PageRoute.ChooseBlock, PageRoute.ChooseSegment].includes(pathname as PageRoute)
//   ? 'accent' : 'disabled'} 