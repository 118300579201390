import React from 'react';

const MexicoOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4209_41213)">
        <path
          d="M4 7.57114C4.19968 7.57114 4.39275 7.57114 4.58559 7.57114C4.67687 7.57114 4.76816 7.57114 4.85944 7.57114C4.99165 7.57114 5.07495 7.64308 5.10933 7.78696C5.28711 8.53365 5.46488 9.28034 5.64289 10.027C5.65133 10.0626 5.66274 10.0972 5.69446 10.1378C5.70496 10.098 5.71614 10.0584 5.7255 10.0183C5.90967 9.23898 6.09497 8.45996 6.27594 7.67968C6.29557 7.59521 6.32638 7.56763 6.40602 7.56889C6.72118 7.57415 7.03634 7.57114 7.36109 7.57114V11.4164H6.71593V8.5176C6.70635 8.5161 6.69676 8.51485 6.68741 8.51334C6.63446 8.74144 6.58083 8.96928 6.5288 9.19762C6.36746 9.90571 6.20588 10.6138 6.04728 11.3226C6.02971 11.4014 6.00004 11.4314 5.9245 11.4284C5.76498 11.4222 5.60478 11.4207 5.44549 11.4287C5.35831 11.4329 5.32956 11.3956 5.3097 11.3076C5.11002 10.4163 4.90645 9.52598 4.7038 8.63541C4.69581 8.60032 4.686 8.56573 4.65839 8.5334V11.4149H4V7.57114Z"
          fill="white"
        />
        <path
          d="M14.238 7.48401V7H14.863C14.863 7.11981 14.8699 7.23962 14.861 7.35818C14.853 7.46421 14.8884 7.50105 14.9819 7.5196C15.3065 7.58402 15.5751 7.74995 15.7458 8.07304C15.8448 8.26053 15.8993 8.4613 15.8859 8.69691C15.6634 8.69691 15.4438 8.69992 15.2248 8.6929C15.2003 8.69215 15.1657 8.63876 15.1558 8.60292C15.0938 8.37808 15.0276 8.27882 14.874 8.20814C14.8701 8.24223 14.8637 8.27281 14.8635 8.30339C14.8626 8.51644 14.8681 8.72975 14.8605 8.9423C14.8571 9.03805 14.8934 9.06788 14.9687 9.09595C15.1515 9.16413 15.3418 9.22303 15.5082 9.32655C15.9044 9.57319 16.0434 9.97799 15.989 10.455C15.9329 10.9488 15.6695 11.2734 15.2375 11.4258C15.1403 11.4601 15.0388 11.4824 14.9377 11.4987C14.8786 11.5082 14.8612 11.5345 14.8624 11.5952C14.8649 11.7275 14.863 11.8601 14.863 11.9995H14.2384C14.2384 11.8724 14.2332 11.7521 14.24 11.6328C14.2455 11.5373 14.2227 11.4954 14.1284 11.4774C13.4292 11.3438 13.154 10.7477 13.1049 10.187C13.1031 10.1672 13.1076 10.1467 13.1106 10.1091C13.3286 10.1091 13.5444 10.1056 13.7599 10.1141C13.7843 10.1151 13.8169 10.18 13.8281 10.2214C13.8854 10.4352 13.968 10.6279 14.1533 10.7447C14.175 10.7583 14.1996 10.766 14.2387 10.7836C14.2387 10.5197 14.2393 10.2708 14.2384 10.0221C14.238 9.92235 14.2663 9.79828 14.2231 9.72935C14.1809 9.66167 14.065 9.64889 13.9806 9.61405C13.657 9.48045 13.3966 9.2704 13.2843 8.89367C13.1092 8.3059 13.4192 7.72338 13.997 7.54642C14.0734 7.52311 14.1513 7.50607 14.2375 7.48376L14.238 7.48401ZM14.8719 9.90179V10.8016C15.0913 10.768 15.2674 10.5628 15.275 10.3414C15.2825 10.1181 15.1533 9.97348 14.8719 9.90179ZM14.235 8.18132C14.0173 8.24373 13.9078 8.37984 13.9276 8.54878C13.9454 8.69892 14.0903 8.83552 14.235 8.83678V8.18132Z"
          fill="white"
        />
        <path
          d="M10.2441 10.2526H8.57767C8.55759 10.6604 8.81638 10.962 9.15048 10.9346C9.33122 10.9198 9.44327 10.8086 9.50717 10.6324C9.52794 10.575 9.54756 10.5391 9.61215 10.5521C9.80567 10.5912 9.99965 10.6261 10.2071 10.6649C10.1051 10.9888 9.93758 11.2404 9.65711 11.3713C9.26755 11.5532 8.87115 11.5425 8.48524 11.3502C8.27757 11.2467 8.12444 11.072 8.03088 10.8431C7.80769 10.2975 7.80061 9.74479 8.05073 9.21316C8.27917 8.72715 8.67512 8.53565 9.16143 8.57926C9.65756 8.62388 9.99668 8.90661 10.153 9.43223C10.2301 9.69166 10.2507 9.96035 10.2445 10.2524L10.2441 10.2526ZM9.57084 9.7864C9.5681 9.53951 9.50786 9.32997 9.30772 9.2074C9.13884 9.10388 8.9622 9.11215 8.802 9.23597C8.63244 9.36731 8.57767 9.55881 8.58132 9.78615H9.57084V9.7864Z"
          fill="white"
        />
        <path
          d="M12.9346 11.4264C12.658 11.4264 12.4117 11.4296 12.1655 11.4226C12.1308 11.4216 12.0872 11.378 12.0646 11.3416C11.9085 11.0905 11.757 10.8361 11.5924 10.5636C11.4786 10.7508 11.3535 10.9153 11.2704 11.1023C11.1538 11.3644 10.9904 11.4757 10.7241 11.4306C10.5885 11.4076 10.4471 11.4266 10.2832 11.4266C10.3829 11.2679 10.4678 11.1316 10.5539 10.9962C10.7476 10.6917 10.9395 10.3859 11.1376 10.0849C11.186 10.0112 11.1849 9.96155 11.1367 9.88861C10.8868 9.51037 10.6422 9.12788 10.3957 8.74664C10.3763 8.71656 10.3587 8.68523 10.325 8.62933C10.4681 8.62933 10.5915 8.62783 10.715 8.62958C10.8551 8.63159 11.0192 8.58798 11.1287 8.6534C11.238 8.71856 11.2933 8.89377 11.3709 9.02185C11.4418 9.13916 11.5107 9.25772 11.5915 9.39432C11.7218 9.18453 11.8517 8.99528 11.961 8.79276C12.0331 8.65941 12.121 8.6173 12.2568 8.62607C12.4453 8.63836 12.6349 8.62908 12.8442 8.62908C12.8195 8.67671 12.8061 8.70854 12.7878 8.73661C12.5452 9.11033 12.304 9.48531 12.0569 9.85552C12.0082 9.92821 12.0064 9.97759 12.0539 10.0505C12.3104 10.4443 12.5623 10.8413 12.8159 11.2374C12.8503 11.291 12.8839 11.3454 12.9343 11.4259L12.9346 11.4264Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4209_41213">
          <rect width="12" height="5" fill="white" transform="translate(4 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MexicoOutlined;
