import { AppAction } from '../store';
import Location from 'model/location';
import Project from 'model/project';

enum ProjectTypes {
  FetchLocations = 'location/FETCH_LOCATIONS',
  FetchProjects = 'location/FETCH_PROJECTS',
  fetchProjectsSecondary = 'location/FETCH_PROJECTS_SECONDARY',
}

export type FetchLocationsAction = AppAction<
  ProjectTypes.FetchLocations,
  { data: Location[] }
>;

export type FetchProjectsAction = AppAction<
  ProjectTypes.FetchProjects,
  {
    data: {
      projects: Project[];
      nearbyLocations: {
        id: number;
        regionName: string;
        projects: Project[];
      }[];
    };
  }
>;

export type FetchProjectsSecondaryAction = AppAction<
  ProjectTypes.fetchProjectsSecondary,
  {
    data: {
      id: number,
      regionName: string;
      projects: Project[];
    };
  }
>;


export default ProjectTypes;
