import React from 'react';

const EuroOutlined = (props: any) => {
  return (
    <svg
      {...props}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.832 1.25C6 1.25 2.08203 5.16797 2.08203 10C2.08203 14.832 6 18.75 10.832 18.75C15.6641 18.75 19.582 14.832 19.582 10C19.582 5.16797 15.6641 1.25 10.832 1.25ZM10.832 17.2656C6.82031 17.2656 3.56641 14.0117 3.56641 10C3.56641 5.98828 6.82031 2.73438 10.832 2.73438C14.8438 2.73438 18.0977 5.98828 18.0977 10C18.0977 14.0117 14.8438 17.2656 10.832 17.2656Z"
        fill="white"
      />
      <g clipPath="url(#clip0_4208_41133)">
        <path
          d="M14.832 6.2489V6.3008C14.5991 6.52659 14.3655 6.75103 14.1345 6.97816C14.04 7.07117 13.9509 7.1689 13.8604 7.26326C11.8665 5.59783 9.16694 6.51648 8.31279 8.33288H11.4863V9.44969H7.91036V10.549H11.4877V11.6617H8.29591C8.70577 12.42 9.27835 12.978 10.0535 13.2975C11.4215 13.8616 12.6896 13.6453 13.8611 12.73C14.0609 12.9342 14.2567 13.1371 14.4559 13.3366C14.5781 13.4579 14.7064 13.5732 14.832 13.6911V13.743C14.7935 13.7679 14.7524 13.7895 14.7172 13.8178C13.408 14.88 11.9232 15.2453 10.2898 14.8382C8.61461 14.4204 7.46674 13.3629 6.81988 11.7662C6.78612 11.6833 6.74763 11.6577 6.66053 11.6584C6.09604 11.6624 5.53156 11.6604 4.9664 11.6604H4.83203V10.5409H6.50387V9.44025H4.83203V8.33086H4.98193C5.53764 8.33086 6.09402 8.32749 6.64972 8.33356C6.75033 8.33491 6.79017 8.29986 6.82595 8.21157C7.5059 6.55355 8.71859 5.51425 10.4681 5.10649C10.6686 5.05999 10.8752 5.03505 11.0785 5C11.3479 5 11.6173 5 11.8867 5C11.9198 5.00809 11.9529 5.02089 11.986 5.02426C12.9549 5.11862 13.8192 5.47112 14.5856 6.06828C14.6659 6.13096 14.7497 6.18892 14.8314 6.24958L14.832 6.2489Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4208_41133">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(4.83203 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EuroOutlined;
