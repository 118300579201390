// APIS
import { fetchProjectDemoApi } from "apis/projectApis";
import { SessionKey, StorageKey } from "constants/storage";

// MODEL
import { DesignHome } from "model/designHome";
import { ProjectStatus } from "model/project";
import { PageRoute } from "constants/route";

// STORAGE
import { removeStorage, setSession, setStorage } from "services/storageService";

// ACTIONS
import { setDesignHome, setFilterProjects } from "store/designHome/designHomeActions";
import useRoute from "./useRoute";

// HOOKS
import { useDispatch, useSelector } from "react-redux";
import { selectDesignHome } from "store/designHome/designHomeSelectors";

const useFunction = () => {
  const { redirect } = useRoute();
  const dispatch = useDispatch();
  const {
    filterProjects,
  } = useSelector(selectDesignHome);

  const handleDemoStudio = async () => {
    const { data } = await fetchProjectDemoApi();
    if (data?.floorId) {
      const designHomeData: DesignHome = {
        blockId: data?.blockId,
        floorId: data?.floorId,
        planId: data?.planId,
        segmentId: data?.segmentId || 3634,
        status: ProjectStatus.Upcoming,
        locationId: 114,
        projectId: data?.projectId,
        orderStatus: undefined,
        orderId: undefined,
        orderType: undefined,
        collectionId: 4,
        vatName: data?.vatName,
      };
      removeStorage(StorageKey.StepOrder);
      dispatch(setFilterProjects({ ...filterProjects, isExistLayout: false }));
      dispatch(setDesignHome(designHomeData));
      setStorage(StorageKey.StepYourDesign, designHomeData);
      setStorage(StorageKey.PackageId, data?.packageId);
      setStorage(StorageKey.ShareId, data?.shareId);
      setStorage(StorageKey.PackageId, data?.packageId);
      setStorage(StorageKey.ShareId, data?.shareId);
      setStorage(StorageKey.SellType, data?.sellType ?? null)
      removeStorage(StorageKey.Envision)
      removeStorage(StorageKey.personalized)
      removeStorage(StorageKey.promotion)
      setSession(SessionKey.isDemo3DInteract, true);
      redirect({
        path: PageRoute.YourDesign,
        params: {
          locationId: 114,
          projectId: data?.projectId,
          personalityId: 4,
        }
      });
    }
  };

  return { handleDemoStudio };
};

export default useFunction;
