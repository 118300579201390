export const POLICY_NL = (textBrand: string) => {
  return [
    {
      title: 'Inleiding',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                <div>1.1.</div>
                <div>${textBrand} International Pte. Ltd. ("<strong>wij</strong>", "<strong>ons</strong>", "<strong>onze</strong>" of "<strong>${textBrand}</strong>"), is de exploitant van <a href="https://levitate.homes">https://levitate.homes</a> (de "<strong>Website</strong>"), en we zijn toegewijd aan het beschermen van uw Persoonsgegevens (zoals gedefinieerd in Clausule 2 hieronder) in overeenstemming met de hierin vermelde voorwaarden en bepalingen.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.2.</div>
                <div>Deze Privacyverklaring (samen met onze Gebruiksvoorwaarden en andere documenten waarnaar hierin wordt verwezen) beschrijft hoe Persoonsgegevens die we van u verzamelen, of die u aan ons verstrekt, zullen worden gebruikt, verwerkt of bekendgemaakt door ons, inclusief hoe bepaalde informatie zal worden gebruikt om reclame te personaliseren die op u is gericht. Deze Privacyverklaring beschrijft ook de soorten Persoonsgegevens die we verzamelen, hoe we deze veilig bewaren, en hoe u toegang kunt krijgen tot uw Persoonsgegevens die door ons worden bewaard of deze kunt wijzigen. Deze Privacyverklaring heeft betrekking op uw gebruik van de Website en elke andere manier waarop u verbinding kunt maken of met ons kunt communiceren door gebruik te maken van onze producten en diensten (hierna gezamenlijk aangeduid als de "<strong>Diensten</strong>").</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.3.</div>
                <div>Lees het volgende goed door om ons beleid en onze werkwijzen met betrekking tot uw Persoonsgegevens en hoe wij deze zullen behandelen te begrijpen.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.4.</div>
                <div>Tenzij beperkt door toepasselijk recht, stemt u ermee in dat alle Persoonsgegevens met betrekking tot u, die door ons worden verzameld of door u aan ons worden verstrekt, van tijd tot tijd kunnen worden gebruikt, verwerkt en bekendgemaakt voor de doeleinden en aan de personen zoals uiteengezet in deze Privacyverklaring, door gebruik te maken van de Website en de Diensten.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>1.5.</div>
                <div>Als onderdeel van onze inspanningen om ervoor te zorgen dat we uw Persoonsgegevens op de juiste wijze beheren, beschermen en verwerken, zullen we periodiek onze beleidsregels, procedures en processen evalueren. We kunnen deze Privacyverklaring te allen tijde wijzigen door een herziene versie op de Website te plaatsen. We zullen proberen een melding te sturen wanneer het beleid wordt gewijzigd, desondanks wordt u geacht de herziene Privacyverklaring te hebben erkend en ermee akkoord te zijn gegaan als u de Website of de Diensten blijft gebruiken nadat de wijzigingen van kracht zijn geworden, ongeacht of u de melding hebt ontvangen of niet. We herinneren u eraan om deze pagina regelmatig te controleren voor updates van deze Privacyverklaring.</div>
              </div>
              <div>
              </div>
            </div>
          `
    },
    {
      "title": "Verzameling van Persoonlijke Gegevens",
      "description": `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div>
              U kunt ervoor kiezen om de Website te doorbladeren en de beperkte functies ervan te gebruiken zonder uw Persoonlijke Gegevens bekend te maken. U bent niet verplicht om Persoonlijke Gegevens te verstrekken als voorwaarde voor het gebruik van de Website, behalve wanneer dit noodzakelijk is om de Services te kunnen leveren die u aanschaft of via onze Website gebruikt, om te reageren op uw verzoeken, of in gevallen waarin u rechtstreeks contact met ons opneemt.
            </div>
            <div>
              Wanneer u onze Services gebruikt, verzamelen wij verschillende informatie over u, uw apparaten en uw interactie met de Services. Sommige van deze informatie identificeert u rechtstreeks of kan worden gebruikt om u losjes te identificeren wanneer deze wordt gecombineerd met andere gegevens. Enige data, waarheidsgetrouw of niet, over een individu dat kan worden geïdentificeerd aan de hand van die data; of aan de hand van die data in combinatie met andere informatie waarover de organisatie beschikt of waarschijnlijk zal beschikken, wordt beschouwd als persoonlijke data ("<strong>Persoonlijke Gegevens</strong>").
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.1.</div>
                <div>
                  <strong>Informatie die u vrijwillig verstrekt</strong>. Wanneer u onze Services gebruikt, kan u worden gevraagd om persoonlijke informatie over uzelf te verstrekken, zoals, onder andere, uw naam, contactinformatie, betaalinformatie, details over uw huis of eigendommen waarin u geïnteresseerd bent, financiële informatie, uw favorieten of uw stijlen. Dit kan bijvoorbeeld voorkomen:
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Wanneer u transacties met ons uitvoert op of in verband met de Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Wanneer u een account bij ons aanmaakt op de Website;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>Wanneer u contact opneemt met onze klantenservice of verkopers per e-mail, telefoon of persoonlijk, om problemen die u mogelijk ondervindt op te lossen, of voor vragen of verzoeken;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>Wanneer u zich abonneert op de services die wij op de Website aanbieden, of voor onze e-mailmeldingen en/of nieuwsbrieven;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>Wanneer u relevante invoer, reacties of informatie op de Website verstrekt of achterlaat voor het gebruik van de Services;</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>f.</div>
                      <div>Wanneer u correspondeert met een vastgoedprofessional (zoals een makelaar, hypotheekverstrekker, vastgoedbeheerder, investeerder, bouwer of anderen) via de Services; en</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>g.</div>
                      <div>Wanneer u andere formulieren of transacties invult, zoals een verzoek om informatie over een lening, een aanvraag voor een achtergrondcontrole (indien van toepassing).</div>
                    </div>
                  </div>
                  <div>
                    U kunt ook informatie over een derde partij verstrekken via de Services, bijvoorbeeld als u een vastgoedlijst deelt met een ontvanger via e-mail of SMS. Door dergelijke informatie aan ons te verstrekken, verklaart u dat u toestemming van de derde partij heeft verkregen om ons hun Persoonlijke Gegevens te verstrekken voor de doeleinden zoals uiteengezet in dit Privacybeleid.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.2.</div>
                <div>
                  <strong>Cookies, Pixels en andere volgmechanismen</strong>. Wij en onze partners gebruiken verschillende technologieën om automatisch informatie te verzamelen wanneer u toegang krijgt tot en onze Services gebruikt, waaronder cookies en andere vergelijkbare technologieën. Cookies zijn elektronische informatiefragmenten die naar uw computer of ander elektronisch apparaat kunnen worden overgebracht om uw browser uniek te identificeren. Wanneer u de Services gebruikt, kunnen wij en onze partners een of meer cookies op uw computer of ander elektronisch apparaat plaatsen of andere technologieën gebruiken met soortgelijke functionaliteit.
                  <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                    <div style="display: flex; gap: 0.3rem;">
                      <div>a.</div>
                      <div>Wij en onze partners kunnen cookies gebruiken om uw activiteit op de Services te verbinden met andere informatie die we over u opslaan in uw accountprofiel of uw eerdere interacties met onze Services, bijvoorbeeld om uw voorkeuren op te slaan. Het gebruik van cookies helpt ons om de kwaliteit van onze Services voor u te verbeteren, door informatie te identificeren die het meest interessant voor u is, trends te volgen, de effectiviteit van reclame te meten of informatie op te slaan die u regelmatig wilt ophalen, zoals uw geselecteerde eigendommen of voorkeurszoekopdrachten, en door andere acties uit te voeren die zijn toegestaan onder de toepasselijke wetgeving. U kunt op elk moment de instellingen van uw browser aanpassen om cookies te weigeren volgens de instructies die betrekking hebben op uw browser. Als u ervoor kiest om cookies uit te schakelen, werken veel van de gratis functies van de Services mogelijk niet goed.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>b.</div>
                      <div>Sommige van onze pagina's kunnen ook pixels bevatten, dit zijn elektronische bestanden om gebruikers te tellen die die pagina hebben bezocht, om activiteiten in de tijd en over verschillende websites te volgen, om de interacties van gebruikers met e-mails die we sturen te bepalen, om bepaalde cookies op de computer of ander elektronisch apparaat dat die pagina bezoekt te identificeren, of om andere gerelateerde informatie te verzamelen, en deze informatie kan worden geassocieerd met uw unieke browser, apparaatidentificatie of IP-adres. Bijvoorbeeld, we kunnen een pixel implementeren op specifieke Services waar u een bepaald aanbod bekijkt, zodat we kunnen bijhouden of het aanbod relevant voor u is en aanbevelingen kunnen doen. Via deze pixels en cookies verzamelen we informatie over uw gebruik van onze Services, inclusief het type browser dat u gebruikt, toegangstijden, bekeken pagina's, uw IP-adres en de pagina die u bezocht voordat u naar onze Services navigeerde.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>c.</div>
                      <div>We verzamelen ook informatie over de computer of mobiel apparaat dat u gebruikt om toegang te krijgen tot onze Services, zoals het hardwaremodel, besturingssysteem en versie, unieke apparaatidentificatie, mobiele netwerkinformatie en browsegedrag.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>d.</div>
                      <div>We staan ook bepaalde derde partijen toe om informatie te verzamelen over uw online activiteiten via cookies en andere technologieën wanneer u de Website gebruikt. Deze derde partijen omvatten (a) zakelijke partners, die informatie verzamelen wanneer u een van hun advertenties op de Website bekijkt of er interactie mee heeft, en (b) advertentienetwerken, die informatie verzamelen over uw interesses wanneer u een van de advertenties bekijkt of er interactie mee heeft die ze plaatsen op veel verschillende websites op internet. De informatie die door deze derde partijen wordt verzameld, is niet-persoonlijk identificeerbare informatie die wordt gebruikt om voorspellingen te doen over uw kenmerken, interesses of voorkeuren en om advertenties op de Website en op internet weer te geven die zijn afgestemd op uw interesses.</div>
                    </div>
                    <div style="display: flex; gap: 0.3rem;">
                      <div>e.</div>
                      <div>U kunt het type cookies dat is toegestaan beheren via de instellingen van uw browser, inclusief het volledig blokkeren van alle cookies als u dat wilt. Voor informatie over hoe u cookies kunt beheren in uw browser, raadpleegt u het helpgedeelte van de browser die u gebruikt.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.3.</div>
                <div>
                  <strong>Gegevens van mobiele apparaten en mobiele browsers.</strong> U kunt de instellingen van uw mobiele apparaat en mobiele browser aanpassen met betrekking tot cookies en het delen van bepaalde informatie, zoals het model van uw mobiele apparaat of de taal die uw mobiele apparaat gebruikt, door de privacy- en beveiligingsinstellingen op uw mobiele apparaat aan te passen. Raadpleeg de instructies die uw mobiele serviceprovider of fabrikant van mobiele apparaten heeft verstrekt.
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.4.</div>
                <div>
                  <strong>Locatiegegevens.</strong> Als u de locatieservices op uw mobiele apparaat inschakelt, kunnen wij met uw toestemming de locatie van uw apparaat verzamelen, die we gebruiken om u locatiegebaseerde informatie en advertenties te verstrekken. Als u deze functie wilt uitschakelen, kunt u de locatieservices op uw mobiele apparaat uitschakelen.
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.5.</div>
                <div>
                  <strong>E-mailbeheer.</strong> U kunt e-mails van ons ontvangen om verschillende redenen - bijvoorbeeld als u een actie heeft ondernomen via de Services, u zich heeft aangemeld voor een regelmatig rapport of uw ontwerp heeft voltooid, en wij sturen u berichten om de volgende stappen te volgen. Als u een account bij ons heeft, kunt u uw voorkeuren bewerken via de instellingen van uw account. Bovendien kunt u het ontvangen van bepaalde soorten communicatie beheren door de instructies te volgen die zijn opgenomen in de e-mail die we u sturen. Houd er rekening mee dat, zelfs als u zich afmeldt voor bepaalde e-mailcorrespondentie, we u mogelijk nog steeds e-mails moeten sturen met belangrijke transactie- of administratieve informatie.
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; gap: 0.3rem;">
                <div>2.6.</div>
                <div>
                  <strong>Vrijwaring.</strong> Wij zijn niet aansprakelijk voor enige vrijwillige bekendmaking van Persoonlijke Gegevens door u aan andere gebruikers in verband met het gebruik van de Website, zoals van toepassing.
                </div>
              </div>
            </div>
          </div>
        `
    },
    {
      title: 'Doel van Gebruik',
      description: `
            <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>3.1</div>
                  <div>
                    De persoonlijke gegevens die u verstrekt, kunnen door ons (of door derden (zoals Google Analytics) namens ons) in totaal of individueel worden gebruikt en verwerkt voor het beheren en exploiteren van de website, waarvan het bereik kan omvatten, maar niet beperkt is tot de volgende doeleinden:
                    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                      <div style="display: flex; gap: 0.3rem;">
                          <div>a.</div>
                          <div>Beheer van de website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                          <div>b.</div>
                          <div>Verbetering van uw browse-ervaring door de website te personaliseren;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>c.</div>
                        <div>Het mogelijk maken van het gebruik van de diensten die op de website beschikbaar zijn;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>d.</div>
                        <div>Het leveren van de diensten die u specifiek heeft aangevraagd;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>e.</div>
                        <div>Reageren op uw vragen of verzoeken met betrekking tot uw e-mails en/of het indienen van formulieren op de website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>f.</div>
                        <div>Reageren op en opvolgen van klachten met betrekking tot het gebruik van de website of een door ons geleverde dienst;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>g.</div>
                        <div>Het sturen van algemene commerciële communicatie via elk communicatiekanaal, zoals e-mail, telefoongesprekken, sms en app-meldingen;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>h.</div>
                        <div>Uitvoeren van kantoor- en operationele administratieve zaken;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>i.</div>
                        <div>Derden voorzien van statistische informatie over onze gebruikers. Dergelijke statistische informatie kan worden gebruikt om advertenties op de website te personaliseren, maar kan niet worden gebruikt om enige individuele gebruiker te identificeren;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>j.</div>
                        <div>Het aanmaken en beheren van uw online account;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>k.</div>
                        <div>Het vergemakkelijken van uw activiteiten en transacties op de website;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>l.</div>
                        <div>Het vergemakkelijken van uw deelname aan wedstrijden of promoties;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>m.</div>
                        <div>De betaling aan u verrichten of terugbetalen;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>n.</div>
                        <div>U voorzien van informatie over de diensten die u bij ons heeft aangevraagd; of, afhankelijk van uw voorafgaande toestemming, informatie waarvan wij denken dat deze interessant voor u kan zijn per e-mail of sms;</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>o.</div>
                        <div>Het bedrijf exploiteren, evalueren en verbeteren (waaronder het ontwikkelen van nieuwe producten en diensten; analyseren en verbeteren van onze diensten);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>p.</div>
                        <div>Onze klanten begrijpen en gegevensanalyse en andere verwerking uitvoeren (waaronder markt- en consumentenonderzoek, trendanalyse en anonimisering);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>q.</div>
                        <div>Reageren op uw opmerkingen, vragen of sollicitaties voor een baan (indien van toepassing);</div>
                      </div>
                      <div style="display: flex; gap: 0.3rem;">
                        <div>r.</div>
                        <div>Deling met dienstverleners zodat zij de diensten aan u kunnen leveren; en zoals anderszins vereist of toegestaan ​​door de wet.</div>
                      </div>
                    </div>
                  </div>
                </div>
                U kunt ervoor kiezen om de website te bezoeken en de beperkte functies te gebruiken zonder uw persoonlijke gegevens bekend te maken. Het verstrekken van persoonlijke gegevens is geen voorwaarde voor het gebruik van de website, behalve indien nodig voor ons om de diensten te kunnen leveren die u via onze website koopt of gebruikt, om te reageren op uw verzoeken of in gevallen waarin u rechtstreeks contact met ons opneemt.
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.2. </div>
                <div>Aangezien de doeleinden waarvoor we uw persoonlijke gegevens kunnen verzamelen, gebruiken, openbaar maken of verwerken afhankelijk zijn van de omstandigheden, kunnen deze doeleinden hierboven niet worden vermeld. We zullen u echter op de hoogte stellen van dergelijke andere doeleinden op het moment van het verkrijgen van uw toestemming, tenzij verwerking van uw persoonlijke gegevens zonder uw toestemming is toegestaan ​​door de Personal Data Protection Act 2012 van Singapore ("<strong>PDPA</strong>") of door enige andere toepasselijke wetgeving.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                <div>3.3. </div>
                <div><strong>Provisies voor Niet Bellen.</strong> Indien u ons uw telefoonnummer(s) heeft verstrekt (de "<strong>Nummers</strong>") en heeft aangegeven dat u akkoord gaat met het ontvangen van marketing- of andere promotionele informatie via uw Nummers, kunnen we u van tijd tot tijd contact met u opnemen via dergelijke Nummers met informatie over onze diensten, zelfs als deze Nummers zijn geregistreerd bij het Niet Bellen-register (indien van toepassing volgens uw respectievelijke rechtsgebied). U kunt echter schriftelijk laten weten dat u niet door ons op uw Nummers voor dergelijke doeleinden wilt worden gecontacteerd.</div>
              </div>
            </div>
          `
    },
    {
      title: 'Nauwkeurigheid en Veiligheid',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>4.1.</div>
            <div>U moet ervoor zorgen dat alle persoonsgegevens die aan ons worden verstrekt volledig, nauwkeurig, waar en correct zijn. Als u dit niet doet, kan dit ertoe leiden dat wij u de door u gevraagde diensten niet kunnen leveren.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.2.</div>
            <div>Wij zullen redelijke inspanningen leveren om ervoor te zorgen dat uw persoonsgegevens nauwkeurig en volledig zijn, als uw persoonsgegevens waarschijnlijk door ons worden gebruikt om een beslissing te nemen die u beïnvloedt of worden bekendgemaakt aan een andere organisatie. U moet ons echter ook op de hoogte stellen van eventuele wijzigingen in uw persoonsgegevens die u aanvankelijk aan ons heeft verstrekt. Wij zijn niet verantwoordelijk voor het vertrouwen op onnauwkeurige of onvolledige persoonsgegevens die voortvloeien uit het niet bijwerken van uw persoonsgegevens die u aanvankelijk aan ons heeft verstrekt.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.3.</div>
            <div>Wij zullen redelijke technische en organisatorische voorzorgsmaatregelen nemen om het verlies, misbruik of wijziging van uw persoonsgegevens te voorkomen.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.4.</div>
            <div>Wij zullen alle persoonsgegevens die u verstrekt opslaan op beveiligde servers in een versleuteld formaat.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.5.</div>
            <div>U begrijpt echter dat de overdracht van informatie via internet niet volledig veilig is. Hoewel we ons best zullen doen om uw persoonsgegevens te beschermen, kunnen we de veiligheid van uw persoonsgegevens die naar de Website worden verzonden niet garanderen; elke overdracht is op eigen risico. Daarnaast kunnen we geen verantwoordelijkheid aanvaarden voor ongeoorloofd gebruik van uw persoonsgegevens door derden die volledig toe te schrijven zijn aan factoren buiten onze controle.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.6.</div>
            <div>Als we u (of als u ervoor hebt gekozen) een wachtwoord hebben gegeven waarmee u toegang kunt krijgen tot bepaalde delen van de Website, bent u verantwoordelijk voor het vertrouwelijk houden van dit wachtwoord. We vragen u om uw wachtwoord met niemand te delen. We zullen NOOIT om uw wachtwoord vragen, behalve wanneer u zich aanmeldt op de Website, indien van toepassing.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>4.7.</div>
            <div>We raden u aan maatregelen te nemen om uw persoonlijke informatie veilig te houden, zoals het kiezen van een sterk wachtwoord en het geheim houden ervan, evenals uitloggen uit uw gebruikersaccount en uw webbrowser sluiten wanneer u klaar bent met het gebruik van onze Diensten op een gedeeld of onbeveiligd apparaat.</div>
          </div>
        </div>
      `
    },
    {
      title: 'Openbaarmaking van Persoonsgegevens',
      description: `
        <div style="display: flex; flex-direction: column; gap: 0.5rem;">
          <div style="display: flex; gap: 0.3rem;">
            <div>5.1.</div>
            <div>Zonder uw uitdrukkelijke toestemming zullen wij uw Persoonsgegevens niet aan derden bekendmaken voor doeleinden van direct marketing. Toestemming voor de bekendmaking van Persoonsgegevens aan derden voor doeleinden van het ontvangen van marketingcommunicatie wordt gegeven via ons registratieformulier, zowel online als op papier.</div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.2.</div>
            <div>
              Om de Website te beheren en te exploiteren, kunnen wij uw Persoonsgegevens bekendmaken aan elk lid van onze groep, wat betekent onze gelieerde ondernemingen en dochterondernemingen. Wij kunnen uw Persoonsgegevens ook bekendmaken aan derden zoals onze dienstverleners en professionele adviseurs voor de volgende doeleinden:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Voor elk doel toegestaan onder enige toepasselijke wet of regelgeving;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Om te voldoen aan enige wettelijke verplichting;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Om onze Gebruiksvoorwaarden af te dwingen of toe te passen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Om onze rechten, eigendommen en veiligheid, evenals de rechten, eigendommen en veiligheid van onze gelieerde ondernemingen en dochterondernemingen, andere gebruikers van de Website en anderen te beschermen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>In verband met enige juridische procedure of potentiële juridische procedure; en</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>In verband met een transactie van bedrijfsmiddelen (dat wil zeggen de aankoop, verkoop, lease, fusie of samenvoeging of enige andere verwerving, vervreemding of financiering van een organisatie of een deel van een organisatie of van een van de bedrijven of activa van een organisatie) waaraan wij partij zijn of een toekomstige partij zijn.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.3.</div>
            <div>
              Wij zullen uw Persoonsgegevens ook niet aan derden bekendmaken zonder eerst uw toestemming te verkrijgen om dit te doen. Let echter op dat wij uw Persoonsgegevens in bepaalde situaties aan derden kunnen bekendmaken zonder eerst uw toestemming te verkrijgen, waaronder, maar niet beperkt tot, de volgende situaties:
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>a.</div>
                  <div>Gevallen waarin bekendmaking vereist of toegestaan is op basis van de geldende wetten en/of regelgeving;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>b.</div>
                  <div>Gevallen waarin het doel van dergelijke bekendmaking duidelijk in uw belang is en als toestemming niet tijdig kan worden verkregen;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>c.</div>
                  <div>Gevallen waarin bekendmaking noodzakelijk is om te reageren op een noodsituatie die uw leven, gezondheid of veiligheid of die van een andere persoon bedreigt;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>d.</div>
                  <div>Gevallen waarin bekendmaking noodzakelijk is voor enig onderzoek of juridische procedure;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>e.</div>
                  <div>Gevallen waarin de Persoonsgegevens worden bekendgemaakt aan een ambtenaar van een voorgeschreven rechtshandhavingsinstantie;</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>f.</div>
                  <div>Gevallen waarin de bekendmaking aan een overheidsinstantie is en een dergelijke bekendmaking noodzakelijk is in het algemeen belang; en/of</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>g.</div>
                  <div>Waar dergelijke bekendmaking zonder uw toestemming is toegestaan volgens de PDPA of de wet.</div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; gap: 0.3rem;">
            <div>5.4.</div>
            <div>
              Wanneer wij uw Persoonsgegevens aan derden bekendmaken zonder uw toestemming, zullen wij ons uiterste best doen om ervoor te zorgen dat dergelijke derden uw Persoonsgegevens beschermen.
            </div>
          </div>
        </div>
      `
    },
    {
      title: 'Intrekken van Toestemming',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.1.</div>
                  <div>U kunt uw toestemming intrekken door contact op te nemen met onze klantenservice op <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>6.2.</div>
                  <div>Houd er rekening mee dat als u uw toestemming voor een of alle gebruik van uw persoonlijke gegevens intrekt, afhankelijk van de aard van uw verzoek, wij mogelijk niet in staat zijn om onze diensten aan u te blijven leveren. Bij melding van u zullen wij u informeren over de mogelijke gevolgen van het intrekken van de toestemming, waaronder maar niet beperkt tot het beëindigen van eventuele overeenkomsten die u mogelijk met ons heeft en het schenden van uw contractuele verplichtingen. Onze juridische rechten en remedies in een dergelijk geval zijn uitdrukkelijk voorbehouden.</div>
              </div>
          </div>
      `
    },
    {
      title: 'Internationale Gegevensoverdracht',
      description: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.1.</div>
                  <div>Wij opereren in veel landen en kunnen uw persoonlijke gegevens tijdelijk opslaan, verwerken of overdragen tussen een van de landen waarin wij actief zijn om ons in staat te stellen uw persoonlijke gegevens te gebruiken in overeenstemming met dit Privacybeleid en onze Gebruiksvoorwaarden.</div>
              </div>
              <div style="display: flex; gap: 0.3rem;">
                  <div>7.2.</div>
                  <div>Wij zullen alle redelijkerwijs noodzakelijke stappen ondernemen om ervoor te zorgen dat uw persoonlijke gegevens worden overgedragen in overeenstemming met enige toepasselijke wet- of regelgeving, en alle redelijke stappen ondernemen om ervoor te zorgen dat elke buitenlandse ontvanger van uw persoonlijke gegevens gebonden is aan juridisch afdwingbare verplichtingen om een standaard van bescherming te bieden die ten minste vergelijkbaar is met de bescherming onder de PDPA.</div>
              </div>
          </div>
      `
    },
    {
      "title": "Website van Derden",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  De website kan van tijd tot tijd links bevatten naar websites van onze partner-netwerken en leveranciers. Als u een link volgt naar een van deze websites, merk dan op dat deze websites hun eigen privacybeleid hebben en dat wij geen verantwoordelijkheid aanvaarden voor dit beleid. Controleer dit beleid voordat u persoonlijke gegevens indient op deze websites.
                </div>
              </div>
            `
    },
    {
      "title": "Toegang, Verwijdering en Correctie",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.1.</div>
                  <div>Onze Functionaris voor Gegevensbescherming is verantwoordelijk voor het correct afhandelen van verzoeken tot toegang, verwijdering of correctie van persoonlijke gegevens volgens de hierin gespecificeerde procedures.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.2.</div>
                  <div>Alle verzoeken om informatie of verzoeken om wijziging of verwijdering van persoonsgegevens die wij bewaren, worden ingediend door een verzoek in te dienen bij onze Functionaris voor Gegevensbescherming op het volgende e-mailadres: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.3.</div>
                  <div>Houd er rekening mee dat we alleen verzoeken in het Engels zullen accepteren en actie zullen ondernemen die voldoen aan dit Privacybeleid. Elk verzoek dat niet voldoet aan het bovenstaande zal worden afgewezen en we zullen geen actie ondernemen met betrekking tot dergelijke afgewezen verzoeken.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.4.</div>
                  <div>We kunnen weigeren om persoonlijke gegevens bekend te maken, te verwijderen of te wijzigen om redenen die zijn uiteengezet in de PDPA.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>9.5.</div>
                  <div>Voor zover redelijkerwijs mogelijk zullen we binnen tien (10) werkdagen na ontvangst van het verzoek op uw verzoek reageren. Indien dit niet mogelijk is, wordt u binnen tien (10) werkdagen na ontvangst van het verzoek op de hoogte gebracht.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Behoud van uw persoonlijke gegevens",
      "description": `
              <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.1.</div>
                  <div>Wij behouden persoonlijke gegevens die we van u verzamelen wanneer we een doorlopend legitiem zakelijk belang hebben om dit te doen. Als u een klant of leverancier bent (of vertegenwoordiger van een klant of leverancier), worden de gegevens bewaard om ons in staat te stellen onze diensten te verlenen, te voldoen aan de van toepassing zijnde wettelijke, fiscale of boekhoudkundige vereisten en, indien nodig, voor het vaststellen, uitoefenen of verdedigen van juridische claims.</div>
                </div>
                <div style="display: flex; gap: 0.3rem;">
                  <div>10.2.</div>
                  <div>Wanneer we geen doorlopend legitiem zakelijk belang hebben om uw persoonlijke informatie te verwerken, zullen we deze verwijderen of anonimiseren, of indien dit niet mogelijk is (bijvoorbeeld omdat uw persoonlijke informatie is opgeslagen in back-uparchieven), zullen we uw persoonlijke informatie veilig opslaan en isoleren van verdere verwerking totdat verwijdering mogelijk is.</div>
                </div>
              </div>
            `
    },
    {
      "title": "Neem contact met ons op",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                    <div>
                      Als u meer informatie nodig heeft over de behandeling van uw persoonlijke gegevens door ons, aarzel dan niet om contact op te nemen met onze Functionaris voor Gegevensbescherming via: <a href="mailto:areyouready@levitate.ch">areyouready@levitate.ch</a>.
                    </div>
                  </div>
                </div>
              `
    },
    {
      "title": "Toepasselijk recht",
      "description": `
                <div style="display: flex; flex-direction: column; gap: 0.5rem;">
                  <div style="display: flex; gap: 0.3rem;">
                  Dit Privacybeleid wordt in alle opzichten beheerst door de wetten van Singapore.
                  </div>
                </div>
              `
    }
  ]
};
