export const DATA_FAQ_KO = (textBrand: string) => {
  return [
    {
      title:
        `${textBrand} International은 무엇이며 무슨 역할을 하고 있나요?`,
      description: `
            <p>자, 우리는 주택 시장과 따분하고 구식적인 판매 경험에 대해 이야기해 봅시다. ${textBrand} International은 이런 게임을 바꾸려고 왔어요. 우린 네 친구, 뒤죽박죽으로 뭔가를 흔들고 있어요.</p>
            <p>우리는 미래의 주택을 공동 개발하고 있어요. 어떻게 그렇게 하느냐구요? 개인화 기술의 힘을 활용해서요. 그래, 맞아요. ${textBrand}은 인공지능에 의해 주도되는 놀라운 3D 실내 디자인 도구를 소개하고 있어요. 네, 제가 맞게 말한 거에요. 이 멋진 놈을 사용하면 실시간으로 거실 공간을 이동하고, 방마다 미래의 집을 미리 설계할 수 있어요.</p>
            <p>${textBrand}과 함께하면 원활하고 즐거운 여정을 시작하게 될 거에요. 더 이상의 놀라운 점도 없고, 더 이상의 지연도 없을 거에요. 이건 꿈꾸던 집으로 가는 효율적이고 투명한 여정이에요.</p>
           `,
    },
    {
      title:
        '세그먼트란 무엇이며 새 집 구매 시 어떻게 작동하나요?',
      description: `
            <p>자, 친구야, 세그먼트에 대해 이야기해 봅시다. ${textBrand}의 주택 구매 여정에서 그들이 어떻게 작동하는지요.</p>
            <p>세그먼트는 당신의 욕망과 예산을 충족시키는 공간을 만들기 위해 결합할 수 있는 하나의 퍼즐 조각과 같아요.</p>
            <p>이는 당신이 개별적으로 또는 여러 개로 얻을 수 있는 생활 공간의 구별된 크기라고 생각하시면 돼요.</p>
           `,
    },
    {
      title:
        '예약 계약은 집 구매 과정에서 왜 중요한 걸까요?',
      description: `
            <p>자, 이 예약 계약에 대해 이야기해 봅시다. 아마도 "이게 뭐야, 그리고 왜 신경 써야 해?"라고 생각하실 수 있어요. 제가 설명해 드리겠습니다. 이 녀석은 부동산 구매의 중요한 요소랍니다. 왜냐하면 이것은 마치 안전망 같은 거거든요.</p>
            <p>예약 계약을 서명하면, 사실 "잠깐만, 이 집을 지켜보고 있어요. 그리고 그건 내 거여야 해요." 라고 말하는 거에요.</p>
            <p>왜 이렇게 중요한 거냐구요? 간단합니다. 이것은 여러분에게 안심을 주며 여러분의 이익을 보호해줍니다. 여러분이 마무리하려는 건 미래의 주소이니까요.</p>
           `,
    },
    {
      title:
        '판매 및 구매 계약에는 어떤 내용이 포함되어 있으며 주택 구매와 어떤 관련이 있나요?',
      description: `
            <p>판매 및 구매 계약은 주택 구매에 있어 마치 그래프의 성대한 신전과 같아요. 이는 일어나고 있는 일과 여러분의 위험을 보호하기 위한 거죠.</p>
            <p>이해를 돕기 위해, 주택 구매 시 판매 및 구매 계약에 무엇이 들어가는지 이야기하도록 하겠습니다. 우린 집값, 전달 조건, 그리고 구매 거래를 폐쇄하는 시기 등 모든 중요 사항을 언급하고 있어요.</p>
            <p>이건 미끄러지는 손잡이 없는 손잡이 거래랑은 다른 거에요. 이건 실제로 간단하지 않은, 양쪽 모두가 책임을 지는 중요한 계약이랍니다. 그러니 거대한 주택 구매에 대비해서 판매 및 구매 계약을 확보하는 걸 잊지 마세요. 여러분의 주택 구매 여정에서 필요한 안전장치입니다.</p>
           `,

    },
    {
      title:
        `어떻게 하면 ${textBrand} International의 비밀스러운 힘을 이용하여 주택 구매 꿈을 현실로 만들 수 있을까요?`,
      description: `
            <p>그러면 여러분이 어떻게 하면 ${textBrand} International의 비밀스러운 힘을 이용해서 꿈이 현실이 되는지 궁금하시죠? 답을 드리도록 하겠습니다.</p>
            <p><strong>1단계:</strong> 여러분이 원하는 나라를 선택하세요. 당신이 가장 행복하게 살 수 있는 곳을 고르세요. 지금 당장 시작하세요.</p>
            <p><strong>2단계:</strong> 여러분이 탐험할 수 있는 다양한 프로젝트가 준비되어 있습니다. 디테일에 빠져들고, 나만의 공간을 만들며, 내 마음대로 집을 미리 디자인할 수 있습니다.</p>
            <p><strong>3단계:</strong> 여기가 더 흥미로워요. 현실에 대비하세요. 꿈을 가지고 준비하세요. 3D 시뮬레이션을 이용해 여러분만의 고유한 라이프스타일을 시각화하고 이해하세요.</p>
            <p><strong>4단계:</strong> 이제 여러분이 준비되었습니다. 거래를 체결하기 위해 온라인으로 예약 계약과 구매 계약을 서명하세요.</p>
            <p>자, 이제 앉아서 기다리세요. 원하시는 것들이 곧 여러분에게로 찾아올 거에요. ${textBrand}은 여러분의 꿈을 실현시키기 위해 여기 있습니다.</p>
          `,
    },

    {

      title:
        '어떻게 하면 "속공의 현실"프로그램에 참여하고 다가오는 서비스로 최신 유행에 참여할 수 있을까요?',
      description: `
            <p>그러니까, 여러분이 "속공의 현실"프로그램에 참여하고 다가오는 서비스에 대한 선두 주자가 되기를 원한다면 어떻게 해야할까요? 난 허세를 부리고 있지 않아요. 낭만적인 그런 이야기는 없다구요. 제가 설명해 드리겠습니다.</p>
            <p>우리 웹사이트로 이동하여 "관심 목록"을 찾으세요. 그것은 여러분과 같은 집을 사랑하는 사람들의 보물상자와 같습니다. 여러분은 "관심 목록"에 가입하여 기다리는 목록에 포함될 수 있습니다. 여러분의 이메일 주소를 입력하고 이 흥미로운 웨이팅 리스트에 포함되세요.</p>
            <p>우리가 보증합니다. 우리 프로젝트가 준비가 되면, 당신은 처음으로 움직일 준비가 되어 있을 겁니다. 원하는 데로 움직이고, 곧 이뤄질 사항에 대한 흥분을 느끼며, 또한 그 시간이 빨리 다가올 거라는 확신을 가질 수 있을 거에요.</p>
            <p>자, 더 이상 시간을 낭비하지 마세요. "관심 목록"에 가입하고 곧 다가올 서비스에 대한 흥분을 느껴보세요. ${textBrand}은 여러분의 꿈이 빠르게 이뤄지도록 이곳에 있습니다.</p>
          `,
    },

    {
      title:
        `${textBrand} International은 여러분의 가치있는 데이터를 보호하기 위해 어떤 노력을 기울이고 있으며 안전한 거래를 위해 어떤 파트너가 있나요?`,
      description: `
            <p>${textBrand} International은 여러분의 가치있는 데이터에는 아무런 장난을 치지 않습니다. 여러분의 데이터는 우리에게 보물과 같으며, 최고 수준의 안전성으로 보호합니다. 우리는 어떤 경향도 없으며, 여러분의 데이터를 보호하기 위해 최선을 다하고 있습니다. 여러분의 안전을 보장하기 위해 적절한 파트너들과 협력하고 있습니다.</p>
            <p>우리는 싱가폴 기반의 Tazapay와 파트너십을 맺고 있습니다. 그들은 여러분의 결제에 대한 세부 정보에 대한 전문적인 지식을 가지고 있으며, 여러분의 데이터가 안전하게 보호되도록 도와줍니다.</p>
            <p>우리는 빈틈 없는 협조를 갖지 않습니다. 여러분의 데이터는 우리에게 우선 순위입니다. 우리는 안전한 거래를 보장하기 위해 적절한 파트너들과 협력하고 있습니다.</p>
          `,
    },
  ];
};
