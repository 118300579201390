import { User } from 'model/user';
import { ECurrency } from 'constants/currency';

export enum DevelopDebugKey {
  LogEnv = 'DEV_LOG_ENV', // to log env to console
  EnableDesignDebugMode = 'DEV_ENABLE_DESIGN_DEBUG_MODE', // to display more info in Your Design page
  DesignLayoutId = 'DEV_DESIGN_LAYOUT_ID', // to set layout id for quick check in Your Design page
  DesignProjectId = 'DEV_DESIGN_PROJECT_ID', // to set layout id for quick check in Your Design page
  PixelStreamingHost = 'DEV_PIXEL_STREAMING_HOST', // to set layout id for quick check in Your Design page
  PixelStreamingProject = 'DEV_PIXEL_STREAMING_PROJECT', // to set layout id for quick check in Your Design page
  DisableCheckStep = 'DEV_DISABLE_CHECK_STEP', // to set layout id for quick check in Your Design page
}

export enum StorageKey {
  Modules = 'modules',
  AccessToken = 'token',
  RememberMe = 'remember_me',
  SkipIntroduction = 'skip_introduction',
  UserAccessCount = 'user_access_count',
  AccessLocation = 'access_location',
  StepYourDesign = 'step_your_design',
  UUID_Design = 'uuid_design',
  AuthDesign = 'auth_design',
  FinancialPackage = 'financial_package',
  ProjectInfo = 'project_info',
  StepOrder = 'step_order',
  OrderTemp = 'order_temp',
  LogoCompany = 'logo_company',
  SliderSegment = 'slider_segment',
  ConversionRate = 'conversionRate', 
  SellType = 'sell_type',
  // 3D Arcware
  ProductDefaultByPlaceholderId = 'productDefaultByPlaceholderId',
  CategoriesById = 'categoriesById',
  SelectedPlaceholderId = 'selectedPlaceholderId',
  PackageId = 'packageId',
  ShareId = 'shareId',
  Envision = 'envision',
  personalized = 'personalized',
  promotion = 'promotion'
}

export type StorageValues = {
  [StorageKey.Modules]?: any;
  [StorageKey.AccessToken]?: string;
  [StorageKey.RememberMe]?: boolean;
  [StorageKey.SkipIntroduction]?: boolean;
  [StorageKey.UserAccessCount]?: number;
  [StorageKey.AccessLocation]?: string;
  [StorageKey.StepYourDesign]?: string;
  [StorageKey.UUID_Design]?: string;
  [StorageKey.AuthDesign]?: string;
  [StorageKey.FinancialPackage]?: string;
  [StorageKey.ProjectInfo]?: string;
  [StorageKey.StepOrder]?: string;
  [StorageKey.OrderTemp]?: string;
  [StorageKey.LogoCompany]?: string;
  [StorageKey.SliderSegment]?: string;
  [StorageKey.ConversionRate]?: number;
  [StorageKey.SellType]?: string;

  // 3D Arcware
  [StorageKey.ProductDefaultByPlaceholderId]?: string;
  [StorageKey.CategoriesById]?: string;
  [StorageKey.SelectedPlaceholderId]?: number;
  [StorageKey.PackageId]?: string;
  [StorageKey.ShareId]?: string;
  [StorageKey.Envision]?: string;
  [StorageKey.personalized]?: string;
  [StorageKey.promotion]?: string;
};

export enum ModuleType {
  ChatBot = 'chat_bot',
  YourHome = 'your_home',
  Payment = 'payment',
  ProjectHistory = 'project_history',
}

export enum SessionKey {
  Language = 'lang',
  PhonePrefix = 'phone_prefix',
  AccessToken = 'levipro.accessToken',
  FirebaseAccessToken = 'levipro.firebaseAccessToken',
  LoginRedirectUrl = 'levipro.redirectUrl',
  UserInfo = 'levipro.userinfo',
  IpAddressInfo = 'ipAddressInfo',
  SaleAgentInfo = 'levipro.saleAgentInfo',
  SaleAgentAccessToken = 'levipro.saleAgentAccessToken',
  PortalToken = 'levipro.portalToken',
  PortalUserInfo = 'levipro.portalUserInfo',
  SkipCheckOverBudget = 'skipCheckOverBudget',
  Currency = 'currency',
  currencySymbol= 'currencySymbol',
  isLoad3DFirst = 'isLoad3DFirst',
  isDemo3DInteract = 'isDemo3DInteract',
}

export type SessionValues = {
  [SessionKey.Language]?: string;
  [SessionKey.PhonePrefix]?: string;
  [SessionKey.AccessToken]?: { accessToken: string };
  [SessionKey.UserInfo]?: User;
  [SessionKey.FirebaseAccessToken]?: string;
  [SessionKey.LoginRedirectUrl]?: string;
  [SessionKey.IpAddressInfo]?: string;
  [SessionKey.SaleAgentInfo]?: User;
  [SessionKey.SaleAgentAccessToken]?: { accessToken: string };
  [SessionKey.PortalToken]?: string;
  [SessionKey.PortalUserInfo]?: string;
  [SessionKey.SkipCheckOverBudget]?: boolean;
  [SessionKey.Currency]?: ECurrency;
  [SessionKey.currencySymbol]?: string;
  [SessionKey.isLoad3DFirst]?: boolean;
  [SessionKey.isDemo3DInteract]?: boolean;
};

export enum Language {
  EN = 'en',
  VI = 'vi',
}
